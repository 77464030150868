import { useQuery } from "@tanstack/react-query";
import CustomTooltip from "components/common/CustomTooltip";
import CallDrop from "img/svg/CallDrop";
import Calling from "img/svg/Calling";
import VoiceMail from "img/svg/VoiceMail";
import React, { useEffect, useRef, useState } from "react";
import CreateAuthAxiosInstance from "utils/authAxios";

interface IOnCallProps {
  hangUpButtonRef: React.RefObject<HTMLButtonElement>
  isCallAccepted: boolean
  setTotalCallDuration: React.Dispatch<React.SetStateAction<string | null>>
  handlePostVoiceMailMutation: () => void
  receiverType: string
}

const OnCall = ({
  hangUpButtonRef,
  isCallAccepted,
  setTotalCallDuration,
  handlePostVoiceMailMutation,
  receiverType
}: IOnCallProps) => {
  const authAxios = CreateAuthAxiosInstance();

  const [callDuration, setCallDuration] = useState<number>(0)
  const [hasVoiceMailFile, setHasVoiceMailFile] = useState<boolean>(false)
  const timerRef = useRef<NodeJS.Timer>()

  const {
    data: electionData,
    isFetching: isFetchingElectionDataStatus,
  } = useQuery({
    queryKey: ['electionDetailForVoiceMailButton'],
    queryFn: () => authAxios.get('api/v1/candidate/election').then(data => data?.data),
  })

  useEffect(() => {
    if (isCallAccepted) {
      timerRef.current = setInterval(() => {
        setCallDuration(prev => prev + 1)
      }, 1000)
    }

    return () => {
      setTotalCallDuration(callDuration ? secondsToHMS(callDuration) : null)
      if (timerRef.current) clearInterval(timerRef.current)
    }
  }, [isCallAccepted, callDuration])

  useEffect(() => {
    if (!electionData) return

    setHasVoiceMailFile(
      receiverType === 'voter' ?
        electionData?.hasVotersVoicemailFile
        :
        electionData?.hasDonorsVoicemailFile
    )
  }, [isFetchingElectionDataStatus])

  return (
    <>
      <div className="callBox-status">
        <Calling />
        <div className="callBox-status-body">
          <div className="callBox-status-text">
            {isCallAccepted ? 'Connected' : 'Calling...'}
          </div>
          <div className="callBox-status-duration">
            {isCallAccepted && secondsToHMS(callDuration)}
          </div>
        </div>
      </div>
      <div className="callBox-btn">
        {isCallAccepted &&
          <CustomTooltip
            content={`${hasVoiceMailFile ? 'Leave voice mail' : 'Upload to send voicemail'}`}
          >
            <button
              className="btn btn-success btn-cancel rounded-circle"
              type='button'
              data-bs-html="true"
              data-bs-custom-class="custom-tooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              data-bs-title="Leave voice mail"
              onClick={handlePostVoiceMailMutation}
              disabled={!hasVoiceMailFile}
            >
              <VoiceMail />
            </button>
          </CustomTooltip>
        }
        <button
          ref={hangUpButtonRef}
          className="btn btn-danger btn-cancel rounded-circle"
          type="button"
        >
          <CallDrop />
        </button>
      </div>
    </>
  );
};

export default OnCall;

export function secondsToHMS(totalSeconds: number) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${hours > 0 ? `${formattedHours}:` : ''}${formattedMinutes}:${formattedSeconds}`;
}

import { STEP_INDEX } from "components/forms/multi-step-forms/onboarding/OnboardingForm";
import { ReactElement, useState } from "react";

export function useMultistepForm(steps: ReactElement[]) {
  const stepIndex = localStorage.getItem(STEP_INDEX);

  const [currentStepIndex, setCurrentStepIndex] = useState(
    stepIndex ? Number(stepIndex) : 0
  );

  function next() {
    setCurrentStepIndex((i) => {
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  }

  function back() {
    setCurrentStepIndex((i) => {
      if (i <= 0) return i;
      return i - 1;
    });
  }

  function goTo(index: number) {
    setCurrentStepIndex(index);
  }

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    stepsLength: steps.length,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    goTo,
    next,
    back,
  };
}

import React from 'react'
import { Link } from 'react-router-dom';
import SideNavbar from '../../components/common/navbar/SideNavbar';
import Stepper from '../components/Stepper';
import FileUpload from '../components/FileUpload';
import CheckSuccess from '../../img/svg/checkSuccess';
const YourStory = () => {
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="row">
            <div className="col-md-4">
              <div className="accountSetup accountSetup-fixed">
                <div className="accountSetup-header">
                  <h5>Account setup list</h5>
                  <p>Setup your account to use Campaign Brain</p>
                </div>
                <div className="accountSetup-body">
                  <Stepper />
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="card card-accountSetup shadow-2">
                <div className="card-title">
                  <h4>Your story</h4>
                  <p> Providing information about yourself allows Campaign Brain to further personalize campaign strategy</p>
                </div>

                <div className="card-body">
                  <div className="your-story">
                  <form action="">
                    <div className="mb-3">
                      <label className="custom-label">Resume</label>
                      

                      {/* before upload */}

                      <FileUpload />

                      {/* after upload */}

                      <div className="row">
                        <div className="col-md-9">
                        <div className="uploadedCard">
                        <div className="uploadedCard-icon">
                          <div className="iconBox"></div>

                          <div className="iconInfo">
                            <span className="iconInfo-title">
                              {" "}
                              Resume.pdf{" "}
                            </span>
                            <p className="iconInfo-desc">
                              {" "}
                              1.5 MB, 6/23/23 at 11:45 am{" "}
                            </p>
                            <span className="iconInfo-status">
                              {" "}
                              <CheckSuccess /> File uploaded{" "}
                            </span>
                          </div>
                        </div>
                        <div className="uploadedCard-info">
                          <div className="file-btn">
                            <button className="btn btn-link text-primary">
                              {" "}
                              Change File
                              <input type="file" name="file" />
                            </button>
                          </div>

                          <button className="btn btn-xs">
                            <span className='icon-trash'></span>
                          </button>
                        
                        
                        </div>
                      </div>
                        </div>
                      </div>
                    
                    </div>

                    <div className="mb-3">
                      <label
                          htmlFor="InputLinked"
                          className="form-label"
                      >
                          Add your Linkedin account  
                      </label>
                      <input
                          type="text"
                          className="form-control"
                          id="InputLinked"
                          aria-describedby="emailHelp"
                          placeholder=""
                      />
                      </div>

                      <div className="mb-3">
                          <label
                              htmlFor="Textarea1"
                              className="form-label"
                          >
                          Why are you running for office?
                          
                          </label>
                          <div className="position-relative">
                            <textarea className="form-control" id="Textarea1" rows={3} maxLength={1000}>
                            
                            </textarea> 
                            <span className='word-count'> 0 / 200 </span> 
                          </div>
                          
                      
                      </div>

                      <div className="mb-3">
                          <label
                              htmlFor="Textarea2"
                              className="form-label"
                          >
                          What are your main policy proposals, ideas, and platforms?
                          
                          </label>
                          <div className="position-relative">
                            <textarea className="form-control" id="Textarea2" rows={3} maxLength={1000}></textarea> 
                            
                          
                            <span className='word-count'> 0 / 200 </span> 
                          </div>
                        
                      
                      </div>

                      <div className="mb-3">
                          <label
                              htmlFor="TextareaStrength"
                              className="form-label"
                          >
                          Your biggest strength
                          
                          </label>
                          <span className='text-muted d-block mb-1'>Tell us your biggest strength compare to the competitors</span>
                        
                          <div className="position-relative">
                          <textarea className="form-control" id="TextareaStrength" rows={2} maxLength={1000}></textarea> 
                            
                          
                            <span className='word-count'> 0 / 100 </span> 
                          </div>
                        
                          <button className="btn btn-link text-primary mt-3"> <span className="icon-plus me-2"></span> Add another strength </button>
                      </div>
                  </form>
                  </div>
                
                </div>

                <div className="card-footer shadow-6 gap-4 d-flex justify-content-end">
                  <Link to="" className="btn btn-link text-primary btn-xs">
                    Not Now{" "}
                  </Link>
                  <Link to="" className="btn btn-primary rounded-pill btn-xs px-6">
                    Next{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </div>
    </div>
  )
}

export default YourStory

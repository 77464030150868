import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";
import { globalQueryConfig } from "utils/reactQuery";

export default function usePersonalDetails() {
  const authAxios = CreateAuthAxiosInstance();
  const { logout } = useAuth0();
  const location = useLocation();
  const [editable, setEditable] = useState<string | null>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isVerifyClicked, setIsVerifyClicked] = useState(false);

  const fetchUserData = async () => {
    const resp = await authAxios.get("api/v1/user/detail");
    return resp.data;
  };

  const fetchCandidateData = async () => {
    const resp = await authAxios.get("api/v1/candidate/election");
    return resp.data;
  };

  const userDataQuery = useQuery({
    queryKey: ["UserDetails"],
    queryFn: fetchUserData,
    ...globalQueryConfig,
  });

  const { data: userData, isLoading: isUserDataLoading } = userDataQuery;

  const candidateDataQuery = useQuery({
    queryKey: ["CandidateDetails"],
    queryFn: fetchCandidateData,
    ...globalQueryConfig,
  });

  const { data: candidateData, isLoading: isCandidateDataLoading } =
    candidateDataQuery;

  async function updatePersonalDetailsApi(data: any) {
    const response = await authAxios.patch(`api/v1/user/${userData.id}`, data);
    return response.data;
  }

  async function updateCandidateData(data: any) {
    const response = await authAxios.patch(
      `api/v1/election/${candidateData.id}`,
      data
    );
    return response.data;
  }

  async function resendEmail(data: any) {
    setIsVerifyClicked(true);
    const response = await authAxios.post(`api/v1/email/verification-email`);
    return response.data;
  }

  async function deleteAccount() {
    const response = await authAxios.delete(
      `api/v1/profile-setting/candidate-account`
    );
    return response.data;
  }

  const { mutate: onPersonalDetailsSubmit } = useMutation({
    mutationFn: updatePersonalDetailsApi,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["UpdateCandidatePhoneNumber"],
      });
      userDataQuery.refetch();
      toastNotify("success", "Changes saved!");
      reset();
    },
    onError: (error: any) => {
      toastNotify("error", "Update failed");
      console.log("error occured", error);
    },
  });

  const { mutate: onUpdateCandidateData } = useMutation({
    mutationFn: updateCandidateData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["UpdateCandidateData"],
      });
      candidateDataQuery.refetch();
      toastNotify("success", "Changes saved!");
      reset();
    },
    onError: (error: any) => {
      toastNotify("error", "Update failed.");
      console.log("error occured", error);
    },
  });

  const { mutate: onResendEmail } = useMutation({
    mutationFn: resendEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["ResentEmail"],
      });
      candidateDataQuery.refetch();
      toastNotify("success", "Email confirmation has been sent!");
      reset();
      openOnNewTab();
    },
    onError: () => {
      toastNotify("error", "Email confirmation send failed.");
    },
  });
  const { mutate: onAccountDeletion } = useMutation({
    mutationFn: deleteAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["DeleteAccount"],
      });
      candidateDataQuery.refetch();
      toastNotify("success", "Account deleted!");
      localStorage.clear();
      logout({
        logoutParams: {
          returnTo: window.location.origin + "/account-deleted",
        },
      });
    },
    onError: (error: any) => {
      toastNotify(
        "error",
        error.response.data.message || "Account deletion failed!"
      );
    },
    onMutate: () => {
      setIsDeleteLoading(true);
    },
    onSettled: () => {
      setIsDeleteLoading(false);
    },
  });

  const openOnNewTab = () => {
    const currentPath = location.pathname;
    if (!currentPath.includes("email-verification")) {
      window.open("/email-verification", "_blank");
    }
  };

  const reset = () => {
    setEditable(null);
  };

  return {
    userData,
    isUserDataLoading,
    candidateData,
    isCandidateDataLoading,
    onPersonalDetailsSubmit,
    onUpdateCandidateData,
    editable,
    setEditable,
    onResendEmail,
    openOnNewTab,
    userDataQuery,
    onAccountDeletion,
    isDeleteLoading,
    isVerifyClicked,
    setIsVerifyClicked,
  };
}

import { useMutation, useQuery } from "@tanstack/react-query"
import { queryClient } from "index"
import CreateAuthAxiosInstance from "utils/authAxios"
import toastNotify from "utils/toastNotify"
import { globalQueryConfig } from "utils/reactQuery"
import axios from "axios"

const useDonorDetails = () => {
  const authAxios = CreateAuthAxiosInstance()

  const {
    data: donorData,
    refetch: refetchDonorData,
    isFetching: isDonorDataFetching
  } = useQuery({
    queryKey: ["donorData"],
    queryFn: () => authAxios("api/v1/profile-setting/candidate-details").then(response => response?.data),
    ...globalQueryConfig
  })

  const {
    mutate: postDonorsSubmit,
    mutateAsync: postAsyncDonorsSubmit,
    isPending: isPendingDonorPostMutation,
    status: postDonorSubmitStatus
  } = useMutation({
    mutationFn: (data: any) => authAxios.post("api/v1/profile-setting/candidate-details", data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["donorData"] })
      const response = await refetchDonorData()

      if (response) toastNotify("success", "Successfully Created!")
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log("on error", error)
        toastNotify("error", error?.response?.data?.message)
      }
    }
  })

  const {
    mutate: updateDonorsSubmit,
    mutateAsync: updateAsyncDonorsSubmit,
    isPending: isPendingDonorUpdateMutation,
    status: updateDonorsSubmitStatus
  } = useMutation({
    mutationFn: (data: any) => authAxios.patch("api/v1/profile-setting/candidate-details", data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["donorData"] })
      const response = await refetchDonorData()

      if (response) toastNotify("success", "Changes saved!")
    },
    onError: (error: unknown) => {
      if (axios.isAxiosError(error)) {
        console.log("on error", error)
        toastNotify("error", error?.response?.data?.message)
      }
    }
  })

  const {
    data: userInfo
  } = useQuery({
    queryKey: ['candidateDetailVoiceMailDonor', donorData?.id],
    queryFn: () => authAxios.get(`api/v1/user/info/${donorData?.id}`).then(data => data?.data),
    refetchOnWindowFocus: false,
    enabled: !!(donorData?.id)
  })

  return {
    donorData,
    isDonorDataFetching,
    postDonorsSubmit,
    postAsyncDonorsSubmit,
    isPendingDonorPostMutation,
    postDonorSubmitStatus,
    updateDonorsSubmit,
    updateAsyncDonorsSubmit,
    isPendingDonorUpdateMutation,
    updateDonorsSubmitStatus,
    userInfo
  }
}

export default useDonorDetails

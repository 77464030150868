import { useMutation } from "@tanstack/react-query";
import Spinner from "templates/components/Spinner"
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";
import { IVoiceMailDataType } from "./VoiceMailUpload";
import { useRef } from "react";
import { queryClient } from "index";

const DeleteVoiceMailModal = ({ sectionType, fileId, setFetchedAudioData }: {
  sectionType: string,
  fileId: number | undefined | null,
  setFetchedAudioData: React.Dispatch<React.SetStateAction<IVoiceMailDataType | null>>,
}) => {
  const authAxios = CreateAuthAxiosInstance()
  const buttonRef = useRef<HTMLButtonElement>(null)

  const {
    mutate: mutateDeleteVoiceMail,
    status: deleteVoiceMailMutationStatus
  } = useMutation({
    mutationFn: () => authAxios.delete(`api/v1/${sectionType === 'voter' ? 'voter' : 'donor'}/voicemail/${fileId}`),
    onSuccess: () => {
      handleOnSuccessMutation()
    },
    onError: (error: any) => {
      // console.log("on error", error);
      toastNotify("error", error?.response?.data?.message);
    }
  });

  function handleOnSuccessMutation() {
    toastNotify('success', 'Voicemail Deleted Successfully !')
    setFetchedAudioData(null)
    queryClient.invalidateQueries({ queryKey: ['uploadedVoiceMail'] })

    if (buttonRef?.current) buttonRef?.current?.click()
  }

  return (
    <>
      <div
        className="modal fade"
        id="deleteVoterVoicemailModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={buttonRef}
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3">Delete voicemail?</h2>
              <p>
                Voicemail will not be available. You will no longer be able to send the voicemail to the&nbsp;
                {`${sectionType === 'voter' ? 'voters' : 'donors'}`}
                &nbsp;in a call.
              </p>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-link rounded-pill btn-xs"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary rounded-pill btn-xs"
                disabled={deleteVoiceMailMutationStatus === 'pending' || !fileId}
                onClick={() => mutateDeleteVoiceMail()}
              >
                {deleteVoiceMailMutationStatus === 'pending' && <Spinner />}
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteVoiceMailModal
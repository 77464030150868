import ApprovedIcon from "img/svg/approvedIcon";
import LogoOnly from "img/svg/logoOnly";
import RejectedIcon from "img/svg/rejectedIcon";
import TwitterIcon from "img/svg/twitter";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ICommuncationModalProps, IMessageProps } from "./interface";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { formattedDate } from "utils/formatDate";
import FacebookIcon from "img/svg/facebook";

const CommunicationModal: React.FC<ICommuncationModalProps> = ({
  isUpdatedEvent,
  events,
  currentEvent,
  setIsUpdatedEvent,
  eventsGroup,
  generatedEvents,
  getNextPost,
  removeEventById,
  closeRef,
  close,
}) => {
  const { getIdTokenClaims } = useAuth0();
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState("");
  const [isContentUpdated, setIsContentUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditMessage, setShowEditMessage] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [generatedContent, setGeneratedContent] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentCandidatePost = currentEvent?.candidatePosts[0];
  const cIndex = currentEvent ? currentEvent?.countIndex : 0;
  const isNextPostDisabled = cIndex ? eventsGroup.length <= cIndex : false;
  const handleEdit = () => {
    setIsEditing(true);
  };
  const descriptionRef = useRef<HTMLDivElement>(null);

  const handleRegnerate = async () => {
    setIsGenerated(true);
    const currentEvent = generatedEvents[currentIndex];

    if (currentEvent) {
      setEditedContent(currentEvent?.description);
      setGeneratedContent(currentEvent?.description);
      setCurrentIndex((prevCount) => prevCount + 1);
      if (currentIndex >= generatedEvents.length - 1) {
        setCurrentIndex(0);
      }
      setIsContentUpdated(true);
    }
  };

  //on delete update the status to Reject instead of actual deletion
  const handleDelete = async () => {
    setIsLoading(true);
    const id = currentCandidatePost?.id;
    const updateData = {
      description: editedContent,
      status: "Rejected",
    };
    await updateEvent(id, updateData);
    removeEventById(currentEvent?.postId, currentEvent?.countIndex);
    reset();
    setShowDeleteMessage(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    reset();
    resetPostDescription();
  };

  const handleSave = () => {
    setIsEditing(false);
    setIsContentUpdated(true);
  };

  const reset = () => {
    setIsContentUpdated(false);
    setIsLoading(false);
    setIsEditing(false);
    setIsGenerated(false);
    setEditedContent("");
    setGeneratedContent("");
    setIsUpdatedEvent(!isUpdatedEvent);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    const updateData = {
      description: editedContent,
    };
    const candidatePostId = currentCandidatePost?.id;
    await updateEvent(candidatePostId, updateData);
    setShowEditMessage(true);
    handleNextPost(currentEvent?.countIndex);
  };

  const updateEvent = async (id: number | undefined, data: object) => {
    if (!id) return;
    const token = await getIdTokenClaims();
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/candidate-posts/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token?.__raw}`,
          },
        }
      );
      reset();
    } catch (error) {
      console.log("error", error);
      reset();
    }
  };

  //on cancel reset the post description paragraph
  const resetPostDescription = () => {
    if (descriptionRef.current) {
      let pElement = descriptionRef.current.querySelector(
        "p"
      ) as HTMLParagraphElement | null;
      if (pElement) {
        pElement.textContent = currentCandidatePost?.description || "";
      }
    }
  };

  const handleNextPost = (currentCountIndex: number | undefined) => {
    if (isNextPostDisabled) {
      // close the modal for last post
      close();
      return;
    }
    reset();
    getNextPost(currentCountIndex);
  };

  const isModalDisabled = isLoading || showEditMessage || showDeleteMessage;
  const postDescription = isGenerated
    ? generatedContent
    : currentCandidatePost && currentCandidatePost?.description;
  const currentSocialMedia = currentEvent?.socialMedia;

  return (
    <div
      className="modal fade"
      id="updateEventModal"
      tabIndex={-1}
      aria-labelledby="updateEventModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="taskModal">
            {/* left part */}
            {/* left part removed and add ms-0 class below*/}

            {/* right part */}
            <div className="taskModal-content ms-0 border-0">
              <div className="modal-header">
                <div className="task-controls text-center">
                  <h5 className="modal-title">Social Media Posts and Emails</h5>
                  <div className="task-staus">
                    {currentEvent?.countIndex} of {eventsGroup.length} posts for{" "}
                    {formattedDate(currentEvent?.startDate)}
                  </div>
                </div>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={reset}
                  ref={closeRef}
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div
                className={`modal-body disable px-4 ${
                  isModalDisabled ? "disabled" : ""
                }`}
              >
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabIndex={0}
                  >
                    <div className="postSlider-wrapper">
                      {/* <SocialMedia /> */}
                      {/* media object */}
                      <div className="media">
                        <div className="media-header">
                          <span className="media-header--text">
                            {" "}
                            <LogoOnly />{" "}
                          </span>
                        </div>
                        <div className="media-body">
                          <h4>
                            Topic : <span> {currentEvent?.title} </span>
                          </h4>
                          <h4>
                            Scheduled for: <span>3:30pm </span>
                            <span className="dot bg-neutral-n3 ms-1"> </span>
                            <span>
                              {formattedDate(currentEvent?.startDate)}
                            </span>
                          </h4>
                        </div>
                      </div>

                      {/* Social media post */}
                      <div className="mediaPost editable-activ regeneratin mt-3">
                        <div className="mediaPost-header">
                          {getSocialMedia(currentSocialMedia)}
                        </div>
                        <div className="mediaPost-body">
                          <div className="platform">
                            <h4 className="platform-title">
                              {currentEvent?.socialMedia}
                            </h4>

                            <div className="platform-action">
                              {!isEditing ? (
                                <>
                                  <Link
                                    to=""
                                    className="btn btn-link p-0 ms-2 d-inline-block"
                                    onClick={handleEdit}
                                  >
                                    <span className="icon-edit"> </span> Edit
                                  </Link>
                                  <Link
                                    to=""
                                    className="btn btn-link p-0 ms-4 d-inline-block reload-btn"
                                    onClick={handleRegnerate}
                                  >
                                    <span className="icon-reload"> </span>
                                    Regenerate
                                  </Link>
                                </>
                              ) : (
                                <>
                                  <Link
                                    to=""
                                    className="btn btn-link p-0 ms-2 d-inline-block"
                                    onClick={handleCancel}
                                  >
                                    Cancel
                                  </Link>
                                  <Link
                                    to=""
                                    className="btn btn-link p-0 ms-4 d-inline-block"
                                    onClick={handleSave}
                                  >
                                    <span className="icon-tick"> </span> Save
                                    Changes
                                  </Link>
                                </>
                              )}
                            </div>
                          </div>

                          <div
                            className="editableBox mt-2"
                            contentEditable={isEditing}
                            onInput={(e) => {
                              if (e.currentTarget.textContent)
                                setEditedContent(e.currentTarget.textContent);
                            }}
                            ref={descriptionRef}
                          >
                            <p>
                              {isGenerated ? generatedContent : postDescription}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer justify-content-between px-0 pt-4">
                        <div className="action-btns">
                          <button
                            className="btn btn-primary rounded-pill me-3"
                            onClick={handleUpdate}
                            disabled={!isContentUpdated}
                          >
                            Update post
                          </button>
                          <button
                            className="btn btn-reject rounded-pill"
                            onClick={handleDelete}
                          >
                            Delete post
                          </button>
                        </div>

                        <div
                          className="next-btn"
                          onClick={() => {
                            handleNextPost(currentEvent?.countIndex);
                          }}
                        >
                          <button
                            className={`btn btn-link rounded-pill ${
                              isNextPostDisabled ? "disabled" : ""
                            }`}
                          >
                            Next post
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* show popup success message  */}
                <div className="status-btn active">
                  {showEditMessage && (
                    <SuccessMessage
                      type="update"
                      message="Post Updated"
                      onClose={() => setShowEditMessage(false)}
                    />
                  )}
                  {showDeleteMessage && (
                    <SuccessMessage
                      type="delete"
                      message="Post Deleted"
                      onClose={() => setShowDeleteMessage(false)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunicationModal;

const SuccessMessage: React.FC<IMessageProps> = ({
  type,
  message,
  onClose,
}) => {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, 1500); // 2 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  let Icon, messageClasses;
  if (type === "update") {
    messageClasses = "tag-success";
    Icon = <ApprovedIcon />;
  } else if (type === "delete") {
    messageClasses = "tag-reject";
    Icon = <RejectedIcon />;
  }

  return visible ? (
    <button className={`tag rounded-pill ${messageClasses}`}>
      {Icon}
      {message}
    </button>
  ) : (
    <div></div>
  );
};

const getSocialMedia = (socialMediaType: string | undefined) => {
  if (!socialMediaType) return;
  return (
    <div
      className={`iconBox ${
        socialMediaType === "Twitter" ? "Twitter" : "facebook"
      }`}
    >
      {socialMediaType === "Twitter" ? <TwitterIcon /> : <FacebookIcon />}
    </div>
  );
};

import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";
import { globalQueryConfig } from "utils/reactQuery";
import { useEffect, useState } from "react";

const useCandidateScriptDetails = () => {
  const authAxios = CreateAuthAxiosInstance();
  const [editable, setEditable] = useState<null | string>(null);
  const [scriptId, setScriptId] = useState<number>();
  const [active, setActive] = useState();

  const [activeScript,setActiveScript] = useState<ICandidateScriptPostData>({})

  const fetchScriptData = async () => {
    const resp = await authAxios.get("api/v1/call-scripts");
    return resp.data;
  };

  const getScriptData = useQuery({
    queryKey: ["script-get-data"],
    queryFn: fetchScriptData,
    ...globalQueryConfig,
  });
  const { data: fetchScriptsData } = getScriptData;
  
  const postCandidateData = async (data: ICandidateScriptPostData) => {
    const resp = await authAxios.post("api/v1/call-scripts", data);
    return resp.data;
  };

  const patchCandidateData = async (data: ICandidateScriptPostData) => {
    const resp = await authAxios.patch(`api/v1/call-scripts/${activeScript?.id}`, data);
    return resp.data;
  };

  const { mutate: postCandidateScriptData } = useMutation({
    mutationFn: postCandidateData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["script-get-data"],
      });
      setEditable(null);
      toastNotify("success", "Successfully Submitted!");
    },
    onError: (error: any) => {
      console.log("on error", error);
      toastNotify("error", error?.response?.data?.message[0]);
    },
  });

  const { mutate: patchCandidateScriptData } = useMutation({
    mutationFn: patchCandidateData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["script-get-data"],
      });
      toastNotify("success", "Successfully Submitted!");
    },
    onError: (error: any) => {
      console.log("on error", error);
      toastNotify("error", error?.response?.data?.message[0]);
    },
  });

    // for title of default script
    const defaultValueMap: { [key: string]: string }  = {
      "By Phone": "Voter Contact Script",
      "By Door": "Voter Contact Canvassing Script",
      "By Fundraising": "Call Time Script"
    };

  return {
    postCandidateScriptData,
    patchCandidateScriptData,
    fetchScriptsData,
    editable,
    setEditable,
    setScriptId,
    active,
    setActive,
    scriptId,
    setActiveScript,
    activeScript,
    defaultValueMap
  };
};

export default useCandidateScriptDetails;

import { useEffect, useRef, useState } from "react";
import PromptSuggestions from "./PromptSuggestions";
import AiChatBox from "./AiChatBox";
import LogoOnly from "img/svg/logoOnly";
import TypingLoader from "templates/components/TypingLoader";
import {
  CHAT_HISTORIES_LIMIT,
  useConversationalAI,
} from "./useConversationalAi";
import CopyText from "./CopyText";
import Loader from "components/common/Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HamburgerIcon from "img/svg/HamburgerIcon";
import { getYearMonthDate } from "utils/getDateTime";
import { useAuth0 } from "@auth0/auth0-react";
import CustomTooltip from "components/common/CustomTooltip";

const PromptModal = () => {
  const {
    conversations,
    setIsModalOpen,
    chatHistoryData,
    activeChatDateIndex,
    setActiveChateDateIndex,
    chatHistoriesLimit,
    setChatHistoriesLimit,
    totalChatHistories,
    setQuery,
    isGenerating,
    setSessionId,
    electionInfo,
    sendButtonRef,
    setConversations,
  } = useConversationalAI();

  const modalRef = useRef<HTMLDivElement | null>(null);

  const { user } = useAuth0();
  const DefaultProfileImage = require("../../../../img/avatar.png");
  const chatHistoriesScrollRef = useRef<HTMLDivElement>(null);
  const [canShowChatHistory, setCanShowChatHistory] = useState(true);
  const navigate = useNavigate();
  const { state } = useLocation();

  // const handleClick = () => {
  //   setCanShowChatHistory(!canShowChatHistory);
  // };
  useEffect(() => {
    const loadMore = (scrollDiv: HTMLDivElement) => {
      if (scrollDiv) {
        const scrollHeight = scrollDiv.scrollHeight;
        const scrollTop = scrollDiv.scrollTop;
        const clientHeight = scrollDiv.clientHeight;
        const toBottom = scrollHeight - clientHeight - scrollTop;
        if (toBottom < 10 && totalChatHistories > chatHistoriesLimit) {
          setChatHistoriesLimit(chatHistoriesLimit + CHAT_HISTORIES_LIMIT);
        }
      }
    };

    const chatHistoriesDiv = chatHistoriesScrollRef.current;
    if (chatHistoriesDiv) {
      chatHistoriesDiv.addEventListener("scroll", () =>
        loadMore(chatHistoriesDiv)
      );
    }

    return () => {
      if (chatHistoriesDiv)
        chatHistoriesDiv.removeEventListener("scroll", () =>
          loadMore(chatHistoriesDiv)
        );
    };
  });
  useEffect(() => {
    const chatDiv = modalRef?.current;
    const sendButton = sendButtonRef?.current;

    if (state?.isTopPriorities && chatDiv) {
      chatDiv.click();
      setIsModalOpen(true);
    }

    if (state?.question && sendButton) {
      setQuery(state.question);
      const timeoutId = setTimeout(() => {
        sendButton.click();
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [state, modalRef, sendButtonRef]);

  useEffect(() => {
    if (state?.isTopPriorities) setConversations([]);
  }, [state]);

  return (
    <>
      <div
        className=""
        data-bs-toggle="modal"
        data-bs-target="#chatAiModal"
        ref={modalRef}
      ></div>
      {/* conversational ai modal */}
      <div
        className="modal fade"
        id="chatAiModal"
        tabIndex={-1}
        aria-labelledby="chatAiModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="aiModalLayout">
              {/* left part */}
              <div
                className={
                  canShowChatHistory
                    ? `aiModalLayout-sidenav`
                    : `aiModalLayout-sidenav hide`
                }
              >
                <div className="modal-header">
                  <h5 className="modal-title"> Chat History </h5>
                  <CustomTooltip content="Add Prompt">
                    <button
                      className="btn btn-xs px-0 py-0"
                      onClick={() => {
                        setActiveChateDateIndex(-1);
                        setSessionId(undefined);
                      }}
                    >
                      {" "}
                      <span className="icon-edit fs-5"> </span>
                    </button>
                  </CustomTooltip>
                </div>
                <div
                  className="modal-body px-0 scroll-y"
                  ref={chatHistoriesScrollRef}
                >
                  {/* Date tabs */}
                  <ul className="nav flex-column " id="myTab" role="tablist">
                    {chatHistoryData?.map((chat, index) => (
                      <li
                        className="nav-item"
                        key={index}
                        onClick={() => setActiveChateDateIndex(index)}
                      >
                        <button
                          className={`nav-link w-100 text-start ${
                            activeChatDateIndex === index && "active"
                          }`}
                          aria-current="page"
                        >
                          {getYearMonthDate(new Date().toISOString()) ===
                          getYearMonthDate(chat.date)
                            ? "Today"
                            : getYearMonthDate(chat.date)}
                        </button>
                      </li>
                    ))}
                  </ul>
                  {totalChatHistories > chatHistoriesLimit && <Loader />}
                </div>
              </div>
              {/* right part */}
              <div className="aiModalLayout-content">
                <div className="modal-header text-center">
                  <Link
                    to=""
                    onClick={() => {
                      setCanShowChatHistory(!canShowChatHistory);
                      // setActiveChateDateIndex(-1);
                    }}
                    className={
                      canShowChatHistory
                        ? `toggle-history active`
                        : `toggle-history`
                    }
                  >
                    {" "}
                    <HamburgerIcon />{" "}
                  </Link>
                  <div className="d-flex flex-column w-100">
                    <h1
                      className="modal-title text-gredient "
                      id="exampleModalLabel"
                    >
                      Welcome,
                      <span className="">
                      {" "}
                    future{" "}
                    {electionInfo?.positionTitle || electionInfo?.electionPosition} 
                      {" "} 
                      {electionInfo?.fullName}
                    !
                      </span>
                    </h1>

                    <p className="text-muted">
                      <span className="text-primary-p3 font-semibold">
                        {electionInfo?.daysUntilElection}{" "}
                      </span>{" "}
                      days until election. Here are the things to get started.{" "}
                    </p>
                  </div>

                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsModalOpen(false);
                      setActiveChateDateIndex(-1);
                      setQuery("");
                      if (state?.isTopPriorities) {
                        setConversations([]);
                        navigate("/dashboard");
                      }
                    }}
                  >
                    <span className="icon-close text-n1 h2"></span>
                  </button>
                </div>
                <div className="modal-body pb-0">
                  {activeChatDateIndex !== -1 && chatHistoryData ? (
                    chatHistoryData[activeChatDateIndex].chats.map(
                      (chat, index) => (
                        <div key={index}>
                          <div className="media media-md promptAi">
                            <div className="media-header">
                              <img
                                src={user?.picture ?? DefaultProfileImage}
                                className="img-fluid"
                                alt="User Avatar"
                              />
                            </div>
                            <div className="media-body">
                              <p className="mb-0">{chat.query}</p>
                            </div>
                          </div>
                          <div className="media media-md promptAi pt-0">
                            <div className="media-header">
                              <LogoOnly />
                            </div>
                            <div className="media-body promptAi-answer">
                              {/* generated  answer will be here! */}
                              <div
                                className="answer-wrapper"
                                defaultValue={chat?.response}
                              >
                                {chat?.response
                                  ?.split("\n\n")
                                  ?.map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                  ))}
                                <CopyText
                                  copyValue={chat.response}
                                  chatId={chat.id}
                                  feedbackRating={chat.rating}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <>
                      {conversations.map((conversation, index) => {
                        const {
                          prompt,
                          isGenerating,
                          response,
                          isError,
                          rating,
                        } = conversation;
                        // console.log(conversation);
                        return (
                          <div key={index}>
                            <div className="media media-md promptAi">
                              <div className="media-header border-0">
                                <img
                                  src={user?.picture ?? DefaultProfileImage}
                                  className="img-fluid"
                                  alt="User Avatar"
                                />
                              </div>
                              <div className="media-body">
                                <p>{prompt}</p>
                              </div>
                            </div>
                            {response || isGenerating ? (
                              <div className="media media-md promptAi">
                                {!isError && (
                                  <div className="media-header border-0">
                                    <LogoOnly />
                                  </div>
                                )}
                                <div className="media-body promptAi-answer">
                                  {/* while generating answer */}
                                  {isGenerating ? <TypingLoader /> : null}
                                  {/* generated  answer will be here! */}
                                  {response ? (
                                    <div
                                      className="answer-wrapper"
                                      defaultValue={response}
                                    >
                                      {response
                                        .split("\n\n")
                                        .map((paragraph, index) => (
                                          <p key={index}>{paragraph}</p>
                                        ))}
                                      <CopyText
                                        copyValue={response}
                                        feedbackRating={rating}
                                        chatId={conversation.chatId ?? -1}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                            {isError && (
                              <div className="media media-md promptAi">
                                <div className="media-header border-0">
                                  <LogoOnly />
                                </div>
                                <div className="media-body">
                                  <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <span className="ms-2">
                                      Apologies, a “Network Error” occurred
                                      while responding to the prompt.
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                  {activeChatDateIndex === -1 && !isGenerating ? (
                    <PromptSuggestions />
                  ) : null}
                </div>
                <div className="modal-footer">
                  <AiChatBox />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromptModal;

export enum NotificationActionType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export type SuccessNotificationAction = {
  type: NotificationActionType.SUCCESS;
  payload: string;
};

export type ErrorNotificationAction = {
  type: NotificationActionType.ERROR;
  payload: string;
};

export type NotificationsAction =
  | SuccessNotificationAction
  | ErrorNotificationAction;

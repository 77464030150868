import { useEffect, useState } from "react";
import { DOOR_TO_DOOR } from "../voters-list/VotersList";
import { CANDIDATE_ID } from "components/pages/Homepage";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useQuery } from "@tanstack/react-query";
import { globalQueryConfig } from "utils/reactQuery";
import { IElectionData } from "type/dashboard";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register({
  id: "minBarHeight",
  beforeDraw: (chart) => {
    // const yScale = chart.scales.y;
    const minBarHeight = 2; // Minimum height in pixels
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((bar: any, index: number) => {
        const value = dataset.data[index];
        if (typeof value === "number" && value > 0) {
          const height = bar.base - bar.y;
          if (height < minBarHeight) {
            bar.height = minBarHeight;
            bar.y = bar.base - minBarHeight;
          }
        }
      });
    });
  },
});

interface IChartData {
  week: Date;
  weekNumber: number;
  votersContacted: number;
  voterContactGoal: number;
  isCurrentWeek: boolean;
}

interface IContactedVoters {
  name: string;
  address: string;
  status: string;
  levelOfSupport: string;
}

const useVotersDashboard = () => {
  const userId = localStorage.getItem(CANDIDATE_ID);

  const [contactedVoters, setContactedVoters] = useState<
    Array<IContactedVoters>
  >([]);
  const [selectedContactType, setSelectedContactType] =
    useState<string>(DOOR_TO_DOOR);

  const authAxios = CreateAuthAxiosInstance();

  const getElectionInfo = async (): Promise<IElectionData | undefined> => {
    try {
      const res = await authAxios.get(`/api/v1/candidate/election`);
      return res.data as IElectionData;
    } catch (error) {
      console.error(error);
    }
  };

  const { data: electionInfo, isLoading: isElectionInfoLoading } = useQuery({
    queryKey: ["electionInfo"],
    queryFn: getElectionInfo,
    ...globalQueryConfig,
  });

  useEffect(() => {
    (async () => {
      try {
        // Get contacted voters
        const contactedVotersResponse = await authAxios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/voter/candidate/${userId}/recent-contacts?limit=10&contactType=${selectedContactType}`
        );
        if (contactedVotersResponse) {
          const mappedVotersToContact: IContactedVoters[] =
            contactedVotersResponse.data.items.map((item: any) => ({
              name: item.name,
              address: item.address,
              status: item.voterContactHistories[0].status,
              levelOfSupport: item.voterContactHistories[0].levelOfSupport,
            }));
          setContactedVoters(mappedVotersToContact);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [userId, selectedContactType]);

  // Chart
  const getChartData = async (): Promise<IChartData[] | undefined> => {
    try {
      const chartResponse = await authAxios.get<IChartData[]>(
        "/api/v1/voter/contacts/graph"
      );
      return chartResponse?.data as IChartData[];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: chartData, isLoading: isChartDataLoading } = useQuery({
    queryKey: ["chartData"],
    queryFn: getChartData,
    ...globalQueryConfig,
  });

  const chartLabels = chartData?.map((week: IChartData) => {
    const weekDate = new Date(week.week);
    const month =
      new Date(weekDate).toLocaleString("en-us", { month: "short" }) ===
      new Date(
        new Date(weekDate).setDate(new Date(weekDate).getDate() + 6)
      ).toLocaleString("en-us", { month: "short" })
        ? new Date(weekDate).toLocaleString("en-us", { month: "short" })
        : new Date(weekDate).toLocaleString("en-us", { month: "short" }) +
          "/" +
          new Date(
            new Date(weekDate).setDate(new Date(weekDate).getDate() + 6)
          ).toLocaleString("en-us", { month: "short" });
    const date =
      new Date(weekDate).getDate() +
      "-" +
      new Date(
        new Date(weekDate).setDate(new Date(weekDate).getDate() + 6)
      ).getDate();
    return [month, date];
  });

  const chartDataLength = chartData && Object.keys(chartData)?.length;

  const maxVoterContactGoal = chartData
    ? Math.max(...chartData.map((item) => item.voterContactGoal))
    : 0;

  const chartDataWithLabels = {
    labels: chartLabels,
    datasets: [
      {
        label: "Voter contact goal",
        backgroundColor: "rgba(134, 82, 163, 1)",
        borderColor: "rgba(134, 82, 163, 1)",
        borderWidth: 1,
        borderRadius: 2,
        hoverBackgroundColor: "rgba(134, 82, 163, 0.8)",
        hoverBorderColor: "rgba(134, 82, 163, 1)",
        data: chartData?.map((item) => item.voterContactGoal),
        barThickness: 14,
      },
      {
        label: "Voter contacted",
        backgroundColor: "rgba(224, 192, 249, 1)",
        borderColor: "rgba(224, 192, 249, 1)",
        borderWidth: 1,
        borderRadius: 2,
        hoverBackgroundColor: "rgba(224, 192, 249, .8)",
        hoverBorderColor: "rgba(224, 192, 249, 1)",
        data: chartData?.map((item) => item.votersContacted),
        barThickness: 14,
      },
    ],
  };

  const chartOptions: ChartOptions<"bar"> = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: maxVoterContactGoal * 1.1,
        grace: "5%",
        ticks: {
          callback: function (value) {
            return Number.isInteger(value) ? value.toString() : null;
          },
        },
      },
    },
  };

  return {
    contactedVoters,
    selectedContactType,
    setSelectedContactType,
    chartDataLength,
    chartDataWithLabels,
    isChartDataLoading,
    electionInfo,
    isElectionInfoLoading,
    chartOptions,
  };
};

export default useVotersDashboard;

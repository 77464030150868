const GoBack = ({ back }: { back?: () => void }) => {
  return (
    <>
      <button
        type="submit"
        className="btn btn-link p-0 goBack"
        onClick={() => back && back()}
      >
        <div className="d-flex align-items-center gap-2">
        <span className="icon-arrowBack fw-bold"> </span>Back
        </div>
       
      </button>
    </>
  );
};

export default GoBack;

import { queryClient } from "index";
import { useEffect } from "react";
import { Link } from "react-router-dom";

interface IVerificationSuccess {
  cleanAndCloseTwilioCard: () => void;
}

const VerificationSuccess = ({ cleanAndCloseTwilioCard }: IVerificationSuccess) => {

  function handleSuccessClickEvent() {
    cleanAndCloseTwilioCard()
    queryClient.invalidateQueries({ queryKey: ['candidateInfo'] })
  }

  return (
    <div className="card-body text-center">
      <img
        src={require("../../../../img/success-modal.png")}
        width={56}
        height={56}
        alt="leave alert"
      />
      <h2 className="mt-4"> Verification Success </h2>
      <p className="mb-6 body-3 text-muted">
        Your number has been verified by Campaign Brain.
      </p>

      <div className="w-100">
        <Link
          to=""
          className="btn btn-primary btn-xs rounded-pill w-100"
          onClick={handleSuccessClickEvent}
        >
          Continue
        </Link>
      </div>
    </div>
  );
};

export default VerificationSuccess;
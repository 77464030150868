const EmptyVoiceMailCard = ({ handleFileChange }: {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <>
      <div className="uploadedCard">
        <div className="uploadedCard-icon">
          <div className="iconInfo">
            <span className="iconInfo-title text-muted">
              mp3, wav, m4a
              <span className="ms-2 dot bg-neutral-n3"> </span>
              2MB max
            </span>
          </div>
        </div>
        <div
          className="uploadedCard-info file-btn"
          style={{ overflow: 'hidden' }}
        >
          <input
            type="file"
            name="firstUploadedFile"
            onChange={handleFileChange}
          />
          <button className="btn btn-info rounded-pill">
            Upload
          </button>
        </div>
      </div>
    </>
  )
}

export default EmptyVoiceMailCard
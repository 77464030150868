import { useQuery } from '@tanstack/react-query'
import CreateAuthAxiosInstance from 'utils/authAxios'

export interface IFileTypeObj {
  id: number
  isActive: boolean
  isArchived: boolean
  deletedAt: string | null
  createdAt: string
  createdBy: string | null
  updatedAt: string
  updatedBy: string | null
  internalComment: string | null
  title: string
  description?: string | null
}

const useGetFileTypeList = () => {
  const authAxios = CreateAuthAxiosInstance()

  const {
    data: fileTypeList,
    refetch: refetchFileTypeList,
    isFetching: isFileTypeListFetching
  } = useQuery({
    queryKey: ["fileTypeList"],
    queryFn: () => authAxios("api/v1/file-type").then((response) => response.data),
    refetchOnWindowFocus: false,
  })

  return {
    fileTypeList,
    refetchFileTypeList,
    isFileTypeListFetching
  }
}

export default useGetFileTypeList
import BudgetFileUpload from "components/common/BudgetFileUpload";
import { Link } from "react-router-dom";
import { getLocalStorageItem } from "utils/localStorageHelper";
import { ACTIVE_STEP, ELECTION_ID } from "../Dashboard";
import { useMutation, useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { isValidFileType } from "components/pages/budget/common/CheckValidFile";
import axios from "axios";
import { queryClient } from "index";
import { fileSizeLimit } from "utils/budgetFileSizeLimit";
import { IValidationResponse } from "components/pages/budget/common/upload-file/UploadModal";
import { globalQueryConfig } from "utils/reactQuery";
import { useGetElectionDetail } from "components/hooks/useGetElectionDetail";
import { BUDGET_MODE_QUERY_KEY } from "components/pages/profile/budget-mode/useBudgetMode";
import toastNotify from "utils/toastNotify";

interface IBudget {
  handleStepCompletion: (stepNumber: number, status: string) => void;
}

export interface IBudgetFileType {
  id: number;
  title: string;
  updatedAt: string;
}

export interface IBudgetFileDetails {
  id: number;
  fileName: string;
  size: number;
  updatedAt: string;
  fileTypeId: number;
  fileLinkId: number;
}

const Budget = ({ handleStepCompletion }: IBudget) => {
  const authAxios = CreateAuthAxiosInstance();
  const activeStep: number = getLocalStorageItem(ACTIVE_STEP);
  const candidateId = localStorage.getItem(CANDIDATE_ID);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const electionId = localStorage.getItem(ELECTION_ID);
  const [timeZone, setTimeZone] = useState("");
  const [parsedBudgetId, setParsedBudgetId] = useState<number | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedBudgetMode, setSelectedBudgetMode] = useState<string>("");

  const { electionDetail, refetch: refetchElectionDetail } =
    useGetElectionDetail();

  const budgetMode: string = electionDetail?.budgetMode;

  // const handleClicked = () => {
  //   setIsChecked(!isChecked);
  // };

  //checking fundraising goal
  const { data: funraisingData, isFetched: isFundraisingFetched } = useQuery({
    queryKey: ["fundraisingGoalDetails"],
    queryFn: () =>
      authAxios
        .get(`api/v1/profile-setting/candidate-details`)
        .then((response) => response.data),
    ...globalQueryConfig,
  });

  //to get fileType id of Budget
  const { data: fileType } = useQuery({
    queryKey: ["fileType"],
    queryFn: () =>
      authAxios.get("/api/v1/file-type").then((response) => response.data),
    ...globalQueryConfig,
  });

  //find the file type of Budget
  const budgetFileType = useMemo(() => {
    if (fileType?.length) {
      return fileType.find(
        (file: IBudgetFileType) => file.title === "candidate-budget"
      );
    }
    return null;
  }, [fileType]);

  //get Budget file detils from api
  const { data: budgetFile } = useQuery({
    queryKey: ["budgetFile"],
    queryFn: () =>
      authAxios
        .get(`api/v1/file/${candidateId}`)
        .then((response) => response.data),
    ...globalQueryConfig,
  });

  //find detils of Budget
  const budgetDetailsFromApi = useMemo(() => {
    if (budgetFile?.length && budgetFileType?.id) {
      return budgetFile.find(
        (budget: IBudgetFileDetails) => budget.fileTypeId === budgetFileType.id
      );
    }
    return null;
  }, [budgetFile, budgetFileType]);

  //validate file
  const { mutate: validateBudgetFile, isPending: isPendingFileValidation } =
    useMutation({
      mutationFn: budgetFileValidationFn,
      onSuccess: handleBudgetFileValidationSuccess,
      onError: handleBudgetFileValidationError,
    });

  async function budgetFileValidationFn({
    timeZone,
    file,
  }: {
    timeZone: string;
    file: FormData;
  }) {
    const response = await authAxios.post("api/v1/budgets/validate", file, {
      headers: {
        "Content-Type": "multipart/form-data",
        "time-zone": timeZone,
      },
    });
    return response?.data;
  }

  function handleBudgetFileValidationSuccess(data: IValidationResponse) {
    if (data?.id) setParsedBudgetId(data.id);
  }

  function handleBudgetFileValidationError(error: unknown) {
    if (axios.isAxiosError(error)) {
      setError(error?.response?.data?.message);
    }
  }

  //upload file
  const {
    mutate: uploadBudgetFile,
    isPending: isFileUploadPending,
    isSuccess: isFileUploadSuccess,
  } = useMutation({
    mutationFn: handleBudgetFileUpload,
    onSuccess: handleUploadOnSuccessEvent,
    onError: handleUploadOnErrorEvent,
  });

  async function handleBudgetFileUpload({
    timeZone,
    file,
  }: {
    timeZone: string;
    file: FormData;
  }) {
    const response = await authAxios.post(
      `api/v1/budgets/upload/${parsedBudgetId}`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "time-zone": timeZone,
        },
      }
    );
    return response?.data;
  }

  async function handleUploadOnSuccessEvent() {
    await queryClient.invalidateQueries({
      queryKey: ["budgetFileData"],
    });
    mutateBudgetMode(BUDGET_MODE_QUERY_KEY.FILE);
    handleStepCompletion(activeStep, "completed");
    setError(null);
  }

  function handleUploadOnErrorEvent(error: unknown) {
    if (axios.isAxiosError(error)) {
      setError(error?.response?.data?.message);
    }
  }

  //to delete Budget File
  // const { mutate: mutateDeleteBudgetFile } = useMutation({
  //   mutationFn: () =>
  //     authAxios.delete(`api/v1/file/${budgetDetailsFromApi?.id}`),
  //   onSuccess: async () => {
  //     queryClient.invalidateQueries({
  //       queryKey: ["budgetFile"],
  //     });
  //     setFile(null);
  //   },
  // });

  const { mutate: mutateBudgetMode } = useMutation({
    mutationFn: (budgetMode: string) =>
      authAxios.post(`api/v1/budgets/build-budgets?budgetMode=${budgetMode}`),
    onSuccess: handleBudgetModeOnSuccess,
    onError: handleBudgetModeOnError,
  });

  function handleBudgetModeOnSuccess() {
    refetchElectionDetail();
    handleStepCompletion(activeStep, "completed");
    setError(null);
  }

  function handleBudgetModeOnError(error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error("on error", error?.response?.data?.message);
      if (
        selectedBudgetMode === BUDGET_MODE_QUERY_KEY.FUND &&
        error?.response?.data?.statusCode === 404
      ) {
        toastNotify(
          "error",
          "Cannot generate budget; system limits election dates to 12 months"
        );
      }
    }
  }

  const handleTemplateDownload = async () => {
    try {
      const response = await authAxios("/api/v1/budgets/import-template", {
        responseType: "blob", // This tells axios to return data as a Blob
      });
      const blobUrl = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "budget_template.xlsx");
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blobUrl);
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error("Failed to download the file:", error);
    }
  };

  const handleFileUpload = () => {
    if (selectedBudgetMode === BUDGET_MODE_QUERY_KEY.FILE) {
      //case in which no new file is uploaded but file exists in api
      if (!file && budgetDetailsFromApi?.id) {
        mutateBudgetMode(BUDGET_MODE_QUERY_KEY.FILE);
        handleStepCompletion(activeStep, "completed");
      }
      //case in which new file is uploaded
      if (
        file &&
        isValidFileType(file) &&
        file.size <= fileSizeLimit &&
        electionId
      ) {
        const formData = new FormData();

        formData.append("fileTypeId", String(budgetFileType?.id));
        formData.append("file", file);

        uploadBudgetFile({ timeZone, file: formData });
      }
    } else if (selectedBudgetMode === BUDGET_MODE_QUERY_KEY.NULL) {
      mutateBudgetMode(BUDGET_MODE_QUERY_KEY.NULL);
    } else if (selectedBudgetMode === BUDGET_MODE_QUERY_KEY.FUND) {
      mutateBudgetMode(BUDGET_MODE_QUERY_KEY.FUND);
    }
  };

  const handleSkip = () => {
    mutateBudgetMode(BUDGET_MODE_QUERY_KEY.NULL);
    handleStepCompletion(activeStep, "skipped");
  };

  const handleFileValidation = (validationFile: File) => {
    if (
      validationFile &&
      isValidFileType(validationFile) &&
      validationFile.size <= fileSizeLimit &&
      electionId &&
      budgetFileType?.id
    ) {
      const formData = new FormData();

      formData.append("fileTypeId", String(budgetFileType?.id));
      formData.append("file", validationFile);

      validateBudgetFile({ timeZone, file: formData });
    }
  };

  const checkFundraisingGoal = funraisingData?.fundraisingGoal;

  const isFileValid =
    ((file && isValidFileType(file) && file.size <= fileSizeLimit) ||
      budgetDetailsFromApi?.id) &&
    !error &&
    !isPendingFileValidation &&
    !isFileUploadPending;

  const shouldDisableButton =
    selectedBudgetMode === "" ||
    (selectedBudgetMode === BUDGET_MODE_QUERY_KEY.FILE && !isFileValid);

  useLayoutEffect(() => {
    if (budgetMode) {
      setSelectedBudgetMode(budgetMode);
      return;
    }
  }, [budgetMode]);

  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  useEffect(() => {
    if (parsedBudgetId) setParsedBudgetId(null);
  }, [file]);

  return (
    <div className="col-md-8">
      <div className="card card-accountSetup shadow-2">
        <div className="card-title">
          <h4>Budget</h4>
          <p>
            {" "}
            To add projected budget for your campaign, choose one of the
            following three options below.
          </p>
        </div>
        <div className="card-body">
          <form action="">
            <div className="mb-4">
              <div className="d-flex">
                <div className="mb-2 customRadio-wrapper">
                  <label className="customRadio form-check-label body-4">
                    <input
                      type="radio"
                      name="radio"
                      checked={
                        selectedBudgetMode === BUDGET_MODE_QUERY_KEY.FILE
                      }
                      onChange={() => {
                        setSelectedBudgetMode(BUDGET_MODE_QUERY_KEY.FILE);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div
                  className={`groupField ${
                    isChecked ? "disabled" : "disable"
                  }`}>
                  <label className="body-2 d-block form-label mb-0">
                    {" "}
                    File upload{" "}
                  </label>
                  <p className="body-4 text-muted mb-1">
                    Upload a file of xlsx format containing information about
                    budget of your campaign.
                  </p>
                  <p className="body-4 text-muted mb-3">
                    (You can{" "}
                    <Link to="" onClick={handleTemplateDownload}>
                      download our template
                    </Link>
                    )
                  </p>
                  <BudgetFileUpload
                    file={file}
                    setFile={setFile}
                    error={error}
                    setError={setError}
                    budgetDetailsFromApi={budgetDetailsFromApi}
                    // mutateDeleteBudgetFile={mutateDeleteBudgetFile}
                    isFileUploadPending={isFileUploadPending}
                    isFileUploadSuccess={isFileUploadSuccess}
                    handleFileValidation={handleFileValidation}
                    isPendingFileValidation={isPendingFileValidation}
                  />
                </div>
              </div>
            </div>
            <div className="mb-4 customRadio-wrapper">
              <label className="customRadio form-check-label body-4">
                I do not have a budget created and will add one later.
                <input
                  type="radio"
                  name="radio"
                  checked={selectedBudgetMode === BUDGET_MODE_QUERY_KEY.NULL}
                  onChange={() =>
                    setSelectedBudgetMode(BUDGET_MODE_QUERY_KEY.NULL)
                  }
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div
              className={`customRadio-wrapper ${
                isFundraisingFetched &&
                !checkFundraisingGoal &&
                "groupField disabled"
              }`}>
              <label className="customRadio form-check-label body-4">
                I do not have a budget created and would like Campaign Brain to
                generate one based off my fundraising goal.
                <input
                  type="radio"
                  name="radio"
                  checked={selectedBudgetMode === BUDGET_MODE_QUERY_KEY.FUND}
                  onChange={() =>
                    setSelectedBudgetMode(BUDGET_MODE_QUERY_KEY.FUND)
                  }
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {isFundraisingFetched && !checkFundraisingGoal && (
              <div className="ps-4">
                <p className="text-italic fs-7">
                  Note: If you haven't entered a fundraising goal yet, this
                  option is currently unavailable. You can update it later in
                  Settings.
                </p>
              </div>
            )}
          </form>
        </div>
        <div className="card-footer shadow-6 d-flex gap-4 justify-content-end">
          <button
            className="btn btn-link text-primary btn-xs"
            onClick={handleSkip}>
            Not Now{" "}
          </button>
          <button
            className={`btn btn-primary rounded-pill btn-xs px-6 ${
              shouldDisableButton && "btn-disable"
            }`}
            onClick={handleFileUpload}>
            Next{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Budget;

import React from "react";
import Select from "react-select";
import CustomDdList from "../components/CustomDdList";

const MultiSelectDd = () => {
  // for select
  const options = [
    { value: "All voters", label: "302 voters" },
    { value: "Democrat", label: "187 voters" },
    { value: "Republicans", label: "133 voters" },
    { value: "Supporters", label: "0 voters" },
  ];

  return (
    
      

      <Select
        options={options}
        formatOptionLabel={CustomDdList}
        isMulti
        menuPosition="fixed"
        placeholder={<div> Assign candidates </div>}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "#0B45A5" : "#CBCBD8",
          }),
        }}
      />
    
  );
};

export default MultiSelectDd;

import React from "react";

const TopPrioritiesSkeleton = () => {
  return (
    <div className="card-taskList-body card-taskList-list">
      {/* top priorites list placeholder  */}
      <div className="">
        <div className="media media-sm py-2 placeholder-glow mb-3">
          <div className="media-header bg-neutral-n4 border-0 rounded-circle">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className=" placeholder col-9 placeholder-sm mb-0"></h2>
            <span className="placeholder col-4 placeholder-sm"></span>
          </div>
        </div>
        <div className="media media-sm py-2 placeholder-glow mb-3">
          <div className="media-header bg-neutral-n4 border-0 rounded-circle">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className=" placeholder col-9 placeholder-sm mb-0"></h2>
            <span className="placeholder col-4 placeholder-sm"></span>
          </div>
        </div>
        <div className="media media-sm py-2 placeholder-glow mb-3">
          <div className="media-header bg-neutral-n4 border-0 rounded-circle">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className=" placeholder col-9 placeholder-sm mb-0"></h2>
            <span className="placeholder col-4 placeholder-sm"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPrioritiesSkeleton;

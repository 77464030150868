import { useAuth0 } from "@auth0/auth0-react";
import FundraisingSuccessRoadMap from "./fundraising";
import VoterContactSuccessRoadMap from "./voter-contact";
import { useEffect, useState } from "react";
import axios from "axios";
import { IGetSurPlusOrDeficit, ISuccessRoadMap } from "../interface";
import InfoIconSecondarySmall from "img/svg/InfoIconSecondarySmall";
import CheckSuccess from "img/svg/checkSuccess";
import InfoIconDangerX16 from "img/svg/InfoIconDangerX16";
import { getReportValue } from "utils/analyticsReport";
import { getCurrentTimeStamp } from "utils/getDateTime";

const DEFAULT_VOTER_SUCCESS_ROAD_MAP = {
  value: 0,
  isOnTrack: false,
  hoursNeededToRemoveDeficit: 0,
};
const DEFAULT_FUNDRAISING_SUCCESS_ROAD_MAP = {
  value: 0,
  isOnTrack: false,
  hoursNeededToRemoveDeficit: 0,
};

const SuccessRoadMap: React.FC = () => {
  const { getIdTokenClaims } = useAuth0();
  const [voterContactSuccessRoadMap, setVoterContactSuccessRoadMap] =
    useState<ISuccessRoadMap>(DEFAULT_VOTER_SUCCESS_ROAD_MAP);
  const [fundRaisingSuccessRoadMap, setFundraisingSuccessRoadMap] =
    useState<ISuccessRoadMap>(DEFAULT_FUNDRAISING_SUCCESS_ROAD_MAP);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchSuccessRoadMaps() {
      const token = await getIdTokenClaims();
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/api/v1/analytics/success-roadmap-report?timestamp=${getCurrentTimeStamp()}`,
          {
            headers: {
              Authorization: `Bearer ${token?.__raw}`,
            },
          }
        );
        setVoterContactSuccessRoadMap(data?.voterContactSuccess);
        setFundraisingSuccessRoadMap(data?.fundraiseSuccess);
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    }
    fetchSuccessRoadMaps();
  }, []);

  return (
    <>
      <VoterContactSuccessRoadMap
        value={voterContactSuccessRoadMap.value}
        isOnTrack={voterContactSuccessRoadMap.isOnTrack}
        hoursNeededToRemoveDeficit={
          voterContactSuccessRoadMap.hoursNeededToRemoveDeficit
        }
        isLoading={isLoading}
      />
      <FundraisingSuccessRoadMap
        value={fundRaisingSuccessRoadMap.value}
        isOnTrack={fundRaisingSuccessRoadMap.isOnTrack}
        hoursNeededToRemoveDeficit={
          fundRaisingSuccessRoadMap.hoursNeededToRemoveDeficit
        }
        isLoading={isLoading}
      />
    </>
  );
};
export default SuccessRoadMap;

export const FirstWeekSuccessRoadMap = () => {
  return (
    <>
      <h1> 0 </h1>
      <div className="d-flex gap-2 flex-row align-items-center">
        <InfoIconSecondarySmall />
        <p className="m-0 text-muted fs-7"> No data available </p>
      </div>
    </>
  );
};

export const FirstWeekRecommendation = () => {
  return (
    <>
      <div className="d-flex gap-2 flex-row align-items-center">
        <InfoIconSecondarySmall />
        <p className="m-0 text-muted fs-8 fw-bold"> No data available </p>
      </div>
      <div className="card-dailyUpdate-content fundraise-card-dailyUpdate-content">
        <h5 className="body-5"> Recommendation </h5>
        <span> - </span>
      </div>
    </>
  );
};

export const GetSurPlusOrDeficit: React.FC<IGetSurPlusOrDeficit> = ({
  value,
  isOnTrack,
  hasAmount,
  isLoading,
}) => {
  let classes, text, Icon;
  if (isOnTrack) {
    classes = "text-success";
    Icon = <CheckSuccess />;
    text = "On Track";
  } else {
    classes = "text-danger";
    Icon = <InfoIconDangerX16 />;
    text = "Not on Track";
  }

  return (
    <>
      {isLoading ? (
        <>
          <div className="placeholder-glow w-100 text-muted">
            <div className="placeholder col-2"></div>
          </div>
          <div className="placeholder-glow w-100">
            <div className="placeholder col-3"></div>
          </div>
        </>
      ) : (
        <>
          <h1 className={classes}> {getReportValue(hasAmount, value)}</h1>
          <div className="d-flex gap-2 flex-row align-items-center">
            {Icon}
            <p className={`m-0 ${classes} fs-7`}> {text}</p>
          </div>
        </>
      )}
    </>
  );
};

export const RecommendationLoadingSkeleton = () => {
  return (
    <>
      <div className="placeholder-glow w-100 text-muted">
        <div className="placeholder col-3"></div>
      </div>
      <div className="card-dailyUpdate-content fundraise-card-dailyUpdate-content">
        <h5 className="body-5"> Recommendation </h5>
        <div className="placeholder-glow w-100 mb-3">
          <div className="placeholder col-12 py-4"></div>
        </div>
        <div className="placeholder-glow w-100">
          <div className="placeholder col-4"></div>
          <div className="placeholder col-12"></div>
          <div className="placeholder col-12"></div>
        </div>
      </div>
    </>
  );
};

import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  GoogleMap,
  DrawingManagerF,
  useLoadScript,
  MarkerF,
  PolygonF,
} from "@react-google-maps/api";
import { isPointInsidePolygon } from "geoenclave";
import CustomPolygonControl from "./CustomPolygonControl";
import LoaderCenter from "components/common/LoaderCenter";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { ISubmitTurfData, useTurfDetail } from "./useTurfDetail";
import UndoIcon from "img/svg/undoIcon";
import RedoIcon from "img/svg/redoIcon";
import CustomTooltip from "components/common/CustomTooltip";
import MapControl from "./MapControl";

export interface IVoterCoordinates {
  lat: number;
  lng: number;
  voterId: number;
}

export interface UniqueVoterCoordinate {
  lat: number;
  lng: number;
  voterIds: number[];
}

export const getUniqueVoterCoordinates = (
  voterCoordinates: IVoterCoordinates[]
): UniqueVoterCoordinate[] => {
  const coordMap = new Map<
    string,
    { lat: number; lng: number; voterIds: number[] }
  >();

  voterCoordinates.forEach(({ lat, lng, voterId }) => {
    const coordKey = `${lat},${lng}`;

    if (coordMap.has(coordKey)) {
      coordMap.get(coordKey)!.voterIds.push(voterId);
    } else {
      coordMap.set(coordKey, { lat, lng, voterIds: [voterId] });
    }
  });

  return Array.from(coordMap.values());
};

const TurfMap = () => {
  const ZOOM_LEVEL = 12;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? "",
    libraries: ["drawing"],
    mapIds: [process.env.REACT_APP_GOOGLE_MAP_ID ?? ""],
  });

  const {
    votersInUniverse,
    selectedUniverse,
    turfDetails,
    turfs,
    setTurfs,
    setShapes,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    highlightedPolygonIndex,
  } = useTurfDetail();

  const [turfCount, setTurfCount] = useState<number>(
    turfDetails?.totalItems ?? 0
  );
  const [poppedTurfs, setPoppedTurfs] = useState<ISubmitTurfData[]>([]);
  useEffect(() => {
    setTurfCount(turfDetails?.totalItems ?? 0);
  }, [turfDetails]);

  const voterCoordinates: IVoterCoordinates[] | undefined = votersInUniverse
    ?.map((voter) => {
      if (voter.geoCoordinates && voter.geoCoordinates.length === 2) {
        return {
          lat: voter.geoCoordinates[0],
          lng: voter.geoCoordinates[1],
          voterId: voter.id,
        };
      }
      return null;
    })
    .filter(
      (coordinates): coordinates is IVoterCoordinates => coordinates !== null
    );

  const savedPolygons = turfDetails?.items?.map((polygon) =>
    polygon.coordinates.map(([lat, lng]) => ({
      lat,
      lng,
    }))
  );

  const uniqueVoterCoordinates =
    voterCoordinates && getUniqueVoterCoordinates(voterCoordinates);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [drawingManager, setDrawingManager] =
    useState<google.maps.drawing.DrawingManager | null>(null);

  const center = useMemo(() => {
    if (voterCoordinates && voterCoordinates.length > 0) {
      return {
        lat: voterCoordinates[0].lat,
        lng: voterCoordinates[0].lng,
      };
    }
    return { lat: 0, lng: 0 };
  }, [voterCoordinates]);

  const onPolygonDraw = useCallback(
    (isActive: boolean) => {
      if (drawingManager) {
        drawingManager.setDrawingMode(
          isActive ? window.google.maps.drawing.OverlayType.POLYGON : null
        );
      }
    },
    [drawingManager]
  );

  const onMapLoad = useCallback((mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  }, []);

  const onDrawingManagerLoad = useCallback(
    (drawingManagerInstance: google.maps.drawing.DrawingManager) => {
      setDrawingManager(drawingManagerInstance);
    },
    []
  );

  const handleOverlayComplete = useCallback((e: any) => {
    if (e.type === "polygon") {
      const newShape = {
        type: e.type,
        data: e.overlay,
      };
      setShapes((prevShapes) => [...prevShapes, newShape]);
      setUndoStack((prevUndoStack) => [...prevUndoStack, newShape]);
      setRedoStack([]); // Clear redo stack on new draw
    }
  }, []);

  const handleUndo = useCallback(() => {
    if (undoStack.length === 0) return;

    const lastShape = undoStack[undoStack.length - 1];
    lastShape.data.setMap(null);
    setUndoStack((prevUndoStack) => prevUndoStack.slice(0, -1));
    setRedoStack((prevRedoStack) => [lastShape, ...prevRedoStack]);
    setShapes((prevShapes) =>
      prevShapes.filter((shape) => shape !== lastShape)
    );
    const turfsCopy = turfs.slice();
    const lastTurf = turfsCopy.pop();
    if (lastTurf)
      setPoppedTurfs((prevPoppedTurfs) => [...prevPoppedTurfs, lastTurf]);
    setTurfs(turfsCopy);
  }, [undoStack]);

  const handleRedo = useCallback(() => {
    if (redoStack.length === 0) return;

    const shapeToRestore = redoStack[0];
    shapeToRestore.data.setMap(map);
    setRedoStack((prevRedoStack) => prevRedoStack.slice(1));
    setUndoStack((prevUndoStack) => [...prevUndoStack, shapeToRestore]);
    setShapes((prevShapes) => [...prevShapes, shapeToRestore]);

    const poppedTurfCopy = poppedTurfs.slice();
    const turfToRestore = poppedTurfCopy.pop();
    setPoppedTurfs(poppedTurfCopy);
    if (turfToRestore) setTurfs([...turfs, turfToRestore]);
  }, [redoStack, map]);

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    const polygonCoordinates = polygon
      .getPath()
      .getArray()
      .map((path) => [path.lat(), path.lng()]);

    const coordinatesInsidePolygon = uniqueVoterCoordinates?.filter(
      (coordinates) =>
        isPointInsidePolygon(
          [coordinates.lat, coordinates.lng],
          polygonCoordinates
        )
    );
    const voterIds = coordinatesInsidePolygon
      ?.map((item) => item.voterIds)
      .flat();
    const doors = coordinatesInsidePolygon?.map((item) => [item.lat, item.lng]);
    const candidateId = localStorage.getItem(CANDIDATE_ID);
    if (selectedUniverse?.id) {
      const turfSubmitData: ISubmitTurfData = {
        name: "turf" + (turfCount + 1),
        candidateId,
        voterIds,
        doors: doors,
        coordinates: polygonCoordinates,
        doorCount: doors?.length ?? 0,
        voterCount: voterIds?.length ?? 0,
        isContacted: false,
        universeId: selectedUniverse?.id,
      };
      setTurfCount(turfCount + 1);
      setTurfs([...turfs, turfSubmitData]);
    }
    if (map) {
      map.panTo(center);
      map.setZoom(ZOOM_LEVEL);
    }
  };

  const drawingManagerOptions = {
    drawingControl: false, // Disable the default drawing control
    polygonOptions: {
      fillColor: "#ff0000",
      fillOpacity: 0.2,
      strokeWeight: 2,
      strokeColor: "#ff0000",
      clickable: true,
      editable: true,
      draggable: false,
    },
  };
  const polygonOptions = (index: number) => ({
    fillColor: index === highlightedPolygonIndex ? "blue" : "black",
    fillOpacity: 0.4,
    strokeColor: index === highlightedPolygonIndex ? "blue" : "black",
    strokeOpacity: 1,
    strokeWeight: 2,
  });

  return isLoaded ? (
    <GoogleMap
      zoom={ZOOM_LEVEL}
      center={center}
      mapContainerStyle={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      options={{
        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
        streetViewControl: false,
      }}
      onLoad={onMapLoad}
    >
      {uniqueVoterCoordinates?.map((marker, index) => (
        <MarkerF position={marker} key={index} />
      ))}
      {savedPolygons?.map((polygon, index) => (
        <PolygonF key={index} paths={polygon} options={polygonOptions(index)} />
      ))}
      <DrawingManagerF
        onLoad={onDrawingManagerLoad}
        options={drawingManagerOptions}
        onOverlayComplete={handleOverlayComplete}
        onPolygonComplete={onPolygonComplete}
      />
      <CustomPolygonControl onPolygonDraw={onPolygonDraw} map={map} />

      <MapControl position="RIGHT_CENTER">
        <div className="undoRedo shadow-6">
          <CustomTooltip content={undoStack?.length ? "Undo" : ""}>
            <button
              className={`btn border-0 py-1 px-2 bg-white ${
                undoStack.length === 0 ? "disabled" : ""
              }`}
              onClick={handleUndo}
              disabled={undoStack.length === 0}
            >
              <UndoIcon />
            </button>
          </CustomTooltip>
          <CustomTooltip content={redoStack.length ? "Redo" : ""}>
            <button
              className={`btn border-0 py-1 px-2 bg-white ${
                redoStack.length === 0 ? "disabled" : ""
              }`}
              onClick={handleRedo}
              disabled={redoStack.length === 0}
            >
              <RedoIcon />
            </button>
          </CustomTooltip>
        </div>
      </MapControl>
    </GoogleMap>
  ) : (
    <LoaderCenter />
  );
};

export default TurfMap;

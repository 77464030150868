import React from "react";

const CustomDdList = () => {
  return (
    <div className="customDDList">
      <img
        src={require("../../img/avatar.png")}
        className="img-fluid"
        alt=""
      />

      <div className="customDDList-body">
        <span className="customDDList-title"> All voters </span>
        <span className="customDDList-subtitle"> 302 voters </span>
      </div>
    </div>
  );
};

export default CustomDdList;

import InfoIconSecondary from "img/svg/InfoIconSecondary";
import HelpIcon from "img/svg/helpIcon";
import { useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import useCallLogsDetails from "./useCallLogsDetails";
import { getDateTime } from "utils/formatDate";
import { uppercaseFirstLetter } from "utils/stringManipulate";
import { formatSeconds } from "utils/getDateTime";
import Loader from "components/common/Loader";
import {
  CURRENT_BILLING_TIP,
  ITEMS_PER_PAGE,
  TOTAL_CALL_TIME_TIP,
  TOTAL_COST_TIP,
} from "./callLogs.d";
import Spinner from "templates/components/Spinner";
import CustomTooltip from "../../../common/CustomTooltip";

const CallLogs = () => {
  const callLogsScrollRef = useRef<HTMLDivElement>(null);

  const {
    callLogsData,
    totalCallCost,
    totalCallDuration,
    icCallLogsLoading,
    totalCallLogs,
    callLogsLimit,
    setCallLogsLimit,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isCsvLoading,
    csvData,
    currentBillingData,
    isCurrentBillingLoading,
  } = useCallLogsDetails();

  const handleDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const loadMore = (scrollDiv: HTMLDivElement) => {
      if (scrollDiv) {
        const scrollHeight = scrollDiv.scrollHeight;
        const scrollTop = scrollDiv.scrollTop;
        const clientHeight = scrollDiv.clientHeight;
        const toBottom = scrollHeight - clientHeight - scrollTop;
        if (toBottom < 10 && totalCallLogs > callLogsLimit) {
          setCallLogsLimit(callLogsLimit + ITEMS_PER_PAGE);
        }
      }
    };

    const callLogsDiv = callLogsScrollRef.current;
    if (callLogsDiv) {
      callLogsDiv.addEventListener("scroll", () => loadMore(callLogsDiv));
    }

    return () => {
      if (callLogsDiv)
        callLogsDiv.removeEventListener("scroll", () => loadMore(callLogsDiv));
    };
  });

  const getStatusBackgroundClassName = (status: string) => {
    switch (status) {
      case "completed":
        return "badge-success";
      case "busy":
        return "badge-warning";
      case "no-answer":
        return "badge-primary5";
      case "failed":
        return "badge-danger";
      default:
        return "badge-secondary";
    }
  };

  const getStatusTextClassName = (status: string) => {
    switch (status) {
      case "completed":
        return "text-success icon-phone-filled";
      case "busy":
        return "text-warning icon-phone-missed-call";
      case "no-answer":
        return "text-primary icon-phone-missed-call";
      case "failed":
        return "text-danger icon-phone-missed-call";
      default:
        return "text-primary icon-phone-filled";
    }
  };

  return (
    <>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="settings">
            <div className="settings-header d-flex justify-content-between align-items-end">
              <div className="">
                <h4> Logs </h4>
                <p className="body-4 text-muted">
                  {" "}
                  View the history for contacting voters and donors.{" "}
                </p>
              </div>

              <button
                className="btn btn-info rounded-pill btn-xs px-4"
                data-bs-toggle="modal"
                data-bs-target="#exportCsvModal"
              >
                {" "}
                Export CSV{" "}
              </button>
            </div>

            <div className="settings-body">
              <div className="voterDetail-info">
                <hr className="mt-3 mb-3" />

                <div className="row mt-4">
                  <div className="col-md-3 col-12 mb-3">
                    <div className="card card-flush h-100 border-1">
                      <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title"> Total Call Time </h4>
                        <div className="card-info">
                          <CustomTooltip content={TOTAL_CALL_TIME_TIP}>
                            <HelpIcon />
                          </CustomTooltip>
                        </div>
                      </div>
                      <div className="card-body pt-1 d-flex flex-column justify-content-end">
                        {icCallLogsLoading ? (
                          <div className="placeholder-glow w-100">
                            <div className="placeholder col-6 py-3"></div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-end">
                            <h1 className="mb-0 me-2">
                              {totalCallDuration
                                ? formatSeconds(totalCallDuration)
                                : "0:0:0"}
                            </h1>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-12 mb-3">
                    <div className="card card-flush h-100 border-1">
                      <div className="card-header d-flex justify-content-between">
                        <h4 className="card-title"> Total Cost </h4>
                        <div className="card-info">
                          <CustomTooltip content={TOTAL_COST_TIP}>
                            <HelpIcon />
                          </CustomTooltip>
                        </div>
                      </div>
                      <div className="card-body pt-1 d-flex flex-column justify-content-end">
                        {icCallLogsLoading ? (
                          <div className="placeholder-glow w-100">
                            <div className="placeholder col-6 py-3"></div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-end">
                            <h1 className="mb-0 me-2">
                              $<span className=""> {totalCallCost ?? 0} </span>
                            </h1>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-12 mb-3">
                    <div className="card card-flush h-100 border-1">
                      <div className="card-header d-flex justify-content-between">
                        <div>
                          <h4 className="card-title">Current Billing Period</h4>
                          <span className="fs-8 text-muted">
                            {currentBillingData
                              ? getDateTime(
                                  new Date(
                                    currentBillingData.billingPeriodStart
                                  )
                                ).split(",")[0] +
                                " - " +
                                getDateTime(
                                  new Date(currentBillingData.billingPeriodEnd)
                                ).split(",")[0]
                              : isCurrentBillingLoading
                              ? "Loading..."
                              : "No date range"}
                          </span>
                        </div>

                        <div className="card-info">
                          <CustomTooltip content={CURRENT_BILLING_TIP}>
                            <HelpIcon />
                          </CustomTooltip>
                        </div>
                      </div>
                      <div className="card-body pt-1 d-flex flex-column justify-content-end">
                        {isCurrentBillingLoading ? (
                          <div className="placeholder-glow w-100">
                            <div className="placeholder col-6 py-3"></div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-end">
                            <h1 className="mb-0 me-2">
                              ${" "}
                              <span className="">
                                {currentBillingData?.totalAmount ?? 0}
                              </span>
                            </h1>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="card card-fullHeight scroll-y mt-3 border-1 card-callLogs"
                      ref={callLogsScrollRef}
                    >
                      {callLogsData?.length > 0 ? (
                        <table className="table table-full table-eq-height table-hover">
                          <thead>
                            <tr>
                              <th>Date and Time </th>
                              <th>Caller Id</th>
                              <th> Receiver ID</th>
                              <th> Call Type </th>
                              <th> Status </th>
                              <th> Voicemail </th>
                              <th> Duration </th>
                              <th> Cost ($) </th>
                            </tr>
                          </thead>
                          <tbody>
                            {callLogsData?.map((callLog, index) => (
                              <tr key={index}>
                                <td>
                                  <span>
                                    {getDateTime(new Date(callLog.createdAt))}
                                  </span>
                                </td>
                                <td>
                                  <span> {callLog.from} </span>
                                  <span className="d-block fs-8 font-regular text-muted">
                                    {callLog.callInitiator}
                                  </span>
                                </td>
                                <td>
                                  <span> {callLog.to} </span>
                                  <span className="d-block fs-8 font-regular text-muted">
                                    {callLog.callReceiver}
                                  </span>
                                </td>
                                {callLog.callType ? (
                                  <td>{callLog.callType} </td>
                                ) : (
                                  <td>-</td>
                                )}
                                <td>
                                  <span
                                    className={`badge ${getStatusBackgroundClassName(
                                      callLog.status
                                    )}
                                      font-semibold rounded-pill`}
                                  >
                                    <span
                                      className={` fs-8 ${getStatusTextClassName(
                                        callLog.status
                                      )} `}
                                    >
                                      {" "}
                                    </span>{" "}
                                    <span className="badge-label">
                                      {uppercaseFirstLetter(callLog.status)}
                                    </span>
                                  </span>

                                  {/* <span className="d-block"> - </span>  */}
                                </td>
                                <td>
                                  <span className="d-flex align-items-center gap-2">
                                    <span
                                      className={`icon-close-circle fs-6 ${
                                        callLog.isVoiceMail
                                          ? "text-success"
                                          : "text-danger"
                                      }`}
                                    >
                                      {" "}
                                    </span>
                                    {callLog.isVoiceMail ? "Yes" : "No"}
                                  </span>
                                </td>
                                <td>
                                  {formatSeconds(callLog.duration)}
                                  {/* <span className="d-block"> - </span> */}
                                </td>
                                <td className="text-center">
                                  $ {callLog.price}
                                </td>
                              </tr>
                            ))}

                            {totalCallLogs > callLogsLimit && (
                              <tr>
                                <td colSpan={8} className="border-0">
                                  <Loader />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : // If no call logs data
                      icCallLogsLoading ? (
                        <div className="m-5 p-5">
                          <Loader />
                        </div>
                      ) : (
                        <div className="noData d-flex gap-2 flex-row align-items-center">
                          <InfoIconSecondary />
                          <p className="m-0 text-muted fs-7">
                            {" "}
                            No logs available{" "}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Date range Modal */}
      <div
        className="modal fade"
        id="exportCsvModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header border-1">
              <h5 className="modal-title body-2 mb-0">
                {" "}
                Export call logs as CSV{" "}
              </h5>
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body">
              <span className="body-3">
                Export call logs as a CSV to quickly share call details .
              </span>
              <div className="mb-3 required">
                <label htmlFor="inputCuPass" className="form-label">
                  Date range
                </label>
                <div className="custom-datepicker multiplePicker">
                  <DatePicker
                    className="form-control"
                    renderCustomHeader={({
                      monthDate,
                      customHeaderCount,
                      decreaseMonth,
                      increaseMonth,
                    }) => (
                      <div>
                        <button
                          aria-label="Previous Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--previous"
                          }
                          style={
                            customHeaderCount === 1
                              ? { visibility: "hidden" }
                              : undefined
                          }
                          onClick={decreaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                            }
                          >
                            {"<"}
                          </span>
                        </button>
                        <span className="react-datepicker__current-month">
                          {monthDate.toLocaleString("en-US", {
                            month: "long",
                            year: "numeric",
                          })}
                        </span>
                        <button
                          aria-label="Next Month"
                          className={
                            "react-datepicker__navigation react-datepicker__navigation--next"
                          }
                          style={
                            customHeaderCount === 0
                              ? { visibility: "hidden" }
                              : undefined
                          }
                          onClick={increaseMonth}
                        >
                          <span
                            className={
                              "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                            }
                          >
                            {">"}
                          </span>
                        </button>
                      </div>
                    )}
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    monthsShown={2}
                    filterDate={(date) => date <= new Date()}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer border-1">
              <button
                className="btn btn-link text-primary btn-xs rounded-pill"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              {csvData && !isCsvLoading ? (
                <CSVLink
                  data={csvData}
                  filename={"call-logs.csv"}
                  target="_blank"
                  className="btn btn-primary rounded-pill btn-xs px-6"
                >
                  Export CSV
                </CSVLink>
              ) : (
                <button
                  className="btn btn-primary rounded-pill btn-xs px-6"
                  disabled
                >
                  Export CSV
                  {isCsvLoading && <Spinner />}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallLogs;

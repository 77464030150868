import { useEffect, useState } from "react";
import OnboardingForm, {
  IS_ONBOARDING_COMPLETED,
} from "../forms/multi-step-forms/onboarding/OnboardingForm";
import Welcome from "templates/pages/Welcome";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "utils/useDocumentTitle";
import { ONBOARDING_STATUS } from "./Homepage";

const Onboarding: React.FC = () => {
  const navigate = useNavigate();
  const retrivedOnboardingStatus = localStorage.getItem(ONBOARDING_STATUS);

  const retrivedIsOnboardingCompleted = localStorage.getItem(
    IS_ONBOARDING_COMPLETED
  );

  const [onboardingStatus] = useState<boolean>(
    retrivedOnboardingStatus && JSON.parse(retrivedOnboardingStatus)
  );

  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<boolean>(
    retrivedIsOnboardingCompleted
      ? JSON.parse(retrivedIsOnboardingCompleted)
      : false
  );

  useEffect(() => {
    if (onboardingStatus) {
      navigate("/dashboard");
    }
  }, [onboardingStatus, navigate]);

  return (
    <>
      {useDocumentTitle("CampaignBrain | Onboarding")}
      {onboardingStatus === true ? (
        <>Loading.</>
      ) : isOnboardingCompleted ? (
        <Welcome />
      ) : (
        <OnboardingForm setIsOnboardingCompleted={setIsOnboardingCompleted} />
      )}
    </>
  );
};

export default Onboarding;

import React from 'react'

const CheckIconDark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M20.6234 6.37658C21.1255 6.87868 21.1255 7.69275 20.6234 8.19485L10.3377 18.4806C9.83561 18.9827 9.02154 18.9827 8.51943 18.4806L3.37658 13.3377C2.87447 12.8356 2.87447 12.0215 3.37658 11.5194C3.87868 11.0173 4.69275 11.0173 5.19485 11.5194L9.42857 15.7532L18.8051 6.37658C19.3073 5.87447 20.1213 5.87447 20.6234 6.37658Z" fill="#3B3B4F"/>
</svg>
  )
}

export default CheckIconDark

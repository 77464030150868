import { ICandidateState } from "../reducers/iCandidateReducer.d";
import { AddCandidateAction, CandidateActionTypes, RemoveCandidateAction, UpdateCandidateAction } from "./iCandidateActions.d";


export const addCandidate = (
  candidate: ICandidateState
): AddCandidateAction => ({
  type: CandidateActionTypes.ADD_CANDIDATE,
  payload: candidate,
});

export const removeCandidate = (
  candidateId: ICandidateState["id"]
): RemoveCandidateAction => ({
  type: CandidateActionTypes.REMOVE_CANDIDATE,
  payload: candidateId,
});

export const updateCandidate = (
  candidate: ICandidateState
): UpdateCandidateAction => ({
  type: CandidateActionTypes.UPDATE_CANDIDATE,
  payload: candidate,
});

import InfoIconWarning from "img/svg/InfoIconWarning";

const VotersListAlert = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <InfoIconWarning />
          <span className="ms-2"> Voter list will be uploaded soon. </span>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          >
            <span className="icon-close"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VotersListAlert;

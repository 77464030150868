import React, { useEffect, useState } from "react";
import CloseIcon from "img/svg/closeIcon";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import TrashIconDanger from "img/svg/TrashIconDanger";
import EditIconPrimary from "img/svg/EditIconPrimary";
import SidePanelPlaceholder from "./SidePanelPlaceholder";
import { IVolunteer, IVolunteerBasic, formatDate } from "components/pages/volunteers/useVolunteerList";
import { useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";

interface IPropsType {
  selectedVolunteerForDetailView: IVolunteer | null
  setSelectedVolunteerForDetailView: React.Dispatch<React.SetStateAction<IVolunteer | null>>
  setSelectedVolunteerForUpdation: React.Dispatch<React.SetStateAction<IVolunteerBasic | null>>
  setSelectedVolunteerIdForDelete: React.Dispatch<React.SetStateAction<number | null>>
}

export interface IVolunteerActivityData {
  date: string | null
  timeSpent: string | null
  phoneCalls: string | null
  doorKnocks: string | null
  phoneCallTimeSpent: number
  doorKnockTimeSpent: number
}

const SidePanelDetails = ({
  selectedVolunteerForDetailView,
  setSelectedVolunteerForDetailView,
  setSelectedVolunteerForUpdation,
  setSelectedVolunteerIdForDelete
}: IPropsType) => {
  const authAxios = CreateAuthAxiosInstance();

  const {
    id,
    isArchived,
    createdAt,
    updatedAt,
    email,
    primaryPhoneNumber,
    auth0Id,
    candidateId,
    fullName,
    status,
    lastActiveAt,
    invitedAt
  } = selectedVolunteerForDetailView || {}

  const [finalVolunteerActivityList, setFinalVolunteerActivityList] = useState<IVolunteerActivityData[] | null>(null)

  const {
    data: volunteerActivityData,
    error: volunteerActivityError,
    isFetching,
    status: volunteerActivityStatus,
    refetch
  } = useQuery({
    queryKey: ['volunteerList', id],
    queryFn: () => authAxios.get(`api/v1/volunteers/${id}/contact-stats`),
    enabled: !!id,
  })

  useEffect(() => {
    if (!volunteerActivityData?.data?.length) {
      setFinalVolunteerActivityList(null)
      return
    }
    setFinalVolunteerActivityList(volunteerActivityData.data)
  }, [volunteerActivityData])

  function handleEdit() {
    if (!(id && fullName && email)) return

    setSelectedVolunteerForUpdation({
      id: id,
      fullName: fullName,
      email: email,
      primaryPhoneNumber: primaryPhoneNumber || null
    })
  }

  function handleDelete() {
    if (!id) return

    setSelectedVolunteerIdForDelete(id)
  }

  return (
    <aside className={`sidePanel control-sidebar shadow-4 ${selectedVolunteerForDetailView ? 'open' : ''}`}>
      <div className="sidePanel-header shadow-4">
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="location-info">
            <div className="location-content">
              <div className="voter-location"> Volunteer Details </div>
            </div>
          </div>
          <div
            className="panel-close"
            onClick={() => setSelectedVolunteerForDetailView(null)}>
            <CloseIcon />
          </div>
        </div>
      </div>
      <div className="sidePanel-body scroll-y">
        {volunteerActivityStatus === 'pending' ? (
          <SidePanelPlaceholder />
        ) : (
          <>
            <div className="volunteerDetails mb-4">
              <div className="volunteerDetails-header">
                <h5 className="heading-5 mb-0 fw-bold">
                  {fullName || '-'}
                </h5>
                <div className="volunteerDetails-header-toolbox">
                  <button
                    className="btn text-primary p-2 fs-7"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    onClick={handleEdit}
                  >
                    <EditIconPrimary /> Edit{" "}
                  </button>
                  <button
                    className="btn text-danger p-2 fs-7"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={handleDelete}
                  >
                    <TrashIconDanger /> Delete{" "}
                  </button>
                </div>
              </div>
              <div className="volunteerDetails-body">
                <div className="media media-sm py-2">
                  <div className="media-header bg-neutral-n4 border-0">
                    <span className="icon-mail"></span>
                  </div>
                  <div className="media-body">
                    <h2 className="mb-0 body-4 text-muted lh-1">Email</h2>
                    <span className="d-block body-4 mb-0">
                      {email || '-'}
                    </span>
                  </div>
                </div>
                <div className="media media-sm py-2">
                  <div className="media-header bg-neutral-n4 border-0">
                    <span className="icon-phone-filled"></span>
                  </div>
                  <div className="media-body">
                    <h2 className="mb-0 body-4 text-muted lh-1"> Phone no.</h2>
                    <span className="d-block body-4 mb-0">
                      {primaryPhoneNumber || '-'}
                    </span>
                  </div>
                </div>
                <div className="media media-sm py-2">
                  <div className="media-header bg-neutral-n4 border-0">
                    <span className="icon-user"></span>
                  </div>
                  <div className="media-body">
                    <h2 className="mb-0 body-4 text-muted lh-1"> Status </h2>
                    {status === 'Active' &&
                      <div>
                        <span className="badge badge-success rounded-pill">
                          Active
                        </span>
                        <span className="d-block fs-10 text-muted">
                          Joined on: {formatDate(createdAt)}
                        </span>
                      </div>
                    }
                    {status === 'Pending' &&
                      <div>
                        <span className="badge badge-warning rounded-pill">
                          Pending
                        </span>
                        <span className="d-block fs-10 text-muted">
                          Invitation sent: {formatDate(invitedAt)}
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <div className="media media-sm py-2">
                  <div className="media-header bg-neutral-n4 border-0">
                    <span className="icon-calendar1"></span>
                  </div>
                  <div className="media-body">
                    <h2 className="mb-0 body-4 text-muted lh-1">
                      Last Active Date
                    </h2>
                    <span className="d-block body-4 mb-0">
                      {lastActiveAt ? formatDate(lastActiveAt) : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <h4 className="body-2"> Activities </h4>
            {!finalVolunteerActivityList?.length ? (
              <div className="d-flex gap-2 flex-row align-items-center">
                <InfoIconSecondary />
                <p className="m-0 text-muted fs-7">No activities available</p>
              </div>
            ) : (
              finalVolunteerActivityList?.map((item, index) => {
                const {
                  date,
                  doorKnockTimeSpent,
                  doorKnocks,
                  phoneCallTimeSpent,
                  phoneCalls,
                  timeSpent
                } = item
                return (
                  <>
                    <div className="volunteerActivities-body">
                      <div className="volunteerActivities-body-item mb-4">
                        <span className="body-4 text-muted">
                          {formatDate(date)}
                        </span>

                        <div className="media media-sm py-2">
                          <div className="media-header bg-transparent border-0">
                            <span className="icon-by-phone" />
                          </div>
                          <div className="media-body">
                            <h2 className="mb-0 body-5 lh-1"> Voters Contacted By Phone </h2>
                            <span className="d-block body-4 mb-0 text-success">
                            </span>
                            <p className="text-muted fs-8 mb-0 mt-1">
                              {/* {!!phoneCallTimeSpent &&
                                <>
                                  {convertHoursToHoursMinutes(phoneCallTimeSpent)}
                                </>
                              } */}
                              <span className="dot bg-neutral-n3 ms-3" />
                              {`${phoneCalls} Voter${Number(phoneCalls) > 1 ? 's' : ''}`}
                            </p>
                          </div>
                        </div>
                        <div className="media media-sm py-2">
                          <div className="media-header bg-transparent border-0">
                            <span className="icon-knock"></span>
                          </div>
                          <div className="media-body">
                            <h2 className="mb-0 body-5 lh-1"> Voters Contacted By Door to Door </h2>
                            <span className="d-block body-4 mb-0 text-success">
                            </span>
                            <p className="text-muted fs-8 mb-0 mt-1">
                              {/* {!!phoneCallTimeSpent &&
                                <>
                                  {convertHoursToHoursMinutes(doorKnockTimeSpent)}
                                </>
                              } */}
                              <span className="dot bg-neutral-n3 ms-3" />
                              {`${doorKnocks} Voter${Number(doorKnocks) > 1 ? 's' : ''}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            )}
          </>
        )}
      </div>
    </aside >
  );
};

export default SidePanelDetails;


// Calculate hours, minutes, and remaining seconds
function convertHoursToHoursMinutes(timeInHour: number) {
  const wholeHours = Math.floor(timeInHour)
  const minutes = Math.round((timeInHour - wholeHours) * 60)

  const hourStr = wholeHours !== 0 ? (`${wholeHours} hr${wholeHours !== 1 ? 's' : ''}`) : ''
  const minuteStr = minutes !== 0 ? (`${minutes} min${minutes !== 1 ? 's' : ''}`) : ''

  if (hourStr && minuteStr) return `${hourStr} ${minuteStr}`

  if (hourStr) return hourStr;

  return minuteStr;
}
import TrashIcon from "img/svg/TrashIcon";
import CheckSuccess from "img/svg/checkSuccess";
import FileIcon from "img/svg/fileIcon";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FileUpload from "templates/components/FileUpload";
import {
  ISumitData,
  dateFormatter,
  useProfessionalDetails,
} from "./useProfessionalDetils";
import { useFieldArray, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import UploadFile from "./UploadFile";
import AlertBanner from "templates/components/AlertBanner";
import useCheckDashboardCompletedStatus from "utils/useDashboardCompletedStatus";

export interface IProfessionalDetails {
  resume: string;
  displayProfileUrl: string;
  motivation: string;
  proposal: string;
  strengths: {
    str: string;
  }[];
}

const ProfessionalDetails = () => {
  const {
    editable,
    setEditable,
    professionalData,
    onFinalSubmit,
    handleFileChange,
    uploadedfile,
    isValidFileType,
    setUploadedFile,
    resumeFileData,
    setIsDeleted,
    setStoreToDeletefile,
    DeleteFile,
    isDeleted,
    storeToDeletefile,
    checkFile,
    updateProfessionalDataStatus,
  } = useProfessionalDetails();

  const { userData } = useCheckDashboardCompletedStatus();

  const form = useForm<IProfessionalDetails>({
    defaultValues: {
      strengths: [
        {
          str: "",
        },
      ],
    },
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: errors,
    watch,
  } = form;

  const watchAllFiles = watch();

  const motivationCharLength = watchAllFiles?.motivation?.length ?? 0;

  const proposalCharLength = watchAllFiles?.proposal?.length ?? 0;

  const checkEmptyFiles =
    watchAllFiles?.displayProfileUrl?.trim() === "" &&
    watchAllFiles?.motivation?.trim() === "" &&
    watchAllFiles?.proposal?.trim() === "" &&
    watchAllFiles?.strengths?.length === 1 &&
    watchAllFiles?.strengths[0]?.str?.trim() === "";

  const isSaveButtonDisabled = checkEmptyFiles || !checkFile;

  const handleEdit = () => {
    setEditable(true);
  };

  async function handleDeleteMutation() {
    await DeleteFile(storeToDeletefile[0]?.id);
  }

  const onSubmit = (data: IProfessionalDetails) => {
    console.log("data", data);

    const filteredStrengths = data?.strengths?.filter(
      (strength) => strength?.str?.trim() !== ""
    );
    data.strengths = filteredStrengths;

    const { displayProfileUrl, motivation, proposal, strengths } = data;
    const modifiedData = {
      displayProfileUrl,
      motivation,
      proposal,
      strengths,
    };

    if (isDeleted === true) {
      handleDeleteMutation();
    }
    onFinalSubmit(modifiedData);
  };

  const { fields, append } = useFieldArray({
    name: "strengths",
    control,
  });

  function handleCancel() {
    setEditable(false);
    if (professionalData) {
      handleSetValues();
      handleStrengthSetValues();
    }

    if (resumeFileData[0]?.fileName !== uploadedfile?.name) {
      setUploadedFile(resumeFileData);
    }
    if (uploadedfile && resumeFileData?.length <= 0) {
      setUploadedFile(null || undefined);
    }
  }

  function handleSetValues() {
    Object.entries(professionalData)?.forEach(([key, value]: any) => {
      setValue(key, value);
    });
  }

  function handleStrengthSetValues() {
    professionalData?.strengths?.forEach((strength: string, index: number) => {
      setValue(`strengths.${index}.str`, strength);
    });
  }

  useEffect(() => {
    if (professionalData) {
      handleSetValues();
      handleStrengthSetValues();
    }
  }, [professionalData, setValue]);

  return (
    <div className="row mt-5">
      <div className="col-md-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="settings">
            <div className="settings-header d-flex justify-content-between">
              <div className="">
                <h4> Candidate story </h4>
                <p className="body-4 text-muted">
                  View or edit your professional details
                </p>
              </div>
              {userData?.isDashboardSetupCompleted === true && (
                <div>
                  {editable ? (
                    <>
                      {" "}
                      <button
                        className="btn btn-link btn-xs rounded-pill"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      {updateProfessionalDataStatus !== "pending" ? (
                        <button
                          className="btn btn-link btn-xs rounded-pill"
                          type="submit"
                          disabled={isSaveButtonDisabled}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn btn-link btn-xs rounded-pill disabled"
                          type="submit"
                        >
                          Saving...
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className="btn btn-link btn-xs px-1 py-0"
                      onClick={handleEdit}
                    >
                      {" "}
                      <i className="icon-edit"> </i> Edit details
                    </button>
                  )}
                </div>
              )}
            </div>

            <div className="settings-body">
              {userData?.isDashboardSetupCompleted === false ? (
                <div className="mt-4">
                  <AlertBanner />
                </div>
              ) : (
                <div className="professional-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Resume </span>
                    </div>
                    <div className="col-md-8">
                      <UploadFile
                        editable={editable}
                        handleFileChange={handleFileChange}
                        uploadedFile={uploadedfile}
                        setUploadedFile={setUploadedFile}
                        isValidFileType={isValidFileType}
                        setIsDeleted={setIsDeleted}
                        setStoreToDeletefile={setStoreToDeletefile}
                      />
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7">
                        {" "}
                        Add your Linkedin account{" "}
                      </span>
                    </div>
                    <div className="col-md-8">
                      {editable ? (
                        <input
                          type="text"
                          className="form-control"
                          id="InputLinked"
                          aria-describedby="emailHelp"
                          placeholder=""
                          {...register("displayProfileUrl")}
                        />
                      ) : professionalData?.displayProfileUrl ? (
                        <Link
                          to={`https://${professionalData?.displayProfileUrl}`}
                          className="body-4 text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {professionalData?.displayProfileUrl}
                        </Link>
                      ) : (
                        <span className="body-4 text-muted"> Not Provided</span>
                      )}
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7">
                        {" "}
                        Why are you running for office?{" "}
                      </span>
                    </div>
                    <div className="col-md-8">
                      {editable ? (
                        <div className="position-relative">
                          <textarea
                            className="form-control"
                            id="Textarea1"
                            rows={3}
                            // maxLength={200}
                            {...register("motivation")}
                          ></textarea>
                          {/* <span className="word-count">
                            {" "}
                            {motivationCharLength} / 200{" "}
                          </span> */}
                        </div>
                      ) : professionalData?.motivation ? (
                        <span className="body-4">
                          {professionalData?.motivation}
                        </span>
                      ) : (
                        <span className="body-4 text-muted"> Not Provided</span>
                      )}
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7">
                        {" "}
                        What are your main policy proposals, ideas, and
                        platforms?{" "}
                      </span>
                    </div>
                    <div className="col-md-8">
                      {editable ? (
                        <div className="position-relative">
                          <textarea
                            className="form-control"
                            id="Textarea1"
                            rows={3}
                            // maxLength={200}
                            {...register("proposal")}
                          ></textarea>

                          {/* <span className="word-count">
                            {" "}
                            {proposalCharLength}/ 200{" "}
                          </span> */}
                        </div>
                      ) : professionalData?.proposal ? (
                        <span className="body-4">
                          {professionalData?.proposal}
                        </span>
                      ) : (
                        <span className="body-4 text-muted"> Not Provided</span>
                      )}
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7">
                        Your biggest strength{" "}
                      </span>
                    </div>
                    <div className="col-md-8">
                      {editable ? (
                        <>
                          {fields.map((field, index) => {
                            const strengthLength =
                              watchAllFiles?.strengths[index]?.str?.length ?? 0;
                            return (
                              <div
                                key={field.id}
                                className="position-relative mb-2"
                              >
                                <textarea
                                  className="form-control"
                                  id={`Textarea${index + 1}`}
                                  rows={2}
                                  // maxLength={200}
                                  {...register(
                                    `strengths.${index}.str` as const
                                  )}
                                ></textarea>
                                {/* <span className="word-count">
                                  {" "}
                                  {strengthLength} / 200{" "}
                                </span> */}
                              </div>
                            );
                          })}

                          {/* Add new strength */}
                          <button
                            type="button"
                            className="btn btn-link text-primary mt-3"
                            onClick={() => {
                              append({ str: "" });
                              setEditable((prevEditable) => prevEditable);
                            }}
                          >
                            {" "}
                            <span className="icon-plus me-2"></span> Add another
                            strength{" "}
                          </button>
                        </>
                      ) : professionalData?.strengths ? (
                        <>
                          <ul className="list-unstyled check-list">
                            {professionalData?.strengths?.map(
                              (strength: any, index: any) => (
                                <li key={index}>
                                  {strength && (
                                    <>
                                      <span className="check-icon"></span>
                                      <span className="body-4">{strength}</span>
                                    </>
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        </>
                      ) : (
                        <span className="body-4 text-muted"> Not Provided</span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* <DevTool control={control} /> */}
      </div>
    </div>
  );
};

export default ProfessionalDetails;

import React from 'react'

const DisabledDangerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 16" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M7.9974 2.66536C6.89283 2.66536 5.9974 3.5608 5.9974 4.66536C5.9974 5.76993 6.89283 6.66536 7.9974 6.66536C9.10197 6.66536 9.9974 5.76993 9.9974 4.66536C9.9974 3.56079 9.10197 2.66536 7.9974 2.66536ZM4.66406 4.66536C4.66406 2.82442 6.15645 1.33203 7.9974 1.33203C9.83835 1.33203 11.3307 2.82442 11.3307 4.66536C11.3307 6.50631 9.83835 7.9987 7.9974 7.9987C6.15645 7.9987 4.66406 6.50631 4.66406 4.66536ZM4.0527 13.332H11.9421C11.6247 11.4402 9.97939 9.9987 7.9974 9.9987C6.0154 9.9987 4.37009 11.4402 4.0527 13.332ZM2.66406 13.9987C2.66406 11.0532 5.05188 8.66537 7.9974 8.66537C10.9429 8.66537 13.3307 11.0532 13.3307 13.9987C13.3307 14.3669 13.0323 14.6654 12.6641 14.6654H3.33073C2.96254 14.6654 2.66406 14.3669 2.66406 13.9987Z" fill="#DE3715"/>
<path fillRule="evenodd" clipRule="evenodd" d="M10.6641 7.9987C10.6641 7.63051 10.9625 7.33203 11.3307 7.33203H15.3307C15.6989 7.33203 15.9974 7.63051 15.9974 7.9987C15.9974 8.36689 15.6989 8.66537 15.3307 8.66537H11.3307C10.9625 8.66537 10.6641 8.36689 10.6641 7.9987Z" fill="#DE3715"/>
</svg>
  )
}

export default DisabledDangerIcon

export const fundraiseTooltip = `Fundraising Progress shows how much money your campaign has raised through call time to date as a percentage of your overall fundraising goal for the entire campaign.`;
export const contactTooltip = `Total Voter Contacts shows how many successful conversations you have had with target voters. Each voter that you successfully reach is shown in the numerator, while the denominator is the goal for your entire campaign.`;
export const progressGoalTooltip = `Progress to Goal is a weighted average of your Fundraising Progress and Total Voter Contacts.`;
export const voterSurplusTooltip = `Based on the constraints of time until the election and how many hours the candidate is able to work each week, will the campaign reach enough voters? <br /> <br /> If the number is negative (and red), there is a deficit and more work beyond the Daily Tasks needs to happen to reach the goal.  <br /> <br />If the number is positive (and green), there is a surplus and the candidate can continue with the Daily Tasks to reach the vote goal.`;
export const additionalvoterSurplusTooltip = `Based on the Voter Contact Deficit, this is an estimate of how many additional hours of work are required during the entire campaign to remove the deficit and reach the Vote Goal.`;
export const fundaiseSurplusTooltip = `Based on the constraints of time until the election and how many hours the candidate is able to work each week, will the campaign raise enough money to reach the fundraising goal?  <br /> <br />  If the number is negative (and red), there is a deficit and more work beyond the Daily Tasks needs to happen to reach the goal.  <br /> <br /> If the number is positive (and green), there is a surplus and the candidate can continue with the Daily Tasks to reach the fundraising goal.`;
export const additionalfundraiseSurplusTooltip = `Based on the Fundraising Deficit, this is an estimate of how many additional hours of work are required during the entire campaign to remove the deficit and reach the Fundraising Goal.`;
export const rateTooltip = `Contact Rate shows the percentage of door knocks that result in a conversation with the target voter. Calculated as Contacts / Attempts.  <br /> <br /> <span class='custom-tooltip-text'> A contact rate at doors typically hovers between 15 - 30%. </span>`;
export const rateTwoTooltip = `Contact Rate shows the percentage of phone calls that result in a conversation with the target voter. Calculated as Contacts / Attempts.`;
export const weeklyPlanTooltip = `Here you can see your weekly progress for this week. In the numerator, you can see how many attempts you have made so far this week. In the denominator, you can see the target goal of contacts for the week. Below, you can see an estimate for how many hours are required to achieve the goal for the week for current week.`;

import { QueryObserverResult, RefetchOptions, useMutation } from "@tanstack/react-query"
import axios, { AxiosResponse } from "axios"
import CustomTooltip from "components/common/CustomTooltip"
import React, { ChangeEvent, useEffect, useState } from "react"
import { countCharacterOccurrences } from "utils/arrayFunctions"
import CreateAuthAxiosInstance from "utils/authAxios"
import toastNotify from "utils/toastNotify"

interface IBudgetCell {
  value: string
  categoryId: number
  subCategoryId: number
  monthlyBudgetId: number
  refetchCategoryData: (options?: RefetchOptions) => Promise<QueryObserverResult<any, Error>>
}

const BudgetCell: React.FC<IBudgetCell> = ({
  value,
  categoryId,
  subCategoryId,
  monthlyBudgetId,
  refetchCategoryData
}) => {
  const authAxios = CreateAuthAxiosInstance()

  const [isFieldEditable, setIsFieldEditable] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(value)

  useEffect(() => {
    if (isFieldEditable) setIsFieldEditable(false)
  }, [value])

  //For Escape event: retain old value
  useEffect(() => {
    if (value !== inputValue && isFieldEditable === false) {
      setInputValue(value || "0.00")
    }
  }, [isFieldEditable])

  const {
    mutate: patchCellValue,
    isPending: isPendingCellValueEdit
  } =
    useMutation({
      mutationFn: (data: { amount: string }) => authAxios.patch(
        `api/v1/budgets/categories/${categoryId}/subcategories/${subCategoryId}/monthly-budgets/${monthlyBudgetId}`,
        data
      ),
      onSuccess: handleOnSuccessEvent,
      onError: handleOnErrorEvent,
    })

  function handleOnSuccessEvent(data: AxiosResponse) {
    toastNotify("success", "Value updated successfully!")
    refetchCategoryData()
  }

  function handleOnErrorEvent(error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error("on error", error?.response?.data?.message)

      toastNotify("error", "Error updating value!")
      setIsFieldEditable(false)
    }
  }

  const handleCellClick = () => {
    setIsFieldEditable(true)

    //remove zero value on focus
    if (inputValue === "0.00") {
      setInputValue("")
      return
    }

    //remove zero decimal value on focus
    if (inputValue?.slice(-3) === '.00') setInputValue(inputValue.slice(0, -3))
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let tempValue = e.target.value.replace(/[^0-9.]/g, '')
    const isDeleted = tempValue?.length < inputValue?.length

    //handling initial zero and decimal point input 
    if (tempValue === '0' || tempValue === '.') {
      tempValue = !isDeleted ? '0.' : ''
    }

    if (
      countCharacterOccurrences(tempValue, '.') > 1 ||    //prevent multiple decimal point
      tempValue[0] === '0' && tempValue[1] !== '.'        //prevent insignificant leading zero
    ) return

    setInputValue(tempValue)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleFinalPatch()
      return
    }

    if (e.key === "Escape") {
      setIsFieldEditable(false)
    }
  }

  function handleFinalPatch() {
    const finalValue: string = inputValue || "0.00"
    const finalRoundedValue = parseFloat(finalValue).toFixed(2)

    //if value is same, no patch
    if (value === finalValue || value === finalRoundedValue) {
      setIsFieldEditable(false)
      return
    }

    if (
      categoryId &&
      subCategoryId &&
      monthlyBudgetId
    ) {
      patchCellValue({ amount: finalRoundedValue })
    }
  }

  return (
    <CustomTooltip content="Use only numeric">
      <div className="budget__cell" onClick={handleCellClick}>
        {isFieldEditable ? (
          <input
            type="text"
            className="form-control rounded-0"
            autoFocus
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleFinalPatch}
          />
        ) : (
          <span>$ {value}</span>
        )}
      </div>
    </CustomTooltip>
  )
}

export default React.memo(BudgetCell)

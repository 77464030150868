import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Tooltip } from "bootstrap";
import {
  IBudgetFileDetails,
  IBudgetFileType,
} from "components/pages/dashboard/account-setup/Budget";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { queryClient } from "index";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import CreateAuthAxiosInstance from "utils/authAxios";
import { globalQueryConfig } from "utils/reactQuery";
import toastNotify from "utils/toastNotify";

const useBudgetOverview = () => {
  const authAxios = CreateAuthAxiosInstance();
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [totalSlides, setTotalSlides] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string>();
  const sliderRef = useRef<Slider>(null);
  const candidateId = localStorage.getItem(CANDIDATE_ID);

  const { data: funraisingData, isFetching: isFetchingFundraisingDetail } =
    useQuery({
      queryKey: ["fundraisingGoalDetail"],
      queryFn: () =>
        authAxios
          .get(`api/v1/profile-setting/candidate-details`)
          .then((response) => response.data),
      ...globalQueryConfig,
    });

  const {
    data: budgetAllocationData,
    isFetching: isFetchingBudgetAlloation,
    refetch: refetchAllocation,
  } = useQuery({
    queryKey: ["budgetAllocation"],
    queryFn: () =>
      authAxios
        .get(`api/v1/budget-overviews/allocation`)
        .then((response) => response.data),
    ...globalQueryConfig,
  });

  const {
    data: cashFlowData,
    isFetching: isFetchingBudgetCashFlow,
    refetch: refetchCashFlow,
  } = useQuery({
    queryKey: ["cashFlow"],
    queryFn: () =>
      authAxios
        .get(`api/v1/budget-overviews/cashflow`)
        .then((response) => response.data),
    ...globalQueryConfig,
  });

  const {
    data: budgetTotalData,
    isFetching: isFetchingBudgetTotal,
    refetch: refetchBudgetTotal,
  } = useQuery({
    queryKey: ["budgetTotal"],
    queryFn: () =>
      authAxios
        .get(`api/v1/budget-overviews/total`)
        .then((response) => response.data),
    ...globalQueryConfig,
  });

  // next arrow
  function NextArrow(props: any) {
    const { nextArrowType, className, style, onClick } = props;
    return (
      <div
        className={`${nextArrowType} ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  // prev arrow
  function PrevArrow(props: any) {
    const { prevArrowType, className, style, onClick } = props;
    return (
      <div
        className={`${prevArrowType} ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  const budgetDifferenceClassName = (value: string) => {
    if (Number(value) === 0) return "";

    return Number(value) < 0 ? "text-danger" : "text-success";
  };

  const sliderSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    infinite: false,
    mobileFirst: true,
    prevArrow: <PrevArrow prevArrowType={"prev-slide"} />,
    nextArrow: <NextArrow nextArrowType={"next-slide"} />,
    afterChange: (current: any) => {
      const totalSlides = budgetTotalData.monthlyCategoryBudgets?.length; // Number of slides you have in the carousel
      setIsLastSlide(current >= totalSlides - 4); // A
    },
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  useEffect(() => {
    setTotalSlides(budgetTotalData?.monthlyCategoryBudgets?.length || 0);
  }, [budgetTotalData]);

  useEffect(() => {
    if (sliderRef.current?.innerSlider?.list) {
      const track = sliderRef.current.innerSlider.list.querySelector(
        ".slick-track"
      ) as HTMLElement;
      if (track && totalSlides < 4) {
        track.style.width = "100%";

        const slideWidth = 100 / totalSlides;

        // Select all .slick-slide elements and set their width
        const slides = Array.from(
          track.querySelectorAll(".slick-slide")
        ) as HTMLElement[];
        slides.forEach((slide) => {
          slide.style.width = `${slideWidth}%`;
        });
      }
    }
  }, [totalSlides]);

  const { data: electionDetail } = useQuery({
    queryKey: ["electionDetail"],
    queryFn: () =>
      authAxios("/api/v1/candidate/election").then((data) => data?.data),
    ...globalQueryConfig,
  });

  const isBudgetPopulated: boolean | undefined =
    electionDetail?.isBudgetPopulated;

  const { mutate: populateDefaultBudget } = useMutation({
    mutationFn: () => authAxios.post("/api/v1/budgets/populate-default-budget"),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["electionDetail"],
      });
      await refetchAllocation();
      await refetchCashFlow();
      await refetchBudgetTotal();
    },
    onError: (error: any) => {
      toastNotify("error", "Error populating default budget");
      console.log("error occured", error);
    },
  });

  //to get fileType id of Budget
  const { data: fileType } = useQuery({
    queryKey: ["budgetfileType"],
    queryFn: () =>
      authAxios.get("/api/v1/file-type").then((response) => response.data),
    ...globalQueryConfig,
  });

  //find the file type of Budget
  const budgetFileType = fileType?.find(
    (file: IBudgetFileType) => file.title === "candidate-budget"
  );

  // get Budget file detils from api
  const { data: budgetFile } = useQuery({
    queryKey: ["budgetFileData"],
    queryFn: () =>
      authAxios
        .get(`api/v1/file/${candidateId}`)
        .then((response) => response.data),
    ...globalQueryConfig,
  });

  //find detils of Budget
  const budgetDetailsFromApi = budgetFile?.find(
    (budget: IBudgetFileDetails) => budget.fileTypeId === budgetFileType?.id
  );

  // api for download template
  const fetchBudgetTemplate = async () => {
    const response = await authAxios.get("api/v1/budgets/import-template");
    return response.data;
  };

  const shouldFetchTemplate = (budgetFile && funraisingData) ? !(budgetDetailsFromApi || funraisingData?.fundraisingGoal) : false

  const { data: budgetTemplate } = useQuery({
    queryKey: ["budgetTemplateDownload"],
    queryFn: fetchBudgetTemplate,
    ...globalQueryConfig,
    enabled: Boolean(shouldFetchTemplate)
  });

  //To upload Budget File
  async function handleBudgetFileUpload({
    timeZone,
    file,
  }: {
    timeZone: string;
    file: FormData;
  }) {
    const response = await authAxios.post("api/v1/budgets/import-excel", file, {
      headers: {
        "Content-Type": "multipart/form-data",
        "time-zone": timeZone,
      },
    });
    return response.data;
  }

  const { mutate: mutateBudgetFile, isPending: isFileUploadPending, isSuccess: isFileUploadSuccess } =
    useMutation({
      mutationFn: handleBudgetFileUpload,
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["budgetFileData"],
        });
        setError(undefined);
      },
      onError: (error: unknown) => {
        if (axios.isAxiosError(error)) {
          setError(error?.response?.data?.message);
        }
      },
    });

  //to delete Budget File
  const { mutate: mutateDeleteBudgetFile } = useMutation({
    mutationFn: () =>
      authAxios.delete(`api/v1/file/${budgetDetailsFromApi?.id}`),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["budgetFileData"],
      });
      setFile(null);
    },
  });

  const handleTemplateDownload = async () => {
    try {
      const response = await authAxios("/api/v1/budgets/import-template", {
        responseType: "blob", // This tells axios to return data as a Blob
      });
      const blobUrl = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "budget_template.xlsx");
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blobUrl);
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error("Failed to download the file:", error);
    }
  };

  useEffect(() => {
    if (isBudgetPopulated === false) populateDefaultBudget();
  }, [isBudgetPopulated]);

  const isbudgetOverviewsLoading = isBudgetPopulated
    ? isFetchingBudgetAlloation ||
    isFetchingBudgetCashFlow ||
    isFetchingBudgetTotal
    : true;

  return {
    funraisingData,
    budgetAllocationData,
    cashFlowData,
    budgetTotalData,
    isFetchingBudgetTotal,
    isFetchingBudgetAlloation,
    isFetchingBudgetCashFlow,
    NextArrow,
    PrevArrow,
    budgetDifferenceClassName,
    sliderSettings,
    isLastSlide,
    sliderRef,
    isbudgetOverviewsLoading,
    budgetDetailsFromApi,
    budgetTemplate,
    mutateDeleteBudgetFile,
    file,
    setFile,
    error,
    setError,
    mutateBudgetFile,
    budgetFileType,
    isFileUploadPending,
    isFileUploadSuccess,
    refetchBudgetTotal,
    handleTemplateDownload
  };
};
export default useBudgetOverview;

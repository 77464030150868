import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { IDonorInfo } from 'type/dashboard';
import InfoIconDanger from 'img/svg/InfoIconDanger';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CreateAuthAxiosInstance from 'utils/authAxios';
import { ISelectedDonor } from './DonorTableDataV2';
import toastNotify from 'utils/toastNotify';
import Spinner from 'components/common/Spinner';

interface IEditDonorModalProps {
  selectedDonor: ISelectedDonor | null
  setSelectedDonor: React.Dispatch<React.SetStateAction<ISelectedDonor | null>>
}

const donorInfoSchema = yup.object().shape({
  name: yup.string()
    .required("Name is required")
    .matches(/^[a-zA-Z\s]*$/, "Name must contain only letters and spaces"),
  email: yup.string().email("Invalid email format"),
  phoneNumber: yup.string()
    .required("Phone is required")
    .matches(
      /^(\+?[0-9]+-?)+[0-9]+$/,
      "Phone should contain numbers and characters only"
    )
    .min(10, ({ min }) => `Phone should be at least ${min} digits`)
    .max(14, ({ max }) => `Phone should be at most ${max} digits`),
});

const EditDonorModalV2 = ({
  selectedDonor,
  setSelectedDonor
}: IEditDonorModalProps) => {
  const authAxios = CreateAuthAxiosInstance()
  const queryClient = useQueryClient()

  const [isUpdateDataSame, setIsUpdateDataSame] = useState<boolean>(false)
  const closeRef = useRef<HTMLButtonElement | null>(null);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<IDonorInfo>({
    resolver: yupResolver(donorInfoSchema)
  });

  //Population of data in the form
  useEffect(() => {
    if (selectedDonor) {
      reset({
        name: selectedDonor?.name ?? '',
        email: selectedDonor?.email ?? '',
        phoneNumber: selectedDonor?.phoneNumber ?? ''
      })
      setIsUpdateDataSame(true)
    }
  }, [selectedDonor])

  //Check if the new value is same as old value
  useEffect(() => {
    if (
      watch('name') === selectedDonor?.name &&
      watch('email') === (selectedDonor?.email ?? '') &&
      watch('phoneNumber') === selectedDonor?.phoneNumber
    ) {
      setIsUpdateDataSame(true)
      return
    }
    if (isUpdateDataSame) setIsUpdateDataSame(false)

  }, [watch()])

  const {
    mutate: updateDonorDetail,
    status: donorDetailUpdationStatus
  } = useMutation({
    mutationFn: (data: IDonorInfo) => authAxios.patch(`api/v1/donor/${selectedDonor?.id}`, data),
    onSuccess: handleOnSuccessMutation,
    onError: () => {
      toastNotify('error', 'Error! Could not update the donor info!')
    },
  })

  function handleOnSuccessMutation() {
    toastNotify('success', 'Volunteer Updated Successfully !')
    queryClient.invalidateQueries({ queryKey: ['donorList'] })
    resetForm()

    if (closeRef?.current) closeRef?.current?.click()
  }

  function finalSumbit(donorUpdatedDetail: IDonorInfo) {
    let finalPayload: IDonorInfo = {
      name: donorUpdatedDetail?.name,
      phoneNumber: donorUpdatedDetail?.phoneNumber
    }

    if (donorUpdatedDetail?.email) {
      finalPayload.email = donorUpdatedDetail.email
    }

    updateDonorDetail(finalPayload)
  }

  const resetForm = () => setSelectedDonor(null)

  return (
    <>
      <form onSubmit={handleSubmit(finalSumbit)}>
        <div
          className="modal fade"
          id='editDonorModalV2'
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">Edit donor</h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeRef}
                  onClick={resetForm}
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="exampleInputName1" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    {...register(`name`)}
                    aria-describedby="nameHelp"
                    placeholder=""
                  />
                  {errors?.name && (
                    <div className="invalidInput form-text text-danger">
                      <InfoIconDanger /> {errors?.name?.message as string}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    {...register(`email`)}
                    aria-describedby="emailHelp"
                    placeholder=""
                  // disabled={selectedDonor.email ? true : false}
                  />
                  {errors?.email && (
                    <div className="invalidInput form-text text-danger">
                      <InfoIconDanger /> {errors?.email?.message as string}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPhone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register(`phoneNumber`)}
                    id="exampleInputPhone"
                    aria-describedby="phoneHelp"
                    placeholder=""
                  />
                  {errors?.phoneNumber && (
                    <div className="invalidInput form-text text-danger">
                      <InfoIconDanger />{" "}
                      {errors?.phoneNumber?.message as string}
                    </div>
                  )}
                </div>
              </div>

              <div className="modal-footer border-1">
                <Link
                  to=""
                  className="btn btn-link text-primary btn-xs"
                  data-bs-dismiss="modal"
                  onClick={resetForm}
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary rounded-pill btn-xs px-6"
                  disabled={donorDetailUpdationStatus === 'pending' || isUpdateDataSame}
                >
                  {donorDetailUpdationStatus === 'pending' && <Spinner />}
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default React.memo(EditDonorModalV2)
import { Link } from "react-router-dom";
import InfoIconWarning from "img/svg/InfoIconWarning";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
// import InfoIconSecondary from "img/svg/InfoIconSecondary";
import ChevronDownIcon from "img/svg/chevronDownIcon";
import { BY_PHONE, DOOR_TO_DOOR } from "../voters-list/VotersList";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import useVotersDashboard from "./useVotersDashboard";
import { TurfCard } from "./turf/turf-card/TurfCard";
import Loader from "components/common/Loader";
import LoaderCenter from "components/common/LoaderCenter";
import { TurfDetailsProvider } from "./turf/turf-detail/useTurfDetail";
import LimitedFeaturesModal from "./LimitedFeaturesModal";

const VotersDashboard = () => {
  const {
    contactedVoters,
    selectedContactType,
    setSelectedContactType,
    chartDataLength,
    chartDataWithLabels,
    chartOptions,
    isChartDataLoading,
    electionInfo,
    isElectionInfoLoading,
  } = useVotersDashboard();

  const hasVotersList = electionInfo?.hasVotersList;

  return (
    <div className="d-flex align-items-stretch">
      <div className="wrapper active-cont">
        {isElectionInfoLoading || hasVotersList === undefined ? (
          <LoaderCenter />
        ) : (
          <main>
            <div className="row">
              <div className="col-md-12">
                {!hasVotersList && (
                  <div
                    className="alert alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    <InfoIconWarning />{" "}
                    <span className="ms-2">
                      Voter list will be uploaded soon.{" "}
                    </span>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    >
                      <span className="icon-close"></span>
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Cards */}
            <div className="row">
              <div className="col-md-9">
                <div className="row h-100">
                  <div className="col-md-6 mb-3">
                    {isChartDataLoading ? (
                      <div
                        className="d-flex justify-content-center  align-items-center"
                        style={{ minHeight: "100%" }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <div className="card card-flush h-100 shadow-2">
                        <div className="card-header">
                          <h4 className="card-title"> Contact Voter</h4>
                          <p className="text-muted">
                            Contact reach compare to contact goal of last 4
                            weeks
                          </p>
                        </div>
                        <div className="card-body">
                          {chartDataLength !== 0 ? (
                            <div className="canvas-container">
                              <Bar
                                data={chartDataWithLabels}
                                options={chartOptions}
                                width={100}
                                height={66}
                              />
                            </div>
                          ) : (
                            <div className="noData d-flex gap-2 flex-row align-items-center">
                              <InfoIconSecondary />
                              <p className="m-0 text-muted fs-7">
                                {" "}
                                No data available{" "}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div className="col-md-6">
                  <div className="card card-flush h-100 shadow-2">
                    <div className="card-header">
                      <h4 className="card-title"> Contact by Phone</h4>
                      <p className="text-muted">
                        Contact reach compare to contact goal of last 4 weeks
                      </p>
                    </div>
                    <div className="card-body">
                      {extractedChartDataLength.length !== 0 ? (
                        <div className="canvas-container">
                          <Bar data={phoneData} width={100} height={66} />
                        </div>
                      ) : (
                        <div className="noData d-flex gap-2 flex-row align-items-center">
                          <InfoIconSecondary />{" "}
                          <p className="m-0 text-muted fs-7">
                            {" "}
                            No data available{" "}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div> */}
                  <TurfDetailsProvider>
                    <TurfCard />
                  </TurfDetailsProvider>
                </div>
              </div>

              <div className="col-md-3 mb-3">
                <div className="card card-flush h-100 shadow-2">
                  <div className="card-header contactCard-header">
                    <div className="iconBox bg-neutral-n4">
                      <img
                        src={require("../../../../img/patriotism.png")}
                        className="img-fluid"
                        width={32}
                        height={32}
                        alt=""
                      />
                    </div>
                    <h4 className="card-title text-center"> Contact Voters</h4>
                    <p className="text-muted text-center">
                      Reach out to your voters here
                    </p>
                  </div>

                  <div className="card-body">
                    <ul className="list-unstyled">
                      <li
                        className={`contactOption-item ${
                          !hasVotersList && "contactOption-item-disabled"
                        }`}
                      >
                        <Link
                          to={`${
                            hasVotersList
                              ? "/voters/contact-plan/contact-by-phone"
                              : "#"
                          }`}
                          // onClick={notify}
                        >
                          <div className="contactOption-item-content">
                            <div className="contactOption-item-content-title">
                              By Phone
                              <p className="m-0">Contact voter by Phone</p>
                            </div>
                          </div>
                          <div className="contactOption-item-link">
                            <span className="icon-arrow-right"></span>
                          </div>
                        </Link>
                      </li>

                      <li
                        className={`contactOption-item ${
                          !hasVotersList && "contactOption-item-disabled"
                        }`}
                      >
                        <Link
                          to={`${
                            hasVotersList
                              ? "/voters/contact-plan/door-to-door"
                              : "#"
                          }`}
                        >
                          <div className="contactOption-item-content">
                            <div className="contactOption-item-content-title">
                              Door to Door
                              <p className="m-0">Talk to voter on Door</p>
                            </div>
                          </div>
                          <div className="contactOption-item-link">
                            <span className="icon-arrow-right"></span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* table */}
            <div className="row mt-4">
              <div className="col-12">
                <div className="card card-flush shadow-2">
                  <div className="card-header d-flex justify-content-between">
                    <div className="d-flex gap-3">
                      <h4 className="card-title"> Recent voters contact</h4>

                      <div className="dropdown">
                        <button
                          className="btn btn-outline-secondary btn-xxs bg-white"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedContactType}
                          <span className="ps-2">
                            <ChevronDownIcon />
                          </span>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() =>
                                setSelectedContactType(DOOR_TO_DOOR)
                              }
                            >
                              Door to Door
                            </button>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => setSelectedContactType(BY_PHONE)}
                            >
                              By Phone{" "}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="card-toolBox">
                      <Link to="/voters/list" className="btn-xxs fw-bold">
                        {" "}
                        View All{" "}
                      </Link>
                    </div>
                  </div>

                  <div className="card-body pb-1 px-0">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Address</th>
                          <th scope="col">Voter Status</th>
                          <th scope="col">Level of Support </th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactedVoters.map((voter, index) => (
                          <tr key={index}>
                            <td>{voter.name}</td>
                            <td>{voter.address}</td>
                            <td>
                              {voter.status ? (
                                <span className="badge badge-secondary rounded-pill">
                                  {voter.status}
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {voter.levelOfSupport ? (
                                <span className="badge badge-success rounded-pill">
                                  {voter.levelOfSupport}
                                </span>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* <div className="noData d-flex gap-2 flex-row align-items-center py-7">
                        <InfoIconSecondary />{" "}
                        <p className="m-0 text-muted fs-7"> No tasks available for today </p>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      </div>

      <div>{/* <ToastContainer /> */}</div>
      <LimitedFeaturesModal />
    </div>
  );
};

export default VotersDashboard;

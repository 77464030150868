import React from "react";

const NoFile = ({
    handleInputFileChange
  }: {
    handleInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }) => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="uploadedCard mb-0">
            <div className="uploadedCard-icon">
              <div className="iconInfo">
                <span className="iconInfo-title text-muted">
                  xlsx
                  <span className="dot bg-neutral-n3 ms-1">
                  </span>
                  20 KB max
                </span>
              </div>
            </div>
            <div className="uploadedCard-info file-btn">
              <button className="btn btn-info rounded-pill">
                Upload File
                <input
                  type="file"
                  name="file"
                  onChange={handleInputFileChange}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  export default React.memo(NoFile);

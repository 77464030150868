import { NotificationsAction } from "global-state/actions/notificationActions.d";
import {
  NotificationReducer,
  initialNotificationReducerState,
} from "global-state/reducers/notificationReducer";
import { INotification } from "global-state/reducers/notificationReducer.d";
import { Dispatch, createContext, useContext, useReducer } from "react";

interface INotificationContextValue {
  notificationState: INotification;
  notificationDispatch: Dispatch<NotificationsAction>;
}

export const NotificationContext = createContext<INotificationContextValue>({
  notificationState: initialNotificationReducerState,
  notificationDispatch: () => {
    return;
  },
});

export const NotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    NotificationReducer,
    initialNotificationReducerState
  );
  return (
    <NotificationContext.Provider
      value={{
        notificationState: state,
        notificationDispatch: dispatch,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error("Context not found");
  }
  return context;
};

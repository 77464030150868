import React from 'react'

const InfoIconPrimary = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
<path d="M7.00065 13.6667C8.76876 13.6667 10.4645 12.9643 11.7147 11.7141C12.9649 10.4638 13.6673 8.76815 13.6673 7.00004C13.6673 5.23193 12.9649 3.53624 11.7147 2.286C10.4645 1.03575 8.76876 0.333374 7.00065 0.333374C5.23254 0.333374 3.53685 1.03575 2.28661 2.286C1.03636 3.53624 0.333984 5.23193 0.333984 7.00004C0.333984 8.76815 1.03636 10.4638 2.28661 11.7141C3.53685 12.9643 5.23254 13.6667 7.00065 13.6667ZM6.00065 10C6.00065 9.73482 6.10601 9.48047 6.29354 9.29293C6.48108 9.1054 6.73543 9.00004 7.00065 9.00004C7.26587 9.00004 7.52022 9.1054 7.70776 9.29293C7.89529 9.48047 8.00065 9.73482 8.00065 10C8.00065 10.2653 7.89529 10.5196 7.70776 10.7071C7.52022 10.8947 7.26587 11 7.00065 11C6.73543 11 6.48108 10.8947 6.29354 10.7071C6.10601 10.5196 6.00065 10.2653 6.00065 10ZM6.34465 3.54671C6.37248 3.393 6.45341 3.25394 6.5733 3.15381C6.69319 3.05367 6.84444 2.99882 7.00065 2.99882C7.15686 2.99882 7.30811 3.05367 7.428 3.15381C7.5479 3.25394 7.62882 3.393 7.65665 3.54671L7.66732 3.66671V7.00004L7.65665 7.12004C7.62882 7.27375 7.5479 7.41281 7.428 7.51294C7.30811 7.61307 7.15686 7.66793 7.00065 7.66793C6.84444 7.66793 6.69319 7.61307 6.5733 7.51294C6.45341 7.41281 6.37248 7.27375 6.34465 7.12004L6.33398 7.00004V3.66671L6.34465 3.54671Z" fill="#1D46D0"/>
</svg>
  )
}

export default InfoIconPrimary

import TrashIcon from "img/svg/TrashIcon";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { formatSize } from "utils/uploadFile";
import CheckSuccess from "../../img/svg/checkSuccess";
import axios from "axios";
import { useCandidateContext } from "global-state/contexts/CandidateContext";
import { useAuth0 } from "@auth0/auth0-react";
import FileIcon from "img/svg/fileIcon";
import {
  IFileDetails,
  IResponseFileInfo,
  uploadFileProps,
} from "type/dashboard";

const UploadFile = ({
  setIsFileRead,
  activeStep,
  fileInfo,
  setFileInfo,
  deleteFile,
  setDeleteFile,
  hasDonorsFileUploaded,
  setHasDonorsFileUploaded,
  electionId,
  closeModalRef,
}: uploadFileProps) => {
  const { getIdTokenClaims } = useAuth0();
  const { candidateState } = useCandidateContext();

  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [FormattedInfo, setFormattedInfo] = useState<IFileDetails | null>();
  const [updateTime, setUpdateTime] = useState<string>("");
  const [updateDate, setUpdateDate] = useState<string>("");
  const validFileTypes = ["pdf", "xls", "jpg", "jpeg", "doc", "csv", "png"];

  const localStorageUserId = localStorage.getItem("userId");
  const fileSizeLimit = 1024 * 1024 * 2;

  const IsValidFileType = (file: File) => {
    const fileType = file.name.split(".").pop()?.toLowerCase();

    if (activeStep === 2) {
      if (fileType === "pdf" || fileType === "doc") {
        return true;
      }
    } else {
      if (fileType && validFileTypes.includes(fileType)) {
        return true;
      }
    }
    return false;
  };

  const formatFileDetails = (uploadedFileData: IResponseFileInfo) => {
    const fileDetails: IFileDetails = {
      date: uploadedFileData.createdAt
        ? new Date(uploadedFileData.createdAt).toLocaleDateString()
        : "",
      time: uploadedFileData.createdAt
        ? new Date(uploadedFileData.createdAt).toLocaleString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
        : "",
      name: uploadedFileData?.fileName,
      size: uploadedFileData?.size,
      type: uploadedFileData?.mimeType,
      fileLinkId: uploadedFileData?.fileLinkId,
      id: uploadedFileData?.id,
    };
    return fileDetails;
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setFormattedInfo(null);
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setProgress(0);

      if (selectedFile.size <= fileSizeLimit && IsValidFileType(selectedFile)) {
        // const reader = new FileReader();

        const currentDate = new Date();
        setUpdateDate(currentDate.toLocaleDateString());
        setUpdateTime(
          currentDate.toLocaleString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
        );
        var fileTypeName = "";
        if (activeStep === 0) fileTypeName = "Voters";
        else if (activeStep === 1) fileTypeName = "Donors";
        else fileTypeName = "Candidate";
        if (activeStep === undefined) fileTypeName = "Voters";

        const formData = new FormData();
        formData.append("file", selectedFile);
        try {
          const fileTypes = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/file-type`
          );

          const fileType: any = fileTypes.data.find(
            (type: any) => type.title === fileTypeName
          );

          if (fileType) {
            const fileTypeId: number = fileType.id;
            formData.append("fileTypeId", fileTypeId.toString());
          }
          if (localStorageUserId) {
            formData.append("fileLinkId", ` ${localStorageUserId}`);
            setIsLoading(true);

            const response = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/api/v1/file`,
              formData,
              {
                onUploadProgress: (progressEvent) => {
                  const loaded = progressEvent.loaded;

                  const total = progressEvent.total;
                  if (total) {
                    setProgress(Math.round((loaded * 100) / total));
                  }
                },
              }
            );
            localStorage.setItem(
              `file-${activeStep}`,
              JSON.stringify(response.data)
            );
            setIsFileRead(true);
            setIsLoading(false);
            if (activeStep === 1 && !hasDonorsFileUploaded) {
              setHasDonorsFileUploaded && setHasDonorsFileUploaded(true);
            }
            closeModalRef?.current.click();

            // reader.onload = () => {
            //   setIsFileRead(true);
            //   setIsLoading(false);
            // };

            // reader.readAsDataURL(selectedFile);
          }
        } catch (error) {
          closeModalRef?.current.click();
          console.error("File not uploaded");
        }
      }
    }
  };

  const handleTrashIconClick = async () => {
    const storedData = localStorage.getItem(`file-${activeStep}`);

    const token = await getIdTokenClaims();
    if (storedData !== null) {
      const storedDataObj = JSON.parse(storedData);

      try {
        if (storedDataObj.length !== 0) {
          await axios
            .delete(
              `${process.env.REACT_APP_API_BASE_URL}/api/v1/file/${storedDataObj?.id}`,
              {
                headers: {
                  Authorization: `Bearer ${token?.__raw}`,
                },
              }
            )
            .then(() => {
              localStorage.removeItem(`file-${activeStep}`);

              setFile(null);
              setProgress(0);
              setIsFileRead(false);
              if (inputFileRef.current) {
                inputFileRef.current.value = "";
              }
              closeModalRef?.current.click();
              // hasDonorsFileUploaded &&
              setHasDonorsFileUploaded && setHasDonorsFileUploaded(false);
            })
            .catch((error) => {
              closeModalRef?.current.click();
              console.error(error);
            });

          // reader.onprogress = (event) => {
          //   formatSize(e.target.size);
          //   if (event.lengthComputable) {
          //     const percentage = (event.loaded / event.total) * 100;
          //     setProgress(percentage);
          //   }
          // };
        }
      } catch (err) {
        setIsLoading(false);

        console.error(err);
      }
    } else {
      if (fileInfo?.id) {
        await axios
          .delete(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/file/${fileInfo?.id}`,
            {
              headers: {
                Authorization: `Bearer ${token?.__raw}`,
              },
            }
          )
          .then(() => {
            localStorage.removeItem(`file-${activeStep}`);

            setFile(null);
            setProgress(0);
            setIsFileRead(false);
            if (inputFileRef.current) {
              inputFileRef.current.value = "";
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  if (deleteFile) {
    handleTrashIconClick();
    setDeleteFile && setDeleteFile(false);
    setHasDonorsFileUploaded && setHasDonorsFileUploaded(false);
  }

  useEffect(() => {
    try {
      if (fileInfo) {
        const formattedFileDetails = formatFileDetails(fileInfo);

        setFormattedInfo(formattedFileDetails);

        setIsFileRead(true);
      } else {
        if (setFileInfo) setFileInfo(null);
        setIsFileRead(false);
      }
    } catch (error) {
      console.error("Error fetching file:", error);
    }
    // update hasDonorsListFileUploaded flag
    (async () => {
      if (activeStep === 1 && electionId) {
        const token = await getIdTokenClaims();
        try {
          if (electionId) {
            await axios.patch(
              `${process.env.REACT_APP_API_BASE_URL}/api/v1/election/${electionId}`,
              { hasDonorsFileUploaded },
              {
                headers: {
                  Authorization: `Bearer ${token?.__raw}`,
                },
              }
            );
          }
        } catch (error: any) {
          console.error(
            "The uploaded file flag was not updated because",
            error.message
          );
        }
      }
    })();
  }, [activeStep, candidateState?.candidates, fileInfo]);

  return (
    <div className="uploadedCard mb-0 ">
      {FormattedInfo !== null &&
        fileInfo !== null &&
        file === null &&
        activeStep !== undefined ? (
        <>
          <div className="uploadedCard-icon">
            <div className="iconBox">
              <FileIcon />
            </div>
            <div className="iconInfo">
              <span className="iconInfo-title">{FormattedInfo?.name}</span>

              <p className="iconInfo-desc">
                {FormattedInfo &&
                  `${formatSize(FormattedInfo?.size)}, ${FormattedInfo?.date
                  } at ${FormattedInfo?.time}`}
                <span className="iconInfo-status">
                  <CheckSuccess /> File uploaded
                </span>{" "}
              </p>
            </div>{" "}
          </div>

          <div className="uploadedCard-info ">
            <button
              type="button"
              className="file-btn btn btn-link text-primary"
            >
              Change File
              <input
                type="file"
                name="file"
                onChange={handleFileChange}
                ref={inputFileRef}
              />
            </button>
            <button
              type="button"
              className="btn btn-xs"
              onClick={() => {
                handleTrashIconClick();
                setFormattedInfo(null);
              }}
            >
              <TrashIcon />{" "}
            </button>
          </div>
        </>
      ) : (
        <>
          {file ? (
            <>
              <div className="uploadedCard-icon ">
                <div className="iconBox">
                  <FileIcon />
                </div>
                {file.size > fileSizeLimit || !IsValidFileType(file) ? (
                  <div className="iconInfo">
                    <span className="iconInfo-title">{file.name} </span>

                    <p className="iconInfo-desc text-danger">
                      {file.size > fileSizeLimit
                        ? "File size exceeds 2 MB"
                        : activeStep === 2 ? "File type not valid. Please enter a file of format pdf, or docs" : "File type not valid. Please enter a file of format pdf, jpg, jpeg, xls, or any."}
                    </p>
                  </div>
                ) : (
                  <div className="iconInfo">
                    <span className="iconInfo-title">{file.name} </span>
                    <p className="iconInfo-desc">
                      {`${formatSize(
                        file.size
                      )}, ${updateDate} at ${updateTime}`}
                    </p>
                    {file && isLoading ? (
                      <span className="iconInfo-status">
                        {
                          <p>
                            Uploading: {progress > 0 && progress.toFixed(2)}%
                          </p>
                        }
                        <div
                          className="progress"
                          role="progressbar"
                          aria-valuenow={progress}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        >
                          <div
                            className="progress-bar"
                            style={{ width: `${progress}%` }}
                          ></div>
                        </div>
                      </span>
                    ) : (
                      <span className="iconInfo-status">
                        <CheckSuccess /> File uploaded
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="uploadedCard-info ">
                {(!isLoading || file) &&
                  file.size <= fileSizeLimit &&
                  IsValidFileType(file) ? (
                  <button
                    type="button"
                    className="file-btn btn btn-link text-primary"
                  >
                    Change File
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                      ref={inputFileRef}
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="file-btn btn btn-link text-primary"
                  >
                    Retry
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                      ref={inputFileRef}
                    />
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-xs"
                  onClick={() => {
                    handleTrashIconClick();
                  }}
                >
                  <TrashIcon />{" "}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="uploadedCard-icon">
                <div className="iconInfo">
                  <span className="iconInfo-title text-muted">
                    {activeStep === 2 ? "Please enter a file of format pdf, or docs" : "Please enter a file of format pdf, jpg, jpeg, xls, or any."}
                    <span className="dot bg-neutral-n3 ms-1"> </span>2MB max
                  </span>
                </div>
              </div>
              <div className="uploadedCard-info file-btn">
                <button type="button" className="btn btn-info rounded-pill">
                  Upload File
                  <input type="file" name="file" onChange={handleFileChange} />
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(UploadFile);

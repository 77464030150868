import {
  CandidateAction,
  CandidateActionTypes,
} from "../actions/iCandidateActions.d";
import { ICandidateReducerState } from "./iCandidateReducer";

export const initialCandidateReducerState: ICandidateReducerState = {
  candidates: [{ id: 0, name: "", city: "", position: "" }],
};

const candidateReducer = (
  state: ICandidateReducerState,
  action: CandidateAction
) => {
  const { type, payload } = action;

  switch (type) {
    case CandidateActionTypes.ADD_CANDIDATE:
      return {
        candidates: [payload],
      };

    case CandidateActionTypes.REMOVE_CANDIDATE: {
      const newCandidates = state.candidates.filter(
        (candidate) => !String(action.payload).includes(String(candidate.id))
      );
      return {
        candidates: newCandidates,
      };
    }

    case CandidateActionTypes.UPDATE_CANDIDATE: {
      const newCandidates = state.candidates.filter(
        (candidate) => !String(action.payload).includes(String(candidate.id))
      );
      return {
        candidates: [...newCandidates, payload],
      };
    }

    default:
      return state;
  }
};

export default candidateReducer;

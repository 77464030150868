import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LinkIcon from "img/svg/LinkIcon";
import { Link } from "react-router-dom";
import InfoIconSecondary from "img/svg/InfoIconSecondary";

interface INewsProps {
  newsType: string;
  varient?: string;
}

const NewsSlider = ({ newsType, varient }: INewsProps) => {
  const parentContainerWidth = '500px'; 

  const sliderRef = useRef<Slider>(null);
  const items = [1,2,3,4];
  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    infinite: true,
    mobileFirst: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  
  return (
    <div className="card-news-body-item" key={newsType}>
      <div className="card-news-body-item-header">
        <span
          className={`badge badge-${varient} text-n1 font-regular rounded-pill`}
        >
          {" "}
          {newsType}
        </span>
        {/* news category placeholder */}
        <div className="placeholder-glow w-100">
          <div className="placeholder col-6 pt-4"></div>
        </div>

        <span className="text-muted fs-8 font-regular d-block mt-2">Showing results for “5 Filters applied” </span>
      </div>
      <Slider {...sliderSettings} ref={sliderRef}>
        {items.map((index)=> (
          <Link to="" key={index}>
          <div className="card-news-body-item-body">
            <span className="d-flex gap-1 align-items-center">
              {" "}
              <LinkIcon />{" "}
              <span className="news-title text-primary">
                The Belmont Voice
              </span>
            </span>

            <ul className="card-news-content-list">
              <li>
                Luzerne County will continue mail ballot deficiency curing for
                the April 23 primary election.
              </li>
              <li>
                Approximately 20,000 mail ballots are expected to be
                distributed in early April.
              </li>
            </ul>
          </div>
        </Link>
        ))}

        {/* <Link to="">
          <div className="card-news-body-item-body">
            <span className="d-flex gap-1 align-items-center">
              {" "}
              <LinkIcon />{" "}
              <span className="news-title text-primary">
                The Belmont Voice
              </span>
            </span>

            <ul className="card-news-content-list">
              <li>
                Luzerne County will continue mail ballot deficiency curing for
                the April 23 primary election.
              </li>
              <li>
                Approximately 20,000 mail ballots are expected to be
                distributed in early April.
              </li>
            </ul>
          </div>
        </Link>
        <Link to="">
          <div className="card-news-body-item-body">
            <span className="d-flex gap-1 align-items-center">
              {" "}
              <LinkIcon />{" "}
              <span className="news-title text-primary">
                The Belmont Voice
              </span>
            </span>

            <ul className="card-news-content-list">
              <li>
                Luzerne County will continue mail ballot deficiency curing for
                the April 23 primary election.
              </li>
              <li>
                Approximately 20,000 mail ballots are expected to be
                distributed in early April.
              </li>
            </ul>
          </div>
        </Link> */}
      </Slider>
    </div>
  );
};

export default NewsSlider;

import React from "react";

const TrashIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7996 2.4C10.3451 2.4 9.92957 2.6568 9.7263 3.06335L8.85797 4.8H4.79961C4.13687 4.8 3.59961 5.33726 3.59961 6C3.59961 6.66274 4.13687 7.2 4.79961 7.2L4.79961 19.2C4.79961 20.5255 5.87413 21.6 7.19961 21.6H16.7996C18.1251 21.6 19.1996 20.5255 19.1996 19.2V7.2C19.8623 7.2 20.3996 6.66274 20.3996 6C20.3996 5.33726 19.8623 4.8 19.1996 4.8H15.1413L14.2729 3.06335C14.0697 2.6568 13.6541 2.4 13.1996 2.4H10.7996ZM8.39961 9.6C8.39961 8.93726 8.93687 8.4 9.59961 8.4C10.2624 8.4 10.7996 8.93726 10.7996 9.6V16.8C10.7996 17.4627 10.2624 18 9.59961 18C8.93687 18 8.39961 17.4627 8.39961 16.8V9.6ZM14.3996 8.4C13.7369 8.4 13.1996 8.93726 13.1996 9.6V16.8C13.1996 17.4627 13.7369 18 14.3996 18C15.0624 18 15.5996 17.4627 15.5996 16.8V9.6C15.5996 8.93726 15.0624 8.4 14.3996 8.4Z"
        fill="#3B3B4F"
      />
    </svg>
  );
};
export default TrashIcon;

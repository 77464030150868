import { AxiosResponse } from "axios";
import { IVotersDetails } from "../DoortoDoor";
import DOMPurify from 'dompurify';


interface IDoorToDoorScripts {
    candidateInfo: AxiosResponse<any, any> | undefined;
    selectedOption: string | undefined;
    voters: IVotersDetails[];
    finalScript: string;
}

const DoortoDoorScript = ({ candidateInfo, selectedOption, voters, finalScript }: IDoorToDoorScripts) => {
    return (
        <div className="script-wrapper textEditor">

            {selectedOption === "defaultScript" && (
                <>
                    <p>
                        {" "}
                        Hi, is{" "}
                        <span className="text-bg">
                            {" "}
                            {voters[0]?.name}{" "}
                        </span>
                        available?{" "}
                    </p>
                    <p>
                        Hi,{" "}
                        <span className="text-bg">
                            {" "}
                            {voters[0].name}
                            ,{" "}
                        </span>{" "}
                        my name is{" "}
                        <span className="text-bg">
                            {" "}
                            {candidateInfo?.data.fullName}{" "}
                        </span>{" "}
                        and I'm running for{" "}
                        <span className="text-bg">
                            {" "}
                            {
                                candidateInfo?.data.candidate.elections[0]
                                    .electionPosition
                            }{" "}
                        </span>{" "}
                        , right here in{" "}
                        <span className="text-bg">
                            {" "}
                            {
                            }{" "}
                        </span>{" "}
                        . How are you doing today?
                    </p>
                    <p>
                        The reason I'm talking to voters today is because we have
                        a really important election upcoming for{" "}
                        <span className="text-bg">
                            {" "}
                            {
                                candidateInfo?.data.candidate.elections[0]?.electionPosition
                            }{" "}
                        </span>
                        .{" "}
                    </p>
                    <p>
                    Thanks for your time,{" "}
                        <span className="text-bg">
                            {" "}
                            {
                             voters[0]?.name   
                            }
                        </span>!
                    </p>
                    <p>
                        I am running for <span className="text-bg">{candidateInfo?.data.candidate?.elections[0]?.electionPosition}</span>.
                    </p>
                    <p>If you have any questions, feel free to reach out. Have a great day</p>
                </>
            )}
            {selectedOption === "customScript" && (
                <>
                    {
                        <p
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(finalScript),
                            }}
                        />

                    }
                </>
            )}
        </div>
    )
}

export default DoortoDoorScript;
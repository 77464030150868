import React from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";


const EditCategoryDrodown = () => {
 
  
  return createPortal(
    <div className="position-relative">
      <div className="dropdown-menu">
        <div className="dropdown-group">
          <ul className="dropdown-body list-unstyled mb-0">
            <li>
              <Link to="">
                {" "}
                Edit Category name
              </Link>
            </li>
            <li>
              <Link
                className="text-danger"
                to=""
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
              >
                {" "}
                Delete Category
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>, document.body
  );
};

export default EditCategoryDrodown;

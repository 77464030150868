import FbSmall from "img/svg/fbSmall";
import HelpIcon from "img/svg/helpIcon";
import TwitterIcon from "img/svg/twitter";
import React from "react";
import { Link } from "react-router-dom";
import CustomTooltip from "components/common/CustomTooltip";
import DisabledDangerIcon from "../../../img/svg/DisabledDangerIcon";
import InfoIconWarning from "img/svg/InfoIconWarning";

const IntegrationsDetails = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="mt-5">
            <div className="settings">
              <div className="settings-header d-flex justify-content-between">
                <div className="">
                  <h4> Social integrations</h4>
                  <p className="body-4 text-muted">
                    Connect or remove the social integrations
                  </p>
                </div>
              </div>

              <div className="settings-body">
                <div className="professional-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-12">
                      {/* connected card fb*/}
                      <div className="connectCard mb-0 p-0 border-0 shadow-none">
                        <div className="connectCard-icon connected">
                          <div className="profileBox fb">
                            {/* <img
                                    src={require("../../img/avatar.png")}
                                    className="img-fluid"
                                    alt=""
                                  /> */}

                            <div className="profileBox-logo">
                              <FbSmall />
                            </div>
                          </div>
                          <div className="iconInfo">
                            <span className="iconInfo-platform">
                              {" "}
                              Facebook{" "}
                            </span>
                            <p className="mb-0">
                              <span className="text-success">Connected </span>{" "}
                              as Elenor Parker
                            </p>
                          </div>
                        </div>
                        <div className="connectCard-info">
                          <button
                            className="btn btn-link rounded-pill text-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#removeIntegrationModal"
                          >
                            {" "}
                            Remove{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-12">
                      {/* connect card twitter*/}
                      <div className="connectCard mb-0 p-0 border-0 shadow-none">
                        <div className="connectCard-icon">
                          <div className="iconBox twitter">
                            <TwitterIcon />
                          </div>
                          <div className="iconInfo">
                            <span className="iconInfo-platform"> Twitter </span>
                          </div>
                        </div>
                        <div className="connectCard-info">
                          <button className="btn btn-info rounded-pill">
                            {" "}
                            Connect{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* AutoDialer Integration */}
          <div className="mt-5">
            <div className="settings">
              <div className="settings-header d-flex justify-content-between">
                <div className="">
                  <h4> Auto dialer integration </h4>
                  <p className="body-4 text-muted">
                    Auto dialer services not activated. Verify phone number to
                    activate.
                  </p>
                </div>
              </div>

              <div className="settings-body">
                <div className="voterDetail-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex">
                        <button className="btn btn-link btn-xs px-1 py-0">
                          Deactivate integration
                        </button>
                      </div>

                      <div
                        className="alert alert-warning alert-dismissible fade show"
                        role="alert"
                      >
                        <InfoIconWarning />{" "}
                        <span className="ms-2">
                          Auto dialer integration deactivated.
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                  <div className="row mt-4">
                    <div className="col-md-4 col-12 mb-3">
                      <div className="card card-flush h-100 border-1">
                        <div className="card-header d-flex justify-content-between">
                          <h4 className="card-title"> Active volunteers </h4>
                          <div className="card-info">
                            <CustomTooltip content="Like">
                              <span className="text-left">
                                {" "}
                                <HelpIcon />{" "}
                              </span>
                            </CustomTooltip>
                          </div>
                        </div>
                        <div className="card-body pt-1 d-flex flex-column justify-content-end">
                          <div className="d-flex align-items-end">
                            <h1 className="mb-0 me-2">1</h1>{" "}
                          </div>

                          {/* placeholder design */}
                          <div className="placeholder-glow w-100">
                            <div className="placeholder col-6 py-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-fullHeight scroll-y mt-3 border-1 card-callLogs">
                        {/* table */}
                        <table className="table table-full table-eq-height table-hover">
                          <thead>
                            <tr>
                              <th>Name </th>
                              <th> Phone no.</th>
                              <th> Role </th>
                              <th> Auto dialer status </th>
                              <th> Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={require("../../../img/avatar.png")}
                                    style={{ width: "32px", height: "32px" }}
                                    className="avatar border-0 img-fluid rounded-circle me-2 mb-0"
                                    alt="User Avatar"
                                  />

                                  <div>
                                    <span className="text-dark userName d-block">
                                      Jenny Wilson
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <span> (406) 555-0120 </span>
                              </td>
                              <td>
                                {" "}
                                <span> Candidate (You) </span>{" "}
                              </td>

                              <td>
                                <span className="badge badge-success font-semibold rounded-pill">
                                  <span className="badge-label">
                                    Integrated
                                  </span>
                                </span>

                                {/* <span className="d-block"> - </span>  */}
                              </td>

                              <td className="text-center"></td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={require("../../../img/avatar.png")}
                                    style={{ width: "32px", height: "32px" }}
                                    className="avatar border-0 img-fluid rounded-circle me-2 mb-0"
                                    alt="User Avatar"
                                  />

                                  <div>
                                    <span className="text-dark userName d-block">
                                      Jacob Jones
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <span> (406) 555-0120 </span>
                              </td>
                              <td>
                                {" "}
                                <span> Volunteer </span>{" "}
                              </td>

                              <td>
                                <span className="badge badge-success font-semibold rounded-pill">
                                  <span className="badge-label">
                                    Integrated
                                  </span>
                                </span>

                                {/* <span className="d-block"> - </span>  */}
                              </td>

                              <td className="text-center">
                                <button
                                  className="btn no-hover btn-link text-danger px-1 py-0 fs-7 d-flex align-items-center gap-2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#disableModal"
                                >
                                  {" "}
                                  <DisabledDangerIcon /> Disable{" "}
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={require("../../../img/avatar.png")}
                                    style={{ width: "32px", height: "32px" }}
                                    className="avatar border-0 img-fluid rounded-circle me-2 mb-0"
                                    alt="User Avatar"
                                  />

                                  <div>
                                    <span className="text-dark userName d-block">
                                      Chloe Davis
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <span> (406) 555-0120 </span>
                              </td>
                              <td>
                                {" "}
                                <span> Volunteer </span>{" "}
                              </td>

                              <td>
                                <span className="badge badge-danger font-semibold rounded-pill">
                                  <span className="badge-label">Disabled</span>
                                </span>

                                {/* <span className="d-block"> - </span>  */}
                              </td>

                              <td className="text-center">
                                <button
                                  className="btn no-hover btn-link text-danger px-1 py-0 fs-7 d-flex align-items-center gap-2"
                                  disabled
                                >
                                  {" "}
                                  <DisabledDangerIcon /> Disabled{" "}
                                </button>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="loader-demo-box">
                                  <div className="jumping-dots-loader">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        {/* if no data */}
                        {/* <div className="noData d-flex gap-2 flex-row align-items-center">
                          <InfoIconSecondary />
                          <p className="m-0 text-muted fs-7">
                            {" "}
                            No tasks available for today{" "}
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* integration disable Modal  */}
      <div
        className="modal fade"
        id="disableModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../img/call-disabled.png")}
                width={56}
                height={56}
                alt="delete warning"
              />
              <h2 className="mt-3">Disable integration?</h2>
              <p>
                This volunteer will no longer be able to access the auto dialer.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link rounded-pill btn-xs"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, disable
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntegrationsDetails;

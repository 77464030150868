

const RejectedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M5.15152 5.15128C5.62015 4.68265 6.37995 4.68265 6.84858 5.15128L12 10.3027L17.1515 5.15128C17.6201 4.68265 18.3799 4.68265 18.8486 5.15128C19.3172 5.61991 19.3172 6.3797 18.8486 6.84833L13.6971 11.9998L18.8486 17.1513C19.3172 17.6199 19.3172 18.3797 18.8486 18.8483C18.3799 19.317 17.6201 19.317 17.1515 18.8483L12 13.6969L6.84858 18.8483C6.37995 19.317 5.62015 19.317 5.15152 18.8483C4.68289 18.3797 4.68289 17.6199 5.15152 17.1513L10.303 11.9998L5.15152 6.84833C4.68289 6.3797 4.68289 5.61991 5.15152 5.15128Z" fill="#DE3715"/>
</svg>
  )
}

export default RejectedIcon

import { useMutation } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import CreateAuthAxiosInstance from 'utils/authAxios'

interface IVerificationRetry {
  phoneNumber: string | null
  setStep: React.Dispatch<React.SetStateAction<string>>
  setValidationCode: React.Dispatch<React.SetStateAction<string | null>>
}

const VerificationRetry = ({
  phoneNumber,
  setStep,
  setValidationCode
}: IVerificationRetry) => {

  const authAxios = CreateAuthAxiosInstance()

  const {
    mutate: retryVerifyPhoneNumberMutation,
    data: retryVerifyPhoneNumberData,
    isPending
  } = useMutation({
    mutationFn:
      (finalPhoneNumber: string) => authAxios.post(
        `${process.env.REACT_APP_COMMUNICATION_BASE_URL}/api/v1/calls/phonenumber-verification`,
        {
          'phoneNumber': finalPhoneNumber
        }
      ),
    onSuccess: (data) => {
      handleSuccessfulSubmission(data?.data?.validationCode)
    },
    onError: (error: any) => {
      // console.log("on error", error?.response?.data?.message);
      setStep('failed')
    },
  })


  useEffect(() => {
    if (phoneNumber) {
      retryVerifyPhoneNumberMutation(phoneNumber)
    }
  }, [phoneNumber])

  const handleSuccessfulSubmission = (validationCode: string) => {
    if (validationCode) {
      setValidationCode(validationCode)
      setStep('otp')
    }
  }

  return (
    <div className="card-body text-center px-5  mx-auto">
      <div className="loader-demo-box">
        <div className="jumping-dots-loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <span className="body-4 fs-5">Loading..........</span>
    </div>
  )
}

export default VerificationRetry
import React from "react";
import { Link } from "react-router-dom";
import SideNavbar from "../../components/common/navbar/SideNavbar";
import Stepper from "../components/Stepper";
import FileUpload from "../components/FileUpload";
import CheckSuccess from "../../img/svg/checkSuccess";
import TrashIcon from "img/svg/TrashIcon";
const CampaignInformation = () => {
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="row">
            <div className="col-md-4">
              <div className="accountSetup accountSetup-fixed">
                <div className="accountSetup-header">
                  <h5>Account setup list</h5>
                  <p>Setup your account to use Campaign Brain</p>
                </div>
                <div className="accountSetup-body">
                  <Stepper />
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="card card-accountSetup shadow-2">
                <div className="card-title mb-0">
                  <h4 className="mb-0"> Campaign Information </h4>
                </div>

                <div className="card-body">
                  <div className="campaign-information">
                    <form action="">
                      <div className="mb-3">
                        <label htmlFor="website" className="custom-label">
                          Website
                        </label>

                        <label className="form-label mb-2 text-muted d-block fs-7">
                          Tell us about your campaign website link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="website"
                          aria-describedby="emailHelp"
                          placeholder=""
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="website" className="custom-label">
                          Campaign’s logo
                        </label>

                        <div className="media media-lg">
                          <div className="media-header avatar border-0 position-relative">
                            <span className="media-header--text">
                              <i className="icon-camera text-muted"></i>
                              {/* <img
                            src={
                              
                              require("../../../img/avatar.png")
                            }
                           
                            className="img-fluid rounded-circle"
                            alt="User Avatar"
                          /> */}
                            </span>

                            <div className="edit-avatar">
                              <label className="custom-file-upload">
                                <input type="file" />
                                <i className="icon-pencil"> </i>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="website" className="custom-label">
                          Endorsement
                        </label>

                        <label className="form-label mb-2 text-muted d-block fs-7">
                          Tell us about the endorsement organization and a brief
                          description of why they received the endorsement
                        </label>
                          
                        <div className="groupField">
                          <div className="groupField-item mb-3">
                            <div className="d-flex gap-3">
                              <div className="position-relative w-100">
                                <textarea
                                  className="form-control"
                                  id="TextareaStrength"
                                  rows={2}
                                  maxLength={1000}
                                ></textarea>

                                <span className="word-count"> 0 / 100 </span>
                              </div>

                              <button className="btn btn-link pt-0 px-0 mt-2">
                                {" "}
                                <span className="svg-neutral-2">
                                  {" "}
                                  <TrashIcon />{" "}
                                </span>{" "}
                              </button>
                            </div>

                          </div>
                          <div className="groupField-item mb-3">
                            <div className="d-flex gap-3">
                              <div className="position-relative w-100">
                                <textarea
                                  className="form-control"
                                  id="TextareaStrength"
                                  rows={2}
                                  maxLength={1000}
                                ></textarea>

                                <span className="word-count"> 0 / 100 </span>
                              </div>

                              <button className="btn btn-link pt-0 px-0 mt-2">
                                {" "}
                                <span className="svg-neutral-2">
                                  {" "}
                                  <TrashIcon />{" "}
                                </span>{" "}
                              </button>
                            </div>

                          </div>
                        </div>

                        <button className="btn btn-link text-primary">
                          {" "}
                          <span className="icon-plus me-2"></span> Add another
                          endorsement{" "}
                        </button>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="website" className="custom-label">
                          Opposition candidate
                        </label>

                        <label className="form-label mb-2 text-muted d-block fs-7">
                          Describe the background of the opposition candidates
                        </label>
                      </div>

                      <div className="mb-3">
                        <div className="groupfield">
                          <div className="groupfield-item mb-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <label className="form-label mb-2 text-muted d-block fs-7">
                                Opposition 1
                                </label>

                                <button className="btn btn-link pt-0 px-0 mt-2">
                            {" "}
                            <span className="svg-neutral-2">
                              {" "}
                              <TrashIcon />{" "}
                            </span>{" "}
                          </button>

                            </div>

                            <div className="mb-3">
                              <label htmlFor="oppName" className="body-5">
                                Name
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                id="oppName"
                                aria-describedby="emailHelp"
                                placeholder=""
                              />
                            </div>

                            <label
                              htmlFor="TextareaStrength"
                              className="body-5"
                            >
                              Description
                            </label>

                            <div className="position-relative">
                              <textarea
                                className="form-control"
                                id="TextareaStrength"
                                rows={3}
                                maxLength={1000}
                              ></textarea>

                              <span className="word-count"> 0 / 200 </span>
                            </div>
                          </div>
                          <div className="groupfield-item mb-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <label className="form-label mb-2 text-muted d-block fs-7">
                                Opposition 2
                                </label>

                                <button className="btn btn-link pt-0 px-0 mt-2">
                            {" "}
                            <span className="svg-neutral-2">
                              {" "}
                              <TrashIcon />{" "}
                            </span>{" "}
                          </button>

                            </div>

                            <div className="mb-3">
                              <label htmlFor="oppName" className="body-5">
                                Name
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                id="oppName"
                                aria-describedby="emailHelp"
                                placeholder=""
                              />
                            </div>

                            <label
                              htmlFor="TextareaStrength"
                              className="body-5"
                            >
                              Description
                            </label>

                            <div className="position-relative">
                              <textarea
                                className="form-control"
                                id="TextareaStrength"
                                rows={3}
                                maxLength={1000}
                              ></textarea>

                              <span className="word-count"> 0 / 200 </span>
                            </div>
                          </div>
                        </div>

                        <button className="btn btn-link text-primary">
                          {" "}
                          <span className="icon-plus me-2"></span> Add another
                          opposition{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="card-footer shadow-6 gap-4 d-flex justify-content-end">
                  <Link to="" className="btn btn-link text-primary btn-xs">
                    Not Now{" "}
                  </Link>
                  <Link
                    to=""
                    className="btn btn-primary rounded-pill btn-xs px-6"
                  >
                    Finish setup{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CampaignInformation;

import React from 'react'

const FileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
<g clipPath="url(#clip0_1944_4695)">
<path d="M26.6134 6.10616C26.6125 8.14417 26.6097 10.1813 26.6097 12.2193C26.6097 15.6194 26.6125 19.0186 26.6134 22.4187C26.5761 22.4382 26.5378 22.4578 26.4996 22.4774C25.4766 22.4765 24.4526 22.4709 23.4287 22.4755C23.1013 22.4774 22.774 22.5063 22.4476 22.5231C21.46 22.5753 20.5396 23.5663 20.5591 24.6767C20.5806 25.8458 20.5638 27.0159 20.5638 28.185H8.03566C7.99929 28.1672 7.96199 28.1486 7.92562 28.1309C7.86687 28.1085 7.80718 28.0861 7.74843 28.0638C6.91191 27.7384 6.34957 26.9804 6.32999 26.1525C6.32906 25.526 6.32906 24.8986 6.32812 24.2721C6.32906 24.135 6.33092 23.9971 6.33092 23.86C6.33092 17.994 6.33092 12.127 6.32999 6.25999C6.32999 5.80595 6.40833 5.36964 6.65732 4.98832C7.10775 4.30122 7.74283 3.9516 8.58028 4.01407C13.2124 4.01593 17.8444 4.0178 22.4756 4.01593C22.552 4.01593 22.6276 3.97864 22.7031 3.95813L22.7078 3.96466C22.7255 3.96372 22.7432 3.96372 22.7619 3.96279C23.4212 3.96372 24.0796 3.96466 24.7389 3.96652C25.1241 4.13154 25.5083 4.29656 25.8925 4.46157C25.983 4.55294 26.0734 4.64337 26.1648 4.73474C26.3812 5.17012 26.6665 5.5822 26.6134 6.10616Z" fill="#CBD7F4"/>
<path d="M9.54297 7.21103C9.54297 6.79508 9.89011 6.45789 10.3183 6.45789H22.7241C23.1524 6.45789 23.4995 6.79508 23.4995 7.21103C23.4995 7.62697 23.1524 7.96417 22.7241 7.96417H10.3183C9.89011 7.96417 9.54297 7.62697 9.54297 7.21103Z" fill="#5688FF"/>
<path d="M9.54297 11.7299C9.54297 11.3139 9.89011 10.9767 10.3183 10.9767H22.7241C23.1524 10.9767 23.4995 11.3139 23.4995 11.7299C23.4995 12.1458 23.1524 12.483 22.7241 12.483H10.3183C9.89011 12.483 9.54297 12.1458 9.54297 11.7299Z" fill="#5688FF"/>
<path d="M9.54297 16.2487C9.54297 15.8328 9.89011 15.4956 10.3183 15.4956H22.7241C23.1524 15.4956 23.4995 15.8328 23.4995 16.2487C23.4995 16.6647 23.1524 17.0019 22.7241 17.0019H10.3183C9.89011 17.0019 9.54297 16.6647 9.54297 16.2487Z" fill="#5688FF"/>
<path d="M9.54297 20.7676C9.54297 20.3516 9.89011 20.0144 10.3183 20.0144H16.5212C16.9495 20.0144 17.2966 20.3516 17.2966 20.7676C17.2966 21.1835 16.9495 21.5207 16.5212 21.5207H10.3183C9.89011 21.5207 9.54297 21.1835 9.54297 20.7676Z" fill="#5688FF"/>
<path d="M26.4994 22.4773C26.3707 22.6591 26.242 22.8409 26.1133 23.0217C26.0219 23.114 25.9305 23.2054 25.8382 23.2968C25.619 23.4981 25.3989 23.6995 25.1789 23.9009C25.0875 23.9923 24.9961 24.0836 24.9047 24.175C24.6846 24.3773 24.4645 24.5787 24.2453 24.7801C24.153 24.8714 24.0616 24.9628 23.9702 25.0542C23.7502 25.2555 23.531 25.4569 23.3109 25.6583C23.2195 25.7497 23.1281 25.841 23.0358 25.9333C22.8344 26.116 22.6329 26.2988 22.4315 26.4824L22.1023 26.8115C21.8822 27.0129 21.6621 27.2143 21.443 27.4157C21.3507 27.508 21.2593 27.5993 21.1679 27.6907C20.9851 27.8557 20.8014 28.0198 20.6186 28.1848H20.5636C20.5636 27.0157 20.5803 25.8457 20.5589 24.6766C20.5393 23.5662 21.4598 22.5752 22.4474 22.523C22.7738 22.5062 23.1011 22.4773 23.4284 22.4754C24.4524 22.4707 25.4763 22.4763 26.4994 22.4773Z" fill="#858599"/>
<path d="M22.7189 3.91012C22.7124 3.92783 22.7087 3.94554 22.7087 3.96419L22.704 3.95766C22.6285 3.97817 22.5529 4.01547 22.4765 4.01547C17.8453 4.01733 13.2133 4.01547 8.58118 4.0136C7.74373 3.95114 7.10865 4.30075 6.65822 4.98786C6.40923 5.36917 6.33089 5.80549 6.33089 6.25952C6.33182 12.1265 6.33182 17.9935 6.33182 23.8595C6.33182 23.9966 6.32996 24.1346 6.32903 24.2716C6.27587 24.2772 6.22178 24.2819 6.16769 24.2875C5.6669 24.2837 5.16611 24.267 4.66532 24.28C3.53411 24.3089 2.67801 23.5593 2.42436 22.5217C2.41876 22.4984 2.39172 22.4807 2.37493 22.4602C2.36001 22.4471 2.34602 22.4331 2.33203 22.4191C2.36187 22.1917 2.4169 21.9651 2.4169 21.7376C2.42156 15.3579 2.42809 8.97812 2.40757 2.59837C2.4057 1.98398 2.46166 1.42739 2.83655 0.933272C3.26087 0.375755 3.82414 0.0671622 4.52823 0.0671622C9.87559 0.0625007 15.2239 0.0531777 20.5712 0.0718237C21.8339 0.0764853 22.6555 0.981752 22.705 2.20866C22.7283 2.7755 22.7152 3.34328 22.7189 3.91012Z" fill="#26DAC2"/>
<path d="M6.32913 26.152C6.27504 26.056 6.17712 25.9599 6.17339 25.8621C6.15847 25.3372 6.16593 24.8123 6.16593 24.2874C6.22002 24.2818 6.27411 24.2771 6.32726 24.2715C6.32819 24.8981 6.32819 25.5255 6.32913 26.152Z" fill="white"/>
<path d="M22.765 3.9631C22.7464 3.96403 22.7287 3.96403 22.7109 3.96496C22.7109 3.94632 22.7147 3.9286 22.7212 3.91089C22.7361 3.9286 22.7501 3.94538 22.765 3.9631Z" fill="#FBFDFF"/>
</g>
<defs>
<clipPath id="clip0_1944_4695">
<rect width="28" height="28" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}

export default FileIcon

import { parseISO } from "date-fns";

export function findDaysUntilElection(electionDate: string): number {
  if (!electionDate) return 0;
  const currentDate = new Date();
  const electionDateTime = parseISO(electionDate);

  let timeDifference = electionDateTime.getTime() - currentDate.getTime();

  if (timeDifference < 0) {
    timeDifference = 0;
  }
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const daysUntilElection = Math.ceil(timeDifference / millisecondsPerDay);
  return daysUntilElection;
}

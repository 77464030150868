import PromptSuggestions from "./PromptSuggestions";
import AiChatBox from "./AiChatBox";
import PromptModal from "./PromptModal";
import { useConversationalAI } from "./useConversationalAi";

const ConversationalAI = () => {
  const { chatDivRef, setIsModalOpen, electionInfo, isLoading } =
    useConversationalAI();

  return (
    <>
      <div className="card card-conversationlAi shadow-2">
        <div className="card-conversationlAi-content">
          {isLoading ? (
            <div className="placeholder-glow">
              <div className="placeholder col-12 mb-2 py-4"></div>
            </div>
          ) : electionInfo ? (
            <>
              <h1 className="mb-2 text-gredient ">
                Welcome,
                <span className="">
                  {" "}
                  future{" "}
                  {electionInfo?.positionTitle ||
                    electionInfo?.electionPosition}{" "}
                  {electionInfo?.fullName}!
                </span>{" "}
              </h1>
            </>
          ) : null}

          {isLoading ? (
            <div className="placeholder-glow text-center">
              <p className="sub-text placeholder col-8 mb-6"></p>
            </div>
          ) : (
            <p className="sub-text">
              <span className="text-primary-p3 font-semibold">
                {electionInfo?.daysUntilElection}{" "}
              </span>
              days until elections. Here are the things to get started.{" "}
            </p>
          )}

          {isLoading ? (
            <div className="d-flex h-100">
              <div className="suggestion-box placeholder-glow">
                <div className="placeholder col-4 flex-1 pt-5 px-2 pb-5 mb-3"></div>
                <div className="placeholder col-4 flex-1 pt-5 px-2 pb-5 mb-3"></div>
                <div className="placeholder col-4 flex-1 pt-5 px-2 pb-5 mb-3"></div>
              </div>
            </div>
          ) : (
            <PromptSuggestions />
          )}

          <div
            className=""
            data-bs-toggle="modal"
            data-bs-target="#chatAiModal"
            ref={chatDivRef}
            onClick={() => setIsModalOpen(true)}
          >
            <AiChatBox />
          </div>
        </div>
      </div>
      <PromptModal />
    </>
  );
};
export default ConversationalAI;

import SideNavbar from "../../components/common/navbar/SideNavbar";
import { Link } from "react-router-dom";
import Stepper from "../components/Stepper";
import InfoCard from "../components/InfoCard";
import FileUpload from '../components/FileUpload';
import TrashIcon from "img/svg/TrashIcon";

const AddDonor = () => {
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="row">
            <div className="col-md-4">
              <div className="accountSetup accountSetup-fixed">
                <div className="accountSetup-header">
                  <h5>Account setup list</h5>
                  <p>Setup your account to use Campaign Brain</p>
                </div>
                <div className="accountSetup-body">
                  <Stepper />
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="card card-accountSetup shadow-2">
                <div className="card-title">
                  <h4>Add donor information</h4>

                  {/* Info Card */}
                  <InfoCard />
                </div>

                <div className="card-body">
                  <div className="donor-options">
                  <form action="">
                    <div className="btn-checkGroup d-flex gap-3 mb-3">
                      <div className="btn-checkGroup-item btn-checkGroup-item--icon">
                        <input
                          type="radio"
                          className="btn-check form-check-input"
                          name="options"
                          id="option11"
                          autoComplete="off"
                          
                        />
                        <label
                          className="btn btn-outline-primary rounded-pill ps-5"
                          htmlFor="option11"
                        >
                          File upload
                        </label>
                      </div>

                      <div className="btn-checkGroup-item btn-checkGroup-item--icon">
                        <input
                          type="radio"
                          className="btn-check form-check-input"
                          name="options"
                          id="option12"
                          autoComplete="off"
                        />
                        <label
                          className="btn btn-outline-primary rounded-pill ps-5"
                          htmlFor="option12"
                        >
                          Add a donor manually
                        </label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="custom-label">File upload</label>
                      <p>
                      Upload a file of any format containing information about potential donors from your personal network
                      </p>

                      <FileUpload />

                    </div>


                    <div className="mb-3">
                      <label className="custom-label">Add a donor manually</label>

                      <table className="table table-borderless">
                      
                        <tbody>
                          <tr>

                            <td>
                              <label htmlFor="inputName" className="form-label">Name</label>
                              <input type="text" className="form-control" id="inputName" />
                            </td>
                            <td>
                              <label htmlFor="inputPhone" className="form-label">Phone</label>
                              <input type="text" className="form-control" id="inputPhone" />
                            </td>
                            <td>
                              <label htmlFor="inputEmail" className="form-label">Email</label>
                              <input type="email" className="form-control" id="inputEmail" />
                            </td>
                            <td>
                            <button className="btn btn-link pt-0 px-0 mt-4"> <span className="svg-neutral-2"> <TrashIcon /> </span> </button>
                            </td>
                        
                          </tr>
                        </tbody>
                        </table>

                        <button className="btn btn-link text-primary"> <span className="icon-plus me-2"></span> Add another donor </button>

                    
                    </div>

                    
                  </form>
                  </div>
                
                </div>

                <div className="card-footer shadow-6 d-flex gap-4 justify-content-end">
                  <Link to="/candidate/your-story" className="btn btn-link text-primary btn-xs">
                    Not Now{" "}
                  </Link>
                  <Link to="/candidate/your-story" className="btn btn-primary rounded-pill btn-xs px-6">
                    Next{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </div>
    </div>
  );
};

export default AddDonor;

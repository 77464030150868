import React, { useEffect, useRef, useState } from "react";

const DefaultScript = () => {
  let contentRef = useRef<HTMLDivElement>(null);

    const defaultScript = { name: "Anish", votername: "Robert", city: "Ktm", strength: "code", position: 'mayor' };
    const DEFAULT_SCRIPT_HTML = `<div class=""> <p> Hi, is <span class="text-bg"> ${defaultScript.votername} </span> available ? </p> <p> Hi,  <span class="text-bg"> ${defaultScript.votername} </span>, my name is  <span class="text-bg"> ${defaultScript.name} </span> and I'm running for  <span class="text-bg"> ${defaultScript.position} </span> , right here in  <span class="text-bg"> ${defaultScript.city} </span>. How are you doing today? </p> <p> Can I count on your support? </p> <p> Thank you so much for your time today! </p> </div>`;
  
  return (
    <>
      <div
        className="textEditor defaultScript form-control"
        contentEditable={false}
        ref={contentRef}
       
        // suppressContentEditableWarning={true}
        // TO DO: Use dom purify while using default script
        dangerouslySetInnerHTML={{ __html: DEFAULT_SCRIPT_HTML ?? "" }}
      ></div>
    </>
  );
};

export default DefaultScript;

import React, { useEffect } from "react";
import CloseIcon from "img/svg/closeIcon";
import { Link } from "react-router-dom";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import CheckSuccess from "img/svg/checkSuccess";
import TrashIconDanger from "img/svg/TrashIconDanger";
import EditIconPrimary from "img/svg/EditIconPrimary";
import SidePanelPlaceholder from "./SidePanelPlaceholder";
import FilterMultiSelectDd from "./FilterMultiSelectDd";
import SearchIcon from "img/svg/SearchIcon";
import Spinner from "./Spinner";
import InfoIconDanger from "img/svg/InfoIconDanger";

const SidePanelFundraise = () => {
  return (
    <aside className="sidePanel control-sidebar shadow-4 ope">
      <div className="sidePanel-header shadow-4">
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="location-info">
            <div className="location-content">
              <div className="voter-location"> Edit Donor </div>
            </div>
          </div>

          <div className="panel-close">
            <Link to="" className="close">
              <CloseIcon />
            </Link>
          </div>
        </div>
      </div>
      <div className="sidePanel-body scroll-y">
        <div className="filter-body">
          <form action="">
            <div className="filter-item">
              <div className="filter-item-body">
                <div className="mb-3">
                  <label htmlFor="donorName" className="form-label">
                    Name
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="donorName"
                    aria-describedby="donorNameHelp"
                    value="Jacob Jones"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="donorEmail" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="donorEmail"
                    aria-describedby="donorEmailHelp"
                    value="jacob.jones@gmail.com"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="donorPhone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="donorPhone"
                    aria-describedby="donorPhoneHelp"
                    value="(603) 768-9234"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="position" className="form-label">
                    {" "}
                    Donor type{" "}
                  </label>
                  <select
                    className="form-select"
                    aria-label="select donor type"
                    name="donorType"
                  >
                    <option disabled hidden value="1" selected>
                      Prospect
                    </option>
                    <option value="1">Prospect</option>
                    <option value="2">Donor</option>
                    <option value="3">Ready for Resoliciation</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="donorSource"
                    className="form-label text-muted"
                  >
                    Source
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="donorSource"
                    aria-describedby="donorSourceHelp"
                    value="Personal Network"
                    disabled
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="position" className="form-label">
                    {" "}
                    Donor status{" "}
                  </label>
                  <select
                    className="form-select"
                    aria-label="select donor status"
                    name="donorStatus"
                  >
                    <option disabled hidden value="1" selected>
                      Choose donor status
                    </option>
                    <option value="1">Not Home</option>
                    <option value="2">Not Interested</option>
                    <option value="3">Donating Later </option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="position" className="form-label">
                    {" "}
                    Contribution amount{" "}
                  </label>
                  <div className="input-group">
                    <span className="input-group-text bg-transparent"> $ </span>
                    <input
                      type="text"
                      className="form-control border-start-0 ps-0"
                      id="contributionAmount"
                      aria-describedby="contributionAmountHelp"
                      placeholder="Insert contribution amount"
                    />
                  </div>
                  <div className="invalidInput form-text text-danger">
                    <InfoIconDanger /> Amount should be numeric{" "}
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Notes
                  </label>
                  <div className="position-relative">
                    <textarea
                      className="form-control"
                      id="Textarea1"
                      rows={3}
                      
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="sidePanel-footer card-footer d-flex justify-content-end border-top">
        <button className="btn btn-link btn-xs rounded-pill me-2">Reset</button>
        <button className="btn btn-primary btn-xs rounded-pill">
          <Spinner /> Update
        </button>
      </div>
    </aside>
  );
};

export default SidePanelFundraise;

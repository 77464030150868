import { useAuth0 } from "@auth0/auth0-react";

const PasswordChangedModal = () => {
  const { logout } = useAuth0();
  return (
    <div
      className="modal fade"
      id="passwordChangedModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header"></div>
          <div className="modal-body text-center px-4">
            <img
              src={require("../../../../img/lock.png")}
              width={56}
              height={56}
              alt="delete warning"
            />
            <h2 className="mt-3">Password Changed </h2>
            <p>
              Signed out from all devices. Please log in with new password to
              continue.
            </p>
          </div>

          <div className="modal-footer justify-content-center">
            <button
              className="btn btn-primary rounded-pill btn-xs"
              onClick={() => logout()}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangedModal;

import React, { useState, useMemo, useCallback } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import LoaderCenter from "components/common/LoaderCenter";
import { useTurfDetail } from "../turf-detail/useTurfDetail";
import { IVoterCoordinates, getUniqueVoterCoordinates } from "../turf-detail/TurfMap";

const TurfMapPreview: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? "",
    libraries: ["drawing"],
    mapIds: [process.env.REACT_APP_GOOGLE_MAP_ID ?? ""],
  });

  const { votersInUniverse } = useTurfDetail();

  const voterCoordinates: IVoterCoordinates[] | undefined = votersInUniverse
    ?.map((voter) => {
      if (voter.geoCoordinates && voter.geoCoordinates.length === 2) {
        return {
          lat: voter.geoCoordinates[0],
          lng: voter.geoCoordinates[1],
          voterId: voter.id,
        };
      }
      return null;
    })
    .filter(
      (coordinates): coordinates is IVoterCoordinates => coordinates !== null
    );

  const uniqueVoterCoordinates =
    voterCoordinates && getUniqueVoterCoordinates(voterCoordinates);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const center = useMemo(() => {
    if (voterCoordinates?.length) {
      return {
        lat: voterCoordinates?.[0]?.lat || 0,
        lng: voterCoordinates?.[0]?.lng || 0,
      };
    }
    return { lat: 0, lng: 0 };
  }, [voterCoordinates]);

  const onMapLoad = useCallback((mapInstance: google.maps.Map) => {
    setMap(mapInstance);
  }, []);

  return isLoaded ? (
    <GoogleMap
      zoom={11}
      center={center}
      mapContainerStyle={{
        width: "100%",
        height: "100%",
        position: "relative",
        borderRadius: "8px",
        pointerEvents: "none",
      }}
      options={{
        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
        fullscreenControl: false,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        draggable: false,
      }}
      onLoad={onMapLoad}
    >
      {uniqueVoterCoordinates?.map((marker, index) => (
        <MarkerF position={marker} key={index} />
      ))}
    </GoogleMap>
  ) : (
    <LoaderCenter />
  );
};

export default TurfMapPreview;

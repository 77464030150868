import InfoIconDanger from "img/svg/InfoIconDanger";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import CheckSuccess from "img/svg/checkSuccess";
import FileIcon from "img/svg/fileIcon";
import React, { useEffect, useState } from "react";
import useVoterDetails from "./useVoterDetail";
import { useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import * as Yup from "yup";

import {
  dateFormatter,
  formatFileSize,
} from "../professional-details/useProfessionalDetils";
import { yupResolver } from "@hookform/resolvers/yup";
import toastNotify from "utils/toastNotify";
import AlertBanner from "templates/components/AlertBanner";
import useCheckDashboardCompletedStatus from "utils/useDashboardCompletedStatus";
import VoiceMailUpload from "./components/VoiceMailUpload";

const VoterDetails = () => {
  const {
    votersData,
    voterFileData,
    editable,
    setEditable,
    updateVotersSubmit,
    postVotersSubmit,
    userInfo
  } = useVoterDetails();

  const { userData } = useCheckDashboardCompletedStatus()

  const voterSchema = Yup.object().shape({
    vanIntegration: Yup.string(),
  });

  const form = useForm({
    resolver: yupResolver(voterSchema as any),
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = form;

  const handleDownload = (url: string | null | undefined) =>
    url ? window.open(url, "_blank") : null;

  const handleSubmitForm = (data: any) => {
    const { vanIntegration, voteGoal } = data;
    const voteGoalNull = voteGoal === "" ? null : voteGoal;
    const vanIntegrationNull = vanIntegration === "" ? null : vanIntegration;

    if (data.action === "saveVanAction") {
      if (!votersData?.id) {
        postVotersSubmit({ ngpVanLink: vanIntegrationNull });
        return;
      }
      updateVotersSubmit({ ngpVanLink: vanIntegrationNull });
    }
    if (data.action === "saveVoteGoalAction") {
      if (isNaN(voteGoalNull) || voteGoalNull < 0) {
        toastNotify("error", "Vote Goal must be a number");
        return;
      }
      if (!votersData?.id) {
        postVotersSubmit({
          voteGoal: voteGoalNull,
        });
        return;
      }
      updateVotersSubmit({ voteGoal: voteGoalNull });
    }
  };

  useEffect(() => {
    if (votersData) {
      Object.entries(votersData).forEach(([key, value]: any) => {
        setValue(key, value);
      });
    }
  }, [votersData]);

  return (
    <div className="row mt-5">
      <div className="col-md-8">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="settings">
            <div className="settings-header d-flex justify-content-between">
              <div className="">
                <h4> Voter </h4>
                <p className="body-4 text-muted">
                  View or edit your voter details
                </p>
              </div>
            </div>
            <div className="settings-body">
              {userData?.isDashboardSetupCompleted === false ? (
                <div className="mt-4">
                  <AlertBanner />
                </div>
              ) : (
                <div className="voterDetail-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> NGP VAN Integration </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          {editable === "van" ? (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter VAN integration link"
                                {...register("vanIntegration")}
                                defaultValue={votersData?.ngpVanLink}
                              />
                              {errors?.vanIntegration?.message && (
                                <div
                                  id="emailHelp"
                                  className="form-text text-danger fs-8"
                                >
                                  {" "}
                                  <span className="me-2">
                                    {" "}
                                    <InfoIconDanger />{" "}
                                  </span>{" "}
                                  {errors?.vanIntegration?.message as string}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {votersData?.ngpVanLink ? (
                                <span className="body-4">
                                  {" "}
                                  {votersData?.ngpVanLink}
                                </span>
                              ) : (
                                <span className="body-4 text-muted">
                                  {" "}
                                  Not Provided
                                </span>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            {editable === "van" ? (
                              <>
                                <button
                                  className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                  onClick={() => {
                                    setEditable(null);
                                    setValue("action", null);
                                    setValue(
                                      "vanIntegration",
                                      votersData?.ngpVanLink ?? ""
                                    );
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                                  type="submit"
                                  onClick={() => {
                                    setValue("action", "saveVanAction");
                                  }}
                                >
                                  Save
                                </button>
                              </>
                            ) : (
                              <button
                                className="btn btn-link btn-xs px-1 py-0"
                                onClick={() => {
                                  setEditable("van");
                                  setValue("action", null);
                                }}
                              >
                                {votersData?.ngpVanLink ? "Edit" : "Add"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Vote goal </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          {editable === "votegoal" ? (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Vote Goal"
                                {...register("voteGoal", {
                                  pattern: {
                                    value: /^\d*$/,
                                    message: "This must be a number",
                                  },
                                })}
                              />
                              {errors?.voteGoal?.message && (
                                <div
                                  id="emailHelp"
                                  className="form-text text-danger fs-8"
                                >
                                  {" "}
                                  <span className="me-2">
                                    {" "}
                                    <InfoIconDanger />{" "}
                                  </span>{" "}
                                  {errors?.voteGoal?.message as string}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {votersData?.voteGoal ? (
                                <span className="h4">
                                  {" "}
                                  {votersData?.voteGoal}
                                </span>
                              ) : (
                                <span className="body-4 text-muted">
                                  {" "}
                                  Not Provided
                                </span>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            {editable === "votegoal" ? (
                              <>
                                <button
                                  className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                  onClick={() => {
                                    setEditable(null);
                                    setValue("action", null);
                                    setValue("voteGoal", votersData?.voteGoal);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                                  type="submit"
                                  onClick={() => {
                                    setValue("action", "saveVoteGoalAction");
                                  }}
                                >
                                  Save
                                </button>
                              </>
                            ) : (
                              <button
                                className="btn btn-link btn-xs px-1 py-0"
                                onClick={() => {
                                  setEditable("votegoal");
                                  setValue("action", null);
                                }}
                              >
                                {votersData?.voteGoal ? "Edit" : "Add"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />

                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Voter list </span>
                      <div className="d-flex gap-2">
                        <InfoIconSecondary />
                        <span className="body-4 text-muted mb-0">
                          {" "}
                          To change or export your voter list, go to the Voter
                          Database
                        </span>
                      </div>
                    </div>
                    <div className="col-md-8">
                      {voterFileData ? (
                        <div className="uploadedCard mb-0 p-0 border-0">
                          <div className="uploadedCard-icon">
                            <div className="iconBox">
                              <FileIcon />
                            </div>

                            <div className="iconInfo">
                              <span className="iconInfo-title">
                                {" "}
                                {voterFileData?.fileName}{" "}
                              </span>
                              <p className="iconInfo-desc">
                                {" "}
                                {/* 1.5 MB, 6/23/23 at 11:45 am{" "} */}
                                {formatFileSize(voterFileData?.size)},{" "}
                                {dateFormatter(voterFileData?.updatedAt)}
                              </p>
                            </div>
                          </div>
                          <div className="uploadedCard-info">
                            <button
                              className="btn btn-link rounded-pill btn-xs"
                              onClick={() =>
                                handleDownload(voterFileData?.url)
                              }
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      ) : (
                        <span className="body-4 text-muted"> Not Provided </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <DevTool control={control} /> */}
        </form>

        <VoiceMailUpload
          candidateElectionId={userInfo?.candidate?.elections?.[0]?.id || 0}
          sectionType='voter'  // 'voter' or 'fundraise'
        />
      </div>
    </div>
  );
};

export default VoterDetails;

import { useQuery } from "@tanstack/react-query";
import InfoIconWarningX18 from "img/svg/InfoIconWarningX18";
import React from "react";
import { useNavigate } from "react-router-dom";
import CreateAuthAxiosInstance from "utils/authAxios";
import { setLocalStorageItem } from "utils/localStorageHelper";

const AlertBanner = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/dashboard");
  };

  return (
    <div className="alertBanner alertBanner-warning">
      <div className="alertBanner-header">
        <InfoIconWarningX18 />
        Add professional details in the setup account of dashboard to display
        here.
      </div>
      <button
        type="button"
        className="btn bg-white text-warning rounded-pill btn-xs"
        onClick={handleNavigate}
      >
        Continue dashboard
      </button>
    </div>
  );
};

export default AlertBanner;

import React, { useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import Select, { components } from "react-select";

interface SingleSelecProps {
  placeholder: string;
  control: any;
  EditTurfDetails: any;
  turfAttemptedData: any;
  onVolunteerChange: (selectedOption: { value: number }) => void;
}

const SingleSelectDd = ({
  placeholder,
  control,
  EditTurfDetails,
  turfAttemptedData,
  onVolunteerChange,
}: SingleSelecProps) => {
  const SingleValue = (props: any) => {
    return (
      <components.SingleValue {...props}>
        {props.data.label}
      </components.SingleValue>
    );
  };

  const transformedOptions = useMemo(() => {
    if (turfAttemptedData?.id) {
      let volunteersData = turfAttemptedData.volunteers.map((item: any) => ({
        label: item.fullName,
        value: item.id,
        subtitle: "Volunteer",
      }));
      volunteersData.unshift({
        label: turfAttemptedData.fullName,
        value: turfAttemptedData.id,
        subtitle: "You",
      });
      return volunteersData;
    }
    return [];
  }, [turfAttemptedData]);

  return (
    <Controller
      name="turfAttempted"
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        if (value !== undefined) {
          onVolunteerChange(value);
        }
        return (
          <Select
            options={transformedOptions}
            formatOptionLabel={CustomDdList}
            components={{ SingleValue }}
            menuPosition="fixed"
            placeholder={<div>Choose assignee</div>}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#0B45A5" : "#CBCBD8",
              }),
            }}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        );
      }}
    />
  );
};

export default SingleSelectDd;

const CustomDdList = ({
  label,
  subtitle,
}: {
  label: string;
  subtitle: string;
}) => {
  return (
    <div className="customDDList">
      <img
        src={require("../../../../../../../img/avatar.png")}
        className="img-fluid"
        alt=""
      />
      <div className="customDDList-body">
        <span className="customDDList-title"> {label} </span>
        <span className="customDDList-subtitle"> {subtitle} </span>
      </div>
    </div>
  );
};

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Spinner from "components/common/Spinner";
import CustomTooltip from "components/common/CustomTooltip";
import HelpIcon from "img/svg/helpIcon";
import React, { useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";
import { AxiosResponse } from "axios";
import SingleSelectDd from "./SingleSelectDd";
import { useNavigate } from "react-router-dom";
import { SELECTED_UNIVERSE_ID } from "../turf-detail/useTurfDetail";

const ACTIVATE_UNIVERSE_TOOLTIP = `Activating this universe will allow your campaign to target this group of voters door-to-door.`;

export interface IAddUniverseFormInput {
  universeName: string;
  filterTypeObj: {
    filterType: string;
    votersCount: number;
  } | null;
  isActive: boolean;
}
interface IFinalAddUniverseData {
  name: string;
  filterType: string;
  candidateId: number;
  isActive: boolean;
  type: string;
}

const schema = yup.object().shape({
  universeName: yup.string().required().max(40),
  filterTypeObj: yup.object().nullable().shape({
    filterType: yup.string().required(),
    votersCount: yup.number().required(),
  }),
  isActive: yup.boolean().required(),
});

export const AddTurfModal = React.memo(
  ({ candidateId }: { candidateId: number }) => {
    const authAxios = CreateAuthAxiosInstance();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const backButtonRef = useRef<HTMLButtonElement>(null);
    const cancelButtonRef = useRef<HTMLButtonElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      reset,
      setValue,
    } = useForm<IAddUniverseFormInput>({
      resolver: yupResolver(schema),
    });

    const {
      mutate: addUniverseMutation,
      data: addUniverseMutationData,
      isPending: isPendingAddUniverse,
    } = useMutation({
      mutationFn: (data: IFinalAddUniverseData) => authAxios.post(`api/v1/universes`, data),
      onSuccess: handleAddUniverseSuccess,
      onError: (error: any) => {
        console.log("on error", error?.response?.data?.message);
        toastNotify("error", "Error! Could not add universe!");
      },
    });

    function handleAddUniverseSuccess(response: AxiosResponse<any, any>) {
      const { id: universeId, isActive } = response.data;

      toastNotify("success", "Universe added successfully!");
      queryClient.invalidateQueries({ queryKey: ["universeList"] });

      if (isActive) {
        localStorage.setItem(SELECTED_UNIVERSE_ID, String(universeId));
        navigate("/voters/turf", { state: { universeId: universeId } });
        if (cancelButtonRef.current) cancelButtonRef.current.click();

        return;
      }

      if (backButtonRef.current) backButtonRef.current.click();
    }

    const handleFormReset = () => {
      timeoutRef.current = setTimeout(() => {
        reset();
        setValue("filterTypeObj", null);
      }, 200);
    };

    useEffect(() => {
      return () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      };
    }, []);

    const onFinalSubmit = (submittedValue: IAddUniverseFormInput) => {
      const { universeName, filterTypeObj, isActive } = submittedValue;

      if (!(universeName && filterTypeObj?.filterType && candidateId)) return;

      const finalSubmittedData: IFinalAddUniverseData = {
        name: universeName || "",
        filterType: filterTypeObj.filterType || "",
        candidateId: candidateId,
        isActive: isActive || false,
        type: "Custom",
      };

      addUniverseMutation(finalSubmittedData);
    };

    return (
      <>
        <div
          className="modal fade"
          id="addTurfModal"
          data-bs-backdrop="static"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">Add universe</h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleFormReset}
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                <p className="">
                  Add universe to create the target groups for door-to-door
                  voter contact.
                </p>
                <form id="add-universe" onSubmit={handleSubmit(onFinalSubmit)}>
                  <div className="mb-3 required">
                    <label htmlFor="exampleInputName1" className="form-label">
                      Universe Name &nbsp;
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      aria-describedby="nameHelp"
                      placeholder="Enter universe name"
                      {...register("universeName")}
                    />
                    {!!errors?.universeName?.message?.length && (
                      <span className="invalidInput form-text text-danger">
                        {handleErrorMessage(
                          errors.universeName.message,
                          "universeName",
                          "Universe Name"
                        )}
                      </span>
                    )}
                  </div>
                  <div className="mb-3 required">
                    <label htmlFor="exampleInputPhone" className="form-label">
                      Filter &nbsp;
                    </label>
                    <SingleSelectDd control={control} errors={errors} />
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      {...register("isActive")}
                    />
                    <label className="body-4" htmlFor="exampleCheck1">
                      Activate and continue this universe. &nbsp;
                      <CustomTooltip content={ACTIVATE_UNIVERSE_TOOLTIP}>
                        <HelpIcon />
                      </CustomTooltip>
                    </label>
                  </div>
                </form>
              </div>
              <div className="modal-footer justify-content-between border-1">
                <button
                  ref={backButtonRef}
                  className="btn btn-link text-primary rounded-pill btn-xs"
                  data-bs-target="#manageTurfModal"
                  data-bs-toggle="modal"
                  onClick={handleFormReset}
                >
                  Back
                </button>
                <div>
                  <button
                    ref={cancelButtonRef}
                    className="btn btn-link text-primary rounded-pill btn-xs me-1"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleFormReset();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary rounded-pill btn-xs px-6"
                    type="submit"
                    form="add-universe"
                    disabled={isPendingAddUniverse}
                  >
                    <div className="d-flex align-items-center">
                      {isPendingAddUniverse && <Spinner />}
                      Add universe
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export function handleErrorMessage(
  message: string,
  oldWord: string,
  newWord: string
) {
  if (!message.includes(oldWord)) return;

  return message.replace(oldWord, newWord) + " !";
}

import {
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
} from "react-hook-form";
import { IOnboardingFormData } from "../../multi-step-forms/onboarding/OnboardingForm";
import { useEffect, useState } from "react";
import axios from "axios";

interface IAboutCandidateFormProps {
  register: UseFormRegister<Partial<IOnboardingFormData>>;
  errors: FieldErrors<FieldValues> | undefined;
  getValues: UseFormGetValues<Partial<IOnboardingFormData>>;
  resetField: UseFormResetField<Partial<IOnboardingFormData>>;
}

interface IState {
  name: string;
  id: number;
}

export function AboutCandidateForm({
  register,
  errors,
  getValues,
  resetField,
}: IAboutCandidateFormProps) {
  const [states, setStates] = useState<Array<IState>>([]);
  const [positions, setPositions] = useState<Array<string>>([]);
  const [counties, setCounties] = useState<Array<string>>([]);
  const selectedState = states.filter(
    (state) => state.name === getValues("state")
  );

  useEffect(() => {
    (async () => {
      try {
        // Get states
        const stateResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/address/state`
        );
        setStates(
          stateResponse.data.map((item: IState) => ({
            name: item.name,
            id: item.id,
          }))
        );
        // Get positions
        const positionResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/positions`
        );
        setPositions(
          positionResponse.data.map((item: { title: string }) => item.title)
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (!counties.length && selectedState.length) {
      (async () => {
        try {
          // Get counties based on selcted state
          const countiesResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/address/state/${selectedState[0].id}/counties`
          );
          setCounties(
            countiesResponse.data.map((item: { name: string }) => item.name)
          );
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [selectedState, counties]);

  return (
    <div className="formArea bg-grey">
      <div className="formContents">
        <div className="formContents-header">
          <h1>Tell us about your campaign </h1>
          <p>This allows us to best tailor Campaign Brain to your race</p>
        </div>
        <div className="formContents-body">
          <div className="mb-3 required">
            <label htmlFor="fullName" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              aria-describedby="nameHelp"
              placeholder="Enter your full name as it appears on the ballot"
              {...register("fullName")}
            />
            {errors?.fullName?.message && (
              <span className="invalidInput form-text text-danger">
                {errors.fullName.message as string}
              </span>
            )}{" "}
          </div>
          <div className="mb-3 required">
            <label htmlFor="position" className="form-label">
              Position you are running for
            </label>

            <select
              className="form-select"
              aria-label="select Position you are running for"
              {...register("position")}
              value={getValues("position")}
            >
              <option disabled hidden value="">
                Choose position for election
              </option>
              {positions.map((position, index) => (
                <option value={position} key={index}>
                  {position}
                </option>
              ))}
            </select>
            {errors?.position?.message && (
              <span className="invalidInput form-text text-danger">
                {errors.position.message as string}
              </span>
            )}
          </div>

          <div className="mb-3 required">
            <label htmlFor="state" className="form-label">
              State
            </label>
            <select
              className="form-select"
              aria-label="select State"
              {...register("state", {
                onChange: () => {
                  resetField("county");
                  setCounties([]);
                },
              })}
              value={getValues("state")}
            >
              <option disabled hidden value="">
                Choose state
              </option>
              {states.map((state, index) => (
                <option value={state.name} key={index}>
                  {state.name}
                </option>
              ))}
            </select>
            {errors?.state?.message && (
              <span className="invalidInput form-text text-danger">
                {errors.state.message as string}
              </span>
            )}
          </div>

          <div className="mb-3 required">
            <label htmlFor="county" className="form-label">
              County
            </label>
            <select
              className="form-select"
              aria-label=" select County"
              {...register("county")}
              value={getValues("county")}
              disabled={counties.length > 0 ? false : true}
            >
              <option hidden value="">
                Choose county
              </option>

              {counties.map((county, index) => (
                <option value={county} key={index}>
                  {county}
                </option>
              ))}
            </select>
            {errors?.county?.message && (
              <span className="invalidInput form-text text-danger">
                {errors.county.message as string}
              </span>
            )}
          </div>

          <div className="mb-3 required">
            <label htmlFor="position" className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              placeholder="Enter your city, town, or municipality"
              {...register("city")}
            />
            {errors?.city?.message && (
              <span className="invalidInput form-text text-danger">
                {errors.city.message as string}
              </span>
            )}
          </div>

          {/* new field district */}
          <div className="mb-3 ">
            <label htmlFor="district" className="form-label">
              District
            </label>

            <input
              type="text"
              className="form-control"
              id="district"
              placeholder="Enter district"
              {...register("district")}
            />
            {errors?.district?.message && (
              <span className="invalidInput form-text text-danger">
                {errors.district.message as string}
              </span>
            )}
          </div>

          <button
            type="submit"
            className="btn btn-primary w-100 rounded-pill mt-2"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { globalQueryConfig } from "utils/reactQuery"
import CreateAuthAxiosInstance from 'utils/authAxios'
import toastNotify from 'utils/toastNotify'
import axios from 'axios'

const ActBlueSection = () => {
  const authAxios = CreateAuthAxiosInstance()
  const queryClient = useQueryClient()

  const {
    data: actBlueStatus,
    refetch: refetchActBlueStatus
  } = useQuery({
    queryFn: () => authAxios("api/v1/actblue/actblue-status").then(data => data?.data),
    queryKey: ["actBlueStatus"],
    ...globalQueryConfig,
  })

  const {
    mutate: sendActBlueIntegrationEmail,
    isPending: isPendingActBlueEmailSend
  } = useMutation({
    mutationFn: () => authAxios.post("api/v1/email/actblue-integration").then(data => data?.data),
    onSuccess: handleOnSuccessEvent,
    onError: handleOnErrorEvent
  })

  function handleOnSuccessEvent() {
    queryClient.invalidateQueries({ queryKey: ["actBlueIntegration"] })
    refetchActBlueStatus()
    toastNotify("success", "Email sent!")
  }

  function handleOnErrorEvent(error: unknown) {
    if (axios.isAxiosError(error)) {
      console.log("on error", error)
      toastNotify("error", error?.response?.data?.message)
    }
  }

  return (
    <div className="mt-5">
      <div className="settings">
        <div className="settings-header d-flex justify-content-between">
          <div className="">
            <h4>Integrate Actblue</h4>
            <p className="body-4 text-muted">
              Send ActBlue integration to sync the funds raised. The
              integration it is expected to take approximately 2 days.
            </p>
          </div>
        </div>
        <div className="settings-body">
          <div className="voterDetail-info">
            <hr className="mt-3 mb-3" />
            <div className="row">
              <div className="col-md-4">
                <span className="body-2 fs-7">Actblue</span>
              </div>
              <div className="col-md-8">
                <div className="row justify-content-end">
                  <div className="col-md-8">
                    {!actBlueStatus?.actBlueIntegrationStatus ? (
                      <span className="body-4 text-muted">
                        Integration request not sent
                      </span>
                    ) : (
                      <div className="d-flex flex-column">
                        <div>
                          {actBlueStatus?.actBlueIntegrationStatus === "Not Linked" && (
                            <>
                              <span className="body-4">Integration request sent</span>
                              &nbsp;
                              <span className="badge bg-warning-w2 rounded-pill px-2 font-regular">Not Linked</span>
                            </>
                          )}
                          {actBlueStatus?.actBlueIntegrationStatus === "Linked" && (
                            <>
                              <span className="body-4">Integrated </span>
                              &nbsp;
                              <span className="badge bg-success-s2 rounded-pill px-2 font-regular">Linked</span>
                            </>
                          )}
                        </div>
                        {/* <span className="body-4 text-muted">
                          Sent on 20 Feb 2024
                        </span> */}
                      </div>
                    )}
                  </div>
                  {actBlueStatus?.actBlueIntegrationStatus !== "Linked" && (
                    <div className="col-md-4">
                      <div className="d-flex justify-content-end">
                        <button
                          className={`btn btn-link btn-xs px-1 py-0 `}
                          onClick={() => sendActBlueIntegrationEmail()}
                        >
                          {isPendingActBlueEmailSend ? "Sending..." : "Send request"}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr className="mt-3 mb-3" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActBlueSection
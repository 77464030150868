import ChevronDownFilledIcon from "img/svg/ChevronDownFilledIcon"
import React, { useEffect, useState } from "react"
import { extractFirstWordInSmallLetter } from "utils/stringManupulation"
import BudgetCell from "./BudgetCell"
import AddBudgetRow from "./AddBudgetRow"
import SubCategoryTitle from "./SubCategoryTitle"
import {
  IBudgetDetail,
  ICategory,
  IMonthlyBudget,
  IMonthlyCategoryBudget,
  IMonthlySubcategoryBudget,
  ISubcategory,
} from "../BudgetDetailType"
import { IDeleteTarget } from "./BudgetTable"
import ChevronRightFilledIcon from "img/svg/ChevronRightFilledIcon"
import CreateAuthAxiosInstance from "utils/authAxios"
import { useQuery } from "@tanstack/react-query"
import { DeleteModal } from "./DeleteModal"

interface ICategorySection {
  index: number
  category: ICategory
  monthColumnData: IMonthlyBudget[]
  setGrandTotalBudgetDetail: React.Dispatch<React.SetStateAction<Partial<IBudgetDetail> | null>>
  isActBlueIntegrated: boolean
}

const CategorySection = ({
  index,
  category: categoryDetail,
  monthColumnData,
  setGrandTotalBudgetDetail,
  isActBlueIntegrated
}: ICategorySection) => {
  const authAxios = CreateAuthAxiosInstance();

  const [isAddingNewSubCategory, setIsAddingNewSubCategory] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useState<number | null>(null)
  const [isCollapsed, setCollapsed] = useState(false)
  const [deleteTarget, setDeleteTarget] = useState<IDeleteTarget | null>(null);

  const zIndexHandler = (index: number): void => setCurrentIndex(index)
  const handleCollapse = () => setCollapsed(!isCollapsed)

  const {
    data: categoryData,
    isFetching: isFetchingCategoryData,
    refetch: refetchCategoryData,
  } = useQuery({
    queryKey: ['category', categoryDetail?.id],
    queryFn: () => authAxios(
      `api/v1/budgets/categories/${categoryDetail?.id}/subcategories`
    )
      .then(data => data?.data?.[0]),
    refetchOnWindowFocus: false,
    enabled: false
  });

  useEffect(() => {
    if (categoryData?.totalBudget && categoryData?.monthlyBudgets) {
      const tempGrandTotalBudgetDetail = {
        totalBudget: categoryData.totalBudget,
        monthlyBudgets: categoryData.monthlyBudgets
      }

      setGrandTotalBudgetDetail(tempGrandTotalBudgetDetail)
    }
  }, [categoryData?.totalBudget, categoryData?.monthlyBudgets])

  const handleDeleteTargetUpdation = (targetData: IDeleteTarget) => setDeleteTarget(targetData)

  const category = categoryData?.categories?.[0] || categoryDetail

  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center gap-2 ps-3 ps-3">
            <button
              className="btn btn-link btn-circle p-0 rounded-circle lh-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${extractFirstWordInSmallLetter(category?.name) || index}`}
              aria-expanded="false"
              aria-controls={`collapse-${extractFirstWordInSmallLetter(category?.name) || index}`}
              onClick={handleCollapse}
            >
              {isCollapsed ? (
                <ChevronDownFilledIcon />
              ) : (
                <ChevronRightFilledIcon />
              )}
            </button>
            <span className="badge badge-secondary rounded-pill text-n1 border border-secondary">
              {category?.name || "N/A"}
            </span>
          </div>
        </td>
        <td>
          <div className="budget__cell non-editable">
            <span>$ {category?.categorySum || 0}</span>
          </div>
        </td>
        {!!category?.monthlyCategoryBudgets?.length &&
          category.monthlyCategoryBudgets.map(
            (budgetItem: IMonthlyCategoryBudget, index: number) => (
              <td key={index}>
                <div className="budget__cell non-editable">
                  <span>$ {budgetItem?.amount || 0}</span>
                </div>
              </td>
            )
          )}
      </tr>
      <tr className="subCatWrapper">
        <td colSpan={monthColumnData?.length + 3}>
          <div
            className="collapse"
            id={`collapse-${extractFirstWordInSmallLetter(category?.name) || index}`}
          >
            <table className="table">
              <tbody>
                {/* Sub-category starts  */}
                {!!category?.subcategories?.length &&
                  category.subcategories.map(
                    (subCategory: ISubcategory, index: number) => (
                      <tr key={subCategory?.id || 0}>
                        <td style={{ zIndex: `${currentIndex === index ? "10" : "auto"}`, }}>
                          <SubCategoryTitle
                            title={subCategory?.name || "N/A"}
                            index={index}
                            zIndexHandler={zIndexHandler}
                            categoryId={subCategory?.categoryId || 0}
                            subcategoryId={subCategory?.id || 0}
                            handleDeleteTargetUpdation={handleDeleteTargetUpdation}
                            isLastItem={index === (category.subcategories.length - 1)}
                            refetchCategoryData={refetchCategoryData}
                            isActBlueSubCategory={subCategory?.name?.toLowerCase() === 'actblue'}
                            isActBlueIntegrated={isActBlueIntegrated}
                          />
                        </td>
                        <td>
                          <div className="budget__cell non-editable">
                            <span>$ {subCategory?.subcategorySum || 0}</span>
                          </div>
                        </td>
                        {!!subCategory?.monthlySubcategoryBudgets?.length &&
                          subCategory.monthlySubcategoryBudgets.map(
                            (
                              budgetItem: IMonthlySubcategoryBudget,
                              index: number
                            ) => (
                              <td key={index}>
                                {subCategory?.name?.toLowerCase() === 'actblue' ? (
                                  <div className="budget__cell non-editable">
                                    <span>$ {budgetItem?.amount || 0}</span>
                                  </div>
                                ) : (
                                  <BudgetCell
                                    value={budgetItem?.amount || '0.00'}
                                    categoryId={subCategory?.categoryId || 0}
                                    subCategoryId={budgetItem?.subcategoryId || 0}
                                    monthlyBudgetId={budgetItem?.id || 0}
                                    refetchCategoryData={refetchCategoryData}
                                  />
                                )}
                              </td>
                            )
                          )
                        }
                      </tr>
                    )
                  )}

                {/* New row  */}
                {isAddingNewSubCategory ? (
                  <AddBudgetRow
                    categoryId={category?.id}
                    colSpanNumber={monthColumnData?.length + 3}
                    setIsAddingNewSubCategory={setIsAddingNewSubCategory}
                    refetchCategoryData={refetchCategoryData}
                  />
                ) : (
                  <tr>
                    <td style={{ minWidth: "240px", height: '49px' }}>
                      <button
                        className="btn btn-link no-hover text-primary rounded-pill btn-xs d-flex align-items-center"
                        onClick={() => setIsAddingNewSubCategory(true)}
                      >
                        <span className="icon-plus me-1"></span>
                        Add Sub Category
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </td>
      </tr>

      {/* delete warning Modal  */}
      <DeleteModal
        categoryId={category?.id}
        deleteTarget={deleteTarget}
        setDeleteTarget={setDeleteTarget}
        refetchCategoryData={refetchCategoryData}
      />
    </>
  )
}

export default React.memo(CategorySection)

import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { IVotersDetails } from "components/pages/voters/door-to-door/DoortoDoor";
import { queryClient } from "index";
import {
  ReactNode,
  useContext,
  useEffect,
  useState,
  createContext,
} from "react";
import { useLocation } from "react-router-dom";
import CreateAuthAxiosInstance from "utils/authAxios";
import { globalQueryConfig } from "utils/reactQuery";
import toastNotify from "utils/toastNotify";
import { IEditableTurfDetail } from "./TurfDetail";

export interface ISubmitTurfData {
  name: string;
  candidateId: string | null;
  voterIds: number[] | undefined;
  doors: number[][] | undefined;
  coordinates: number[][];
  doorCount: number;
  voterCount: number;
  isContacted: boolean;
  universeId: number;
}

interface ISubmitTurfAssignmentData {
  turfId: number;
  candidateId?: number;
  volunteerId?: number;
}

export interface ITurfDetail {
  id: number;
  name: string;
  candidateId: number;
  createdAt: string;
  coordinates: Array<number[]>;
  doors: Array<number[]>;
  voterIds: number[];
  doorCount: number;
  voterCount: number;
  universeId: number;
  isContacted: false;
  turfAssignment: {
    id: number;
    candidate: {
      id: number;
      fullName: string;
    };
    volunteerId: number;
    volunteer: {
      id: number;
      fullName: string;
    };
  };
  expectedTimeToComplete: number;
  completePercentage: number;
}

export interface IUniverseDetail {
  id: number;
  name: string;
  filterType: string;
  isActive: boolean;
  type: string;
  candidateId: number;
  createdAt: string;
  doorCount: number;
  voterCount: number;
  turfsCount: number;
  activeUniverseTurfsDoorCount: number | null;
}

interface ShapeData {
  type: string;
  data: google.maps.Polygon;
}
interface ITurfDetailContext {
  votersInUniverse: IVotersDetails[] | undefined;
  isUniverseDataLoading: boolean;
  createTurf: UseMutateFunction<void, Error, ISubmitTurfData[], unknown>;
  isTurfCreating: boolean;
  turfDetails:
  | {
    items: ITurfDetail[];
    totalItems: number;
  }
  | undefined;

  isTurfDetailsLoading: boolean;
  universeDetails: IUniverseDetail[] | undefined;
  isUniverseDetailsLoading: boolean;
  selectedUniverse: IUniverseDetail | undefined;
  setSelectedUniverse: React.Dispatch<
    React.SetStateAction<IUniverseDetail | undefined>
  >;
  turfs: ISubmitTurfData[];
  setTurfs: React.Dispatch<React.SetStateAction<ISubmitTurfData[]>>;
  deleteTurf: UseMutateFunction<void, Error, number, unknown>;
  isTurfDeleting: boolean;
  shapes: ShapeData[];
  setShapes: React.Dispatch<React.SetStateAction<ShapeData[]>>;
  undoStack: ShapeData[];
  setUndoStack: React.Dispatch<React.SetStateAction<ShapeData[]>>;
  redoStack: ShapeData[];
  setRedoStack: React.Dispatch<React.SetStateAction<ShapeData[]>>;
  resetMap: () => void;
  UpdateTurfName: UseMutateFunction<
    any,
    Error,
    {
      name: string;
    },
    unknown
  >;
  setEditTurfDetails: React.Dispatch<
    React.SetStateAction<IEditableTurfDetail | null>
  >;
  EditTurfDetails: IEditableTurfDetail | null;
  turfAttemptedData: any;
  totalDoors: number | undefined;
  highlightedPolygonIndex: number | undefined;
  setHighlightedPolygonIndex: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  openEditModal: boolean;
  PostTurfAssignmentDetails: UseMutateFunction<
    any,
    Error,
    {
      turfId: number;
      candidateId: number | null;
      volunteerId: number | null;
    },
    unknown
  >;
  PatchTurfAssignmentDetails: UseMutateFunction<
    any,
    Error,
    {
      turfId: number;
      candidateId: number | null;
      volunteerId: number | null;
    },
    unknown
  >;
}
interface TurfDetailsProviderProps {
  children: ReactNode;
}

const TurfDetailsContext = createContext<ITurfDetailContext | undefined>(undefined);
export const SELECTED_UNIVERSE_ID = "selectedUniverseId";
const TURF_LIMIT = 100;
const UNIVERSE_LIMIT = 100;
const UNIVERSE_ORDER = "ASC";


export const TurfDetailsProvider: React.FC<TurfDetailsProviderProps> = ({ children }) => {
  const authAxios = CreateAuthAxiosInstance();
  const candidateId = localStorage.getItem(CANDIDATE_ID);
  const storedSelectedUniverseId = localStorage.getItem(SELECTED_UNIVERSE_ID);
  const { state } = useLocation();
  const stateUniverseId = state?.universeId;

  const [selectedUniverse, setSelectedUniverse] = useState<IUniverseDetail>();
  const [turfs, setTurfs] = useState<ISubmitTurfData[]>([]);
  const [shapes, setShapes] = useState<ShapeData[]>([]);
  const [undoStack, setUndoStack] = useState<ShapeData[]>([]);
  const [redoStack, setRedoStack] = useState<ShapeData[]>([]);
  const [EditTurfDetails, setEditTurfDetails] = useState<IEditableTurfDetail | null>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [highlightedPolygonIndex, setHighlightedPolygonIndex] = useState<number>();

  const selectedUniverseId: number = storedSelectedUniverseId
    ? Number(storedSelectedUniverseId)
    : Number(stateUniverseId);

  const getUniverseDetails = async () => {
    try {
      const response = await authAxios.get(
        `/api/v1/universes?limit=${UNIVERSE_LIMIT}`
      );
      const selectedUniversefiltered = response.data?.find(
        (item: any) => item.id === Number(selectedUniverseId)
      );
  
      setSelectedUniverse(selectedUniversefiltered ?? response.data?.[0]);
      return response.data as IUniverseDetail[];
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  };

  const {
    data: universeDetails,
    isLoading: isUniverseDetailsLoading,
    refetch: refetchUniverseDetails,
  } = useQuery({
    queryKey: ["universeDetails"],
    queryFn: getUniverseDetails,
    ...globalQueryConfig,
  });

  const getAllVotersInUniverse = async () => {
    try {
      const response = await authAxios.get(
        `/api/v1/voter/candidate/${candidateId}?limit=${50000}&universeFilterType=${selectedUniverse?.filterType
        }`
      );
      return response.data?.items as IVotersDetails[];
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  };

  const {
    data: votersInUniverse,
    isLoading: isUniverseDataLoading,
    refetch: refetchVoterInUniverse,
  } = useQuery({
    queryKey: ["votersInUniverse"],
    queryFn: getAllVotersInUniverse,
    enabled: selectedUniverse ? true : false,
    ...globalQueryConfig,
  });
  useEffect(() => {
    if (selectedUniverse?.filterType) refetchVoterInUniverse();
  }, [selectedUniverse]);

  const handleCreateTurfAssignment = async (
    data: ISubmitTurfAssignmentData
  ) => {
    try {
      await authAxios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/turf-assignments`,
        data
      );
      // toastNotify("success", "Successfully Submitted");
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  };

  const { mutate: createTurfAssignment } = useMutation({
    mutationFn: handleCreateTurfAssignment,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["turfDetails"],
      });
      resetMap();
    },
  });

  const handleCreateTurf = async (data: ISubmitTurfData[]) => {
    try {
      const res = await authAxios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/turfs/bulk`,
        data
      );
      if (selectedUniverse?.turfsCount === 0) {
        const submitTurfAssignmentData: ISubmitTurfAssignmentData = {
          turfId: res.data?.[0].id,
          candidateId: Number(candidateId),
        };
        createTurfAssignment(submitTurfAssignmentData);
      }
      toastNotify("success", "Changes Saved");
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  };

  const { mutate: createTurf, isPending: isTurfCreating } = useMutation({
    mutationFn: handleCreateTurf,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["turfDetails"],
      });
      refetchUniverseDetails();
      resetMap();
    },
  });

  const handleTurfDelete = async (id: number) => {
    try {
      await authAxios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/turfs/${id}`
      );
      toastNotify("success", "Turf deleted");
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  };

  const { mutate: deleteTurf, isPending: isTurfDeleting } = useMutation({
    mutationFn: handleTurfDelete,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["turfDetails"],
      });
      refetchUniverseDetails();
      resetMap();
    },
  });

  useEffect(() => {
    const selectedUniverseId: number = stateUniverseId ? Number(stateUniverseId) : Number(storedSelectedUniverseId)
    const selectedUniversefiltered = universeDetails?.find((item) => item.id === selectedUniverseId);

    setSelectedUniverse(selectedUniversefiltered);
  }, [universeDetails, stateUniverseId, storedSelectedUniverseId]);

  const getTurfDetails = async () => {
    try {
      const response = await authAxios.get(
        `/api/v1/turfs?limit=${TURF_LIMIT}&universeId=${selectedUniverse?.id}&order=${UNIVERSE_ORDER}`
      );
      return response.data as { items: ITurfDetail[]; totalItems: number };
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  };

  const {
    data: turfDetails,
    isLoading: isTurfDetailsLoading,
    refetch: refetchTurfDetails,
  } = useQuery({
    queryKey: ["turfDetails"],
    queryFn: getTurfDetails,
    enabled: selectedUniverse?.id ? true : false,
    ...globalQueryConfig,
  });

  useEffect(() => {
    // Refetch turf details when selectedUniverse changes
    if (selectedUniverse) {
      localStorage.setItem(SELECTED_UNIVERSE_ID, String(selectedUniverse?.id));
      refetchTurfDetails();
    }
  }, [selectedUniverse, refetchTurfDetails]);

  const resetMap = () => {
    shapes.forEach((shape) => {
      shape.data.setMap(null);
    });
    setTurfs([]);
    setShapes([]);
    setUndoStack([]);
    setRedoStack([]);
  };
  const voterCoordinates = votersInUniverse?.map(
    (voter) => voter.geoCoordinates
  );
  const totalDoors = Array.from(
    new Set(voterCoordinates?.map((item) => JSON.stringify(item)))
  ).map((item) => JSON.parse(item))?.length;

  //Edit Turf
  async function MutateTurfName(data: { name: string }) {
    try {
      const response = await authAxios.patch(
        `api/v1/turfs/${EditTurfDetails?.id}`,
        data
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  }

  const { mutate: UpdateTurfName } = useMutation({
    mutationFn: MutateTurfName,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["turfDetails"],
      });
      refetchTurfDetails();
    },
  });

  // Attempted List
  const getAttemptedTurfDetails = async () => {
    try {
      const response = await authAxios.get("api/v1/turfs/candidate-volunteers");
      return response.data;
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  };

  const { data: turfAttemptedData } = useQuery({
    queryKey: ["turf-Attempted"],
    queryFn: getAttemptedTurfDetails,
    ...globalQueryConfig,
  });

  // Post and Patch Turf Attempted
  async function PostTurfAssignment(data: {
    turfId: number;
    candidateId: number | null;
    volunteerId: number | null;
  }) {
    try {
      const response = await authAxios.post(`api/v1/turf-assignments`, data);
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  }

  const { mutate: PostTurfAssignmentDetails } = useMutation({
    mutationFn: PostTurfAssignment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["turfDetails"],
      });
      setOpenEditModal(false);
      toastNotify("success", "Turf Created");
    },
  });

  async function PatchTurfAssignment(data: {
    turfId: number;
    candidateId: number | null;
    volunteerId: number | null;
  }) {
    try {
      if (EditTurfDetails?.id) {
        const response = await authAxios.patch(
          `api/v1/turf-assignments/${EditTurfDetails?.turfAssignment?.id}`,
          data
        );
        return response.data;
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const { mutate: PatchTurfAssignmentDetails } = useMutation({
    mutationFn: PatchTurfAssignment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["turfDetails"],
      });
      setOpenEditModal(false);
      toastNotify("success", "Turf updated");
    },
  });

  return (
    <TurfDetailsContext.Provider
      value={{
        votersInUniverse,
        isUniverseDataLoading,
        createTurf,
        isTurfCreating,
        turfDetails,
        isTurfDetailsLoading,
        universeDetails,
        isUniverseDetailsLoading,
        selectedUniverse,
        setSelectedUniverse,
        turfs,
        setTurfs,
        deleteTurf,
        isTurfDeleting,
        shapes,
        setShapes,
        undoStack,
        setUndoStack,
        redoStack,
        setRedoStack,
        resetMap,
        UpdateTurfName,
        setEditTurfDetails,
        EditTurfDetails,
        turfAttemptedData,
        totalDoors,
        highlightedPolygonIndex,
        setHighlightedPolygonIndex,
        setOpenEditModal,
        openEditModal,
        PostTurfAssignmentDetails,
        PatchTurfAssignmentDetails,
      }}
    >
      {children}
    </TurfDetailsContext.Provider>
  );
};

// create custom hooks for using the context
export const useTurfDetail = () => {
  const context = useContext(TurfDetailsContext);
  if (!context)
    throw new Error(
      "useTurfDetail hook must be used within the TurfDetailContextProvider"
    );
  return context;
};

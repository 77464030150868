import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";
import usePersonalDetails from "../personal-details/usePersonalDetails";
import { globalQueryConfig } from "utils/reactQuery";

export interface ISumitData {
  candidateId: number;
  fileTypeId: number;
  fileLinkId: number;
  displayProfileUrl: string;
  proposal: string | null;
  motivation: string | null;
  strengths: [str: string];
  file: any;
}

export const dateFormatter = (date: string | null) => {
  if (!date) return;

  const newDate = new Date(date);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${
    newDate.getMonth() + 1
  }/${newDate.getDate()}/${newDate.getFullYear()} at ${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatFileSize = (bytes: number | null) => {
  if (bytes === null) return;
  if (bytes > 1048576) return (bytes / (1024 * 1024)).toFixed(3) + " MB";
  if (bytes > 1023) return (bytes / 1024).toFixed(3) + " KB";

  return bytes + "Byte";
};

export const useProfessionalDetails = () => {
  const { userData } = usePersonalDetails();
  const authAxios = CreateAuthAxiosInstance();
  const [editable, setEditable] = useState(false);
  const [uploadedfile, setUploadedFile] = useState<File | null | undefined>(
    null || undefined
  );
  const [storeToDeletefile, setStoreToDeletefile] = useState<File | any>();
  const [isDeleted, setIsDeleted] = useState(false);
  async function fetchProfessionalData() {
    try {
      const response = await authAxios.get(
        "api/v1/profile-setting/professional-details"
      );

      return response.data;
    } catch (error: any) {
      console.log("error", error);
      return null;
    }
  }

  const professionalDataQuery = useQuery({
    queryKey: ["ProfessionalDetails"],
    queryFn: fetchProfessionalData,
    ...globalQueryConfig,
  });

  const { data: professionalData } = professionalDataQuery;

  async function mutateUpdateData(data: ISumitData) {
    const response = await authAxios.put(
      `api/v1/profile-setting/professional-details/${professionalData.id}`,
      data,
      {
        headers: {
          "Content-Type": "multiple/form-data",
        },
      }
    );
    return response.data;
  }

  async function mutatePostData(data: ISumitData) {
    const response = await authAxios.post(
      `api/v1/profile-setting/professional-details`,
      data,
      {
        headers: {
          "Content-Type": "multiple/form-data",
        },
      }
    );
    return response.data;
  }

  const {
    mutate: updateProfessionalData,
    status: updateProfessionalDataStatus,
  } = useMutation({
    mutationFn: mutateUpdateData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["ProfessionalDetails"],
      });
      professionalDataQuery.refetch();
      resumeDataQuery.refetch();
      setEditable(false);
      toastNotify("success", "Updated Successfully !");
    },
    onError: (error: any) => {
      console.log("error occured", error);
    },
  });

  const { mutate: postProfessionalData } = useMutation({
    mutationFn: mutatePostData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["ProfessionalDetails"],
      });
      // fetchProfessionalData();
      professionalDataQuery.refetch();
      resumeDataQuery.refetch();

      setEditable(false);
      toastNotify("success", "Added Successfully !");
    },
    onError: (error: any) => {
      console.log("error occured", error);
    },
  });

  async function fetchResumeFile() {
    const response = await authAxios.get(`api/v1/file/file-type/3`);
    return response.data;
  }

  const resumeDataQuery = useQuery({
    queryKey: ["resumeFile"],
    queryFn: fetchResumeFile,
    ...globalQueryConfig,
    // enabled: isSuccessProfessionalData,
  });

  const { data: resumeFileData } = resumeDataQuery;

  // async function uploadFileMutation(data: FormData) {
  //   const response = await authAxios.post(`api/v1/file`, data, {
  //     headers: {
  //       "Content-Type": "multiple/form-data",
  //     },
  //   });
  //   return await response.data;
  // }

  // const { mutate: UpdateFile, status: resumeUploadStatus } = useMutation({
  //   mutationFn: uploadFileMutation,
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({
  //       queryKey: ["resumeFile"],
  //     });
  //     fetchResumeFile();
  //     resumeFileData.retch();
  //   },
  // });

  async function deleteFileMutation(id: number) {
    const response = await authAxios.delete(`api/v1/file/${id}`);
    return response.data;
  }

  const { mutate: DeleteFile } = useMutation({
    mutationFn: deleteFileMutation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["ProfessionalDetails"],
      });
      setIsDeleted(false);
      // fetchResumeFile();
      resumeDataQuery.refetch();
      setUploadedFile(undefined);
    },
    onError: () => {
      setUploadedFile(undefined);
    },
  });

  const onFinalSubmit = (data: any) => {
    console.log("data.srt", data?.strengths);
    const formattedStrengths = data?.strengths?.map(
      (strength: any) => strength.str
    );

    const newFormData: any = new FormData();
    newFormData.append("displayProfileUrl", data.displayProfileUrl);
    newFormData.append("fileTypeId", "3");
    newFormData.append("fileLinkId", userData?.id);
    newFormData.append("proposal", data.proposal);
    newFormData.append("motivation", data.motivation);
    newFormData.append("strengths", JSON.stringify(formattedStrengths));
    if (uploadedfile?.name) {
      newFormData.append("file", uploadedfile);
    }
    if (isDeleted === true) {
      newFormData.append("resume", "");
    }

    if (!professionalData?.id) {
      postProfessionalData(newFormData);
      return;
    }
    updateProfessionalData(newFormData);
  };

  const isValidFileType = (file: File) => {
    const fileType = file?.name?.split(".").pop()?.toLowerCase();

    if (fileType === "pdf" || fileType === "doc") {
      return true;
    }
    return false;
  };
  const fileSizeLimit = 2 * 1024 * 1024;

  const [checkFile, setCheckFile] = useState(true);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target?.files?.[0];

    if (file) {
      setUploadedFile(file);

      if (file.size <= fileSizeLimit && isValidFileType(file)) {
        setCheckFile(true);
      }
      if (!isValidFileType(file)) {
        setCheckFile(false);
      }
    }
  };

  useEffect(() => {
    console.log("fileSizeLimit:", fileSizeLimit);
  }, [fileSizeLimit]);

  useEffect(() => {
    if (resumeFileData?.length > 0) {
      setUploadedFile(resumeFileData);
    } else {
      setUploadedFile(undefined);
    }
  }, [resumeFileData]);

  useEffect(() => {
    if (uploadedfile) {
      setUploadedFile(uploadedfile);
    }
  }, [uploadedfile]);

  

  return {
    editable,
    setEditable,
    professionalData,
    onFinalSubmit,
    handleFileChange,
    resumeFileData,
    fileSizeLimit,
    DeleteFile,
    uploadedfile,
    setUploadedFile,
    isValidFileType,
    setIsDeleted,
    setStoreToDeletefile,
    isDeleted,
    storeToDeletefile,
    checkFile,
    updateProfessionalDataStatus,
  };
};

import React from "react";
import { Link } from "react-router-dom";

const Stepper = () => {
  return (
    <div className="stepper">
      <div className="stepper-header">
        <div className="indicator">
          <div className="indicator-item success"></div>
          <div className="indicator-item skipped"></div>
          <div className="indicator-item active"></div>
          <div className="indicator-item"></div>
        </div>
      </div>

      <div className="stepper-body mt-4">
        <ul className="nav flex-column">
          <li className="nav-item success">
            <Link className="nav-link" aria-current="page" to="#">
              <span className="nav-link-circle"> </span>
              <span> Create your account </span>
            </Link>
          </li>
          <li className="nav-item skipped">
            <Link className="nav-link" to="#">
              <span className="nav-link-circle"> </span>
              <span> Voter </span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="#">
              <span className="nav-link-circle"> </span>
              <span> Fundraise </span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="#">
              <span className="nav-link-circle"> </span>
              <span>Your story </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="#">
              <span className="nav-link-circle"> </span>
              <span>Campaign Information </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="#">
              <span className="nav-link-circle"> </span>
              <span>Budget </span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="#">
              <span className="nav-link-circle"> </span>
              <span>Communication </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Stepper;

import SideNavbar from "components/common/navbar/SideNavbar";
import HelpIcon from "img/svg/helpIcon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "bootstrap";
import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import InfoIconDanger from "img/svg/InfoIconDanger";
import CheckIcon from "img/svg/checkIcon";
import CheckSuccess from "img/svg/checkSuccess";
import InfoIconWarning from "img/svg/InfoIconWarning";
import InfoIconSecondarySmall from "img/svg/InfoIconSecondarySmall";
import InfoIconDangerX16 from "img/svg/InfoIconDangerX16";
import CustomTooltip from "components/common/CustomTooltip";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import HorizontalBarChart from "./HorizontalBarChart";
import NoData from "img/svg/noData";
import TableCellPlaceholder from "./TableCellPlaceholder";
import TrashIcon from "img/svg/TrashIcon";
import FileIcon from "img/svg/fileIcon";
import ExclamationIcon from "img/svg/ExclamationIcon";
import InfoIconPrimary from "img/svg/InfoIconPrimary";

// next arrow

function NextArrow(props: any) {
  const { nextArrowType, className, style, onClick } = props;
  return (
    <div
      className={`${nextArrowType} ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
function PrevArrow(props: any) {
  const { prevArrowType, className, style, onClick } = props;
  return (
    <div
      className={`${prevArrowType} ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const BudgetOverviewTemplate = () => {
  // tooltip
  const budgetAllocationTooltip = `Breakdown of total planned expenditure for the entirety of the campaign by category.`;
  const cashflowTooltip = `Snapshot updated monthly showing income and expenses until the current date.`;
  const budgetTotalTooltip = `Taken from the Budget Details module, monthly breakdown and projections of Income and Expenses for the entirety of the campaign.`;

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  const [isLastSlide, setIsLastSlide] = useState(false);

  const sliderSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    infinite: false,
    mobileFirst: true,
    prevArrow: <PrevArrow prevArrowType={"prev-slide"} />,
    nextArrow: <NextArrow nextArrowType={"next-slide"} />,
    afterChange: (current: any) => {
      const totalSlides = 6; // Number of slides you have in the carousel
      setIsLastSlide(current >= totalSlides - 4); // A
    },
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  // useEffect(() => {
  //   let carouselGrandParent = document.querySelector('.budgetPlanOverview .contactPlan__carousel');
  //   let carouselParent = document.querySelector('.budgetPlanOverview .contactPlan__carousel .slick-next.slick-disabled');

  //   if (carouselGrandParent && carouselParent) {
  //     carouselGrandParent.classList.add('budgetPlanOverview-control');
  //   }
  // }, []);

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          {/* alert */}
          
          <div
            className="alert alert-primary alert-dismissible fade show"
            role="alert"
          >
            <InfoIconPrimary />{" "}
            <span className="ms-2">Budget based on uploaded budget file.</span>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span className="icon-close"></span>
            </button>
          </div>

          <div className="alert alert-warning fade show" role="alert">
                  <div className="d-flex justify-content-between">
                    <span>
                      <InfoIconWarning />{" "}
                      <span className="ms-2">
                        Add fundraising goal in settings or upload budget to
                        generate predefined budget.
                      </span>
                    </span>
                    <div className="d-flex gap-2">
                      <Link
                        to="/profile?tabIndex=3"
                        className="btn btn-link no-hover btn-xs text-warning py-0 px-0 fs-8"
                      >
                        {" "}
                        Go to Settings{" "}
                      </Link>
                      <span className="hr-verticle"></span>
                      <button
                        className="btn btn-link no-hover btn-xs text-warning py-0 px-0 fs-8"
                        data-bs-toggle="modal"
                        data-bs-target="#uploadModal"
                      >
                        {" "}
                        Upload budget{" "}
                      </button>
                    </div>
                  </div>
                </div>

          {/* if no budget available  */}
          <div className="row h-100 justify-content-center">
            <div className="col-md-6">
              <div className="centeredXY noData text-center justify-content-around">
                <div className="noData-header">
                  <NoData />
                  <h1 className="mt-6">No budget available</h1>
                  <p className="text-muted">
                    Upload a file of csv containing information about
                    budget for your campaign.
                  </p>

                  <div className="mt-4">
                    <Link
                      to=""
                      data-bs-toggle="modal"
                      data-bs-target="#uploadModal"
                      className="btn btn-primary rounded-pill px-5"
                    >
                      Upload file
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Cards */}
          <div className="row">
            <div className="col-md-6 col-12 mb-3">
              <div className="card card-flush h-100 shadow-2">
                <div className="card-header d-flex justify-content-between">
                  <h4 className="card-title"> Budget allocation </h4>
                  <div className="card-info">
                    <CustomTooltip content={budgetAllocationTooltip}>
                      {" "}
                      <HelpIcon />{" "}
                    </CustomTooltip>
                  </div>
                </div>
                <div className="card-body pt-1 d-flex flex-column justify-content-end">
                  <div className="d-flex flex-column gap-1 mb-4">
                    <h1 className="mb-0 me-2">$0.00 </h1>
                    <span className="d-block fs-8">
                      {" "}
                      Before Aug 2023 - Dec 2023{" "}
                    </span>
                  </div>

                  {/* top placeholder  */}
                  <div className="d-flex flex-column gap-1 mb-4 placeholder-glow">
                    <div className="placeholder col-3 py-3"></div>
                    <div className="placeholder col-5"></div>
                  </div>

                  <span className="d-block body-5 mb-0">All Categories</span>
                  <div
                    className="canvas-container"
                    style={{ width: "100%!important" }}
                  >
                    <HorizontalBarChart />
                  </div>

                  {/* bottom placeholder  */}
                  <div className="d-flex flex-column gap-1 mb-4 placeholder-glow">
                    <div className="placeholder col-12 py-7"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12 mb-3">
              <div className="card card-flush h-100 shadow-2">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h4 className="card-title"> Cashflow </h4>
                      <span className="d-block fs-8">
                        {" "}
                        Before Aug 2023 - Aug 2023{" "}
                      </span>
                    </div>
                    <div className="card-info">
                      <CustomTooltip content={cashflowTooltip}>
                        {" "}
                        <HelpIcon />{" "}
                      </CustomTooltip>
                    </div>
                  </div>
                  {/* top placeholder  */}
                  <div className="d-flex flex-column gap-1 mb-4 placeholder-glow">
                    <div className="placeholder col-5"></div>
                  </div>
                </div>

                <div className="card-body pt-1 d-flex flex-column justify-content-end">
                  <div className="cashFlowProgress-group mb-6">
                    <span className="body-5">Income as of Aug 2023</span>
                    {/* progress bar */}
                    <div
                      className="progress my-2"
                      role="progressbar"
                      aria-label="task progress"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ height: 16 + "px" }}
                    >
                      <div
                        className="progress-bar"
                        style={{ width: " 20%" }}
                      ></div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <span className="fs-8 text-muted fw-bold">
                        {" "}
                        $ 0.00 Funds raised
                      </span>
                      <span className="fs-8 text-muted fw-bold">
                        $ 24,000.00 Fundraising goal
                        <div className="d-inline-block ms-1">
                          <CustomTooltip content="Add a fundraising goal in the fundraise of the settings.">
                            <ExclamationIcon />
                          </CustomTooltip>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="cashFlowProgress-group">
                    <span className="body-5">Expenses as of Aug 2023</span>
                    {/* progress bar */}
                    <div
                      className="progress my-2"
                      role="progressbar"
                      aria-label="task progress"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ height: 16 + "px" }}
                    >
                      <div
                        className="progress-bar"
                        style={{ width: " 0%" }}
                      ></div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <span className="fs-8 text-muted fw-bold">
                        {" "}
                        $ 0.00 Spent
                      </span>
                      <span className="fs-8 text-muted fw-bold">
                        $ 0.00 Total Expenses
                      </span>
                    </div>
                  </div>

                  {/* placeholder  */}
                  <div className="d-flex flex-column placeholder-glow">
                    <div className="placeholder col-12 pt-4 pb-5 mb-6"></div>
                    <div className="placeholder col-12 pt-4 pb-5"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-6">
            <div className="col-md-12">
              <div className="card card-flush shadow-2">
                <div className="card-header">
                  <div className="d-flex gap-2">
                    <h4 className="card-title"> Budget total </h4>
                    <CustomTooltip content={budgetTotalTooltip}>
                      <HelpIcon />
                    </CustomTooltip>
                  </div>
                  <span className="d-block fs-8">
                    {" "}
                    Before Aug 2023 - Dec 2023{" "}
                  </span>
                </div>
                <div className="card-body p-0">
                  <div className="contactPlan budgetPlanOverview">
                    <div className="contactPlan__legend">
                      <div className="contactPlan__legend-item">
                        <div
                          style={{ height: "64px", background: "#F4F4F6" }}
                        ></div>
                        <div> Total Income </div>
                        <div> Total Expenses</div>
                        <div> Differences </div>
                      </div>
                    </div>
                    <div className="contactPlan__legend">
                      <div className="contactPlan__legend-item">
                        <div
                          style={{
                            height: "64px",
                            background: "#F4F4F6",
                            borderRight: "1px solid #CBCBD8",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          Election Total{" "}
                        </div>
                        <div style={{ textAlign: "right" }}>
                          {" "}
                          <TableCellPlaceholder />{" "}
                        </div>
                        <div style={{ textAlign: "right" }}> $0</div>
                        <div
                          style={{ textAlign: "right" }}
                          className="text-danger"
                        >
                          {" "}
                          $0
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        isLastSlide
                          ? `contactPlan__carousel contactPlan__carousel-control`
                          : `contactPlan__carousel`
                      }
                    >
                      <Slider {...sliderSettings}>
                        <div className="contactPlan__carousel-item">
                          <div className="performance-card">
                            <span className="performance-card__datetime">
                              Pre Signup
                            </span>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              {/* <span> $0 </span> */}
                              <TableCellPlaceholder />
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span className="text-success"> $0 </span>
                            </div>
                          </div>
                        </div>
                        <div className="contactPlan__carousel-item">
                          <div className="performance-card">
                            <span className="performance-card__datetime">
                              Aug '23
                            </span>

                            {/* this week */}
                            <div className="current-month">
                              <span className="d-block font-semibold text-success fs-8">
                                This Month
                              </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span className="text-danger"> $0 </span>
                            </div>
                          </div>
                        </div>
                        <div className="contactPlan__carousel-item">
                          <div className="performance-card">
                            <span className="performance-card__datetime">
                              Sep '23
                            </span>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                        </div>
                        <div className="contactPlan__carousel-item">
                          <div className="performance-card">
                            <span className="performance-card__datetime">
                              Oct '23
                            </span>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                        </div>
                        <div className="contactPlan__carousel-item">
                          <div className="performance-card">
                            <span className="performance-card__datetime">
                              Nov '23
                            </span>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                        </div>
                        <div className="contactPlan__carousel-item">
                          <div className="performance-card">
                            <span className="performance-card__datetime">
                              Dec '23
                            </span>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="budget__cell">
                              <span> $0 </span>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* Modal */}
        <div
          className="modal fade"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">Add budget</h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                <form action="">
                  <div className="mb-3">
                    <label className="custom-label">File upload</label>
                    <p className="body-4 text-muted mb-1">
                      Upload a file of any xls or csv format containing
                      information about budget of your campaign.
                    </p>
                    <p className="body-4 text-muted mb-1">
                      (You can <Link to=""> download our template </Link> )
                    </p>

                    {/* before upload */}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="uploadedCard mb-0">
                          <div className="uploadedCard-icon">
                            <div className="iconInfo">
                              <span className="iconInfo-title text-muted">
                                (pdf, jpg, jpeg, xls, or any){" "}
                                <span className="dot bg-neutral-n3 ms-1">
                                  {" "}
                                </span>{" "}
                                2MB Max
                              </span>
                            </div>
                          </div>
                          <div className="uploadedCard-info file-btn">
                            <button className="btn btn-info rounded-pill">
                              {" "}
                              Upload File
                              <input type="file" name="file" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* after upload */}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="uploadedCard uploadedCard-danger mb-0">
                          <div className="uploadedCard-icon">
                            <div className="iconBox">
                              <FileIcon />
                            </div>

                            <div className="iconInfo">
                              <span className="iconInfo-title">
                                {" "}
                                Voter List.pdf{" "}
                              </span>
                              <p className="iconInfo-desc">
                                {" "}
                                14 KB, 6/23/23 at 11:45 am{" "}
                              </p>
                              <div className="messageWrapper">
                                {/* if successful */}
                                {/* <span className="iconInfo-status">
                                {" "}
                                <CheckSuccess /> File uploaded{" "}
                              </span> */}

                                {/* if error */}
                                <span className="iconInfo-status">
                                  <div className="text-danger">
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 1</strong>: File is not in
                                      CSV format
                                    </span>

                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 2</strong>: File is not in
                                      CSV format
                                    </span>

                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 3</strong>: File is not in
                                      CSV format
                                    </span>

                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 4</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 5</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 6</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 7</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 8</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 9</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 10</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 11</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 12</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 13</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 14</strong>: File is not in
                                      CSV format
                                    </span>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="uploadedCard-info">
                            <div className="file-btn">
                              <button className="btn btn-link rounded-pill text-primary">
                                {" "}
                                Change File
                                <input type="file" name="file" />
                              </button>
                            </div>

                            <button className="btn btn-xs">
                              <span className="icon-trash"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer border-1">
                <Link
                  to=""
                  className="btn btn-link rounded-pill text-primary btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
                <Link
                  to=""
                  className="btn btn-primary rounded-pill btn-xs px-6"
                >
                  Done
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetOverviewTemplate;

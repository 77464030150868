import CustomTooltip from "components/common/CustomTooltip";
import Loader from "components/common/Loader";
import HelpIcon from "img/svg/helpIcon";
import useBudgetOverview from "../useBudgetOverview";
import { formatBudgetOverviewDate, getDateForBudgetPage, incrementMonth } from "utils/formatDate";

interface ICashFlow{
  cashFlowData:{
    currentDate:string,
    currentExpense:string,
    currentIncome:string,
    fundraisingGoal:string,
    startDate:string,
    totalExpense:string,
    totalIncome:string
  }
isFetchingBudgetCashFlow:boolean
}

const CashFlow = ({cashFlowData,isFetchingBudgetCashFlow}:ICashFlow) => {
  const cashflowTooltip = `Snapshot updated monthly showing income and expenses until the current date.`;

  //calculate percentage
  const calculatePercentage = (current: string, total: string) => {
    if (Number(total) === 0) return 0;
    return (Number(current) / Number(total)) * 100;
  };

  // Calculate the percentage of currentIncome with respect to totalIncome 
  const incomePercentage = calculatePercentage(
    cashFlowData?.currentIncome,
    cashFlowData?.fundraisingGoal
  );
  const expensePercentage = calculatePercentage(
    cashFlowData?.currentExpense,
    cashFlowData?.totalExpense
  );

  // calculate if current income/expence exceeds its total
  const incomeExceeded =
    Number(cashFlowData?.currentIncome) > Number(cashFlowData?.fundraisingGoal);
    
  return (
    <div className="col-md-6 col-12 mb-3">
      <div className="card card-flush h-100 shadow-2">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="card-title"> Cashflow </h4>
              {!isFetchingBudgetCashFlow && (
                <span className="d-block fs-8">
                  {" "}
                  Before {formatBudgetOverviewDate(cashFlowData?.startDate,true)?.formattedDate} -{" "}
                  {formatBudgetOverviewDate(cashFlowData?.currentDate,true)?.formattedDate}
                </span>
              )}
            </div>
            <div className="card-info">
              <CustomTooltip content={cashflowTooltip}>
                {" "}
                <HelpIcon />{" "}
              </CustomTooltip>
            </div>
          </div>
          {/* top placeholder  */}
          {isFetchingBudgetCashFlow && (
            <div className="d-flex flex-column gap-1 mb-4 placeholder-glow">
              <div className="placeholder col-5"></div>
            </div>
          )}
        </div>

        <div className="card-body pt-1 d-flex flex-column justify-content-end">
          {/* placeholder  */}
          {isFetchingBudgetCashFlow ? (
            <div className="d-flex flex-column placeholder-glow">
              <div className="placeholder col-12 pt-4 pb-5 mb-6"></div>
              <div className="placeholder col-12 pt-4 pb-5"></div>
            </div>
          ) : (
            <>
              <div className="cashFlowProgress-group mb-6">
                <span className="body-5">
                  Income as of {formatBudgetOverviewDate(cashFlowData?.currentDate,true)?.formattedDate}
                </span>
                {/* progress bar */}
                <div
                  className="progress my-2"
                  role="progressbar"
                  aria-label="task progress"
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ height: 16 + "px" }}
                >
                  <div
                    className="progress-bar"
                    style={{ width: `${incomePercentage}%` }}
                  ></div>
                </div>

                <div className="d-flex justify-content-between">
                  <span
                    className={`fs-8 ${
                      incomeExceeded ? "text-success" : "text-muted"
                    } fw-bold`}
                  >
                    {" "}
                    $ {cashFlowData?.currentIncome || 0} Funds raised
                  </span>
                  <span className="fs-8 text-muted fw-bold">
                    $ {cashFlowData?.fundraisingGoal || 0} Fundraising goal
                  </span>
                </div>
              </div>

              <div className="cashFlowProgress-group">
                <span className="body-5">
                  Expenses as of {formatBudgetOverviewDate(cashFlowData?.currentDate,true)?.formattedDate}
                </span>
                {/* progress bar */}
                <div
                  className="progress my-2"
                  role="progressbar"
                  aria-label="task progress"
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ height: 16 + "px" }}
                >
                  <div
                    className="progress-bar"
                    style={{ width: `${expensePercentage}%` }}
                  ></div>
                </div>

                <div className="d-flex justify-content-between">
                  <span
                    className="fs-8 text-muted fw-bold"
                  >
                    {" "}
                    $ {cashFlowData?.currentExpense || 0} Spent
                  </span>
                  <span className="fs-8 text-muted fw-bold">
                    $ {cashFlowData?.totalExpense || 0} Total Expenses
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CashFlow;

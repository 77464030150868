import TrashIcon from 'img/svg/TrashIcon';
import FileIcon from 'img/svg/fileIcon';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ScriptTabs from './tabs/ScriptTabs';
import CandidateScript from './candidate-script/CandidateScript';
import VolunteerScript from './VolunteerScript';
import useCandidateScriptDetails from './candidate-script/useCandidateScriptDetails';

const ScriptDetails = () => {

  const {editable, setEditable}= useCandidateScriptDetails()
   

  return (
    <div className='row mt-5'>
        <div className="col-md-3">
          <ScriptTabs />
        </div>
        <div className="col-md-8 offset-md-1">
        <div className="tab-content" id="v-pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="v-pills-calls"
          role="tabpanel"
          aria-labelledby="v-pills-calls-tab"
          tabIndex={0}
        >
          <CandidateScript userType="candidate" contactType="By Phone" postedTo="Voter" editable={editable} setEditable={setEditable} />
          <VolunteerScript userType="volunteer" contactType="By Phone" postedTo="Voter" editable={editable} setEditable={setEditable}/>
        </div>
        <div
          className="tab-pane fade"
          id="v-pills-canvassing"
          role="tabpanel"
          aria-labelledby="v-pills-canvassing-tab"
          tabIndex={0}
        >
          <CandidateScript userType="candidate" contactType="By Door" postedTo="Voter" editable={editable} setEditable={setEditable}/>
          <VolunteerScript userType="volunteer" contactType="By Door" postedTo="Voter" editable={editable} setEditable={setEditable}/>
        </div>

        <div
          className="tab-pane fade"
          id="v-pills-callTime"
          role="tabpanel"
          aria-labelledby="v-pills-callTime-tab"
          tabIndex={0}
        >
          <CandidateScript userType="candidate" contactType="By Fundraising" postedTo="Donor" editable={editable} setEditable={setEditable}/>
         
        </div>
      </div>
        </div>
      
    </div>
  )
}

export default ScriptDetails

import React from 'react'

interface IMapIframeDummyProp {
    width: string;
    height: string
}

const MapIframeDummy = ({width, height}: IMapIframeDummyProp) => {
  return (
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14132.466345175933!2d84.426752!3d27.682791299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1692952960214!5m2!1sen!2snp"
        width={width}
        height={height}
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  )
}

export default MapIframeDummy

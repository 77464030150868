import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import lottie from 'lottie-web';
import animationData from "../../img/lotties/failed.json";

interface Props {
  
  onClickModal: () => void;
}

const VerificationFailed = ( {onClickModal}: Props) => {
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(element.current) // add this
       lottie.loadAnimation({
         animationData,
         container: element.current,
         loop: false
       });
       return () => lottie.destroy(); 
  }, [element]); 


  return (
    <div className="card-body text-center pt-4">
      {/* <img
        src={require("../../img/leave.png")}
        width={56}
        height={56}
        alt="leave alert"
      /> */}
       <div className="lottee-container" ref={element} style={{width: 56 , height: 56}}> </div>
      <h2 className="mt-4"> Verification Failed </h2>
      <p className="mb-6 body-3 text-muted">
      Your number has not been verified by Campaign Brain. Retry to verify again.
      </p>

      <div className="w-100">
        <Link to="" className="btn btn-primary btn-xs rounded-pill w-100 mb-2"> Retry </Link>
        <Link to="" type="button" className="btn btn-link btn-xs rounded-pill w-100" onClick={onClickModal}> Continue without verifying </Link>
      </div>
    </div>
  );
};

export default VerificationFailed;

import React from 'react'

const CalendarOutlineSmallIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" fill="none">
<path d="M5.33333 4.66667V2M10.6667 4.66667V2M4.66667 7.33333H11.3333M3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V4.66667C14 3.93029 13.403 3.33333 12.6667 3.33333H3.33333C2.59695 3.33333 2 3.93029 2 4.66667V12.6667C2 13.403 2.59695 14 3.33333 14Z" stroke="#858599" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default CalendarOutlineSmallIcon

import HelpIcon from "img/svg/helpIcon";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "bootstrap";
import { useEffect } from "react";
import EyeIcon from "img/svg/eyeIcon";
import WarningIcon from "img/svg/warningIcon";

import { IdToken, useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosError } from "axios";
import * as yup from "yup";

import { IElectionData } from "type/dashboard";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IDonorFormData } from "type/fundraise";
import { useNotificationContext } from "global-state/contexts/notificationContext";
import { toast } from "react-toastify";
import InfoIconLight from "img/svg/InfoIconLight";
import { ErrorNotification } from "global-state/actions/notificationActions";
import {
  CONTRIBUTION_TOOLTIP,
  DONOR_EMAIL_TOOLTIP,
  DONOR_STATUS_TOOLTIP,
} from "utils/contactDonor";
import DonorScript from "./DonorScript";
import DonorDetails from "./DonorDetails";
import SuccessIcon from "img/svg/successIcon";
import { CANDIDATE_ID } from "components/pages/Homepage";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useQuery } from "@tanstack/react-query";
import TwilioCard from "components/pages/voters/phone-number-verification/TwilioCard";
import BreadCrumbs from "components/common/BreadCrumbs";
import CallerId from "components/pages/voters/contact-plan/components/CallerId";
import VerifyNumber from "components/pages/voters/phone-number-verification/VerifyNumber";
import { PhoneComponent } from "components/pages/voters/contact-plan/components/PhoneComponent";
import useCandidateScriptDetails from "components/pages/profile/script/candidate-script/useCandidateScriptDetails";
import VerificationDisabled from "components/pages/voters/phone-number-verification/VerificationDisabled";
import { replaceMultipleWords } from "utils/replaceMultipleWords";
import CustomTooltip from "components/common/CustomTooltip";
export const DONOR_STATUS = ["Not Home", "Not Interested", "Donating Later"];

interface IDonorsResponse {
  donorStatus: string;
  donorEmail: string;
  contribution: string;
  donorNotes: string;
}

const ContactDonor: React.FC = () => {
  const { getIdTokenClaims } = useAuth0();

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { notificationDispatch, notificationState } = useNotificationContext();

  const [token, setToken] = useState<IdToken>();
  const userId = localStorage.getItem(CANDIDATE_ID);

  const [isScriptActive, setIsScriptActive] = useState(true);
  const BUTTON_TEXT = isScriptActive ? "Hide Script" : "Show Script";

  const [selectedDonorStatus, setSelectedDonorStatus] = useState("");
  const [donorContribution, setDonorContribution] = useState("");

  const [letterCounts, setLetterCounts] = useState<number>(0);
  const [mappedDonorEmail, setMappedDonorEmail] = useState("");

  const [isSendButtonClicked, setIsSendButtonClicked] = useState(false);

  const [fullName, setFullName] = useState("");
  const [donationUrl, setDonationUrl] = useState("");

  const [electionInfo, setElectionInfo] = useState<IElectionData>();

  const [donorId, setDonorId] = useState(0);
  const [donorsToContact, setDonorsToContact] = useState<Array<any>>([]);

  const [hasDonorsToContactLoaded, setHasDonorsToContactLoaded] =
    useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [parentCallSId, setParentCallSId] = useState<string | null>(null);

  const donorsResponseFormSchema = yup.object().shape(
    {
      contribution: yup.string().when(["donorStatus", "donorEmail"], {
        is: (donorStatus: string, donorEmail: string) =>
          !donorStatus && !donorEmail,
        then: () => {
          return yup
            .string()
            .required(
              "Select either Contribution or Donor Status or Donor Email"
            );
        },
        otherwise: () => yup.string().notRequired(),
      }),
      donorStatus: yup.string().when(["contribution", "donorEmail"], {
        is: (contribution: string, donorEmail: string) => {
          return !contribution && !donorEmail;
        },
        then: () => {
          return yup
            .string()
            .required(
              "Select either Donor Status or Contribution or Donor Email"
            );
        },
        otherwise: () => yup.string().notRequired(),
      }),
      donorNotes: yup.string().notRequired(),
      donorEmail: yup
        .string()
        .default(mappedDonorEmail)
        .when(["contribution", "donorStatus"], {
          is: (contribution: string, donorStatus: string) =>
            !contribution && !donorStatus,

          then: () => {
            return yup
              .string()
              .required(
                "Select either Donor Status or Contribution or Donor Email"
              );
          },
          otherwise: () => yup.string().notRequired(),
        }),
    },
    [
      ["donorEmail", "donorStatus"],
      ["donorEmail", "contribution"],
      ["donorStatus", "contribution"],
    ]
  );

  const { handleSubmit, register, setValue, reset } = useForm<IDonorsResponse>({
    resolver: yupResolver(donorsResponseFormSchema) as any,
  });

  useEffect(() => {
    (async () => {
      const tokenResponse = await getIdTokenClaims();
      setToken(tokenResponse);
    })();
  }, [getIdTokenClaims]);

  const handleScript = () => {
    setIsScriptActive(!isScriptActive);
  };

  const handleSkip = async () => {
    if (activeIndex < donorsToContact.length) setActiveIndex(activeIndex + 1);
    setSelectedDonorStatus("");
    setIsSendButtonClicked(false);
    reset();
  };

  const handleSendButton = async () => {
    try {
      if (donorId !== 0) {
        const emailSentResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/email/donation-email`,
          {
            recipientEmail: mappedDonorEmail,
            donorId: donorId,
          },
          {
            headers: {
              Authorization: `Bearer ${token?.__raw}`,
            },
          }
        );
        setIsSendButtonClicked(true);

        toast.success("Email sent", {
          toastId: 1,
          className: "snackBar",
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          hideProgressBar: true,
          autoClose: 5000,
          icon: <InfoIconLight />,
        });
      }
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message;

        if (errorMessage) {
          toast.error(errorMessage, {
            toastId: 1,
            className: "snackBar",
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "dark",
            hideProgressBar: true,
            autoClose: 5000,
            icon: <InfoIconLight />,
          });
        }
      }
    }
  };

  function ensureHttps(url: string) {
    // Check if the URL starts with http:// or https://
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      // If not, add https:// to the beginning
      url = "https://" + url;
    }

    return url;
  }

  const handleContributionLinkClick = () => {
    if (!donationUrl) {
      toast.error("No contribution link available.  Contact Admin", {
        toastId: 1,
        className: "snackBar",
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        hideProgressBar: true,
        autoClose: 5000,
        icon: <InfoIconLight />,
      });
      return;
    }

    const finalDonationUrl = ensureHttps(donationUrl);
    const newWindow = window.open(finalDonationUrl, "_blank");
    if (newWindow) {
      newWindow?.focus();
      return;
    }

    toast.error(
      "The popup window might be blocked. Please allow popups for this website.",
      {
        toastId: 1,
        className: "snackBar",
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        hideProgressBar: true,
        autoClose: 5000,
        icon: <InfoIconLight />,
      }
    );
  };

  const handleTextareaChange = (value: number) => {
    setLetterCounts(value);
  };

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        // Get election response to check hasDonorsList
        if (token) {
          const electionResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/candidate/election`,
            {
              headers: {
                Authorization: `Bearer ${token?.__raw}`,
              },
            }
          );
          if (electionResponse?.data) {
            setFullName(electionResponse?.data?.fullName);
            setElectionInfo(electionResponse?.data);
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, [donorId]);

  useEffect(() => {
    (async () => {
      try {
        // Get donationUrl response to get donation-url
        if (token) {
          const donationUrlResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/candidate/donation-url`,
            {
              headers: {
                Authorization: `Bearer ${token?.__raw}`,
              },
            }
          );
          if (donationUrlResponse?.data !== null) {
            setDonationUrl(donationUrlResponse?.data.donationUrl);
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, [token]);

  useEffect(() => {
    (async () => {
      try {
        // Get donor details
        const donorsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/donor/contact-histories/${userId}
          `
        );

        if (donorsResponse.data.result.length > 0) {
          const mappedDonorsToContact: any[] = donorsResponse.data.result?.map(
            (item: any) => ({
              id: item?.id,
              name: item?.name,
              age: item?.age || "-",
              email: item?.email || "-",
              source: item?.createdBy || "Personal Network",
              donorType:
                item?.donorContactHistories[0]?.donorType || "Prospects",
              status: item?.donorContactHistories[0]?.status || "-",
              phoneNumber: item?.phoneNumber || "-",
              sex: item?.sex || "-",
              partyAffiliation: item.partyAffiliation || "-",
              donorContactHistories: item.donorContactHistories,
            })
          );

          setDonorsToContact(mappedDonorsToContact);
          setIsSendButtonClicked(
            mappedDonorsToContact[activeIndex]?.donorContactHistories[0]
              ?.isContributionEmailSent
          );
          mappedDonorsToContact[activeIndex]?.email.length > 1
            ? setMappedDonorEmail(mappedDonorsToContact[activeIndex]?.email)
            : setMappedDonorEmail("");
          setDonorId(mappedDonorsToContact[activeIndex]?.id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [userId]);

  useEffect(() => {
    setIsSendButtonClicked(
      donorsToContact[activeIndex]?.donorContactHistories[0]
        ?.isContributionEmailSent
    );
    donorsToContact[activeIndex]?.email.length > 1
      ? setMappedDonorEmail(donorsToContact[activeIndex]?.email)
      : setMappedDonorEmail("");
    setDonorId(donorsToContact[activeIndex]?.id);
  }, [activeIndex, donorsToContact]);

  const submitDonorResponse = async (data: IDonorFormData) => {
    try {
      const token = await getIdTokenClaims();
      const donorStatus = isSendButtonClicked ? "Donating Later" : "";
      // Get not contacted voters

      if (donorId !== 0) {
        let tempPayload: any = {
          status: data?.donorStatus || donorStatus,
          donorType: data?.donorType,
          donorId: donorId,
          isContributionEmailSent: isSendButtonClicked,
          email: mappedDonorEmail,
          notes: data?.donorNotes,
          donationAmount: Number(donorContribution),
        };
        if (parentCallSId) {
          tempPayload.parentCallSid = parentCallSId;
        }

        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/donor-contact-history`,
          tempPayload,
          {
            headers: {
              Authorization: `Bearer ${token?.__raw}`,
            },
          }
        );
        if (activeIndex < donorsToContact.length)
          setActiveIndex(activeIndex + 1);
        setHasDonorsToContactLoaded(true);

        setIsSendButtonClicked(false);
        setSelectedDonorStatus("");
        reset();
        setDonorContribution("");
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      if (error instanceof AxiosError) {
        if (error.response) {
          const errorMessage = error.response.data.error;

          notificationDispatch(ErrorNotification(errorMessage));
          if (notificationState.errorMessage) {
            toast.error(notificationState.errorMessage, {
              toastId: 1,
              className: "snackBar",
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "dark",
              hideProgressBar: true,
              autoClose: 5000,
              icon: <InfoIconLight />,
            });
          }
        }
      }
    }
  };
  useEffect(() => {
    if (hasDonorsToContactLoaded && activeIndex === donorsToContact.length) {
      submitButtonRef.current?.click();
      setActiveIndex(activeIndex + 1);
    }
  }, [activeIndex, donorsToContact, hasDonorsToContactLoaded]);

  const [isActivePopup, setIsActivePopup] = useState<boolean>(true);

  const authAxios = CreateAuthAxiosInstance();
  const retrievedCandidateId = localStorage.getItem(CANDIDATE_ID);

  const [autoDialerStatus, setAutoDialerStatus] = useState<string | null>(null)
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState<string | null>(null)

  const { data: candidateInfo, status: candidateInfoStatus } = useQuery({
    queryKey: ["candidateInfo"],
    queryFn: () => authAxios.get(`api/v1/user/info/${retrievedCandidateId}`),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (candidateInfoStatus === 'success') {
      const autoDialerStatusFromApi = candidateInfo?.data?.autoDialerStatus
      const hasSeenAutoDialerStatusPopup = candidateInfo?.data?.hasSeenAutoDialerStatusPopup

      setAutoDialerStatus(autoDialerStatusFromApi || null)
      if (
        autoDialerStatusFromApi === 'Integrated' ||
        autoDialerStatusFromApi === 'Rejected' ||
        (autoDialerStatusFromApi === "Inactive" && hasSeenAutoDialerStatusPopup)
      ) {
        setIsActivePopup(false);
      }

      setVerifiedPhoneNumber(
        candidateInfo?.data?.phoneNumber?.phoneNumber || null
      );
    }
  }, [candidateInfo, candidateInfoStatus]);

  const handleModalClick = () => setIsActivePopup(true);
  const handleCancelModal = () => setIsActivePopup(false);
  const handleParentCallSId = (acceptCallSId: string | null) => {
    setParentCallSId(acceptCallSId);
  };

  const { fetchScriptsData } = useCandidateScriptDetails();

  const [finalScript, setFinalScript] = useState("");
  const [selectedOption, setSelectedOption] = useState<undefined | string>();

  // const replaceMultipleWords = (
  //   inputString: string | undefined,
  //   replacements: { [key: string]: string | undefined }
  // ): string => {
  //   let result = inputString || "";
  //   for (const [wordToReplace, replacement] of Object.entries(replacements)) {
  //     // Check if the replacement is defined
  //     if (replacement !== undefined) {
  //       // Create a regular expression to match the word to be replaced
  //       const regex = new RegExp("\\b" + wordToReplace + "\\b", "gi");
  //       // Replace all occurrences of the word with the replacement
  //       result = result.replace(regex, replacement);
  //     }
  //   }
  //   return result;
  // };

  useEffect(() => {
    const filteredscript = fetchScriptsData?.filter(
      (item: any) =>
        item.contactType == "By Fundraising" && item.usageScope === "candidate"
    );
    const script: string = filteredscript?.[0]?.script;

    if (candidateInfo?.data?.id && donorsToContact?.length) {
      const customScriptMappedObject: { [key: string]: string | undefined } = {
        "Donor Name": donorsToContact[activeIndex]?.name ?? "",
        "Candidate Name": candidateInfo?.data.fullName ?? "",
        City: candidateInfo?.data.candidate.elections[0].city ?? "",
        Position: candidateInfo?.data.candidate.elections[0].electionPosition ?? "",
        "Strength 1":
          candidateInfo?.data.candidate.candidateStories[0]?.strengths[0] ?? "",
        "Strength 2":
          candidateInfo?.data.candidate.candidateStories[0]?.strengths[1] ?? "",
      };
      const finalScriptTemp = replaceMultipleWords(
        script,
        customScriptMappedObject
      );
      setFinalScript(finalScriptTemp);
    }

    if (filteredscript?.[0]?.isActive === true) {
      setSelectedOption("defaultScript");
      return;
    }
    if (filteredscript?.[0]?.isActive === false) {
      setSelectedOption("customScript");
      return;
    }
    if (!filteredscript?.[0]?.length) {
      setSelectedOption("defaultScript");
    }
  }, [fetchScriptsData, activeIndex, donorsToContact, candidateInfo]);



  return (
    <div className="d-flex align-items-stretch">
      <div
        className={
          isActivePopup
            ? "wrapper active-cont active-popup"
            : "wrapper active-cont"
        }
      >
        {/* <div className="wrapper active-cont"> */}
        {candidateInfoStatus !== "success" ? (
          <LoadingAnimation />
        ) : (
          <>
            {isActivePopup &&
              <>
                {(autoDialerStatus === null || autoDialerStatus === 'Rejected') && (
                  <TwilioCard
                    modalStatus={isActivePopup}
                    onClickModal={handleCancelModal}
                  />
                )}
                {autoDialerStatus === 'Inactive' && (
                  <VerificationDisabled
                    modalStatus={isActivePopup}
                    onClickModal={handleCancelModal}
                  />
                )}
              </>
            }
          </>
        )}
        <main>
          <form
            action=""
            onSubmit={handleSubmit(async (data: any) => {
              await submitDonorResponse(data);

              if (activeIndex < donorsToContact.length)
                setActiveIndex(activeIndex + 1);
              setSelectedDonorStatus("");
              reset();
            })}
          >
            <div className="container">
              <div className="row">
                <div className="col-9">
                  <BreadCrumbs title="Fundraise" subtitle="Contact Donors" link="/fundraise" />
                </div>
                {autoDialerStatus !== 'Inactive' &&
                  <>
                    {autoDialerStatus === 'Integrated' ? (
                      <div className="col-lg-3 col-12">
                        <CallerId
                          phoneNumber={verifiedPhoneNumber}
                        />
                      </div>
                    ) : (
                      <div className="col-lg-3 col-12">
                        <VerifyNumber onClickModal={handleModalClick} />
                      </div>
                    )}
                  </>
                }
              </div>

              {/* Daily goal updates */}
              <div className="row justify-content-between mt-3">
                <div className="col-md-5">
                  <div className="alert bg-white shadow-2">
                    <div className="d-flex gap-4 voterGoal">
                      <div className="voter-goal-item">
                        <span className="ms-2">
                          <span className="dot bg-success-s2"> </span>
                          <span className="fw-bold">
                            {/* {contactedVotersCount} of {dailyGoalVotersCount}{" "} */}
                          </span>
                          Daily donor goal
                        </span>
                      </div>
                      {/* <div className="voter-goal-item">
                      <span className="ms-2">
                        <span className="dot bg-extended-e2"> </span>
                        <span className="fw-bold">1 of 186 </span>
                        Weekly voter goal
                      </span>
                    </div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="d-flex justify-content-end gap-2">
                    {autoDialerStatus !== 'Inactive' &&
                      <PhoneComponent
                        receiverType="donor"
                        phoneNumber={donorsToContact[activeIndex]?.phoneNumber || null}
                        autoDialerStatus={autoDialerStatus}
                        receiverName={donorsToContact[activeIndex]?.name || null}
                        receiverId={donorsToContact[activeIndex]?.id || null}
                        parentCallSId={parentCallSId}
                        handleParentCallSId={handleParentCallSId}
                        submitButtonRef={submitButtonRef}
                      />
                    }
                    <button
                      className="btn btn-info btn-xs rounded-pill"
                      data-bs-toggle="modal"
                      data-bs-target="#skipModal"
                      type="button"
                      onClick={() =>
                        setDonorId(donorsToContact[activeIndex]?.id)
                      }
                    >
                      Skip voter
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill btn-xs "
                      ref={submitButtonRef}
                      disabled={
                        selectedDonorStatus?.length === 0 &&
                        !isSendButtonClicked &&
                        donorContribution?.length === 0
                      }
                      data-bs-toggle={
                        activeIndex === donorsToContact?.length ? "modal" : ""
                      }
                      data-bs-target={
                        activeIndex === donorsToContact?.length
                          ? "#successModal"
                          : ""
                      }
                    >
                      Save and call next
                    </button>
                  </div>
                </div>
              </div>

              {/* Scripts and Response */}
              {donorsToContact?.length > activeIndex ? (
                <div className="row">
                  <DonorDetails activeDonor={donorsToContact[activeIndex]} />
                  <div className="col-md-9">
                    <div className="contactAction flex-row card card-flush card-fullHeight mt-3 shadow-2">
                      {electionInfo?.electionPosition && (
                        <DonorScript
                          fullName={fullName}
                          electionPosition={electionInfo?.electionPosition}
                          isScriptActive={isScriptActive}
                          donorName={donorsToContact[activeIndex]?.name}
                          finalScript={finalScript}
                          selectedOption={selectedOption}
                          setSelectedOption={setSelectedOption}
                          candidateInfo={candidateInfo}
                        />
                      )}

                      <div
                        className={isScriptActive ? "card-divider" : ""}
                      ></div>

                      {/* voter response */}
                      <div className="contactAction-cardRight flex-1">
                        <div className="card-header">
                          <h4 className="card-title"> Donor Response</h4>
                          <button
                            className="btn btn-link btn-sm p-1 ps-0 d-flex align-items-center gap-1"
                            onClick={handleScript}
                            type="button"
                          >
                            {" "}
                            <EyeIcon /> {BUTTON_TEXT}
                          </button>
                        </div>{" "}
                        <div className="card-body">
                          <p className="body-4 text-muted">
                            Select either donor status, contribution or Donor
                            email.
                          </p>

                          <div className="voter-status mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="body-5"> Donor Status </label>
                              <div className="card-info">
                                <CustomTooltip content={DONOR_STATUS_TOOLTIP}>
                                  <HelpIcon />
                                </CustomTooltip>
                                
                              </div>
                            </div>

                            {/* Donor status */}
                            {DONOR_STATUS.map((status, index) => (
                              <div className="col-md-6" key={index}>
                                <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                  <input
                                    type="radio"
                                    className="btn-check form-check-input"
                                    id={status}
                                    autoComplete="off"
                                    value={status}
                                    {...register("donorStatus")}
                                    checked={selectedDonorStatus === status}
                                    onClick={() => {
                                      status === selectedDonorStatus
                                        ? setSelectedDonorStatus("")
                                        : setSelectedDonorStatus(status);
                                    }}
                                  />
                                  <label
                                    className="btn btn-outline-primary w-100 ps-5 text-start"
                                    htmlFor={status}
                                  >
                                    {status}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="level-of-support pb-3">
                            <div className="d-flex justify-content-between">
                              <label className="body-5 mb-1">
                                {" "}
                                Contribution{" "}
                              </label>
                              <div className="card-info">
                                <CustomTooltip content={CONTRIBUTION_TOOLTIP}>
                                  <HelpIcon />
                                </CustomTooltip>
                                
                              </div>
                            </div>
                            <p className="body-4 text-muted">
                              Add contribution amount in link or add on donation
                              page
                            </p>

                            <div className="">
                              <button
                                type="button"
                                onClick={() => {
                                  handleContributionLinkClick();
                                }}
                                className="btn text-primary fw-bold px-0 py-2 btn-xs"
                              >
                                Add via contribution link
                              </button>

                              <div className="input-group">
                                <span className="input-group-text bg-transparent">
                                  {" "}
                                  ${" "}
                                </span>
                                <input
                                  type="text"
                                  className="form-control border-start-0 ps-0"
                                  id="exampleInputEmail1"
                                  {...(register("contribution"),
                                  {
                                    onChange: (e) => {
                                      setDonorContribution(e.target.value);
                                      setValue("contribution", e.target.value);
                                    },
                                  })}
                                  aria-describedby="emailHelp"
                                  placeholder="Insert contribution amount"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="level-of-support py-3">
                            <div className="d-flex justify-content-between">
                              <label className="body-5 mb-1">Donor email</label>
                              <div className="card-info">
                                <CustomTooltip content={DONOR_EMAIL_TOOLTIP}>
                                  <HelpIcon />
                                </CustomTooltip>
                                
                              </div>
                            </div>
                            <p className="body-4 text-muted">
                              Add the email of donor to send the contribution
                              link
                            </p>

                            <div className="">
                              <div className="d-flex align-items-center gap-1">
                                <input
                                  type="email"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  {...register("donorEmail", {
                                    onChange: (e) => {
                                      setMappedDonorEmail(e.target.value);
                                      setValue("donorEmail", e.target.value);
                                    },
                                  })}
                                  value={mappedDonorEmail}
                                  placeholder="Add donor email"
                                />

                                <button
                                  type="button"
                                  className="btn btn-info rounded-pill btn-xs"
                                  onClick={() => {
                                    handleSendButton();
                                  }}
                                  disabled={mappedDonorEmail.length === 0}
                                >
                                  {" "}
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="level-of-support py-3">
                            <label className="body-5"> Notes </label>

                            <div className="position-relative">
                              <textarea
                                className="form-control"
                                id="Textarea1"
                                rows={4}
                                maxLength={200}
                                {...register("donorNotes", {
                                  onChange: (e) =>
                                    handleTextareaChange(e.target.value.length),
                                })}
                              ></textarea>
                              <span className="word-count">
                                {" "}
                                {letterCounts}/ 200{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* buttons */}
                        {/* <div className="card-footer shadow-6 d-flex justify-content-between">
                          <button
                            className="btn btn-link btn-sm p-1 ps-0"
                            data-bs-toggle="modal"
                            data-bs-target="#skipModal"
                            type="button"
                            onClick={() =>
                              setDonorId(donorsToContact[activeIndex]?.id)
                            }
                          >
                            Skip donor
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary rounded-pill btn-xs "
                            ref={submitButtonRef}
                            disabled={
                              selectedDonorStatus?.length === 0 &&
                              !isSendButtonClicked &&
                              donorContribution?.length === 0
                            }
                            data-bs-toggle={
                              activeIndex === donorsToContact?.length
                                ? "modal"
                                : ""
                            }
                            data-bs-target={
                              activeIndex === donorsToContact?.length
                                ? "#successModal"
                                : ""
                            }
                          >
                            Save and call next
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                donorsToContact.length && (
                  <div className="row">
                    <div className="card card-flush card-fullHeight mt-3 shadow-2">
                      <div className="noData d-flex gap-2 align-items-center">
                        <SuccessIcon />
                        <h1 className="mt-6">All voters contacted </h1>
                        <p>
                          {" "}
                          <span className="fw-bold">
                            {donorsToContact.length} /{donorsToContact.length}
                          </span>{" "}
                          voters attempted today. Continue to complete other
                          tasks
                        </p>
                        <Link
                          to="/dashboard"
                          className="btn btn-primary rounded-pill px-5"
                        >
                          {" "}
                          Continue to Homepage
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </form>
        </main>
      </div>

      {/* Skip Modal */}
      <div
        className="modal fade"
        id="skipModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <WarningIcon />
              <h2 className="mt-3">
                {" "}
                Do you want to skip "{donorsToContact[activeIndex]?.name}"?{" "}
              </h2>
              <p>
                By skipping, all details entered for this donor will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary rounded-pill btn-xs"
                onClick={handleSkip}
                data-bs-dismiss="modal"
              >
                Yes, skip
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when user go to next page without confirming Modal */}
      <div
        className="modal fade"
        id="leaveModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Do you want to leave this page </h2>
              <p>
                By leaving, all details entered for this donor will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, leave
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when save and call next voter after last step */}
      <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../img/success-modal.png")}
                width={56}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Great Work! </h2>
              <p>
                <span className="fw-bold">
                  {donorsToContact.length ? activeIndex + 1 : activeIndex - 1} /
                  {donorsToContact.length}
                </span>{" "}
                donors contacted today. Continue to complete other tasks
              </p>
            </div>

            <div className="modal-footer justify-content-center">
              <Link to="/" className="btn btn-primary rounded-pill">
                Continue to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDonor;

const LoadingAnimation = () => {
  return (
    <div
      className="card-body"
      style={{
        zIndex: "100000",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <div className="text-center px-5">
        <div className="loader-demo-box">
          <div className="jumping-dots-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <span className="body-4 fs-5">Loading...</span>
      </div>
    </div>
  );
};

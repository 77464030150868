import React from 'react'

const SortIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M12.4107 13.5C12.6238 13.5 12.8282 13.579 12.9789 13.7197C13.1296 13.8603 13.2143 14.0511 13.2143 14.25C13.2143 14.4489 13.1296 14.6397 12.9789 14.7803C12.8282 14.921 12.6238 15 12.4107 15H7.58929C7.37617 15 7.17177 14.921 7.02108 14.7803C6.87038 14.6397 6.78571 14.4489 6.78571 14.25C6.78571 14.0511 6.87038 13.8603 7.02108 13.7197C7.17177 13.579 7.37617 13.5 7.58929 13.5H12.4107ZM14.5536 9.25C14.7667 9.25 14.9711 9.32902 15.1218 9.46967C15.2725 9.61032 15.3571 9.80109 15.3571 10C15.3571 10.1989 15.2725 10.3897 15.1218 10.5303C14.9711 10.671 14.7667 10.75 14.5536 10.75H5.44643C5.23331 10.75 5.02892 10.671 4.87822 10.5303C4.72752 10.3897 4.64286 10.1989 4.64286 10C4.64286 9.80109 4.72752 9.61032 4.87822 9.46967C5.02892 9.32902 5.23331 9.25 5.44643 9.25H14.5536ZM16.6964 5C16.9095 5 17.1139 5.07902 17.2646 5.21967C17.4153 5.36032 17.5 5.55109 17.5 5.75C17.5 5.94891 17.4153 6.13968 17.2646 6.28033C17.1139 6.42098 16.9095 6.5 16.6964 6.5H3.30357C3.09045 6.5 2.88606 6.42098 2.73536 6.28033C2.58466 6.13968 2.5 5.94891 2.5 5.75C2.5 5.55109 2.58466 5.36032 2.73536 5.21967C2.88606 5.07902 3.09045 5 3.30357 5H16.6964Z" fill="#3B3B4F"/>
</svg>
  )
}

export default SortIcon

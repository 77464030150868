import InfoCard from "templates/components/InfoCard";
import UploadFile from "components/common/UploadFile";
import { useEffect, useState } from "react";
import {
  IAddDonorProp,
  IDashboardDonorFormSchema,
  IDashboardDonorSchema,
  IResponseFileInfo,
} from "type/dashboard";
import * as yup from "yup";
import useFormPersist from "react-hook-form-persist";

import TrashIcon from "img/svg/TrashIcon";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { handleFileDelete } from "utils/uploadFile";
import { ACTIVE_STEP } from "../Dashboard";
import { getLocalStorageItem } from "utils/localStorageHelper";
import CreateAuthAxiosInstance from "utils/authAxios";
import { toast } from "react-toastify";
import InfoIconLight from "img/svg/InfoIconLight";
import { CANDIDATE_ID } from "../../Homepage";

const donorSchema = yup.object().shape(
  {
    fundraisingGoal: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable()
      .typeError("Must be a number")
      .integer("Must be an integer")
      .min(1, "Must be a positive number")
      .max(1_000_000_000, "Must be under 10 digits"),

    fundRaised: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable()
      .typeError("Must be a number")
      .integer("Must be an integer")
      .min(1, "Must be a positive number")
      .max(1_000_000_000, "Must be under 10 digits"),

    canUploadFileManually: yup.boolean().nullable(),

    donors: yup.number().when("canUploadFileManually", {
      is: (canUploadFileManually: boolean) => {
        return canUploadFileManually === true ? true : false;
      },
      then: () => {
        return yup.array().of(
          yup.object().shape({
            name: yup
              .string()
              .required("Name is required")
              .min(3, "Name should be of minimum 3 characters in length")
              .matches(/^[aA-zZ\s]+$/, "Name should contain alphabets only"),
            phoneNumber: yup
              .string()
              .required("Phone is required")
              .matches(
                /^(\+?\d{1,4}[\s-]?)?\d{10,14}$/,
                "Invalid phone number format"
              )
              .min(10, ({ min }) => `Phone should be at least ${min} digits`)
              .max(14, ({ max }) => `Phone should be at most ${max} digits`),

            email: yup
              .string()
              .transform((value, originalValue) =>
                originalValue === "" ? null : value
              )
              .nullable()
              .email("Invalid email format")
              .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                "Please enter a valid email format. eg: brain@campaign.com"
              ),
          })
        );
      },
      otherwise: () => yup.array().nullable(),
    }),
  }
  // [["canUploadFileManually", "donors"]]
);

const AddDonor = ({
  handleStepCompletion,
  fileInfo,
  setFileInfo,
  electionId,
  setHasDonorsFileUploaded,
  hasDonorsFileUploaded,
  fundRaised,
  fundraisingGoal,
  isProfileCreated,
}: IAddDonorProp) => {
  // const { getIdTokenClaims } = useAuth0();

  const userId = localStorage.getItem(CANDIDATE_ID);

  const [isFileRead, setIsFileRead] = useState<boolean>(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] =
    useState<boolean>(false);

  const [isFileUploadSelected, setIsFileUploadSelected] = useState(
    true && localStorage.getItem("FILE_UPLOAD_SELECTED") !== "false"
  );

  const activeStep: number = getLocalStorageItem(ACTIVE_STEP);
  const authAxtios = CreateAuthAxiosInstance();

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IDashboardDonorFormSchema>({
    resolver: yupResolver(donorSchema as any),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "donors",
  });

  const dataMap = fields.length
    ? fields
    : [{ name: "", phoneNumber: "", email: "" }];

  useFormPersist("addDonorForm", {
    watch,
    setValue,
    storage: localStorage,
  });

  const handleDonorsSubmission = async (donors: IDashboardDonorSchema[]) => {
    const donorObjects = Object.keys(donors).map((key: string) => {
      const index = parseInt(key, 10);

      return {
        ...donors[index],
      };
    });

    const mappedDonors = donorObjects.map((donor: any) => {
      donor["electionId"] = electionId;
      if (donor.email === "") {
        donor.email = null;
      }

      return donor;
    });

    try {
      await authAxtios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/donor/bulk`,
        {
          donors: mappedDonors,
        }
      );
      setIsSubmittedSuccessfully(true);
    } catch (error: any) {
      setIsSubmittedSuccessfully(false);
      const errorMessage = error.response.data.message;
      if (errorMessage) {
        toast.error(errorMessage, {
          toastId: 1,
          className: "snackBar",
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          hideProgressBar: true,
          autoClose: 5000,
          icon: <InfoIconLight />,
        });
      }
    }
  };

  const handleUpdateFundraiseData = async (
    fundRaisedNumber: number | null,
    fundraisingGoalNumber: number | null
  ) => {
    try {
      if (isProfileCreated) {
        await authAxtios.patch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/profile-setting/candidate-details`,
          {
            fundRaised: fundRaisedNumber,
            fundraisingGoal: fundraisingGoalNumber,
          }
        );
      } else {
        await authAxtios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/profile-setting/candidate-details`,
          {
            fundRaised: fundRaisedNumber,
            fundraisingGoal: fundraisingGoalNumber,
            userId: userId,
          }
        );
      }
      setIsSubmittedSuccessfully(true);
    } catch (error: any) {
      setIsSubmittedSuccessfully(false);
      const errorMessage = error.response.data.message;
      if (errorMessage) {
        toast.error(errorMessage, {
          toastId: 1,
          className: "snackBar",
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          hideProgressBar: true,
          autoClose: 5000,
          icon: <InfoIconLight />,
        });
      }
    }
  };

  useEffect(() => {
    const storedFundRaised: string = String(getValues("fundRaised"));
    const storedFundraisingGoal: string = String(getValues("fundraisingGoal"));
    if (fundRaised && storedFundRaised === "") {
      setValue("fundRaised", fundRaised);
    }
    if (fundraisingGoal && storedFundraisingGoal === "") {
      setValue("fundraisingGoal", fundraisingGoal);
    }

    if (isFileUploadSelected) {
      setValue("canUploadFileManually", false);
    } else {
      setValue("canUploadFileManually", true);
    }
  }, [fundRaised, fundraisingGoal]);

  return (
    <div className="col-md-8">
      <div className="card card-accountSetup shadow-2">
        <div className="card-title">
          <h4 className="mb-0">Fundraise</h4>
        </div>
        <form
          onSubmit={handleSubmit(async (data) => {
            await handleUpdateFundraiseData(
              data.fundRaised,
              data.fundraisingGoal
            );
            if (data.canUploadFileManually)
              await handleDonorsSubmission(data.donors);

            handleStepCompletion(activeStep, "completed");
          })}
        >
          <div className="card-body pt-0">
            <div className="mb-4">
              <label htmlFor="fundraisingGoal" className="custom-label">
                Fundraising goal
              </label>
              <label className="form-label mb-2 text-muted d-block fs-7">
                Input your fundraising goal for the entire campaign. This
                information is used to create your Daily Goals.
              </label>
              <div className="input-group">
                <span className="input-group-text bg-transparent"> $ </span>
                <input
                  type="text"
                  className="form-control border-start-0 ps-0"
                  id="fundraisingGoal"
                  aria-describedby="fundraisingGoal"
                  placeholder={
                    fundraisingGoal
                      ? String(fundraisingGoal)
                      : "Enter Fundraising Goal Number"
                  }
                  {...register("fundraisingGoal")}
                />
              </div>

              {errors?.fundraisingGoal?.message && (
                <span className="invalidInput form-text text-danger">
                  {errors.fundraisingGoal.message as string}
                </span>
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="InputLinked" className="custom-label">
                Fund raised
              </label>
              <label className="form-label mb-2 text-muted d-block fs-7">
                Input the amount your campaign has fundraised until now. This
                information is used to create your Daily Goals.
              </label>

              <div className="input-group">
                <span className="input-group-text bg-transparent"> $ </span>
                <input
                  type="text"
                  className="form-control border-start-0 ps-0"
                  id="fundRaised"
                  aria-describedby="emailHelp"
                  placeholder={
                    fundRaised ? String(fundRaised) : "Enter Fundraised Amount"
                  }
                  {...register("fundRaised")}
                />
              </div>
              {errors?.fundRaised?.message && (
                <span className="invalidInput form-text text-danger">
                  {errors.fundRaised.message as string}
                </span>
              )}
            </div>

            <div className="mb-4">
              <label className="custom-label mb-3">Add donor information</label>
              <InfoCard />
            </div>

            <div className="donor-options">
              <div className="btn-checkGroup d-flex gap-3 mb-3">
                <div className="btn-checkGroup-item btn-checkGroup-item--icon">
                  <input
                    type="radio"
                    className="btn-check form-check-input"
                    name="options"
                    id="option11"
                    autoComplete="off"
                    checked={isFileUploadSelected}
                    onChange={(e) => {
                      setValue("canUploadFileManually", false);
                      setIsFileUploadSelected(true);
                      localStorage.setItem(
                        "FILE_UPLOAD_SELECTED",
                        JSON.stringify(true)
                      );
                      if (e?.target.files) {
                        setIsFileRead(true);
                      }
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-pill ps-5"
                    htmlFor="option11"
                  >
                    File upload
                  </label>
                </div>

                <div className="btn-checkGroup-item btn-checkGroup-item--icon">
                  <input
                    type="radio"
                    className="btn-check form-check-input"
                    id="option12"
                    autoComplete="off"
                    checked={!isFileUploadSelected}
                    onChange={() => {
                      setValue("canUploadFileManually", true);
                      setIsFileUploadSelected(false);
                      localStorage.setItem(
                        "FILE_UPLOAD_SELECTED",
                        JSON.stringify(false)
                      );
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-pill ps-5"
                    htmlFor="option12"
                  >
                    Add a donor manually
                  </label>
                </div>
              </div>

              {isFileUploadSelected ? (
                <div className="mb-3 ">
                  <label className="custom-label form-label">File upload</label>
                  <p>
                    Upload a file of any format containing information about
                    potential donors from your personal network
                  </p>

                  {electionId && (
                    <UploadFile
                      setIsFileRead={setIsFileRead}
                      activeStep={activeStep}
                      fileInfo={fileInfo}
                      setFileInfo={
                        setFileInfo as React.Dispatch<
                          React.SetStateAction<IResponseFileInfo | null>
                        >
                      }
                      hasDonorsFileUploaded={hasDonorsFileUploaded}
                      setHasDonorsFileUploaded={setHasDonorsFileUploaded}
                      electionId={electionId}
                    />
                  )}
                </div>
              ) : (
                <div className="mb-3 required">
                  <label className="custom-label">Add a donor manually</label>

                  {dataMap.map((donor, index) => (
                    <div key={index} className="table-responsive mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              <label
                                htmlFor={`inputName-${index}`}
                                className="form-label"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`inputName-${index}`}
                                {...register(`donors.${index}.name`)}
                                value={getValues(`donors.${index}.name`)}
                              />
                              {errors?.donors?.[index]?.name && (
                                <span className="invalidInput form-text text-danger">
                                  {
                                    errors.donors?.[index]?.name
                                      ?.message as string
                                  }
                                </span>
                              )}
                            </td>
                            <td>
                              <label
                                htmlFor={`inputPhone-${index}`}
                                className="form-label"
                              >
                                Phone
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`inputPhone-${index}`}
                                {...register(`donors.${index}.phoneNumber`)}
                                value={getValues(`donors.${index}.phoneNumber`)}
                              />
                              {errors?.donors?.[index]?.phoneNumber && (
                                <span className="invalidInput form-text text-danger">
                                  {errors.donors?.[index]?.phoneNumber?.message}
                                </span>
                              )}
                            </td>
                            <td>
                              <label
                                htmlFor={`inputEmail-${index}`}
                                className="mt-2"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id={`inputEmail-${index}`}
                                {...register(`donors.${index}.email`)}
                                value={getValues(`donors.${index}.email`)}
                              />
                              {errors?.donors?.[index]?.email && (
                                <span className="invalidInput form-text text-danger">
                                  {errors.donors?.[index]?.email?.message}
                                </span>
                              )}
                            </td>
                            <td>
                              <button
                                type="button"
                                className={`btn btn-link pt-4 mt-2 px-0 ${
                                  (fields.length !== 0 &&
                                    fields.length !== 1) ||
                                  (dataMap.length !== 1 && isDirty)
                                    ? "active"
                                    : "disabled"
                                }`}
                                onClick={() => remove(index)}
                              >
                                <span className="svg-neutral-2">
                                  <TrashIcon />
                                </span>{" "}
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}

                  <button
                    className="btn btn-link text-primary"
                    type="button"
                    onClick={() =>
                      append({ name: "", phoneNumber: "", email: "" })
                    }
                  >
                    <span className="icon-plus me-2"></span> Add another donor
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="card-footer shadow-6 d-flex gap-4 justify-content-end">
            <button
              className="btn btn-link text-primary btn-xs rounded-pill"
              onClick={() => {
                handleStepCompletion(activeStep, "skipped");
                handleFileDelete(activeStep);
              }}
            >
              Not Now
            </button>
            <button
              type="submit"
              className={`btn btn-primary rounded-pill btn-xs px-6 ${
                isFileRead ||
                !isFileUploadSelected ||
                getValues("fundraisingGoal") ||
                getValues("fundRaised")
                  ? ""
                  : "btn-disable"
              }`}
              // className={`btn btn-primary rounded-pill btn-xs px-6 ${
              //   isDirty ? "" : "btn-disable"
              // }`}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDonor;

import React, { useEffect, useState } from "react";
import CloseIcon from "img/svg/closeIcon";
import LocationIcon from "img/svg/locationIcon";
import { Link } from "react-router-dom";
import HelpIcon from "img/svg/helpIcon";
import { IVotersDetails, areArraysEqual } from "./DoortoDoor";
import { LEVEL_OF_SUPPORT } from "../voters-list/TableFilter";

import axios from "axios";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import InfoIconLight from "img/svg/InfoIconLight";
import { Tooltip } from "bootstrap";
import Loader from "components/common/Loader";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { DOOR_TO_DOOR } from "../voters-list/VotersList";
import useCandidateScriptDetails from "components/pages/profile/script/candidate-script/useCandidateScriptDetails";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useQuery } from "@tanstack/react-query";
import DOMPurify from 'dompurify';
import { replaceMultipleWords } from "utils/replaceMultipleWords";
import DoortoDoorScript from "./components/DoorToDoorScript";

const VOTER_STATUS_TOOLTIP = `Not Home: the voter did not pick up the phone, or you reached someone besides the target voter. We will try again with this voter soon.<br /> <br /> Moved: the voter no longer lives at this address. We will not target this voter further.  <br /> <br /> Deceased: the voter has passed away. We will not target this voter further. <br /> <br /> Wrong Number: the phone number was not valid.. We will target this voter door-to-door instead. `;

const LOS_TOOLTIP = `Strong Support: The voter clearly communicated they will definitely be supporting you. <br /> <br />Lean Support:  The voter communicated they will likely be supporting you.  <br /> <br />Undecided: The votercommunicated they aren’t sure who they will vote for or they haven’t decided yet. <br /> <br />Lean Against: The voter communicated they will likely NOT be supporting you. <br /> <br /> Strong Against: The voter clearly communicated they will definitely NOT be supporting you.`;

export const VOTER_STATUS_D2D = [
  "Not Home",
  "Moved",
  "Deceased",
  "Inaccessible",
];

export const getYearMonthDateTime = (date: string) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const monthIndex = dateObj.getMonth(); // Months are 0-indexed
  const day = dateObj.getDate();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const formattedMinutes =
    String(minutes).length === 1 ? "0" + minutes : minutes;
  // const seconds = dateObj.getUTCSeconds();
  const amPm = hours >= 12 ? "PM" : "AM";
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedHours = hours % 12 || 12;
  return `${monthNames[monthIndex]} ${day}, ${year}-${formattedHours}:${formattedMinutes} ${amPm}`;
};

export interface IVoterResponseD2D {
  voterStatus: string;
  voterId: number;
  levelOfSupport: string;
}
export interface IVoterContactHistory {
  id: number;
  createdAt: string;
  updatedAt: string;
  voterId: number;
  levelOfSupport: string;
  status: string;
}
const SidePanel = ({
  show,
  setShow,
  voters,
  activeAddressIndex,
  totalAddress,
  setActiveAddressIndex,
  setIsVoterResponseSubmitted,
  setShowSkipModal,
  canSubmitAllVotersDefaultResponse,
  setCanSubmitAllVotersDefaultResponse,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  voters: IVotersDetails[];
  activeAddressIndex: number;
  totalAddress: number;
  setActiveAddressIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsVoterResponseSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSkipModal: React.Dispatch<React.SetStateAction<boolean>>;
  canSubmitAllVotersDefaultResponse: boolean;
  setCanSubmitAllVotersDefaultResponse: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) => {
  // const [selectedVoterId, setSelectedVoterId] = useState(-1);
  const userId = localStorage.getItem(CANDIDATE_ID);

  const authAxios = CreateAuthAxiosInstance();

  const [selectedVoterStatus, setSelectedVoterStatus] = useState("");
  const [selectedLevelOfSupport, setSelectedLevelOfSupport] = useState("");
  const [canVisitNextDoor, setCanVisitNextDoor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [token, setToken] = useState<IdToken>();

  // const [votersContactHistory, setVotersContactHistory] = useState<
  //   Array<IVoterContactHistory[]>
  // >([]);

  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    (async () => {
      const tokenResponse = await getIdTokenClaims();
      setToken(tokenResponse);
    })();
  }, [getIdTokenClaims]);

  const submitVoterResponse = async (data: IVoterResponseD2D) => {
    try {
      // Get not contacted voters
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/voter-contact-history`,
        {
          levelOfSupport: data.levelOfSupport || null,
          status: data.voterStatus || null,
          voterId: data.voterId,
        },
        {
          headers: {
            Authorization: `Bearer ${token?.__raw}`,
          },
        }
      );
      if (res) {
        setIsVoterResponseSubmitted(true);
        toast.success("Successfully submitted the response", {
          toastId: 1,
          className: "snackBar",
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          hideProgressBar: true,
          autoClose: 5000,
          icon: <InfoIconLight />,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   const allVoterIds = voters.map((voter) => voter.id);
  //   if (allVoterIds.length)
  //     (() => {
  //       let tempContactHistory: IVoterContactHistory[][] = [];
  //       try {
  //         allVoterIds.forEach(async (voterId) => {
  //           // Get voters contacted histories
  //           const response = await axios.get(
  //             `${process.env.REACT_APP_API_BASE_URL}/api/v1/voter-contact-history/voter/${voterId}`
  //           );
  //           tempContactHistory = [...tempContactHistory, response.data];
  //           setVotersContactHistory(tempContactHistory);
  //         });
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     })();
  // }, [voters]);

  useEffect(() => {
    const accordionItems = document.querySelectorAll(".accordion-collapse");
    const acc = document.getElementById("accordionExample");

    if (acc) {
      accordionItems?.forEach((el: any) => {
        el.addEventListener("shown.bs.collapse", (e: any) => {
          var scrollOffset = acc.scrollTop + el.parentNode.offsetTop;

          window.scrollTo({
            top: scrollOffset,
            behavior: "smooth",
          });
        });
      });
    }
  }, []);

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  const getContactedVoters = async () => {
    try {
      const token = await getIdTokenClaims();
      setCanVisitNextDoor(false);

      // Get contacted voters
      const contactedVoterResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL
        }/api/v1/voter/candidate/${userId}/recent-contacts?contact_type=${DOOR_TO_DOOR}&timestamp=${new Date().toLocaleString(
          "US"
        )}
          `,
        {
          headers: {
            Authorization: `Bearer ${token?.__raw}`,
          },
        }
      );

      const contactedVoterCoordinates: number[][] =
        contactedVoterResponse.data.items
          .map((item: IVotersDetails[]) => item)
          .map(
            (contactedVoters: IVotersDetails) => contactedVoters.geoCoordinates
          );

      voters.forEach((voter) => {
        contactedVoterCoordinates.forEach((coordinates) => {
          if (areArraysEqual(voter.geoCoordinates, coordinates)) {
            setCanVisitNextDoor(true);
            return;
          }
        });
      });
      // setHasVotersResponseSubmitted(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (userId && token && voters) getContactedVoters();
  }, [userId, token, voters]);

  // useEffect(() => {
  //   setCanVisitNextDoor(false);
  //   voters.forEach((voter) => {
  //     if (voter.voterContactHistories.length) {
  //       setCanVisitNextDoor(true);
  //       return;
  //     }
  //   });
  // }, [voters]);

  useEffect(() => {
    if (canSubmitAllVotersDefaultResponse) {
      const votersWithoutContactHistory = voters.filter(
        (voter) => voter.voterContactHistories.length === 0
      );
      console.log("as", votersWithoutContactHistory);

      if (votersWithoutContactHistory.length) {
        setIsLoading(true);
        votersWithoutContactHistory.forEach(async (voter, index) => {
          await submitVoterResponse({
            voterStatus: VOTER_STATUS_D2D[0],
            levelOfSupport: "",
            voterId: voter.id,
          });
          if (index === votersWithoutContactHistory.length - 1) {
            setIsLoading(false);
            if (activeAddressIndex < totalAddress - 1)
              setActiveAddressIndex(activeAddressIndex + 1);
          }
        });
      } else {
        if (activeAddressIndex < totalAddress - 1)
          setActiveAddressIndex(activeAddressIndex + 1);
      }
      setCanSubmitAllVotersDefaultResponse(false);
    }
  }, [canSubmitAllVotersDefaultResponse]);


  const [selectedOption, setSelectedOption] = useState<undefined | string>();

  const [finalScript, setFinalScript] = useState("");

  const { fetchScriptsData } = useCandidateScriptDetails()

  const { data: candidateInfo, status: candidateInfoStatus } = useQuery({
    queryKey: ["candidateInfo"],
    queryFn: () => authAxios.get(`api/v1/user/info/${userId}`),
    refetchOnWindowFocus: false,
  });

  // adding dynamic value for script variables
  //  const replaceMultipleWords = (
  //   inputString: string | undefined,
  //   replacements: { [key: string]: string | undefined }
  // ): string => {
  //   let result = inputString || "";
  //   for (const [wordToReplace, replacement] of Object.entries(replacements)) {
  //     // Check if the replacement is defined
  //     if (replacement !== undefined) {
  //       // Create a regular expression to match the word to be replaced
  //       // const regex = new RegExp("\\b" + wordToReplace + "\\b", "gi");
  //       const regex = new RegExp(`(?<=<select[^>]*>)${wordToReplace}(?=<\/option><\/select>)`, "gi");
  //       // Replace all occurrences of the word with the replacement
  //       result = result.replace(regex, replacement);
  //     }
  //   }
  //   return result;
  // };

  useEffect(() => {
    const filteredscript = fetchScriptsData?.find(
      (item: any) =>
        item.contactType == "By Door" && item.usageScope === "candidate"
    );
    const script: string = filteredscript?.script;
    console.log("script", script);

    if (voters?.length && candidateInfo?.data?.id) {
      const customScriptMappedObject: { [key: string]: string | undefined | any } = {
        "Voter Name":
          voters[0]?.name ?? "",
        "Candidate Name": candidateInfo?.data.fullName ?? "",
        "City": candidateInfo?.data.candidate.elections[0].city ?? "",
        "Position": candidateInfo?.data.candidate.elections[0].electionPosition ?? "",
        "Strength 1":
          candidateInfo?.data.candidate.candidateStories[0]?.strengths[0] ?? "",
        "Strength 2":
          candidateInfo?.data.candidate.candidateStories[0]?.strengths[1] ?? "",
      };
      const finalScriptTemp = replaceMultipleWords(
        script,
        customScriptMappedObject
      );
      setFinalScript(finalScriptTemp);
    }

    if (filteredscript?.isActive === true) {
      setSelectedOption("defaultScript");
      return;
    }
    if (filteredscript?.isActive === false) {
      setSelectedOption("customScript");
      return
    }
    if (!filteredscript?.length) {
      setSelectedOption("defaultScript");
    }
  }, [fetchScriptsData, candidateInfo, voters]);

  return (
    <aside className={`sidePanel control-sidebar ${show ? "open" : ""}`}>
      <div className="sidePanel-header shadow-4">
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="location-info">
            <LocationIcon />
            <div className="location-content">
              <div className="voter-location">
                {voters.length && voters[0]?.address}
              </div>
              <div className="voter-count">
                {voters.length + "  "} {voters.length > 1 ? "voters" : "voter"}
              </div>
            </div>
          </div>

          <div
            className="panel-close"
            onClick={() => {
              setShow(false);
              setActiveAddressIndex(-1);
            }}
          >
            <Link to="" className="close">
              <CloseIcon />
            </Link>
          </div>
        </div>
      </div>
      <div className="sidePanel-body scroll-y">
        {/* loading  */}
        {isLoading ? (
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          <div className="accordion accordion-flush" id="accordionExample">
            {voters?.map((voter, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}
                  >
                    <div>
                      <span className="body-4"> {voter.name} </span>

                      <div className="body-4 text-muted mb-0 n-mt-2">
                        {voter.sex}{" "}
                        <span className="dot bg-neutral-n3 ms-2"></span>
                        {voter.age}{" "}
                        <span className="dot bg-neutral-n3 ms-2"></span>
                        {voter.partyAffiliation}
                      </div>

                      {voter.voterContactHistories.length > 0 && (
                        <div className="body-4 text-muted mb-0 n-mt-2">
                          Marked as{" "}
                          <span className="text-s2 fw-semibold">
                            {" "}
                            {voter.voterContactHistories[0]?.status
                              ? voter.voterContactHistories[0]?.status
                              : voter.voterContactHistories[0]?.levelOfSupport}
                          </span>
                        </div>
                      )}
                      {/* <div className="body-4 text-muted mb-0 n-mt-2">
                    Marked as{" "}
                    <span className="text-s2 fw-semibold"> Not Home </span>
                  </div> */}
                    </div>
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <form key={index} id={String(index)}>
                    <div className="accordion-body">
                      <ul
                        className="nav nav-tabs nav-flush position-relative ps-0 justify-content-between"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id={`home-tab${index}`}
                            data-bs-toggle="tab"
                            data-bs-target={`#home-tab${index}-pane`}
                            type="button"
                            role="tab"
                            aria-controls={`home-tab${index}-pane`}
                            aria-selected="true"
                          >
                            Voter Response
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id={`profile-tab${index}`}
                            data-bs-toggle="tab"
                            data-bs-target={`#profile-tab${index}-pane`}
                            type="button"
                            role="tab"
                            aria-controls={`profile-tab$${index}-pane`}
                            aria-selected="false"
                          >
                            History
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id={`contact-tab$${index}`}
                            data-bs-toggle="tab"
                            data-bs-target={`#contact-tab${index}-pane`}
                            type="button"
                            role="tab"
                            aria-controls={`contact-tab${index}-pane`}
                            aria-selected="false"
                          >
                            Script
                          </button>
                        </li>
                      </ul>

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id={`home-tab${index}-pane`}
                          role="tabpanel"
                          aria-labelledby={`home-tab${index}`}
                          tabIndex={0}
                        >
                          <div className="voter-status">
                            <div className="d-flex justify-content-between">
                              <label className="body-5"> Voter Status </label>
                              <div className="card-info">
                                <Link
                                  to=""
                                  data-bs-html="true"
                                  data-bs-custom-class="custom-tooltip"
                                  className="text-left"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  data-bs-title={VOTER_STATUS_TOOLTIP}
                                >
                                  {" "}
                                  <HelpIcon />
                                </Link>
                              </div>
                            </div>
                            <div className="row">
                              {VOTER_STATUS_D2D.map((status, statusIndex) => (
                                <div className="col-md-6" key={statusIndex}>
                                  <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                    <input
                                      type="radio"
                                      className="btn-check form-check-input"
                                      id={status}
                                      autoComplete="off"
                                      defaultValue={status}
                                      defaultChecked={false}
                                      checked={selectedVoterStatus === status}
                                      onClick={() => {
                                        setSelectedLevelOfSupport("");
                                        status === selectedVoterStatus
                                          ? setSelectedVoterStatus("")
                                          : setSelectedVoterStatus(status);
                                      }}
                                    />
                                    <label
                                      className="btn btn-outline-primary w-100 ps-5 text-start"
                                      htmlFor={status}
                                    >
                                      {status}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div className="d-flex align-items-center py-3">
                              <hr className="w-100" />
                              <div className="px-4 text-muted">or</div>
                              <hr className="w-100" />
                            </div>
                          </div>

                          <div className="level-of-support">
                            <div className="d-flex justify-content-between">
                              <label className="body-5">
                                {" "}
                                Level of Support{" "}
                              </label>
                              <div className="card-info">
                                <Link
                                  to=""
                                  data-bs-html="true"
                                  data-bs-custom-class="custom-tooltip"
                                  className="text-left"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  data-bs-title={LOS_TOOLTIP}
                                >
                                  {" "}
                                  <HelpIcon />
                                </Link>
                              </div>
                            </div>
                            <div className="row">
                              {LEVEL_OF_SUPPORT.map((support, losIndex) => (
                                <div className="col-md-12" key={losIndex}>
                                  <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                    <input
                                      type="radio"
                                      className="btn-check form-check-input"
                                      id={support}
                                      autoComplete="off"
                                      defaultValue={support}
                                      defaultChecked={false}
                                      checked={
                                        selectedLevelOfSupport === support
                                      }
                                      onClick={() => {
                                        setSelectedVoterStatus("");
                                        support === selectedLevelOfSupport
                                          ? setSelectedLevelOfSupport("")
                                          : setSelectedLevelOfSupport(support);
                                      }}
                                    />
                                    <label
                                      className="btn btn-outline-primary w-100 ps-5 text-start"
                                      htmlFor={support}
                                    >
                                      {support}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id={`profile-tab${index}-pane`}
                          role="tabpanel"
                          aria-labelledby={`profile-tab${index}`}
                          tabIndex={0}
                        >
                          <h5 className="body-5">
                            {" "}
                            {voter.voterContactHistories.length
                              ? "Contact History"
                              : "No Contact History"}{" "}
                          </h5>
                          <div className="contact-history">
                            <div className="timeline timeline-sm">
                              {voter.voterContactHistories
                                .sort((a, b) => b.id - a.id)
                                .map((history) => (
                                  <div className="timeline-item pb-4">
                                    <div className="timeline-step">
                                      <span> </span>
                                    </div>
                                    <div className="timeline-content">
                                      <h4 className="mb-1 body-4">
                                        {" "}
                                        Marked as{" "}
                                        <span className="text-success body-5">
                                          {" "}
                                          {history.status ||
                                            history.levelOfSupport}
                                        </span>{" "}
                                      </h4>
                                      <p className="text-muted fs-8">
                                        Called on{" "}
                                        {
                                          getYearMonthDateTime(
                                            history.updatedAt
                                          ).split("-")[0]
                                        }
                                        <span className="dot bg-neutral-n3 ms-1">
                                          {" "}
                                        </span>{" "}
                                        {
                                          getYearMonthDateTime(
                                            history.updatedAt
                                          ).split("-")[1]
                                        }
                                      </p>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>

                          {/* if no history */}

                          {/* <div className="d-flex gap-2 flex-row align-items-center">
                        <InfoIconSecondary />
                        <p className="m-0 text-muted fs-7"> No tasks available for today </p>
                    </div>  */}
                        </div>
                        <div
                          className="tab-pane fade"
                          id={`contact-tab${index}-pane`}
                          role="tabpanel"
                          aria-labelledby={`contact-tab${index}`}
                          tabIndex={0}
                        >
                          <DoortoDoorScript selectedOption={selectedOption} candidateInfo={candidateInfo} voters={voters} finalScript={finalScript} />
                        </div>
                      </div>

                      <div className="card-footer d-flex shadow-6 justify-content-end">
                        <button className="btn btn-link btn-xs">
                          {" "}
                          Cancel{" "}
                        </button>
                        <button
                          className="btn btn-primary rounded-pill btn-xs py-1 px-4"
                          type="submit"
                          onClick={() => {
                            submitVoterResponse({
                              levelOfSupport: selectedLevelOfSupport,
                              voterStatus: selectedVoterStatus,
                              voterId: voter.id,
                            });
                            setSelectedLevelOfSupport("");
                            setSelectedVoterStatus("");
                          }}
                          disabled={
                            selectedLevelOfSupport.length ||
                              selectedVoterStatus.length
                              ? false
                              : true
                          }
                        >
                          {" "}
                          Save{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="sidePanel-footer card-footer d-flex justify-content-center">
        <button
          className="btn fw-bold bg-primary-p5 btn-xs rounded-pill py-2 px-4 w-100"
          onClick={() => {
            setShowSkipModal(true);
          }}
          disabled={!canVisitNextDoor}
        >
          {" "}
          Save and next door{" "}
        </button>
      </div>
    </aside>
  );
};

export default SidePanel;

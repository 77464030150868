import { useMutation, useQueryClient } from "@tanstack/react-query";
import Spinner from "components/common/Spinner"
import React, { useRef } from "react";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";

export const DeleteTurfModal = React.memo(({
  universeId,
  universeName
}: {
  universeId: number | null,
  universeName: string
}) => {
  const authAxios = CreateAuthAxiosInstance();
  const queryClient = useQueryClient()

  const cancelButtonRef = useRef<HTMLButtonElement>(null)

  const {
    mutate: deleteUniverseMutation,
    data: deleteUniverseMutationData,
    isPending: isPendingDeleteUniverse,
  } = useMutation({
    mutationFn: () => authAxios.delete(`api/v1/universes/${universeId}`),
    onSuccess: handleSuccessEvent,
    onError: (error: any) => {
      console.log("on error", error?.response?.data?.message);
      toastNotify('error', 'Error! Could not delete universe!')
    },
  });

  function handleSuccessEvent() {
    toastNotify('success', 'Universe deleted successfully!')
    if (cancelButtonRef.current) cancelButtonRef.current.click()

    queryClient.invalidateQueries({ queryKey: ['universeList'] })
  }

  return (
    <>
      <div
        className="modal fade"
        id="deleteTurfModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Do you want to delete this universe? </h2>
              <p>
                By deleting the universe "{universeName}", all turf
                for this universe will be deleted.
              </p>
            </div>
            <div className="modal-footer">
              <button
                ref={cancelButtonRef}
                className="btn btn-link rounded-pill btn-xs"
                data-bs-toggle="modal"
                data-bs-target="#manageTurfModal"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary rounded-pill btn-xs"
                disabled={isPendingDeleteUniverse}
                onClick={() => deleteUniverseMutation()}
              >
                <div className="d-flex align-items-center">
                  {isPendingDeleteUniverse && <Spinner />}
                  &nbsp;
                  Yes, Delete
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})


import { useEffect, useState } from "react";
import axios from "axios";
import VotersTable from "./VotersTable";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import UploadVotersList from "./UploadVotersList";
import Spinner from "templates/components/Spinner";

export const ASC = "ASC";
export const DESC = "DESC";
export const BY_PHONE = "By Phone";
export const DOOR_TO_DOOR = "Door to Door";
export const NOT_CONTACTED = "Not Contacted";

const VotersList: React.FC = () => {
  const [hasVotersList, setHasVotersList] = useState<boolean>();
  const [hasVotersListFileUploaded, setHasVotersListFileUploaded] =
    useState<boolean>();

  const [isFileUploadCompleted, setIsFileUploadCompleted] = useState(false);
  const { getIdTokenClaims } = useAuth0();
  const [token, setToken] = useState<IdToken>();
  const [electionId, setElectionId] = useState(null);
  useEffect(() => {
    (async () => {
      const tokenResponse = await getIdTokenClaims();
      setToken(tokenResponse);
    })();
  }, [getIdTokenClaims]);

  useEffect(() => {
    (async () => {
      try {
        // Get election response to check hasVotersList
        if (token) {
          const electionResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/candidate/election`,
            {
              headers: {
                Authorization: `Bearer ${token?.__raw}`,
              },
            }
          );
          setHasVotersList(electionResponse.data.hasVotersList);
          setHasVotersListFileUploaded(
            electionResponse.data.hasVotersListFileUploaded
          );
          setElectionId(electionResponse.data.id);
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, [token, isFileUploadCompleted]);

  return (
    <>
      {hasVotersList === undefined ||
      hasVotersListFileUploaded === undefined ? (
        <div className="bg-white spinner-wrapper shadow-2">
          <Spinner />
        </div>
      ) : hasVotersList || hasVotersListFileUploaded ? (
        <VotersTable
          electionId={electionId}
          hasVotersListFileUploaded={
            hasVotersList ? false : hasVotersListFileUploaded
          }
        />
      ) : (
        <UploadVotersList setIsFileUploadCompleted={setIsFileUploadCompleted} />
      )}
    </>
  );
};

export default VotersList;

import React from 'react'

const HelpIconSm = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 3.2C5.34903 3.2 3.2 5.34903 3.2 8C3.2 10.651 5.34903 12.8 8 12.8C10.651 12.8 12.8 10.651 12.8 8C12.8 5.34903 10.651 3.2 8 3.2ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8ZM8.00003 5.6C7.117 5.6 6.48295 6.02374 6.27935 6.45578C6.13808 6.75553 5.78057 6.88401 5.48082 6.74275C5.18107 6.60149 5.05258 6.24397 5.19385 5.94422C5.64918 4.97803 6.79311 4.4 8.00003 4.4C8.78004 4.4 9.5093 4.63637 10.0571 5.04721C10.6051 5.45819 11 6.07175 11 6.8C11 8.04161 9.89963 8.90493 8.7096 9.13321C8.66603 9.14157 8.63132 9.16315 8.61238 9.18319C8.60383 9.19225 8.60083 9.19844 8.60014 9.20007C8.6001 9.20017 8.60006 9.20025 8.60003 9.20032C8.59986 9.53155 8.3313 9.8 8.00003 9.8C7.66866 9.8 7.40003 9.53137 7.40003 9.2C7.40003 8.51474 7.94582 8.05786 8.48352 7.9547C9.36729 7.78517 9.80003 7.23772 9.80003 6.8C9.80003 6.53414 9.65775 6.2477 9.33709 6.00721C9.01624 5.76658 8.54551 5.6 8.00003 5.6ZM7.4 11C7.4 10.6686 7.66863 10.4 8 10.4H8.006C8.33737 10.4 8.606 10.6686 8.606 11C8.606 11.3314 8.33737 11.6 8.006 11.6H8C7.66863 11.6 7.4 11.3314 7.4 11Z" fill="#858599"/>
    </svg>
  )
}

export default HelpIconSm

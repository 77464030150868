import React, { useEffect } from "react";
import CloseIcon from "img/svg/closeIcon";
import { Link } from "react-router-dom";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import CheckSuccess from "img/svg/checkSuccess";
import TrashIconDanger from "img/svg/TrashIconDanger";
import EditIconPrimary from "img/svg/EditIconPrimary";
import SidePanelPlaceholder from "./SidePanelPlaceholder";
import FilterMultiSelectDd from "./FilterMultiSelectDd";
import SearchIcon from "img/svg/SearchIcon";
import Spinner from "./Spinner";

const SidePanelFilter = () => {
  return (
    <aside className="sidePanel control-sidebar shadow-4 open">
      <div className="sidePanel-header shadow-4">
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="location-info">
            <div className="location-content">
              <div className="voter-location"> Filter news by </div>
            </div>
          </div>

          <div className="panel-close">
            <Link to="" className="close">
              <CloseIcon />
            </Link>
          </div>
        </div>
      </div>
      <div className="sidePanel-body scroll-y">
        <div className="filter-header">
          <span className="body-3 text-muted">
            Filters applied at each level will only affect news clips from that
            same level.
          </span>
        </div>
        <div className="filter-body">
          <form action="">
            <div className="filter-item">
              <div className="filter-item-header">
                <div className="filter-item-title body-5">Local Level</div>
              </div>

              <div className="filter-item-body">
                {/* <div className="mb-3">
                  <div className="searchBox">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search news with specific keyword"
                    />
                    <SearchIcon />
                  </div>
                </div> */}
                <div className="mb-3">
                  <label htmlFor="newsSource" className="form-label">
                    News source URL
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="newsSource"
                    aria-describedby="sourceHelp"
                    placeholder="Input news source URL"
                  />
                </div>
                <div className="">
                  <label htmlFor="position" className="form-label">
                    {" "}
                    Top Issues{" "}
                  </label>
                  <FilterMultiSelectDd />
                  <p className="text-muted fs-8 mb-0 mt-1">
                    {" "}
                    3 issues selected
                  </p>
                </div>
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-item-header">
                <div className="filter-item-title body-5">National Level</div>
              </div>

              <div className="filter-item-body">
                {/* <div className="mb-3">
                  <div className="searchBox">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search news with specific keyword"
                    />
                    <SearchIcon />
                  </div>
                </div> */}

                <div className="">
                  <label htmlFor="position" className="form-label">
                    {" "}
                    Top Issues{" "}
                  </label>
                  <FilterMultiSelectDd />
                  <p className="text-muted fs-8 mb-0 mt-1">
                    {" "}
                    2 issues selected
                  </p>
                </div>
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-item-header">
                <div className="filter-item-title body-5">State Level</div>
              </div>

              <div className="filter-item-body">
                {/* <div className="mb-3">
                  <div className="searchBox">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search news with specific keyword"
                    />
                    <SearchIcon />
                  </div>
                </div> */}

                <div className="mb-5">
                  <label htmlFor="position" className="form-label">
                    {" "}
                    Top Issues{" "}
                  </label>
                  <FilterMultiSelectDd />
                  <p className="text-muted fs-8 mb-0 mt-1">
                    {" "}
                    1 issue selected
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="sidePanel-footer card-footer d-flex justify-content-end border-top">
        <button className="btn btn-link btn-xs rounded-pill me-2">Reset</button>
        <button className="btn btn-primary btn-xs rounded-pill">
        <Spinner /> Apply filters
        </button>
      </div>
    </aside>
  );
};

export default SidePanelFilter;

import UploadFile from "components/common/UploadFile";
import TrashIcon from "img/svg/TrashIcon";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import useFormPersist from "react-hook-form-persist";
import { useFieldArray, useForm } from "react-hook-form";
import {
  IDonorInfo,
  IFileType,
  IResponseFileInfo,
  TFormValues,
} from "type/dashboard";
import { yupResolver } from "@hookform/resolvers/yup";
import axios, { AxiosError } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { handleFileDelete } from "utils/uploadFile";
import { CANDIDATE_ID } from "../../Homepage";
import InfoIconLight from "img/svg/InfoIconLight";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";


const UploadDonorsV2 = ({
  electionId,
  hasDonorsFileUploaded,
  setHasDonorsFileUploaded,
}: {
  electionId: number;
  hasDonorsFileUploaded: boolean;
  setHasDonorsFileUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const localUserId = localStorage.getItem(CANDIDATE_ID);
  const { getIdTokenClaims } = useAuth0();
  const queryClient = useQueryClient()

  const [isFileUploadSelected, setIsFileUploadSelected] = useState<boolean>(true);
  const [isFileRead, setIsFileRead] = useState<boolean>(false);
  const [fileInfo, setFileInfo] = useState<IResponseFileInfo | null>();
  const [deleteFile, setDeleteFile] = useState(false);
  const closeRef = useRef(null);

  const donorSchema = Yup.object().shape({
    donors: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("Name is required")
          .min(3, "Name should be of minimum 3 characters in length")
          .matches(/^[aA-zZ\s]+$/, "Name should contain alphabets only"),
        phoneNumber: Yup.string()
          .required("Phone is required")
          .matches(
            /^(\+?[0-9]+-?)+[0-9]+$/,
            "Phone should contain numbers and characters only"
          )
          .min(10, ({ min }) => `Phone should be at least ${min} digits`)
          .max(14, ({ max }) => `Phone should be at most ${max} digits`),

        email: Yup.string()
          .email("Invalid email format")
          .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
            message: "Please enter valid format. eg: brain@campaign.com",
            excludeEmptyString: true,
          }),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<TFormValues>({
    resolver: yupResolver(donorSchema as any),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "donors",
  });

  const handleDonorFormSubmission = async (data: IDonorInfo[]) => {
    const token = await getIdTokenClaims();

    const donorObjects = Object.keys(data).map((key: string) => {
      const index = parseInt(key, 10);

      return {
        ...data[index],
      };
    });

    const donors = donorObjects.map((donor: any) => {
      donor["electionId"] = electionId;
      if (donor.email === "") {
        donor.email = null;
      }

      return donor;
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/donor/bulk`,
        {
          donors: donors,
        },
        {
          headers: {
            Authorization: `Bearer ${token?.__raw}`,
          },
        }
      );

      if (response?.status === 201) {
        queryClient.invalidateQueries({ queryKey: ['donorList'] })

      }
      reset();
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        if (error.response) {
          const errorMessage = error.response.data.message;

          if (errorMessage) {
            toast.error(errorMessage, {
              toastId: 1,
              className: "snackBar",
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "dark",
              hideProgressBar: true,
              autoClose: 5000,
              icon: <InfoIconLight />,
            });
          }
        }
      }
    }
  };

  const dataMap = fields.length
    ? fields
    : [{ name: "", phoneNumber: "", email: "" }];

  useFormPersist("addDonorForm", {
    watch,
    setValue,
    storage: localStorage,
  });

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const token = await getIdTokenClaims();

        const fileTypes = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/file-type`
        );

        const fileType: IFileType = fileTypes.data.find(
          (type: IFileType) => type.title === "Donors"
        );

        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/file/${Number(
            localUserId
          )}`,
          {
            headers: {
              Authorization: `Bearer ${token?.__raw}`,
            },
          }
        );
        if (response.data?.length > 0) {
          const fileTypeId: number = fileType.id;

          const requiredFile = response.data.filter(
            (file: IResponseFileInfo) =>
              file.fileTypeId.toString() === fileTypeId.toString()
          );
          if (requiredFile) setFileInfo(requiredFile[0]);
        } else {
          setFileInfo(null);
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    fetchFile();
  }, [isFileUploadSelected, setFileInfo, deleteFile, , hasDonorsFileUploaded]);

  return (
    <>
      {/* Add donor Modal */}
      <div
        className="modal fade"
        id="uploadModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-1">
              <h5 className="modal-title body-2 mb-0">Add donor information</h5>
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <form
              onSubmit={handleSubmit((data) => {
                if (isDirty && isValid) {
                  handleDonorFormSubmission(data.donors);
                }
              })}
            >
              <div className="modal-body">
                <div className="btn-checkGroup d-flex gap-3 mb-4">
                  <div className="btn-checkGroup-item btn-checkGroup-item--icon">
                    <input
                      type="radio"
                      className="btn-check form-check-input"
                      name="options"
                      id="option11"
                      autoComplete="off"
                      checked={isFileUploadSelected}
                      onChange={(e) => {
                        setIsFileUploadSelected(true);

                        if (e?.target.files) {
                          setIsFileRead(true);
                        }
                      }}
                    />
                    <label
                      className="btn btn-outline-primary rounded-pill ps-5"
                      htmlFor="option11"
                    >
                      File upload
                    </label>
                  </div>

                  <div className="btn-checkGroup-item btn-checkGroup-item--icon">
                    <input
                      type="radio"
                      className="btn-check form-check-input"
                      name="options"
                      id="option12"
                      autoComplete="off"
                      checked={!isFileUploadSelected}
                      onChange={(e) => {
                        setIsFileUploadSelected(false);
                      }}
                    />
                    <label
                      className="btn btn-outline-primary rounded-pill ps-5"
                      htmlFor="option12"
                    >
                      Add a donor manually
                    </label>
                  </div>
                </div>

                {isFileUploadSelected ? (
                  <div className="">
                    <div className="modal-body">
                      <div className="">
                        <label className="custom-label">File upload</label>
                        <p>
                          Upload a file of any format containing information
                          about donors in your district
                        </p>
                        <div className="row">
                          <div className="col-md-12 ">
                            <UploadFile
                              setIsFileRead={setIsFileRead}
                              activeStep={1}
                              closeModalRef={closeRef}
                              fileInfo={fileInfo}
                              setFileInfo={
                                setFileInfo as React.Dispatch<
                                  React.SetStateAction<IResponseFileInfo | null>
                                >
                              }
                              setDeleteFile={setDeleteFile}
                              deleteFile={deleteFile}
                              // hasDonorsFileUploaded={hasDonorsFileUploaded}
                              setHasDonorsFileUploaded={
                                setHasDonorsFileUploaded
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mb-3">
                    <label className="custom-label">Add a donor manually</label>
                    {dataMap.map((donor, index) => (
                      <div key={index} className="table-responsive mb-3">
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <label
                                  htmlFor={`inputName-${index}`}
                                  className="form-label"
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`inputName-${index}`}
                                  {...register(`donors.${index}.name`)}
                                  value={getValues(`donors.${index}.name`)}
                                />
                                {errors?.donors?.[index]?.name && (
                                  <span className="invalidInput form-text text-danger">
                                    {
                                      errors.donors?.[index]?.name
                                        ?.message as string
                                    }
                                  </span>
                                )}
                              </td>
                              <td>
                                <label
                                  htmlFor={`inputPhone-${index}`}
                                  className="form-label"
                                >
                                  Phone
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`inputPhone-${index}`}
                                  {...register(`donors.${index}.phoneNumber`)}
                                  value={getValues(
                                    `donors.${index}.phoneNumber`
                                  )}
                                />
                                {errors?.donors?.[index]?.phoneNumber && (
                                  <span className="invalidInput form-text text-danger">
                                    {
                                      errors.donors?.[index]?.phoneNumber
                                        ?.message
                                    }
                                  </span>
                                )}
                              </td>
                              <td>
                                <label
                                  htmlFor={`inputEmail-${index}`}
                                  className="form-label"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id={`inputEmail-${index}`}
                                  {...register(`donors.${index}.email`)}
                                  value={getValues(`donors.${index}.email`)}
                                />
                                {errors?.donors?.[index]?.email && (
                                  <span className="invalidInput form-text text-danger">
                                    {errors.donors?.[index]?.email?.message}
                                  </span>
                                )}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className={`btn btn-link pt-0 px-0 mt-4 ${(fields.length !== 0 &&
                                    fields.length !== 1) ||
                                    (dataMap.length !== 1 && isDirty)
                                    ? "active"
                                    : "disabled"
                                    }`}
                                  onClick={() => remove(index)}
                                >

                                  <span className="svg-neutral-2">

                                    <TrashIcon />
                                  </span>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                    <button
                      className="btn btn-link text-primary px-1 py-1"
                      type="button"
                      onClick={() =>
                        append({ name: "", phoneNumber: "", email: "" })
                      }
                    >
                      <span className="icon-plus me-2"></span> Add another donor
                    </button>
                  </div>
                )}
              </div>
              {isFileUploadSelected ? (
                <div className="modal-footer border-1">
                  <Link
                    to=""
                    className="btn btn-link text-primary btn-xs"
                    onClick={() => {
                      if (isFileRead && !hasDonorsFileUploaded) {
                        setDeleteFile(true);
                        setHasDonorsFileUploaded(false);
                      }
                      reset();
                    }}
                    data-bs-dismiss="modal"
                    data-bs-target="#uploadModal"
                  >
                    Cancel
                  </Link>
                  <button
                    className={`btn btn-primary rounded-pill btn-xs px-6 ${isFileRead ? "" : "btn-disable"}`}
                    onClick={() => setHasDonorsFileUploaded && setHasDonorsFileUploaded(true)}
                    data-bs-target="#uploadModal"
                    data-bs-dismiss="modal"
                  >
                    Done
                  </button>
                </div>
              ) : (
                <div className="modal-footer border-1">
                  <Link
                    to=""
                    className="btn btn-link text-primary btn-xs"
                    data-bs-dismiss="modal"
                    data-bs-target="#uploadModal"
                    onClick={() => {
                      handleFileDelete(1);
                      reset();
                    }}
                  >
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className={`btn btn-primary rounded-pill btn-xs px-6 ${isDirty ? "" : "btn-disable"}`}
                    data-bs-dismiss={isValid ? "modal" : ""}
                  >
                    Done
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(UploadDonorsV2)

import Spinner from "templates/components/Spinner";
import usePersonalDetails from "./usePersonalDetails";

export const AccountDeleteModal = () => {
  const { onAccountDeletion, isDeleteLoading } = usePersonalDetails();
  return (
    <div
      className="modal fade"
      id="deleteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close p-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span className="icon-close text-n1 h2"></span>
            </button>
          </div>
          <div className="modal-body text-center px-4">
            <img
              src={require("../../../../../src/img/delete-warning.png")}
              width={56}
              height={56}
              alt="delete warning"
            />
            <h2 className="mt-3">Delete your account? </h2>
            <p>
              You will no longer be able to access your account, and voter and
              donor data may be lost. You will still be responsible for
              fulfilling any outstanding payments.
            </p>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-link rounded-pill btn-xs"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>

            <button
              className={`btn btn-danger rounded-pill btn-xs ${
                isDeleteLoading ? "disabled" : ""
              }`}
              onClick={() => onAccountDeletion()}
            >
              {isDeleteLoading ? <Spinner /> : null}
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

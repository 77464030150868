import React from "react";

const LogoOnly = () => {
  return (
    <svg
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7148 3.38623C14.5063 3.38623 15.1539 4.02665 15.1539 4.82538C15.1539 5.62412 14.5135 6.26453 13.7148 6.26453C12.916 6.26453 12.2756 5.62412 12.2756 4.82538C12.2756 4.02665 12.916 3.38623 13.7148 3.38623Z"
        fill="url(#paint0_linear_2870_5383)"
      />
      <path
        d="M11.446 14.607C11.6012 14.5007 11.6769 14.3889 11.7696 14.2502C12.101 13.7514 12.6671 13.4278 13.305 13.4231C14.3122 13.424 15.1312 14.2361 15.1534 15.2463C15.1537 15.2592 15.1539 15.2721 15.1539 15.285V15.2871C15.1547 16.3161 14.3287 17.1489 13.3081 17.148C12.6651 17.1475 12.1035 16.8177 11.7713 16.3184L11.7712 16.3182C11.6828 16.1862 11.6114 16.0795 11.465 15.9757C11.2292 15.8086 10.9949 15.814 10.7433 15.8198L10.6918 15.8209C10.6611 15.8215 10.6301 15.8219 10.5988 15.8219C9.77912 15.8212 9.07008 16.2023 8.61353 16.8857C8.10183 17.6565 7.22741 18.1568 6.24221 18.1639C4.6659 18.1626 3.388 16.8734 3.38672 15.2832C3.38544 13.693 4.66119 12.406 6.2375 12.4073C7.23059 12.4081 8.09799 12.9177 8.61088 13.6894C9.06859 14.3735 9.77825 14.7558 10.5979 14.7565C10.6434 14.7565 10.688 14.7575 10.7321 14.7584C10.9809 14.7637 11.2103 14.7685 11.446 14.607Z"
        fill="url(#paint1_linear_2870_5383)"
      />
      <path
        d="M15.1539 25.7458C15.1539 24.947 14.5063 24.3066 13.7148 24.3066C12.916 24.3066 12.2756 24.947 12.2756 25.7458C12.2756 26.5445 12.916 27.1849 13.7148 27.1849C14.5135 27.1849 15.1539 26.5445 15.1539 25.7458Z"
        fill="url(#paint2_linear_2870_5383)"
      />
      <path
        d="M10.4491 5.27169C9.99257 4.87901 9.29122 4.93403 8.89458 5.39363C8.49794 5.85322 8.54716 6.55382 9.0037 6.94651C9.22809 7.13953 9.51044 7.22632 9.79293 7.21141C10.2123 7.17851 10.585 7.30907 10.9022 7.58195C11.3046 7.92803 11.4914 8.4145 11.4268 8.94246C11.3522 9.53658 11.5698 10.1513 12.0501 10.5712C12.8239 11.2368 13.9952 11.1449 14.6675 10.3659C15.3397 9.58693 15.2575 8.41683 14.4837 7.75128C13.9963 7.33197 13.3556 7.2152 12.778 7.3767C12.2646 7.51866 11.755 7.40611 11.3526 7.06002C11.0354 6.78714 10.8582 6.43792 10.8215 6.01956C10.8022 5.74511 10.6812 5.47136 10.4491 5.27169Z"
        fill="url(#paint3_linear_2870_5383)"
      />
      <path
        d="M8.89458 25.1776C9.29122 25.6371 9.99257 25.6922 10.4491 25.2995C10.6812 25.0998 10.8022 24.8261 10.8215 24.5516C10.8582 24.1333 11.0354 23.784 11.3526 23.5112C11.755 23.1651 12.2646 23.0525 12.778 23.1945C13.3556 23.356 13.9963 23.2392 14.4837 22.8199C15.2575 22.1543 15.3397 20.9843 14.6675 20.2053C13.9952 19.4263 12.8239 19.3344 12.0501 20C11.5698 20.4198 11.3522 21.0346 11.4268 21.6287C11.4914 22.1567 11.3046 22.6431 10.9022 22.9892C10.585 23.2621 10.2123 23.3927 9.79293 23.3598C9.51044 23.3449 9.22809 23.4316 9.0037 23.6247C8.54716 24.0174 8.49794 24.718 8.89458 25.1776Z"
        fill="url(#paint4_linear_2870_5383)"
      />
      <path
        d="M11.5285 11.6783C11.9844 12.0717 12.0337 12.7735 11.6378 13.2337C11.2419 13.694 10.5418 13.7489 10.0859 13.3555C9.86182 13.1621 9.73423 12.8957 9.70718 12.6141C9.6777 12.1945 9.49348 11.8452 9.17668 11.5718C8.77489 11.225 8.26615 11.1122 7.75354 11.2542C7.17699 11.4159 6.53679 11.2915 6.05052 10.8786C5.27785 10.2118 5.1955 9.03976 5.8665 8.25967C6.5375 7.4796 7.70688 7.38788 8.47954 8.05471C8.96632 8.47483 9.17657 9.09121 9.10225 9.68628C9.0378 10.2151 9.22446 10.7024 9.62625 11.0491C9.94304 11.3226 10.3146 11.4462 10.7338 11.4206C11.0081 11.3991 11.2967 11.4783 11.5285 11.6783Z"
        fill="url(#paint5_linear_2870_5383)"
      />
      <path
        d="M11.6378 17.3374C12.0337 17.7977 11.9844 18.4994 11.5285 18.8929C11.2967 19.0929 11.0081 19.1721 10.7338 19.1506C10.3146 19.125 9.94304 19.2486 9.62625 19.522C9.22446 19.8688 9.0378 20.3561 9.10225 20.8849C9.17657 21.48 8.96632 22.0964 8.47954 22.5165C7.70688 23.1833 6.5375 23.0916 5.8665 22.3115C5.1955 21.5314 5.27785 20.3594 6.05052 19.6926C6.53679 19.2797 7.17699 19.1553 7.75354 19.3169C8.26615 19.459 8.77489 19.3461 9.17668 18.9994C9.49348 18.726 9.6777 18.3767 9.70718 17.9571C9.73423 17.6755 9.86182 17.4091 10.0859 17.2157C10.5418 16.8223 11.2419 16.8772 11.6378 17.3374Z"
        fill="url(#paint6_linear_2870_5383)"
      />
      <path
        d="M18.2852 27.1849C17.4937 27.1849 16.8461 26.5445 16.8461 25.7458C16.8461 24.9471 17.4865 24.3066 18.2852 24.3066C19.084 24.3066 19.7244 24.9471 19.7244 25.7458C19.7244 26.5445 19.084 27.1849 18.2852 27.1849Z"
        fill="url(#paint7_linear_2870_5383)"
      />
      <path
        d="M20.554 15.9641C20.3988 16.0705 20.3231 16.1822 20.2304 16.321C19.899 16.8197 19.3329 17.1434 18.695 17.148C17.6878 17.1472 16.8688 16.335 16.8466 15.3249C16.8463 15.312 16.8461 15.2991 16.8461 15.2861V15.2841C16.8453 14.2551 17.6713 13.4223 18.6919 13.4232C19.3349 13.4237 19.8965 13.7534 20.2287 14.2527L20.2288 14.253C20.3172 14.385 20.3886 14.4917 20.535 14.5954C20.7708 14.7625 21.0051 14.7572 21.2567 14.7514L21.3082 14.7503C21.3389 14.7497 21.3699 14.7492 21.4012 14.7493C22.2209 14.7499 22.9299 14.3689 23.3865 13.6855C23.8982 12.9146 24.7726 12.4144 25.7578 12.4073C27.3341 12.4086 28.612 13.6977 28.6133 15.288C28.6146 16.8782 27.3388 18.1652 25.7625 18.1639C24.7694 18.1631 23.902 17.6535 23.3891 16.8818C22.9314 16.1976 22.2218 15.8154 21.4021 15.8147C21.3566 15.8146 21.312 15.8137 21.2679 15.8128C21.0191 15.8075 20.7897 15.8026 20.554 15.9641Z"
        fill="url(#paint8_linear_2870_5383)"
      />
      <path
        d="M16.8461 4.82539C16.8461 5.62412 17.4937 6.26454 18.2852 6.26454C19.084 6.26454 19.7244 5.62412 19.7244 4.82539C19.7244 4.02666 19.084 3.38624 18.2852 3.38624C17.4865 3.38624 16.8461 4.02666 16.8461 4.82539Z"
        fill="url(#paint9_linear_2870_5383)"
      />
      <path
        d="M21.5509 25.2995C22.0074 25.6922 22.7088 25.6371 23.1054 25.1775C23.5021 24.7179 23.4528 24.0173 22.9963 23.6247C22.7719 23.4316 22.4896 23.3449 22.2071 23.3598C21.7877 23.3927 21.415 23.2621 21.0978 22.9892C20.6954 22.6431 20.5086 22.1567 20.5732 21.6287C20.6478 21.0346 20.4302 20.4198 19.9499 19.9999C19.1761 19.3344 18.0048 19.4263 17.3325 20.2053C16.6603 20.9842 16.7425 22.1543 17.5163 22.8199C18.0037 23.2392 18.6444 23.356 19.222 23.1945C19.7354 23.0525 20.245 23.1651 20.6474 23.5111C20.9646 23.784 21.1418 24.1333 21.1785 24.5516C21.1978 24.8261 21.3188 25.0998 21.5509 25.2995Z"
        fill="url(#paint10_linear_2870_5383)"
      />
      <path
        d="M23.1054 5.39362C22.7088 4.93402 22.0074 4.879 21.5509 5.27167C21.3188 5.47135 21.1978 5.74509 21.1785 6.01954C21.1418 6.4379 20.9646 6.78713 20.6474 7.06001C20.245 7.4061 19.7354 7.51865 19.222 7.37668C18.6444 7.21519 18.0037 7.33196 17.5163 7.75127C16.7425 8.41682 16.6603 9.58692 17.3325 10.3659C18.0048 11.1449 19.1761 11.2368 19.9499 10.5712C20.4302 10.1513 20.6478 9.53657 20.5732 8.94245C20.5086 8.41448 20.6954 7.92802 21.0978 7.58194C21.415 7.30906 21.7877 7.1785 22.2071 7.2114C22.4896 7.22631 22.7719 7.13952 22.9963 6.9465C23.4528 6.55381 23.5021 5.85321 23.1054 5.39362Z"
        fill="url(#paint11_linear_2870_5383)"
      />
      <path
        d="M20.4715 18.8929C20.0156 18.4994 19.9663 17.7977 20.3622 17.3374C20.7581 16.8772 21.4582 16.8223 21.9141 17.2157C22.1382 17.4091 22.2658 17.6755 22.2928 17.9571C22.3223 18.3767 22.5065 18.726 22.8233 18.9994C23.2251 19.3461 23.7338 19.459 24.2465 19.3169C24.823 19.1553 25.4632 19.2797 25.9495 19.6926C26.7222 20.3594 26.8045 21.5314 26.1335 22.3115C25.4625 23.0916 24.2931 23.1833 23.5205 22.5165C23.0337 22.0963 22.8234 21.48 22.8977 20.8849C22.9622 20.3561 22.7755 19.8688 22.3738 19.522C22.057 19.2486 21.6854 19.125 21.2662 19.1506C20.9919 19.1721 20.7033 19.0929 20.4715 18.8929Z"
        fill="url(#paint12_linear_2870_5383)"
      />
      <path
        d="M20.3622 13.2337C19.9663 12.7735 20.0156 12.0717 20.4715 11.6783C20.7033 11.4782 20.9919 11.399 21.2662 11.4206C21.6854 11.4462 22.057 11.3225 22.3738 11.0491C22.7755 10.7024 22.9622 10.2151 22.8977 9.68627C22.8234 9.09119 23.0337 8.47482 23.5205 8.0547C24.2931 7.38787 25.4625 7.47959 26.1335 8.25966C26.8045 9.03975 26.7222 10.2118 25.9495 10.8786C25.4632 11.2915 24.823 11.4158 24.2465 11.2542C23.7338 11.1122 23.2251 11.225 22.8233 11.5718C22.5065 11.8452 22.3223 12.1945 22.2928 12.6141C22.2658 12.8957 22.1382 13.1621 21.9141 13.3555C21.4582 13.7489 20.7581 13.694 20.3622 13.2337Z"
        fill="url(#paint13_linear_2870_5383)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2870_5383"
          x1="9.27031"
          y1="3.38623"
          x2="9.27031"
          y2="27.1849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2870_5383"
          x1="9.27031"
          y1="3.38623"
          x2="9.27031"
          y2="27.1849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2870_5383"
          x1="9.27031"
          y1="3.38623"
          x2="9.27031"
          y2="27.1849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2870_5383"
          x1="9.27031"
          y1="3.38623"
          x2="9.27031"
          y2="27.1849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2870_5383"
          x1="9.27031"
          y1="3.38623"
          x2="9.27031"
          y2="27.1849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2870_5383"
          x1="9.27031"
          y1="3.38623"
          x2="9.27031"
          y2="27.1849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2870_5383"
          x1="9.27031"
          y1="3.38623"
          x2="9.27031"
          y2="27.1849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2870_5383"
          x1="22.7297"
          y1="27.1849"
          x2="22.7297"
          y2="3.38624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2870_5383"
          x1="22.7297"
          y1="27.1849"
          x2="22.7297"
          y2="3.38624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2870_5383"
          x1="22.7297"
          y1="27.1849"
          x2="22.7297"
          y2="3.38624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2870_5383"
          x1="22.7297"
          y1="27.1849"
          x2="22.7297"
          y2="3.38624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2870_5383"
          x1="22.7297"
          y1="27.1849"
          x2="22.7297"
          y2="3.38624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_2870_5383"
          x1="22.7297"
          y1="27.1849"
          x2="22.7297"
          y2="3.38624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_2870_5383"
          x1="22.7297"
          y1="27.1849"
          x2="22.7297"
          y2="3.38624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B45A5" />
          <stop offset="1" stopColor="#97BBF8" stopOpacity="0.96" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoOnly;

import { useMutation, useQuery } from "@tanstack/react-query";
import { Tooltip } from "bootstrap";
import {
  IBudgetFileDetails,
  IBudgetFileType,
} from "components/pages/dashboard/account-setup/Budget";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { useEffect, useRef } from "react";
import { queryClient } from "index";
import CreateAuthAxiosInstance from "utils/authAxios";
import { globalQueryConfig } from "utils/reactQuery";
import toastNotify from "utils/toastNotify";

export const useBudgetDetails = () => {
  const authAxios = CreateAuthAxiosInstance();
  const candidateId = localStorage.getItem(CANDIDATE_ID);

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  // Expense table refs
  const expenseTableContainerRef = useRef<HTMLDivElement>(null);
  const expenseCardRef = useRef<HTMLDivElement>(null);
  const expenseSliderNextRef = useRef<HTMLButtonElement>(null);

  // Income table refs
  const incomeTableContainerRef = useRef<HTMLDivElement>(null);
  const incomeCardRef = useRef<HTMLDivElement>(null);
  const incomeSliderNextRef = useRef<HTMLButtonElement>(null);

  const colOneWidth = 240;
  const colTwoWidth = 155;
  const scrollStep = 152; // Width of each cell
  const stickyColumnsWidth = colOneWidth + colTwoWidth; // Width of the first two sticky columns
  let maxScroll = null;

  const handlePrevClick = (
    tableContainerRef: React.RefObject<HTMLDivElement>,
    cardRef: React.RefObject<HTMLDivElement>,
    sliderNextRef: React.RefObject<HTMLButtonElement>
  ) => {
    if (tableContainerRef.current) {
      const maxScroll =
        tableContainerRef.current.scrollWidth -
        tableContainerRef.current.clientWidth +
        stickyColumnsWidth;
      const newScrollAmount = Math.max(
        0,
        tableContainerRef.current.scrollLeft - scrollStep
      );
      tableContainerRef.current.scrollTo({
        left: newScrollAmount,
        behavior: "smooth",
      });
    }

    // Remove class if scrolling back
    if (cardRef.current) {
      cardRef.current.classList.remove("budget-slider");
      sliderNextRef.current?.classList.remove("next-disabled");
    }
  };

  const handleNextClick = (
    tableContainerRef: React.RefObject<HTMLDivElement>,
    cardRef: React.RefObject<HTMLDivElement>,
    sliderNextRef: React.RefObject<HTMLButtonElement>
  ) => {
    if (tableContainerRef.current) {
      const maxScroll =
        tableContainerRef.current.scrollWidth -
        tableContainerRef.current.clientWidth;

      const newScrollAmount = Math.min(
        maxScroll,
        tableContainerRef.current.scrollLeft + scrollStep
      );

      tableContainerRef.current.scrollTo({
        left: newScrollAmount,
        behavior: "smooth",
      });

      // Check if we've reached the end
      if (newScrollAmount >= maxScroll) {
        if (cardRef.current) {
          cardRef.current.classList.add("budget-slider");
          sliderNextRef.current?.classList.add("next-disabled");
        }
      } else {
        if (cardRef.current) {
          cardRef.current.classList.remove("budget-slider");
        }
      }
    }
  };

  const { data: electionDetail, refetch: refetchElectionDetails } = useQuery({
    queryKey: ["electionDetail"],
    queryFn: () =>
      authAxios("/api/v1/candidate/election").then((data) => data?.data),
    ...globalQueryConfig,
  });

  const isBudgetPopulated: boolean | undefined = electionDetail?.isBudgetPopulated;
  const isActBlueIntegrated: boolean = Boolean(electionDetail?.candidate?.actBlueIntegrationStatus?.toLowerCase() === 'linked')

  const {
    data: budgetDetail,
    isFetching: isFetchingBudgetDetail,
    refetch: refetchBudgetDetail,
  } = useQuery({
    queryKey: ["budget", "detail"],
    queryFn: () =>
      authAxios("api/v1/budgets/details").then((data) => data?.data),
    enabled: isBudgetPopulated ?? false,
    ...globalQueryConfig,
  });

  //checking fundraising goal
  const { data: funraisingData } = useQuery({
    queryKey: ["fundraisingGoalDetails"],
    queryFn: () =>
      authAxios
        .get(`api/v1/profile-setting/candidate-details`)
        .then((response) => response.data),
    ...globalQueryConfig,
  });

  // api for download template
  const fetchBudgetTemplate = async () => {
    const response = await authAxios.get("api/v1/budgets/import-template");
    return response.data;
  };

  const { data: budgetTemplate } = useQuery({
    queryKey: ["budgetTemplateDownload"],
    queryFn: fetchBudgetTemplate,
    ...globalQueryConfig,
  });

  //to get fileType id of Budget
  const { data: fileType } = useQuery({
    queryKey: ["budgetfileType"],
    queryFn: () =>
      authAxios.get("/api/v1/file-type").then((response) => response.data),
    ...globalQueryConfig,
  });

  //find the file type of Budget
  const budgetFileType = fileType?.find(
    (file: IBudgetFileType) => file.title === "candidate-budget"
  );

  // get Budget file detils from api
  const { data: budgetFile, refetch: refecthBudgetFile } = useQuery({
    queryKey: ["budgetFileData"],
    queryFn: () =>
      authAxios
        .get(`api/v1/file/${candidateId}`)
        .then((response) => response.data),
    ...globalQueryConfig,
  });

  //find detils of Budget
  const budgetDetailsFromApi = budgetFile?.find(
    (budget: IBudgetFileDetails) => budget.fileTypeId === budgetFileType?.id
  );

  const { mutate: populateDefaultBudget } = useMutation({
    mutationFn: () => authAxios.post("/api/v1/budgets/populate-default-budget"),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["electionDetail"],
      });
      await refetchBudgetDetail();
    },
    onError: (error: any) => {
      toastNotify("error", "Error populating default budget");
      console.log("error occured", error);
    },
  });

  useEffect(() => {
    if (isBudgetPopulated === false) populateDefaultBudget();
  }, [isBudgetPopulated]);

  const isBudgetDetailsLoading = isBudgetPopulated ? isFetchingBudgetDetail : true;

  return {
    expenseTableContainerRef,
    expenseCardRef,
    expenseSliderNextRef,
    incomeTableContainerRef,
    incomeCardRef,
    incomeSliderNextRef,
    handlePrevClick,
    handleNextClick,
    isBudgetDetailsLoading,
    budgetDetail,
    budgetDetailsFromApi,
    funraisingData,
    budgetFileType,
    isActBlueIntegrated
  };
};

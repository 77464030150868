import React from "react";

const ExpertListSkeleton = () => {
  return (
    <>
      <div className="card border-0 mt-4">
        <div className="card-body">
          <div className="row align-items-end">
            <div className="col-md-9">
              <div className="media media-lg gap-3 placeholder-glow">
                <div className="media-header border-0">
                  <span className="placeholder col-12 h-100 rounded-pill"></span>
                </div>

                <div className="media-body flex-1">
                  <h5 className="mb-0 placeholder col-3"></h5>

                  <span className="d-block placeholder col-2"></span>
                  <p className="mt-3 placeholder placeholder-xl col-10"></p>

                  <div className="d-flex gap-2">
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mt-3 text-center placeholder-glow">
                <button
                  type="submit"
                  className="btn btn-primary btn-xs rounded-pill w-100 d-block mb-2 disabled placeholder"
                ></button>
                <span className="placeholder placeholder-lg col-8">
                  {" "}
                  {" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card border-0 mt-4">
        <div className="card-body">
          <div className="row align-items-end">
            <div className="col-md-9">
              <div className="media media-lg gap-3 placeholder-glow">
                <div className="media-header border-0">
                  <span className="placeholder col-12 h-100 rounded-pill"></span>
                </div>

                <div className="media-body flex-1">
                  <h5 className="mb-0 placeholder col-3"></h5>

                  <span className="d-block placeholder col-2"></span>
                  <p className="mt-3 placeholder placeholder-xl col-10"></p>

                  <div className="d-flex gap-2">
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mt-3 text-center placeholder-glow">
                <button
                  type="submit"
                  className="btn btn-primary btn-xs rounded-pill w-100 d-block mb-2 disabled placeholder"
                ></button>
                <span className="placeholder placeholder-lg col-8">
                  {" "}
                  {" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card border-0 mt-4">
        <div className="card-body">
          <div className="row align-items-end">
            <div className="col-md-9">
              <div className="media media-lg gap-3 placeholder-glow">
                <div className="media-header border-0">
                  <span className="placeholder col-12 h-100 rounded-pill"></span>
                </div>

                <div className="media-body flex-1">
                  <h5 className="mb-0 placeholder col-3"></h5>

                  <span className="d-block placeholder col-2"></span>
                  <p className="mt-3 placeholder placeholder-xl col-10"></p>

                  <div className="d-flex gap-2">
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mt-3 text-center placeholder-glow">
                <button
                  type="submit"
                  className="btn btn-primary btn-xs rounded-pill w-100 d-block mb-2 disabled placeholder"
                ></button>
                <span className="placeholder placeholder-lg col-8">
                  {" "}
                  {" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card border-0 mt-4">
        <div className="card-body">
          <div className="row align-items-end">
            <div className="col-md-9">
              <div className="media media-lg gap-3 placeholder-glow">
                <div className="media-header border-0">
                  <span className="placeholder col-12 h-100 rounded-pill"></span>
                </div>

                <div className="media-body flex-1">
                  <h5 className="mb-0 placeholder col-3"></h5>

                  <span className="d-block placeholder col-2"></span>
                  <p className="mt-3 placeholder placeholder-xl col-10"></p>

                  <div className="d-flex gap-2">
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                    <span className="col-2 placeholder rounded-pill"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mt-3 text-center placeholder-glow">
                <button
                  type="submit"
                  className="btn btn-primary btn-xs rounded-pill w-100 d-block mb-2 disabled placeholder"
                ></button>
                <span className="placeholder placeholder-lg col-8">
                  {" "}
                  {" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpertListSkeleton;

import React, { useEffect, createRef, useRef } from "react";
import lottie from 'lottie-web';
import { Link } from "react-router-dom";
import animationData from "../../../../img/lotties/success.json";
import { useMutation } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import { CANDIDATE_ID } from "components/pages/Homepage";

interface Props {
  modalStatus: boolean;
  onClickModal: () => void;
}

const VerificationDisabled = ({ onClickModal, modalStatus }: Props) => {
  const element = useRef<HTMLDivElement>(null);
  const authAxios = CreateAuthAxiosInstance();
  const retrievedCandidateId = localStorage.getItem(CANDIDATE_ID);



  useEffect(() => {
    if (element.current) // add this
      lottie.loadAnimation({
        animationData,
        container: element.current,
        loop: false
      });
    return () => lottie.destroy();
  }, [element]);

  const {
    mutate: patchAutoDailerStatus,
    isPending: isPendingAutoDialerStatusMutation,
  } = useMutation({
    mutationFn: () =>
      authAxios
        .patch(`api/v1/user/${retrievedCandidateId}`,
          {
            "hasSeenAutoDialerStatusPopup": true
          }
        ),
    onSuccess: () => {
      // console.log("Success")
    },
    onError: (error: unknown) => {
      // console.log("on error", error?.response?.data?.message);
    },
  });

  function handleOnContinueEvent() {
    if (retrievedCandidateId) {
      patchAutoDailerStatus()
    }
    onClickModal()
  }

  return (
    <div className={modalStatus ? "modal fade show" : "modal fade"} style={{ zIndex: 2000 }}>
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="card card-flush card-twilio shadow-6">
            <div className="card-body text-center pt-4">
              <img
                src={require("../../../../img/call-disabled.png")}
                width={56}
                height={56}
                alt="leave alert"
              />
              {/* <div className="lottee-container" ref={element} style={{width: 56 , height: 56}}> </div> */}

              <h2 className="mt-4"> Autodialer Deactivated </h2>
              <p className="mb-6 body-3 text-muted">
                You will no longer be able to use auto dialer services, but you may continue with manually contacting voters.
              </p>

              <div className="w-100">
                <Link
                  to=""
                  className="btn btn-primary btn-xs rounded-pill w-100"
                  onClick={handleOnContinueEvent}>
                  Continue
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerificationDisabled

import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { IBudgetFileDetails } from "components/pages/dashboard/account-setup/Budget";
import CheckSuccess from "img/svg/checkSuccess";
import FileIcon from "img/svg/fileIcon";
import TrashIcon from "img/svg/TrashIcon";
import React from "react";
import { dateFormatter, formatFileSize } from "utils/uploadFile";

const InitialFile = ({
  budgetDetailsFromApi,
  handleInputFileChange,
}: // mutateDeleteBudgetFile,
{
  budgetDetailsFromApi: IBudgetFileDetails;
  handleInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // mutateDeleteBudgetFile: UseMutateFunction<
  //   AxiosResponse<any, any>,
  //   Error,
  //   void,
  //   unknown
  // >;
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="uploadedCard uploadedCard-danger mb-0 flex-wrap">
          <div className="uploadedCard-icon">
            <div className="iconBox">
              <FileIcon />
            </div>
            <div className="iconInfo">
              <span className="iconInfo-title">
                {budgetDetailsFromApi?.fileName}
              </span>
              <p className="iconInfo-desc">
                {formatFileSize(budgetDetailsFromApi?.size)},
                {dateFormatter(budgetDetailsFromApi?.updatedAt)}
              </p>
              {budgetDetailsFromApi?.id && (
                <span className="iconInfo-status">
                  {" "}
                  <CheckSuccess /> File ready to be uploaded{" "}
                </span>
              )}
            </div>
          </div>
          <div className="uploadedCard-info">
            <div className="file-btn">
              <button className="btn btn-link rounded-pill text-primary">
                Change File
                <input
                  type="file"
                  name="file"
                  onChange={handleInputFileChange}
                />
              </button>
            </div>

            {/* <button
              className="btn btn-xs"
              type="button"
              onClick={() => mutateDeleteBudgetFile()}
            >
              <TrashIcon />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(InitialFile);

import React from "react";

interface OptionType {
  label: string;
  value: string;
}

interface NewsFilterDdListProps {
  data: OptionType;
}

const NewsFilterDdList = ({ data }: NewsFilterDdListProps) => {
  return (
    <div className="customDDList">
      <input type="checkbox" name="" id="" />
      <div className="customDDList-body">
        <span className="customDDList-title"> {data.label} </span>
      </div>
    </div>
  );
};

export default NewsFilterDdList;

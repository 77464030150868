import TwitterXLogo from "img/svg/TwitterXLogo";
import { ICustomTitleProps } from "./interface";
import FacebookFLogo from "img/svg/FacebookFLogo";

const CustomEventTitle: React.FC<ICustomTitleProps> = ({
  title,
  customProps,
  handleCurrentEvent,
}) => {
  let socialMediaClasses, SocialMediaIcon;

  if (customProps.socialMedia === "Facebook") {
    socialMediaClasses = "bg-facebook";
    SocialMediaIcon = <FacebookIcon />;
  } else {
    socialMediaClasses = "bg-twitter";
    SocialMediaIcon = <TwitterIcon />;
  }
  const disabledClass = customProps.isApproved ? "" : "disabled";
  return (
    <>
      {customProps.isPostEventCompleted && customProps.hasCandidatePosts ?  (
        <button className={`btn  w-100 bg-event ${socialMediaClasses} success`}>
          <span className="eventItem">
            {SocialMediaIcon}
            <span className="d-flex flex-column">
              <span className="eventItem-title">{title}</span>

              <span className="eventItem-time"> 3:30 PM</span>
            </span>
          </span>
        </button>
      ) : (
        <button
          className={`btn  w-100 bg-event ${socialMediaClasses} ${disabledClass}`}
          data-bs-toggle="modal"
          data-bs-target="#updateEventModal"
          onClick={() => handleCurrentEvent(customProps.postId)}
        >
          <span className="eventItem">
            {SocialMediaIcon}
            <span className="d-flex flex-column">
              <span className="eventItem-title">{title}</span>

              {customProps.isApproved ? (
                <span className="eventItem-time"> 3:30 PM</span>
              ) : null}
            </span>
          </span>
        </button>
      )}
    </>
  );
};

export default CustomEventTitle;

function TwitterIcon() {
  return <TwitterXLogo />;
}

function FacebookIcon() {
  return <FacebookFLogo />;
}

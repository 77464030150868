import React, { useState } from "react";
import Select, { CSSObjectWithLabel, GroupBase, OptionProps } from "react-select";
import NewsFilterDdList from "./NewsFilterDdList";
import CreatableSelect from 'react-select/creatable';

interface OptionType {
  label: string;
  value: string;
}

const FilterMultiSelectDd = () => {
  // for select
  // const options = [
  //   { value: "foreign_policy", label: "Foreign Policy" },
  //   { value: "healthcare", label: "Healthcare" },
  //   { value: "transportation", label: "Transportation" },
  //   { value: "crime", label: "Crime" },
  //   { value: "environment", label: "Environment" },
  // ];

  const [options, setOptions] = useState<OptionType[]>([
    { value: "foreign_policy", label: "Foreign Policy" },
    { value: "healthcare", label: "Healthcare" },
    { value: "transportation", label: "Transportation" },
    { value: "crime", label: "Crime" },
    { value: "environment", label: "Environment" },
  ]);

  return (
    
      

      <CreatableSelect
        options={options}
        formatOptionLabel={(data) => <NewsFilterDdList data={data} />}
        hideSelectedOptions={false}
        isMulti
        menuPosition="fixed"
        placeholder={<div> Choose or create issue preference </div>}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "#0B45A5" : "#CBCBD8",
          }),

          option: (baseStyles: CSSObjectWithLabel, state: OptionProps<OptionType, true, GroupBase<OptionType>>): CSSObjectWithLabel => {
            console.log('option baseStyles:', baseStyles);
            console.log('option state:', state);

            return {
              ...baseStyles,
              backgroundColor: state.isSelected ? "#F4F4F6" : state.isFocused ? "#F4F4F6" : undefined,
            }
            
             
          }        
          
        }}
      />
    
  );
};

export default FilterMultiSelectDd;

import { getDateForBudgetPage } from "utils/formatDate";
import React, { useEffect, useState } from "react";
import CategorySection from "./CategorySection";
import {
  IBudgetDetail,
  ICategory,
  IMonthlyBudget
} from "../BudgetDetailType";

interface IBudgetTable {
  budgetType: string;
  tableContainerRef: React.RefObject<HTMLDivElement>;
  handlePrevClick: () => void;
  handleNextClick: () => void;
  monthColumnData: IMonthlyBudget[];
  budgetDetail: IBudgetDetail;
  cardRef?: React.RefObject<HTMLDivElement>;
  sliderNextRef?: React.RefObject<HTMLButtonElement>;
  isActBlueIntegrated?: boolean
}

export interface IDeleteTarget {
  title?: string | null;
  categoryId: number;
  subcategoryId: number;
}

const BudgetTable = ({
  budgetType,
  tableContainerRef,
  handlePrevClick,
  handleNextClick,
  monthColumnData,
  budgetDetail,
  cardRef,
  sliderNextRef,
  isActBlueIntegrated = false
}: IBudgetTable) => {
  const [grandTotalBudgetDetail, setGrandTotalBudgetDetail] = useState<Partial<IBudgetDetail> | null>(null)

  useEffect(() => {
    if (cardRef?.current) {
      if (monthColumnData?.length < 2) {
        cardRef.current.classList.add("budget-slider");
      } else {
        cardRef.current.classList.remove("budget-slider");
      }
    }
  }, [monthColumnData]);

  const finalTotalBudgetDetail = grandTotalBudgetDetail || budgetDetail

  return (
    <span id={budgetType}>
      <div className="row">
        <div className="col-md-12">
          <div
            className={
              monthColumnData?.length <= 2 ||
                monthColumnData?.length === undefined
                ? "card card-flush card-budget shadow-2 budget-slider"
                : "card card-flush card-budget shadow-2"
            }
            ref={cardRef}
          >
            <div className="card-header">
              <h4 className="card-title">{budgetType}</h4>
            </div>
            <div className="card-body p-0">
              <div
                className="table-wrapper table-container"
                ref={tableContainerRef}
              >
                <div className="table-navs">
                  <button
                    className="btn btn-link p-0 btn-prev"
                    onClick={handlePrevClick}
                  >
                    <span className="icon-chevron-left"> </span>
                  </button>
                  <button
                    className="btn btn-link p-0 btn-next"
                    onClick={handleNextClick}
                    ref={sliderNextRef}
                  >
                    <span className="icon-chevron-right"> </span>
                  </button>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        className="th-sticky"
                        style={{
                          minWidth: "240px",
                          maxWidth: "240px",
                          width: "240px",
                        }}
                      >
                        Inputs
                      </th>
                      <th
                        className="th-sticky"
                        style={{
                          minWidth: "152px",
                          maxWidth: "152px",
                          width: "152px",
                        }}
                      >
                        Election Total
                      </th>
                      <th
                        className="text-end"
                        style={{
                          minWidth: "152px",
                          maxWidth: "152px",
                          width: "152px",
                        }}
                      >
                        Pre Signup
                      </th>

                      {monthColumnData?.map((item: IMonthlyBudget, index) => {
                        const result = getDateForBudgetPage(item?.date);
                        return (
                          <th className="text-end" key={index}>
                            {result?.formattedDate || 0}
                            {result?.isCurrentMonth && (
                              <span className="d-block font-semibold text-success fs-8">
                                This month
                              </span>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Category starts */}
                    {!!budgetDetail?.categories?.length &&
                      budgetDetail.categories.map(
                        (category: ICategory, index: number) => {
                          return (
                            <CategorySection
                              key={index}
                              category={category}
                              index={index}
                              monthColumnData={monthColumnData}
                              setGrandTotalBudgetDetail={setGrandTotalBudgetDetail}
                              isActBlueIntegrated={isActBlueIntegrated}
                            />
                          );
                        }
                      )}
                  </tbody>
                  {/* Total expenses */}
                  <tfoot>
                    <tr>
                      <td className="text-start body-2">
                        Total {budgetType?.toLowerCase()}
                      </td>
                      <td>
                        <div className="budget__cell non-editable">
                          <span>$ {finalTotalBudgetDetail?.totalBudget || 0}</span>
                        </div>
                      </td>
                      {!!finalTotalBudgetDetail?.monthlyBudgets?.length &&
                        finalTotalBudgetDetail.monthlyBudgets.map(
                          (item: IMonthlyBudget, index: number) => (
                            <td key={index}>
                              <div className="budget__cell non-editable">
                                <span>$ {item?.amount || 0}</span>
                              </div>
                            </td>
                          )
                        )}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default React.memo(BudgetTable);

import React, { useEffect } from "react";
import CloseIcon from "img/svg/closeIcon";
import { Link } from "react-router-dom";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import CheckSuccess from "img/svg/checkSuccess";
import TrashIconDanger from "img/svg/TrashIconDanger";
import EditIconPrimary from "img/svg/EditIconPrimary";
import SidePanelPlaceholder from "./SidePanelPlaceholder";


const SidePanelDetails = () => {
  return (
    <aside className="sidePanel control-sidebar shadow-4 open">
      <div className="sidePanel-header shadow-4">
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="location-info">
            <div className="location-content">
              <div className="voter-location"> Volunteer Details </div>
            </div>
          </div>

          <div className="panel-close">
            <Link to="" className="close">
              <CloseIcon />
            </Link>
          </div>
        </div>
      </div>
      <div className="sidePanel-body scroll-y">
        {/* placeholder starts */}
        <SidePanelPlaceholder />
        {/* placeholder ends */}

        {/* Details starts */}
        <div className="volunteerDetails mb-4">
          <div className="volunteerDetails-header">
            <h5 className="heading-5 mb-0 fw-bold"> Daniel Foster </h5>

            <div className="volunteerDetails-header-toolbox">
              <button className="btn text-primary p-2 fs-7">
                {" "}
                <EditIconPrimary /> Edit{" "}
              </button>
              <button className="btn text-danger p-2 fs-7">
                {" "}
                <TrashIconDanger /> Delete{" "}
              </button>
            </div>
          </div>
          <div className="volunteerDetails-body">
            <div className="media media-sm py-2">
              <div className="media-header bg-neutral-n4 border-0">
                <span className="icon-mail"></span>
              </div>
              <div className="media-body">
                <h2 className="mb-0 body-4 text-muted lh-1"> Email </h2>
                <span className="d-block body-4 mb-0">
                  elenor.parker@gmail.com{" "}
                  <span className="ms-1">
                    <CheckSuccess />{" "}
                  </span>
                </span>
              </div>
            </div>

            <div className="media media-sm py-2">
              <div className="media-header bg-neutral-n4 border-0">
                <span className="icon-phone-filled"></span>
              </div>
              <div className="media-body">
                <h2 className="mb-0 body-4 text-muted lh-1"> Phone no.</h2>
                <span className="d-block body-4 mb-0"> (111) 222-333 </span>
              </div>
            </div>

            <div className="media media-sm py-2">
              <div className="media-header bg-neutral-n4 border-0">
                <span className="icon-user"></span>
              </div>
              <div className="media-body">
                <h2 className="mb-0 body-4 text-muted lh-1"> Status </h2>
                <span className="badge badge-warning rounded-pill">
                  Pending
                </span>
              </div>
            </div>

            <div className="media media-sm py-2">
              <div className="media-header bg-neutral-n4 border-0">
                <span className="icon-calendar1"></span>
              </div>
              <div className="media-body">
                <h2 className="mb-0 body-4 text-muted lh-1">
                  Last Active Date
                </h2>
                <span className="d-block body-4 mb-0"> - </span>
              </div>
            </div>
          </div>
        </div>

        <h4 className="body-2"> Activities </h4>
        <div className="volunteerActivities-body">
          <div className="volunteerActivities-body-item mb-4">
            <span className="body-4 text-muted"> 6/12/23</span>
            <div className="media media-sm py-2">
              <div className="media-header bg-transparent border-0">
                <span className="icon-by-phone"></span>
              </div>
              <div className="media-body">
                <h2 className="mb-0 body-5 lh-1"> Voters Contacted By Phone </h2>
                <span className="d-block body-4 mb-0 text-success">

                </span>
                <p className="text-muted fs-8 mb-0 mt-1">
                  {" "}
                  30 voters <span className="dot bg-neutral-n3 ms-1">
                    {" "}
                  </span>{" "}
                  4 hours 10 mins to contact{" "}
                </p>
              </div>
            </div>
            <div className="media media-sm py-2">
              <div className="media-header bg-transparent border-0">
                <span className="icon-knock"></span>
              </div>
              <div className="media-body">
                <h2 className="mb-0 body-5 lh-1"> Voters Contacted By Door to Door </h2>
                <span className="d-block body-4 mb-0 text-success">

                </span>
                <p className="text-muted fs-8 mb-0 mt-1">
                  {" "}
                  30 voters <span className="dot bg-neutral-n3 ms-1">
                    {" "}
                  </span>{" "}
                  4 hours 10 mins to contact{" "}
                </p>
              </div>
            </div>



          </div>
          <div className="volunteerActivities-body-item mb-4">
            <span className="body-4 text-muted"> 6/12/23</span>
            <div className="media media-sm py-2">
              <div className="media-header bg-transparent border-0">
                <span className="icon-by-phone"></span>
              </div>
              <div className="media-body">
                <h2 className="mb-0 body-5 lh-1"> Voters Contacted By Phone </h2>
                <span className="d-block body-4 mb-0 text-success">

                </span>
                <p className="text-muted fs-8 mb-0 mt-1">
                  {" "}
                  30 voters <span className="dot bg-neutral-n3 ms-1">
                    {" "}
                  </span>{" "}
                  4 hours 10 mins to contact{" "}
                </p>
              </div>
            </div>
            <div className="media media-sm py-2">
              <div className="media-header bg-transparent border-0">
                <span className="icon-knock"></span>
              </div>
              <div className="media-body">
                <h2 className="mb-0 body-5 lh-1"> Voters Contacted By Door to Door </h2>
                <span className="d-block body-4 mb-0 text-success">

                </span>
                <p className="text-muted fs-8 mb-0 mt-1">
                  {" "}
                  30 voters <span className="dot bg-neutral-n3 ms-1">
                    {" "}
                  </span>{" "}
                  4 hours 10 mins to contact{" "}
                </p>
              </div>
            </div>



          </div>

        </div>
        {/* <div className="d-flex gap-2 flex-row align-items-center">
          <InfoIconSecondary />
          <p className="m-0 text-muted fs-7">No activities available</p>
        </div> */}

      </div>
    </aside>
  );
};

export default SidePanelDetails;

import NoData from "img/svg/noData";
import { Link } from "react-router-dom";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import HelpIcon from "img/svg/helpIcon";

import { Tooltip } from "bootstrap";
import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from "react";
import SideNavbar from "components/common/navbar/SideNavbar";
import ChevronDownFilledIcon from "img/svg/ChevronDownFilledIcon";
import { CSVLink } from "react-csv";
import CustomTooltip from "components/common/CustomTooltip";
import CheckSuccess from "img/svg/checkSuccess";
import { toast } from "react-toastify";
import EditCategoryDrodown from "./EditCategoryDrodown";
// import { createPortal } from 'react-dom';
import ReactDOM from "react-dom";
import TableCellPlaceholder from "./TableCellPlaceholder";
import AddBudgetRow from "./AddBudgetRow";
import InfoIconWarning from "img/svg/InfoIconWarning";
import Spinner from "components/common/Spinner";
import TrashIcon from "img/svg/TrashIcon";
import FileIcon from "img/svg/fileIcon";
import InfoIconDanger from "img/svg/InfoIconDanger";
import InfoIconPrimary from "img/svg/InfoIconPrimary";
import HelpIconSm from "img/svg/HelpIconSm";


const BudgetCell: React.FC = () => {
  const [editField, setEditField] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("0");

  const handleCellClick = () => {
    setEditField(true);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setEditField(false);
  };

  return (
    <CustomTooltip content="Use only numeric">
      <div className="budget__cell" onClick={handleCellClick}>
        {editField ? (
          <input
            type="text"
            value={inputValue}
            className="form-control rounded-0"
            onChange={handleInputChange}
            onBlur={handleBlur}
            autoFocus
          />
        ) : (
          <>
            <span> ${inputValue} </span>
            {/* <div className="placeholder-wrapper">
            <TableCellPlaceholder />

          </div> */}
          </>
        )}
      </div>
    </CustomTooltip>
  );
};

const BudgetDetailsTemplate = () => {
  // tooltip
  const budgetDetailsTooltip = `Track your monthly expenses and income sources by editing the cells directly. The budget timeframe covers the period from before signup to one month after the election.`;

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  const cardRef = useRef<HTMLDivElement>(null);
  const sliderNextRef = useRef<HTMLButtonElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const colOneWidth = 240;
  const colTwoWidth = 155;
  const scrollStep = 152; // Width of each cell
  const stickyColumnsWidth = colOneWidth + colTwoWidth; // Width of the first two sticky columns

  const handlePrevClick = () => {
    if (tableContainerRef.current) {
      const maxScroll =
        tableContainerRef.current.scrollWidth -
        tableContainerRef.current.clientWidth +
        stickyColumnsWidth;
      const newScrollAmount = Math.max(
        0,
        tableContainerRef.current.scrollLeft - scrollStep
      );
      tableContainerRef.current.scrollTo({
        left: newScrollAmount,
        behavior: "smooth",
      });
    }

    // Remove class if scrolling back
    if (cardRef.current) {
      cardRef.current.classList.remove("budget-slider");
      sliderNextRef.current?.classList.remove("next-disabled");
    }
  };

  const handleNextClick = () => {
    if (tableContainerRef.current) {
      const maxScroll =
        tableContainerRef.current.scrollWidth -
        tableContainerRef.current.clientWidth;

      const newScrollAmount = Math.min(
        maxScroll,
        tableContainerRef.current.scrollLeft + scrollStep
      );

      // console.log("Max Scroll:", maxScroll);
      // console.log("New Scroll Amount:", newScrollAmount);
      // console.log("Current Scroll Left:", tableContainerRef.current.scrollLeft);

      tableContainerRef.current.scrollTo({
        left: newScrollAmount,
        behavior: "smooth",
      });

      // Check if we've reached the end
      if (newScrollAmount >= maxScroll) {
        // console.log("Reached the end");
        if (cardRef.current) {
          cardRef.current.classList.add("budget-slider");
          sliderNextRef.current?.classList.add("next-disabled");
        }
      } else {
        if (cardRef.current) {
          cardRef.current.classList.remove("budget-slider");
        }
      }
    }
  };

  // toaster
  const notify = () => {
    // toast("Wow so easy!");

    toast.error("Deleted Successfully !", {
      className: "alert alert-success",
      position: toast.POSITION.TOP_RIGHT,
      theme: "light",
      hideProgressBar: true,
      autoClose: 5000,
      icon: <CheckSuccess />,
    });
  };

  const [isOpen, setOpen] = useState(false);

  const handleDropdown = () => {
    setOpen(!isOpen);
  };

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="">
            <div className="row">
              <div className="col-md-12">
                {/* alert */}
                {/* <div
                  className="alert alert-primary alert-dismissible fade show"
                  role="alert"
                >
                  <div className="d-flex gap-2">
                    <InfoIconPrimary />{" "}

                    <span>
                      <span className="font-semibold">
                      Reflect according to budget file.
                      </span>

                      <span className="d-block font-regular">
                      Budget based on budget file. Edit this mode or change to a different mode from settings.
                      </span>

                    </span>

                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  >
                    <span className="icon-close"></span>
                  </button>
                </div> */}
                <div
                  className="alert alert-primary alert-dismissible fade show"
                  role="alert"
                >
                  <InfoIconPrimary />{" "}
                  {/* if budget uploded file  */}
                  <span className="ms-2">
                  Budget reflected according to uploaded budget file.
                  </span>

                  {/* if fundraise goal provided  */}
                  {/* <span className="ms-2">
                  Budget reflected according to suggested budget based on fundraising goal.
                  </span> */}


                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  >
                    <span className="icon-close"></span>
                  </button>
                </div>

                <div className="alert alert-warning fade show" role="alert">
                  <div className="d-flex justify-content-between">
                    <span>
                      <InfoIconWarning />{" "}
                      <span className="ms-2">
                        Add fundraising goal in settings or upload budget to
                        generate predefined budget.
                      </span>
                    </span>
                    <div className="d-flex gap-2">
                      <Link
                        to="/profile?tabIndex=3"
                        className="btn btn-link no-hover btn-xs text-warning py-0 px-0 fs-8"
                      >
                        {" "}
                        Go to Settings{" "}
                      </Link>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="section-box">
                  <div className="row align-items-center">
                    <div className="col-md-7">
                      <div className="d-flex gap-2 flex-column">
                        <div className="d-flex gap-2">
                          <h3 className="section-box-title">
                            {" "}
                            Budget details{" "}
                          </h3>
                          <CustomTooltip content={budgetDetailsTooltip}>
                            <HelpIcon />
                          </CustomTooltip>
                        </div>

                        {/* if started from scratch  */}
                        <span className="body-3 text-muted">
                        View or edit the projected budget information for before August 2023 to December 2023 started from scratch. You can change to different budget mode from settings.{" "}
                        </span>

                        {/* if budeget file uploaded */}
                        {/* <span className="body-3 text-muted">
                        View or edit the projected budget information for before August 2023 to December 2023 based on the uploaded budget file. You can change to different budget mode from settings.
                        </span> */}

                        {/* if fundraise goal provided */}
                        {/* <span className="body-3 text-muted">
                        View or edit the projected budget information for before August 2023 to December 2023 reflected according to suggested budget based fundraising goal. You can change to different budget mode from settings.
                        </span> */}
                      </div>
                    </div>

                    <div className="col-md-5 text-end">
                      <CSVLink
                        data=""
                        filename={"voters-list.csv"}
                        className="btn btn-info rounded-pill btn-xs px-4"
                        target="_blank"
                      >
                        {" "}
                        Export CSV{" "}
                      </CSVLink>

                      <button
                        className="btn btn-primary rounded-pill btn-xs px-4 ms-3"
                        data-bs-toggle="modal"
                        data-bs-target="#uploadModal"
                      >
                        {" "}
                        Upload file
                      </button>
                      <button
                        className="btn btn-primary rounded-pill btn-xs px-4 ms-3"
                        data-bs-toggle="modal"
                        data-bs-target="#editBudgetModal"
                      >
                        {" "}
                        Change file
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="budget-wrapper">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-flush card-budget shadow-2">
                    <div className="card-header">
                      <h4 className="card-title"> Expenses </h4>
                    </div>

                    <div className="card-body p-0">
                      <div
                        className="table-wrapper table-container"
                        ref={tableContainerRef}
                      >
                        <div className="table-navs">
                          <button
                            className="btn btn-link p-0 btn-prev"
                            id="prevBtn"
                            onClick={handlePrevClick}
                          >
                            <span className="icon-chevron-left"> </span>
                          </button>
                          <button
                            className="btn btn-link p-0 btn-next"
                            id="nextBtn"
                            onClick={handleNextClick}
                          >
                            <span className="icon-chevron-right"> </span>
                          </button>
                        </div>

                        <table className="table">
                          <thead>
                            <tr>
                              <th
                                className="th-sticky"
                                style={{ minWidth: "240px", width: "240px" }}
                              >
                                {" "}
                                Inputs{" "}
                              </th>
                              <th
                                className="th-sticky"
                                style={{ minWidth: "152px", width: "152px" }}
                              >
                                {" "}
                                Election Total{" "}
                              </th>
                              <th className="text-end"> Pre Signup</th>
                              <th className="text-end">
                                {" "}
                                Aug '23
                                <span className="d-block font-semibold text-success fs-8">
                                  {" "}
                                  This month
                                </span>
                              </th>
                              <th className="text-end"> Sep '23 </th>
                              <th className="text-end"> Oct '23</th>
                              <th className="text-end"> Nov '23 </th>
                              <th className="text-end"> Dec '23 </th>
                              <th className="text-end"> Jan '23 </th>
                            </tr>
                          </thead>

                          <tbody>
                            {/* 1. staff category starts */}
                            <tr>
                              <td>
                                {" "}
                                <div className="d-flex align-items-center gap-2 ps-3 ps-3">
                                  <button
                                    className="btn btn-link btn-circle p-0 rounded-circle lh-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse1"
                                    aria-expanded="false"
                                    aria-controls="collapse1"
                                  >
                                    <ChevronDownFilledIcon />
                                  </button>{" "}
                                  <span className="badge badge-secondary rounded-pill text-n1 border border-secondary">
                                    {" "}
                                    Staff{" "}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                </div>
                                {/* <span> $11 </span> */}
                              </td>
                              <td>
                                <BudgetCell />
                                {/* <input
                                type="text"
                                value="0"
                                className="form-control rounded-0"
                                
                              /> */}
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                            </tr>

                            {/* 1.1. staff sub category starts */}
                            <tr className="subCatWrapper">
                              <td colSpan={9}>
                                <div className="collapse" id="collapse1">
                                  <table className="table">
                                    <tbody>
                                      {/* staff sub category starts  */}
                                      <tr>
                                        <td className="">
                                          {/* add class budgetSubCat-editable for title editable mode */}
                                          <div className="budgetSubCat budgetSubCat-editabl">
                                            <span className="text-muted">
                                              Campaign Manager
                                            </span>{" "}
                                            {/* when editable mode on  */}
                                            {/* <input
                                              type="text"
                                              
                                              className="form-control rounded-0"
                                              
                                              autoFocus
                                            /> */}
                                            {/* if editable mode on hide this edit button  */}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              onClick={handleDropdown}
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                            {/* edit delete dropdown */}
                                            {/* {ReactDOM.createPortal(
                                              <EditCategoryDrodown />,
                                              document.body
                                            )} */}
                                            {isOpen && <EditCategoryDrodown />}
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $100</span>{" "}
                                          </div>
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              Campaign Staffer
                                            </span>{" "}
                                            {/* if editable mode on hide this edit button  */}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Organizer{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Paid Canvassers{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Consulting services{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Intern{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Staff Expense 1{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Staff Expense 2{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Staff Expense 3{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      {/* new row  */}
                                      <AddBudgetRow />

                                      {/* add sub category */}
                                      <tr>
                                        <td style={{ minWidth: "240px" }}>
                                          {" "}
                                          <button className="btn btn-link text-primary rounded-pill btn-xs d-flex align-items-center">
                                            {" "}
                                            <span className="icon-plus me-1"></span>{" "}
                                            Add Sub Category{" "}
                                          </button>
                                        </td>
                                      </tr>

                                      {/* staff sub category ends  */}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                            {/* 1.1. staff sub category ends */}

                            {/* 1. staff category ends */}

                            {/* 2. materials category starts */}
                            <tr>
                              <td>
                                {" "}
                                <div className="d-flex align-items-center gap-2 ps-3 ps-3">
                                  <button
                                    className="btn btn-link btn-circle p-0 rounded-circle lh-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse2"
                                    aria-expanded="false"
                                    aria-controls="collapse2"
                                  >
                                    <ChevronDownFilledIcon />
                                  </button>{" "}
                                  <span className="badge badge-secondary rounded-pill text-n1 border border-secondary">
                                    {" "}
                                    Materials{" "}
                                  </span>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <div className="budget__cell">
                                  {" "}
                                  <span> $0 </span>{" "}
                                </div>
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                            </tr>

                            {/* 2.1. materials category starts */}
                            <tr className="subCatWrapper">
                              <td colSpan={9}>
                                <div className="collapse" id="collapse2">
                                  <table className="table">
                                    <tbody>
                                      {/* materials sub category starts  */}
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              Yard/Road Signs
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              Printing/Lit
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Volunteer Suppliers & Food
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Radio Ads{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Digital Ads{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Print Ads{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Direct{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Materials Expense 1{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Materials Expense 2{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Materials Expense 3{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      {/* add sub category */}
                                      <tr>
                                        <td style={{ minWidth: "240px" }}>
                                          {" "}
                                          <button className="btn btn-link text-primary rounded-pill btn-xs d-flex align-items-center">
                                            {" "}
                                            <span className="icon-plus me-1"></span>{" "}
                                            Add Sub Category{" "}
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>

                            {/* 2.1. materials category ends */}
                            {/* 2. materials category ends */}

                            {/* 3. data & tech category starts */}

                            <tr>
                              <td>
                                {" "}
                                <div className="d-flex align-items-center gap-2 ps-3 ps-3">
                                  <button
                                    className="btn btn-link btn-circle p-0 rounded-circle lh-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse3"
                                    aria-expanded="false"
                                    aria-controls="collapse3"
                                  >
                                    <ChevronDownFilledIcon />
                                  </button>{" "}
                                  <span className="badge badge-secondary rounded-pill text-n1 border border-secondary">
                                    {" "}
                                    Data & Technology{" "}
                                  </span>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <div className="budget__cell">
                                  {" "}
                                  <span> $0 </span>{" "}
                                </div>{" "}
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                            </tr>
                            {/* 3.1. data & tech sub category starts */}

                            <tr className="subCatWrapper">
                              <td colSpan={9}>
                                <div className="collapse" id="collapse3">
                                  <table className="table">
                                    <tbody>
                                      {/* data & tech sub category starts  */}
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              Voterfile
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              Calls (Pay Per Call)
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Website Creation / Maintenance
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Data/Tech Expense 1
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Data/Tech Expense 2
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Data/Tech Expense 3
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      {/* add sub category */}
                                      <tr>
                                        <td style={{ minWidth: "240px" }}>
                                          {" "}
                                          <button className="btn btn-link text-primary rounded-pill btn-xs d-flex align-items-center">
                                            {" "}
                                            <span className="icon-plus me-1"></span>{" "}
                                            Add Sub Category{" "}
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                            {/* 3.1. data & tech sub category ends */}
                            {/* 3. data & tech category ends */}

                            {/* 4. Miscellaneous category starts */}
                            <tr>
                              <td>
                                {" "}
                                <div className="d-flex align-items-center gap-2 ps-3 ps-3">
                                  <button
                                    className="btn btn-link btn-circle p-0 rounded-circle lh-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse4"
                                    aria-expanded="false"
                                    aria-controls="collapse4"
                                  >
                                    <ChevronDownFilledIcon />
                                  </button>{" "}
                                  <span className="badge badge-secondary rounded-pill text-n1 border border-secondary">
                                    {" "}
                                    Miscellaneous{" "}
                                  </span>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <div className="budget__cell">
                                  {" "}
                                  <span> $0 </span>{" "}
                                </div>{" "}
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                            </tr>

                            <tr className="subCatWrapper">
                              <td colSpan={9}>
                                <div className="collapse" id="collapse4">
                                  <table className="table">
                                    <tbody>
                                      {/* Miscellaneous sub category starts  */}
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              Transportation
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              Campaign Office
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Events
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Legal
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Contingency
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Expense 1
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Expense 2
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Additional Expense 3
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      {/* add sub category */}
                                      <tr>
                                        <td style={{ minWidth: "240px" }}>
                                          {" "}
                                          <button className="btn btn-link text-primary rounded-pill btn-xs d-flex align-items-center">
                                            {" "}
                                            <span className="icon-plus me-1"></span>{" "}
                                            Add Sub Category{" "}
                                          </button>
                                        </td>
                                      </tr>

                                      {/* 4.1 material sub category ends  */}
                                      {/* 4. material category ends  */}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </tbody>

                          {/* Miscellaneous category ends */}

                          {/* total expenses */}
                          <tfoot>
                            <tr>
                              <td className="text-start body-2">
                                {" "}
                                Total expenses{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                  {/* <span> $0 </span> */}
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                  {/* <span> $0 </span> */}
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                  {/* <span> $0 </span> */}
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                  {/* <span> $0 </span> */}
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                  {/* <span> $0 </span> */}
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                  {/* <span> $0 </span> */}
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                  {/* <span> $0 </span> */}
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                  {/* <span> $0 </span> */}
                                </div>{" "}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <div
                    className="card card-flush card-budget shadow-2"
                    ref={cardRef}
                  >
                    <div className="card-header">
                      <h4 className="card-title"> Income </h4>
                    </div>

                    <div className="card-body p-0">
                      <div
                        className="table-wrapper table-container"
                        ref={tableContainerRef}
                      >
                        <div className="table-navs">
                          <button
                            className="btn btn-link p-0 btn-prev"
                            id="prevBtn"
                            onClick={handlePrevClick}
                          >
                            <span className="icon-chevron-left"> </span>
                          </button>
                          <button
                            className="btn btn-link p-0 btn-next"
                            id="nextBtn"
                            onClick={handleNextClick}
                            ref={sliderNextRef}
                          >
                            <span className="icon-chevron-right"> </span>
                          </button>
                        </div>

                        <table className="table">
                          <thead>
                            <tr>
                              <th
                                className="th-sticky"
                                style={{ minWidth: "240px", width: "240px" }}
                              >
                                {" "}
                                Inputs{" "}
                              </th>
                              <th
                                className="th-sticky"
                                style={{ minWidth: "152px", width: "152px" }}
                              >
                                {" "}
                                Election Total{" "}
                              </th>
                              <th className="text-end"> Pre Signup</th>
                              <th className="text-end">
                                {" "}
                                Aug '23{" "}
                                <span className="d-block font-semibold text-success fs-8">
                                  {" "}
                                  This month
                                </span>{" "}
                              </th>
                              <th className="text-end"> Sep '23 </th>
                              <th className="text-end"> Oct '23</th>
                              <th className="text-end"> Nov '23 </th>
                              <th className="text-end"> Dec '23 </th>
                              <th className="text-end"> Jan '23 </th>
                            </tr>
                          </thead>

                          {/* 1. Income source category starts */}
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <div className="d-flex align-items-center gap-2 ps-3 ps-3">
                                  <button
                                    className="btn btn-link btn-circle p-0 rounded-circle lh-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseGroup2"
                                    aria-expanded="false"
                                    aria-controls="collapseGroup2"
                                  >
                                    <ChevronDownFilledIcon />
                                  </button>{" "}
                                  <span className="badge badge-secondary rounded-pill text-n1 border border-secondary">
                                    {" "}
                                    Income Source{" "}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                </div>
                                {/* <span> $11 </span> */}
                              </td>
                              <td>
                                <BudgetCell />
                                {/* <input
                                type="text"
                                value="0"
                                className="form-control rounded-0"
                                
                              /> */}
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                              <td>
                                <BudgetCell />
                              </td>
                            </tr>

                            {/* 1.1. staff sub category starts */}
                            <tr className="subCatWrapper">
                              <td colSpan={9}>
                                <div className="collapse" id="collapseGroup2">
                                  <table className="table">
                                    <tbody>
                                      {/* staff sub category starts  */}
                                      <tr>
                                        <td className="">
                                          {/* add class budgetSubCat-editable for title editable mode */}
                                          <div className="budgetSubCat budgetSubCat-editabl">
                                            <span className="text-muted">
                                              Actblue
                                            </span>{" "}
                                            {/* when editable mode on  */}
                                            {/* <input
                                              type="text"
                                              
                                              className="form-control rounded-0"
                                              
                                              autoFocus
                                            /> */}

                                             {/* if editable mode off show this */ }
                                             <span className="budgetSubCat-tools opacity-100">
                                              <CustomTooltip content="To integrate your Actblue account and enhance your fundraising efforts, request the Actblue integration in Settings.">
                                                <HelpIconSm />
                                              </CustomTooltip>

                                             </span>

                                             

                                            {/* if editable mode on hide this edit button  */}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>

                                            {/* edit delete dropdown */}
                                            {/* {ReactDOM.createPortal(
                                              <EditCategoryDrodown />,
                                              document.body
                                            )} */}
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $100</span>{" "}
                                          </div>
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              Cash
                                            </span>{" "}
                                            {/* if editable mode on hide this edit button  */}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div className="budgetSubCat">
                                            {" "}
                                            <span className="text-muted">
                                              {" "}
                                              Check{" "}
                                            </span>{" "}
                                            <Link
                                              className="btn btn-link btn-circle rounded-circle p-0 text-n1 budgetSubCat-tools"
                                              to=""
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span className="icon-pencil">
                                                {" "}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="budget__cell">
                                            {" "}
                                            <span> $0 </span>{" "}
                                          </div>{" "}
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                        <td>
                                          <BudgetCell />
                                        </td>
                                      </tr>

                                      {/* add sub category */}
                                      <tr>
                                        <td style={{ minWidth: "240px" }}>
                                          {" "}
                                          <button className="btn btn-link text-primary rounded-pill btn-xs d-flex align-items-center">
                                            {" "}
                                            <span className="icon-plus me-1"></span>{" "}
                                            Add Sub Category{" "}
                                          </button>
                                        </td>
                                      </tr>

                                      {/* Income source sub category ends  */}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </tbody>

                          {/* income category ends */}

                          {/* total expenses */}
                          <tfoot>
                            <tr>
                              <td className="text-start body-2">
                                {" "}
                                Total income{" "}
                              </td>
                              <td>
                                {" "}
                                <div className="budget__cell">
                                  <TableCellPlaceholder />{" "}
                                  {/* <span> $0 </span> */}
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  {/* <TableCellPlaceholder />{" "} */}
                                  <span> $0 </span>
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  {/* <TableCellPlaceholder />{" "} */}
                                  <span> $0 </span>
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  {/* <TableCellPlaceholder />{" "} */}
                                  <span> $0 </span>
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  {/* <TableCellPlaceholder />{" "} */}
                                  <span> $0 </span>
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  {/* <TableCellPlaceholder />{" "} */}
                                  <span> $0 </span>
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  {/* <TableCellPlaceholder />{" "} */}
                                  <span> $0 </span>
                                </div>{" "}
                              </td>
                              <td>
                                <div className="budget__cell">
                                  {/* <TableCellPlaceholder />{" "} */}
                                  <span> $0 </span>
                                </div>{" "}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>

        {/* delete warning Modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body text-center px-4">
                <img
                  src={require("../../img/leave.png")}
                  width={66}
                  height={56}
                  alt="leave alert"
                />
                <h2 className="mt-3">
                  Do you want to delete “Yard/Road Signs”?{" "}
                </h2>
                <p>
                  By deleting this sub category, details filled for all months
                  will be erased.
                </p>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-link rounded-pill btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>

                <button
                  className="btn btn-primary rounded-pill btn-xs"
                  onClick={notify}
                >
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* edit budget Modal */}
        <div
          className="modal fade"
          id="editBudgetModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>

              <div className="modal-body text-center px-4">
                {/* <img
                src={require("../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              /> */}
                <h2 className="mt-3">Do you want to change the budget?</h2>
                <p>
                  By changing the budget, the details in the current budget will
                  get replaced by the new one.
                </p>
              </div>

              <div className="modal-footer">
                <Link
                  to=""
                  className="btn btn-link text-primary btn-xs rounded-pill"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
                {/* remove button disabled class after spinner goes off */}
                <Link
                  to=""
                  className="btn btn-primary rounded-pill btn-xs px-6"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadModal"
                >
                  Yes, change
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <div
          className="modal fade"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">Add budget</h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                <form action="">
                  <div className="mb-3">
                    <label className="custom-label">File upload</label>
                    <p className="body-4 text-muted mb-1">
                      Upload a file of any xls or csv format containing
                      information about budget of your campaign.
                    </p>
                    <p className="body-4 text-muted mb-1">
                      (You can <Link to=""> download our template </Link> )
                    </p>

                    {/* before upload */}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="uploadedCard mb-0">
                          <div className="uploadedCard-icon">
                            <div className="iconInfo">
                              <span className="iconInfo-title text-muted">
                                csv{" "}
                                <span className="dot bg-neutral-n3 ms-1">
                                  {" "}
                                </span>{" "}
                                15 KB max
                              </span>
                            </div>
                          </div>
                          <div className="uploadedCard-info file-btn">
                            <button className="btn btn-info rounded-pill">
                              {" "}
                              Upload File
                              <input type="file" name="file" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* after upload */}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="uploadedCard uploadedCard-danger mb-0">
                          <div className="uploadedCard-icon">
                            <div className="iconBox">
                              <FileIcon />
                            </div>

                            <div className="iconInfo">
                              <span className="iconInfo-title">
                                {" "}
                                Voter List.pdf{" "}
                              </span>
                              <p className="iconInfo-desc">
                                {" "}
                                14 KB, 6/23/23 at 11:45 am{" "}
                              </p>
                              <div className="messageWrapper">
                                {/* if successful */}
                                {/* <span className="iconInfo-status">
                                {" "}
                                <CheckSuccess /> File uploaded{" "}
                              </span> */}

                                {/* if error */}
                                <span className="iconInfo-status">
                                  <div className="text-danger">
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 1</strong>: File is not in
                                      CSV format
                                    </span>

                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 2</strong>: File is not in
                                      CSV format
                                    </span>

                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 3</strong>: File is not in
                                      CSV format
                                    </span>

                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 4</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 5</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 6</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 7</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 8</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 9</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 10</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 11</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 12</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 13</strong>: File is not in
                                      CSV format
                                    </span>
                                    <span className="d-block">
                                      <InfoIconDanger />{" "}
                                      <strong> Error 14</strong>: File is not in
                                      CSV format
                                    </span>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="uploadedCard-info">
                            <div className="file-btn">
                              <button className="btn btn-link rounded-pill text-primary">
                                {" "}
                                Change File
                                <input type="file" name="file" />
                              </button>
                            </div>

                            <button className="btn btn-xs">
                              <span className="icon-trash"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer border-1">
                <Link
                  to=""
                  className="btn btn-link rounded-pill text-primary btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
                <Link
                  to=""
                  className="btn btn-primary rounded-pill btn-xs px-6"
                >
                  Done
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetDetailsTemplate;

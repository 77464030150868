import React, { useEffect, useState } from "react";

import UploadFile from "components/common/UploadFile";
import { DashboardChildProp, IResponseFileInfo } from "type/dashboard";
import { handleFileDelete } from "utils/uploadFile";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useFormPersist from "react-hook-form-persist";
import { ACTIVE_STEP } from "../Dashboard";
import { getLocalStorageItem } from "utils/localStorageHelper";
import CreateAuthAxiosInstance from "utils/authAxios";
import { CANDIDATE_ID } from "../../Homepage";
import { toast } from "react-toastify";
import InfoIconLight from "img/svg/InfoIconLight";

const voterSchema = yup.object().shape({
  voteGoal: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable()
    .typeError("Must be a number")
    .integer("Must be an integer")
    .min(1, "Must be a positive number")
    .max(1_000_000_000, "Must be under 10 digits"),

  estimatedVoters: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable()
    .typeError("Must be a number")
    .integer("Must be an integer")
    .min(0, "Must be a positive number")
    .max(1_000_000_000, "Must be under 10 digits"),

  hasRequestedVoterFile: yup.boolean(),
});

interface IVoterData {
  voteGoal: number;
  estimatedVoters: number;
  hasRequestedVoterFile: boolean;
}

export const ESTIMATED_COST_CONSTANT = 0.03;
const HAS_VOTER_LIST = "hasVoterList";
const VOTE_GOAL = "voteGoal";
const ESTIMATED_VOTERS = "estimatedVoters";
const IS_FIRST_EMAIL_SENT = "isFirstEmailSent";

const AddVoter = ({
  handleStepCompletion,
  fileInfo,
  setFileInfo,
  voteGoal,
  isProfileCreated,
  estimatedVoters,
}: DashboardChildProp) => {
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IVoterData>({
    resolver: yupResolver(voterSchema as any),
  });
  const activeStep: number = getLocalStorageItem(ACTIVE_STEP);
  const [isFileRead, setIsFileRead] = useState(false);

  const [hasVotersList, setHasVotersList] = useState(true);
  const [estimatedVotersState, setEstimatedVotersState] = useState(0);
  const isFirstEmailSent = localStorage.getItem(IS_FIRST_EMAIL_SENT);

  // const submitButtonRef = useRef<HTMLButtonElement>(null);

  useFormPersist("addVoterForm", {
    watch,
    setValue,
    storage: localStorage,
  });

  const authAxtios = CreateAuthAxiosInstance();
  const userId = localStorage.getItem(CANDIDATE_ID);

  useEffect(() => {
    const storedVoteGoal: string = String(getValues(VOTE_GOAL));

    if (voteGoal && storedVoteGoal === "") {
      setValue(VOTE_GOAL, voteGoal);
    }
  }, [voteGoal]);

  useEffect(() => {
    const estimatedVotersToShow = getValues(ESTIMATED_VOTERS)
      ? Number(getValues(ESTIMATED_VOTERS))
      : estimatedVoters !== undefined
      ? estimatedVoters
      : undefined;

    if (estimatedVotersToShow) {
      setEstimatedVotersState(estimatedVotersToShow);
      setValue("estimatedVoters", estimatedVotersToShow);
    }
  }, [estimatedVoters]);

  useEffect(() => {
    setHasVotersList(
      getLocalStorageItem(HAS_VOTER_LIST) === undefined
        ? true
        : getLocalStorageItem(HAS_VOTER_LIST)
    );
  }, []);

  const handleCreateVoterFileRequest = async (estimatedVoters?: number) => {
    try {
      // if (estimatedVoters)
      await authAxtios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/voters-requests`,
        {
          estimatedVoters: estimatedVoters ?? undefined,
          cost: estimatedVoters
            ? (estimatedVoters * ESTIMATED_COST_CONSTANT).toFixed(2)
            : undefined,
          candidateId: userId,
        }
      );
    } catch (error: any) {
      const errorMessage = error.response.data.message;
      if (errorMessage) {
        toast.error(errorMessage, {
          toastId: 1,
          className: "snackBar",
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          hideProgressBar: true,
          autoClose: 5000,
          icon: <InfoIconLight />,
        });
      }
    }
  };

  const handleUpdateVoteGoal = async (
    voteGoalNumber: number,
    hasRequestedVoterFile: boolean
  ) => {
    try {
      if (isProfileCreated) {
        await authAxtios.patch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/profile-setting/candidate-details`,
          {
            voteGoal: voteGoalNumber,
            isVoterFileRequested: hasRequestedVoterFile,
          }
        );
      } else {
        await authAxtios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/profile-setting/candidate-details`,
          {
            voteGoal: voteGoalNumber,
            isVoterFileRequested: hasRequestedVoterFile,
            userId: userId,
          }
        );
      }
    } catch (error: any) {
      const errorMessage = error.response.data.message;
      if (errorMessage) {
        toast.error(errorMessage, {
          toastId: 1,
          className: "snackBar",
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          hideProgressBar: true,
          autoClose: 5000,
          icon: <InfoIconLight />,
        });
      }
    }
  };

  useEffect(() => {
    const parsedIsEmailSent = isFirstEmailSent
      ? JSON.parse(isFirstEmailSent)
      : undefined;
    if (!hasVotersList && parsedIsEmailSent === false) {
      handleCreateVoterFileRequest();
      localStorage.setItem(IS_FIRST_EMAIL_SENT, JSON.stringify(true));
    }
  }, [hasVotersList, isFirstEmailSent]);

  useEffect(() => {
    const parsedIsEmailSent = isFirstEmailSent
      ? JSON.parse(isFirstEmailSent)
      : undefined;
    if (parsedIsEmailSent === undefined)
      localStorage.setItem(IS_FIRST_EMAIL_SENT, JSON.stringify(false));
  }, []);

  // useEffect(() => {
  //   const listener = (event: KeyboardEvent) => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       event.preventDefault();
  //       if (submitButtonRef?.current) {
  //         submitButtonRef.current.click();
  //       }
  //     }
  //   };

  //   document.addEventListener("keydown", listener);

  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);

  return (
    <div className="col-md-8">
      <div className="card card-accountSetup shadow-2">
        <div className="card-title">
          <h4>Voter</h4>
        </div>
        <form
          onSubmit={handleSubmit(async (data) => {
            const hasRequestedVoterFile = getValues("hasRequestedVoterFile");
            if (isFileRead)
              await handleUpdateVoteGoal(
                data.voteGoal,
                hasVotersList ? false : data.hasRequestedVoterFile
              );
            if (!hasVotersList && hasRequestedVoterFile)
              await handleCreateVoterFileRequest(data.estimatedVoters);
            if (isFileRead || !hasVotersList)
              handleStepCompletion(activeStep, "completed");
          })}
        >
          <div className="card-body voterCardHeight pt-0" style={{}}>
            <div className="mb-4">
              <label htmlFor="voteGoal" className="custom-label">
                Vote goal
              </label>
              <span className="body-4 text-muted d-block">
                Input your Vote Goal (also known as Win Number). This is used to
                craft your Daily Goals.
              </span>
              <input
                type="text"
                className="form-control mt-3"
                id="voteGoal"
                aria-describedby="voteGoal"
                placeholder={voteGoal ? String(voteGoal) : ""}
                {...register("voteGoal")}
              />
              {errors?.voteGoal?.message && (
                <span className="invalidInput form-text text-danger">
                  {errors.voteGoal.message as string}
                </span>
              )}{" "}
            </div>

            <div className="mb-4 required">
              <label className="custom-label h5 mb-3">
                {" "}
                Add voter information{" "}
              </label>

              <div className={`groupField ${!hasVotersList ? "disabled" : ""}`}>
                <label className="body-2 d-block form-label mb-0">
                  {" "}
                  File upload{" "}
                </label>
                <p className="body-4 text-muted mb-3">
                  Upload a file of supported formats containing information
                  about voters in your district
                </p>

                <UploadFile
                  setIsFileRead={setIsFileRead}
                  activeStep={activeStep}
                  fileInfo={fileInfo}
                  setFileInfo={
                    setFileInfo as React.Dispatch<
                      React.SetStateAction<IResponseFileInfo | null>
                    >
                  }
                />
              </div>
            </div>

            <div className="mb-2 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                // id="exampleCheck1"
                checked={!hasVotersList}
                // checked={!getLocalStorageItem(HAS_VOTER_LIST)}
                onChange={() => {
                  setHasVotersList(!hasVotersList);
                  localStorage.setItem(
                    HAS_VOTER_LIST,
                    JSON.stringify(!hasVotersList)
                  );
                }}
              />
              <label
                className="form-check-label body-4"
                htmlFor="exampleCheck1"
              >
                {" "}
                I do not have a voter file.
              </label>
            </div>

            {/* new form section */}
            {!hasVotersList && (
              <div className="split-form">
                <div className="mb-3">
                  <label htmlFor="estimatedVoters" className="body-5 mb-2">
                    Estimated number of voters in your district
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="estimatedVoters"
                    aria-describedby="emailHelp"
                    placeholder="Enter estimated number"
                    {...register("estimatedVoters", {
                      onChange: (e) => setEstimatedVotersState(e.target.value),
                    })}
                  />
                  {errors?.estimatedVoters?.message && (
                    <span className="invalidInput form-text text-danger">
                      {errors.estimatedVoters.message as string}
                    </span>
                  )}{" "}
                </div>

                <div className="mb-3">
                  <label htmlFor="estimatedVoters" className="body-5 mb-0">
                    Estimated cost to acquire voter file for your district
                  </label>
                  <label className="form-label mb-2 text-muted d-block">
                    Calculated based on estimated number of voters provided
                  </label>

                  <div className="input-group">
                    <span className="input-group-text bg-transparent"> $ </span>
                    <input
                      type="text"
                      className="form-control border-start-0 ps-0"
                      id="estimated cost"
                      aria-describedby="emailHelp"
                      placeholder="0"
                      value={Number(
                        isNaN(estimatedVotersState)
                          ? 0
                          : estimatedVotersState * ESTIMATED_COST_CONSTANT
                      ).toFixed(2)}
                      disabled
                    />
                  </div>
                </div>

                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    disabled={estimatedVotersState ? false : true}
                    id="requestedVoterFile"
                    {...register("hasRequestedVoterFile")}
                  />
                  <label
                    className="form-check-label body-4"
                    htmlFor="requestedVoterFile"
                  >
                    {" "}
                    At this estimated cost, I would like to request a voter
                    file.
                  </label>
                </div>

                <div className="mb-3">
                  <span className="body-5 mb-1 d-block"> Disclaimer</span>

                  <p className="text-italic fs-7">
                    "You will NOT be charged at the time of checking this box. A
                    team member from Campaign Brain will reach out with an
                    official estimate on the cost of voter file acquisition."
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="card-footer shadow-6 d-flex gap-4 justify-content-end">
            <button
              onClick={() => {
                handleStepCompletion(activeStep, "skipped");
                handleFileDelete(activeStep);
              }}
              // ref={submitButtonRef}
              className="btn btn-link text-primary btn-xs rounded-pill"
            >
              Not Now
            </button>
            <button
              type="submit"
              className={`btn btn-primary rounded-pill btn-xs px-6 ${
                isFileRead || !hasVotersList ? "" : "btn-disable"
              }`}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVoter;

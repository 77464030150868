import CheckSuccess from "img/svg/checkSuccess";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import InfoIconDanger from "img/svg/InfoIconDanger";
import { Controller, useForm } from "react-hook-form";
import usePersonalDetails from "./usePersonalDetails";
import { AccountDeleteModal } from "./AccountDeleteModal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { getDateWithDashSeperation } from "utils/getDateTime";
import { format, parseISO } from "date-fns";

const PersonalDetails = () => {
  const {
    userData,
    candidateData,
    onPersonalDetailsSubmit,
    onUpdateCandidateData,
    editable,
    setEditable,
    onResendEmail,
    isVerifyClicked,
  } = usePersonalDetails();
  const [electionDate, setElectionDate] = useState<any>(new Date());

  const personalDetailSchema = Yup.object().shape({
    primaryPhoneNumber: Yup.string()
      .matches(
        /^(\+?[0-9]+-?)+[0-9]+$/,
        "Phone should contain numbers and characters only"
      )
      .min(10, ({ min }) => `Phone should be at least ${min} digits`)
      .max(14, ({ max }) => `Phone should be at most ${max} digits`),
    electionDate: Yup.date().required("Please choose a date"),
  });
  const districtFieldSchema = Yup.object().shape({
    district: Yup.string()
      .matches(
        /^[A-Za-z]*$/,
        "District should contain only alphabetic characters"
      )
      .nullable(),
  });
  const campaignDetailSchema = Yup.object().shape({
    weeklyAvailability: Yup.number()
      .integer("Working hours must be an integer number")
      .min(1, "Working hours must be a positive number")
      .max(168, "Working hours must be less than 169")
      .typeError("Working hours must be a number"),
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    clearErrors,
    control,
  } = useForm({
    resolver: yupResolver(personalDetailSchema as any),
  });
  const {
    handleSubmit: handleCamptainDetails,
    register: registerCampainDetails,
    setValue: setCamapinDetails,
    formState: { errors: errorsCampainDetails },
    clearErrors: clearCandidateErrors,
  } = useForm({
    resolver: yupResolver(campaignDetailSchema as any),
  });

  const {
    handleSubmit: handleDistrictSubmit,
    register: registerDistrictField,
    setValue: setDistrictField,
    formState: { errors: errorsDistrict },
    clearErrors: clearDistrictErrors,
  } = useForm({
    resolver: yupResolver(districtFieldSchema as any),
  });
  const handleSubmitForm = (data: any) => {
    const { primaryPhoneNumber } = data;
    onPersonalDetailsSubmit({ primaryPhoneNumber });
  };

  const handleSubmitCampainDetails = (data: any) => {
    const { weeklyAvailability } = data;
    onUpdateCandidateData({ weeklyAvailability: weeklyAvailability });
  };

  const handleSubmitDistrictField = (data: any) => {
    const { district } = data;
    onUpdateCandidateData({ district });
  };

  const handleElectionDateSubmit = () => {
    if (electionDate) {
      onUpdateCandidateData({
        electionDate: format(electionDate, "yyyy-MM-dd"),
      });
    }
  };

  useEffect(() => {
    if (userData) {
      Object.entries(userData).forEach(([key, value]: any) => {
        setValue(key, value);
      });
    }
    if (candidateData) {
      Object.entries(candidateData).forEach(([key, value]: any) => {
        setCamapinDetails(key, value);
      });
      setElectionDate(
        candidateData.electionDate
          ? parseISO(candidateData.electionDate)
          : new Date()
      );
      setDistrictField("district", candidateData?.district);
    }
  }, [userData, candidateData, setValue, setCamapinDetails, setDistrictField]);

  return (
    <div className="row mt-5">
      <div className="col-md-8">
        <div className="settings">
          <div className="settings-header d-flex justify-content-between">
            <div className="">
              <h4> Basic Details</h4>
              <p className="body-4 text-muted">
                Update or edit your information
              </p>
            </div>

            {/* <div className="media media-lg">
                  <div className="media-header avatar border-0 position-relative">
                    <span className="media-header--text">
                      <i className="icon-user"></i>
                      <img
                        src={require("../../../img/avatar.png")}
                        className="img-fluid rounded-circle"
                        alt="User Avatar"
                      />
                    </span>

                    <div className="edit-avatar">
                      <label className="custom-file-upload">
                        <input type="file" />
                        <i className="icon-pencil"> </i>
                      </label>
                    </div>
                  </div>
                </div> */}
          </div>

          <div className="settings-body">
            <div className="personal-info">
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-4">
                  <span className="body-2 fs-7"> Name </span>
                </div>
                <div className="col-md-8">
                  <span className="body-4"> {candidateData?.fullName} </span>
                </div>
              </div>
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-4">
                  <span className="body-2 fs-7"> Email </span>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-8">
                      <span className="body-4 me-1">{userData?.email}</span>
                      {userData?.isEmailVerified ? <CheckSuccess /> : null}
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex justify-content-end">
                        {!userData?.isEmailVerified ? (
                          <>
                            {isVerifyClicked ? (
                              <button
                                className="btn btn-link btn-xs px-1 py-0"
                                onClick={onResendEmail}
                              >
                                Resend email
                              </button>
                            ) : (
                              <button
                                className="btn btn-link btn-xs px-1 py-0"
                                onClick={onResendEmail}
                              >
                                Verify email
                              </button>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-3 mb-3" />
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> Phone no. </span>
                  </div>
                  <div className="col-md-8">
                    {/* after save edit  */}

                    <div className="row">
                      <div className="col-md-8">
                        {editable === "phone" ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter phone no."
                              {...register("primaryPhoneNumber")}
                            />
                            {errors?.primaryPhoneNumber?.message && (
                              <div
                                id="emailHelp"
                                className="form-text text-danger fs-8"
                              >
                                <span className="me-2">
                                  <InfoIconDanger />
                                </span>
                                {errors?.primaryPhoneNumber?.message as string}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {userData?.primaryPhoneNumber ? (
                              <span className="body-4 text-muted">
                                {userData?.primaryPhoneNumber}
                              </span>
                            ) : (
                              <span className="body-4 text-muted">
                                Not Provided
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex justify-content-end">
                          {editable === "phone" ? (
                            <>
                              <Link
                                to="#"
                                className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                onClick={() => {
                                  setEditable(null);
                                  setDistrictField(
                                    "primaryPhoneNumber",
                                    userData?.primaryPhoneNumber
                                  );
                                  clearErrors();
                                }}
                              >
                                Cancel
                              </Link>
                              <button
                                className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                                type="submit"
                              >
                                Save
                              </button>
                            </>
                          ) : (
                            <>
                              {userData?.primaryPhoneNumber ? (
                                <Link
                                  to="#"
                                  className="btn btn-link btn-xs px-1 py-0"
                                  onClick={() => {
                                    setEditable("phone");
                                  }}
                                >
                                  Edit
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  className="btn btn-link btn-xs px-1 py-0"
                                  onClick={() => {
                                    setEditable("phone");
                                  }}
                                >
                                  Add
                                </Link>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-4">
                  <span className="body-2 fs-7"> State </span>
                </div>
                <div className="col-md-8">
                  <span className="body-4"> {candidateData?.state} </span>
                </div>
              </div>
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-4">
                  <span className="body-2 fs-7"> County </span>
                </div>
                <div className="col-md-8">
                  <span className="body-4"> {candidateData?.county} </span>
                </div>
              </div>
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-4">
                  <span className="body-2 fs-7"> City </span>
                </div>
                <div className="col-md-8">
                  <span className="body-4"> {candidateData?.city} </span>
                </div>
              </div>
              <hr className="mt-3 mb-3" />

              <form onSubmit={handleDistrictSubmit(handleSubmitDistrictField)}>
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> District </span>
                  </div>
                  <div className="col-md-8">
                    {/* after save edit  */}

                    <div className="row">
                      <div className="col-md-8">
                        {editable === "district" ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your district"
                              {...registerDistrictField("district")}
                            />
                            {errorsDistrict?.district?.message && (
                              <div
                                id="emailHelp"
                                className="form-text text-danger fs-8"
                              >
                                <span className="me-2">
                                  <InfoIconDanger />
                                </span>
                                {errorsDistrict?.district?.message as string}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {candidateData?.district ? (
                              <span className="body-4 text-muted">
                                {candidateData?.district}
                              </span>
                            ) : (
                              <span className="body-4 text-muted">
                                Not Provided
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex justify-content-end">
                          {editable === "district" ? (
                            <>
                              <Link
                                to="#"
                                className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                onClick={() => {
                                  setEditable(null);
                                  setDistrictField(
                                    "district",
                                    userData?.district
                                  );
                                  clearDistrictErrors();
                                }}
                              >
                                Cancel
                              </Link>
                              <button
                                className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                                type="submit"
                              >
                                Save
                              </button>
                            </>
                          ) : (
                            <>
                              {candidateData?.district ? (
                                <Link
                                  to="#"
                                  className="btn btn-link btn-xs px-1 py-0"
                                  onClick={() => {
                                    setEditable("district");
                                  }}
                                >
                                  Edit
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  className="btn btn-link btn-xs px-1 py-0"
                                  onClick={() => {
                                    setEditable("district");
                                  }}
                                >
                                  Add
                                </Link>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <hr className="mt-3 mb-3" />
            </div>

            <div className="campaign-details mt-5">
              <h4> Campaign Details </h4>
              <p className="body-4 text-muted">
                Update or edit your campaign Details
              </p>

              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-4">
                  <span className="body-2 fs-7"> Date of Election </span>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-8">
                      {editable === "electionDate" ? (
                        <div className="custom-datepicker">
                          <Controller
                            name="electionDate"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <ReactDatePicker
                                  // selected={value ? new Date(value) : null}
                                  onChange={(date: Date) => {
                                    onChange(date);
                                    setElectionDate(date);
                                  }}
                                  className="form-control"
                                  dateFormat="yyyy-MM-dd"
                                  showIcon
                                  filterDate={(date) => {
                                    return new Date() < date;
                                  }}
                                  selected={
                                    value ? new Date(value) : electionDate
                                  }
                                />
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <span className="body-4">
                          {getDateWithDashSeperation(electionDate)}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex justify-content-end">
                        {editable === "electionDate" ? (
                          <>
                            <button
                              className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                              onClick={() => {
                                setEditable(null);
                                setElectionDate(
                                  new Date(candidateData?.electionDate) ||
                                    new Date()
                                );
                                clearCandidateErrors();
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                              onClick={() => {
                                handleElectionDateSubmit();
                              }}
                            >
                              Save
                            </button>
                          </>
                        ) : (
                          <button
                            className="btn btn-link btn-xs px-1 py-0"
                            onClick={() => {
                              setEditable("electionDate");
                            }}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-3 mb-3" />
              <form
                onSubmit={handleCamptainDetails(handleSubmitCampainDetails)}
              >
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> Hours per week </span>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-8">
                        {editable === "hoursPerWeek" ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter hours per week"
                              {...registerCampainDetails("weeklyAvailability")}
                            />
                            {errorsCampainDetails?.weeklyAvailability
                              ?.message && (
                              <div
                                id="emailHelp"
                                className="form-text text-danger fs-8"
                              >
                                <span className="me-2">
                                  <InfoIconDanger />
                                </span>
                                {
                                  errorsCampainDetails?.weeklyAvailability
                                    ?.message as string
                                }
                              </div>
                            )}
                          </>
                        ) : (
                          <span className="body-4">
                            {candidateData?.weeklyAvailability
                              ? candidateData?.weeklyAvailability + " Hours"
                              : ""}
                          </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex justify-content-end">
                          {editable === "hoursPerWeek" ? (
                            <>
                              <Link
                                to="#"
                                className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                onClick={() => {
                                  setEditable(null);
                                  setCamapinDetails(
                                    "weeklyAvailability",
                                    candidateData?.weeklyAvailability
                                  );
                                  clearCandidateErrors();
                                }}
                              >
                                Cancel
                              </Link>
                              <button
                                className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                                type="submit"
                              >
                                Save
                              </button>
                            </>
                          ) : (
                            <Link
                              to="#"
                              className="btn btn-link btn-xs px-1 py-0"
                              onClick={() => {
                                setEditable("hoursPerWeek");
                              }}
                            >
                              Edit
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <DevTool control={control} /> */}
                </div>
              </form>
              <hr className="mt-3 mb-3" />
            </div>

            <div className="deactivate-account mt-5">
              <h4> Delete account</h4>
              <p className="body-4 text-muted">
                Once you delete your account, there is no going back. Please be
                certain.
              </p>

              <div className="mt-3 d-flex gap-3">
                <button
                  className="btn btn-reject rounded-pill btn-sm fw-bold"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                >
                  {" "}
                  Delete account{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <AccountDeleteModal />
      </div>
    </div>
  );
};

export default PersonalDetails;

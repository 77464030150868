import SideNavbar from "../../components/common/navbar/SideNavbar";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import HelpIcon from "img/svg/helpIcon";
import PhoneOutlineIcon from "img/svg/phoneOutlineIcon";
import React, { useState } from "react";
import Breadcrumbs from "templates/components/Breadcrumbs";
import { Link } from "react-router-dom";
import { Tooltip } from "bootstrap";
import { useEffect } from "react";
import EyeIcon from "img/svg/eyeIcon";
import WarningIcon from "img/svg/warningIcon";
import SuccessIcon from "img/svg/successIcon";
import LeaveIcon from "img/svg/leaveIcon";
import PhoneFilledIcon from "img/svg/PhoneFilledIcon";
import CallEnded from "templates/components/call/CallEnded";

const ContactDonorTemplating = () => {
  const [isScriptActive, setIsScriptActive] = useState(true);
  let buttonText = isScriptActive ? "Hide Script" : "Show Script";

  const handleScript = () => {
    setIsScriptActive(!isScriptActive);
  };

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  //tooltip
  const donorStatusTooltip = `Not Home: the donor did not pick up the phone, or you reached someone besides the target donor. We will try again with this donor soon. <br /> <br /> Not Interested: the donor is not interested in financially supporting your campaign <br /> <br />Donating Later: the donor is interested in supporting financially, but does not provide credit card information in real time over the phone.`;
  const contributionTooltip = `Insert the amount of money the donor has pledged to support your campaign. After inputting the amount directly into your external fundraising page, input the same amount here to allow Campaign Brain to accurately track donation history.`;
  const donorEmailTooltip = `If the candidate prefers to not share their credit card information over the phone and instead wants to input their information themselves, input the donor’s email to automatically send the website for the donor to make a donation.`;

  const historyTooltip = `Potential of donating later also and contact again. Suggested few other potential donors`;

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />
      <div className="wrapper active-cont">
        <main>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Breadcrumbs />
              </div>
            </div>

            {/* Daily goal updates */}
            <div className="row justify-content-between mt-3">
              <div className="col-md-5">
                <div className="alert">
                  <div className="d-flex gap-4 voterGoal">
                    <div className="voter-goal-item">
                      <span className="ms-2">
                        {" "}
                        <span className="dot bg-success-s2"> </span>
                        <span className="fw-bold"> 1 of 10 </span>
                        Daily voter goal{" "}
                      </span>
                    </div>
                    <div className="voter-goal-item">
                      <span className="ms-2">
                        {" "}
                        <span className="dot bg-extended-e2"> </span>
                        <span className="fw-bold">1 of 186 </span>
                        Weekly voter goal{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="d-flex justify-content-end gap-2">
                  <div className="callBox">
                    {/* <OnCall/> */}
                    <CallEnded />
                  </div>

                  <button className="btn btn-success btn-xs px-4 rounded-pill text-white">
                    <PhoneFilledIcon /> Call
                  </button>
                  <button
                    className="btn btn-info btn-xs rounded-pill"
                    data-bs-toggle="modal"
                    data-bs-target="#skipModal"
                  >
                    Skip voter
                  </button>
                  <button
                    className="btn btn-primary rounded-pill btn-xs"
                    data-bs-toggle="modal"
                    data-bs-target="#successModal"
                  >
                    Save and call next
                  </button>
                </div>
              </div>
            </div>

            {/* Scripts and Response */}
            <div className="row">
              <div className="col-md-3">
                <div className="card card-fullHeight mt-3 shadow-2">
                  <div className="card-body scroll-y">
                    <div className="basic-info">
                      <div className="avatar m-auto border-0">
                        <span className="name fw-bold">JJ</span>
                      </div>

                      <p className="mb-0 mt-2 fw-bold text-center">
                        {" "}
                        Jacob Jones
                      </p>
                      <p className="body-4 fw-bold text-center text-muted">
                        {" "}
                        Male{" "}
                      </p>
                      <p className="mb-4 fw-bold text-center">
                        {" "}
                        <PhoneOutlineIcon />{" "}
                        <span className="ms-2"> (603) 555-0123 </span>
                      </p>
                    </div>

                    <hr className="mb-4"></hr>

                    {/* voter Details */}

                    <div className="voter-details">
                      <h4 className="body-5"> Donor Details </h4>

                      <div className="row">
                        <div className="col-4 text-muted d-flex body-4">
                          Email
                        </div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4">jacob.jones@gmail.com</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 text-muted d-flex body-4">
                          Age
                        </div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4"> - </span>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-4 text-muted d-flex body-4">
                          Party Affiliation
                        </div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4"> Democrats </span>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-4 text-muted d-flex body-4">
                          Source
                        </div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4"> Personal Network </span>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-4 text-muted d-flex body-4">
                          Donor Type
                        </div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4"> Donor </span>
                        </div>
                      </div>
                    </div>

                    <h4 className="body-5"> Contact History </h4>

                    <div className="contact-history">
                      <div className="timeline timeline-sm">
                        <div className="timeline-item pb-4">
                          <div className="timeline-step">
                            <span> </span>
                          </div>
                          <div className="timeline-content">
                            <h4 className="mb-1 body-4">
                              {" "}
                              Donated
                              <span className="text-success body-5">
                                {" "}
                                $ 30.99
                              </span>{" "}
                            </h4>
                            <p className="text-muted fs-8 mb-0">
                              Called on Aug{" "}
                              <span className="dot bg-neutral-n3 ms-1"> </span>{" "}
                              3:30pm
                            </p>

                            <p
                              className="text-muted fs-8 mb-0 text-truncate-1 text-left"
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title={historyTooltip}
                            >
                              {" "}
                              Potential of donating later also and contact again{" "}
                            </p>
                          </div>
                        </div>

                        <div className="timeline-item pb-4">
                          <div className="timeline-step">
                            <span></span>
                          </div>
                          <div className="timeline-content">
                            <h4 className="mb-1 body-4">
                              {" "}
                              Marked as{" "}
                              <span className="text-success body-5">
                                {" "}
                                Not Home{" "}
                              </span>{" "}
                            </h4>
                            <p className="text-muted fs-8 mb-0">
                              Called on Aug{" "}
                              <span className="dot bg-neutral-n3 ms-1"> </span>{" "}
                              3:30pm
                            </p>

                            <p className="text-muted fs-8 mb-0 text-truncate-1">
                              {" "}
                              Interested but wanted to donate later and and sent
                              donation link{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex gap-2 flex-row align-items-center">
                      <InfoIconSecondary />
                      <p className="m-0 text-muted fs-7">
                        {" "}
                        No tasks available for today{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="contactAction flex-row card card-flush card-fullHeight mt-3 shadow-2">
                  {/* script */}
                  <div
                    className={
                      isScriptActive
                        ? "contactAction-cardLeft flex-1 scroll-y"
                        : "contactAction-cardLeft flex-0"
                    }
                  >
                    <div className="card-header">
                      <h4 className="card-title text-primary"> Script</h4>
                    </div>

                    <div className="card-body">
                      <div className="card scroll-y script-wrapper">
                        <p>
                          Hello, this is City Council Elenore Parker. I hope
                          you’re having a great day!{" "}
                        </p>
                        <p>
                          I wanted to take a moment to talk to you about the
                          upcoming city council elections. As a candidate, I am
                          deeply committed to serving our community and
                          addressing the important issues that matter to all of
                          us.
                        </p>
                        <p>
                          {" "}
                          You’ve been such an incredible supporter of my
                          campaign, thank you! We’re building out host committee
                          for a fundraiser on April 5th, and we’ve extended the
                          deadline to join to this Friday. Can you join the host
                          committee at the $100 contribution level?{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={isScriptActive ? "card-divider" : ""}></div>

                  {/* voter response */}
                  <div className="contactAction-cardRight flex-1">
                    <div className="card-header">
                      <h4 className="card-title"> Donor Response</h4>
                      <button
                        className="btn btn-link btn-sm p-1 ps-0 d-flex align-items-center gap-1"
                        onClick={handleScript}
                      >
                        {" "}
                        <EyeIcon /> {buttonText}
                      </button>
                    </div>
                    <div className="card-body">
                      <p className="body-4 text-muted">
                        Select either donor status, contribution or Donor email.
                      </p>

                      <form action="">
                        <div className="voter-status mb-3">
                          <div className="d-flex justify-content-between">
                            <label className="body-5"> Donor Status </label>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={donorStatusTooltip}
                              >
                                {" "}
                                <HelpIcon />
                              </Link>
                            </div>
                          </div>

                          {/* Donor status */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="notHome"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="notHome"
                                >
                                  Not Home
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="moved"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="moved"
                                >
                                  Not Interested
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="deceased"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="deceased"
                                >
                                  Donating Later
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="level-of-support pb-3">
                          <div className="d-flex justify-content-between">
                            <label className="body-5 mb-1">
                              {" "}
                              Contribution{" "}
                            </label>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={contributionTooltip}
                              >
                                {" "}
                                <HelpIcon />
                              </Link>
                            </div>
                          </div>
                          <p className="body-4 text-muted">
                            Add contribution amount in link or add on donation
                            page
                          </p>

                          <div className="">
                            <Link
                              to=""
                              target="_blank"
                              className="btn btn-link px-0 py-2 no-hover btn-xs"
                            >
                              Add via contribution link
                            </Link>

                            <div className="input-group">
                              <span className="input-group-text bg-transparent">
                                {" "}
                                ${" "}
                              </span>
                              <input
                                type="text"
                                className="form-control border-start-0 ps-0"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Insert contribution amount"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="level-of-support py-3">
                          <div className="d-flex justify-content-between">
                            <label className="body-5 mb-1"> Donor email </label>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={donorEmailTooltip}
                              >
                                {" "}
                                <HelpIcon />
                              </Link>
                            </div>
                          </div>

                          <p className="body-4 text-muted">
                            Add the email of donor to send the contribution link
                          </p>

                          <div className="">
                            <Link
                              to=""
                              className="btn btn-link px-0 py-2 no-hover btn-xs"
                              data-bs-toggle="modal"
                              data-bs-target="#sampleEmailModal"
                            >
                              View sample email
                            </Link>

                            <div className="d-flex align-items-center gap-1">
                              <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Add donor email"
                              />

                              <button className="btn btn-info rounded-pill btn-xs">
                                {" "}
                                Send{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="level-of-support py-3">
                          <label className="body-5"> Notes </label>

                          <div className="position-relative">
                            <textarea
                              className="form-control"
                              id="Textarea1"
                              rows={3}
                              maxLength={1000}
                            ></textarea>
                            <span className="word-count"> 0 / 200 </span>
                          </div>
                        </div>
                      </form>
                    </div>

                    {/* buttons */}
                    {/* <div className="card-footer shadow-6 d-flex justify-content-between">
                      <button
                        className="btn btn-link btn-sm p-1 ps-0"
                        data-bs-toggle="modal"
                        data-bs-target="#skipModal"
                      >
                        Skip donor
                      </button>
                      <button
                        className="btn btn-primary rounded-pill btn-xs"
                        data-bs-toggle="modal"
                        data-bs-target="#successModal"
                      >
                        Save and call next
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* All attempted */}

            {/* <div className="row">
              <div className="card card-flush card-fullHeight mt-3 shadow-2">
                <div className="noData d-flex gap-2 align-items-center">
                    <SuccessIcon />
                    <h1 className="mt-6">All voters contacted </h1>
                    <p> <span className="fw-bold"> 10/10 </span> voters attempted today. Continue to complete other tasks</p>
                    <Link to="/dashboard" className="btn btn-primary rounded-pill px-5"> Continue to Homepage</Link>
                </div>
              </div>
            </div> */}
          </div>
        </main>
      </div>

      {/* Skip Modal */}
      <div
        className="modal fade"
        id="skipModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <WarningIcon />
              <h2 className="mt-3"> Do you want to skip ‘Jacob Jones'? </h2>
              <p>
                By skipping, all details entered for this donor will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, I Skip
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when user go to next page without confirming Modal */}
      <div
        className="modal fade"
        id="leaveModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Do you want to leave this page </h2>
              <p>
                By leaving, all details entered for this donor will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, Leave
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when save and call next voter after last step */}
      <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../img/success-modal.png")}
                width={56}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Great Work! </h2>
              <p>
                <span className="fw-bold">9/10 </span> donors contacted today.
                Continue to complete other tasks
              </p>
            </div>

            <div className="modal-footer justify-content-center">
              <Link to="/" className="btn btn-primary rounded-pill">
                Continue to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* email sample modal */}

      <div
        className="modal fade"
        id="sampleEmailModal"
        tabIndex={-1}
        aria-labelledby="sampleEmailModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-1">
              <h5 className="modal-title body-2 mb-0" id="sampleEmailModalLabel">
                Sample email
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"> </span>
              </button>
            </div>
            <div className="modal-body">
              <p className="body-3 text-muted mb-4">
                View the sample email to send the donors who have donated for
                your campaign. You can edit the sample email in the fundraise
                tab of the settings.
              </p>

              <div className="textEditor rounded-3 border border-1 p-3">
                <p className=" fs-7">
                  {" "}
                  Hi <span className="text-bg"> Jacob Jones</span>
                </p>
                <p className="fs-7">
                  Glad we got at chance to connect today. I really appreciate
                  you being a supporter of our campaign. We’re working hard to
                  improve our community, and your support helps us to do that.
                </p>
                <p className="fs-7">
                  {" "}
                  To make a donation, please use our campaign’s secure donation
                  link.
                  <span className="text-bg">
                    {" "}
                    <Link to="" className="text-n1"> https://secure.actblue.com.donate/elenor</Link>
                  </span>
                </p>
                <p className="fs-7 mb-1"> Thanks again</p>
                <p className="fs-7">
                  {" "}
                  <span className="text-bg"> Elenor Parker </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDonorTemplating;

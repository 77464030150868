import InfoIconSecondarySmall from "img/svg/InfoIconSecondarySmall";
import HelpIcon from "img/svg/helpIcon";
import React from "react";
import { Link } from "react-router-dom";
import {
  additionalfundraiseSurplusTooltip,
  fundaiseSurplusTooltip,
} from "../Tooltip";
import {
  IGetSuccessRoadMapRecommnedation,
  ISuccessRoadMap,
} from "../interface";
import {
  FirstWeekRecommendation,
  FirstWeekSuccessRoadMap,
  GetSurPlusOrDeficit,
  RecommendationLoadingSkeleton,
} from "./";
import CheckSuccess from "img/svg/checkSuccess";
import InfoIconDangerX16 from "img/svg/InfoIconDangerX16";

const FundraisingSuccessRoadMap: React.FC<ISuccessRoadMap> = ({
  value,
  isOnTrack,
  hoursNeededToRemoveDeficit,
  isLoading,
}) => {
  return (
    <div className="row mt-6">
      <div className="col-md-12">
        <div className="card card-flush h-100 shadow-2">
          <div className="card-header d-flex justify-content-between">
            <h4 className="card-title"> Fundraising Success Roadmap </h4>
          </div>

          <div className="card-body pt-2 d-flex flex-column justify-content-end">
            <div className="row">
              <div className="col-md-6">
                <div className="analyticsGroup">
                  <div className="analyticsGroup-item">
                    <div className="d-flex gap-2 align-items-center mb-2">
                      <h6 className="mb-0"> Fundraising Deficit / Surplus </h6>
                      <div className="card-info">
                        <Link
                          to=""
                          data-bs-html="true"
                          data-bs-custom-class="custom-tooltip"
                          className="text-left"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          data-bs-title={fundaiseSurplusTooltip}
                        >
                          {" "}
                          <HelpIcon />{" "}
                        </Link>
                      </div>
                    </div>

                    <div className="surplus-label">
                      {value === null ? (
                        <FirstWeekSuccessRoadMap />
                      ) : (
                        <GetSurPlusOrDeficit
                          value={value}
                          isOnTrack={isOnTrack}
                          hasAmount={true}
                          isLoading={isLoading}
                        />
                      )}
                    </div>
                  </div>

                  <div className="analyticsGroup-item">
                    <div className="d-flex gap-2 align-items-center mb-2">
                      <h6 className="mb-0">
                        {" "}
                        Additional Hours Needed to Remove Deficit{" "}
                      </h6>
                      <div className="card-info">
                        <Link
                          to=""
                          data-bs-html="true"
                          data-bs-custom-class="custom-tooltip"
                          className="text-left"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          data-bs-title={additionalfundraiseSurplusTooltip}
                        >
                          {" "}
                          <HelpIcon />{" "}
                        </Link>
                      </div>
                    </div>

                    <div className="surplus-label">
                      {isLoading ? (
                        <h1>
                          <div className="placeholder-glow w-100 text-muted">
                            <div className="placeholder col-2"></div>
                          </div>
                        </h1>
                      ) : (
                        <h1 className="">
                          {hoursNeededToRemoveDeficit
                            ? `${hoursNeededToRemoveDeficit} Hours`
                            : 0}
                        </h1>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card flex-column gap-3 card-dailyUpdate p-3 border-1">
                  {value === null ? (
                    <FirstWeekRecommendation />
                  ) : (
                    <GetFundraisingRecommendation
                      isOnTrack={isOnTrack}
                      hoursNeededToRemoveDeficit={hoursNeededToRemoveDeficit}
                      isLoading={isLoading}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundraisingSuccessRoadMap;

const GetFundraisingRecommendation: React.FC<
  IGetSuccessRoadMapRecommnedation
> = ({ isOnTrack, hoursNeededToRemoveDeficit, isLoading }) => {
  if (isLoading) {
    return <RecommendationLoadingSkeleton />;
  }
  return (
    <>
      {isOnTrack ? (
        <>
          <div className="d-flex gap-2 flex-row align-items-center">
            <CheckSuccess />
            <p className="m-0 text-success fs-8 fw-bold"> On Track </p>
          </div>

          <div className="card-dailyUpdate-content fundraise-card-dailyUpdate-content">
            <h5 className="body-5"> Recommendation </h5>
            <p>
              You are on track to reach your fundraising goal! Keep completing
              the daily tasks to stay on track.
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex gap-2 flex-row align-items-center">
            <InfoIconDangerX16 />
            <p className="m-0 text-danger fs-8 fw-bold"> Not on Track </p>
          </div>

          <div className="card-dailyUpdate-content fundraise-card-dailyUpdate-content">
            <h5 className="body-5"> Recommendation </h5>
            <p>
              In addition to completing the Daily Goals, spend an additional{" "}
              <span className="fw-bold">
                {hoursNeededToRemoveDeficit
                  ? `${hoursNeededToRemoveDeficit}`
                  : '0'} hours
              </span>{" "}
              to remove the fundraising deficit by taking the following actions:
            </p>
            <ul className="list-unstyled">
              <li>
                <span>
                  Candidate spends additional time calling potential donors
                </span>
              </li>
              <li>
                <span>Host a fundraising event</span>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

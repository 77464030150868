import { Link, useLocation } from "react-router-dom";
import LoginButton from "../../buttons/LoginButton";
import LogoutButton from "../../buttons/LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";
import Logo from "img/svg/logo";
import NavbarTitle from "../NavbarTitle";
import { useState } from "react";
import { ONBOARDING_STATUS } from "components/pages/Homepage";
const TopNavbar: React.FC = () => {
  const { isLoading, error, isAuthenticated, user } = useAuth0();

  const [isMobileActive, isSetMobileActive] = useState(false);

  const isOnboardingCompleted = localStorage.getItem(ONBOARDING_STATUS);

  // when click on hamburger open sidenav
  const handleHamburger = () => {
    isSetMobileActive(!isMobileActive);
    document.body.classList.toggle("mobile-sidenav");
  };

  // when click on overlay remove close sidenav and remove overlay
  const handleOverlay = () => {
    document.body.classList.remove("mobile-sidenav");
    isSetMobileActive(false);
  };

  const location = useLocation();
  const { pathname } = location;
  let title = "";
  if (pathname.split("/").length > 3) {
    title = pathname.replace("/", " ");
  } else {
    title = pathname.replaceAll("/", " ");
  }
  const isPublicRoute =
    pathname.includes("email-verification") ||
    pathname.includes("account-deleted");

  return (
    <>
      <nav className="navbar navbar-expand top-navbar fixed-top bg-white">
        <div className="container-fluid px-0">
          <button
            className="navbar-toggler border-0 shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleHamburger}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link className="navbar-brand" to="/">
            <Logo />
          </Link>
          {isPublicRoute ? null : (
            <div className="navbar-collapse collapse">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-none d-lg-block">
                <li>
                  <NavbarTitle title={title} />
                </li>
              </ul>
              <ul className="navbar-nav navbar-align ms-auto">
                {error && <p>Authentication Error</p>}
                {!error && isLoading && (
                  <ul className="navbar-nav navbar-align ms-auto">
                    {" "}
                    <div className="me-3">
                      <div className="placeholder-glow">
                        <span className="placeholder placeholder-circle"></span>
                      </div>
                    </div>
                    <div className="avatarPlaceholder placeholder-glow">
                      <span className="placeholder placeholder-circle col-3"></span>
                      <span className="placeholder col-7"></span>
                    </div>
                  </ul>
                )}
                {!error &&
                  !isLoading &&
                  (isAuthenticated ? (
                    <>
                      <li className="nav-item me-3">
                        <Link className="nav-link icon-link" to="/">
                          {" "}
                          <span className="icon-notification h1"></span>
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                          to="/"
                          data-bs-toggle="dropdown"
                        >
                          <img
                            src={
                              user?.picture ??
                              require("../../../img/avatar.png")
                            }
                            style={{ width: "32px", height: "32px" }}
                            className="avatar img-fluid rounded-circle me-2 mb-0"
                            alt="User Avatar"
                          />
                        </Link>

                        <Link
                          className="nav-link d-none d-sm-inline-block"
                          to="/#"
                          data-bs-toggle="dropdown"
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                user?.picture ??
                                require("../../../img/avatar.png")
                              }
                              style={{ width: "32px", height: "32px" }}
                              className="avatar img-fluid rounded-circle me-2 mb-0"
                              alt="User Avatar"
                            />

                            <div>
                              <span className="text-dark userName d-block">
                                {user?.name}
                              </span>
                              {/* <span className="text-dark userRole"> Admin </span> */}
                            </div>
                          </div>
                        </Link>
                        <div className="dropdown-menu dropdown-topnav dropdown-menu-lg dropdown-menu-end">
                          <div className="dropdown-user-info">
                            <div className="media media- flex-column align-items-center">
                              <div className="media-header avatar border-0 position-relative">
                                <span className="media-header--text">
                                  <img
                                    src={
                                      user?.picture ??
                                      require("../../../img/avatar.png")
                                    }
                                    className="img-fluid rounded-circle"
                                    alt="User Avatar"
                                  />
                                </span>
                              </div>
                              <div className="media-body text-center">
                                <h4 className="mb-0"> {user?.name} </h4>
                                {/* <span className="body-4 text-muted"> City Counselor </span> */}
                              </div>
                            </div>

                            <Link
                              className="btn btn-info rounded-pill mt-4"
                              to={
                                isOnboardingCompleted &&
                                JSON.parse(isOnboardingCompleted)
                                  ? "/profile"
                                  : "/onboarding"
                              }
                            >
                              View or edit profile
                            </Link>
                          </div>
                          <div className="dropdown-divider"></div>

                          <div className="dropdown-item">
                            <LogoutButton />
                          </div>
                        </div>
                      </li>
                    </>
                  ) : (
                    <LoginButton />
                  ))}
              </ul>
            </div>
          )}
        </div>
      </nav>

      <div
        className={isMobileActive ? "overlay show" : "overlay"}
        onClick={handleOverlay}
      ></div>
    </>
  );
};

export default TopNavbar;

import React, { useEffect, useState } from "react";
import PolygonIcon from "img/svg/PolygonIcon";
import CustomTooltip from "components/common/CustomTooltip";
import MapControl from "./MapControl";

interface CustomPolygonControlProps {
  onPolygonDraw: (isActive: boolean) => void;
  map: google.maps.Map | null;
}

const CustomPolygonControl: React.FC<CustomPolygonControlProps> = ({
  onPolygonDraw,
  map,
}) => {
  useEffect(() => {
    if (!map) return;
  }, [map]);

  const [isClicked, setIsClicked] = useState(false);

  return (
    <MapControl position="RIGHT_CENTER">
     
      <div className="drawPolygon shadow-6">
      <CustomTooltip content={ !isClicked? 'Create Turf': ''}>
        <button
          className={`btn border-0 py-1 px-2  ${isClicked ? 'bg-info': ''}`}
          onClick={() => {
            onPolygonDraw(!isClicked);
            setIsClicked(!isClicked);
          }}
        >
          <PolygonIcon />
        </button>
        
        </CustomTooltip>
      </div>{" "}
    </MapControl>
  );
};

export default CustomPolygonControl;

const FbIcon = ()=> {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_199_3863)">
            <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#3B5998"/>
            <path d="M15.0168 12.4697H12.8755V20.3143H9.63132V12.4697H8.08838V9.71284H9.63132V7.92882C9.63132 6.65305 10.2373 4.65533 12.9044 4.65533L15.3075 4.66539V7.34142H13.5639C13.2779 7.34142 12.8757 7.48431 12.8757 8.09289V9.71541H15.3002L15.0168 12.4697Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_199_3863">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default FbIcon;

import useAutosizeTextArea from "components/hooks/useAutosizeTextArea";
import AiIcon from "img/svg/AiIcon";
import MessageSendIcon from "img/svg/MessageSendIcon";
import React from "react";
import { useRef, useState } from "react";

const AiChatBox = () => {
  const [value, setValue] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, value);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;

    setValue(val);
  };

  return (
    // disabled class added when we go to history list and remove disabled when we click close icon
    <div className="aiChatBox disable">
      <div className="ai-icon">
        <AiIcon />
      </div>

      <textarea
        rows={1}
        value={value}
        ref={textAreaRef}
        id="review-text"
        onChange={handleChange}
        className="form-control"
        placeholder="Ask AI to write anything for your campaign..."
      ></textarea>
      <button className="btn btn-link no-hover px-0 py-0 send-btn">
        <MessageSendIcon />
      </button>
    </div>
  );
};

export default AiChatBox;

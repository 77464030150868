import React, { useRef } from 'react'
import { ISelectedDonor } from './DonorTableDataV2';
import CreateAuthAxiosInstance from 'utils/authAxios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toastNotify from 'utils/toastNotify';
import Spinner from 'components/common/Spinner';

interface IDeleteDonorModalProps {
  selectedDonor: ISelectedDonor | null
  setSelectedDonor: React.Dispatch<React.SetStateAction<ISelectedDonor | null>>
}

const DeleteDonorModalV2 = ({
  selectedDonor,
  setSelectedDonor
}: IDeleteDonorModalProps) => {
  const authAxios = CreateAuthAxiosInstance()
  const queryClient = useQueryClient()

  const closeRef = useRef<HTMLButtonElement | null>(null);

  const {
    mutate: deleteDonorMutation,
    status: donorDeleteStatus
  } = useMutation({
    mutationFn: () => authAxios.delete(`api/v1/donor/${selectedDonor?.id}`),
    onSuccess: handleOnSuccessMutation,
    onError: () => {
      toastNotify('error', 'Error! Could not delete the donor info!')
    },
  })

  function handleOnSuccessMutation() {
    toastNotify('success', 'Volunteer Updated Successfully !')
    queryClient.invalidateQueries({ queryKey: ['donorList'] })
    setSelectedDonor(null)

    if (closeRef?.current) closeRef?.current?.click()
  }

  const handleDeleteDonor = () => {
    if (selectedDonor?.id) deleteDonorMutation()
  }

  const resetDeleteTarget = () => setSelectedDonor(null)

  return (
    <div
      className="modal fade"
      id='deleteDonorModalV2'
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close p-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={closeRef}
              onClick={resetDeleteTarget}
            >
              <span className="icon-close text-n1 h2"></span>
            </button>
          </div>
          <div className="modal-body text-center px-4">
            <img
              src={require("../../../../img/leave.png")}
              width={66}
              height={56}
              alt="leave alert"
            />
            <h2 className="mt-3">Do you want to delete the donor? </h2>
            <p>
              By deleting, details and history of the donor will be erased.
            </p>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-link btn-sm p-1 ps-0"
              data-bs-dismiss="modal"
              onClick={resetDeleteTarget}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary rounded-pill btn-xs"
              onClick={handleDeleteDonor}
              disabled={donorDeleteStatus === 'pending'}
            >
              {donorDeleteStatus === 'pending' && <Spinner />}
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteDonorModalV2
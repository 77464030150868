import { yupResolver } from "@hookform/resolvers/yup";
import InfoIconDanger from "img/svg/InfoIconDanger";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useTopPrioritiesData } from "./useTopPrioritiesData";
import { getDateWithDashSeperation } from "utils/getDateTime";
import Spinner from "components/common/Spinner";
import { useEffect } from "react";

const candidateAddedTaskFormSchema = yup.object().shape({
  priorityName: yup
    .string()
    .trim()
    .required("Please enter your priority")
    .test(
      "not-only-numbers",
      "Priority name cannot contain only numbers",
      (value) => {
        // Check if the string contains only numbers
        return !/^\d+$/.test(value);
      }
    ),

  dueDate: yup.date().required("Please choose a date"),
});
const CandidateAddedTaskForm = () => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(candidateAddedTaskFormSchema),
  });

  const {
    postSystemAndManualTask,
    setIsEditable,
    isSystemAndManualTaskPosting,
    candidateTaskName,
    setCandidateTaskName,
    candidateTaskDueDate,
    setCandidateTaskDueDate,
    updateSystemAndManualTask,
    isSystemAndManualTaskUpdating,
    editedTaskId,
  } = useTopPrioritiesData();

  const handleResetState = () => {
    setCandidateTaskName("");
    setCandidateTaskDueDate("");
    reset();
  };

  useEffect(() => {
    if (candidateTaskName) setValue("priorityName", candidateTaskName);
    if (candidateTaskDueDate)
      setValue("dueDate", new Date(candidateTaskDueDate));
  }, [candidateTaskName, candidateTaskDueDate]);

  return (
    <>
      <form
        onSubmit={handleSubmit(async (data) => {
          if (editedTaskId) {
            updateSystemAndManualTask({
              id: String(editedTaskId),
              name: data.priorityName,
              dueDate: getDateWithDashSeperation(data.dueDate),
            });
          } else {
            postSystemAndManualTask({
              name: data.priorityName,
              dueDate: getDateWithDashSeperation(data.dueDate),
            });
          }

          reset();
        })}
      >
        <div className="groupForm">
          <div className="groupForm-header row">
            <div className="col-md-8 required">
              <label htmlFor="inputPriority" className="form-label">
                Priority name
              </label>
              <input
                className="form-control"
                id="inputPriority"
                aria-describedby="emailHelp"
                placeholder="Enter priority name"
                {...register("priorityName")}
              />
              {errors?.priorityName?.message && (
                <div className="invalidInput form-text text-danger">
                  <InfoIconDanger /> {errors?.priorityName?.message}
                </div>
              )}
            </div>
            <div className="col-md-4 required">
              <label htmlFor="date" className="form-label">
                Due date
              </label>
              <div className="custom-datepicker">
                <Controller
                  name="dueDate"
                  control={control}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        className="form-control"
                        onChange={(date) =>
                          field.onChange(
                            date?.toLocaleDateString("en-us", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          )
                        }
                        showIcon
                        dateFormat="MMMM d, yyyy"
                        filterDate={(date) => {
                          // Disable past dates
                          return (
                            date >= new Date(new Date().setHours(0, 0, 0, 0))
                          );
                        }}
                        selected={field.value ? new Date(field.value) : null}
                      />
                    );
                  }}
                />
                {errors?.dueDate?.message && (
                  <div className="invalidInput form-text text-danger">
                    <InfoIconDanger /> {errors?.dueDate?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="groupForm-body d-flex justify-content-end gap-3">
            <button
              className="btn btn-link rounded-pill btn-xs"
              type="button"
              onClick={() => {
                handleResetState();
                setIsEditable(false);
              }}
            >
              {" "}
              Cancel
            </button>
            <button
              className={`btn btn-primary rounded-pill btn-xs ${
                (isSystemAndManualTaskPosting ||
                  isSystemAndManualTaskUpdating) &&
                "disabled"
              }`}
              type="submit"
            >
              {editedTaskId ? "Update" : "Add Priority"}
              {(isSystemAndManualTaskPosting ||
                isSystemAndManualTaskUpdating) && <Spinner />}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CandidateAddedTaskForm;

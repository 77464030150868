import { useQuery } from '@tanstack/react-query';
import CreateAuthAxiosInstance from 'utils/authAxios';

interface IUseGetElectionDetail {
  enabled?: boolean
  gcTime?: number
}

export const useGetElectionDetail = ({
  enabled = true,
  gcTime = 1000 * 5 * 60
}: IUseGetElectionDetail = {}) => {
  const authAxios = CreateAuthAxiosInstance();

  const {
    data: electionDetail,
    refetch,
    isFetching
  } = useQuery({
    queryKey: ["electionDetail"],
    queryFn: () => authAxios('api/v1/candidate/election').then(data => data.data),
    refetchOnWindowFocus: false,
    enabled,
    gcTime,
  });

  return {
    electionDetail,
    refetch,
    isFetching
  }
}

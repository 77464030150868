import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <Link
      className="dropdown-item p-0"
      to="#"
      onClick={() => {
        localStorage.clear();
        logout();
      }}
    >
      <i className="icon-signout"> </i>
      Sign Out
    </Link>
  );
};

export default LogoutButton;

import FacebookIcon from "img/svg/facebook";
import LogoOnly from "img/svg/logoOnly";
import TwitterIcon from "img/svg/twitter";
import { useEffect, useRef, useState } from "react";
import { formattedDate } from "utils/formatDate";
import { useTopPrioritiesData } from "./useTopPrioritiesData";

type IPostStatus = "Approved" | "Rejected" | "Skipped";

export interface IPostActionProps {
  postId: number;
  eventId: number | null;
  description: string;
  socialMedia: string;
  status: IPostStatus;
}

const socialMediaIcons = {
  Twitter: {
    icon: <TwitterIcon />,
    className: "twitter",
  },
  Facebook: {
    icon: <FacebookIcon />,
    className: "fb",
  },
};

const removeNumbersAtEndOfTitle = (title: string | null | undefined) => {
  const finalTitle = title?.length ? title.replace(/\s\d+$/, "").trim() : "";
  return finalTitle;
};

const SocialMediaHandle = () => {
  const {
    isPostSubmitting,
    submitPostData,
    todaysPosts,
    setCurrentSocialMedia,
  } = useTopPrioritiesData();
  const [currentTodaysPostIndex, setCurrentTodayPostIndex] =
    useState<number>(0);
  const currentPost = todaysPosts
    ? todaysPosts[currentTodaysPostIndex]
      ? todaysPosts[currentTodaysPostIndex]
      : null
    : null;

  const [currentPostContent, setCurrentPostContent] = useState<string | null>(
    ""
  );
  const [isContentEditable, setIsContentEditable] = useState<boolean>(false);

  const editedCurrentPostContentRef = useRef<string | null>("");

  useEffect(() => {
    if (currentPost) {
      setCurrentPostContent(currentPost?.description ?? "");
      setCurrentSocialMedia(currentPost.socialMedia);
    }
  }, [currentPost]);

  function handlePostRegeneration() {
    const totalPostNumber = todaysPosts?.length ?? 0;
    if (currentTodaysPostIndex === totalPostNumber - 1) {
      setCurrentTodayPostIndex(0);
      return;
    }
    setCurrentTodayPostIndex(currentTodaysPostIndex + 1);
  }

  function handleCandidatePostAction(status: IPostStatus) {
    if (currentPost?.id) {
      const finalPostData = {
        postId: currentPost?.id,
        eventId: currentPost?.eventId,
        description: currentPostContent ?? "",
        status: status,
        socialMedia: currentPost?.socialMedia,
      };

      if (
        finalPostData.postId &&
        finalPostData.eventId &&
        finalPostData.status &&
        finalPostData.socialMedia
      ) {
        submitPostData(finalPostData);
      }
    }
  }

  function handleEditSaveChanges() {
    setCurrentPostContent(editedCurrentPostContentRef.current);
    setIsContentEditable(false);
  }

  return (
    <>
      {currentPost && (
        <>
          {/* media object */}
          <div className="media">
            <div className="media-header">
              <span className="media-header--text">
                <LogoOnly />
              </span>
            </div>
            <div className="media-body">
              <h4>
                Topic :
                <span> {removeNumbersAtEndOfTitle(currentPost?.title)} </span>
              </h4>
              <h4>
                Scheduled for: <span>3:30pm </span>
                <span className="dot bg-neutral-n3 ms-1"> </span>
                <span>{formattedDate(currentPost?.startDate)}</span>
              </h4>
            </div>
          </div>

          {/* Social media post */}
          <div className="mediaPost editable-activ mt-3">
            <div className="mediaPost-header">
              <div
                className={`iconBox ${
                  socialMediaIcons[
                    currentPost?.socialMedia as keyof typeof socialMediaIcons
                  ]?.className ?? ""
                }`}
              >
                {socialMediaIcons[
                  currentPost?.socialMedia as keyof typeof socialMediaIcons
                ]?.icon ?? ""}
              </div>
            </div>
            <div className="mediaPost-body">
              <div className="platform">
                <h4 className="platform-title">
                  {currentPost?.socialMedia ?? ""}
                </h4>

                <div className="platform-action">
                  {!isContentEditable ? (
                    <>
                      <button
                        className="btn btn-link p-0 ms-2 d-inline-block"
                        onClick={() => {
                          setIsContentEditable(true);
                          editedCurrentPostContentRef.current =
                            currentPostContent;
                        }}
                      >
                        <span className="icon-edit"> </span>
                        Edit
                      </button>
                      <button
                        className="btn btn-link p-0 ms-4 d-inline-block reload-btn"
                        onClick={handlePostRegeneration}
                      >
                        <span className="icon-reload"> </span>
                        Regenerate
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-link p-0 ms-2 d-inline-block"
                        onClick={() => setIsContentEditable(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-link p-0 ms-4 d-inline-block"
                        onClick={handleEditSaveChanges}
                      >
                        <span className="icon-tick"> </span>
                        Save Changes
                      </button>
                    </>
                  )}
                </div>
              </div>

              {!isContentEditable ? (
                <p className="editable editedCurrentPostContentRef.current = e.currentTarget.textContentBox mt-2">
                  {currentPostContent}
                </p>
              ) : (
                <div
                  className="editableBox mt-2"
                  contentEditable={isContentEditable}
                  suppressContentEditableWarning={true}
                  onInput={(e) => {
                    if (e.currentTarget.textContent) {
                      editedCurrentPostContentRef.current =
                        e.currentTarget.textContent;
                    }
                  }}
                >
                  <p>{currentPostContent}</p>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer justify-content-between px-0 pt-4">
            <div className="action-btns">
              <button
                className={`btn btn-primary rounded-pill me-3 ${
                  isContentEditable || isPostSubmitting ? "disabled" : ""
                }`}
                onClick={() => handleCandidatePostAction("Approved")}
              >
                Approve post
              </button>
              <button
                className={`btn btn-reject rounded-pill ${
                  isContentEditable || isPostSubmitting ? "disabled" : ""
                }`}
                onClick={() => handleCandidatePostAction("Rejected")}
              >
                Reject post
              </button>
            </div>
            <div className="next-btn">
              <button
                className={`btn btn-link  ${
                  isContentEditable || isPostSubmitting ? "disabled" : ""
                }`}
                onClick={() => handleCandidatePostAction("Skipped")}
              >
                Skip post
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SocialMediaHandle;

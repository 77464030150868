const FacebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_3606_23980)">
        <path
          d="M12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.5C5.37258 0.5 0 5.87258 0 12.5C0 19.1274 5.37258 24.5 12 24.5Z"
          fill="#3B5998"
        />
        <path
          d="M15.0182 12.9697H12.877V20.8142H9.63279V12.9697H8.08984V10.2128H9.63279V8.42876C9.63279 7.15299 10.2388 5.15527 12.9058 5.15527L15.3089 5.16533V7.84136H13.5653C13.2793 7.84136 12.8772 7.98425 12.8772 8.59283V10.2153H15.3017L15.0182 12.9697Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3606_23980">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;

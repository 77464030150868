import React, { useEffect, useRef, ReactNode } from "react";
import { Tooltip } from "bootstrap";

interface ICustomTooltipProps {
  content: string;
  children: ReactNode;
  position?: Tooltip.PopoverPlacement;
}

const CustomTooltip: React.FC<ICustomTooltipProps> = ({
  content,
  children,
  position = "bottom",
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tooltip = new Tooltip(tooltipRef.current!, {
      title: content,
      placement: position,
      html: true,
      trigger: "hover",
    });

    return () => {
      tooltip.dispose();
    };
  }, [content, position]);

  return <span ref={tooltipRef}>{children}</span>;
};

export default CustomTooltip;

import { useGetElectionDetail } from "components/hooks/useGetElectionDetail";
import useGetFileTypeList, { IFileTypeObj } from "components/hooks/useGetFileTypeList";
import useGetCandidateFileList, { ICandidateFile } from "components/hooks/useGetCandidateFileList";
import { useEffect, useState } from "react";

export const BUDGET_MODE_QUERY_KEY = {
  RESET: 'reset',
  NULL: 'from_scratch',
  FILE: 'budget_file_based',
  FUND: 'fundraising_goal_based'
} as const

export type ModalTextKeyType = typeof BUDGET_MODE_QUERY_KEY[keyof typeof BUDGET_MODE_QUERY_KEY]

export const MODAL_TEXT: Record<ModalTextKeyType, { text1: string; text2: string }> = {
  [BUDGET_MODE_QUERY_KEY.RESET]: {
    text1: 'Do you want to reset the budget?',
    text2: 'By resetting the budget, the value in the budget details will be empty and auto activates budget mode “Start from scratch”.'
  },
  [BUDGET_MODE_QUERY_KEY.NULL]: {
    text1: 'Do you want to change the budget to “Start from Scratch”?',
    text2: 'By changing the budget, budget details will be empty initially. But the changes in the suggested budget will not be saved the next time you access it.'
  },
  [BUDGET_MODE_QUERY_KEY.FILE]: {
    text1: 'Do you want to change the budget to “Reflect according to uploaded file”? ',
    text2: 'By changing the budget, budget details will reflect according to uploaded file. But the changes in the suggested budget based on fundraising goal will not be saved the next time you access it.'
  },
  [BUDGET_MODE_QUERY_KEY.FUND]: {
    text1: 'Do you want to change the budget to “Reflect according to suggested budget based on fundraising goal"?',
    text2: 'By changing the budget, budget details will reflected according to suggested budget. But the changes in the uploaded file will be saved the next time you access it.'
  }
}

const useBudgetMode = () => {
  const [currentBudgetMode, setCurrentBudgetMode] = useState<ModalTextKeyType>(BUDGET_MODE_QUERY_KEY.RESET)
  const [isCandidateBudgetFileUploaded, setIsCandidateBudgetFileUploaded] = useState<boolean>(false)

  const {
    electionDetail,
    refetch: refetchElectionDetail,
    isFetching
  } = useGetElectionDetail()

  const { fileTypeList } = useGetFileTypeList()
  const { candidateFileList } = useGetCandidateFileList()

  const isFundRaisingGoalAdded: boolean = electionDetail?.candidate?.fundraisingGoal ? electionDetail.candidate.fundraisingGoal > 0 : false
  const budgetMode: string | null = electionDetail?.budgetMode || null

  useEffect(() => {
    if (
      Array.isArray(fileTypeList) &&
      fileTypeList.length &&
      Array.isArray(candidateFileList) &&
      candidateFileList.length
    ) {
      const candidateBudgetFileId: number | null = (fileTypeList?.find((obj: IFileTypeObj) => obj?.title === "candidate-budget")?.id) || null
      const isFileUploaded = candidateFileList?.some((file: ICandidateFile) => file?.fileTypeId === candidateBudgetFileId)
      setIsCandidateBudgetFileUploaded(isFileUploaded || false)
    }
  }, [fileTypeList, candidateFileList])

  return {
    currentBudgetMode,
    setCurrentBudgetMode,
    isCandidateBudgetFileUploaded,
    refetchElectionDetail,
    isFundRaisingGoalAdded,
    budgetMode
  }
}

export default useBudgetMode
import AiIcon from "img/svg/AiIcon";
import MessageSendIcon from "img/svg/MessageSendIcon";
import { useConversationalAI } from "./useConversationalAi";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AiChatBox = () => {
  const {
    query,
    handlePromptInput,
    onGeneratePrompt,
    isGenerating,
    textAreaRef,
    activeChatDateIndex,
    sendButtonRef,
  } = useConversationalAI();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey && query.trim() !== "") {
      event.preventDefault();
      if (isGenerating || activeChatDateIndex !== -1) return;
      onGeneratePrompt();
    }
  };

  useEffect(() => {
    const textAreaDiv = textAreaRef.current;
    if (textAreaDiv) {
      const scrollHeight =
        textAreaDiv.scrollHeight === 0 ? 48 : textAreaDiv.scrollHeight;
      textAreaDiv.style.height = query === "" ? "48px" : scrollHeight + "px";
    }
  }, [textAreaRef, query]);

  return (
    <div
      className={`aiChatBox ${
        activeChatDateIndex !== -1 || isGenerating ? "disabled" : ""
      }`}
    >
      <div className="ai-icon">
        <AiIcon />
      </div>
      <textarea
        rows={1}
        value={query}
        ref={textAreaRef}
        id="review-text"
        onKeyDown={handleKeyDown}
        onChange={handlePromptInput}
        className="form-control"
        placeholder="Ask AI to write anything for your campaign..."
        disabled={isGenerating}
      ></textarea>
      <button
        className={`btn btn-link no-hover px-0 py-0 send-btn ${
          isGenerating || query.trim() === "" ? "disabled" : ""
        }`}
        onClick={() => {
          if (activeChatDateIndex === -1) onGeneratePrompt();
        }}
        ref={sendButtonRef}
      >
        <MessageSendIcon />
      </button>
    </div>
  );
};

export default AiChatBox;

import { Link } from "react-router-dom";

interface IBreadCrumbsProps {
  title?: string;
  subtitle?: string;
  link?: string;
}

const BreadCrumbs = ({ title, subtitle, link }: IBreadCrumbsProps) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb flex-nowrap">
        <li className="breadcrumb-item">
          <Link to={link ?? "/voters/contact-plan"}>
            {title ?? "Contact Plan"}
          </Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {subtitle ?? ""}
        </li>
      </ol>
    </nav>
  );
};

export default BreadCrumbs;

import HelpIcon from "img/svg/helpIcon";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomTooltip from "components/common/CustomTooltip";
import "chart.js/auto";
import BudgetAllocation from "./components/BudgetAllocation";
import CashFlow from "./components/CashFlow";
import useBudgetOverview from "./useBudgetOverview";
import TableCellPlaceholder from "./components/TableCellPlaceholder";
import { formatBudgetOverviewDate, incrementMonth } from "utils/formatDate";
import Loader from "components/common/Loader";
import { dateFormatter, formatFileSize } from "utils/uploadFile";
import { useEffect, useState } from "react";
import InfoIconDanger from "img/svg/InfoIconDanger";
import { ELECTION_ID } from "components/pages/dashboard/Dashboard";
import CheckSuccess from "img/svg/checkSuccess";
import { fileSizeLimit } from "utils/budgetFileSizeLimit";
import { handleDownloadClick } from "utils/fileDownload";
import { isValidFileType } from "../common/CheckValidFile";
import TopBanner from "../common/TopBanner";
import UploadModal from "../common/upload-file/UploadModal";

export interface IMonthlyCategoryOverviewBudget {
  date: string | null;
  monthNumber: number | null;
  name: string | null;
  totalIncome: string;
  totalExpense: string;
  totalDifference: string;
}

export interface IFundraisingData {
  id: number;
  fundraisingGoal: number;
}

const BudgetOverview = () => {
  // tooltip
  const budgetTotalTooltip = `Taken from the Budget Details module, monthly breakdown and projections of Income and Expenses for the entirety of the campaign.`;

  const {
    funraisingData,
    budgetTotalData,
    isFetchingBudgetTotal,
    budgetDifferenceClassName,
    sliderSettings,
    isLastSlide,
    sliderRef,
    budgetAllocationData,
    isFetchingBudgetAlloation,
    cashFlowData,
    isFetchingBudgetCashFlow,
    isbudgetOverviewsLoading,
    budgetDetailsFromApi,
    budgetTemplate,
    mutateDeleteBudgetFile,
    file,
    setFile,
    error,
    mutateBudgetFile,
    budgetFileType,
    isFileUploadPending,
    isFileUploadSuccess,
    refetchBudgetTotal,
    handleTemplateDownload,
  } = useBudgetOverview();

  const [timeZone, setTimeZone] = useState("");
  const electionId = localStorage.getItem(ELECTION_ID);
  const currentDate = new Date();

  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  // Loading Animation
  if (isbudgetOverviewsLoading) {
    return (
      <div className="d-flex align-items-stretch">
        <div className="wrapper active-cont d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-stretch">
      <div className="wrapper active-cont">
        <main>
          <>
            {/* alert */}
            <TopBanner
              funraisingData={funraisingData}
              budgetDetailsFromApi={budgetDetailsFromApi}
            />
            {/* Cards */}
            <div className="row">
              <BudgetAllocation
                budgetAllocationData={budgetAllocationData}
                isFetchingBudgetAlloation={isFetchingBudgetAlloation}
              />
              <CashFlow
                cashFlowData={cashFlowData}
                isFetchingBudgetCashFlow={isFetchingBudgetCashFlow}
              />
            </div>
            <div className="row mt-6">
              <div className="col-md-12">
                <div className="card card-flush shadow-2">
                  <div className="card-header">
                    <div className="d-flex gap-2">
                      <h4 className="card-title"> Budget total </h4>
                      <CustomTooltip content={budgetTotalTooltip}>
                        <HelpIcon />
                      </CustomTooltip>
                    </div>
                    <span className="d-block fs-8">
                      Before{" "}
                      {
                        formatBudgetOverviewDate(
                          budgetTotalData?.startDate,
                          true
                        )?.formattedDate
                      }{" "}
                      -{" "}
                      {
                        formatBudgetOverviewDate(budgetTotalData?.endDate, true)
                          ?.formattedDate
                      }
                    </span>
                  </div>
                  <div className="card-body p-0">
                    <div className="contactPlan budgetPlanOverview">
                      <div className="contactPlan__legend">
                        <div className="contactPlan__legend-item">
                          <div
                            style={{
                              height: "64px",
                              background: "#F4F4F6",
                            }}
                          ></div>
                          <div> Total Income </div>
                          <div> Total Expenses</div>
                          <div> Differences </div>
                        </div>
                      </div>
                      <div className="contactPlan__legend">
                        <div className="contactPlan__legend-item">
                          <div
                            style={{
                              height: "64px",
                              background: "#F4F4F6",
                              borderRight: "1px solid #CBCBD8",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            Election Total{" "}
                          </div>
                          <div style={{ textAlign: "right" }}>
                            {isFetchingBudgetTotal ? (
                              <TableCellPlaceholder />
                            ) : (
                              `$${budgetTotalData?.totalIncome || 0}`
                            )}
                          </div>
                          <div style={{ textAlign: "right" }}>
                            {isFetchingBudgetTotal ? (
                              <TableCellPlaceholder />
                            ) : (
                              `$${budgetTotalData?.totalExpense || 0}`
                            )}
                          </div>
                          <div
                            style={{ textAlign: "right" }}
                            className={budgetDifferenceClassName(
                              budgetTotalData?.totalDifference
                            )}
                          >
                            {isFetchingBudgetTotal ? (
                              <TableCellPlaceholder />
                            ) : (
                              `$${Math.abs(
                                Number(budgetTotalData?.totalDifference) || 0
                              )?.toFixed(2)}`
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          isLastSlide ||
                          budgetTotalData?.monthlyCategoryBudgets.length < 4
                            ? `contactPlan__carousel contactPlan__carousel-control`
                            : `contactPlan__carousel`
                        }
                      >
                        <Slider ref={sliderRef} {...sliderSettings}>
                          {!!budgetTotalData?.monthlyCategoryBudgets?.length &&
                            budgetTotalData?.monthlyCategoryBudgets.map(
                              (budget: IMonthlyCategoryOverviewBudget) => {
                                const budgetDate = budget?.date || ""; // Default to an empty string if date is null
                                const isCurrentOrPastMonth =
                                  formatBudgetOverviewDate(budget?.date)
                                    ?.currentMonth ||
                                  new Date(budgetDate) <= new Date();
                                const hasBudgetName = !!budget?.name;
                                return (
                                  <div className="contactPlan__carousel-item">
                                    <div className="performance-card">
                                      <span className="performance-card__datetime">
                                        {budget?.name ??
                                          formatBudgetOverviewDate(
                                            budget?.date,
                                            false
                                          )?.formattedDate}
                                      </span>
                                      {/* this week */}
                                      {formatBudgetOverviewDate(budget?.date)
                                        ?.currentMonth && (
                                        <div className="current-month">
                                          <span className="d-block font-semibold text-success fs-8">
                                            This Month
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div className="contactPlan__cell">
                                      <div className="budget__cell non-editable">
                                        <span>
                                          {isFetchingBudgetTotal ? (
                                            <TableCellPlaceholder />
                                          ) : (
                                            `$${budget?.totalIncome || 0}`
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="contactPlan__cell">
                                      <div className="budget__cell non-editable">
                                        <span>
                                          {isFetchingBudgetTotal ? (
                                            <TableCellPlaceholder />
                                          ) : (
                                            `$${budget?.totalExpense || 0}`
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="contactPlan__cell">
                                      <div className="budget__cell non-editable">
                                        <span
                                          className={
                                            isCurrentOrPastMonth ||
                                            hasBudgetName
                                              ? budgetDifferenceClassName(
                                                  budget?.totalDifference
                                                )
                                              : ""
                                          }
                                        >
                                          {isFetchingBudgetTotal ? (
                                            <TableCellPlaceholder />
                                          ) : (
                                            `$${Math.abs(
                                              Number(budget?.totalDifference) ||
                                                0
                                            )?.toFixed(2)}`
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </main>
      </div>

      <UploadModal
        handleTemplateDownload={handleTemplateDownload}
        budgetDetailsFromApi={budgetDetailsFromApi}
        electionId={electionId}
        budgetFileType={budgetFileType}
      />
    </div>
  );
};

export default BudgetOverview;

import FilterIcon from "img/svg/fiIterIcon";
import SortIcon from "img/svg/sortIcon";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { IVotersList } from "components/pages/voters/voters-list/VotersTable";
import { ASC, DESC } from "components/pages/voters/voters-list/VotersList";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export const VOTER_STATUS = ["Not Home", "Moved", "Deceased", "Wrong Number"];
export const LEVEL_OF_SUPPORT = [
  "Strong Support",
  "Lean Support",
  "Undecided",
  "Lean Against",
  "Strong Against",
];

const TableFilter = ({
  sortingOrder,
  setSortingOrder,
  selectedLevelOfSupport,
  setSelectedLevelOfSupport,
  selectedVoterStatus,
  setSelectedVoterStatus,
  searchTerm,
  setSearchTerm,
  electionId,
}: {
  votersList: Array<IVotersList>;
  sortingOrder: string;
  setSortingOrder: React.Dispatch<React.SetStateAction<string>>;
  selectedVoterStatus: string;
  setSelectedVoterStatus: React.Dispatch<React.SetStateAction<string>>;
  selectedLevelOfSupport: string;
  setSelectedLevelOfSupport: React.Dispatch<React.SetStateAction<string>>;
  searchTerm: string | undefined;
  setSearchTerm: React.Dispatch<React.SetStateAction<string | undefined>>;
  electionId: number | null;
}) => {
  // const handleVoterStatusChange = (value: string) => {
  //   if (selectedVoterStatus.includes(value)) {
  //     setSelectedVoterStatus([
  //       ...selectedVoterStatus.filter((item) => item !== value),
  //     ]);
  //   } else {
  //     setSelectedVoterStatus([...selectedVoterStatus, value]);
  //   }
  // };

  // const handleLevelOfSupportChange = (value: string) => {
  //   if (selectedLevelOfSupport.includes(value)) {
  //     setSelectedLevelOfSupport([
  //       ...selectedLevelOfSupport.filter((item) => item !== value),
  //     ]);
  //   } else {
  //     setSelectedLevelOfSupport([...selectedLevelOfSupport, value]);
  //   }
  // };
  const { getIdTokenClaims } = useAuth0();

  const [csvData, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const token = await getIdTokenClaims();
      try {
        // Get voters list for exporting as csv
        const csvResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/voter/csv?electionId=${electionId}`,
          {
            headers: {
              Authorization: `Bearer ${token?.__raw}`,
            },
          }
        );
        const csvDataInFormat = csvResponse?.data
          ?.split("\n")
          .reduce((result: Array<Array<string>>, item: string) => {
            return (result = [...result, item.split(",")]);
          }, []);

        setCsvData(csvDataInFormat);
        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className="row justify-content-between align-items-start">
      <div className="col-md-4">
        <div className="d-flex flex-column gap-2">
          <input
            type="text"
            className="form-control"
            placeholder="Search voter"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                setSearchTerm(e.target.value);
              }
            }}
          />

          {((searchTerm && searchTerm?.length > 2) ||
            selectedVoterStatus ||
            selectedLevelOfSupport) && (
            <div className="filter-message">
              Showing results for {searchTerm && <span>"{searchTerm}", </span>}
              {selectedVoterStatus && <span>"{selectedVoterStatus}", </span>}
              {selectedLevelOfSupport && (
                <span>"{selectedLevelOfSupport}", </span>
              )}
              <span>{sortingOrder === ASC ? "A - Z" : "Z - A"} </span>
            </div>
          )}
        </div>
      </div>
      <div className="col-md-8">
        <div className="d-flex justify-content-end align-items-center gap-3">
          <div className="dropdown">
            <button
              className="btn btn-outline-secondary btn-xxs bg-white"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Filter{" "}
              <span className="">
                {" "}
                <FilterIcon />{" "}
              </span>
            </button>

            <div className="dropdown-menu dropdown-menu-checkbox">
              <div className="dropdown-group">
                <h6 className="dropdown-header"> Voter Status</h6>
                <ul className="dropdown-body list-unstyled">
                  {VOTER_STATUS.map((status, index) => (
                    <li key={index}>
                      <input
                        className="checkbox-round"
                        type="checkbox"
                        id={status + index}
                        value={status}
                        checked={status === selectedVoterStatus}
                        onChange={() => {
                          status === selectedVoterStatus
                            ? setSelectedVoterStatus("")
                            : setSelectedVoterStatus(status);
                        }}
                      />
                      <label htmlFor={status + index}>{status}</label>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="dropdown-group">
                <h6 className="dropdown-header">Level of Support </h6>
                <ul className="dropdown-body list-unstyled mb-0">
                  {LEVEL_OF_SUPPORT.map((support, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        className="checkbox-round"
                        id={support + index}
                        value={support}
                        checked={support === selectedLevelOfSupport}
                        onChange={() => {
                          support === selectedLevelOfSupport
                            ? setSelectedLevelOfSupport("")
                            : setSelectedLevelOfSupport(support);
                        }}
                      />
                      <label htmlFor={support + index}>{support}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="dropdown">
            <button
              className="btn btn-outline-secondary btn-xxs bg-white"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Sort{" "}
              <span className="">
                {" "}
                <SortIcon />{" "}
              </span>
            </button>
            <div className="dropdown-menu dropdown-menu-checkbox">
              <div className="dropdown-group">
                <ul className="dropdown-body list-unstyled">
                  <li>
                    <input
                      type="radio"
                      value="asc"
                      id="atoz"
                      className="checkbox-round"
                      checked={sortingOrder === ASC}
                      onChange={() => setSortingOrder(ASC)}
                    />
                    <label htmlFor="atoz">A - Z</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      value="desc"
                      id="ztoa"
                      className="checkbox-round"
                      checked={sortingOrder === DESC}
                      onChange={() => setSortingOrder(DESC)}
                    />
                    <label htmlFor="ztoa">Z - A</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* export */}
          {csvData && (
            <CSVLink
              data={csvData}
              filename={"voters-list.csv"}
              // className={`btn btn-info rounded-pill btn-xs px-4`}
              className={`btn btn-info rounded-pill btn-xs px-4 ${
                isLoading ? "disabled" : ""
              }`}
              target="_blank"
            >
              Export CSV
            </CSVLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableFilter;

import React, { useState } from "react";
//svg
import FbIcon from "../img/svg/fb";
import TwitterIcon from "../img/svg/twitter";
import FbSmall from "../img/svg/fbSmall";

import DatePicker from "react-datepicker";
import TwitterSmall from "../img/svg/twitterSmall";
import SideNavbar from "../components/common/navbar/SideNavbar";
import CheckSuccess from "../img/svg/checkSuccess";
import InfoIconWarning from "img/svg/InfoIconWarning";
import { Link } from "react-router-dom";

import AlertBanner from "./components/AlertBanner";
import TableFilter from "../components/pages/voters/voters-list/TableFilter";
import Breadcrumbs from "./components/Breadcrumbs";
import ApprovedIcon from "img/svg/approvedIcon";
import RejectedIcon from "img/svg/rejectedIcon";
import Calling from "img/svg/Calling";
import CallDrop from "img/svg/CallDrop";
import PhoneDropped from "img/svg/PhoneDropped";
import OnCall from "./components/call/OnCall";
import CallEnded from "./components/call/CallEnded";
import VoiceMail from "img/svg/VoiceMail";
import InfoIconDanger from "img/svg/InfoIconDanger";
import FlagUsa from "img/svg/FlagUsa";
import MultiSelectDd from "./components/MultiSelectDd";
import SearchIcon from "img/svg/SearchIcon";

const Components = () => {
  const [startDate, setStartDate] = useState(new Date());
    // for select 
    const options = [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' },
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' },
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' },
    ]
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="container">
            <div className="row">
              {/* Alert */}
              <div className="col-md-12">
                <div
                  className="alert alert-warning alert-dismissible fade show"
                  role="alert"
                >
                  <InfoIconWarning />{" "}
                  <span className="ms-2">
                    Please verify your email to continue with all features
                  </span>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  >
                    <span className="icon-close"></span>
                  </button>
                </div>

                <AlertBanner />
              </div>

              {/* Breadcrumbs */}
              <Breadcrumbs />

              <div className="row">
                <div className="col-12">
                  <div className="section-box">
                    <h3 className="section-box-title">Basic Components </h3>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3> Typography </h3>
                  </div>

                  <div className="card-body">
                    <h1> Heading 1</h1>
                    <h2> Heading 2</h2>
                    <h3> Heading 3</h3>
                    <h4> Heading 4</h4>

                    <p>
                      {" "}
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Ratione, exercitationem vel. Ipsa, velit enim eos nobis ut
                      facilis excepturi pariatur consequuntur sit sunt odio sint
                      libero illo earum atque alias?
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3> Buttons </h3>
                  </div>

                  <div className="card-body">
                    <div className="d-flex gap-2 mb-2">
                      <Link to="/" className="tag tag-success rounded-pill">
                        {" "}
                        <ApprovedIcon /> Approved{" "}
                      </Link>
                      <Link to="/" className="tag tag-reject rounded-pill">
                        {" "}
                        <RejectedIcon /> Rejected{" "}
                      </Link>
                      <Link to="/" className="btn btn-primary">
                        {" "}
                        Primary{" "}
                      </Link>
                      <Link to="/" className="btn btn-secondary">
                        {" "}
                        Secondary{" "}
                      </Link>
                      <Link to="/" className="btn btn-warning">
                        {" "}
                        Warning{" "}
                      </Link>
                      <Link to="/" className="btn btn-info">
                        {" "}
                        Info{" "}
                      </Link>
                      <Link to="/" className="btn btn-success">
                        {" "}
                        Success{" "}
                      </Link>
                      <Link to="/" className="btn btn-danger">
                        {" "}
                        Danger{" "}
                      </Link>
                    </div>
                    <div className="d-flex gap-2 mb-2">
                      <Link to="/" className="btn btn-primary rounded-pill">
                        {" "}
                        Primary{" "}
                      </Link>
                      <Link to="/" className="btn btn-secondary rounded-pill">
                        {" "}
                        Secondary{" "}
                      </Link>
                      <Link to="/" className="btn btn-warning rounded-pill">
                        {" "}
                        Warning{" "}
                      </Link>
                      <Link to="/" className="btn btn-info rounded-pill">
                        {" "}
                        Info{" "}
                      </Link>
                      <Link to="/" className="btn btn-success rounded-pill">
                        {" "}
                        Success{" "}
                      </Link>
                      <Link to="/" className="btn btn-danger rounded-pill">
                        {" "}
                        Danger{" "}
                      </Link>
                    </div>
                    <div className="d-flex gap-2 mb-2">
                      <Link to="/" className="btn btn-outline-primary">
                        {" "}
                        Primary{" "}
                      </Link>
                      <Link to="/" className="btn btn-outline-secondary">
                        {" "}
                        Secondary{" "}
                      </Link>
                      <Link to="/" className="btn btn-outline-warning">
                        {" "}
                        Warning{" "}
                      </Link>
                      <Link to="/" className="btn btn-outline-info">
                        {" "}
                        Info{" "}
                      </Link>
                      <Link to="/" className="btn btn-outline-success">
                        {" "}
                        Success{" "}
                      </Link>
                      <Link to="/" className="btn btn-outline-danger">
                        {" "}
                        Danger{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card mt-5 mb-5 ">
                  <div className="card-header">
                    <h4> Media Objects</h4>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="media">
                          <div className="media-header">
                            <span className="media-header--text"> JJ </span>
                          </div>
                          <div className="media-body">
                            <h4> Jacob Jones </h4>
                            <p>
                              <strong>Date of joining: 6/23/2023 </strong>
                            </p>
                            <span> Mesa, New Jersey </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="media media--sm">
                          <div className="media-header">
                            <span className="media-header--text"> JJ </span>
                          </div>
                          <div className="media-body">
                            <h4> Jacob Jones </h4>
                            <p>
                              <strong>Date of joining: 6/23/2023 </strong>
                            </p>
                            <span> Mesa, New Jersey </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card border-1 mt-4">
                  <div className="card-body">
                    <div className="row align-items-end">
                      <div className="col-md-9">
                        <div className="media media-lg gap-3">
                          <div className="media-header border-0">
                            <img
                              src={require("../img/avatar.png")}
                              className="img-fluid"
                              alt="User Avatar"
                            />
                          </div>

                          <div className="media-body">
                            <h5 className="mb-0">Alexa Johnson</h5>

                            <span className="badge badge-success rounded-pill">
                              {" "}
                              Digital Ad
                            </span>
                            <p className="mt-3 text-muted">
                              Alex consistently delivers impactful results in
                              connecting candidates with voters through mail
                              campaigns, showcasing expertise and dedication in
                              crafting effective communication strategies.
                            </p>

                            <div className="d-flex gap-2">
                              <span className="badge badge-primary5 font-regular rounded-pill">
                                {" "}
                                Mail{" "}
                              </span>
                              <span className="badge badge-primary5 font-regular rounded-pill">
                                {" "}
                                Democrats{" "}
                              </span>
                              <span className="badge badge-primary5 font-regular rounded-pill">
                                {" "}
                                Rural{" "}
                              </span>
                              <span className="badge badge-primary5 font-regular rounded-pill">
                                {" "}
                                BIPOC{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mt-3 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-xs rounded-pill w-100 d-block mb-2"
                          >
                            {" "}
                            Schedule{" "}
                          </button>
                          <span className="fs-8 text-muted"> Hourly rate </span>
                          <span className="body-5 d-block mb-0">
                            {" "}
                            $70.00 - $105.00{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card mt-5 mb-5">
                  <div className="card-header">
                    <h4> Call with component</h4>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="callBox">
                          <OnCall />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="callBox">
                          <CallEnded />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card mt-5 mb-5">
                  <div className="card-header">
                    <h4> Call </h4>
                  </div>

                  <div className="card-body">
                    <div className="callBox">
                      <div className="callBox-status">
                        <Calling />

                        {/* if phone dropped */}
                        {/* <PhoneDropped />  */}

                        <div className="callBox-status-body">
                          <div className="callBox-status-text"> Calling...</div>
                          <div className="callBox-status-duration">
                            {" "}
                            0:14
                            {/* if not connected to call */}
                            {/* <span className="text-danger"> Couldn’t connect </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="callBox-btn">
                        <button className="btn btn-danger btn-cancel rounded-circle">
                          {/* <Incall /> */}
                          {/* End Call icon */}
                          <CallDrop />
                        </button>
                      </div>
                    </div>

                    <div className="callBox">
                      <div className="callBox-status">
                        {/* <Calling /> */}

                        {/* if phone dropped */}
                        <PhoneDropped />

                        <div className="callBox-status-body">
                          <div className="callBox-status-text"> Call Ended</div>
                          <div className="callBox-status-duration">
                            {" "}
                            0:14
                            {/* if not connected to call */}
                            {/* <span className="text-danger"> Couldn’t connect </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="callBox-btn">
                        <button className="btn btn-success btn-cancel rounded-circle">
                          <VoiceMail />
                          {/* End Call icon */}
                          {/* <CallDrop /> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card mt-5 mb-5 ">
                  <div className="card-header">
                    <h4> Forms </h4>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      {/* timeline */}
                      <div className="col-md-5">
                        <div className="timeline">
                          <div className="timeline-item active success">
                            <div className="timeline-step">
                              <span>1 </span>
                            </div>

                            <div className="timeline-content">
                              <h6 className="mb-1"> About Yourself </h6>
                              <p>Personal details about yourself </p>
                            </div>
                          </div>
                          <div className="timeline-item active">
                            <div className="timeline-step">
                              <span>2 </span>
                            </div>

                            <div className="timeline-content">
                              <h6 className="mb-1"> Election Date </h6>
                              <p>Main election event </p>
                            </div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-step">
                              <span>3</span>
                            </div>

                            <div className="timeline-content">
                              <h6 className="mb-1"> Social Media </h6>
                              <p>Link social media pages </p>
                            </div>
                          </div>
                          <div className="timeline-item">
                            <div className="timeline-step">
                              <span>4 </span>
                            </div>

                            <div className="timeline-content">
                              <h6 className="mb-1"> Working Hours </h6>
                              <p> Hours available in a week </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* forms */}
                      <div className="col-md-7">
                        <form>
                          <div className="row">
                            <div className="col-md-12">
                              <h5> Button Radio </h5>

                              <h6 className="mt-4">Normal Radio</h6>
                              <div className="btn-checkGroup d-flex gap-3">
                                <div className="btn-checkGroup-item">
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="options"
                                    id="option1"
                                    autoComplete="off"
                                  />
                                  <label
                                    className="btn btn-outline-primary btn-xs rounded-pill"
                                    htmlFor="option1"
                                  >
                                    5 hours
                                  </label>
                                </div>

                                <div className="btn-checkGroup-item">
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="options"
                                    id="option2"
                                    autoComplete="off"
                                  />
                                  <label
                                    className="btn btn-outline-primary btn-xs rounded-pill"
                                    htmlFor="option2"
                                  >
                                    10 hours
                                  </label>
                                </div>

                                <div className="btn-checkGroup-item">
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="options"
                                    id="option3"
                                    autoComplete="off"
                                  />
                                  <label
                                    className="btn btn-outline-primary btn-xs rounded-pill"
                                    htmlFor="option3"
                                  >
                                    15 hours
                                  </label>
                                </div>
                              </div>

                              <h6 className="mt-4"> Radio with check </h6>
                              <div className="btn-checkGroup d-flex gap-3">
                                <div className="btn-checkGroup-item btn-checkGroup-item--icon">
                                  <input
                                    type="radio"
                                    className="btn-check form-check-input"
                                    name="options"
                                    id="option11"
                                    autoComplete="off"
                                  />
                                  <label
                                    className="btn btn-outline-primary rounded-pill ps-5"
                                    htmlFor="option11"
                                  >
                                    File upload
                                  </label>
                                </div>

                                <div className="btn-checkGroup-item btn-checkGroup-item--icon">
                                  <input
                                    type="radio"
                                    className="btn-check form-check-input"
                                    name="options"
                                    id="option12"
                                    autoComplete="off"
                                  />
                                  <label
                                    className="btn btn-outline-primary rounded-pill ps-5"
                                    htmlFor="option12"
                                  >
                                    Add a donor manually
                                  </label>
                                </div>
                              </div>

                              <div className="mb-3">
                                <label
                                  htmlFor="position"
                                  className="form-label"
                                >
                                  {" "}
                                  Default Select{" "}
                                </label>
                                <select
                                  className="form-select"
                                  aria-label="select Position you are running for"
                                  name="position"
                                >
                                  <option disabled hidden value="" selected>
                                    Choose position for election
                                  </option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>

                              <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              React Select (multi select)
                            </label>

                            <MultiSelectDd />
                            </div>
                            </div>
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Email address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter email address"
                            />
                            <div className="invalidInput form-text text-danger">
                              <InfoIconDanger /> Name cannot be numeric{" "}
                            </div>
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Enter Password"
                            />
                          </div>
                          
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Enter Password"
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Message
                            </label>
                            <div className="position-relative">
                              <textarea
                                className="form-control"
                                id="Textarea1"
                                rows={3}
                                maxLength={1000}
                              ></textarea>
                              <span className="word-count"> 0 / 200 </span>
                            </div>
                          </div>

                          <div className="mb-3 required">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Phone no
                            </label>
                            <div className="input-group">
                              <button
                                className="btn py-1 px-3 btn-outline-secondary dropdown-toggle disable"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <FlagUsa /> <span className="body-4"> +1 </span>
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a className="dropdown-item" href="#">
                                    USA
                                  </a>
                                </li>
                              </ul>
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Text input with country code dropdown"
                                placeholder="Enter your phone no."
                              />
                            </div>
                          </div>

                          <div className="mb-3">
                            <div className="searchBox">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search news with specific keyword"
                              />
                              <SearchIcon />
                            </div>
                          </div>

                          {/* Datepicker */}

                          <div className="mb-3">
                            <label htmlFor="date" className="form-label">
                              Datepicker
                            </label>
                            <div className="custom-datepicker">
                              <DatePicker
                                className="form-control"
                                showIcon={true}
                                selected={startDate}
                                onChange={(date) =>
                                  setStartDate(date ?? new Date())
                                }
                              />
                            </div>
                          </div>

                          {/* File Upload as Input Group */}
                          <div className="row">
                            <div className="col-md-9">
                              <h5> File Upload as Input Group </h5>
                              <div className="input-group custom-file-button mb-3">
                                <label
                                  className="input-group-text btn btn-info rounded-pill"
                                  htmlFor="inputGroupFile"
                                >
                                  {" "}
                                  Upload File{" "}
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  id="inputGroupFile"
                                />
                              </div>
                            </div>
                          </div>

                          {/* Custom File Upload */}
                          <div className="row">
                            <div className="col-md-9">
                              <div className="uploadedCard">
                                <div className="uploadedCard-icon">
                                  <div className="iconInfo">
                                    <span className="iconInfo-title text-muted">
                                      (pdf, jpg, jpeg, xls, or any)
                                    </span>
                                  </div>
                                </div>
                                <div className="uploadedCard-info file-btn">
                                  <button className="btn btn-info rounded-pill">
                                    {" "}
                                    Upload File
                                    <input type="file" name="file" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* File Uploaded */}
                          <div className="row">
                            <div className="col-md-9">
                              <div className="uploadedCard">
                                <div className="uploadedCard-icon">
                                  <div className="iconBox"></div>

                                  <div className="iconInfo">
                                    <span className="iconInfo-title">
                                      {" "}
                                      Voter List.pdf{" "}
                                    </span>
                                    <p className="iconInfo-desc">
                                      {" "}
                                      1.5 MB, 6/23/23 at 11:45 am{" "}
                                    </p>
                                    <span className="iconInfo-status">
                                      {" "}
                                      <CheckSuccess /> File uploaded{" "}
                                    </span>
                                  </div>
                                </div>
                                <div className="uploadedCard-info file-btn">
                                  <button className="btn btn-link rounded-pill text-primary">
                                    {" "}
                                    Change File
                                    <input type="file" name="file" />
                                  </button>
                                </div>
                              </div>

                              
                            </div>
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary w-100 rounded-pill"
                          >
                            Get Started
                          </button>
                        </form>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <h5 className="mt-4"> Connect with us </h5>

                        {/* connect card fb*/}
                        <div className="connectCard">
                          <div className="connectCard-icon">
                            <div className="iconBox fb">
                              <FbIcon />
                            </div>
                            <div className="iconInfo">
                              <span className="iconInfo-platform">
                                {" "}
                                Facebook{" "}
                              </span>
                            </div>
                          </div>
                          <div className="connectCard-info">
                            <button className="btn btn-info rounded-pill">
                              {" "}
                              Connect{" "}
                            </button>
                          </div>
                        </div>

                        {/* connect card twitter*/}
                        <div className="connectCard">
                          <div className="connectCard-icon">
                            <div className="iconBox twitter">
                              <TwitterIcon />
                            </div>
                            <div className="iconInfo">
                              <span className="iconInfo-platform">
                                {" "}
                                Facebook{" "}
                              </span>
                            </div>
                          </div>
                          <div className="connectCard-info">
                            <button className="btn btn-info rounded-pill">
                              {" "}
                              Connect{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h5 className="mt-4"> Connected </h5>

                        {/* connected card fb*/}
                        <div className="connectCard">
                          <div className="connectCard-icon connected">
                            <div className="profileBox fb">
                              <img
                                src={require("../img/avatar.png")}
                                className="img-fluid"
                                alt=""
                              />

                              <div className="profileBox-logo">
                                <FbSmall />
                              </div>
                            </div>
                            <div className="iconInfo">
                              <span className="iconInfo-platform">
                                {" "}
                                Facebook{" "}
                              </span>
                              <p className="mb-0">
                                <span className="text-success">Connected </span>{" "}
                                as Elenor Parker
                              </p>
                            </div>
                          </div>
                          <div className="connectCard-info">
                            <button className="btn btn-link text-danger">
                              {" "}
                              Remove{" "}
                            </button>
                          </div>
                        </div>

                        {/* connected card twitter*/}
                        <div className="connectCard">
                          <div className="connectCard-icon connected">
                            <div className="profileBox fb">
                              <img
                                src={require("../img/avatar.png")}
                                className="img-fluid"
                                alt=""
                              />

                              <div className="profileBox-logo">
                                <TwitterSmall />
                              </div>
                            </div>
                            <div className="iconInfo">
                              <span className="iconInfo-platform">
                                {" "}
                                Twitter{" "}
                              </span>
                              <p className="mb-0">
                                <span className="text-success">Connected </span>{" "}
                                as Elenor Parker
                              </p>
                            </div>
                          </div>
                          <div className="connectCard-info">
                            <button className="btn btn-link text-danger">
                              {" "}
                              Remove{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12">
                <div>
                  {/* filter */}
                  {/* <TableFilter /> */}

                  <div className="card card-fullHeight scroll-y mt-3">
                    {/* table */}
                    <table className="table table-full table-eq-height">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" />
                          </th>
                          <th>Name</th>
                          <th>Address</th>
                          <th>Voter Id</th>
                          <th>Voter Status</th>
                          <th>Level of Support</th>
                          <th>Contact Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Marvin McKinney</td>
                          <td>1908 E 10 St. Austin TX</td>
                          <td> 1243454323</td>
                          <td>
                            <div>
                              <span className="badge badge-secondary rounded-pill">
                                Not Home
                              </span>

                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div>

                            {/* <span className="d-block text-center"> - </span>  */}
                          </td>
                          <td>
                            {/* <div>
                                <span className="badge badge-secondary rounded-pill">
                                  Strong Support
                                
                                </span> 

                              </div> */}
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td className="text-center">By Phone</td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Marvin McKinney</td>
                          <td>1908 E 10 St. Austin TX</td>
                          <td> 1243454323</td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td>
                            <div>
                              <span className="badge badge-success rounded-pill">
                                Strong Support
                              </span>
                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div>
                          </td>
                          <td className="text-center">By Phone</td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Marvin McKinney</td>
                          <td>1908 E 10 St. Austin TX</td>
                          <td> 1243454323</td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td>
                            <div>
                              <span className="badge badge-success rounded-pill">
                                Strong Support
                              </span>
                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div>
                          </td>
                          <td className="text-center">Door to Door </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Marvin McKinney</td>
                          <td>1908 E 10 St. Austin TX</td>
                          <td> 1243454323</td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td className="text-center">
                            <span className="d-block text-center"> - </span>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Marvin McKinney</td>
                          <td>1908 E 10 St. Austin TX</td>
                          <td> 1243454323</td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td>
                            <div>
                              <span className="badge badge-success rounded-pill">
                                Strong Support
                              </span>
                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div>
                          </td>
                          <td className="text-center">Door to Door </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Marvin McKinney</td>
                          <td>1908 E 10 St. Austin TX</td>
                          <td> 1243454323</td>
                          <td>
                            <div>
                              <span className="badge badge-secondary rounded-pill">
                                Not Home
                              </span>

                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div>
                          </td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td className="text-center">Door to Door </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Marvin McKinney</td>
                          <td>1908 E 10 St. Austin TX</td>
                          <td> 1243454323</td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td className="text-center">
                            <span className="d-block text-center"> - </span>{" "}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Marvin McKinney</td>
                          <td>1908 E 10 St. Austin TX</td>
                          <td> 1243454323</td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td className="text-center">
                            <span className="d-block text-center"> - </span>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Marvin McKinney</td>
                          <td>1908 E 10 St. Austin TX</td>
                          <td> 1243454323</td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td className="text-center">
                            <span className="d-block text-center"> - </span>{" "}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Marvin McKinney</td>
                          <td>1908 E 10 St. Austin TX</td>
                          <td> 1243454323</td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td>
                            <span className="d-block text-center"> - </span>
                          </td>
                          <td className="text-center">
                            <span className="d-block text-center"> - </span>{" "}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={7} className="border-0">
                            <div className="loader-demo-box">
                              <div className="jumping-dots-loader">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {/* <tr>
                            <td colSpan={7} className="border-0">
                              <div className="noData d-flex gap-2 flex-row align-items-center">
                                <InfoIconSecondary />
                                <p className="m-0 text-muted fs-7">
                                  {" "}
                                  No tasks available for today{" "}
                                </p>
                              </div>
                            </td>
                          </tr>  */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Components;

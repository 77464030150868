import { UseMutateFunction } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import Spinner from 'components/common/Spinner'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import toastNotify from 'utils/toastNotify'

interface IDonationLinkSection {
  donorData: any
  isDonorDataFetching: boolean
  postDonorsSubmit: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>
  isPendingDonorPostMutation: boolean
  updateDonorsSubmit: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>
  isPendingDonorUpdateMutation: boolean
}

const DonationLinkSection = ({
  donorData,
  isDonorDataFetching,
  postDonorsSubmit,
  isPendingDonorPostMutation,
  updateDonorsSubmit,
  isPendingDonorUpdateMutation
}: IDonationLinkSection) => {
  const value: string = donorData?.donationUrl || ''

  const [inputValue, setInputValue] = useState<string>('')
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const divRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  //Initial value population & closing due to new update
  useEffect(() => {
    if (value) setInputValue(value)
    if (isEditable) setIsEditable(false)
  }, [value])

  //Add event listener on outside click 
  useLayoutEffect(() => {
    function handleClickOutSideEvent(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsEditable(false)
      }
    }

    if (isEditable) {
      if (value !== inputValue) setInputValue(value)
      if (inputRef.current) inputRef.current.focus()

      document.addEventListener('mousedown', handleClickOutSideEvent)
    } else {
      document.removeEventListener('mousedown', handleClickOutSideEvent)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutSideEvent)
    }
  }, [isEditable])

  function handleSaveEvent() {
    if (value === inputValue) {
      setIsEditable(false)
      return
    }
    if (!(inputValue.startsWith("http://") || inputValue.startsWith("https://"))) {
      toastNotify("error", "Wrong format! Add http or https protocol.")
      return
    }

    if (donorData?.id) {
      updateDonorsSubmit({ donationUrl: inputValue })
      return
    }
    postDonorsSubmit({ donationUrl: inputValue })
  }

  function handleCancelEvent() {
    setIsEditable(false)
  }

  function handleAddEditEvent() {
    setIsEditable(true)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSaveEvent()
      return
    }
    if (e.key === "Escape") {
      handleCancelEvent()
    }
  }

  return (
    <div
      className="row"
      ref={divRef}
    >
      <div className="col-md-4">
        <span className="body-2 fs-7">Donation link</span>
      </div>
      <div className="col-md-8">
        <div className="row  align-items-center justify-content-end">
          <div className="col-md-8">
            {isEditable ? (
              <>
                <input
                  ref={inputRef}
                  type="text"
                  className="form-control"
                  placeholder="Add link"
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </>
            ) : (
              <>
                {value ? (
                  <Link
                    to={value}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {value}
                  </Link>
                ) : (
                  <span className="body-4 text-muted">
                    Not Provided
                  </span>
                )}
              </>
            )}
          </div>
          <div className="col-md-4">
            <div className="d-flex justify-content-end">
              {isEditable ? (
                <>
                  {(isPendingDonorPostMutation || isPendingDonorUpdateMutation || isDonorDataFetching) ? (
                    <Spinner
                      varient='text-primary'
                      size='spinner-border-sm'
                    />
                  ) : (
                    <>
                      <button
                        className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                        onClick={handleCancelEvent}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                        onClick={handleSaveEvent}
                      >
                        Save
                      </button>
                    </>
                  )}
                </>
              ) : (
                <button
                  className="btn btn-link btn-xs px-1 py-0"
                  onClick={handleAddEditEvent}
                >
                  {value ? "Edit" : "Add"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(DonationLinkSection)
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LinkIcon from "img/svg/LinkIcon";
import { useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import { INewsFilterData } from "./DailyNewsClips";
import toastNotify from "utils/toastNotify";

interface INewsObjPropsType {
  newsType: string;
  variant: string;
  newsKey: string;
}

interface INewsDetailType {
  id: number;
  title: string;
  description: string;
  url: string;
  type: "local" | "state" | "national";
  state: string | null;
  publishedAt: string;
  source: string;
  summary: string[];
}

const NewsSlider = ({
  newsTypeData,
  newsFilterData,
  isApplyFiltersClicked,
  setIsApplyFiltersClicked,
  setIsewsFilterSidebarOpen,
  isNewsFiltersApiCalled,
}: {
  newsTypeData: INewsObjPropsType;
  newsFilterData: INewsFilterData;
  isApplyFiltersClicked: boolean;
  setIsApplyFiltersClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setIsewsFilterSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isNewsFiltersApiCalled: boolean;
}) => {
  const authAxios = CreateAuthAxiosInstance();

  const { newsType, variant, newsKey } = newsTypeData;

  const [dailyNewsList, setDailyNewsList] = useState<INewsDetailType[] | null>(
    null
  );

  const [numberOfFiltersAppliedLocal, setNumberOfFiltersAppliedLocal] =
    useState(0);
  const [numberOfFiltersAppliedState, setNumberOfFiltersAppliedState] =
    useState(0);
  const [numberOfFiltersAppliedNational, setNumberOfFiltersAppliedNational] =
    useState(0);
  const [isNewsFiltersCountApplied, setIsNewsFiltersCountApplied] =
    useState(false);

  const {
    data: dailyNewsDataFromApi,
    isFetching: isFetchingDailyNews,
    refetch: refetchDailyNews,
    status,
  } = useQuery({
    queryKey: ["dailyNews", newsKey],
    queryFn: async () => {
      const { localTags, localUrl, nationalTags, stateTags } = newsFilterData;

      const resposne = await authAxios
        .get(
          `api/v1/news?page=1&limit=5&type=${newsKey}${
            newsKey === "local" && localUrl ? "&url=" + localUrl : ""
          }${
            newsKey === "local" && localTags?.length
              ? "&tags=" +
                JSON.stringify(localTags.map((item) => item.value.trim()))
              : newsKey === "state" && stateTags?.length
              ? "&tags=" +
                JSON.stringify(stateTags.map((item) => item.value.trim()))
              : newsKey === "national" && nationalTags?.length
              ? "&tags=" +
                JSON.stringify(nationalTags.map((item) => item.value.trim()))
              : ""
          }`
        )
        .then((data) => {
          if (isApplyFiltersClicked) setIsApplyFiltersClicked(false);
          if (newsKey === "national" && isApplyFiltersClicked) {
            toastNotify("success", "Filters applied successfully");
            setIsewsFilterSidebarOpen(false);
          }
          return data?.data;
        })
        .catch((error) => {
          console.log(error);
          toastNotify("error", error?.response?.data?.message);
        });

      return resposne;
    },
    enabled: !!newsKey && isNewsFiltersApiCalled,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isApplyFiltersClicked) refetchDailyNews();
  }, [isApplyFiltersClicked, refetchDailyNews, newsFilterData]);

  useEffect(() => {
    if (dailyNewsDataFromApi?.items?.length) {
      setDailyNewsList(dailyNewsDataFromApi.items);
    }
  }, [dailyNewsDataFromApi, isFetchingDailyNews]);

  useEffect(() => {
    if (
      isNewsFiltersApiCalled &&
      (isApplyFiltersClicked || !isNewsFiltersCountApplied)
    ) {
      const localFiltersNumber =
        (newsFilterData.localUrl ? 1 : 0) +
        (newsFilterData.localTags?.length ?? 0);
      setNumberOfFiltersAppliedLocal(localFiltersNumber);
      setNumberOfFiltersAppliedState(newsFilterData.stateTags?.length ?? 0);
      setNumberOfFiltersAppliedNational(
        newsFilterData.nationalTags?.length ?? 0
      );
      setIsNewsFiltersCountApplied(true);
    }
  }, [
    isApplyFiltersClicked,
    newsFilterData,
    isNewsFiltersCountApplied,
    isNewsFiltersApiCalled,
  ]);

  if (isFetchingDailyNews) return <NewsLoadingSkeleton />;

  return (
    <div className="card-news-body-item" key={newsKey}>
      <div className="card-news-body-item-header">
        <span
          className={`badge badge-${variant} text-n1 font-regular rounded-pill`}
        >
          {newsType}
        </span>
        {newsKey === "local" && numberOfFiltersAppliedLocal > 0 && (
          <span className="text-muted fs-8 font-regular d-block mt-2">
            Showing results for “{numberOfFiltersAppliedLocal} Filters applied”
          </span>
        )}

        {newsKey === "state" && numberOfFiltersAppliedState > 0 && (
          <span className="text-muted fs-8 font-regular d-block mt-2">
            Showing results for “{numberOfFiltersAppliedState} Filters applied”
          </span>
        )}

        {newsKey === "national" && numberOfFiltersAppliedNational > 0 && (
          <span className="text-muted fs-8 font-regular d-block mt-2">
            Showing results for “{numberOfFiltersAppliedNational} Filters
            applied”
          </span>
        )}
      </div>

      {status === "success" && (
        <>
          {dailyNewsDataFromApi?.items?.length === 0 ? (
            <>
              {/* No news list */}
              <div className="card-news-body-item-failed">
                <div className="noData d-flex gap-2 flex-row justify-content-start">
                  <InfoIconSecondary />
                  <p className="m-0 text-muted fs-7">No News for Today</p>
                </div>
              </div>
            </>
          ) : (
            <Slider {...sliderSettings}>
              {!!dailyNewsList?.length &&
                dailyNewsList.map((item, index) => {
                  const { title, description, url, summary, source } = item;
                  return (
                    <div key={index}>
                      <div className="card-news-body-item-body">
                        <span className="d-flex gap-1 align-items-baseline">
                          <span>
                            <LinkIcon />
                          </span>
                          {url ? (
                            <a
                              className="news-title text-primary"
                              href={url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {title || "N/A"}
                            </a>
                          ) : (
                            <span className="news-title text-primary border">
                              {title || "N/A"}
                            </span>
                          )}
                        </span>
                        <span className="fs-8 text-muted font-semibold ps-3 text-capitalize">
                          {source}
                        </span>
                        <ul className="card-news-content-list mt-2 mb-0">
                          {!!summary?.length ? (
                            summary.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))
                          ) : (
                            <li>{description}</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          )}
        </>
      )}

      {status === "error" && (
        <>
          <div className="card-news-body-item-failed">
            <div className="noData d-flex gap-2 flex-row align-items-center">
              <InfoIconSecondary />
              <p className="m-0 text-muted fs-7">News loading failed.</p>
              <button
                className="btn btn-link no-hover btn-xs"
                onClick={() => refetchDailyNews()}
              >
                Retry
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NewsSlider;

const sliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: "linear",
  infinite: true,
  mobileFirst: true,
  dots: true,
  autoplay: true,
  autoplaySpeed: 7000,
  arrows: false,
  responsive: [
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
};

export const NewsLoadingSkeleton = () => {
  return (
    <div className="card-news-body-item">
      <div className="card-news-body-item-header">
        {/* news category placeholder */}
        <div className="placeholder-glow w-100">
          <div className="placeholder col-6 pt-4"></div>
        </div>
      </div>
      <div>
        <div className="card-news-body-item-body">
          {/* news title placeholder */}
          <div className="placeholder-glow w-100">
            <div className="placeholder col-9 mb-2"></div>
          </div>
          {/* news list placeholder */}
          <div className="placeholder-glow w-100">
            <div className="placeholder col-12 pt-5 pb-4 mb-2"></div>
            <div className="placeholder col-12 pt-5 pb-4 mb-2"></div>
          </div>
          {/* news slider dots placeholder */}
          <div className="placeholder-glow w-100 text-center">
            <div className="placeholder col-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

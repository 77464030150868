import { useBudgetDetails } from "./useBudgetDetails";
import Loader from "components/common/Loader";
import IntroSection from "./budget-detail-components/IntroSection";
import BudgetTable from "./budget-detail-components/BudgetTable";
import { IMonthlyBudget } from "./BudgetDetailType";

const BudgetDetails = () => {
  const {
    expenseTableContainerRef,
    expenseCardRef,
    expenseSliderNextRef,
    incomeTableContainerRef,
    incomeCardRef,
    incomeSliderNextRef,
    handlePrevClick,
    handleNextClick,
    isBudgetDetailsLoading,
    budgetDetail,
    budgetDetailsFromApi,
    funraisingData,
    budgetFileType,
    isActBlueIntegrated
  } = useBudgetDetails()

  const expenseData = budgetDetail?.[0];
  const incomeData = budgetDetail?.[1];

  const monthColumnData = expenseData?.monthlyBudgets?.filter(
    (item: IMonthlyBudget) => item.name !== "Pre Signup"
  );

  if (isBudgetDetailsLoading) {
    return (
      <div className="d-flex align-items-stretch">
        <div className="wrapper active-cont d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-stretch">
      <div className="wrapper active-cont">
        <main>
          <div>
            <IntroSection
              monthColumnData={monthColumnData}
              budgetDetailsFromApi={budgetDetailsFromApi}
              funraisingData={funraisingData}
              budgetFileType={budgetFileType}
            />

            <section className="budget-wrapper">
              <BudgetTable
                budgetType="Expenses"
                tableContainerRef={expenseTableContainerRef}
                cardRef={expenseCardRef}
                sliderNextRef={expenseSliderNextRef}
                handlePrevClick={() => handlePrevClick(expenseTableContainerRef, expenseCardRef, expenseSliderNextRef)}
                handleNextClick={() => handleNextClick(expenseTableContainerRef, expenseCardRef, expenseSliderNextRef)}
                monthColumnData={monthColumnData}
                budgetDetail={expenseData}
              />

              <div className="row mt-5" />

              <BudgetTable
                budgetType="Income"
                tableContainerRef={incomeTableContainerRef}
                cardRef={incomeCardRef}
                sliderNextRef={incomeSliderNextRef}
                handlePrevClick={() => handlePrevClick(incomeTableContainerRef, incomeCardRef, incomeSliderNextRef)}
                handleNextClick={() => handleNextClick(incomeTableContainerRef, incomeCardRef, incomeSliderNextRef)}
                monthColumnData={monthColumnData}
                budgetDetail={incomeData}
                isActBlueIntegrated={isActBlueIntegrated}
              />
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default BudgetDetails;

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import { IDonorInfo } from "type/dashboard";
import { Link } from "react-router-dom";
import ThreeDots from "img/svg/threeDots";
import { IDonorList } from "type/fundraise";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useInfiniteQuery } from "@tanstack/react-query";
import { CANDIDATE_ID } from "../../Homepage";
import { IReducerState } from "./DonorTableTabV2";
import EditDonorModalV2 from "./EditDonorModalV2";
import DeleteDonorModalV2 from "./DeleteDonorModalV2";

const MIN_SEARCH_LENGTH: number = 3

export interface ISelectedDonor extends IDonorInfo {
  id: number
}

function generateFinalUrl(
  candidateId: string | null,
  page: number,
  donorState: IReducerState
) {
  if (!(candidateId && page && donorState)) return ''

  const baseUrl = `api/v1/donor/candidate/${candidateId}?page=${page}`
  const params = []

  if (donorState?.searchInput && donorState.searchInput.length >= MIN_SEARCH_LENGTH)
    params.push(`nameOrPhone=${donorState.searchInput}`)

  if (donorState?.donorType) {
    params.push(`donorType=${donorState.donorType}`);
  }

  if (donorState?.donorStatus) {
    params.push(`status=${donorState.donorStatus}`);
  }

  if (donorState?.donorSource) {
    params.push(`donorSource=${donorState.donorSource}`);
  }

  if (donorState?.order) {
    params.push(`order=${donorState.order}`);
  }

  return `${baseUrl}&${params.join('&')}`
}

const DonorsTableDataV2 = ({
  title,
  donorState
}: {
  title: string;
  donorState: IReducerState
}) => {
  const authAxios = CreateAuthAxiosInstance();
  const localCandidateId = localStorage.getItem(CANDIDATE_ID);

  const [donorList, setDonorList] = useState<IDonorList[]>()
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const targetItemRef = useRef<HTMLTableRowElement>(null)
  const prevTextCountRef = useRef<number>(0)

  const [selectedDonor, setSelectedDonor] = useState<ISelectedDonor | null>(null)

  const {
    data: donorListData,
    error: errorDonorListData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status: volunteerDataStatusInfinite,
    refetch
  } = useInfiniteQuery({
    queryKey: ['donorList', title],
    queryFn: ({ pageParam }) => authAxios(
      generateFinalUrl(localCandidateId, pageParam, donorState)
    )
      .then(data => data?.data),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      const hasNextPage = lastPage?.page * lastPage?.perPage < lastPage?.total

      return hasNextPage ? lastPage?.page + 1 : null
    },
    retry: false,
    enabled: Boolean(title && localCandidateId && donorState)
  })

  //Initial & futher data population
  useLayoutEffect(() => {
    if (donorListData) {
      handleVolunteerListInfinite()
    }
  }, [donorListData])

  function handleVolunteerListInfinite() {
    if (!donorListData?.pages?.length) return

    const tempVolunteerList = []
    for (let obj of donorListData?.pages) {
      const tempItemArray = obj?.result
      if (tempItemArray?.length) {
        tempVolunteerList.push(...tempItemArray)
      }
    }
    setDonorList(tempVolunteerList)
  }

  //Debounce for search
  useEffect(() => {
    const currentTextLength = donorState?.searchInput?.length
    const prevTextLength = prevTextCountRef.current
    const isTextDeleted = prevTextLength > currentTextLength

    if (isTextDeleted && currentTextLength === 0) {
      refetch()
      return
    }

    const refetchTimer = setTimeout(() => {
      if (
        (currentTextLength >= MIN_SEARCH_LENGTH) ||
        (isTextDeleted && prevTextLength >= MIN_SEARCH_LENGTH)
      ) {
        refetch()
      }
      prevTextCountRef.current = currentTextLength
    }, 500)

    return () => clearTimeout(refetchTimer)
  }, [donorState?.searchInput])

  //Refetch for other filters
  useEffect(() => {
    refetch()
  }, [
    donorState?.donorStatus,
    donorState?.donorSource,
    donorState?.order
  ])

  //Intersection observer for inifinite scroll
  useEffect(() => {
    const options = {
      root: tableContainerRef.current,
      rootMargin: "0px",
      threshold: .75,
    }
    const observer = new IntersectionObserver(
      (entries, observer) => {
        if (entries?.[0]?.isIntersecting && !isFetching && hasNextPage) {
          fetchNextPage()
        }
      },
      options
    )

    if (targetItemRef.current) observer.observe(targetItemRef.current)

    return () => observer.disconnect()
  }, [donorList?.length])

  function ErrorStatus() {
    return (
      <tr>
        <td colSpan={7} className="border-0">
          <div className="d-flex justify-content-center gap-2 flex-row align-items-center mt-5">
            <div className="centeredXY noNetwork text-center justify-content-around">
              <div className="noNetwork-header">
                <h1 className="mt-6 text-danger">Error occur !</h1>
                <p className="text-muted">
                  Could not get the data. Please check your network
                </p>

                <div className="mt-4">
                  <Link
                    to="" className="fw-bold"
                    onClick={() => refetch()}
                  >
                    Try Again
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    )
  }

  function showNoDataOrErrorStatus() {
    if (isFetching) return

    if (errorDonorListData) return <ErrorStatus />
    if (donorListData && !donorList?.length) return <NoDataStatus />
  }

  const handleDonorSelection = (donor: IDonorList) => {
    const tempDonor = {
      id: donor?.id || 0,
      name: donor?.name || '',
      email: donor?.email || undefined,
      phoneNumber: donor?.phoneNumber || ''
    }

    setSelectedDonor(tempDonor)
  }

  return (
    <>
      <div
        className="card card-fullHeight scroll-y mt-3 shadow-4 border-1 card-fundraise"
        ref={tableContainerRef}
      >
        {/* table */}
        <table className="table table-full table-eq-height table-hover">
          <thead>
            <tr>
              <th>
                <input type="checkbox" />
              </th>
              <th >Name</th>
              <th>Source</th>
              <th>Donor Type</th>
              <th> Status</th>
              <th>Donation Amount</th>
              <th>Action</th>
            </tr>
          </thead>

          {(isFetching && !isFetchingNextPage) ? (
            <LoadingAnimation />
          ) : (
            <>
              <tbody>
                {donorList?.map((donor, index) => (
                  <tr
                    key={donor?.id || 0}
                    ref={index === donorList?.length - 1 ? targetItemRef : null}
                  >
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>{donor?.name || ''}</td>
                    <td>
                      {donor?.createdBy ? donor.createdBy : "Personal Network"}
                    </td>
                    <td>{donor?.donorType ? donor.donorType : "Prospects"}</td>
                    <td>
                      {donor?.status ? (
                        <div>
                          <span className="badge badge-secondary rounded-pill">
                            {donor.status}
                          </span>

                          <span className="d-block fs-10 text-muted ">
                            Last attempt:
                            {new Date(donor?.updatedAt).toLocaleDateString()}
                          </span>
                        </div>
                      ) : (
                        <span className="d-block"> - </span>
                      )}
                    </td>
                    <td>
                      {donor?.totalDonationAmount ? (
                        <div>
                          <span className="body-5">
                            ${donor?.totalDonationAmount || 0}
                          </span>
                          <span className="d-block fs-10 text-muted ">
                            Last attempt:
                            {new Date(donor?.updatedAt).toLocaleDateString()}
                          </span>
                        </div>
                      ) : (
                        <span className="d-block"> - </span>
                      )}
                    </td>
                    <td className="text-center">
                      <Link
                        className="px-3 py-2"
                        to=""
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <ThreeDots />
                      </Link>

                      <div className="dropdown-menu">
                        <div className="dropdown-group">
                          <ul className="dropdown-body list-unstyled mb-0">
                            <li>
                              <Link
                                to=""
                                data-bs-toggle="modal"
                                data-bs-target='#editDonorModalV2'
                                onClick={() => handleDonorSelection(donor)}
                              >
                                Edit Donor
                              </Link>
                            </li>
                            <li>
                              <Link
                                to=""
                                data-bs-toggle="modal"
                                data-bs-target={'#deleteDonorModalV2'}
                                onClick={() => handleDonorSelection(donor)}
                              >
                                Delete Donor
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          )}

          {isFetchingNextPage && <LoadingAnimation />}
          {showNoDataOrErrorStatus()}
        </table>
      </div>

      <EditDonorModalV2
        selectedDonor={selectedDonor}
        setSelectedDonor={setSelectedDonor}
      />
      <DeleteDonorModalV2
        selectedDonor={selectedDonor}
        setSelectedDonor={setSelectedDonor}
      />
    </>
  );
};
export default React.memo(DonorsTableDataV2)

function NoDataStatus() {
  return (
    <tr>
      <td colSpan={7} className="border-0">
        <div className="noData d-flex gap-2 flex-row align-items-center">
          <InfoIconSecondary />
          <p className="m-0 text-muted fs-7">
            No data available
          </p>
        </div>
      </td>
    </tr>
  )
}

function LoadingAnimation() {
  return (
    <tr>
      <td colSpan={7} className="border-0">
        <div className="loader-demo-box">
          <div className="jumping-dots-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </td>
    </tr>
  )
}


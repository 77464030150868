import React from "react";
import { Link } from "react-router-dom";

const PromptSuggestions = () => {
  return (
    <div className="d-flex h-100">
      <ul className="list-unstyled suggestion-box align-self-end mb-0">
        <li className="suggestion-box-item">
          <Link to="">
            What in person events do you have for a political fundraiser?
          </Link>
        </li>
        <li className="suggestion-box-item">
          <Link to="">What is a vote goal and how do I calculate it?</Link>
        </li>
        <li className="suggestion-box-item">
          <Link to="">
            Write a press release to highlight my top policy positions.
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default PromptSuggestions;

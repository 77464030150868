import React from 'react'

const CheckRectSmallIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M4 3.33H12C12.37 3.33 12.67 3.62997 12.67 4V12C12.67 12.37 12.37 12.67 12 12.67H4C3.62997 12.67 3.33 12.37 3.33 12V4C3.33 3.62997 3.62997 3.33 4 3.33ZM2 4C2 2.89543 2.89543 2 4 2H12C13.1046 2 14 2.89543 14 4V12C14 13.1046 13.1046 14 12 14H4C2.89543 14 2 13.1046 2 12V4ZM11.4702 6.47023C11.7299 6.21053 11.7299 5.78947 11.4702 5.52977C11.2105 5.27008 10.7895 5.27008 10.5298 5.52977L7 9.05955L5.47023 7.52977C5.21053 7.27008 4.78947 7.27008 4.52977 7.52977C4.27008 7.78947 4.27008 8.21053 4.52977 8.47023L6.17622 10.1167C6.63118 10.5716 7.36882 10.5716 7.82378 10.1167L11.4702 6.47023Z" fill="#858599"/>
</svg>
  )
}

export default CheckRectSmallIcon

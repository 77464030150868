import SideNavbar from "components/common/navbar/SideNavbar";
import Fundraise from "components/pages/Fundraise/Fundraise";
import Homepage from "components/pages/Homepage";
import Login from "components/pages/Login";
import Onboarding from "components/pages/Onboarding";
import Signup from "components/pages/Signup";
import ActBlueContribution from "components/pages/actblue-test";
import Communications from "components/pages/communications";
import Profile from "components/pages/profile/Profile";
import ContactPlan from "components/pages/voters/contact-plan/ContactPlan";
import ContactbyPhone from "components/pages/voters/contact-plan/ContactbyPhone";
import DoortoDoor from "components/pages/voters/door-to-door/DoortoDoor";
import VotersDashboard from "components/pages/voters/voters-dashboard/VotersDashboard";
import VotersList from "components/pages/voters/voters-list/VotersList";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import VolunteerList from "components/pages/volunteers/VolunteerList";
import ContactDonor from "components/pages/Fundraise/contact-donor/ContactDonor";
import ProfessionalDetails from "components/pages/profile/professional-details/ProfessionalDetails";
import { useAuth0 } from "@auth0/auth0-react";
import Analytics from "components/pages/analytics";
import ConversationalAITemplate from "templates/candidate/ConversationalAI";
import CandidateProfile from "templates/profile/CandidateProfile";
import ContactByPhoneCall from "templates/voters/ContactByPhoneCall";
import Components from "templates/Components";
import DashboardTemplate from "templates/candidate/Dashboard";
import Dashboard from "components/pages/dashboard/Dashboard";
import AccountDeleted from "components/pages/profile/personal-details/AccountDeleted";
import TopNavbar from "components/common/navbar/TopNavbar";
import { useEffect } from "react";
import VolunteerListTemplate from "templates/Volunteer/VolunteerList";
import AnalyticsDashboard from "templates/analytics/AnalyticsDashboard";
import VoterDashboard from "templates/voters/VoterDashboard";
import TurfDetailViewTemplate from "templates/voters/TurfDetailView";
import TurfDetail from "components/pages/voters/voters-dashboard/turf/turf-detail/TurfDetail";
import { TurfDetailsProvider } from "components/pages/voters/voters-dashboard/turf/turf-detail/useTurfDetail";
import VoterContactPlan from "templates/voters/VoterContactPlan";
import BudgetOverview from "components/pages/budget/budget-overview/BudgetOverview";
import BudgetDetails from "components/pages/budget/budget-detail/BudgetDetails";
import BudgetNoDetails from "templates/budget/BudgetNoDetails";
// import BudgetOverview from "templates/budget/BudgetOverview";
import EmailVerification from "components/pages/profile/personal-details/email-verifications";
import BudgetDetailsTemplate from "templates/budget/BudgetDetailsTemplate";
import BudgetOverviewTemplate from "templates/budget/BudgetOverviewTemplate";
import AddVoter from "templates/candidate/AddVoter";
import AddDonor from "templates/candidate/AddDonor";
import YourStory from "templates/candidate/YourStory";
import CampaignInformation from "templates/candidate/CampaignInformation";
import Ideology from "templates/candidate/Ideology";
import AddBudget from "templates/candidate/AddBudget";
import ExpertList from "templates/expert/ExpertList";
import FundraiseList from "templates/fundraise/FundraiseList";
import ContactDonorTemplating from "templates/fundraise/ContactDonor";




export const IS_AUTHENTICATED = "isAuthenticated";
const ProtectedRoutes = () => {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();

  const isAuthenticatedLocalStorage = localStorage.getItem(IS_AUTHENTICATED);

  const isAuthenticatedUser = isAuthenticatedLocalStorage
    ? JSON.parse(isAuthenticatedLocalStorage)
    : isAuthenticated;

  if (isAuthenticated) {
    localStorage.setItem(IS_AUTHENTICATED, JSON.stringify(true));
  }

  useEffect(() => {
    const checkTokenExpiry = async () => {
      if (isAuthenticated) {
        try {
          const idTokenClaims = await getIdTokenClaims();
          const exp = idTokenClaims?.exp;
          if (exp) {
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);
            if (currentTimeInSeconds >= exp) {
              navigate("/login");
              console.log("Token is expired");
            }
          }
        } catch (error) {
          console.error("Error fetching token claims:", error);
        }
      }
    };
    checkTokenExpiry();
  });

  return isAuthenticatedUser ? <Outlet /> : <Navigate to="/login" replace />;
};

const AppLayout = () => {
  return (
    <>
      <TopNavbar />
      <SideNavbar />
      {/* <Outlet /> */}
    </>
  );
};

const DataRouter = () => {
  const router = createBrowserRouter([
    // Public routes
    {
      path: "/login",
      element: <Login />,
    },

    {
      path: "/signup",
      element: <Signup />,
    },

    {
      path: "/account-deleted",
      element: (
        <>
          <TopNavbar />
          <AccountDeleted />
        </>
      ),
    },
    {
      path: "/",
      element: (
        <>
          <TopNavbar />
          <Homepage />
        </>
      ),
    },
    {
      path: "/email-verification",
      element: (
        <>
          <TopNavbar />
          <EmailVerification />
        </>
      ),
    },

    // Protected routes
    {
      path: "/onboarding",
      element: (
        <>
          <ProtectedRoutes />
          <TopNavbar />
          <Onboarding />
        </>
      ),
    },
    // Protected routes with App layout i.e topnavbar and sidenavbar
    {
      element: (
        <>
          <ProtectedRoutes />
          <AppLayout />
        </>
      ),
      children: [
        // Components

        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/analytics",
          element: <Analytics />,
        },
        {
          path: "/voters/dashboard",
          element: <VotersDashboard />,
        },
        {
          path: "/voters/list",
          element: <VotersList />,
        },
        {
          path: "/voters/contact-plan",
          element: <ContactPlan />,
        },

        {
          path: "voters/contact-plan/contact-by-phone",
          element: <ContactbyPhone />,
        },
        {
          path: "voters/contact-plan/door-to-door",
          element: <DoortoDoor />,
        },
        {
          path: "/Fundraise",
          element: <Fundraise />,
        },
        {
          path: "/donors/contact-donor",
          element: <ContactDonor />,
        },
        {
          path: "/communication",
          element: <Communications />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/volunteers",
          element: <VolunteerList />,
        },
        {
          path: "/settings",
          element: <ProfessionalDetails />,
        },
        {
          path: "/contribution-test",
          element: <ActBlueContribution />,
        },
        {
          path: "/voters/turf",
          element: (
            <TurfDetailsProvider>
              <TurfDetail />
            </TurfDetailsProvider>
          ),
        },
        {
          path: "/budget/overview",
          element: <BudgetOverview />,
        },
        {
          path: "/budget/detail",
          element: <BudgetDetails />,
        },

        //Templates
        {
          path: "/template/profile",
          element: <CandidateProfile />,
        },
        {
          path: "/template/candidate/coversational-ai",
          element: <ConversationalAITemplate />,
        },

        {
          path: "/template/voters/contact-by-phone-call",
          element: <ContactByPhoneCall />,
        },
        {
          path: "/template/components",
          element: <Components />,
        },
        {
          path: "/template/candidate/dashboard",
          element: <DashboardTemplate />,
        },
        {
          path: "/template/volunteers/VolunteerList",
          element: <VolunteerListTemplate />,
        },
        {
          path: "templates/analytics/AnalyticsDashboard",
          element: <AnalyticsDashboard />,
        },

        // <Route path="/template/candidate/dashboard" element={<Dashboard />} />

        {
          path: "/account-deleted",
          element: <AccountDeleted />,
        },

        {
          path: "template/voters/voters-dashboard",
          element: <VoterDashboard />,
        },
        {
          path: "template/voters/turf",
          element: <TurfDetailViewTemplate />,
        },
        {
          path: "template/voters/VoterContactPlan",
          element: <VoterContactPlan />,
        },
        {
          path: "template/budget/budget-details",
          element: <BudgetDetailsTemplate/>
        },
        {
          path: "template/budget/no-budget-details",
          element: <BudgetNoDetails />,
        },
       
        {
          path: "template/budget/budget-overview",
          element: <BudgetOverviewTemplate />,
        },
        {
          path: "/template/candidate/add-voter",
          element: <AddVoter />,
        },
        {
          path: "/template/candidate/add-donor",
          element: <AddDonor />,
        },
        {
          path: "/template/candidate/your-story",
          element: <YourStory />,
        },
        {
          path: "/template/candidate/campaing-information",
          element: <CampaignInformation />,
        },
        {
          path: "/template/candidate/ideology",
          element: <Ideology />,
        },
        {
          path: "/template/candidate/add-budget",
          element: <AddBudget />,
        },
        {
          path: "/template/experts/list",
          element: <ExpertList />,
        },
        {
          path: "template/fundraise/FundraiseList",
          element: <FundraiseList />,
        },
        {
          path: "template/fundraise/ContactDonor",
          element: <ContactDonorTemplating />,
        },

        // import ContactDonor from "templates/fundraise/ContactDonor";

      ]

    },
  ]);
  return <RouterProvider router={router} />;
};

export default DataRouter;


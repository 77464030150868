import LogoMark from "img/svg/logoMark";

const AccountDeleted = () => {
  return (
    <main className="content">
      <div className="container">
        <div className="row h-100 justify-content-center">
          <div className="col-md-6">
            <div className="centeredXY noNetwork text-center justify-content-around">
              <div className="noNetwork-header">
                <LogoMark />
                <h1 className="mt-6">Account Deleted</h1>
                <p className="text-muted">
                  Thanks for trying our app! We're sad to see you go. Share any
                  thoughts you have we're here for it!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AccountDeleted;

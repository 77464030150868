import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import { globalQueryConfig } from "utils/reactQuery";
import { ReactNode, createContext, useContext, useState } from "react";
import toastNotify from "utils/toastNotify";
import {
  getCurrentTimeFormatted,
  getCurrentTimeStamp,
} from "utils/getDateTime";
import { IPostActionProps } from "./SocialMediaHandle";
import { queryClient } from "index";
import { ICandidatePost } from "type/dashboard";

interface IPrerequisiteData {
  hasCampaignWebsite: boolean;
  hasInvitedVolunteers: boolean;
  hasUploadedDonorsList: boolean;
  hasUploadedVotersList: boolean;
  donorCallsCountInLast7Days: number;
  postApprovedOrRejectedCount: number;
  socialMediaPostsCount: number;
  socialMediaPostsCountInLast7Days: number;
  voterCallsCountInLast7Days: number;
  voterCanvassingCountInLast7Days: number;
  connectedSocialMedias: string[];
}
interface ITopPrioritiesData {
  prerequisitesTasks: {
    title: string;
    isCompleted: boolean;
    redirectUrl: string;
    description: string;
  }[];
  isTopprioritiesFlagsLoading: boolean;
  persistingTasks: (
    | {
        title: string;
        count: number | undefined;
        isModal: boolean;
        description: string;
        redirectUrl?: undefined;
      }
    | {
        title: string;
        count: number | undefined;
        redirectUrl: string;
        description: string;
        isModal?: undefined;
      }
  )[];
  todaysPosts: ICandidatePost[] | undefined;
  totalSocialMediaPostsCount: number | undefined;
  postApprovedOrRejectCount: number | undefined;
  isTwitterConnected: boolean;
  isFacebookConnected: boolean;
  submitPostData: UseMutateFunction<void, Error, IPostActionProps, unknown>;
  isPostSubmitting: boolean;
  postSubmitStatus: string | null;
  setDaysUntilElection: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  systemDefinedAndManuallyAddedTask?: ISystemAndManuallyAddedData;
  postSystemAndManualTask: UseMutateFunction<
    void,
    Error,
    ISystemAndManualPostData,
    unknown
  >;
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  updateSystemAndManualTask: UseMutateFunction<
    void,
    Error,
    ISystemAndManualPostData,
    unknown
  >;
  isEditable: boolean;
  isSystemAndManualTaskPosting: boolean;
  setCandidateTaskName: React.Dispatch<React.SetStateAction<string>>;
  candidateTaskName: string;
  setCandidateTaskDueDate: React.Dispatch<React.SetStateAction<string>>;
  candidateTaskDueDate: string;
  isSystemAndManualTaskUpdating: boolean;
  deleteManualTask: UseMutateFunction<void, Error, number, unknown>;
  setCurrentSocialMedia: React.Dispatch<React.SetStateAction<string>>;
  currentSocialMedia: string;
  setEditedTaskId: React.Dispatch<React.SetStateAction<number | undefined>>;
  editedTaskId: number | undefined;
}

interface ISystemAndManualPostData {
  name?: string;
  dueDate?: string;
  status?: string;
  priorityId?: string;
  id?: string;
}
interface ISystemAndManuallyAddedData {
  candidateAdded: {
    name: string;
    dueDate: string;
    status: string;
    priorityId: string | null;
    id: number;
  }[];
  systemDefined: {
    candidateId: number;
    dueDate: string;
    name: string;
    priorityId: string;
    status: string;
    redirectsTo: string | null;
    question: string | null;
  }[];
}
interface ITopPrioritiesProviderProps {
  children: ReactNode;
}

const TopPrioritiesContext = createContext<ITopPrioritiesData | undefined>(
  undefined
);

export const TopPrioritiesProvider: React.FC<ITopPrioritiesProviderProps> = ({
  children,
}) => {
  const authAxios = CreateAuthAxiosInstance();
  const [postSubmitStatus, setPostSubmitStatus] = useState<string | null>(null);
  const [daysUntilElection, setDaysUntilElection] = useState<
    number | undefined
  >(undefined);
  const [isEditable, setIsEditable] = useState(false);
  const [candidateTaskName, setCandidateTaskName] = useState<string>("");
  const [candidateTaskDueDate, setCandidateTaskDueDate] = useState<string>("");
  const [currentSocialMedia, setCurrentSocialMedia] = useState<string>("");
  const [editedTaskId, setEditedTaskId] = useState<number | undefined>(
    undefined
  );

  async function getTopPrioritiesFlags() {
    try {
      const response = await authAxios.get(
        `/api/v1/priorities/flags?timestamp=${getCurrentTimeFormatted()}`
      );
      return response.data as IPrerequisiteData;
    } catch (error) {
      console.log(error);
      toastNotify("error", "Error fetching data");
    }
  }

  const { data: topPrioritiesFlags, isLoading: isTopprioritiesFlagsLoading } =
    useQuery({
      queryKey: ["prerequisites"],
      queryFn: getTopPrioritiesFlags,
      ...globalQueryConfig,
    });

  const prerequisitesTasks = isTopprioritiesFlagsLoading
    ? []
    : [
        {
          title: "Upload your voter file to the voters tab",
          isCompleted: topPrioritiesFlags?.hasUploadedVotersList ?? false,
          redirectUrl: "/voters/list",
          description: "Add voters for contacting",
        },
        {
          title: "Upload your donor list",
          isCompleted: topPrioritiesFlags?.hasUploadedDonorsList ?? false,
          redirectUrl: "/fundraise",
          description: "Add donors for raising donations",
        },
        {
          title: "Invite volunteer by email",
          isCompleted: topPrioritiesFlags?.hasInvitedVolunteers ?? false,
          redirectUrl: "/volunteers",
          description: "Add volunteer for volunteering",
        },
        // {
        //   title: "Create your campaign website",
        //   isCompleted: topPrioritiesFlags?.hasCampaignWebsite ?? false,
        //   redirectUrl: ''
        // description: "Consult the web design expert",
        // },
      ];

  const persistingTasks = isTopprioritiesFlagsLoading
    ? []
    : [
        {
          title: "Social media posting",
          count: topPrioritiesFlags?.socialMediaPostsCountInLast7Days,
          isModal: true,
          description: `${topPrioritiesFlags?.socialMediaPostsCountInLast7Days} posts in last 7 days`,
        },
        {
          title: "Fundraising: Call Time",
          count: topPrioritiesFlags?.donorCallsCountInLast7Days,
          redirectUrl: topPrioritiesFlags?.hasUploadedDonorsList
            ? "/donors/contact-donor"
            : "/fundraise",
          description: `${topPrioritiesFlags?.donorCallsCountInLast7Days} calls made in last 7 days`,
        },
        {
          title: "Voter Contact: Canvassing",
          count: topPrioritiesFlags?.voterCanvassingCountInLast7Days,
          redirectUrl: topPrioritiesFlags?.hasUploadedVotersList
            ? "/voters/contact-plan/door-to-door"
            : "/voters/list",
          description: `${topPrioritiesFlags?.voterCanvassingCountInLast7Days} doors knocked in last 7 days`,
        },
        {
          title: "Voter Contact: Calls",
          count: topPrioritiesFlags?.voterCallsCountInLast7Days,
          redirectUrl: topPrioritiesFlags?.hasUploadedVotersList
            ? "/voters/contact-plan/contact-by-phone"
            : "/voters/list",
          description: `${topPrioritiesFlags?.voterCallsCountInLast7Days} calls made in last 7 days`,
        },
      ];

  async function getTodaysPosts() {
    try {
      const response = await authAxios.get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/api/v1/posts/todays-posts?timestamp=${getCurrentTimeStamp()}`
      );
      return response?.data as ICandidatePost[];
    } catch (error) {
      console.log(error);
    }
  }

  const {
    data: todaysPosts,
    isLoading: isTodaysPostsLoading,
    refetch: refetchTodaysPosts,
  } = useQuery({
    queryKey: ["todaysPosts"],
    queryFn: getTodaysPosts,
    ...globalQueryConfig,
  });

  async function submitCandidatePostAction(postData: IPostActionProps) {
    try {
      setPostSubmitStatus("Loading");
      const response = await authAxios.post(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/api/v1/candidate-posts?timestamp=${getCurrentTimeStamp()}`,
        postData
      );
      if (response?.status === 201) {
        setPostSubmitStatus(postData?.status);
        toastNotify("success", "Successfully Submitted");
      }
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  }
  const { mutate: submitPostData, isPending: isPostSubmitting } = useMutation({
    mutationFn: submitCandidatePostAction,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["prerequisites"],
      });
      setCurrentSocialMedia("");
      await refetchTodaysPosts();
      setPostSubmitStatus(null);
    },
  });

  async function getSystemAndManuallyAddedTasks() {
    try {
      const response = await authAxios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/priorities/system-and-candidate?daysUntilElection=${daysUntilElection}`
      );
      return response?.data as ISystemAndManuallyAddedData;
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  }

  const {
    data: systemDefinedAndManuallyAddedTask,
    isLoading: isSystemAndManuallyAddedDataLoading,
  } = useQuery({
    queryKey: ["systemAndManuallyAddedTasks"],
    queryFn: getSystemAndManuallyAddedTasks,
    enabled: daysUntilElection ? true : false,
    ...globalQueryConfig,
  });

  async function handleUpdateSystemAndManualTask(
    data: ISystemAndManualPostData
  ) {
    const { id } = data;
    if (id) {
      try {
        setPostSubmitStatus("Loading");
        await authAxios.patch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/priorities/candidate-priorities/${id}`,
          data
        );
        toastNotify("success", "Successfully Submitted");
      } catch (error: any) {
        console.log(error);
        toastNotify("error", error?.response?.data?.message);
      }
    }
  }

  const {
    mutate: updateSystemAndManualTask,
    isPending: isSystemAndManualTaskUpdating,
  } = useMutation({
    mutationFn: handleUpdateSystemAndManualTask,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["systemAndManuallyAddedTasks"],
      });
      setIsEditable(false);
      setCandidateTaskName("");
      setCandidateTaskDueDate("");
    },
  });

  async function handlePostingSystemAndManualTask(
    data: ISystemAndManualPostData
  ) {
    try {
      setPostSubmitStatus("Loading");
      await authAxios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/priorities/candidate-priorities`,
        data
      );
      toastNotify("success", "Successfully Submitted");
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  }

  const {
    mutate: postSystemAndManualTask,
    isPending: isSystemAndManualTaskPosting,
  } = useMutation({
    mutationFn: handlePostingSystemAndManualTask,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["systemAndManuallyAddedTasks"],
      });
      setIsEditable(false);
    },
  });

  async function handleDeleteManualTask(id: number) {
    try {
      setPostSubmitStatus("Loading");
      await authAxios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/priorities/candidate-priorities/${id}`
      );
      toastNotify("success", "Successfully Deleted");
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  }
  const { mutate: deleteManualTask, isPending: isManualTaskDeleting } =
    useMutation({
      mutationFn: handleDeleteManualTask,
      onSuccess: async () => {
        queryClient.invalidateQueries({
          queryKey: ["systemAndManuallyAddedTasks"],
        });
      },
    });
  return (
    <TopPrioritiesContext.Provider
      value={{
        prerequisitesTasks,
        isTopprioritiesFlagsLoading,
        persistingTasks,
        todaysPosts,
        totalSocialMediaPostsCount: topPrioritiesFlags?.socialMediaPostsCount,
        postApprovedOrRejectCount:
          topPrioritiesFlags?.postApprovedOrRejectedCount,
        isTwitterConnected:
          topPrioritiesFlags?.connectedSocialMedias.includes("twitter") ??
          false,
        isFacebookConnected:
          topPrioritiesFlags?.connectedSocialMedias.includes("facebook") ??
          false,
        submitPostData,
        isPostSubmitting,
        postSubmitStatus,
        setDaysUntilElection,
        systemDefinedAndManuallyAddedTask,
        updateSystemAndManualTask,
        postSystemAndManualTask,
        isEditable,
        setIsEditable,
        isSystemAndManualTaskPosting,
        candidateTaskName,
        setCandidateTaskName,
        candidateTaskDueDate,
        setCandidateTaskDueDate,
        isSystemAndManualTaskUpdating,
        deleteManualTask,
        setCurrentSocialMedia,
        currentSocialMedia,
        setEditedTaskId,
        editedTaskId,
      }}
    >
      {children}
    </TopPrioritiesContext.Provider>
  );
};

export const useTopPrioritiesData = () => {
  const context = useContext(TopPrioritiesContext);
  if (!context)
    throw new Error(
      "useTopPrioritiesData hook must be used within the TopPrioritiesContextProvider"
    );
  return context;
};

import React from "react";
import InfoIconPrimary from "../../img/svg/InfoIconPrimary";

const InfoCard = () => {
  return (
    <div className="infoCard">
       <span className="d-none d-md-block"> <InfoIconPrimary /></span>

      <div className="infoCard-content">
        <h6>
          Let's brainstorm some potential sources of donors from your personal
          network
        </h6>
        <ul className="list-unstyled">
          <li>
            <span>
              {" "}
              The most likely people to support your campaign are your family,
              friends, and co-workers.
            </span>
          </li>
          <li>
            <span>
              {" "}
              If you have a list of people who receive your holiday card, upload
              them here.
            </span>
          </li>
          <li>
            <span>
              {" "}
              Upload any lists of members of organizations you are part of.{" "}
            </span>
          </li>
          <li>
            <span>
              Make sure to upload a phone number or email to contact each
              potential donor.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InfoCard;

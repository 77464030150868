import React from 'react'

const UndoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M7.96187 2.26035C8.30901 2.60748 8.30901 3.1703 7.96187 3.51743L4.14597 7.33333L7.96187 11.1492C8.30901 11.4964 8.30901 12.0592 7.96187 12.4063C7.61474 12.7535 7.05193 12.7535 6.70479 12.4063L2.26035 7.96187C1.91322 7.61474 1.91322 7.05193 2.26035 6.70479L6.70479 2.26035C7.05193 1.91322 7.61474 1.91322 7.96187 2.26035Z" fill="#3B3B4F"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 7.33333C2 6.84241 2.39797 6.44444 2.88889 6.44444H12.2222C12.981 6.44444 13.7323 6.59389 14.4333 6.88425C15.1343 7.17461 15.7712 7.6002 16.3077 8.13672C16.8442 8.67323 17.2698 9.31017 17.5602 10.0112C17.8506 10.7122 18 11.4635 18 12.2222C18 12.981 17.8506 13.7323 17.5602 14.4333C17.2698 15.1343 16.8442 15.7712 16.3077 16.3077C15.7712 16.8442 15.1343 17.2698 14.4333 17.5602C13.7323 17.8506 12.981 18 12.2222 18H9.11111C8.62019 18 8.22222 17.602 8.22222 17.1111C8.22222 16.6202 8.62019 16.2222 9.11111 16.2222H12.2222C12.7475 16.2222 13.2677 16.1188 13.753 15.9177C14.2383 15.7167 14.6792 15.4221 15.0506 15.0506C15.4221 14.6792 15.7167 14.2383 15.9177 13.753C16.1188 13.2677 16.2222 12.7475 16.2222 12.2222C16.2222 11.6969 16.1188 11.1768 15.9177 10.6915C15.7167 10.2062 15.4221 9.76523 15.0506 9.39379C14.6792 9.02236 14.2383 8.72772 13.753 8.5267C13.2677 8.32569 12.7475 8.22222 12.2222 8.22222H2.88889C2.39797 8.22222 2 7.82425 2 7.33333Z" fill="#3B3B4F"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 7.33333C2 6.84241 2.39797 6.44444 2.88889 6.44444H12.2222C12.981 6.44444 13.7323 6.59389 14.4333 6.88425C15.1343 7.17461 15.7712 7.6002 16.3077 8.13672C16.8442 8.67323 17.2698 9.31017 17.5602 10.0112C17.8506 10.7122 18 11.4635 18 12.2222C18 12.981 17.8506 13.7323 17.5602 14.4333C17.2698 15.1343 16.8442 15.7712 16.3077 16.3077C15.7712 16.8442 15.1343 17.2698 14.4333 17.5602C13.7323 17.8506 12.981 18 12.2222 18H9.11111C8.62019 18 8.22222 17.602 8.22222 17.1111C8.22222 16.6202 8.62019 16.2222 9.11111 16.2222H12.2222C12.7475 16.2222 13.2677 16.1188 13.753 15.9177C14.2383 15.7167 14.6792 15.4221 15.0506 15.0506C15.4221 14.6792 15.7167 14.2383 15.9177 13.753C16.1188 13.2677 16.2222 12.7475 16.2222 12.2222C16.2222 11.6969 16.1188 11.1768 15.9177 10.6915C15.7167 10.2062 15.4221 9.76523 15.0506 9.39379C14.6792 9.02236 14.2383 8.72772 13.753 8.5267C13.2677 8.32569 12.7475 8.22222 12.2222 8.22222H2.88889C2.39797 8.22222 2 7.82425 2 7.33333Z" fill="#3B3B4F"/>
</svg>
  )
}

export default UndoIcon

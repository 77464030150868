import InfoIconWarning from "img/svg/InfoIconWarning";
import { IAnalyticsAlertProps } from "./interface";
import { DAYS_UNTIL_ELECTION } from "../dashboard/Dashboard";

const AnalyticsAlert: React.FC<IAnalyticsAlertProps> = ({
  daysUntilElection,
}) => {
  const storedDaysUntilElection = localStorage.getItem(DAYS_UNTIL_ELECTION);
  return (
    <div className="row">
      <div className="col-md-12">
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <InfoIconWarning />
          <span className="ms-2">
            {storedDaysUntilElection ?? daysUntilElection} days left until
            Election Day. Analytics available up to election day.
          </span>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsAlert;

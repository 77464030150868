import CustomTooltip from "components/common/CustomTooltip";
import React, {
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import toastNotify from "utils/toastNotify";

interface ICustomScriptProps {
  contentRef: RefObject<HTMLDivElement> | null;
  fetchScriptsData: IScriptDetailPropstype[];
  userType: string;
  contactType: any;
  textLength: number;
  setTextLength: React.Dispatch<React.SetStateAction<number>>;
}

interface IScriptDetailPropstype {
  usageScope: string;
  contactType: string;
  script: string;
}

export const CustomScriptV2 = ({
  contentRef: contentDivRef,
  fetchScriptsData,
  contactType,
  userType,
  textLength,
  setTextLength,
}: ICustomScriptProps) => {
  const dynamicVariableListRef = useRef<string[]>([]);
  const cursorPositionRef = useRef<Selection | null>(null);
  const [isDropDownVisible, setIsDropDownVisible] = useState<boolean>(false);
  const dropDownRef = useRef<HTMLUListElement>(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);

  useEffect(() => {
    //Initial old script population
    const finalScriptDetail = fetchScriptsData?.find(
      (scriptDetail: IScriptDetailPropstype) =>
        scriptDetail.contactType === contactType &&
        scriptDetail.usageScope === userType
    );
    if (
      finalScriptDetail &&
      finalScriptDetail?.script?.length > 0 &&
      contentDivRef?.current
    ) {
      contentDivRef.current.innerHTML = finalScriptDetail.script || "";
    }

    //Create and store dynamic variable list
    if (userType && contactType) {
      dynamicVariableListRef.current = getDynamicVariableList(
        userType,
        contactType
      );
    }
  }, [contentDivRef, fetchScriptsData, userType, contactType]);

  useEffect(() => {
    handleTextChangeEvent(); //Instant change the text count on adding the item

    //Remove the dropdown menu when clicked elsewhere
    const handleClickOutside = (e: MouseEvent) => {
      if (
        dropDownRef.current &&
        !(e.target instanceof Node && dropDownRef.current.contains(e.target))
      ) {
        setIsDropDownVisible(false);
      }
    };
    if (isDropDownVisible) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropDownVisible]);

  const handleKeyDownEvent = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isDropDownVisible) {
      e.preventDefault();

      // Handle arrow key navigation within dropdown
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        setSelectedItemIndex((prevIndex) =>
          e.key === "ArrowDown"
            ? (prevIndex + 1) % dynamicVariableListRef.current.length
            : prevIndex > 0
            ? prevIndex - 1
            : dynamicVariableListRef.current.length - 1
        );
        return;
      }

      // Handle selection when Enter key is pressed
      if (e.key === "Enter") {
        const selectedItem = dynamicVariableListRef.current[selectedItemIndex];
        if (selectedItem) {
          handleDropDownClick(selectedItem);
        }
        return;
      }

      //Close dropdown menu when any key is pressed
      setIsDropDownVisible(false);
      return;
    }

    //Prevent adding data after limit reached
    if (textLength >= 500 && !isAllowedKeyOnTextareaFull(e.key) && !e.ctrlKey) {
      e.preventDefault();
      return;
    }

    //Open dropdown menu when pressed '/' key
    if (e.key === "/") {
      e.preventDefault();
      setSelectedItemIndex(0);
      setIsDropDownVisible(true);

      if (contentDivRef?.current) {
        cursorPositionRef.current = window.getSelection() || null;
      }
    }
  };

  const handleDropDownClick = (item: string) => {
    if (!cursorPositionRef.current) return;

    const selectedTextLength = window.getSelection()?.toString().length || 0;
    const currentDivContentLength =
      contentDivRef?.current?.textContent?.length || 0;

    //Total length of content after the dropdown click event
    const totalLength =
      item.length + currentDivContentLength - selectedTextLength;
    if (totalLength > 500) {
      toastNotify("error", "Text limit exceeded!");
      return;
    }

    //DYNAMIC INSERTION OF THE SELECT OPTION
    const selection = cursorPositionRef.current;
    const range = selection.getRangeAt(0);
    range.deleteContents();

    // Create select element
    const selectElement = document.createElement("select");
    selectElement.setAttribute("disabled", ""); // Add disabled attribute
    selectElement.classList.add("variableSelect"); // Add class "variableSelect"
    // Create option element
    const optionElement = document.createElement("option");
    optionElement.textContent = item; // Set option text
    // Append option element to select element
    selectElement.appendChild(optionElement);

    // Insert the name element at the cursor position
    range.insertNode(selectElement);

    // Move the cursor to the end of the inserted name element
    range.collapse(false);

    // Update the selection
    if (selection.rangeCount > 0) selection.removeAllRanges();
    selection.addRange(range);

    setIsDropDownVisible(false);
  };

  const handleTextChangeEvent = () => {
    if (contentDivRef?.current && !isDropDownVisible) {
      const newTextLength = contentDivRef.current?.textContent?.length || 0;
      setTextLength(newTextLength);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const selectedTextLength = window.getSelection()?.toString().length || 0;
    const clipBoardTextLength =
      e.clipboardData.getData("text").trim().length || 0;
    const currentDivContentLength =
      contentDivRef?.current?.textContent?.length || 0;

    //Total length of context after the paste event
    const totalLength =
      clipBoardTextLength + currentDivContentLength - selectedTextLength;
    if (totalLength > 500) {
      e.preventDefault();
      toastNotify("error", "Text limit exceeded!");
    }
  };

  useLayoutEffect(() => {
    function updateCursorPosition() {
      const selection = window.getSelection();

      if (selection && selection.rangeCount > 0 && contentDivRef?.current) {
        const range = selection.getRangeAt(0);
        range.collapse(false);

        // Get the cursor position relative to the contentDivRef element
        const cursorRect = range.getBoundingClientRect();
        const contentDivRect = contentDivRef.current.getBoundingClientRect();

        const dropDownWidth = dropDownRef.current?.clientWidth || 0;
        const contentDivWidth = contentDivRef.current.clientWidth;

        const relativeX =
          cursorRect.left - contentDivRect.left >= 0
            ? cursorRect.left - contentDivRect.left
            : 0;
        const relativeY =
          cursorRect.top - contentDivRect.top >= 0
            ? cursorRect.top - contentDivRect.top
            : 0;

        const finalRelativeX =
          relativeX + dropDownWidth + 15 < contentDivWidth
            ? relativeX
            : contentDivWidth - dropDownWidth - 15;

        if (dropDownRef.current) {
          dropDownRef.current.style.left = finalRelativeX + 5 + "px";
          dropDownRef.current.style.top = relativeY + 15 + "px";
        }

        // console.log("cursor position: ",
        //   // cursorRect,
        //   // contentDivRect,
        //   // cursorPosition.current,
        //   // range,
        //   cursorRect.left,
        //   contentDivRect.left,
        //   relativeX,
        //   // dropDownWidth,
        //   // contentDivWidth
        // )
      }
    }

    if (isDropDownVisible) {
      updateCursorPosition();
    }
  }, [isDropDownVisible]);

  return (
    <>
      <CustomTooltip content='Input Your Script and use "/" for variables`'>
        <div
          className="textEditor customScript form-control"
          ref={contentDivRef}
          // placeholder={`Input Your Script and use "/" for variables`}
          contentEditable
          suppressContentEditableWarning
          onKeyDown={handleKeyDownEvent}
          onInput={handleTextChangeEvent}
          onPaste={handlePaste}
        ></div>
      </CustomTooltip>

      {isDropDownVisible && (
        <ul
          className="dropdown-menu d-block"
          ref={dropDownRef}
          suppressContentEditableWarning
        >
          {dynamicVariableListRef.current.map((item, index) => (
            <a key={item} href="#" onClick={(e) => e.preventDefault()}>
              <li
                className={`dropdown-item ${
                  index === selectedItemIndex ? "active" : ""
                }`}
                suppressContentEditableWarning
                onClick={() => handleDropDownClick(item)}
              >
                {item}
              </li>
            </a>
          ))}
        </ul>
      )}
    </>
  );
};

const getDynamicVariableList = (userType: string, contactType: string) => {
  const tempList = [
    "Candidate Name",
    "City",
    "Position",
    userType === "volunteer" ? "Volunteer Name" : "",
    contactType === "By Fundraising" ? "Donor Name" : "Voter Name",
  ];
  const finalList = tempList.filter((item) => item);

  return finalList;
};

const isAllowedKeyOnTextareaFull = (key: string) =>
  [
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "/",
  ].includes(key);

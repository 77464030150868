import React from 'react'

const LikeFilledIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M2 10.5001C2 9.67169 2.67157 9.00012 3.5 9.00012C4.32843 9.00012 5 9.67169 5 10.5001V16.5001C5 17.3285 4.32843 18.0001 3.5 18.0001C2.67157 18.0001 2 17.3285 2 16.5001V10.5001Z" fill="#3B3B4F"/>
<path d="M6 10.3335V15.7641C6 16.5216 6.428 17.2141 7.10557 17.5529L7.15542 17.5778C7.71084 17.8555 8.32329 18.0001 8.94427 18.0001H14.3604C15.3138 18.0001 16.1346 17.3272 16.3216 16.3924L17.5216 10.3924C17.7691 9.15477 16.8225 8.00012 15.5604 8.00012H12V4.00012C12 2.89555 11.1046 2.00012 10 2.00012C9.44772 2.00012 9 2.44784 9 3.00012V3.66679C9 4.53227 8.71929 5.3744 8.2 6.06679L6.8 7.93346C6.28071 8.62584 6 9.46797 6 10.3335Z" fill="#3B3B4F"/>
</svg>
  )
}

export default LikeFilledIcon

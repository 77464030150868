import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

function CreateAuthAxiosInstance(baseURL?: string) {
  const { getIdTokenClaims } = useAuth0();

  const authAxios = axios.create({
    baseURL: baseURL || `${process.env.REACT_APP_API_BASE_URL}/`,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  // Add a request interceptor
  authAxios.interceptors.request.use(
    async function (config) {
      const token = await getIdTokenClaims();
      if (token) {
        config.headers.Authorization = `Bearer ${token?.__raw}`;
      }

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return authAxios;
}

export default CreateAuthAxiosInstance;

import InfoIconPrimary from "img/svg/InfoIconPrimary";
import Envelope from "img/svg/envelope";
import React from "react";
import { Link } from "react-router-dom";
import usePersonalDetails from "../usePersonalDetails";

const EmailVerify = () => {
  const { userData, onResendEmail } = usePersonalDetails();
  return (
    <main className="content">
      <div className="container">
        <div className="row h-100 justify-content-center">
          <div className="col-md-6">
            <div className="centeredXY emailVerify text-center justify-content-around">
              <div className="emailVerify-header">
                <Envelope />
                <h1 className="mt-6">Please verify your email</h1>
                <p className="text-muted">
                  We’ve sent a verification link to{" "}
                  <span className="fw-bold">{userData?.email}</span>. Click on
                  the link to verify your email.
                </p>

                <div className="mt-4">
                  <span> Didn’t get an email? </span>{" "}
                  <Link to="" className="fw-bold" onClick={onResendEmail}>
                    Resend
                  </Link>
                </div>
              </div>

              {/* <div className="emailVerify-footer d-flex w-100 bg-white justify-content-between">
                <div>
                  <InfoIconPrimary />{" "}
                  <span className="ms-2">
                    {" "}
                    Want to know about verifying email more?
                  </span>
                </div>
                <Link
                  to=""
                  className=""
                  data-bs-toggle="modal"
                  data-bs-target="#verifyModal"
                >
                  Learn more
                </Link>
              </div> */}
            </div>
          </div>
        </div>

        {/* Modal */}
        <div
          className="modal fade"
          id="verifyModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Verifying email information</h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                <div className="verify-info">
                  <h5> We Will </h5>
                  <ul className="list-unstyled do-info">
                    <li>
                      <span>
                        {" "}
                        Integrate email for content approval & scheduling in
                        Campaign Brain{" "}
                      </span>
                    </li>
                    <li>
                      <span>
                        {" "}
                        Provide contact address to add top donors to potential
                        list.{" "}
                      </span>
                    </li>
                  </ul>

                  <h5> We Will not </h5>
                  <ul className="list-unstyled dont-info">
                    <li>
                      <span> Access your inbox or read your emails</span>
                    </li>
                    <li>
                      <span> Send emails without your express consent </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmailVerify;

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import SideNavbar from '../../components/common/navbar/SideNavbar';
import Stepper from '../components/Stepper';
import FileUpload from '../components/FileUpload';
import CheckSuccess from '../../img/svg/checkSuccess';

const Ideology = () => {

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="row">
            <div className="col-md-4">
              <div className="accountSetup accountSetup-fixed">
                <div className="accountSetup-header">
                  <h5>Account setup list</h5>
                  <p>Setup your account to use Campaign Brain</p>
                </div>
                <div className="accountSetup-body">
                  <Stepper />
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="card card-accountSetup shadow-2">
                <div className="card-title">
                  <h4>Candidate Ideology</h4>
                  
                </div>

                <div className="card-body">
                  <div className="your-story">
                  <form action="">
                    <div className="mb-3">
                    <label className="custom-label">On the political spectrum, where do you put yourself?</label>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="range-wrapper">
                        {/* <input type="range" className="range" min="1" max="5" /> */}
                        <div className="range-slider">
                        <input className="range-slider__range" type="range" min="1" max="5" />
                        
                      </div>

                        <div className="ideology">
                          <div className="ideology-item"> Very Progressive</div>
                          <div className="ideology-item"> Liberal </div>
                          <div className="ideology-item"> Moderate </div>
                          <div className="ideology-item"> Conservative </div>
                          <div className="ideology-item">Very Conservative</div>
                        </div>
                        </div>
                      
                      </div>
                    </div>
                    </div>
                    
                  </form>
                  </div>
                
                </div>

                <div className="card-footer shadow-6 gap-4 d-flex justify-content-end">
                  <Link to="" className="btn btn-link text-primary btn-xs">
                    Not Now{" "}
                  </Link>
                  <Link to="" className="btn btn-primary rounded-pill btn-xs px-6">
                    Finish setup{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </div>
    </div>
  )
}

export default Ideology

import InfoIconWarning from "img/svg/InfoIconWarning";
import SocialMediaHandle from "./SocialMediaHandle";
import { useTopPrioritiesData } from "./useTopPrioritiesData";
import ApprovedIcon from "img/svg/approvedIcon";
import RejectedIcon from "img/svg/rejectedIcon";
import PostCompleted from "templates/candidate/socialMedia/PostCompleted";

const SocialMediaModal = () => {
  const {
    totalSocialMediaPostsCount,
    postApprovedOrRejectCount,
    isTwitterConnected,
    isFacebookConnected,
    todaysPosts,
    postSubmitStatus,
    currentSocialMedia,
  } = useTopPrioritiesData();

  const canShowConnectSocialMediaAlert =
    currentSocialMedia === "Twitter"
      ? !isTwitterConnected
      : currentSocialMedia === "Facebook"
      ? !isFacebookConnected
      : false;
  return (
    <>
      {/* Get Started: Social media post Modal */}
      <div
        className="modal fade"
        id="getStarted"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="taskModal">
              {/* right part */}
              <div className="taskModal-content">
                <div className="modal-header">
                  {todaysPosts && todaysPosts?.length > 0 && (
                    <div className="task-controls text-center">
                      <h5 className="modal-title">
                        Approvals on different posts
                      </h5>
                      <div className="task-staus">
                        {postApprovedOrRejectCount} of{" "}
                        {totalSocialMediaPostsCount} approvals
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="icon-close text-n1 h2"></span>
                  </button>
                </div>

                <div
                  className={`modal-body px-4 ${
                    postSubmitStatus && "disabled"
                  }`}
                >
                  {postSubmitStatus && (
                    <div className="status-btn active">
                      {postSubmitStatus === "Loading" && (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden"></span>
                        </div>
                      )}
                      {postSubmitStatus === "Approved" && (
                        <span className="tag tag-success rounded-pill">
                          <ApprovedIcon /> Post approved{" "}
                        </span>
                      )}
                      {postSubmitStatus === "Rejected" && (
                        <span className="tag tag-reject rounded-pill">
                          <RejectedIcon /> Post rejected
                        </span>
                      )}
                    </div>
                  )}
                  <div className="postSlider-wrapper">
                    {/* Alert */}
                    {canShowConnectSocialMediaAlert && (
                      <div className="col-md-12">
                        <div
                          className="alert alert-warning alert-dismissible fade show"
                          role="alert"
                        >
                          <InfoIconWarning />{" "}
                          <span className="ms-2">
                            Connect social media account to directly post.
                          </span>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          >
                            <span className="icon-close"></span>
                          </button>
                        </div>
                      </div>
                    )}
                    {todaysPosts && todaysPosts?.length > 0 ? (
                      <SocialMediaHandle />
                    ) : (
                      <PostCompleted />
                    )}
                  </div>
                </div>
                {todaysPosts && todaysPosts?.length === 0 && (
                  <div className="modal-footer d-flex justify-content-center">
                    <button
                      className="btn btn-primary btn-xs px-4 rounded-pill"
                      data-bs-dismiss="modal"
                    >
                      Done
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaModal;

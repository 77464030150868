import TrashIcon from "img/svg/TrashIcon";
import FileIcon from "img/svg/fileIcon";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DefaultScript from "./scriptEditor/DefaultScript";
import CustomScript from "./scriptEditor/CustomScript";
import useCandidateScriptDetails from "./candidate-script/useCandidateScriptDetails";
import { useForm } from "react-hook-form";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { getYearMonthDate } from "utils/getDateTime";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoIconDanger from "img/svg/InfoIconDanger";
import { CustomScriptV2 } from "./scriptEditor/CustomScriptV2";


type ScriptTitleSchema = yup.InferType<typeof schema>;

const schema = yup.object({
  title: yup.string().required("Title is a required field"),
});

const VolunteerScript = ({
  userType,
  contactType,
  postedTo,
  editable,
  setEditable,
}: ICandidateScriptProps) => {
  const userId = localStorage.getItem(CANDIDATE_ID);
  const contentRef = useRef<HTMLDivElement>(null);

  const [active, setActive] = useState();

  const {
    postCandidateScriptData,
    patchCandidateScriptData,
    fetchScriptsData,
    scriptId,
    activeScript,
    setActiveScript,
    defaultValueMap,
  } = useCandidateScriptDetails();

  // form validation
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ScriptTitleSchema>({
    resolver: yupResolver(schema) as any,
  });

  const [volunteerTextLength, setVolunteerTextLength] = useState<number>(0);
  
  useEffect(() => {
    if (fetchScriptsData) {
      const activeScript = fetchScriptsData?.find(
        (data: any) =>
          data.contactType === contactType && data.usageScope === "volunteer"
      );
      setActiveScript(activeScript);
      setActive(activeScript?.isActive ?? true);
    }
  }, [fetchScriptsData, active]);
  
  const handleScript = (type: string) => {
    if (type == "default") {
      patchCandidateScriptData({ isActive: true });
    }
    if (type === "custom") {
      patchCandidateScriptData({ isActive: false });
    }
  };

  const handleEditClick = (contactType: string) => {
    setEditable && setEditable(`volunteerScript-${contactType}`);
  };

  useEffect(()=>{
    if(activeScript){
      Object.entries(activeScript).forEach(([key,value])=>{

      if (typeof key === 'string') {
          setValue(key as "title" , value);
        }
      })
    }
  },[fetchScriptsData, activeScript, setValue])

  //check contactType to open form
  const matchContactTypeForForm =
    editable &&
    editable.startsWith("volunteerScript-") &&
    editable.split("-")[1] === contactType;

  // check if candidate form is open
  const isCandidateFormOpen =
    editable?.startsWith("candidateScript-") &&
    editable.split("-")[1] === contactType;

  const defaultValue = defaultValueMap[contactType] || "";

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const customVolunteerScriptPostData = {
          candidateId: Number(userId),
          title: data.title,
          script: contentRef?.current?.innerHTML,
          contactType: contactType,
          usageScope: "volunteer",
        };

        if (!activeScript) {
          // If scriptData doesn't exist, create a new record
          postCandidateScriptData(customVolunteerScriptPostData);
        } else {
          // If scriptData exists, update the existing record
          patchCandidateScriptData(customVolunteerScriptPostData);
        }
        setEditable && setEditable(null);
      })}
    >
      <div className="settings mt-5">
        <div className="settings-header d-flex justify-content-between">
          <div className="">
            <h4> Volunteer </h4>
            <p className="body-4 text-muted">
              Edit or add script for calling voters by candidate.
            </p>
          </div>

          <div>
            {matchContactTypeForForm ? (
              <>
                {" "}
                <button
                  className="btn btn-link btn-xs rounded-pill"
                  onClick={() => setEditable && setEditable(null)}
                >
                  Cancel
                </button>
                <button className="btn btn-link btn-xs rounded-pill" disabled={volunteerTextLength === 0}>
                  Update
                </button>
              </>
            ) : (
              <button
                className="btn btn-link btn-xs px-1 py-0"
                onClick={() => handleEditClick(contactType)}
                disabled={isCandidateFormOpen}
                type="button"
              >
                {" "}
                <i className="icon-edit"> </i> Edit details
              </button>
            )}
          </div>
        </div>

        <div className="settings-body">
          <div className="professional-info">
            <hr className="mt-3 mb-3" />
            <div className="row">
              <div className="col-md-4">
                <span className="body-2 fs-7"> Default Script </span>
              </div>
              <div
                className={matchContactTypeForForm ? "col-md-8" : "col-md-6"}
              >
                {matchContactTypeForForm ? (
                  <>
                    <>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputTitle"
                          className="form-label"
                        >
                          Title
                        </label>
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          value={defaultValue}
                          id="exampleInputTitle"
                          aria-describedby="titleHelp"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputScript"
                          className="form-label"
                        >
                          Script
                        </label>
                        <DefaultScript
                          userType={userType}
                          contactType={contactType}
                          postedTo={postedTo}
                        />
                      </div>
                    </>
                  </>
                ) : (
                  <div className="d-flex flex-column">
                    <div>
                      <span className="body-4"> Voter Contact Script </span>
                      {active === true && (
                        <span className="badge bg-success rounded-pill px-2 font-regular">
                          {" "}
                          Active{" "}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!matchContactTypeForForm && active === false && (
                <div className="col-md-2 text-end">
                  <button
                    className="btn btn-link btn-xs no-hover"
                    onClick={() => handleScript("default")}
                    disabled={isCandidateFormOpen}
                    type="button"
                  >
                    {" "}
                    Activate{" "}
                  </button>
                </div>
              )}
            </div>
            <hr className="mt-3 mb-3" />
            <div className="row">
              <div className="col-md-4">
                <span className="body-2 fs-7"> Custom Script </span>
              </div>
              <div
                className={matchContactTypeForForm ? `col-md-8` : `col-md-6`}
              >
                {!matchContactTypeForForm ? (
                  !activeScript?.script ? (
                    <span className="body-4 text-muted"> Not Provided </span>
                  ) : (
                    <div className="d-flex flex-column">
                      <div>
                        <span className="body-4"> Custom Contact Script </span>
                        {active === false && (
                          <span className="badge bg-success rounded-pill px-2 font-regular">
                            {" "}
                            Active{" "}
                          </span>
                        )}
                      </div>
                      <span className="body-4 text-muted">
                        {" "}
                        Updated on{" "}
                        {activeScript?.updatedAt && 
                          getYearMonthDate(activeScript?.updatedAt)}{" "}
                      </span>
                    </div>
                  )
                ) : (
                  <>
                    {" "}
                    <div className="mb-3">
                      <label htmlFor="exampleInputTitle" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputTitle"
                        aria-describedby="emailHelp"
                        placeholder="Enter a Title for Your Script"
                        maxLength={100}

                        {...register("title")}
                      />
                      {errors.title?.message &&  (
                        <div
                          id="emailHelp"
                          className="form-text text-danger fs-8"
                        >
                          <span className="me-2">
                            <InfoIconDanger />
                          </span>
                          {errors?.title?.message as string}
                        </div>
                      )}  

                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputScript"
                        className="form-label"
                      >
                        Script
                      </label>
                      <div className="position-relative">
                        <CustomScriptV2
                          contentRef={contentRef}
                          contactType={contactType}
                          userType={userType}
                          fetchScriptsData={fetchScriptsData}
                          textLength={volunteerTextLength}
                          setTextLength={setVolunteerTextLength}
                        />
                        <span className="word-count">
                          {" "}
                          {volunteerTextLength} / 500{" "}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-2 text-end">
                {!matchContactTypeForForm &&
                  active === true &&
                  activeScript?.script && (
                    <button
                      className="btn btn-link btn-xs no-hover"
                      onClick={() => handleScript("custom")}
                      type="button"
                      disabled={isCandidateFormOpen}
                    >
                      {" "}
                      Activate{" "}
                    </button>
                  )}
              </div>
            </div>
            <hr className="mt-3 mb-3" />
          </div>
        </div>
      </div>
    </form>
  );
};

export default VolunteerScript;

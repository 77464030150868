import React from 'react'

const DislikeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M8.33358 11.6667H4.36364C3.12467 11.6667 2.31884 10.3628 2.87293 9.25464L5.7896 3.42131C6.07192 2.85667 6.64902 2.5 7.28031 2.5H10.6284C10.7647 2.5 10.9004 2.51671 11.0326 2.54976L14.1669 3.33333M8.33358 11.6667V15.8333C8.33358 16.7538 9.07978 17.5 10.0003 17.5H10.0798C10.4961 17.5 10.8336 17.1625 10.8336 16.7462C10.8336 16.151 11.0098 15.569 11.34 15.0738L14.1669 10.8333V3.33333M8.33358 11.6667H10.0003M14.1669 3.33333H15.8336C16.7541 3.33333 17.5003 4.07952 17.5003 5V10C17.5003 10.9205 16.7541 11.6667 15.8336 11.6667H13.7503" stroke="#3B3B4F" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default DislikeIcon

import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import { useState } from "react";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";
import { globalQueryConfig } from "utils/reactQuery";
import { FILE_OWNER } from "../../../../constant/file";

const useVoterDetails = () => {
  const authAxios = CreateAuthAxiosInstance();
  const [editable, setEditable] = useState<string | null>(null);

  async function getVoterDetail() {
    const response = await authAxios.get(
      "api/v1/profile-setting/candidate-details"
    );
    return await response.data;
  }

  const { data: votersData } = useQuery({
    queryKey: ["voter-detail"],
    queryFn: getVoterDetail,
    ...globalQueryConfig,
  });

  async function fetchVoterFile() {
    const { data } = await authAxios.get(`api/v1/file/file-type/1`);
    const adminDonorFile = data.find(
      (file: any) => file.createdBy === FILE_OWNER.ADMIN
    );
    return adminDonorFile;
  }

  const { data: voterFileData } = useQuery({
    queryKey: ["voterFile"],
    queryFn: fetchVoterFile,
    ...globalQueryConfig,
  });

  async function postVotersData(data: any) {
    const response = await authAxios.post(
      `api/v1/profile-setting/candidate-details`,
      data
    );
    return response.data;
  }

  const { mutate: postVotersSubmit } = useMutation({
    mutationFn: postVotersData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["voter-detail"],
      });
      getVoterDetail();
      toastNotify("success", "Successfully created!");
      reset();
    },
    onError: (error: any) => {
      console.log("on error", error);

      toastNotify("error", error.response.data.message);
    },
  });

  async function updateVotersData(data: any) {
    const response = await authAxios.patch(
      `api/v1/profile-setting/candidate-details`,
      data
    );
    return response.data;
  }

  const { mutate: updateVotersSubmit } = useMutation({
    mutationFn: updateVotersData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["voter-detail"],
      });
      getVoterDetail();
      toastNotify("success", "Changes saved!");
      reset();
    },
    onError: (error: any) => {
      console.log("on error", error);
      toastNotify("error", error.response.data.message);
    },
  });

  const reset = () => {
    setEditable(null);
  };

  const {
    data: userInfo
  } = useQuery({
    queryKey: ['candidateDetailVoiceMailVoter', votersData?.id],
    queryFn: () => authAxios.get(`api/v1/user/info/${votersData?.id}`).then(data => data?.data),
    refetchOnWindowFocus: false,
    enabled: !!(votersData?.id)
  })

  // console.log("voters data---: ", userInfo?.outgoingPhoneVerificationStatus)

  return {
    votersData,
    voterFileData,
    editable,
    setEditable,
    updateVotersSubmit,
    postVotersSubmit,
    userInfo
  };
};

export default useVoterDetails;

import React from 'react'

const ChevronRightFilledIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
<path d="M-5.27643e-08 10.7929C-3.3293e-08 11.2383 0.538571 11.4614 0.853553 11.1464L5.64645 6.35355C5.84171 6.15829 5.84171 5.84171 5.64645 5.64645L0.853553 0.853553C0.538571 0.53857 -4.91244e-07 0.761654 -4.71772e-07 1.20711L-5.27643e-08 10.7929Z" fill="#3B3B4F"/>
</svg>
  )
}

export default ChevronRightFilledIcon

import CustomTooltip from "components/common/CustomTooltip";
import HelpIcon from "img/svg/helpIcon";
import HorizontalBarChart from "./HorizontalBarChart";
import { formatBudgetOverviewDate, getDateForBudgetPage, incrementMonth } from "utils/formatDate";

export interface ICategories {
  categorySum:number,
  name:string
}

export interface IAllocation {
  startDate:string,
  endDate:string,
  totalAllocation:number,
  categories:ICategories[]
}

interface IBudgetAllocation {
  budgetAllocationData : IAllocation
  isFetchingBudgetAlloation:boolean
}

const BudgetAllocation = ({budgetAllocationData,isFetchingBudgetAlloation}: IBudgetAllocation) => {
  // tooltip
  const budgetAllocationTooltip = `Breakdown of total planned expenditure for the entirety of the campaign by category.`;

  return (
    <div className="col-md-6 col-12 mb-3">
      <div className="card card-flush h-100 shadow-2">
        <div className="card-header d-flex justify-content-between">
          <h4 className="card-title"> Budget allocation </h4>
          <div className="card-info">
            <CustomTooltip content={budgetAllocationTooltip}>
              {" "}
              <HelpIcon />{" "}
            </CustomTooltip>
          </div>
        </div>
        <div className="card-body pt-1 d-flex flex-column justify-content-end">
          {/* top placeholder  */}
          {isFetchingBudgetAlloation ? (
            <div className="d-flex flex-column gap-1 mb-4 placeholder-glow">
              <div className="placeholder col-3 py-3"></div>
              <div className="placeholder col-5"></div>
            </div>
          ) : (
            <div className="d-flex flex-column gap-1 mb-4">
              <h1 className="mb-0 me-2">
                ${budgetAllocationData?.totalAllocation || 0}
              </h1>
              <span className="d-block fs-8">
                Before {formatBudgetOverviewDate(budgetAllocationData?.startDate,true)?.formattedDate} -{" "}
                {formatBudgetOverviewDate(budgetAllocationData?.endDate,true)?.formattedDate}
              </span>
            </div>
          )}
          <span className="d-block body-5 mb-0">All Categories</span>
          {/* bottom placeholder  */}
          {isFetchingBudgetAlloation ? (
            <div className="d-flex flex-column gap-1 mb-4 placeholder-glow">
              <div className="placeholder col-12 py-7"></div>
            </div>
          ) : (
            <div
              className="canvas-container"
              style={{ width: "100%!important" }}
            >
              <HorizontalBarChart budgetAllocationData={budgetAllocationData} />
            </div>
          )}

          {/* <p className="m-0 text-muted fs-8 mt-1 fw-bold">
                    <span> 0%</span> to your fundraising goal{" "}
                  </p> */}
        </div>
      </div>
    </div>
  );
};

export default BudgetAllocation;

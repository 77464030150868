import React from "react";
import Loader from "./Loader";

export const LoaderCenter: React.FC = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100%" }}
    >
      <Loader />
    </div>
  );
};

export default LoaderCenter;

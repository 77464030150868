import CustomTooltip from "components/common/CustomTooltip";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import HelpIcon from "img/svg/helpIcon";
import { ManageTurfModal } from "./ManageTurfModal";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AddTurfModal } from "./AddTurfModal";
import { useGetElectionDetail } from "components/hooks/useGetElectionDetail";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useQuery } from "@tanstack/react-query";
import TurfMapPreview from "./TurfMapPreview";
import { IUniverseDetail, useTurfDetail } from "../turf-detail/useTurfDetail";
import { useLocation } from "react-router-dom";

export const TurfCard = React.memo(() => {
  const authAxios = CreateAuthAxiosInstance();
  const { state } = useLocation();

  const [isManageBtnRefClicked, setIsManageBtnRefClicked] = useState<boolean>(false);
  const manageTurfButtonRef = useRef<HTMLButtonElement>(null);

  const { electionDetail, isFetching: isFetchingElectionDetail } = useGetElectionDetail();
  const { setSelectedUniverse } = useTurfDetail();

  const isVoterListAvailable: boolean = Boolean(
    electionDetail?.hasVotersList
    //  || electionDetail?.hasVotersListFileUploaded
  );

  const {
    data: universeList,
    refetch: refetchUniverseList,
    isFetching: isFetchingUniverseList,
  } = useQuery({
    queryKey: ["universeList"],
    queryFn: () => authAxios(`api/v1/universes`).then((data) => data.data),
    refetchOnWindowFocus: false,
  });

  const finalUniverseList = useMemo(() => {
    if (!universeList?.length) return null;

    const defaultObject = universeList.find((item: IUniverseDetail) => item.type === "Default");
    const filteredUniverseList = universeList.filter((item: IUniverseDetail) => item.type !== "Default");

    if (defaultObject) filteredUniverseList.unshift(defaultObject);

    return filteredUniverseList;
  }, [universeList]);

  const activeUniverse: IUniverseDetail = useMemo(() => {
    if (!universeList?.length) return null;

    return universeList.find((item: IUniverseDetail) => item.isActive);
  }, [universeList]);

  const isTurfCardLoading: boolean = isFetchingElectionDetail || isFetchingUniverseList;

  useEffect(() => {
    if (activeUniverse) setSelectedUniverse(activeUniverse);
  }, [activeUniverse]);

  useEffect(() => {
    const showUniverseList = state?.showUniverseList;
    const isManageTurfBtnDisable = isTurfCardLoading || !isVoterListAvailable;
    if (showUniverseList && !isManageBtnRefClicked && !isManageTurfBtnDisable) {
      manageTurfButtonRef?.current?.click();
      setIsManageBtnRefClicked(true);
    }
  }, [state, universeList, isTurfCardLoading]);

  return (
    <>
      <div className="col-md-6 mb-3">
        <div className="card card-flush h-100 shadow-2">
          <div className="card-header">
            <div className="row">
              <div className="col">
                <div className="d-flex gap-2">
                  <h4 className="card-title">Turf</h4>
                  <CustomTooltip
                    content={"Generate lists of voters to reach door-to-door."}
                  >
                    <HelpIcon />
                  </CustomTooltip>
                </div>
                <p className="body-4 text-muted mb-0 lh-1">
                  Manage voter turf and walk lists.
                </p>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-info rounded-pill btn-xs"
                  data-bs-target="#manageTurfModal"
                  data-bs-toggle="modal"
                  disabled={isTurfCardLoading || !isVoterListAvailable}
                  onClick={() => refetchUniverseList()}
                  ref={manageTurfButtonRef}
                >
                  Manage Turf
                </button>
              </div>
            </div>
          </div>
          <div
            className="card-body position-relative"
            style={{ minHeight: "250px" }}
          >
            {isTurfCardLoading ? (
              <>
                {/* map placeholder */}
                <div className="placeholder-glow h-100">
                  <div className="placeholder col-12 h-100"></div>
                </div>
              </>
            ) : (
              <>
                {isVoterListAvailable ? (
                  <>
                    <TurfMapPreview />
                    <div className="universeList-absolute">
                      <div className="universeList border-0 shadow-6 pe-none">
                        <div className="universeList-body">
                          <div className="universeList-title">
                            <span className="body-4">
                              {`${activeUniverse?.type === "Default" ? "Default Universe: " : ""} ${activeUniverse?.name || ''}`}
                            </span>
                          </div>
                          <div className="universeList-info">
                            <span className="fs-8 text-muted">
                              {activeUniverse?.filterType || ""}
                            </span>
                            <span className="dot dot-sm bg-neutral-n3 mx-1"></span>
                            <span className="fs-8 text-muted">
                              Turfs: {activeUniverse?.turfsCount || 0}
                            </span>
                            <span className="dot dot-sm bg-neutral-n3 mx-1"></span>
                            <span className="fs-8 text-muted">
                              Selected Doors: {activeUniverse?.activeUniverseTurfsDoorCount || 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="noData d-flex gap-2 flex-row align-items-center">
                    <InfoIconSecondary />
                    <p className="m-0 text-muted fs-7">
                      No voter list uploaded by admin.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <ManageTurfModal
        universeList={finalUniverseList}
        isFetchingUniverseList={isFetchingUniverseList}
      />

      <AddTurfModal candidateId={Number(electionDetail?.candidateId) || 0} />
    </>
  );
});

import InfoIconSecondary from "img/svg/InfoIconSecondary";
import HelpIcon from "img/svg/helpIcon";
import PhoneOutlineIcon from "img/svg/phoneOutlineIcon";
import { useState, useEffect, useRef } from "react";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { Link } from "react-router-dom";
import { Tooltip } from "bootstrap";
import EyeIcon from "img/svg/eyeIcon";
import WarningIcon from "img/svg/warningIcon";
import axios from "axios";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { LEVEL_OF_SUPPORT, VOTER_STATUS } from "../voters-list/TableFilter";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IVoterContactHistory,
  getYearMonthDateTime,
} from "../door-to-door/SidePanel";

// import { PhoneComponent } from "./call/PhoneComponent";
import TwilioCard from "../phone-number-verification/TwilioCard";
import VerifyNumber from "../phone-number-verification/VerifyNumber";
import { useMutation, useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import CallerId from "./components/CallerId";
import { PhoneComponent } from "./components/PhoneComponent";
import useCandidateScriptDetails from "components/pages/profile/script/candidate-script/useCandidateScriptDetails";

import toastNotify from "utils/toastNotify";
import VerificationDisabled from "../phone-number-verification/VerificationDisabled";
import ContactByPhoneScript from "./components/ContactByPhoneScript";
import { replaceMultipleWords } from "utils/replaceMultipleWords";
import { DAYS_UNTIL_ELECTION } from "components/pages/dashboard/Dashboard";

interface IVotersToContactByPhone {
  id: number;
  name: string;
  address: string;
  partyAffiliation: string;
  age: number | null;
  sex: string;
  phoneNumber: string | null;
  voterContactHistories: IVoterContactHistory[];
}
export interface IVotersResponse {
  voterStatus: string;
  // voterId: number;
  levelOfSupport: string;
  contactStatus: string;
}
export const votersResponseFormSchema = yup.object().shape(
  {
    voterStatus: yup.string().when("levelOfSupport", {
      is: (voterStatus: string) => !voterStatus,
      then: () => {
        return yup
          .string()
          .required("Select either Voter Status or Level of Support");
      },
      otherwise: () => yup.string().notRequired(),
    }),
    levelOfSupport: yup.number().when("voterStatus", {
      is: (voterStatus: string) => !voterStatus,
      then: () => {
        return yup
          .string()
          .required("Select either Voter Status or Level of Support");
      },
      otherwise: () => yup.string().notRequired(),
    }),
  },
  [["voterStatus", "levelOfSupport"]]
);

export const calculateAge = (dob: string) => {
  const dobDate = new Date(dob);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - dobDate.getFullYear();
  // if (
  //   currentDate.getMonth() < dobDate.getMonth() ||
  //   (currentDate.getMonth() === dobDate.getMonth() &&
  //     currentDate.getDate() < dobDate.getDate())
  // ) {
  //   age--;
  // }

  return age;
};

const ContactbyPhone = () => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const userId = localStorage.getItem(CANDIDATE_ID);
  const daysUntilElection = localStorage.getItem(DAYS_UNTIL_ELECTION);

  const [votersToContactByPhone, setVotersToContactByPhone] = useState<
    Array<IVotersToContactByPhone>
  >([]);

  const [contactedVotersCount, setContactedVotersCount] = useState(0);
  const [dailyGoalVotersCount, setDailyGoalVotersCount] = useState(0);

  // const [hasVotersToContactByPhoneLoaded, setHasVotersToContactByPhoneLoaded] =
  //   useState(false);

  const [hasVotersResponseSubmitted, setHasVotersResponseSubmitted] =
    useState(false);

  const [isScriptActive, setIsScriptActive] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  // const [voterId, setVoterId] = useState(0);

  const [selectedVoterStatus, setSelectedVoterStatus] = useState("");
  const [selectedLevelOfSupport, setSelectedLevelOfSupport] = useState("");

  const [isActivePopup, setIsActivePopup] = useState<boolean>(true);

  const [parentCallSId, setParentCallSId] = useState<string | null>(null);

  // fetch script data
  const { fetchScriptsData } = useCandidateScriptDetails();

  const [selectedOption, setSelectedOption] = useState<undefined | string>();

  const [finalScript, setFinalScript] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors },
    // setValue,
    reset,
  } = useForm<IVotersResponse>({
    resolver: yupResolver(votersResponseFormSchema) as any,
  });

  const handleSkip = async () => {
    await submitVoterResponse({
      levelOfSupport: "",
      voterStatus: VOTER_STATUS[0],
      contactStatus: "skipped",
    });
    reset();
  };

  let buttonText = isScriptActive ? "Hide Script" : "Show Script";

  const handleScript = () => {
    setIsScriptActive(!isScriptActive);
  };

  const voterStatusTooltip = `Not Home: the voter did not pick up the phone, or you reached someone besides the target voter. We will try again with this voter soon. <br /> <br /> Moved: the voter no longer lives at this address. We will not target this voter further. <br /> <br /> Deceased: the voter has passed away. We will not target this voter further. <br /> <br /> Wrong Number: the phone number was not valid.. We will target this voter door-to-door instead.`;
  const losTooltip = `Strong Support: The voter clearly communicated they will definitely be supporting you. <br /> <br /> Lean Support:  The voter communicated they will likely be supporting you <br /> <br /> Undecided: The votercommunicated they aren’t sure who they will vote for or they haven’t decided yet. <br /> <br /> Lean Against: The voter communicated they will likely NOT be supporting you. <br /> <br /> Strong Against: The voter clearly communicated they will definitely NOT be supporting you. `;

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  // const IsDailyGoalTasksCompleted =
  //   activeIndex === votersToContactByPhone?.length;

  const authAxios = CreateAuthAxiosInstance();

  const getVoterDetails = async () => {
    try {
      setActiveIndex(0);

      // Get voters based on daily goals
      const dailyGoalResponse = await authAxios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/voter/daily-goal?daysUntilElection=${daysUntilElection}
          `
      );

      if (dailyGoalResponse) {
        const mappedVotersToContactByPhone: IVotersToContactByPhone[] =
          dailyGoalResponse.data.items?.map(
            (item: IVotersToContactByPhone) => ({
              id: item.id,
              name: item.name,
              age: item.age,
              sex:
                item.sex === "F"
                  ? "Female"
                  : item.sex === "M"
                  ? "Male"
                  : item.sex,
              partyAffiliation: item.partyAffiliation,
              address: item.address,
              phoneNumber: item.phoneNumber,
              voterContactHistories: item.voterContactHistories,
            })
          );

        setVotersToContactByPhone(mappedVotersToContactByPhone);
        // setVoterId(mappedVotersToContactByPhone[activeIndex]?.id);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getVoterDetails();
  }, [userId, daysUntilElection]);

  useEffect(() => {
    (async () => {
      try {
        // Get voters count based on daily goals
        const dailyGoalResponse = await authAxios.get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/api/v1/tasks/goals/daily?daysUntilElection=${daysUntilElection}&timestamp=${new Date().toLocaleString(
            "US"
          )}
          `
        );
        setDailyGoalVotersCount(dailyGoalResponse.data.voterCalls);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [userId, daysUntilElection]);

  const getContactedVotersCount = async () => {
    try {
      // Get contacted voters
      const contactedVoterResponse = await authAxios.get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/api/v1/task-stats/todays-completed-task-counts?timestamp=${new Date().toLocaleString(
          "US"
        )}
          `
      );
      setContactedVotersCount(contactedVoterResponse.data.votersCallCount);
      setHasVotersResponseSubmitted(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getContactedVotersCount();
  }, [userId]);

  const submitVoterResponse = async (data: IVotersResponse) => {
    try {
      const voterID = votersToContactByPhone[activeIndex]?.id;
      // setVoterId(voterID)
      // Get not contacted voters
      let tempPayload: any = {
        levelOfSupport: data.levelOfSupport || null,
        status: data.voterStatus || null,
        voterId: voterID,
        contactStatus:
          data.contactStatus ||
          (data.voterStatus === "Not Home" ? "failed" : "completed"),
      };
      if (parentCallSId) {
        tempPayload.parentCallSid = parentCallSId;
      }

      const res = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/voter-contact-history/${latestVoterContactHistoryId.current}`,
        tempPayload
      );
      if (res) {
        toastNotify("success", "Successfully submitted the response");
        setHasVotersResponseSubmitted(true);
        setSelectedOption("");

        await getContactedVotersCount();
        latestVoterContactHistoryId.current = null;
        if (activeIndex === votersToContactByPhone?.length - 1) {
          await getVoterDetails();
        } else {
          setActiveIndex(activeIndex + 1);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const retrievedCandidateId = localStorage.getItem(CANDIDATE_ID);

  const [autoDialerStatus, setAutoDialerStatus] = useState<string | null>(null);
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState<string | null>(
    null
  );

  const { data: candidateInfo, status: candidateInfoStatus } = useQuery({
    queryKey: ["candidateInfo"],
    queryFn: () => authAxios.get(`api/v1/user/info/${retrievedCandidateId}`),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (candidateInfoStatus === "success") {
      const autoDialerStatusFromApi = candidateInfo?.data?.autoDialerStatus;
      const hasSeenAutoDialerStatusPopup =
        candidateInfo?.data?.hasSeenAutoDialerStatusPopup;

      setAutoDialerStatus(autoDialerStatusFromApi || null);
      if (
        autoDialerStatusFromApi === "Integrated" ||
        autoDialerStatusFromApi === "Rejected" ||
        (autoDialerStatusFromApi === "Inactive" && hasSeenAutoDialerStatusPopup)
      ) {
        setIsActivePopup(false);
      }

      setVerifiedPhoneNumber(
        candidateInfo?.data?.phoneNumber?.phoneNumber || null
      );
    }
  }, [candidateInfo, candidateInfoStatus]);

  const handleModalClick = () => setIsActivePopup(true);
  const handleCancelModal = () => setIsActivePopup(false);
  const handleParentCallSId = (acceptCallSId: string | null) => {
    setParentCallSId(acceptCallSId);
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const candidateDetailsResponse = await authAxios.get(
  //         `${process.env.REACT_APP_API_BASE_URL}/api/v1/profile-setting/professional-details`
  //       );
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   })();
  // }, []);

  // adding dynamic value for script variables
  // const replaceMultipleWords = (
  //   inputString: string | undefined,
  //   replacements: { [key: string]: string | undefined }
  // ): string => {
  //   let result = inputString || "";
  //   for (const [wordToReplace, replacement] of Object.entries(replacements)) {
  //     // Check if the replacement is defined
  //     if (replacement !== undefined) {
  //       // Create a regular expression to match the word to be replaced
  //       // const regex = new RegExp("\\b" + wordToReplace + "\\b", "gi");
  //       const regex = new RegExp(`(?<=<select[^>]*>\\s*<option>\\s*)${wordToReplace}(?=\\s*<\/option>\\s*<\/select>)`, "gi");
  //       // Replace all occurrences of the word with the replacement
  //       result = result.replace(regex, replacement);
  //     }
  //   }
  //   return result;
  // };
  console.log("selectedOption,", selectedOption);

  useEffect(() => {
    const filteredscript = fetchScriptsData?.find(
      (item: any) =>
        item.contactType == "By Phone" && item.usageScope === "candidate"
    );

    const script: string = filteredscript?.script;

    if (candidateInfo?.data?.id && votersToContactByPhone?.length) {
      const customScriptMappedObject: { [key: string]: string } = {
        "Voter Name": votersToContactByPhone[activeIndex]?.name ?? "",
        "Candidate Name": candidateInfo?.data.fullName ?? "",
        City: candidateInfo?.data.candidate.elections[0].city ?? "",
        Position:
          candidateInfo?.data.candidate.elections[0].electionPosition ?? "",
      };

      const finalScriptTemp = replaceMultipleWords(
        script,
        customScriptMappedObject
      );
      setFinalScript(finalScriptTemp);
    }
    if (filteredscript?.isActive === true) {
      setSelectedOption("defaultScript");
      return;
    }
    if (filteredscript?.isActive === false) {
      setSelectedOption("customScript");
      return;
    }
    if (!filteredscript?.length) {
      setSelectedOption("defaultScript");
    }
  }, [fetchScriptsData, votersToContactByPhone, activeIndex, candidateInfo]);

  const latestVoterContactHistoryId = useRef<number | null>(null);

  const {
    mutate: postCallConcurrency,
    isPending: isPendingPostCallConcurrency,
  } = useMutation({
    mutationFn: (data: any) =>
      authAxios
        .post(`api/v1/voter-contact-history`, data)
        .then((data) => data?.data),
    onSuccess: (data) => {
      if (data?.id) latestVoterContactHistoryId.current = data.id;
    },
    onError: (error: unknown) => {
      // console.log("on error", error?.response?.data?.message);
    },
  });

  const {
    mutate: patchCallConcurrency,
    isPending: isPendingPatchCallConcurrency,
  } = useMutation({
    mutationFn: (data: any) =>
      authAxios
        .patch(
          `api/v1/voter-contact-history/${latestVoterContactHistoryId.current}`,
          data
        )
        .then((data) => data?.data),
    onSuccess: (data) => {
      // console.log("Concurrency patch response", data);
    },
    onError: (error: unknown) => {
      // console.log("on error", error?.response?.data?.message);
    },
  });

  function handlePatchCallConcurrency(data: any) {
    if (latestVoterContactHistoryId.current) {
      patchCallConcurrency(data);
    }
  }

  //Run when voter detailpage is loaded
  useEffect(() => {
    if (
      votersToContactByPhone[activeIndex]?.id &&
      latestVoterContactHistoryId.current === null &&
      !isPendingPostCallConcurrency
    ) {
      postCallConcurrency({
        voterId: votersToContactByPhone[activeIndex]?.id,
        status: null,
        levelOfSupport: null,
        contactStatus: "viewing",
      });
    }
  }, [votersToContactByPhone, activeIndex]);

  //Run when user move to next page without any interaction in voter detail page
  useEffect(() => {
    return () => {
      // console.log("clean up", latestVoterContactHistoryId.current)
      handlePatchCallConcurrency({ contactStatus: null });
    };
  }, []);

  return (
    <div className="d-flex align-items-stretch">
      <div
        className={
          isActivePopup
            ? "wrapper active-cont active-popup"
            : "wrapper active-cont"
        }
      >
        {candidateInfoStatus !== "success" ? (
          <LoadingAnimation />
        ) : (
          <>
            {isActivePopup && (
              <>
                {(autoDialerStatus === null ||
                  autoDialerStatus === "Rejected") && (
                  <TwilioCard
                    modalStatus={isActivePopup}
                    onClickModal={handleCancelModal}
                  />
                )}
                {autoDialerStatus === "Inactive" && (
                  <VerificationDisabled
                    modalStatus={isActivePopup}
                    onClickModal={handleCancelModal}
                  />
                )}
              </>
            )}
          </>
        )}

        <main className=" border">
          <form
            onSubmit={handleSubmit(async (data) => {
              await submitVoterResponse(data);
              // if (activeIndex < votersToContactByPhone.length)
              //   setActiveIndex(activeIndex + 1);
              setSelectedLevelOfSupport("");
              setSelectedVoterStatus("");
              reset();
            })}
          >
            <div className="container">
              <div className="row">
                <div className="col-9">
                  <BreadCrumbs
                    title="Contact Plan"
                    subtitle={"Contact Voters By Phone"}
                  />
                </div>
                {autoDialerStatus !== "Inactive" && (
                  <>
                    {autoDialerStatus === "Integrated" ? (
                      <div className="col-lg-3 col-12">
                        <CallerId phoneNumber={verifiedPhoneNumber} />
                      </div>
                    ) : (
                      <div className="col-lg-3 col-12">
                        <VerifyNumber onClickModal={handleModalClick} />
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* Daily goal updates */}
              <div className="row justify-content-between mt-3">
                <div className="col-md-5">
                  <div className="alert bg-white shadow-2">
                    <div className="d-flex gap-4 voterGoal">
                      <div className="voter-goal-item">
                        <span className="ms-2">
                          <span className="dot bg-success-s2"> </span>
                          <span className="fw-bold">
                            {contactedVotersCount} of {dailyGoalVotersCount}{" "}
                          </span>
                          Daily voter goal
                        </span>
                      </div>
                      {/* <div className="voter-goal-item">
                      <span className="ms-2">
                        <span className="dot bg-extended-e2"> </span>
                        <span className="fw-bold">1 of 186 </span>
                        Weekly voter goal
                      </span>
                    </div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="d-flex justify-content-end gap-2">
                    {autoDialerStatus !== "Inactive" && (
                      <PhoneComponent
                        receiverType="voter"
                        phoneNumber={
                          votersToContactByPhone[activeIndex]?.phoneNumber ||
                          null
                        }
                        autoDialerStatus={autoDialerStatus}
                        receiverName={
                          votersToContactByPhone[activeIndex]?.name || null
                        }
                        receiverId={
                          votersToContactByPhone[activeIndex]?.id || null
                        }
                        parentCallSId={parentCallSId}
                        handleParentCallSId={handleParentCallSId}
                        callVoterId={votersToContactByPhone[activeIndex]?.id}
                        submitVoterButtonRef={submitButtonRef}
                      />
                    )}

                    <button
                      className="btn btn-info btn-xs rounded-pill"
                      data-bs-toggle="modal"
                      data-bs-target="#skipModal"
                      type="button"
                      disabled={
                        selectedLevelOfSupport.length ||
                        selectedVoterStatus.length
                          ? true
                          : false
                      }
                      // onClick={() =>
                      //   votersToContactByPhone &&
                      //   setVoterId(votersToContactByPhone[activeIndex]?.id)
                      // }
                    >
                      Skip voter
                    </button>
                    <button
                      className="btn btn-primary rounded-pill btn-xs"
                      type="submit"
                      ref={submitButtonRef}
                      data-bs-toggle={
                        activeIndex >= votersToContactByPhone.length
                          ? "modal"
                          : ""
                      }
                      data-bs-target={
                        activeIndex >= votersToContactByPhone.length
                          ? "#successModal"
                          : ""
                      }
                      disabled={
                        selectedLevelOfSupport.length ||
                        selectedVoterStatus.length
                          ? false
                          : true
                      }
                      // onClick={() =>
                      //   votersToContactByPhone &&
                      //   setVoterId(votersToContactByPhone[activeIndex]?.id)
                      // }
                    >
                      Save and call next
                    </button>
                  </div>
                </div>
              </div>

              {/* Scripts and Response */}

              <div className="row">
                <div className="col-md-3">
                  <div className="card card-fullHeight mt-3 shadow-2">
                    <div className="card-body scroll-y">
                      <div className="basic-info">
                        <div className="avatar m-auto border-0">
                          <span className="name fw-bold">MM</span>
                        </div>

                        <p className="mb-0 mt-2 fw-bold text-center">
                          {votersToContactByPhone &&
                            votersToContactByPhone[activeIndex]?.name}
                        </p>
                        <p className="body-4 fw-bold text-center text-muted">
                          {votersToContactByPhone &&
                            votersToContactByPhone[activeIndex]?.sex}
                        </p>
                        <p className="mb-4 fw-bold text-center">
                          {" "}
                          <PhoneOutlineIcon />{" "}
                          <span className="ms-2">
                            {" "}
                            {(votersToContactByPhone &&
                              votersToContactByPhone[activeIndex]
                                ?.phoneNumber) ||
                              "Not Set"}
                          </span>
                        </p>
                      </div>

                      <hr className="mb-4"></hr>

                      {/* voter Details */}

                      <div className="voter-details">
                        <h4 className="body-5"> Voter Details </h4>

                        <div className="row">
                          <div className="col-4 text-muted d-flex">Address</div>
                          <div className="col d-flex align-items-center">
                            <span className="body-4">
                              {votersToContactByPhone &&
                                votersToContactByPhone[activeIndex]?.address}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 text-muted d-flex">Age</div>
                          <div className="col d-flex align-items-center">
                            <span className="body-4">
                              {votersToContactByPhone &&
                                votersToContactByPhone[activeIndex]?.age}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-4 text-muted d-flex">
                            Party Affiliation
                          </div>
                          <div className="col d-flex align-items-center">
                            <span className="body-4">
                              {votersToContactByPhone &&
                                votersToContactByPhone[activeIndex]
                                  ?.partyAffiliation}
                            </span>
                          </div>
                        </div>
                      </div>

                      <h4 className="body-5"> Contact History </h4>
                      {votersToContactByPhone &&
                      votersToContactByPhone[activeIndex]?.voterContactHistories
                        ?.length ? (
                        <div className="contact-history">
                          <div className="timeline timeline-sm">
                            {votersToContactByPhone &&
                              votersToContactByPhone[
                                activeIndex
                              ]?.voterContactHistories
                                ?.sort((a, b) => {
                                  if (a.updatedAt > b.updatedAt) {
                                    return -1;
                                  }
                                  if (a.updatedAt < b.updatedAt) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                ?.map((history, index) => (
                                  <div
                                    className="timeline-item pb-4"
                                    key={index}
                                  >
                                    <div className="timeline-step">
                                      <span> </span>
                                    </div>
                                    <div className="timeline-content">
                                      <h4 className="mb-1 body-4">
                                        {" "}
                                        Marked as{" "}
                                        <span className="text-success body-5">
                                          {history.status ||
                                            history.levelOfSupport}
                                        </span>{" "}
                                      </h4>
                                      <p className="text-muted fs-8">
                                        Called on{" "}
                                        {
                                          getYearMonthDateTime(
                                            history.updatedAt
                                          ).split("-")[0]
                                        }
                                        <span className="dot bg-neutral-n3 ms-1">
                                          {" "}
                                        </span>{" "}
                                        {
                                          getYearMonthDateTime(
                                            history.updatedAt
                                          ).split("-")[1]
                                        }
                                      </p>
                                    </div>
                                  </div>
                                ))}
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex gap-2 flex-row align-items-center">
                          <InfoIconSecondary />
                          <p className="m-0 text-muted fs-7">
                            {" "}
                            No tasks available for today{" "}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  <div className="contactAction flex-row card card-flush card-fullHeight mt-3 shadow-2">
                    {/* script */}
                    <ContactByPhoneScript
                      candidateInfo={candidateInfo}
                      finalScript={finalScript}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      voterName={votersToContactByPhone[activeIndex]?.name}
                      isScriptActive={isScriptActive}
                    />
                    <div className={isScriptActive ? "card-divider" : ""}></div>

                    {/* voter response */}
                    <div className="contactAction-cardRight flex-1">
                      <div className="card-header">
                        <h4 className="card-title"> Voter Response</h4>
                        <button
                          className="btn btn-link btn-sm p-1 ps-0 d-flex align-items-center gap-1"
                          onClick={handleScript}
                        >
                          {" "}
                          <EyeIcon /> {buttonText}
                        </button>
                      </div>
                      <div className="card-body">
                        <p className="body-4 text-muted">
                          Select either voter status or level of support
                        </p>

                        <div className="voter-status">
                          <div className="d-flex justify-content-between">
                            <label className="body-5"> Voter Status </label>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={voterStatusTooltip}
                              >
                                {" "}
                                <HelpIcon />
                              </Link>
                            </div>
                          </div>
                          <div className="row">
                            {errors?.voterStatus?.message &&
                              errors?.levelOfSupport?.message && (
                                <span className="invalidInput form-text text-danger">
                                  {errors.voterStatus.message as string}
                                </span>
                              )}
                            {VOTER_STATUS.map((status, index) => (
                              <div className="col-md-6" key={index}>
                                <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                  <input
                                    type="radio"
                                    className="btn-check form-check-input"
                                    id={status}
                                    autoComplete="off"
                                    value={status}
                                    {...register("voterStatus")}
                                    checked={selectedVoterStatus === status}
                                    onClick={() => {
                                      reset();
                                      setSelectedLevelOfSupport("");
                                      status === selectedVoterStatus
                                        ? setSelectedVoterStatus("")
                                        : setSelectedVoterStatus(status);
                                    }}
                                  />
                                  <label
                                    className="btn btn-outline-primary w-100 ps-5 text-start"
                                    htmlFor={status}
                                  >
                                    {status}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="d-flex align-items-center py-3 mb-3">
                            <hr className="w-100" />
                            <div className="px-4 text-muted">or</div>
                            <hr className="w-100" />
                          </div>
                        </div>

                        <div className="level-of-support">
                          <div className="d-flex justify-content-between">
                            <label className="body-5"> Level of Support </label>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={losTooltip}
                              >
                                {" "}
                                <HelpIcon />
                              </Link>
                            </div>
                          </div>
                          <div className="row">
                            {errors?.levelOfSupport?.message &&
                              errors?.voterStatus?.message && (
                                <span className="invalidInput form-text text-danger">
                                  {errors.levelOfSupport.message as string}
                                </span>
                              )}
                            {LEVEL_OF_SUPPORT.map((support, index) => (
                              <div className="col-md-12" key={index}>
                                <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                  <input
                                    type="radio"
                                    className="btn-check form-check-input"
                                    id={support}
                                    autoComplete="off"
                                    value={support}
                                    {...register("levelOfSupport")}
                                    checked={selectedLevelOfSupport === support}
                                    onClick={() => {
                                      reset();
                                      setSelectedVoterStatus("");
                                      support === selectedLevelOfSupport
                                        ? setSelectedLevelOfSupport("")
                                        : setSelectedLevelOfSupport(support);
                                    }}
                                  />
                                  <label
                                    className="btn btn-outline-primary w-100 ps-5 text-start"
                                    htmlFor={support}
                                  >
                                    {support}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      {/* <div className="card-footer shadow-6 d-flex justify-content-between">
                        <button
                          className="btn btn-link btn-sm p-1 ps-0"
                          data-bs-toggle="modal"
                          data-bs-target="#skipModal"
                          type="button"
                          disabled={
                            selectedLevelOfSupport.length ||
                            selectedVoterStatus.length
                              ? true
                              : false
                          }
                          onClick={() =>
                            votersToContactByPhone &&
                            setVoterId(votersToContactByPhone[activeIndex]?.id)
                          }
                        >
                          Skip voter
                        </button>
                        <button
                          className="btn btn-primary rounded-pill btn-xs"
                          // ref={submitButtonRef}
                          type="submit"
                          // data-bs-toggle={
                          //   activeIndex >= votersToContactByPhone.length ? "modal" : ""
                          // }
                          // data-bs-target={
                          //   activeIndex >= votersToContactByPhone.length
                          //     ? "#successModal"
                          //     : ""
                          // }
                          disabled={
                            selectedLevelOfSupport.length ||
                            selectedVoterStatus.length
                              ? false
                              : true
                          }
                          onClick={() =>
                            votersToContactByPhone &&
                            setVoterId(votersToContactByPhone[activeIndex]?.id)
                          }
                        >
                          Save and call next1
                        </button>
                      </div> */}
                      {/* <span
                      // ref={submitButtonRef}
                      // data-bs-toggle={
                      //   activeIndex === votersToContactByPhone?.length
                      //     ? "modal"
                      //     : ""
                      // }
                      // data-bs-target={
                      //   activeIndex === votersToContactByPhone?.length
                      //     ? "#successModal"
                      //     : ""
                      // }
                    ></span> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* All attempted */}

              {/* <div className="row">
              <div className="card card-flush card-fullHeight mt-3 shadow-2">
                <div className="noData d-flex gap-2 align-items-center">
                    <SuccessIcon />
                    <h1 className="mt-6">All voters contacted </h1>
                    <p> <span className="fw-bold"> 10/10 </span> voters attempted today. Continue to complete other tasks</p>
                    <Link to="/dashboard" className="btn btn-primary rounded-pill px-5"> Continue to Homepage</Link>
                </div>
              </div>
            </div> */}
            </div>
          </form>
        </main>
      </div>

      {/* Skip Modal */}

      <div
        className="modal fade"
        id="skipModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <WarningIcon />
              <h2 className="mt-3">
                {" "}
                Do you want to skip '
                {votersToContactByPhone &&
                  votersToContactByPhone[activeIndex]?.name}
                '?{" "}
              </h2>
              <p>
                By skipping, all details entered for this voter from this
                contact will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary rounded-pill btn-xs"
                onClick={() => {
                  handleSkip();
                }}
                data-bs-dismiss="modal"
              >
                Yes, Skip
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when user go to next page without confirming Modal */}
      <div
        className="modal fade"
        id="leaveModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Do you want to leave this page </h2>
              <p>
                By leaving, all details entered for this voter from this contact
                will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, Leave
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when save and call next voter after last step */}
      <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../img/success-modal.png")}
                width={56}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Great Work! </h2>
              <p>
                <span className="fw-bold">
                  {contactedVotersCount}/{votersToContactByPhone?.length}{" "}
                </span>{" "}
                voters attempted today. Continue to complete other tasks
              </p>
            </div>

            <div
              className="modal-footer justify-content-center"
              data-bs-dismiss="modal"
            >
              <Link to="/dashboard" className="btn btn-primary rounded-pill">
                Continue to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactbyPhone;

const LoadingAnimation = () => {
  return (
    <div
      className="card-body"
      style={{
        zIndex: "100000",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <div className="text-center px-5">
        <div className="loader-demo-box">
          <div className="jumping-dots-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <span className="body-4 fs-5">Loading...</span>
      </div>
    </div>
  );
};

import React, { useEffect } from 'react'

const LimitedFeaturesModal = () => {
  useEffect(() => {
    const showModal = () => {
        const modalElement = document.getElementById('mobileTurfModal');

        const handleBodyStyles = () => {
          // Reset styles
          document.body.style.overflow = '';
          document.body.style.paddingRight = '';
          // Remove data attributes
          document.body.removeAttribute('data-bs-overflow');
          document.body.removeAttribute('data-bs-padding-right');
       };
        if (modalElement) {
            import('bootstrap').then(({ Modal }) => {
                const modal = new Modal(modalElement);
                modal.show();

                modalElement.addEventListener('hidden.bs.modal', () => {
                    modal.dispose(); // Dispose the modal instance to clean up
                    handleBodyStyles();
                });
            });
        }
    };

    const isMobileView = () => window.innerWidth <= 768;
    if (isMobileView()) {
        showModal();
    }

    // Cleanup the event listener on component unmount
    return () => {
        const modalElement = document.getElementById('mobileTurfModal');
        if (modalElement) {
            modalElement.removeEventListener('hidden.bs.modal', () => {});
        }
    };
}, []);
  return (
     
     <div
     className="modal fade"
     id="mobileTurfModal"
     data-bs-backdrop="static"
     data-bs-keyboard="false"
     tabIndex={-1}
     aria-labelledby="staticBackdropLabel"
     aria-hidden="true"
   >
     <div className="modal-dialog modal-dialog-centered">
       <div className="modal-content">
         
         <div className="modal-body text-center">
           <img
             src={require("../../img/marker.png")}
             width={56}
             height={56}
             alt="turf alert"
           />
           <h2 className="mt-3"> Manage Turf Unavailable </h2>
           <p>
           This feature is not supported on mobile.
           </p>
         </div>

         <div className="modal-footer">
         

           <button
             className="btn btn-primary w-100 rounded-pill btn-xs"
             data-bs-dismiss="modal"

           >
             Continue
           </button>
         </div>
       </div>
     </div>
   </div>
  )
}

export default LimitedFeaturesModal

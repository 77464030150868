import useCandidateScriptDetails from "components/pages/profile/script/candidate-script/useCandidateScriptDetails";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
interface IDonorScriptProp {
  fullName: string;
  electionPosition: string;
  isScriptActive: boolean;
  donorName: string;
  finalScript: string;
  selectedOption: string | undefined;
  setSelectedOption: React.Dispatch<React.SetStateAction<string | undefined>>;
  candidateInfo: any;
}

const DonorScript = ({
  fullName,
  electionPosition,
  isScriptActive,
  donorName,
  finalScript,
  selectedOption,
  setSelectedOption,
  candidateInfo,
}: IDonorScriptProp) => {
  const handleScriptChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
  };

  const SCRIPT_SELECT_OPTIONS = {
    defaultScript: "Default Script",
    customScript: "Custom Script",
  };

  return (
    <div
      className={
        isScriptActive
          ? "contactAction-cardLeft flex-1 scroll-y"
          : "contactAction-cardLeft flex-0 scroll-y"
      }
    >
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title text-primary"> Script</h4>
        {selectedOption && (
          <select
            className="form-select"
            aria-label="Default select example"
            style={{ width: "fit-content" }}
            onChange={handleScriptChange}
            defaultValue={selectedOption}
          >
            {Object.entries(SCRIPT_SELECT_OPTIONS).map(
              ([key, value], index) => (
                <option
                  key={index}
                  value={key}
                  selected={selectedOption === key}
                >
                  {value}
                </option>
              )
            )}
            =
          </select>
        )}
      </div>

      <div className="card-body">
        <div className="card scroll-y script-wrapper textEditor">
          {selectedOption === "defaultScript" && (
            <>
              <p>Hi, is <span className="text-bg">{donorName}</span> available? .</p>
              <p>
                Hi,<span className="text-bg">{donorName}</span> , my name is{" "}
                <span className="text-bg">{candidateInfo?.data.fullName}</span> and I'm running for{" "}
                <span className="text-bg">{electionPosition}</span> , right here in{" "}
                <span className="text-bg">{candidateInfo?.data.candidate.elections[0].city}</span> . How are you
                doing today?
              </p>
              <p>
                The reason I’m calling today is because we have really important
                election upcoming for{" "}
                <span className="text-bg">{candidateInfo?.data.candidate.elections[0].electionPosition}</span> .
              </p>
              <p>
                I’m running for <span className="text-bg">{candidateInfo?.data.candidate.elections[0].electionPosition}</span> 
                .
              </p>
              <p>
                Can we count on you to support our campaign with a donation to
                help us reach voters and meet our goals?
              </p>
            </>
          )}

          {selectedOption === "customScript" && (
            <>
              {
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(finalScript),
                  }}
                />
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default DonorScript;

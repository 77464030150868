import React, { useEffect, useRef } from "react";
import { useGoogleMap } from "@react-google-maps/api";

interface MapControlProps {
  position: keyof typeof google.maps.ControlPosition;
}
const MapControl = (props: React.PropsWithChildren<MapControlProps>) => {
  const map = useGoogleMap();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (map && ref.current) {
      map.controls[window.google.maps.ControlPosition[props.position]].push(
        ref.current
      );
    }
  }, [map, ref]);
  return <div className="w-100" ref={ref} >{props.children}</div>;
};

export default MapControl;

import { yupResolver } from "@hookform/resolvers/yup";
import InfoIconDanger from "img/svg/InfoIconDanger";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "templates/components/Spinner";
import * as Yup from "yup";
import useSecurityDetails from "./useSecurityDetails";
import toastNotify from "utils/toastNotify";
import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface IChangePasswordProps {
  editPasswordModalRef: any;
}

const ChangePasswordModal: React.FC<IChangePasswordProps> = ({
  editPasswordModalRef,
}) => {
  const { mutationPromise } = useSecurityDetails();
  const { logout } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const passwordSchema = Yup.string()
    .test("password", "Password must be at least 8 characters", (value) =>
      value ? value.length >= 8 : undefined
    )
    .test(
      "lowercase",
      "Password must contain at least one lowercase letter",
      (value) => /[a-z]/.test(value!)
    )
    .test(
      "uppercase",
      "Password must contain at least one uppercase letter",
      (value) => /[A-Z]/.test(value!)
    )
    .test("number", "Password must contain at least one number", (value) =>
      /\d/.test(value!)
    );
  // .test(
  //   "specialChar",
  //   "Password must contain at least one special character (@$!%*?&)",
  //   (value) => /[@$!%*?&]/.test(value!)
  // );
  const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    newPassword: passwordSchema,
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(changePasswordSchema as any),
  });
  const handleSubmitForm = async (formData: any) => {
    const res = await handlePasswordChange(formData);
    if (!res.success) {
      if (res.isNewPasswordError) {
        setNewPasswordError(res.message);
      } else {
        setError(res.message);
      }
    } else {
      toastNotify("success", "Password updated!");
      closeBtnRef?.current?.click();
      setTimeout(() => {
        logout();
      }, 1000);
    }
  };

  useEffect(() => {
    setValue("password", "");
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setError("");
      setNewPasswordError("");
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [error, newPasswordError]);

  const handlePasswordChange = async (data: any) => {
    setLoading(true);
    const { password, newPassword } = data;
    if (password === newPassword) {
      setLoading(false);
      return {
        isNewPasswordError: true,
        success: false,
        message: "New password can't be same as previous password",
      };
    }
    try {
      const data = await mutationPromise.mutateAsync({ password, newPassword });
      setLoading(false);
      return { success: true, data };
    } catch (err: any) {
      setLoading(false);
      return { success: false, message: err.response.data.message };
    }
  };

  const handleCancel = () => {
    clearErrors();
    setValue("password", "");
    setValue("newPassword", "");
    setValue("confirmNewPassword", "");
  };

  const allPasswordFieldsEmpty =
    watch("password") !== "" &&
    watch("newPassword") !== "" &&
    watch("confirmNewPassword") !== "";

  return (
    <div
      className="modal fade"
      id="editPasswordModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header border-1">
              <h5 className="modal-title body-2 mb-0">Change Password </h5>
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCancel}
                ref={closeBtnRef}
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 required">
                <label htmlFor="inputCuPass" className="form-label">
                  Current password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="inputCuPass"
                  aria-describedby="passHelp"
                  placeholder="Enter current password"
                  {...register("password")}
                />
                <>
                  {errors?.password?.message && (
                    <div id="emailHelp" className="form-text text-danger fs-8">
                      <span className="me-2">
                        <InfoIconDanger />
                      </span>
                      {errors?.password?.message as string}
                    </div>
                  )}
                  {error ? (
                    <div className="invalidInput form-text text-danger">
                      <InfoIconDanger /> {error}
                    </div>
                  ) : null}
                </>
              </div>
              <div className="mb-3 required">
                <label htmlFor="newPassword" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  aria-describedby="newPassHelp"
                  placeholder="Enter new password"
                  {...register("newPassword")}
                />
                {errors?.newPassword?.message && (
                  <div id="emailHelp" className="form-text text-danger fs-8">
                    <span className="me-2">
                      <InfoIconDanger />
                    </span>
                    {errors?.newPassword?.message as string}
                  </div>
                )}
              </div>
              <div className="mb-3 required">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm new password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  aria-describedby="confirmPass"
                  placeholder="Re-enter new password"
                  {...register("confirmNewPassword")}
                />
                {errors?.confirmNewPassword?.message && (
                  <div id="emailHelp" className="form-text text-danger fs-8">
                    <span className="me-2">
                      <InfoIconDanger />
                    </span>
                    {errors?.confirmNewPassword?.message as string}
                  </div>
                )}
                {newPasswordError ? (
                  <div className="invalidInput form-text text-danger">
                    <InfoIconDanger /> {newPasswordError}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="modal-footer border-1">
              <Link
                to=""
                className="btn btn-link text-primary btn-xs rounded-pill"
                data-bs-dismiss="modal"
                onClick={handleCancel}
              >
                Cancel
              </Link>
              {/* remove button disabled class after spinner goes off */}
              <button
                className={`btn btn-primary rounded-pill btn-xs px-6 ${
                  loading || !allPasswordFieldsEmpty ? "disabled" : ""
                }`}
              >
                {loading ? <Spinner /> : null}
                Change password
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordModal;

import React, { useEffect, createRef, useRef } from "react";
import lottie from "lottie-web";
import { Link } from "react-router-dom";
import animationData from "../../../img/lotties/success.json";

const PostCompleted = () => {
  const element = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (element.current)
      // add this
      lottie.loadAnimation({
        animationData,
        container: element.current,
        loop: false,
      });
    return () => lottie.destroy();
  }, [element]);

  return (
    <>
      <div className="text-center">
        <div
          className="lottee-container m-auto"
          ref={element}
          style={{ width: 56, height: 56 }}
        >
          {" "}
        </div>

        <h2 className="mt-4"> Social media posting complete </h2>
        <p className="mb-6 body-3 text-muted">
          You have responded to all social media posts for today.
        </p>
      </div>

      {/* <div className="text-center">
        <Link to="" className="btn btn-primary btn-xs px-4 rounded-pill">
          {" "}
          Done{" "}
        </Link>
      </div> */}
    </>
  );
};

export default PostCompleted;

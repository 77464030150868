import React, { useReducer } from 'react'
import { ASC } from '../../voters/voters-list/VotersList'
import DonorTableFilterV2 from './DonorTableFilterV2'
import DonorsTableDataV2 from './DonorTableDataV2'

export interface IReducerState {
  searchInput: string
  donorType: string
  donorStatus: string
  donorSource: string
  order: string
}

export interface IReducerAction {
  type: 'searchInput' | 'donorType' | 'donorStatus' | 'donorSource' | 'order'
  value: string
}

const initialState: IReducerState = {
  searchInput: '',
  donorType: '',
  donorStatus: '',
  donorSource: '',
  order: ASC
}

function myReducer(state: IReducerState, action: IReducerAction): IReducerState {
  switch (action.type) {
    case 'searchInput':
      return { ...state, searchInput: action.value }
    case 'donorType':
      return { ...state, donorType: action.value }
    case 'donorStatus':
      return { ...state, donorStatus: action.value }
    case 'donorSource':
      return { ...state, donorSource: action.value }
    case 'order':
      return { ...state, order: action.value }
    default:
      throw new Error('Unknown action type')
  }
}

const DonorTableTabV2 = ({
  title
}: {
  title: string
}) => {
  const initialState: IReducerState = {
    searchInput: '',
    donorType: title !== 'All' ? title : '',
    donorStatus: '',
    donorSource: '',
    order: ASC
  }

  const [donorState, dispatch] = useReducer(myReducer, initialState)

  return (
    <>
      <DonorTableFilterV2
        donorState={donorState}
        dispatch={dispatch}
      />

      <DonorsTableDataV2
        title={title}
        donorState={donorState}
      />
    </>
  )
}

export default React.memo(DonorTableTabV2)
import CalendarOutlineDangerIcon from "img/svg/CalendarOutlineDangerIcon";
import CalendarOutlineSmallIcon from "img/svg/CalendarOutlineSmallIcon";
import EditIconSmall from "img/svg/EditIconSmall";
import TrashIconSmall from "img/svg/TrashIconSmall";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomTooltip from "components/common/CustomTooltip";

interface IPriorityData {
  onHandlePriority: any;
}

const PriorityListItem = ({ onHandlePriority }: IPriorityData) => {

    const [ isChecked, setChecked ] = useState(false)

    const handleCheck = ()=> {
        setChecked(true)
    }
  return (
    <>
      <li className="taskList-item">
        <div className="taskList-item-content">
          <CustomTooltip content="Mark as complete">
            <div className="taskList-item-content-indicator" onClick={handleCheck}></div>

          </CustomTooltip>
          <div className="d-flex justify-content-between w-100">
            <div>
              <span className="taskList-item-content-title">
                {" "}
                Pursue initiatives to address inequality and promote fairness{" "}
              </span>
              <div className="d-flex gap-2">
                <span className="d-block text-muted fs-8 font-regular d-flex align-items-center gap-1">
                  <CalendarOutlineSmallIcon /> Complete by 12 Apr
                </span>
                <span className="d-block text-danger-d2 fs-8 font-regular d-flex align-items-center gap-1">
                  <CalendarOutlineDangerIcon /> Expired on 12 Apr
                </span>

              </div>
            </div>
            <div className="taskList-item-action">
              <button
                className="btn btn-link no-hover px-0 py-0"
                onClick={onHandlePriority}
              >
                {" "}
                <EditIconSmall />{" "}
              </button>
              <button
                className="btn btn-link no-hover px-0 py-0"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
              >
                {" "}
                <TrashIconSmall />{" "}
              </button>
            </div>
          </div>
        </div>
      </li>

      {/* delete warning Modal  */}
      <div
        className="modal fade"
        id="deleteModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../img/warning.png")}
                width={66}
                height={56}
                alt="delete warning"
              />
              <h2 className="mt-3">Do you want to delete the priority? </h2>
              <p>
                By deleting, it will be removed from the list of my priorities.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link rounded-pill btn-xs"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriorityListItem;

import React from 'react'

const FacebookFLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
<path d="M10.0082 5.2685C9.50976 5.2685 9.36806 5.4885 9.36806 5.9735V6.774H10.6939L10.5632 8.07075H9.36781V12H7.78122V8.0705H6.71094V6.77375H7.78172V5.99575C7.78172 4.6875 8.30883 4 9.78738 4C10.1047 4 10.4843 4.025 10.7109 4.0565V5.274" fill="white"/>
</svg>
  )
}

export default FacebookFLogo

import HelpIcon from "img/svg/helpIcon";
import React from "react";
import { Link } from "react-router-dom";
import {
  ATTEMP_TOOLTIP,
  CONTACT_TOOLTIP,
  ICampaignSummary,
  RATE_TOOLTIP,
  RATE_TWO_TOOLTIP,
} from "./ContactPlan";

const CampaignSummary = ({
  campaignSummary,
}: {
  campaignSummary: ICampaignSummary;
}) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="section-box">
            <h3 className="section-box-title">Campaign Summary </h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-6 mb-3">
          <div className="card card-flush h-100 shadow-2">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title"> Total Voter Contact Attempts</h4>
              <div className="card-info">
                <Link
                  to=""
                  data-bs-html="true"
                  data-bs-custom-class="custom-tooltip"
                  className="text-left"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title={ATTEMP_TOOLTIP}
                >
                  {" "}
                  <HelpIcon />{" "}
                </Link>
              </div>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="d-flex align-items-end">
                <h1 className="mb-0 me-2">
                  {campaignSummary.totalContactAttempts}
                </h1>{" "}
                <span className="text-muted">voters</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-6 mb-3">
          <div className="card card-flush h-100 shadow-2">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title"> Total Voter Contacts </h4>
              <div className="card-info">
                <Link
                  to=""
                  data-bs-html="true"
                  data-bs-custom-class="custom-tooltip"
                  className="text-left"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title={CONTACT_TOOLTIP}
                >
                  {" "}
                  <HelpIcon />{" "}
                </Link>
              </div>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="d-flex align-items-end">
                <h1 className="mb-0 me-2">
                  {campaignSummary.totalSuccessfulContactAttempts} /{" "}
                </h1>
                <h1 className="mb-0 me-2 text-muted">
                  {campaignSummary.totalVoters}
                </h1>{" "}
                <span className="text-muted">voters</span>
              </div>
              <div
                className="progress mt-3"
                role="progressbar"
                aria-label="task progress"
                aria-valuenow={25}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ height: 4 + "px" }}
              >
                <div
                  className="progress-bar"
                  style={{
                    width: `${campaignSummary.successfulContactRate}%`,
                  }}
                ></div>
              </div>
              <p className="m-0 text-muted fs-7 mt-1 fw-bold">
                <span> {campaignSummary.successfulContactRate ?? 0}%</span> to your
                voter contact goal{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-6 mb-3">
          <div className="card card-flush h-100 shadow-2">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title"> Contact Rate</h4>
              <div className="card-info">
                <Link
                  to=""
                  data-bs-html="true"
                  data-bs-custom-class="custom-tooltip"
                  className="text-left"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title={RATE_TOOLTIP}
                >
                  {" "}
                  <HelpIcon />{" "}
                </Link>
              </div>
            </div>

            <div className="card-body d-flex flex-column justify-content-end">
              <div className="d-flex align-items-end">
                <h1 className="mb-0 me-2">
                  {campaignSummary.dtdContactRate ?? 0}%
                </h1>{" "}
                <span className="text-muted">voters</span>
              </div>

              <p className="m-0 text-muted fs-7 mt-1 fw-bold">
                {" "}
                Door to Door contact{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-6 mb-3">
          <div className="card card-flush h-100 shadow-2">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title"> Contact Rate</h4>
              <div className="card-info">
                <Link
                  to=""
                  data-bs-html="true"
                  data-bs-custom-class="custom-tooltip"
                  className="text-left"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title={RATE_TWO_TOOLTIP}
                >
                  {" "}
                  <HelpIcon />{" "}
                </Link>
              </div>
            </div>

            <div className="card-body d-flex flex-column justify-content-end">
              <div className="d-flex align-items-end">
                <h1 className="mb-0 me-2">
                  {campaignSummary.phoneContactRate ?? 0}%
                </h1>{" "}
                <span className="text-muted">voters</span>
              </div>

              <p className="m-0 text-muted fs-7 mt-1 fw-bold">
                {" "}
                By Phone contact{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignSummary;

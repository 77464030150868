import { ToastContainer, toast } from 'react-toastify';
import SideNavbar from "components/common/navbar/SideNavbar";
import PersonalDetails from "./personal-details/PersonalDetails";
import VoterDetails from './voter/VoterDetails';
import DonorDetails from "./donor/DonorDetails";
import YourStoryDetails from "./professional-details/ProfessionalDetails";
import SecurityDetails from "./security/SecurityDetails";
import IntegrationsDetails from "./integrations/IntegrationDetails";
import CheckSuccess from "img/svg/checkSuccess";
import ScriptContent from './script/ScriptContent';
import CallLogsDetails from './call-logs/CallLogsDetails';
import BudgetContent from './script/BudgetContent';


const CandidateProfile = () => {
  const notify = () => {
    // toast("Wow so easy!");

    toast.success("Changes saved", {
      className: 'alert alert-success',
      position: toast.POSITION.TOP_RIGHT,
      theme:"light",
      hideProgressBar:true,
      autoClose: 50000,
      icon: <CheckSuccess />
    });
  }

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont bg-white">
        <main>
          <div className="container-fluid">
            {/* <button onClick={notify}> notify</button> */}
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="nav nav-tabs nav-flush scroll-x nav-settings position-relative bg-transparent"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="personal-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#personal-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="personal-tab-pane"
                      aria-selected="true"
                    >
                      My account
                      
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="story-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#story-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="story-tab-pane"
                      aria-selected="false"
                    >
                      Professional details
                      
                    </button>
                  </li>
                 
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="voter-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#voter-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="voter-tab-pane"
                      aria-selected="false"
                    >
                      Voter{" "}
                     
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="donor-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#donor-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="donor-tab-pane"
                      aria-selected="false"
                    >
                      Fundraise
                      
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="script-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#script-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="script-tab-pane"
                      aria-selected="false"
                    >
                      Script
                      
                    </button>
                  </li>
                  
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="campaign-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#campaign-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="campaign-tab-pane"
                      aria-selected="false"
                    >
                      Integrations{" "}
                      
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="callLogs-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#callLogs-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="callLogs-tab-pane"
                      aria-selected="false"
                    >
                      Logs{" "}
                      
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="budget-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#budget-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="budget-tab-pane"
                      aria-selected="false"
                    >
                      Budget{" "}
                      
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="security-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#security-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="security-tab-pane"
                      aria-selected="false"
                    >
                      Security
                      
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="personal-tab-pane"
                    role="tabpanel"
                    aria-labelledby="personal-tab"
                    tabIndex={0}
                  >
                    <PersonalDetails />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="story-tab-pane"
                    role="tabpanel"
                    aria-labelledby="story-tab"
                    tabIndex={0}
                  >
                    <YourStoryDetails />
                  </div>
                  

                  <div
                    className="tab-pane fade"
                    id="voter-tab-pane"
                    role="tabpanel"
                    aria-labelledby="voter-tab"
                    tabIndex={0}
                  >
                    <VoterDetails />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="donor-tab-pane"
                    role="tabpanel"
                    aria-labelledby="donor-tab"
                    tabIndex={0}
                  >
                    <DonorDetails />
                  </div>
                  
                  <div
                    className="tab-pane fade"
                    id="script-tab-pane"
                    role="tabpanel"
                    aria-labelledby="script-tab"
                    tabIndex={0}
                  >
                    <ScriptContent />
                  </div>
                  
                  <div
                    className="tab-pane fade"
                    id="campaign-tab-pane"
                    role="tabpanel"
                    aria-labelledby="campaign-tab"
                    tabIndex={0}
                  >
                  

                    <IntegrationsDetails />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="callLogs-tab-pane"
                    role="tabpanel"
                    aria-labelledby="callLogs-tab"
                    tabIndex={0}
                  >
                  

                    <CallLogsDetails />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="budget-tab-pane"
                    role="tabpanel"
                    aria-labelledby="budget-tab"
                    tabIndex={0}
                  >
                  

                    <BudgetContent />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="security-tab-pane"
                    role="tabpanel"
                    aria-labelledby="story-tab"
                    tabIndex={0}
                  >
                    <SecurityDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        
      </div>
    </div>
  );
};

export default CandidateProfile;

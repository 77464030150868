import CheckSuccess from "img/svg/checkSuccess";
import { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import InfoIconDanger from "img/svg/InfoIconDanger";

interface ButtonEditProps {
  clickedButton: number | false;
}

const PersonalDetails = () => {
  const [editable, setEditable] = useState<ButtonEditProps>({
    clickedButton: false,
  });

  const [startDate, setStartDate] = useState(new Date());

  const settingEditHandler = (buttonId: number) => {
    setEditable({ clickedButton: buttonId });
  };
  return (
    <>
    <div className="row">
      <div className="col-md-8">
        <div className="mt-5">
          <div className="settings">
            <div className="settings-header d-flex justify-content-between">
              <div className="">
                <h4> Basic Details</h4>
                <p className="body-4 text-muted">Update or edit your information</p>
              </div>

              <div className="media media-lg">
                <div className="media-header avatar border-0 position-relative">
                  <span className="media-header--text">
                    <i className="icon-user"></i>
                    {/* <img
                                src={
                                  
                                  require("../../../img/avatar.png")
                                }
                              
                                className="img-fluid rounded-circle"
                                alt="User Avatar"
                              /> */}
                  </span>

                  <div className="edit-avatar">
                    <label className="custom-file-upload">
                      <input type="file" />
                      <i className="icon-pencil"> </i>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="settings-body">
              <div className="personal-info">
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> Name </span>
                  </div>
                  <div className="col-md-8">
                    <span className="body-4"> Elenor Parker </span>
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> Email </span>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-8">
                        <span className="body-4"> elenor. parker@gmail.com</span>{" "}
                        <CheckSuccess />
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex justify-content-end">
                          <button className="btn btn-link btn-xs px-1 py-0">
                            Verify email
                          </button>
                          {/* <button
                                className="btn btn-link btn-xs px-1 py-0"
                                
                              >
                                Resend email
                              </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> Phone no. </span>
                  </div>
                  <div className="col-md-8">
                    {/* after save edit  */}
                    {/* <span className="body-4"> 010 1 718 222 2222  </span>  */}
                    <div className="row">
                      <div className="col-md-8">
                        {editable.clickedButton ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter phone no."
                            />
                            <div
                              id="emailHelp"
                              className="form-text text-danger fs-8"
                            >
                              {" "}
                              <span className="me-2">
                                {" "}
                                <InfoIconDanger />{" "}
                              </span>{" "}
                              Phone no. should be 10 digit{" "}
                            </div>
                          </>
                        ) : (
                          <span className="body-4 text-muted"> Not Provided </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex justify-content-end">
                          {editable.clickedButton ? (
                            <>
                              <button
                                className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                onClick={() =>
                                  setEditable({ clickedButton: false })
                                }
                              >
                                Cancel
                              </button>
                              <button className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block disabled">
                                Save
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-link btn-xs px-1 py-0"
                              onClick={() => {
                                settingEditHandler(1);
                              }}
                            >
                              Add
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> State </span>
                  </div>
                  <div className="col-md-8">
                    <span className="body-4"> Texas </span>
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> Country </span>
                  </div>
                  <div className="col-md-8">
                    <span className="body-4"> Culberson </span>
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> City </span>
                  </div>
                  <div className="col-md-8">
                    <span className="body-4"> Austin </span>
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
              </div>

              <div className="campaign-details mt-5">
                <h4> Campaign Details </h4>
                <p className="body-4 text-muted">
                  Update or edit your campaign Details
                </p>

                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> Date of Election </span>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-8">
                        {editable.clickedButton ? (
                          <div className="custom-datepicker">
                            <DatePicker
                              className="form-control"
                              showIcon={true}
                              selected={startDate}
                              onChange={(date) => setStartDate(date ?? new Date())}
                            />
                          </div>
                        ) : (
                          <span className="body-4"> 11/7/23 </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex justify-content-end">
                          {editable.clickedButton ? (
                            <>
                              <button
                                className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                onClick={() =>
                                  setEditable({ clickedButton: false })
                                }
                              >
                                Cancel
                              </button>
                              <button className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block">
                                Save
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-link btn-xs px-1 py-0"
                              onClick={() => {
                                settingEditHandler(2);
                              }}
                            >
                              Edit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> Hours per week </span>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-8">
                        {editable.clickedButton ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter hours per week"
                          />
                        ) : (
                          <span className="body-4"> 14 hours </span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex justify-content-end">
                          {editable.clickedButton ? (
                            <>
                              <button
                                className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                onClick={() =>
                                  setEditable({ clickedButton: false })
                                }
                              >
                                Cancel
                              </button>
                              <button className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block">
                                Save
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-link btn-xs px-1 py-0"
                              onClick={() => {
                                settingEditHandler(3);
                              }}
                            >
                              Edit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
              </div>

              <div className="deactivate-account mt-5">
                <h4> Delete account</h4>
                <p className="body-4 text-muted">
                  Once you delete your account, there is no going back. Please be
                  certain.
                </p>

                <div className="mt-3 d-flex gap-3">
                  <button className="btn btn-reject rounded-pill btn-sm fw-bold"  data-bs-toggle="modal" data-bs-target="#deleteModal">
                    {" "}
                    Delete account{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

     {/* delete warning Modal  */}
     <div
          className="modal fade"
          id="deleteModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body text-center px-4">
                <img
                  src={require("../../../img/delete-warning.png")}
                  width={56}
                  height={56}
                  alt="delete warning"
                />
                <h2 className="mt-3">Do you want to delete the volunteer? </h2>
                <p>
                By deleting, this volunteer will no longer be able to support your campaign through Campaign Brain.
                </p>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-link rounded-pill btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                
                <button className="btn btn-danger rounded-pill btn-xs">
                 
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>
    
    </>
  );
};

export default PersonalDetails;

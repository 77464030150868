import { IBudgetFileDetails } from "components/pages/dashboard/account-setup/Budget";
import InfoIconPrimary from "img/svg/InfoIconPrimary";
import InfoIconWarning from "img/svg/InfoIconWarning";
import { Link } from "react-router-dom";
import { IFundraisingData } from "../budget-overview/BudgetOverview";

interface ITopBanner {
  funraisingData: IFundraisingData;
  budgetDetailsFromApi: IBudgetFileDetails;
}

const TopBanner = ({ funraisingData, budgetDetailsFromApi }: ITopBanner) => {
  function getAlertMessage() {
    if (!!funraisingData?.fundraisingGoal && !budgetDetailsFromApi) {
      return "Budget based on fundraising goal provided.";
    }
    if (!!budgetDetailsFromApi) {
      return "Budget based on uploaded budget file";
    }
  }

  return (
    <>
      {(!!funraisingData?.fundraisingGoal || !!budgetDetailsFromApi) && (
        <div
          className="alert alert-primary alert-dismissible fade show"
          role="alert"
        >
          <InfoIconPrimary /> <span className="ms-2">{getAlertMessage()}</span>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          >
            <span className="icon-close"></span>
          </button>
        </div>
      )}
      {!budgetDetailsFromApi && !funraisingData?.fundraisingGoal && (
        <div className="alert alert-warning fade show" role="alert">
          <div className="d-flex justify-content-between">
            <span>
              <InfoIconWarning />
              <span className="ms-2">
                Add fundraising goal in settings or upload budget to generate
                predefined budget.
              </span>
            </span>
            <div className="d-flex gap-2">
              <Link
                to="/profile?tabIndex=3"
                className="btn btn-link no-hover btn-xs text-warning py-0 px-0 fs-8"
              >
                Go to Settings
              </Link>
              <span className="hr-verticle"></span>
              <button
                className="btn btn-link no-hover btn-xs text-warning py-0 px-0 fs-8"
                data-bs-toggle="modal"
                data-bs-target="#uploadModal"
              >
                Upload budget
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TopBanner;

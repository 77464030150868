import React from 'react'

const LocationIconSeconary = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.636 7.29131C14.1508 3.77659 19.8492 3.77659 23.364 7.29131C26.8787 10.806 26.8787 16.5045 23.364 20.0192L17 26.3832L10.636 20.0192C7.12132 16.5045 7.12132 10.806 10.636 7.29131ZM17 16.2267C18.4202 16.2267 19.5714 15.0754 19.5714 13.6553C19.5714 12.2351 18.4202 11.0838 17 11.0838C15.5798 11.0838 14.4286 12.2351 14.4286 13.6553C14.4286 15.0754 15.5798 16.2267 17 16.2267Z" fill="#F4F4F6"/>
    </svg>
  )
}

export default LocationIconSeconary

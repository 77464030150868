import { useQuery } from '@tanstack/react-query'
import { FILE_OWNER } from 'constant/file'
import FileIcon from 'img/svg/fileIcon'
import { useMemo } from 'react'
import CreateAuthAxiosInstance from 'utils/authAxios'
import { globalQueryConfig } from 'utils/reactQuery'
import { dateFormatter, formatFileSize } from 'utils/uploadFile'

const DonorListSection = () => {
  const authAxios = CreateAuthAxiosInstance()

  const { data: donorFileList } = useQuery({
    queryFn: () => authAxios("api/v1/file/file-type/2").then(response => response?.data),
    queryKey: ["fetch-donorFile"],
    ...globalQueryConfig,
  })

  const donorFile = useMemo(() => {
    return donorFileList?.find((file: any) => file.createdBy === FILE_OWNER.ADMIN) || null
  }, [donorFileList])

  const handleDownload = (url: string | null | undefined) => {
    if (url) {
      window.open(url, "_blank")
    }
  }

  return (
    <div className="row">
      <div className="col-md-4">
        <span className="body-2 fs-7"> Donor list </span>
      </div>
      <div className="col-md-8">
        {donorFile ? (
          <div className="uploadedCard mb-0 p-0 border-0">
            <div className="uploadedCard-icon">
              <div className="iconBox">
                <FileIcon />
              </div>
              <div className="iconInfo">
                <span className="iconInfo-title">
                  {donorFile?.fileName}
                </span>
                <p className="iconInfo-desc">
                  {formatFileSize(donorFile?.size)},
                  {dateFormatter(donorFile?.updatedAt)}
                </p>
              </div>
            </div>
            <div className="uploadedCard-info">
              <button
                className="btn btn-link rounded-pill btn-xs"
                onClick={() => handleDownload(donorFile?.url)}
              >
                Download
              </button>
            </div>
          </div>
        ) : (
          <span className="body-4 text-muted">
            Not Provided
          </span>
        )}
      </div>
    </div>
  )
}

export default DonorListSection
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from '../common/Spinner';

export interface IRegisteredUser {
  id: number;
  auth0Id: string;
  email: string;
  fullName: string;
  isDashboardSetupCompleted: boolean;
  isOnboardingCompleted: boolean;
  roleId: number;
  isActive?: boolean;
  isArchived?: boolean;
}

export const CANDIDATE_ID = "userId";
export const ONBOARDING_STATUS = "isOnboardingCompleted";

const Homepage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, user, getIdTokenClaims } =
    useAuth0();

  const [registeredUser, setRegisteredUser] = useState<IRegisteredUser>();

  useEffect(() => {
    const checkTokenExpiry = async () => {
      if (isAuthenticated) {
        try {
          const idTokenClaims = await getIdTokenClaims();
          const exp = idTokenClaims?.exp;
          if (exp) {
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);
            if (currentTimeInSeconds >= exp) {
              console.log("Token is expired");
              navigate("/login");
            }
          }
        } catch (error) {
          console.error("Error fetching token claims:", error);
        }
      }
    };
    checkTokenExpiry();
  });

  useEffect(() => {
    if (user && isAuthenticated) {
      (async () => {
        try {
          const token = await getIdTokenClaims();
          // Get registered user details
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/detail`,
            {
              headers: {
                Authorization: `Bearer ${token?.__raw}`,
              },
            }
          );
          setRegisteredUser(response.data);
        } catch (error: any) {
          console.log(error);
          // if (error?.response?.data?.message?.includes("User not found"))
          //   // Register User
          //   handleRegisterUser();
        }
      })();
    }
  }, [user, getIdTokenClaims, isAuthenticated]);

  useEffect(() => {
    if (registeredUser?.id) {
      localStorage.setItem(CANDIDATE_ID, String(registeredUser?.id));
      localStorage.setItem(
        ONBOARDING_STATUS,
        String(registeredUser?.isOnboardingCompleted)
      );
    }

    if (isAuthenticated && !registeredUser?.isOnboardingCompleted) {
      navigate("/onboarding");
    }

    if (isAuthenticated && registeredUser?.isOnboardingCompleted) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredUser]);

  return (
    <div
      className="text-center"
      style={{ marginTop: "5rem", position: "relative" }}
    >
      {isAuthenticated || isLoading ? (
        <div className="bg-white spinner-wrapper shadow-2">
          {" "}
          <Spinner varient="text-primary" />{" "}
        </div>
      ) : (
        <h1>Welcome</h1>
      )}
    </div>
  );
};

export default Homepage;

import React from "react";
import SideNavbar from "../../components/common/navbar/SideNavbar";
import { Link } from "react-router-dom";
import CheckSuccess from "../../img/svg/checkSuccess";
import Stepper from "../components/Stepper";
import FileUpload from "../components/FileUpload";
import FileIcon from "img/svg/fileIcon";
import InfoIconDanger from "img/svg/InfoIconDanger";

const AddBudget = () => {
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="row">
            <div className="col-md-4">
              <div className="accountSetup accountSetup-fixed">
                <div className="accountSetup-header">
                  <h5>Account setup list</h5>
                  <p>Setup your account to use Campaign Brain</p>
                </div>
                <div className="accountSetup-body">
                  <Stepper />
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="card card-accountSetup shadow-2">
                <div className="card-title">
                  <h4>Budget</h4>
                  <p>
                    {" "}
                    To add projected budget for your campaign, choose one of the
                    following three options below.
                  </p>
                </div>

                <div className="card-body">
                  <form action="">
                    <div className="mb-4">
                      <div className="d-flex">
                        <div className="mb-2 customRadio-wrapper">
                          <label className="customRadio form-check-label body-4">
                            <input type="radio" checked name="radio" />
                            <span className="checkmark"></span>
                          </label>
                        </div>

                        <div className="groupField disable">
                          <label className="body-2 d-block form-label mb-0">
                            {" "}
                            File upload{" "}
                          </label>
                          <p className="body-4 text-muted mb-1">
                            Upload a file of xls format containing information
                            about budget of your campaign.
                          </p>
                          <p className="body-4 text-muted mb-3">
                            (You can <Link to=""> download our template </Link>{" "}
                            )
                          </p>

                          <FileUpload />
                        {/* after file uploaded */}
                        <div className="row mb-4">
                          <div className="col-md-12">
                            <div className="uploadedCard uploadedCard-danger mb-0">
                              <div className="uploadedCard-icon">
                                <div className="iconBox">
                                  <FileIcon />
                                </div>

                                <div className="iconInfo">
                                  <span className="iconInfo-title">
                                    {" "}
                                    Voter List.pdf{" "}
                                  </span>
                                  <p className="iconInfo-desc">
                                    {" "}
                                    14 KB, 6/23/23 at 11:45 am{" "}
                                  </p>
                                  <div className="messageWrapper">
                                    {/* if successful */}
                                    {/* <span className="iconInfo-status">
                                {" "}
                                <CheckSuccess /> File uploaded{" "}
                              </span> */}

                                    {/* if error */}
                                    <span className="iconInfo-status">
                                      <div className="text-danger">
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 1</strong>: File is not
                                          in CSV format
                                        </span>

                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 2</strong>: File is not
                                          in CSV format
                                        </span>

                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 3</strong>: File is not
                                          in CSV format
                                        </span>

                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 4</strong>: File is not
                                          in CSV format
                                        </span>
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 5</strong>: File is not
                                          in CSV format
                                        </span>
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 6</strong>: File is not
                                          in CSV format
                                        </span>
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 7</strong>: File is not
                                          in CSV format
                                        </span>
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 8</strong>: File is not
                                          in CSV format
                                        </span>
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 9</strong>: File is not
                                          in CSV format
                                        </span>
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 10</strong>: File is
                                          not in CSV format
                                        </span>
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 11</strong>: File is
                                          not in CSV format
                                        </span>
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 12</strong>: File is
                                          not in CSV format
                                        </span>
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 13</strong>: File is
                                          not in CSV format
                                        </span>
                                        <span className="d-block">
                                          <InfoIconDanger />{" "}
                                          <strong> Error 14</strong>: File is
                                          not in CSV format
                                        </span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="uploadedCard-info">
                                <div className="file-btn">
                                  <button className="btn btn-link rounded-pill text-primary">
                                    {" "}
                                    Change File
                                    <input type="file" name="file" />
                                  </button>
                                </div>

                                <button className="btn btn-xs">
                                  <span className="icon-trash"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 customRadio-wrapper">
                      <label className="customRadio form-check-label body-4">
                        I do not have a budget created and will add one later.
                        <input type="radio" checked name="radio" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="customRadio-wrapper groupField disabled">
                      <label className="customRadio form-check-label body-4">
                        I do not have a budget created and would like Campaign
                        Brain to generate one based off my fundraising goal.
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                      </label>

                    </div>
                       <div className="ps-4">
                      

                      <p className="text-italic fs-7">
                      Note: If you haven't entered a fundraising goal yet, this option is currently unavailable. You can update it later in Settings.
                      </p>
                    </div>

                    {/* <div className="mb-3">
                      <span className="body-5 mb-1 d-block"> Disclaimer</span>

                      <p className="text-italic fs-7">
                        "If your do not upload your budget, Campaign Brain will automatically will update a budget in budget module."
                      </p>
                    </div> */}
                  </form>
                </div>

                <div className="card-footer shadow-6 d-flex gap-4 justify-content-end">
                  <Link
                    to="/candidate/add-donor"
                    className="btn btn-link text-primary btn-xs"
                  >
                    Not Now{" "}
                  </Link>
                  <Link
                    to="/candidate/add-donor"
                    className="btn btn-primary rounded-pill btn-xs px-6"
                  >
                    Next{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AddBudget;

import DeviceIcon from "img/svg/DeviceIcon";
import InfoIconDanger from "img/svg/InfoIconDanger";
import React from "react";
import { Link } from "react-router-dom";
import Spinner from "templates/components/Spinner";

const SecurityDetails = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="mt-5">
            <div className="settings">
              <div className="settings-header d-flex justify-content-between">
                <div className="">
                  <h4> Login </h4>
                  <p className="body-4 text-muted">
                    {" "}
                    Set a unique password to protect your account{" "}
                  </p>
                </div>
              </div>

              <div className="settings-body">
                <div className="passwordChange-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Change password </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          <span className="body-4 text-muted">
                            {" "}
                            Set a unique password to protect your account{" "}
                          </span>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-link btn-xs px-1 py-0"
                              data-bs-toggle="modal"
                              data-bs-target="#editPasswordModal"
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                </div>
                <div className="loginActivity-info mt-5">
                  <h4> Active devices </h4>
                  <p className="body-4 text-muted">
                    Check and manage logged in devices
                  </p>
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Devices</span>
                    </div>
                    <div className="col-md-8">
                      {/* loggedin devices list */}
                      <div className="media mb-3">
                        <div className="media-icon">
                          <DeviceIcon />
                        </div>
                        <div className="media-body w-100">
                          <div className="row">
                            <div className="col-md-8">
                              <span className="body-2">Windows PC</span>
                              <div className="body-4 text-muted mb-0">
                                Kathmandu, Nepal{" "}
                                <span className="dot bg-neutral-n3 ms-2"></span>
                                <span className="text-s2 fw-semibold">
                                  {" "}
                                  Active{" "}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="d-flex justify-content-end">
                                <button
                                  className="btn btn-link text-muted btn-xs px-1 py-0"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteDeviceModal"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="media mb-3">
                        <div className="media-icon">
                          <DeviceIcon />
                        </div>
                        <div className="media-body w-100">
                          <div className="row">
                            <div className="col-md-8">
                              <span className="body-2">Android</span>
                              <div className="body-4 text-muted mb-0">
                                Kathmandu, Nepal{" "}
                                <span className="dot bg-neutral-n3 ms-2"></span>
                                Yesterday at 06:41 
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="d-flex justify-content-end">
                                <button
                                  className="btn btn-link text-danger btn-xs px-1 py-0"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editPasswordModal"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* password Modal */}
      <div
        className="modal fade"
        id="editPasswordModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header border-1">
              <h5 className="modal-title body-2 mb-0">Change Password </h5>
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="mb-3 required">
                  <label htmlFor="inputCuPass" className="form-label">
                    Current password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCuPass"
                    aria-describedby="passHelp"
                    placeholder="Enter current password"
                  />
                </div>
                <div className="mb-3 required">
                  <label htmlFor="newPassword" className="form-label">
                    New Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="newPassword"
                    aria-describedby="newPassHelp"
                    placeholder=""
                  />
                </div>
                <div className="mb-3 required">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm new password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="confirmPassword"
                    aria-describedby="confirmPass"
                    placeholder=""
                  />
                  <div className="invalidInput form-text text-danger">
                    {" "}
                    <InfoIconDanger /> Password does not match{" "}
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer border-1">
              <Link
                to=""
                className="btn btn-link text-primary btn-xs rounded-pill"
                data-bs-dismiss="modal"
              >
                Cancel
              </Link>
              {/* remove button disabled class after spinner goes off */}
              <Link
                to=""
                className="btn btn-primary rounded-pill btn-xs px-6 disabled"
              >
                <Spinner />
                Change password
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Changed password Modal */}
      <div
          className="modal fade"
          id=""
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                
              </div>
              <div className="modal-body text-center px-4">
                <img
                  src={require("../../../img/lock.png")}
                  width={56}
                  height={56}
                  alt="delete warning"
                />
                <h2 className="mt-3">Password Changed </h2>
                <p>
                Signed out from all devices. Please log in with new password to continue.
                </p>
              </div>

              <div className="modal-footer">
               
                
                <button className="btn btn-danger rounded-pill btn-xs">
                 
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default SecurityDetails;

import LogoOnly from "img/svg/logoOnly";
import TwitterIcon from "img/svg/twitter";
import { Link } from "react-router-dom";

const SocialMedia = () => {
  return (
    <>
      {/* media object */}
      <div className="media">
        <div className="media-header">
          {/* <span className="media-header--text"> </span> */}
          <LogoOnly />
          
        </div>
        <div className="media-body">
        
          <h4>
            Topic : <span> Thanksgiving </span>
          </h4>
          <h4>
            Scheduled for: <span>3:30pm </span>
            <span className="dot bg-neutral-n3 ms-1"> </span>
            <span>Aug 4, 2023 </span>
          </h4>
        </div>
      </div>

      {/* Social media post */}
      <div className="mediaPost editable-activ mt-3">
        <div className="mediaPost-header">
          <div className="iconBox twitter">
            <TwitterIcon />
          </div>
        </div>
        <div className="mediaPost-body">
          <div className="platform">
            <h4 className="platform-title"> Twitter</h4>

            <div className="platform-action">
              <Link to="" className="btn btn-link p-0 ms-2 d-inline-block"> <span className="icon-edit"> </span> Edit </Link>
              <Link to="" className="btn btn-link p-0 ms-4 d-inline-block reload-btn"> <span className="icon-reload"> </span>Regenerate </Link>

              {/* <Link to="" className="btn btn-link p-0 ms-2 d-inline-block">
                Cancel
              </Link>
              <Link to="" className="btn btn-link p-0 ms-4 d-inline-block">
                <span className="icon-tick"> </span> Save Changes
              </Link> */}
            </div>
          </div>

          <div className="editableBox mt-2" contentEditable="true">
            <p>"🦃 HAPPY THANKSGIVING! 🦃</p>
            <p>
              Wishing you all a delightful Thanksgiving filled with love,
              gratitude, and cherished moments with family and friends! 🍁🍗🍂
            </p>
          </div>
        </div>
      </div>

      <div className="modal-footer justify-content-between px-0 pt-4">
        <div className="action-btns">
          <button className="btn btn-primary btn-xs rounded-pill me-3 disabled">

            Approve post
          </button>
          <button className="btn btn-reject btn-xs rounded-pill"> Reject post </button>

          {/* approved */}
          {/* <button className="btn bg-success-s2 text-s1 rounded-pill me-3" disabled> <span className="icon-tick"></span> Approved </button> */}

          {/* rejected */}
          {/* <button className="btn btn-reject rounded-pill me-3" disabled> <span className="icon-close"></span> Rejected </button> */}
        </div>

        <div className="next-btn">
          <button className="btn rounded-pill btn-link btn-xs">Skip post</button>
        </div>
      </div>
    </>
  );
};

export default SocialMedia;

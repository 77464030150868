export const setLocalStorageItem=(key:string, value:any)=> {
    localStorage.setItem(key, value);
  }
  
  export const getLocalStorageItem=(key:string, defaultValue?:any)=> {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  }

  export const removeLocalStorageItem=(key:string)=>{
    localStorage.removeItem(key);
  }
  

  

 
  
import React from "react";

const FileUpload = () => {
  return (
    <div className="row">
      <div className="col-md-9">
        <div className="uploadedCard">
          <div className="uploadedCard-icon">
            <div className="iconInfo">
              <span className="iconInfo-title text-muted">
                (pdf, jpg, jpeg, xls, or any){" "}
                <span className="dot bg-neutral-n3 ms-1"> </span> 2MB Max
              </span>
            </div>
          </div>
          <div className="uploadedCard-info file-btn">
            <button className="btn btn-info rounded-pill">
              {" "}
              Upload File
              <input type="file" name="file" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;

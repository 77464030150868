import FbSmall from "img/svg/fbSmall";
import TwitterIcon from "img/svg/twitter";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FbIcon from "img/svg/fb";
import useIntegrationDetails from "./useIntegrationDetails";
import DisabledDangerIcon from "img/svg/DisabledDangerIcon";
import InfoIconWarning from "img/svg/InfoIconWarning";
import CustomTooltip from "components/common/CustomTooltip";
import HelpIcon from "img/svg/helpIcon";
import DisableIntegrationModal from "./DisableIntegrationModal";
import { useState } from "react";
import Loader from "components/common/Loader";

const ACTIVE_VOLUNTEER_TOOLTIP =
  "Volunteers who have verified their phone numbers for auto dialer integration and are still active.";

const VOLUNTEER_ACTION_TOOLTIP =
  "Disable volunteers to deactivating their auto-dialer services.  Disabled volunteers will no longer be able to utilize auto-dialer  services.";

const IntegrationsDetails = () => {
  const {
    isFbConnected,
    responseFacebook,
    fbResponse,
    isTwitterConnected,
    twitterResponse,
    twitterLogin,
    twitterLogout,
    facebookLogout,
    autoDialerData,
    isAutoDialerDataLoading,
  } = useIntegrationDetails();

  const defaultImg = require("../../../../img/avatar.png");

  const candidateAutodialerData = autoDialerData?.candidate;
  const volunteersAutodialerData = autoDialerData?.volunteers;
  const integratedVolunteersCount =
    autoDialerData?.integratedVolunteersCount ?? 0;

  const [volunteerId, setVolunteerId] = useState<number>();

  const hasCandidateRegisteredPhoneNumber =
    candidateAutodialerData?.autoDialerStatus === undefined ||
    candidateAutodialerData?.autoDialerStatus === null ||
    candidateAutodialerData?.autoDialerStatus === "Rejected"
      ? false
      : true;

  return (
    <div className="row mt-5">
      <div className="col-md-8">
        <div className="settings">
          <div className="settings-header d-flex justify-content-between">
            <div className="">
              <h4> Social integrations</h4>
              <p className="body-4 text-muted">
                Connect or remove the social integrations
              </p>
            </div>
          </div>

          <div className="settings-body">
            <div className="professional-info">
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-12">
                  {/* connected card fb*/}
                  {isFbConnected ? (
                    <div className="connectCard mb-0 p-0 border-0 shadow-none">
                      <div className="connectCard-icon connected">
                        <div className="profileBox fb">
                          <img
                            src={fbResponse?.picture?.data?.url ?? defaultImg}
                            className="img-fluid"
                            alt=""
                          />

                          <div className="profileBox-logo">
                            <FbSmall />
                          </div>
                        </div>
                        <div className="iconInfo">
                          <span className="iconInfo-platform"> Facebook </span>
                          <p className="mb-0">
                            <span className="text-success">Connected </span> as
                            {fbResponse?.name}
                          </p>
                        </div>
                      </div>
                      <div className="connectCard-info">
                        <button
                          className="btn btn-link rounded-pill text-danger"
                          // data-bs-toggle="modal"
                          // data-bs-target="#removeIntegrationModal"
                          onClick={() => {
                            facebookLogout();
                          }}
                        >
                          {" "}
                          Remove{" "}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <FacebookLogin
                      appId={3592166627694494}
                      state={{ config_id: "922691438804656" }}
                      // autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      render={(renderProps: any) => (
                        <div className="connectCard mb-0 p-0 border-0 shadow-none">
                          <div className="connectCard-icon">
                            <div className="iconBox twitter">
                              <FbIcon />
                            </div>
                            <div className="iconInfo">
                              <span className="iconInfo-platform">
                                {" "}
                                Facebook{" "}
                              </span>
                            </div>
                          </div>
                          <div className="connectCard-info">
                            <button
                              className="btn btn-info rounded-pill"
                              onClick={renderProps.onClick}
                            >
                              {" "}
                              Connect{" "}
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-12">
                  {/* connect card twitter*/}
                  {isTwitterConnected ? (
                    <div className="connectCard mb-0 p-0 border-0 shadow-none">
                      <div className="connectCard-icon connected">
                        <div className="profileBox fb">
                          <img
                            src={twitterResponse?.profilePicture ?? defaultImg}
                            className="img-fluid"
                            alt=""
                          />

                          <div className="profileBox-logo">
                            <TwitterIcon />
                          </div>
                        </div>
                        <div className="iconInfo">
                          <span className="iconInfo-platform"> Twitter </span>
                          <p className="mb-0">
                            <span className="text-success">Connected </span> as{" "}
                            {twitterResponse?.name}
                          </p>
                        </div>
                      </div>
                      <div className="connectCard-info">
                        <button
                          className="btn btn-link rounded-pill text-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#removeIntegrationModal"
                          onClick={() => {
                            twitterLogout();
                          }}
                        >
                          {" "}
                          Remove{" "}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="connectCard mb-0 p-0 border-0 shadow-none">
                      <div className="connectCard-icon">
                        <div className="iconBox twitter">
                          <TwitterIcon />
                        </div>
                        <div className="iconInfo">
                          <span className="iconInfo-platform"> Twitter </span>
                        </div>
                      </div>
                      <div className="connectCard-info">
                        <button
                          className="btn btn-info rounded-pill"
                          onClick={() => twitterLogin()}
                        >
                          {" "}
                          Connect{" "}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* AutoDialer Integration */}
          <div className="mt-5">
            <div className="settings">
              <div className="settings-header d-flex justify-content-between">
                <div className="">
                  <h4> Auto dialer integration </h4>
                  <p className="body-4 text-muted">
                    Auto dialer services not activated. Verify phone number to
                    activate.
                  </p>
                </div>
              </div>

              <div className="settings-body">
                <div className="voterDetail-info">
                  <hr className="mt-3 mb-3" />
                  {(hasCandidateRegisteredPhoneNumber ||
                    integratedVolunteersCount > 0) && (
                    <div className="row">
                      <div className="col-md-12">
                        {candidateAutodialerData?.autoDialerStatus ===
                        "Inactive" ? (
                          <div
                            className="alert alert-warning alert-dismissible fade show"
                            role="alert"
                          >
                            <InfoIconWarning />{" "}
                            <span className="ms-2">
                              Auto dialer integration deactivated.
                            </span>
                          </div>
                        ) : (
                          <div className="d-flex">
                            <button
                              className="btn btn-link btn-xs px-1 py-0"
                              data-bs-toggle="modal"
                              data-bs-target="#disableModal"
                              onClick={() => setVolunteerId(undefined)}
                            >
                              Deactivate integration
                            </button>
                          </div>
                        )}
                      </div>
                      <hr className="mt-3 mb-3" />
                    </div>
                  )}
                  {(hasCandidateRegisteredPhoneNumber ||
                    integratedVolunteersCount > 0) && (
                    <div className="row mt-2">
                      <div className="col-md-4 col-12 mb-3">
                        <div className="card card-flush h-100 border-1">
                          <div className="card-header d-flex justify-content-between">
                            <h4 className="card-title"> Active volunteers </h4>
                            <div className="card-info">
                              <CustomTooltip content={ACTIVE_VOLUNTEER_TOOLTIP}>
                                <span className="text-left">
                                  {" "}
                                  <HelpIcon />{" "}
                                </span>
                              </CustomTooltip>
                            </div>
                          </div>
                          <div className="card-body pt-1 d-flex flex-column justify-content-end">
                            {isAutoDialerDataLoading ? (
                              <div className="placeholder-glow w-100">
                                <div className="placeholder col-6 py-3"></div>
                              </div>
                            ) : (
                              <div className="d-flex align-items-end">
                                <h1 className="mb-0 me-2">
                                  {integratedVolunteersCount}
                                </h1>{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {(hasCandidateRegisteredPhoneNumber ||
                    integratedVolunteersCount > 0) && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-fullHeight scroll-y mt-3 border-1 card-callLogs scroll-y">
                          {/* table */}
                          <table className="table table-full table-eq-height table-hover">
                            <thead>
                              <tr>
                                <th>Name </th>
                                <th> Phone no.</th>
                                <th> Role </th>
                                <th> Auto dialer status </th>
                                <th>
                                  <CustomTooltip
                                    content={VOLUNTEER_ACTION_TOOLTIP}
                                  >
                                    <span>
                                      Action
                                      <span className="ms-2">
                                        <HelpIcon />
                                      </span>
                                    </span>
                                  </CustomTooltip>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Candidate information */}
                              {hasCandidateRegisteredPhoneNumber && (
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={
                                          candidateAutodialerData?.profilePicture ??
                                          defaultImg
                                        }
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                        className="avatar border-0 img-fluid rounded-circle me-2 mb-0"
                                        alt="User Avatar"
                                      />

                                      <div>
                                        <span className="text-dark userName d-block">
                                          {candidateAutodialerData?.fullName}
                                        </span>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <span>
                                      {
                                        candidateAutodialerData?.phoneNumber
                                          ?.phoneNumber
                                      }
                                    </span>
                                  </td>

                                  <td>
                                    {" "}
                                    <span> Candidate (You) </span>{" "}
                                  </td>

                                  <td>
                                    <span
                                      className={`badge font-semibold rounded-pill ${
                                        candidateAutodialerData?.autoDialerStatus ===
                                          "Integrated" && "badge-success"
                                      }
                                      ${
                                        candidateAutodialerData?.autoDialerStatus ===
                                          "Disabled" && "badge-danger"
                                      }
                                      ${
                                        candidateAutodialerData?.autoDialerStatus ===
                                          "Inactive" && "badge-warning"
                                      }
                                      `}
                                    >
                                      <span className="badge-label">
                                        {
                                          candidateAutodialerData?.autoDialerStatus
                                        }
                                      </span>
                                    </span>

                                    {/* <span className="d-block"> - </span>  */}
                                  </td>

                                  <td className="text-center"></td>
                                </tr>
                              )}

                              {/* <tr className="p-5 m-5">
                              <Loader />
                            </tr> */}
                              {/* Volunteer information */}
                              {volunteersAutodialerData?.map(
                                (volunteer, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={
                                            volunteer?.profilePicture ??
                                            defaultImg
                                          }
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                          className="avatar border-0 img-fluid rounded-circle me-2 mb-0"
                                          alt="User Avatar"
                                        />

                                        <div>
                                          <span className="text-dark userName d-block">
                                            {volunteer?.fullName}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {" "}
                                      <span>
                                        {volunteer?.phoneNumber?.phoneNumber}
                                      </span>
                                    </td>
                                    <td>
                                      {" "}
                                      <span> Volunteer </span>{" "}
                                    </td>

                                    <td>
                                      <span
                                        className={`badge font-semibold rounded-pill ${
                                          volunteer?.autoDialerStatus ===
                                            "Integrated" && "badge-success"
                                        }
                                        ${
                                          volunteer?.autoDialerStatus ===
                                            "Disabled" && "badge-danger"
                                        }
                                        ${
                                          volunteer?.autoDialerStatus ===
                                            "Inactive" && "badge-warning"
                                        }
                                        `}
                                      >
                                        <span className="badge-label">
                                          {volunteer?.autoDialerStatus}
                                        </span>
                                      </span>

                                      {/* <span className="d-block"> - </span>  */}
                                    </td>

                                    <td className="text-center">
                                      <button
                                        className="btn no-hover btn-link text-danger px-1 py-0 fs-7 d-flex align-items-center gap-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#disableModal"
                                        disabled={
                                          volunteer?.autoDialerStatus !==
                                          "Integrated"
                                        }
                                        onClick={() => {
                                          setVolunteerId(volunteer?.id);
                                        }}
                                      >
                                        {" "}
                                        <DisabledDangerIcon /> Disable{" "}
                                      </button>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>

                          {/* if no data */}
                          {/* { (
                            <div className="noData d-flex gap-2 flex-row align-items-center">
                              <InfoIconSecondary />
                              <p className="m-0 text-muted fs-7">
                                {" "}
                                No tasks available for today{" "}
                              </p>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Social media delete warning Modal  */}
      <div
        className="modal fade"
        id="removeIntegrationModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={twitterResponse?.profilePicture ?? defaultImg}
                width={56}
                height={56}
                alt="delete warning"
              />
              <h2 className="mt-3">Remove Twitter Integration? </h2>
              <p>
                By removing Twitter integration, Campaign Brain will no longer
                be able to suggest posts for Twitter.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link rounded-pill btn-xs"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button
                className="btn btn-primary rounded-pill btn-xs"
                onClick={() => {
                  twitterLogout();
                }}
                data-bs-dismiss="modal"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* integration disable Modal  */}
      {volunteerId ? (
        <DisableIntegrationModal
          content="This volunteer will no longer be able to access the auto dialer."
          volunteerId={volunteerId}
          isVolunteer={true}
        />
      ) : (
        <DisableIntegrationModal
          content="You and volunteers will no longer be able to access the auto dialer. Once the integration is deactivated you can't activate again."
          isVolunteer={false}
        />
      )}
    </div>
  );
};

export default IntegrationsDetails;

import React from "react";
import CheckIcon from "../../img/svg/checkIcon";

const Timeline = ({
  titles,
  currentIndex,
  hideActiveTitle = false,
}: {
  titles: String[];
  currentIndex: number;
  hideActiveTitle?: boolean;
}) => {
  const completedTitles = titles.slice(0, currentIndex);
  const remainingTitles = titles.slice(currentIndex + 1);
  return (
    <div className="timeline">
      {completedTitles.map((title, index) => (
        <div className="timeline-item active success" key={index}>
          <div className="timeline-step">
            <CheckIcon />
          </div>
          <div className="timeline-content">
            <h6 className="mb-1">{title} </h6>
          </div>
        </div>
      ))}

      {!hideActiveTitle && (
        <div className="timeline-item active">
          <div className="timeline-step">
            <span>{currentIndex + 1} </span>
          </div>
          <div className="timeline-content">
            <h6 className="mb-1"> {titles[currentIndex]} </h6>
          </div>
        </div>
      )}

      {remainingTitles.map((title, index) => (
        <div className="timeline-item" key={index}>
          <div className="timeline-step">
            <span>{currentIndex + index + 2}</span>
          </div>

          <div className="timeline-content">
            <h6 className="mb-1"> {title} </h6>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;

import { Link } from "react-router-dom";
import WarningIcon from "img/svg/warningIcon";
import BreadCrumbs from "components/common/BreadCrumbs";
import SidePanel, { IVoterContactHistory } from "./SidePanel";
import { useEffect, useMemo, useRef, useState } from "react";
import Map from "./Map";
import { useLoadScript } from "@react-google-maps/api";
import Loader from "components/common/Loader";
import axios from "axios";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { DOOR_TO_DOOR } from "../voters-list/VotersList";
import { CANDIDATE_ID } from "components/pages/Homepage";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useQuery } from "@tanstack/react-query";
import { DAYS_UNTIL_ELECTION } from "components/pages/dashboard/Dashboard";

export interface IVotersDetails {
  id: number;
  address: string;
  name: string;
  geoCoordinates: number[];
  partyAffiliation: string;
  age: string;
  sex: string;
  voterContactHistories: IVoterContactHistory[];
}

export interface IInfoWindow {
  id: number;
  voters: IVotersDetails[];
}

export const VOTERS_LIMIT = 10;

export const getUniqueArrayFromArrayofArrays = (
  arrayOfArrays: number[][]
): number[][] => {
  arrayOfArrays = arrayOfArrays.filter(item => item)
  const uniqueSet = new Set(arrayOfArrays.map((item) => JSON.stringify(item)));
  const uniqueArray = Array.from(uniqueSet).map((item) =>
    JSON.parse(item)
  ) as number[][];
  return uniqueArray;
};

export const areArraysEqual = (arr1: number[], arr2: number[]): boolean => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

const DoortoDoor = () => {
  const userId = localStorage.getItem(CANDIDATE_ID);
  const { getIdTokenClaims } = useAuth0();
  const daysUntilElection = localStorage.getItem(DAYS_UNTIL_ELECTION);

  const [showSidePanel, setShowSidePanel] = useState(false);
  const [hasVisitedVoters, setHasVisitedVoters] = useState<Array<boolean>>([]);

  const [votersDetails, setVotersDetails] = useState<Array<IVotersDetails[]>>(
    []
  );
  const [activeVoters, setActiveVoters] = useState<Array<IVotersDetails>>([]);

  const [votersCoordinates, setVotersCordinates] = useState<Array<number[]>>(
    []
  );
  // const [voterIds, setVoterIds] = useState<Array<number>>([]);

  const [activeCoordinates, setActiveCoordinates] = useState<Array<number>>([]);
  const [activeAddressIndex, setActiveAddressIndex] = useState(-1);
  const [contactedVotersCount, setContactedVotersCount] = useState(0);
  const [dailyGoalVotersCount, setDailyGoalVotersCount] = useState(0);

  const [isVoterResponseSubmitted, setIsVoterResponseSubmitted] =
    useState(false);

  // const successModalRef = useRef<HTMLButtonElement>(null);
  const skipModalRef = useRef<HTMLButtonElement>(null);
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [
    canSubmitAllVotersDefaultResponse,
    setCanSubmitAllVotersDefaultResponse,
  ] = useState(false);
  const [token, setToken] = useState<IdToken>();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? "",
    libraries: ["drawing"],
    mapIds: [process.env.REACT_APP_GOOGLE_MAP_ID ?? ""],
  });

  const getContactedVoters = async () => {
    try {
      const token = await getIdTokenClaims();

      // Get contacted voters
      const contactedVoterResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL
        }/api/v1/task-stats/todays-completed-task-counts?timestamp=${new Date().toLocaleString(
          "US"
        )}
          `,
        {
          headers: {
            Authorization: `Bearer ${token?.__raw}`,
          },
        }
      );
      setContactedVotersCount(contactedVoterResponse.data.doorTodoorCount);
      // setHasVotersResponseSubmitted(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getVoterDetails = async () => {
    try {
      const contactPlanResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/contact-plans/daily-goal?daysUntilElection=${daysUntilElection}`,
        {
          headers: {
            Authorization: `Bearer ${token?.__raw}`,
          },
        }
      );
      const voters: IVotersDetails[] = contactPlanResponse.data;
      const mappedVotersCoordinates = getUniqueArrayFromArrayofArrays(
        voters.map((item) => item.geoCoordinates)
      );
      let voterInfo: IVotersDetails[] = [];
      const voterInfos: IVotersDetails[][] = [];
      mappedVotersCoordinates.forEach((coordinates) => {
        voterInfo = [];
        voters?.forEach((voter) => {
          if (areArraysEqual(voter.geoCoordinates, coordinates)) {
            voterInfo.push(voter);
          }
        });
        voterInfo.length && voterInfos.push(voterInfo);
      });

      setVotersCordinates(mappedVotersCoordinates);
      setVotersDetails(voterInfos);
      getContactedVoters();
      // setIsVotersDetailsSet(true);
      if (isVoterResponseSubmitted)
        setActiveVoters(voterInfos[activeAddressIndex] ?? []);
      setIsVoterResponseSubmitted(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const authAxios = CreateAuthAxiosInstance();
  const retrievedCandidateId = localStorage.getItem(CANDIDATE_ID);

  const { data: candidateInfo, status: candidateInfoStatus } = useQuery({
    queryKey: ["candidateInfo"],
    queryFn: () => authAxios.get(`api/v1/user/info/${retrievedCandidateId}`),
    refetchOnWindowFocus: false,
  });

  console.log("ci d2d",candidateInfo)

  useEffect(() => {
    (async () => {
      try {
        const token = await getIdTokenClaims();

        // Get voters based on daily goals
        const dailyGoalResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL
          }/api/v1/tasks/goals/daily?daysUntilElection=${daysUntilElection}&timestamp=${new Date().toLocaleString(
            "US"
          )}
          `,
          {
            headers: {
              Authorization: `Bearer ${token?.__raw}`,
            },
          }
        );
        setDailyGoalVotersCount(dailyGoalResponse.data.doorKnocks);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [userId, getIdTokenClaims, daysUntilElection]);

  useEffect(() => {
    (async () => {
      const tokenResponse = await getIdTokenClaims();
      setToken(tokenResponse);
    })();
  }, [getIdTokenClaims]);

  useEffect(() => {
    if (token) getVoterDetails();
  }, [token]);

  useEffect(() => {
    if (token && isVoterResponseSubmitted) getVoterDetails();
  }, [token, isVoterResponseSubmitted]);

  useEffect(() => {
    if (votersDetails.length) {
      const activeVoterDetails = votersDetails[activeAddressIndex];
      // set active voters details and coordinates
      if (activeAddressIndex !== -1) {
        setActiveVoters(activeVoterDetails);
        setActiveCoordinates(activeVoterDetails[0].geoCoordinates);
        setShowSidePanel(true);
      } else {
        setActiveVoters([]);
        setActiveCoordinates([]);
        setShowSidePanel(true);
      }
    }
  }, [activeAddressIndex]);

  useEffect(() => {
    const visitedVoters = votersDetails.map((voters) => {
      return voters
        .map((voter) => voter.voterContactHistories.length > 0)
        .every((value) => value === true);
    });

    setHasVisitedVoters(visitedVoters);
  }, [votersDetails]);

  const totalVoters = votersDetails.reduce(
    (result, voters) => result + voters.length,
    0
  );
  const totalDoors = votersDetails.length;

  const totalDoorsVisited = hasVisitedVoters.reduce((count, value) => {
    return count + (value ? 1 : 0);
  }, 0);

  // useEffect(() => {
  //   const successModalDiv = successModalRef.current;
  //   if (
  //     successModalDiv &&
  //     totalDoorsVisited &&
  //     totalDoors &&
  //     totalDoorsVisited === totalDoors
  //   ) {
  //     successModalDiv.click();
  //   }
  // }, [totalDoorsVisited, totalDoors]);

  useEffect(() => {
    const skipModalDiv = skipModalRef.current;

    if (showSkipModal && skipModalDiv) {
      skipModalDiv.click();
      setShowSkipModal(false);
    }
  }, [showSkipModal]);

  const totalActiveVoters = activeVoters?.length;
  const totalActiveVotersVisited = activeVoters?.reduce((count, value) => {
    return count + (value.voterContactHistories.length ? 1 : 0);
  }, 0);
  const totalVisitedVoters = votersDetails.reduce((count, voters) => {
    voters.forEach((voter) => {
      count = count + (voter.voterContactHistories.length ? 1 : 0);
    });
    return count;
  }, 0);

  // swipe
  const touchStart = useRef<number | null>(null);
  const touchEnd = useRef<number | null>(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 1;

  const onTouchStart = (e: React.TouchEvent) => {
    touchEnd.current = null;
    // touchStart.current = e.targetTouches[0].clientX;
    touchStart.current = e.targetTouches[0].clientY;
  };

  const onTouchMove = (e: React.TouchEvent) => {
    // touchEnd.current = e.targetTouches[0].clientX;
    touchEnd.current = e.targetTouches[0].clientY;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isTopSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    let addressSection = document.getElementById("address-js");
    if (isTopSwipe) {
      addressSection?.classList.add("scrollToTop");
    }

    if (isDownSwipe) {
      addressSection?.classList.remove("scrollToTop");
    }
  };

  return (
    <div className="d-flex align-items-stretch">
      {activeVoters?.length > 0 && (
        <SidePanel
          show={showSidePanel}
          setShow={setShowSidePanel}
          voters={activeVoters}
          activeAddressIndex={activeAddressIndex}
          totalAddress={votersDetails.length}
          setActiveAddressIndex={setActiveAddressIndex}
          setIsVoterResponseSubmitted={setIsVoterResponseSubmitted}
          setShowSkipModal={setShowSkipModal}
          canSubmitAllVotersDefaultResponse={canSubmitAllVotersDefaultResponse}
          setCanSubmitAllVotersDefaultResponse={
            setCanSubmitAllVotersDefaultResponse
          }
        />
      )}
      <div className="wrapper active-cont">
        <main className="p-0">
          <div className="container-fluid p-0">
            <div className="map-wrapper">
              <div
                id="address-js"
                className="daily-address scrollToTo"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                <div className="d-none d-lg-block">
                  <BreadCrumbs title="Contact Plan" subtitle={"Contact Voters Door To Door"} />
                </div>

                {/* <div className="voterGoal d-none d-lg-block">
                  <div className="voter-goal-item">
                    <span className="dot bg-success-s2"> </span>
                    <span className="fw-bold me-1">
                      {" "}
                      {contactedVotersCount} of {dailyGoalVotersCount}
                    </span>
                    Daily door goal
                  </div> */}
                  {/* <div className="voter-goal-item">
                    <span className="dot bg-extended-e12"> </span>
                    <span className="fw-bold me-1">1 of 186</span>Weekly door
                    goal
                  </div> */}
                {/* </div> */}

                {/* Address list */}
                <div className="voterAddress">
                  <div className="drag-control d-sm-block d-lg-none">
                    <div className="drag-control-item"></div>
                  </div>
                  <div className="voterAddress-header">
                    <span className="body-4 text-muted">
                      {totalDoors} Addresses{" "}
                    </span>
                    <span className="dot bg-neutral-n3 ms-2"> </span>
                    <span className="body-4 text-muted">
                      {totalVoters} voters
                    </span>
                  </div>
                  <div className="voterAddress-body">
                    {votersDetails.map(
                      (voters, index) =>
                        // index < VOTERS_LIMIT && (
                          <div
                            className={`voterAddress-body-item  ${hasVisitedVoters[index] ? "success" : ""
                              } ${activeAddressIndex === index ? "active" : ""}`}
                            key={index}
                            onClick={() => {
                              if (voters.length) {
                                activeAddressIndex === index
                                  ? setActiveAddressIndex(-1)
                                  : setActiveAddressIndex(index);
                              }
                            }}
                          >
                            <div className="address-index">{index + 1}</div>
                            <Link to="" className="address-content">
                              <div className="voter-address">
                                {voters.length && voters[0]?.address}
                              </div>
                              <div className="voter-count">
                                {" "}
                                {voters.length} voters
                              </div>
                            </Link>
                          </div>
                        // )
                    )}
                  </div>
                </div>
              </div>
              {!isLoaded ? (
                <div className="map-address">
                  <div className="position-relative  top-50 start-50 translate-middle">
                    <Loader />
                  </div>
                </div>
              ) : (
                <Map
                  votersCoordinates={votersCoordinates}
                  votersDetails={votersDetails}
                  activeCoordinates={activeCoordinates}
                  setActiveAddressIndex={setActiveAddressIndex}
                  hasVisitedVoters={hasVisitedVoters}
                />
              )}
            </div>
          </div>
        </main>
      </div>

      {/* <button
        className="btn btn-primary d-none"
        ref={successModalRef}
        data-bs-toggle="modal"
        data-bs-target="#successModal"
      >
        Show Success Modal
      </button> */}

      <button
        className="btn btn-primary d-none"
        ref={skipModalRef}
        data-bs-toggle="modal"
        data-bs-target="#skipModal"
      >
        Show Skip Modal
      </button>
      {/* if all door visited */}

      <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../img/success-modal.png")}
                width={56}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Great Work! </h2>
              <p>
                You have reached your daily door goal and knocked on{" "}
                <span className="fw-bold">
                  {" "}
                  {`${totalDoorsVisited}/${totalDoors}`}{" "}
                </span>{" "}
                doors. Continue to your next task for the day or add knocking
                more doors.
              </p>
            </div>

            <div
              className="modal-footer justify-content-end"
              data-bs-dismiss="modal"
            >
              <Link to="/voters/list" className="btn btn-link rounded-pill">
                Add more doors
              </Link>
              <Link to="/" className="btn btn-primary rounded-pill">
                Continue to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* If all doors visited but not talked to some voters */}
      <div
        className="modal fade"
        id="skipModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <WarningIcon />
              <h2 className="mt-3">
                {" "}
                Would you like to mark{" "}
                {totalActiveVoters - totalActiveVotersVisited} voters as ‘Not
                Home’?{" "}
              </h2>
              <p>
                <span className="fw-bold">
                  {" "}
                  {`${totalVisitedVoters}/${totalVoters}`}{" "}
                </span>{" "}
                voters talked today.
              </p>
            </div>

            <div
              className="modal-footer justify-content-end"
              data-bs-dismiss="modal"
            >
              <button className="btn btn-link rounded-pill">
                No, do nothing
              </button>
              <button
                className="btn btn-primary rounded-pill"
                onClick={() => setCanSubmitAllVotersDefaultResponse(true)}
              >
                Yes, continue
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when user leave page  */}
      <div
        className="modal fade"
        id="leaveModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Do you want to leave this page? </h2>
              <p>
                By leaving the page, details filled in this page will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, Leave
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoortoDoor;

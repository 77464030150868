import React from 'react'

const DocumentIconSmall = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M7.50033 4.16667H5.83366C4.91318 4.16667 4.16699 4.91286 4.16699 5.83333V15.8333C4.16699 16.7538 4.91318 17.5 5.83366 17.5H14.167C15.0875 17.5 15.8337 16.7538 15.8337 15.8333V5.83333C15.8337 4.91286 15.0875 4.16667 14.167 4.16667H12.5003M7.50033 4.16667C7.50033 5.08714 8.24652 5.83333 9.16699 5.83333H10.8337C11.7541 5.83333 12.5003 5.08714 12.5003 4.16667M7.50033 4.16667C7.50033 3.24619 8.24652 2.5 9.16699 2.5H10.8337C11.7541 2.5 12.5003 3.24619 12.5003 4.16667" stroke="#3B3B4F" strokeWidth="1.66667" strokeLinecap="round"/>
</svg>
  )
}

export default DocumentIconSmall

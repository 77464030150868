import { useQuery } from '@tanstack/react-query';
import { CANDIDATE_ID } from 'components/pages/Homepage';
import CreateAuthAxiosInstance from 'utils/authAxios';

export interface ICandidateFile {
  id: number
  isActive: boolean
  isArchived: boolean
  deletedAt: string | null
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string | null
  internalComment: string | null
  url: string
  key: string | null
  mimeType: string | null
  fileTypeId: number
  fileLinkId: number
  size: number
  fileName: string | null
}

const useGetCandidateFileList = () => {
  const authAxios = CreateAuthAxiosInstance();
  const candidateId = localStorage.getItem(CANDIDATE_ID);

  const {
    data: candidateFileList,
    refetch: refetchCandidateFileList,
    isFetching: isCandidateFileListFetching
  } = useQuery({
    queryKey: ["candidateFileList"],
    queryFn: () => authAxios(`api/v1/file/${candidateId}`).then((response) => response.data),
    refetchOnWindowFocus: false,
    enabled: Boolean(candidateId)
  })

  return {
    candidateFileList,
    refetchCandidateFileList,
    isCandidateFileListFetching
  }
}

export default useGetCandidateFileList
import React from 'react'

const TrashIconDanger = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M7.1999 1.6001C6.89688 1.6001 6.61987 1.7713 6.48436 2.04233L5.90548 3.2001H3.1999C2.75807 3.2001 2.3999 3.55827 2.3999 4.0001C2.3999 4.44193 2.75807 4.8001 3.1999 4.8001L3.1999 12.8001C3.1999 13.6838 3.91625 14.4001 4.7999 14.4001H11.1999C12.0836 14.4001 12.7999 13.6838 12.7999 12.8001V4.8001C13.2417 4.8001 13.5999 4.44193 13.5999 4.0001C13.5999 3.55827 13.2417 3.2001 12.7999 3.2001H10.0943L9.51544 2.04233C9.37993 1.7713 9.10292 1.6001 8.7999 1.6001H7.1999ZM5.5999 6.4001C5.5999 5.95827 5.95807 5.6001 6.3999 5.6001C6.84173 5.6001 7.1999 5.95827 7.1999 6.4001V11.2001C7.1999 11.6419 6.84173 12.0001 6.3999 12.0001C5.95807 12.0001 5.5999 11.6419 5.5999 11.2001V6.4001ZM9.5999 5.6001C9.15807 5.6001 8.7999 5.95827 8.7999 6.4001V11.2001C8.7999 11.6419 9.15807 12.0001 9.5999 12.0001C10.0417 12.0001 10.3999 11.6419 10.3999 11.2001V6.4001C10.3999 5.95827 10.0417 5.6001 9.5999 5.6001Z" fill="#DE3715"/>
</svg>
  )
}

export default TrashIconDanger

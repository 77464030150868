import { useMutation } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import Spinner from 'components/common/Spinner'
import React, { useEffect, useRef } from 'react'
import CreateAuthAxiosInstance from 'utils/authAxios'
import toastNotify from 'utils/toastNotify'

interface IFundRaisingGoalConfirmationModal {
  fundRaisingGoalConfirmationModalButtonRef: React.RefObject<HTMLButtonElement>
  budgetConfirmationModalRef: React.RefObject<HTMLDivElement>
  handleFinalSave: () => void
  handleAsyncFinalSave: () => Promise<AxiosResponse<any, any>>
  postDonorSubmitStatus: "error" | "idle" | "pending" | "success"
  updateDonorsSubmitStatus: "error" | "idle" | "pending" | "success"
}

const FundRaisingGoalConfirmationModal = ({
  fundRaisingGoalConfirmationModalButtonRef,
  budgetConfirmationModalRef,
  handleFinalSave,
  handleAsyncFinalSave,
  postDonorSubmitStatus,
  updateDonorsSubmitStatus
}: IFundRaisingGoalConfirmationModal) => {
  const authAxios = CreateAuthAxiosInstance()

  const closeButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (postDonorSubmitStatus === 'success' || updateDonorsSubmitStatus === 'success') {
      closeButtonRef.current?.click()
    }
  }, [postDonorSubmitStatus, updateDonorsSubmitStatus])

  const {
    mutate: activateFundRaisingGoalMode,
    isPending
  } = useMutation({
    mutationFn: () => authAxios.post(`api/v1/budgets/build-budgets?budgetMode=fundraising_goal_based`).then((response) => response?.data),
    // onSuccess: handleBudgetActivationSuccessEvent,
    onError: handleBudgetActivationErrorEvent
  })

  function handleBudgetActivationSuccessEvent() {
    // toastNotify('success', budgetMode === BUDGET_MODE_QUERY_KEY.RESET ? 'Budget reset successfully.' : 'Budget mode changed successfully.')
    // queryClient.invalidateQueries({ queryKey: ["candidateDetail"] })
  }

  function handleBudgetActivationErrorEvent(error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error("on error", error?.response?.data?.message)
      toastNotify("error", "Could activate fund raising mode in budget!")
    }
  }

  const handleCancelEvent = () => {
    handleFinalSave()
  }

  const handleConfirmEvent = async () => {
    const apiResponse = await handleAsyncFinalSave()

    if (apiResponse) activateFundRaisingGoalMode()
  }

  return (
    <>
      <button
        ref={fundRaisingGoalConfirmationModalButtonRef}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#fundraisingGoalConfirmationModal"
      >
      </button>
      <button
        ref={closeButtonRef}
        className="btn btn-primary d-none"
        data-bs-dismiss="modal"
        data-bs-target="#fundraisingGoalConfirmationModal"
      >
      </button>
      <div
        ref={budgetConfirmationModalRef}
        className="modal fade"
        id="fundraisingGoalConfirmationModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>

            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3">Do you want Campaign Brain to update your budget to reflect the new fundraising goal?</h2>
              <p>
                Please note, by clicking on ‘Yes, Confirm’, this will override the current budget, which cannot be recovered.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link rounded-pill btn-xs"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCancelEvent}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary rounded-pill btn-xs d-flex align-items-center"
                onClick={handleConfirmEvent}
              >
                {(postDonorSubmitStatus === 'pending' || updateDonorsSubmitStatus === 'pending')
                  && <Spinner />
                }
                Yes, confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FundRaisingGoalConfirmationModal
import UploadFile from "components/common/UploadFile";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DashboardChildProp, IResponseFileInfo } from "type/dashboard";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useFormPersist from "react-hook-form-persist";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { handleFileDelete } from "utils/uploadFile";
import { ACTIVE_STEP } from "../Dashboard";
import { getLocalStorageItem } from "utils/localStorageHelper";
import toastNotify from "utils/toastNotify";
import CreateAuthAxiosInstance from "utils/authAxios";
import { CANDIDATE_ID } from "../../Homepage";

const YourStory = ({
  handleStepCompletion,
  fileInfo,
  setFileInfo,
}: DashboardChildProp) => {
  const authAxtios = CreateAuthAxiosInstance();

  const activeStep: number = getLocalStorageItem(ACTIVE_STEP);

  const [isFileRead, setIsFileRead] = useState<boolean>(false);
  const [strengths, setStrengths] = useState<string[]>([""]);
  const [inputCharCounts, setInputCharCounts] = useState<number[]>([0]);
  // const [letterCounts, setLetterCounts] = useState<number[]>([0]);
  const userId = localStorage.getItem(CANDIDATE_ID);
  const [campaignStoryId, setCampaignStoryId] = useState<number>(0);
  const [isCampaignInfoLoaded, setIsCampaignInfoLoaded] = useState(false);

  const [formValues, setFormValues] = useState<{
    [key: string]: string;
  }>({
    reason: "",
    policyProposalsPlatforms: "",
  });
  const YourStorySchema = Yup.object().shape({
    linkedin: Yup.string(),
    reason: Yup.string().required("Reason for running is required"),
    policyProposalsPlatforms: Yup.string().required("This field is required"),

    strength: Yup.array().of(
      Yup.string().required("Your biggest strength is required")
    ),
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(YourStorySchema),
  });
  useFormPersist("addCandidateStory", {
    watch,
    setValue,
    storage: localStorage,
  });

  const handleFormSubmit = async (data: any) => {
    let resume = "";
    const resumeResponse = await authAxtios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/file/${userId}`
    );
    if (resumeResponse?.data) {
      const foundFile = resumeResponse?.data?.find(
        (item: any) => item.fileTypeId === 3
      );
      resume = foundFile?.url;
    }
    if (campaignStoryId) {
      await authAxtios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/candidate-story/${campaignStoryId}`,
        {
          strengths: data.strength,
          motivation: data.reason,
          proposal: data.policyProposalsPlatforms,
          displayProfileUrl: data.linkedin,
          resume: resume,
        }
      );
    } else {
      await authAxtios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/candidate-story`,
        {
          strengths: data.strength,
          motivation: data.reason,
          proposal: data.policyProposalsPlatforms,
          displayProfileUrl: data.linkedin,
          resume: resume,
        }
      );
    }

    handleStepCompletion(activeStep, "completed");
    localStorage.removeItem("addCandidateStory");
  };

  // const handleTextareaChange = (name: string, value: string) => {
  //   const newLetterCounts = [...letterCounts];
  //   setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  // };

  const getCandidateStory = async () => {
    try {
      const res = await authAxtios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/candidate-story/candidate/${userId}`
      );
      if (res.data) {
        console.log(res.data);
        setStrengths(res?.data?.strengths ?? [""]);
        setFormValues({
          reason: res?.data?.motivation ?? "",
          policyProposalsPlatforms: res?.data?.proposal ?? "",
        });
        setCampaignStoryId(res?.data?.id);
      }
      setIsCampaignInfoLoaded(true);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage) {
        toastNotify("error", errorMessage);
      }
    }
  };

  useEffect(() => {
    getCandidateStory();
  }, []);

  useEffect(() => {
    const storedStrengths = getValues("strength");
    const storedReason = getValues("reason");
    const storedPolicy = getValues("policyProposalsPlatforms");

    if (storedStrengths && storedStrengths?.[0]?.length) {
      setValue("strength", storedStrengths);
    } else {
      setValue("strength", strengths);
    }
    if (storedReason) {
      setValue("reason", storedReason);
    } else {
      setValue("reason", formValues?.reason);
    }

    if (storedPolicy) {
      setValue("policyProposalsPlatforms", storedPolicy);
    } else {
      setValue(
        "policyProposalsPlatforms",
        formValues?.policyProposalsPlatforms
      );
    }
  }, [isCampaignInfoLoaded]);

  return (
    <div className="col-md-8">
      <div className="card card-accountSetup shadow-2">
        <div className="card-title">
          <h4>Your story</h4>
          <p>
            This information is used to train the AI Copilot to assist your
            campaign.
          </p>
        </div>

        <div className="card-body">
          <div className="your-story">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="mb-3">
                <label className="custom-label">Resume</label>

                <UploadFile
                  setIsFileRead={setIsFileRead}
                  activeStep={activeStep}
                  fileInfo={fileInfo}
                  setFileInfo={
                    setFileInfo as React.Dispatch<
                      React.SetStateAction<IResponseFileInfo | null>
                    >
                  }
                />
              </div>

              <div className="mb-3">
                <label htmlFor="InputLinked" className="form-label">
                  Add your Linkedin account
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="InputLinked"
                  aria-describedby="emailHelp"
                  placeholder="Share your LinkedIn profile URL for a glimpse of your professional background."
                  {...register("linkedin")}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="Textarea1" className="form-label">
                  Why are you running for office?
                </label>
                <div className="position-relative">
                  <textarea
                    className="form-control"
                    id="Textarea1"
                    rows={3}
                    // maxLength={200}
                    placeholder="We're interested in knowing what motivates your candidacy. Please share your reasons for seeking public office."
                    {...register("reason")}
                    // onChange={(e) =>
                    //   handleTextareaChange(
                    //     "reason",
                    //     String(e.target.value.length)
                    //   )
                    // }
                  ></textarea>
                  {/* <span className="word-count">
                    {formValues["reason"]}/ 200
                  </span> */}
                </div>
                {errors.reason && (
                  <span className="invalidInput form-text text-danger">
                    {errors.reason.message}
                  </span>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="Textarea2" className="form-label">
                  What are your main policy proposals, ideas, and platforms?
                </label>
                <div className="position-relative">
                  <textarea
                    className="form-control"
                    id="Textarea2"
                    rows={3}
                    // maxLength={200}
                    placeholder="Outline your key policy proposals, ideas, and platforms that define your campaign's vision and direction."
                    {...register("policyProposalsPlatforms")}
                    // onChange={(e) =>
                    //   handleTextareaChange(
                    //     "policyProposalsPlatforms",
                    //     String(e.target.value.length)
                    //   )
                    // }
                  ></textarea>
                  {/* <span className="word-count">
                    {formValues["policyProposalsPlatforms"]}/ 200
                  </span> */}
                </div>
                {errors.policyProposalsPlatforms && (
                  <span className="invalidInput form-text text-danger">
                    {errors.policyProposalsPlatforms.message}
                  </span>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="TextareaStrength" className="form-label">
                  Your biggest strength
                </label>
                <span className="text-muted d-block mb-1 fs-7">
                  Tell us your biggest strength compared to the competitors
                </span>
                {strengths.map((strength, index) => (
                  <div key={index} className="position-relative mb-3">
                    <textarea
                      className="form-control"
                      id="TextareaStrength"
                      rows={2}
                      // maxLength={100}
                      placeholder="Highlight a distinctive strength that sets you apart and contributes to your potential as a candidate."
                      onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const value = e?.target?.value;
                        const newInputCharCounts = [...inputCharCounts];
                        newInputCharCounts[index] = value.length;
                        setInputCharCounts(newInputCharCounts);
                      }}
                      {...register(`strength.${index}`)}
                    ></textarea>
                    {/* <span className="word-count">
                      {inputCharCounts[index]}/100
                    </span> */}

                    {errors.strength?.[index]?.message && (
                      <span className="invalidInput form-text text-danger mt-1">
                        {errors.strength?.[index]?.message}
                      </span>
                    )}
                  </div>
                ))}

                <button
                  type="button"
                  className="btn btn-link text-primary mt-3"
                  onClick={() => {
                    setStrengths([...strengths, ""]);
                  }}
                >
                  <span className="icon-plus me-2"></span> Add another strength
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="card-footer shadow-6 gap-4 d-flex justify-content-end">
          <Link
            to=""
            className="btn btn-link text-primary btn-xs rounded-pill"
            onClick={() => {
              handleStepCompletion(activeStep, "skipped");
              localStorage.removeItem("addCandidateStory");

              handleFileDelete(activeStep);
            }}
          >
            Not Now
          </Link>
          <Link
            to=""
            className={`btn btn-primary rounded-pill btn-xs px-6 ${
              isDirty && isValid ? "" : "btn-disable"
            }`}
            onClick={handleSubmit(handleFormSubmit)}
          >
            Next
          </Link>
        </div>
      </div>
    </div>
  );
};

export default YourStory;

import CallDrop from "img/svg/CallDrop";
import Calling from "img/svg/Calling";
import React from "react";

const OnCall = () => {
  return (
    <>
      <div className="callBox-status">
        <Calling />

        <div className="callBox-status-body">
          <div className="callBox-status-text"> Calling...</div>
          <div className="callBox-status-duration">
            0:14
            {/* if not connected to call */}
            {/* <span className="text-danger"> Couldn’t connect </span> */}

          </div>
        </div>
      </div>
      <div className="callBox-btn">
        <button className="btn btn-danger btn-cancel rounded-circle">
          <CallDrop />
        </button>
      </div>
    </>
  );
};

export default OnCall;

import NoData from "img/svg/noData";

import { useState } from "react";
import UploadFile from "components/common/UploadFile";

const UploadVotersList = ({
  setIsFileUploadCompleted,
}: {
  setIsFileUploadCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isFileRead, setIsFileRead] = useState<boolean>(false);

  return (
    <div className="d-flex align-items-stretch">
      <div className="wrapper active-cont">
        <main>
          <div className="row h-100 justify-content-center">
            <div className="col-md-6">
              <div className="centeredXY noData text-center justify-content-around">
                <div className="noData-header">
                  <NoData />
                  <h1 className="mt-6">No voter data available</h1>
                  <p className="text-muted">
                    Upload a file of any format containing information about
                    voters in your district
                  </p>

                  <div className="mt-4">
                    <button
                      className="btn btn-primary rounded-pill px-5"
                      data-bs-toggle="modal"
                      data-bs-target="#uploadModal"
                    >
                      Upload file
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* Modal */}
        <div
          className="modal fade"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title">Add voter information</h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                <form action="">
                  <div className="mb-3">
                    <label className="custom-label">File upload</label>
                    <p>
                      Upload a file of any format containing information about
                      voters in your district
                    </p>
                    <div className="row">
                      <div className="col-md-12 ">
                        <UploadFile setIsFileRead={setIsFileRead} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer border-1">
                <button
                  className="btn btn-link text-primary btn-xs"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadModal"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary rounded-pill btn-xs px-6"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadModal"
                  disabled={!isFileRead}
                  onClick={() => setIsFileUploadCompleted(true)}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadVotersList;

import TrashIcon from "img/svg/TrashIcon";
import FileIcon from "img/svg/fileIcon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomScript from "templates/components/scriptEditor/CustomScript";

import DefaultScript from "templates/components/scriptEditor/DefaultScript";

const CandidateScript = () => {
    const [editable, setEditable] = useState<null | string>(null);

  return (
    <div className="settings">
        <div className="settings-header d-flex justify-content-between">
          <div className="">
            <h4> Candidate </h4>
            <p className="body-4 text-muted">
              Edit or add a script for Voter Contact calls done by the
              Candidate.
            </p>
          </div>

          <div>
            {editable === "candidateScript"  ? (
              <>
                {" "}
                <button
                  className="btn btn-link btn-xs rounded-pill"
                  onClick={() => setEditable(null)}
                >
                  Cancel
                </button>
                <button className="btn btn-link btn-xs rounded-pill disabled">
                  Save
                </button>
              </>
            ) : (
              <button
                className="btn btn-link btn-xs px-1 py-0"
                onClick={()=>setEditable("candidateScript")}
              >
                {" "}
                <i className="icon-edit"> </i> Edit details
              </button>
            )}
          </div>
        </div>

        <div className="settings-body">
          <div className="professional-info">
            <hr className="mt-3 mb-3" />
            <div className="row">
              <div className="col-md-4">
                <span className="body-2 fs-7"> Default Script </span>
              </div>
              <div className="col-md-6">
                {editable !== "candidateScript" ? (
                  <div className="d-flex flex-column">
                    <div>
                      <span className="body-4"> Voter Contact Script </span>
                      <span className="badge bg-success rounded-pill px-2 font-regular">
                        {" "}
                        Active{" "}
                      </span>
                    </div>
                    <span className="body-4 text-muted">
                      {" "}
                      Updated on 29 Jan 2024{" "}
                    </span>
                  </div>
                ) : (
                  <>
                    <form>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputTitle"
                          className="form-label"
                        >
                          Title
                        </label>
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          value="Voter Contact Script"
                          id="exampleInputTitle"
                          aria-describedby="titleHelp"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputScript"
                          className="form-label"
                        >
                          Script
                        </label>
                        <DefaultScript />
                      </div>
                    </form>
                  </>
                )}
              </div>
              {/* <div className="col-md-2">
              {!editable && <button className="btn btn-link btn-xs no-hover"> Activate </button> }
                
              </div> */}
            </div>
            <hr className="mt-3 mb-3" />
            <div className="row">
              <div className="col-md-4">
                <span className="body-2 fs-7"> Custom Script </span>
              </div>
              <div className="col-md-6">
                { editable !== "candidateScript" ? (
                  <span className="body-4 text-muted"> Not Provided </span>
                ) : (
                  <form>
                    {" "}
                    <div className="mb-3">
                      <label htmlFor="exampleInputTitle" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value=""
                        id="exampleInputTitle"
                        aria-describedby="emailHelp"
                        placeholder="Enter a Title for Your Script"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputScript"
                        className="form-label"
                      >
                        Script
                      </label>
                      <div className="position-relative">
                       
                        <CustomScript />
                        <span className="word-count"> 0 / 500 </span>
                      </div>
                    </div>
                  </form>
                )}
              </div>
              <div className="col-md-2 text-end">
              {!editable && <button className="btn btn-link btn-xs no-hover" disabled> Activate </button> }
              </div>
            </div>
            <hr className="mt-3 mb-3" />
          </div>
        </div>
      </div>
  )
}

export default CandidateScript

import { QueryObserverResult, RefetchOptions, useMutation } from "@tanstack/react-query"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import CreateAuthAxiosInstance from "utils/authAxios"
import toastNotify from "utils/toastNotify"
import { IDeleteTarget } from "./BudgetTable"
import axios from "axios"
import CustomTooltip from "components/common/CustomTooltip"
import HelpIcon from "img/svg/helpIcon"
import HelpIconSm from "img/svg/HelpIconSm"

interface ISubCategoryTitle {
  title: string
  index: number
  zIndexHandler: (index: number) => void
  categoryId: number
  subcategoryId: number
  handleDeleteTargetUpdation: (targetData: IDeleteTarget) => void
  isLastItem: boolean
  refetchCategoryData: (options?: RefetchOptions) => Promise<QueryObserverResult<any, Error>>
  isActBlueSubCategory: boolean
  isActBlueIntegrated: boolean
}

const SubCategoryTitle = ({
  title,
  index,
  zIndexHandler,
  categoryId,
  subcategoryId,
  handleDeleteTargetUpdation,
  isLastItem,
  refetchCategoryData,
  isActBlueSubCategory,
  isActBlueIntegrated
}: ISubCategoryTitle) => {
  const authAxios = CreateAuthAxiosInstance()

  const [isTitleEditable, setIsTitleEditable] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(title)

  useEffect(() => {
    if (isTitleEditable) setIsTitleEditable(false)
  }, [title])

  const { mutate: patchTitleValue, isPending: isPendingTitleValueEdit } =
    useMutation({
      mutationFn: (data: { name: string }) =>
        authAxios.patch(
          `api/v1/budgets/categories/${categoryId}/subcategories/${subcategoryId}`,
          data
        ),
      onSuccess: handleOnSuccessEvent,
      onError: handleOnErrorEvent
    })

  function handleOnSuccessEvent() {
    toastNotify("success", "Title edited successfully!")
    refetchCategoryData()
  }

  function handleOnErrorEvent(error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error("on error", error?.response?.data?.message)

      toastNotify("error", "Could not edit title!")
      setIsTitleEditable(false)
      if (title !== inputValue) setInputValue(title)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(e.target.value || "")

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleFinalPatch()
    }
    if (e.key === "Escape") {
      setIsTitleEditable(false)
      if (title !== inputValue) setInputValue(title)
    }
  }

  function handleFinalPatch() {
    const finalInputValue = inputValue?.trim() || ''

    if (categoryId && subcategoryId && title !== finalInputValue) {
      patchTitleValue({ name: finalInputValue })
      return
    }
    //if value is same, no patch
    setIsTitleEditable(false)
    if (inputValue !== finalInputValue) setInputValue(finalInputValue)
  }

  function handleDeleteEvent() {
    if (categoryId && subcategoryId) {
      const targetSubCategory = {
        title: title,
        categoryId: categoryId,
        subcategoryId: subcategoryId,
      }
      handleDeleteTargetUpdation(targetSubCategory)
    }
  }

  const ACTBLUE_TOOLTIP: string =
    isActBlueIntegrated ?
      'Your Actblue history is now being reflected directly in the budget, broken down by month based on when you received donations from your supporters.'
      :
      'To integrate your Actblue account and enhance your fundraising efforts, request the Actblue integration in Settings'

  return (
    <>
      <div className={`budgetSubCat dropup ${isTitleEditable ? "budgetSubCat-editable" : ""}`}>
        {!isTitleEditable ? (
          <>
            {title.length <= 20 ? (
              <span className="text-muted">{title || "N/A"}</span>
            ) : (
              <CustomTooltip content={title || "N/A"}>
                <span className="text-muted">{title || "N/A"}</span>
              </CustomTooltip>
            )}

            {isActBlueSubCategory ? (
              <span className="budgetSubCat-tools opacity-100">
                <CustomTooltip content={ACTBLUE_TOOLTIP}>
                  <HelpIconSm />
                </CustomTooltip>
              </span>
            ) : (
              <>
                {/* Subcategory dropdown  */}
                <div className={`budgetSubCat-tools ${isLastItem ? 'dropup' : ''}`}>
                  <Link
                    className="btn btn-link btn-circle rounded-circle p-0 text-n1"
                    to=""
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() => zIndexHandler(index)}
                  >
                    <span className="icon-pencil"></span>
                  </Link>
                  <div className="dropdown-menu">
                    <div className="dropdown-group">
                      <ul className="dropdown-body list-unstyled mb-0 ">
                        <li>
                          <Link to="" onClick={() => setIsTitleEditable(true)}>
                            Edit Category name
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="text-danger"
                            to=""
                            data-bs-toggle="modal"
                            data-bs-target={`#budgetDeleteModal${categoryId}`}
                            onClick={handleDeleteEvent}
                          >
                            Delete Category
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <input
            type="text"
            className="form-control rounded-0"
            autoFocus
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleFinalPatch}
          />
        )}
      </div >
    </>
  )
}

export default React.memo(SubCategoryTitle)

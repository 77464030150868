import { useEffect } from "react";

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    // if (textAreaRef?.innerText === '') {
    //     textAreaRef.style.height = "24px";
    // }
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      // textAreaRef.style.height = "0px";
      // const scrollHeight = textAreaRef.scrollHeight === 0 ? 48 : textAreaRef.scrollHeight;
      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      // textAreaRef.style.height = scrollHeight + "px";

      // Set the textarea's value
      textAreaRef.value = value;

      // Set the height based on the scroll height of the content
      textAreaRef.style.height = "auto";
      const newHeight = Math.min(textAreaRef.scrollHeight, 300);
      textAreaRef.style.height = newHeight + "px";
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;

import React from "react";
import SideNavbar from "../../components/common/navbar/SideNavbar";
import { Link } from "react-router-dom";
import CheckSuccess from "../../img/svg/checkSuccess";
import Stepper from "../components/Stepper";
import FileUpload from "../components/FileUpload";
import FileIcon from "img/svg/fileIcon";

const AddVoter = () => {
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="row">
            <div className="col-md-4">
              <div className="accountSetup accountSetup-fixed">
                <div className="accountSetup-header">
                  <h5>Account setup list</h5>
                  <p>Setup your account to use Campaign Brain</p>
                </div>
                <div className="accountSetup-body">
                  <Stepper />
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="card card-accountSetup shadow-2">
                <div className="card-title">
                  <h4>Add Voter</h4>
                  <p> Add a list of voters in your district</p>
                </div>

                <div className="card-body">
                  <form action="">
                    <div className="mb-3">
                      <label className="custom-label">File upload</label>
                      <p>
                        Upload a file of any format containing information about
                        voters in your district
                      </p>

                      {/* before upload */}

                      <FileUpload />

                      {/* after upload */}

                      <div className="row">
                        <div className="col-md-9">
                          <div className="uploadedCard mb-0">
                            <div className="uploadedCard-icon">
                              <div className="iconBox">
                                <FileIcon />
                              </div>

                              <div className="iconInfo">
                                <span className="iconInfo-title">
                                  {" "}
                                  Voter List.pdf{" "}
                                </span>
                                <p className="iconInfo-desc">
                                  {" "}
                                  1.5 MB, 6/23/23 at 11:45 am{" "}
                                </p>
                                <span className="iconInfo-status">
                                  {" "}
                                  <CheckSuccess /> File uploaded{" "}
                                </span>
                              </div>
                            </div>
                            <div className="uploadedCard-info">
                              <div className="file-btn">
                                <button className="btn btn-link text-primary">
                                  {" "}
                                  Change File
                                  <input type="file" name="file" />
                                </button>
                              </div>

                              <button className="btn btn-xs">
                                <span className="icon-trash"></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="card-footer shadow-6 d-flex gap-4 justify-content-end">
                  <Link
                    to="/candidate/add-donor"
                    className="btn btn-link text-primary btn-xs"
                  >
                    Not Now{" "}
                  </Link>
                  <Link
                    to="/candidate/add-donor"
                    className="btn btn-primary rounded-pill btn-xs px-6"
                  >
                    Next{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AddVoter;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  contactTooltip,
  fundraiseTooltip,
  progressGoalTooltip,
} from "./Tooltip";
import HelpIcon from "img/svg/helpIcon";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import AnalyticsAlert from "./Alert";
import { IProgressReport } from "./interface";
import { getReportValue } from "utils/analyticsReport";
const INITIAL_PROGRESS_REPORT = {
  totalDonationAmount: 0,
  fundraisingGoal: 0,
  fundraisingProgressRate: 0,
  totalContactedVoters: 0,
  totalVoters: 0,
  voterContactRate: 0,
  averageProgressRate: 0,
  daysUntilElection: 0,
};
const ProgressAnalytics: React.FC = () => {
  const { getIdTokenClaims } = useAuth0();
  const [progressReportData, setProgressReportData] = useState<IProgressReport>(
    INITIAL_PROGRESS_REPORT
  );
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchProgressReport() {
      const token = await getIdTokenClaims();
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/analytics/progress-report`,
          {
            headers: {
              Authorization: `Bearer ${token?.__raw}`,
            },
          }
        );
        setProgressReportData(data);
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    }
    fetchProgressReport();
  }, []);
  return (
    <>
      <AnalyticsAlert
        daysUntilElection={progressReportData?.daysUntilElection}
      />
      <div className="row mt-3">
        <div className="col-md-4 col-12 mb-3">
          <div className="card card-flush h-100 shadow-2">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title"> Fundraising Progress</h4>
              <div className="card-info">
                <Link
                  to=""
                  data-bs-html="true"
                  data-bs-custom-class="custom-tooltip"
                  className="text-left"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title={fundraiseTooltip}
                >
                  {" "}
                  <HelpIcon />{" "}
                </Link>
              </div>
            </div>
            <div className="card-body pt-1 d-flex flex-column justify-content-end">
              {isLoading ? (
                <div className="placeholder-glow w-100">
                  <div className="placeholder col-4 py-3"></div>
                  <div className="placeholder col-12 mt-3"></div>
                </div>
              ) : (
                <>
                  <div className="d-flex align-items-end">
                    <h1 className="mb-0 me-2">
                      {getReportValue(
                        true,
                        progressReportData.totalDonationAmount
                      )}{" "}
                      /
                    </h1>
                    <h1 className="mb-0 me-2 text-muted">
                      {getReportValue(true, progressReportData.fundraisingGoal)}
                    </h1>
                  </div>
                  <div
                    className="progress mt-3"
                    role="progressbar"
                    aria-label="task progress"
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ height: 4 + "px" }}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${progressReportData.fundraisingProgressRate}%`,
                      }}
                    ></div>
                  </div>
                  <p className="m-0 text-muted fs-8 mt-1 fw-bold">
                    <span>
                      {" "}
                      {getReportValue(
                        false,
                        progressReportData.fundraisingProgressRate
                      )}
                      %
                    </span>{" "}
                    to your fundraising goal{" "}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-4 col-12 mb-3">
          <div className="card card-flush h-100 shadow-2">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title"> Total Voter Contacts </h4>
              <div className="card-info">
                <Link
                  to=""
                  data-bs-html="true"
                  data-bs-custom-class="custom-tooltip"
                  className="text-left"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title={contactTooltip}
                >
                  {" "}
                  <HelpIcon />{" "}
                </Link>
              </div>
            </div>
            <div className="card-body pt-1 d-flex flex-column justify-content-end">
              {isLoading ? (
                <div className="placeholder-glow w-100">
                  <div className="placeholder col-4 py-3"></div>
                  <div className="placeholder col-12 mt-3"></div>
                </div>
              ) : (
                <>
                  <div className="d-flex align-items-end">
                    <h1 className="mb-0 me-2">
                      {getReportValue(
                        false,
                        progressReportData.totalContactedVoters
                      )}{" "}
                      /
                    </h1>
                    <h1 className="mb-0 me-2 text-muted">
                      {getReportValue(false, progressReportData.totalVoters)}
                    </h1>{" "}
                    <span className="text-muted">voters</span>
                  </div>
                  <div
                    className="progress mt-3"
                    role="progressbar"
                    aria-label="task progress"
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ height: 4 + "px" }}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${progressReportData.voterContactRate}%`,
                      }}
                    ></div>
                  </div>
                  <p className="m-0 text-muted fs-8 mt-1 fw-bold">
                    <span>
                      {getReportValue(
                        false,
                        progressReportData.voterContactRate
                      )}
                      %
                    </span>{" "}
                    to your voter contact goal{" "}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-4 col-12 mb-3">
          <div className="card card-flush h-100 shadow-2">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title"> Progress to Goal</h4>
              <div className="card-info">
                <Link
                  to=""
                  data-bs-html="true"
                  data-bs-custom-class="custom-tooltip"
                  className="text-left"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title={progressGoalTooltip}
                >
                  {" "}
                  <HelpIcon />{" "}
                </Link>
              </div>
            </div>

            <div className="card-body pt-1 d-flex flex-column justify-content-end">
              <div className="d-flex align-items-end">
                {isLoading ? (
                  <div className="placeholder-glow w-100">
                    <div className="placeholder col-4"></div>
                  </div>
                ) : (
                  <h1 className="mb-0 me-2">
                    {getReportValue(
                      false,
                      progressReportData.averageProgressRate
                    )}
                    %
                  </h1>
                )}
              </div>

              <div className="progressGoal">
                <div className="progressGoal-item">
                  <div className="progressGoal-item-title">
                    {" "}
                    Voters contacted{" "}
                  </div>
                  <div className="progressGoal-item-progress">
                    {isLoading ? (
                      <div className="placeholder-glow w-100">
                        <div className="placeholder col-12"></div>
                      </div>
                    ) : (
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="task progress"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ height: 4 + "px" }}
                      >
                        <div
                          className="progress-bar"
                          style={{
                            width: `${progressReportData.voterContactRate}%`,
                          }}
                        ></div>
                      </div>
                    )}

                    <div className="progressGoal-item-percent">
                      {isLoading ? (
                        <div className="placeholder-glow w-100">
                          <div className="placeholder col-12"></div>
                        </div>
                      ) : (
                        <>
                          {" "}
                          {getReportValue(
                            false,
                            progressReportData.voterContactRate
                          )}
                          %
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="progressGoal-item">
                  <div className="progressGoal-item-title"> Funds raised </div>
                  <div className="progressGoal-item-progress">
                    {isLoading ? (
                      <div className="placeholder-glow w-100">
                        <div className="placeholder col-12"></div>
                      </div>
                    ) : (
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="task progress"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ height: 4 + "px" }}
                      >
                        <div
                          className="progress-bar"
                          style={{
                            width: `${progressReportData.fundraisingProgressRate}%`,
                          }}
                        ></div>
                      </div>
                    )}

                    <div className="progressGoal-item-percent">
                      {isLoading ? (
                        <div className="placeholder-glow w-100">
                          <div className="placeholder col-12"></div>
                        </div>
                      ) : (
                        <>
                          {getReportValue(
                            false,
                            progressReportData.fundraisingProgressRate
                          )}
                          %
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProgressAnalytics;

import { useQuery } from '@tanstack/react-query'
import { CSVLink } from 'react-csv'
import CreateAuthAxiosInstance from 'utils/authAxios';
import { globalQueryConfig } from 'utils/reactQuery';

export const ExportCsv = () => {
  const authAxios = CreateAuthAxiosInstance();

  const {
    data: csvData,
    status
  } = useQuery({
    queryKey: ['volunteerList', 'csvData'],
    queryFn: () => authAxios.get('api/v1/volunteers/csv'),
    retry: false,
    ...globalQueryConfig
  })

  return (
    <>
      {status === 'success' && !!csvData?.data &&
        <CSVLink
          data={csvData?.data}
          filename={"volunteer-list.csv"}
          className="btn btn-info rounded-pill btn-xs px-4"
          target="_blank"
        >
          Export CSV
        </CSVLink>
      }
    </>
  )
}

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useState } from "react";
import useFormPersist from "react-hook-form-persist";
import { useMultistepForm } from "components/hooks/useMultistepForm";
import { AboutCandidateForm } from "components/forms/single-step-forms/onboarding/AboutCandidateForm";
import {
  ConnectSocialMediaForm,
  FB_SAVED_RESPONSE_KEY,
  TWITTER_SAVED_RESPONSE_KEY,
} from "components/forms/single-step-forms/onboarding/ConnectSocialMediaForm";
import { ElectionDateForm } from "components/forms/single-step-forms/onboarding/ElectionDateForm";
import { WorkingHoursForm } from "components/forms/single-step-forms/onboarding/WorkingHoursForm";
import Timeline from "templates/components/Timeline";
import axios, { AxiosError } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useCandidateContext } from "global-state/contexts/CandidateContext";
import { addCandidate } from "global-state/actions/candidateActions";
import { useNotificationContext } from "global-state/contexts/notificationContext";
import { ErrorNotification } from "global-state/actions/notificationActions";
import InfoIconLight from "img/svg/InfoIconLight";
import { toast } from "react-toastify";

export const IS_ONBOARDING_COMPLETED = "isOnboardingCompleted";
export interface IOnboardingFormData {
  fullName: string;
  position: string;
  state: string;
  county: string;
  city: string;
  district: string;
  electionDate: Date;
  workingHours: string;
  customWorkingHours: string;
}

export const STEP_INDEX = "stepIndex";
export const WORKING_HOURS_FORM_COMMON_ERROR_MSG =
  "Choose or enter a working hours in number";

const titles = [
  "About Yourself",
  "Election Date",
  "Working Hours",
  "Social Media",
];

const aboutCandidateFormSchema = yup.object().shape({
  fullName: yup
    .string()
    .trim()
    .required("Please enter your name")
    .min(3, "Name should be of minimum 3 characters in length")
    .max(50, "Name should be of maximum 50 characters in length")
    .matches(/^[aA-zZ\s]+$/, "Name should contain alphabets only"),
  position: yup.string().required("Please choose a position"),
  state: yup.string().required("Please choose a state"),
  county: yup.string().required("Please choose a county"),
  city: yup
    .string()
    .trim()
    .required("Please enter a city")
    .min(3, "City should be of minimum 3 characters in length")
    .max(50, "City should be of maximum 50 characters in length"),
  district: yup
    .string()
    .trim()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable()
    .max(50, "District should be of maximum 50 characters in length"),
});

const electionDateFormSchema = yup.object().shape({
  electionDate: yup.date().required("Please choose a date"),
});

const connectSocialMediaFormSchema = yup.object().shape({});

const workingHoursFormSchema = yup.object().shape(
  {
    workingHours: yup.number().when("customWorkingHours", {
      is: (customWorkingHours: number) => !customWorkingHours,
      then: () => {
        return yup.number().required(WORKING_HOURS_FORM_COMMON_ERROR_MSG);
      },
      otherwise: () => yup.string().notRequired(),
    }),
    customWorkingHours: yup.number().when("workingHours", {
      is: (workingHours: number) => workingHours === null,
      then: () => {
        return yup
          .number()
          .integer("Working hours must be an integer number")
          .min(1, "Working hours must be a positive number")
          .max(168, "Working hours must be less than 169")
          .typeError("Working hours must be a number")
          .required(WORKING_HOURS_FORM_COMMON_ERROR_MSG);
      },
      otherwise: () => {
        return yup
          .number()
          .nullable()
          .notRequired()
          .transform((_, val) =>
            val !== "" ? (isNaN(Number(val)) ? null : Number(val)) : null
          );
      },
    }),
  },
  [["customWorkingHours", "workingHours"]]
);

const onboardingFormSchema = [
  aboutCandidateFormSchema,
  electionDateFormSchema,
  workingHoursFormSchema,
  connectSocialMediaFormSchema,
];

const OnboardingForm = ({
  setIsOnboardingCompleted,
}: {
  setIsOnboardingCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { candidateDispatch } = useCandidateContext();

  const { notificationDispatch, notificationState } = useNotificationContext();

  // const [fetchData, setFetchData] = useState();

  const [schemActiveIndex, setSchemActiveIndex] = useState(0);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
    getValues,
    reset,
    resetField,
  } = useForm<Partial<IOnboardingFormData>>({
    resolver: yupResolver(onboardingFormSchema[schemActiveIndex] as any),
  });

  const { getIdTokenClaims } = useAuth0();

  // useFormPersist: To save data on localstorage so that react-hook-form can retrive it back
  const { clear } = useFormPersist("onboardingFormData", {
    watch,
    setValue,
    storage: localStorage,
  });

  const { currentStepIndex, step, isLastStep, back, next } = useMultistepForm([
    <AboutCandidateForm
      register={register}
      errors={errors}
      getValues={getValues}
      resetField={resetField}
    />,
    <ElectionDateForm errors={errors} control={control} back={() => back()} />,
    <WorkingHoursForm
      register={register}
      errors={errors}
      selectedWorkingHours={Number(getValues(["workingHours"]))}
      back={() => back()}
    />,

    <ConnectSocialMediaForm register={register} back={() => back()} />,
  ]);

  useEffect(() => {
    setSchemActiveIndex(currentStepIndex);
    localStorage.setItem(STEP_INDEX, String(currentStepIndex));
  }, [currentStepIndex]);

  const postOnboardingData = async (data: Partial<IOnboardingFormData>) => {
    try {
      const token = await getIdTokenClaims();
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/election`,
        {
          fullName: data.fullName,
          electionPosition: data.position,
          city: data.city,
          district: data.district || undefined,
          state: data.state,
          county: data.county,
          electionDate: data.electionDate,
          weeklyAvailability: data.workingHours
            ? data.workingHours
            : data.customWorkingHours,
          hasVotersList: false,
          hasDonorsList: false,
        },
        {
          headers: {
            Authorization: `Bearer ${token?.__raw}`,
          },
        }
      );

      if (response.status === 201) {
        // alert("Successfully submitted data");

        const candidate = response.data;
        candidateDispatch(
          addCandidate({
            id: candidate?.candidateId,
            name: candidate?.fullName,
            city: candidate?.city,
            position: candidate?.position,
          })
        );

        localStorage.setItem(IS_ONBOARDING_COMPLETED, "true");
        setIsOnboardingCompleted(true);

        // reset values from react-hook-form and localStorage
        reset(); // react-hook-form reset
        clear(); // removing data from localStorage
        localStorage.removeItem(STEP_INDEX);
        localStorage.removeItem(TWITTER_SAVED_RESPONSE_KEY);
        localStorage.removeItem(FB_SAVED_RESPONSE_KEY);
      }
    } catch (err: any) {
      console.log(err);
      if (err instanceof AxiosError) {
        if (err.response) {
          const errorMessage = err.response.data.error;

          notificationDispatch(ErrorNotification(errorMessage));
          if (notificationState.errorMessage) {
            toast.error(notificationState.errorMessage, {
              toastId: 1,
              className: "snackBar",
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "dark",
              hideProgressBar: true,
              autoClose: 5000,
              icon: <InfoIconLight />,
            });
          }
        }
      }
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => {
          if (!isLastStep) return next();
          postOnboardingData(data);
        })}
      >
        <main className="content">
          <div className="timeline-progress bg-white">
            <Timeline titles={titles} currentIndex={currentStepIndex} />
          </div>
          {step}
        </main>
      </form>
    </div>
  );
};

export default OnboardingForm;

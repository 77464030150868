import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useRef } from "react"
import Spinner from "templates/components/Spinner"
import CreateAuthAxiosInstance from "utils/authAxios"
import toastNotify from "utils/toastNotify"
import { IVolunteer } from "../useVolunteerList"

export const DeleteVolunteerModal = ({
  volunteerId,
  setSelectedVolunteerForDetailView
}: {
  volunteerId: number | null,
  setSelectedVolunteerForDetailView: React.Dispatch<React.SetStateAction<IVolunteer | null>>
}) => {
  const authAxios = CreateAuthAxiosInstance()
  const queryClient = useQueryClient()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const {
    mutate: deleteVolunteerMutation,
    status: deleteVolunteerStatus
  } = useMutation({
    mutationFn: (volunteerId: number) => authAxios.delete(`api/v1/volunteers/${volunteerId}`),
    onSuccess: handleOnSuccessMutation,
    onError: (data) => {
      console.log("onerror data: ", data)
    }
  })

  function handleOnSuccessMutation() {
    toastNotify('success', 'Volunteer Deleted Successfully !')
    queryClient.invalidateQueries({ queryKey: ['volunteerList'] })
    setSelectedVolunteerForDetailView(null)

    if (buttonRef?.current) buttonRef?.current?.click()
  }

  function handleDeleteMutation() {
    if (volunteerId) deleteVolunteerMutation(volunteerId)
  }

  const resetDeleteTarget = () => setSelectedVolunteerForDetailView(null)

  return (
    <>
      <div
        className="modal fade"
        id="deleteModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetDeleteTarget}
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3">Do you want to delete the volunteer? </h2>
              <p>
                By deleting, this volunteer will no longer be able to support your campaign through Campaign Brain.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-dismiss="modal"
                onClick={resetDeleteTarget}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary rounded-pill btn-xs"
                onClick={handleDeleteMutation}
                disabled={deleteVolunteerStatus === 'pending'}
              >
                {deleteVolunteerStatus === 'pending' && <Spinner />}
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        className="btn btn-primary d-none"
        ref={buttonRef}
        type="button"
        data-bs-dismiss="modal"
        aria-label="Close"
        data-bs-target="#deleteModal"
      />
    </>
  )
}

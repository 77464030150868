import useDonorDetails from "./useDonorDetails"
import AlertBanner from "templates/components/AlertBanner"
import VoiceMailUpload from "../voter-details/components/VoiceMailUpload"
import ActBlueSection from "./donor-details-component/ActBlueSection"
import DonorListSection from "./donor-details-component/DonorListSection"
import DonationLinkSection from "./donor-details-component/DonationLinkSection"
import FundRaiseSection from "./donor-details-component/FundRaiseSection"
import FundRaisingGoalSection from "./donor-details-component/FundRaisingGoalSection"

const DonorDetails = () => {
  const {
    donorData,
    isDonorDataFetching,
    postDonorsSubmit,
    postAsyncDonorsSubmit,
    isPendingDonorPostMutation,
    postDonorSubmitStatus,
    updateDonorsSubmit,
    updateAsyncDonorsSubmit,
    isPendingDonorUpdateMutation,
    updateDonorsSubmitStatus,
    userInfo
  } = useDonorDetails()

  return (
    <div className="row mt-5">
      <div className="col-md-8">
        <div className="settings">
          <div className="settings-header d-flex justify-content-between">
            <div className="">
              <h4>Fundraise</h4>
              <p className="body-4 text-muted">View your fundraise details</p>
            </div>
          </div>
          <div className="settings-body">
            {!userInfo?.isDashboardSetupCompleted ? (
              <div className="mt-4">
                <AlertBanner />
              </div>
            ) : (
              <div className="voterDetail-info">
                <hr className="mt-3 mb-3" />
                <DonationLinkSection
                  donorData={donorData}
                  isDonorDataFetching={isDonorDataFetching}
                  postDonorsSubmit={postDonorsSubmit}
                  isPendingDonorPostMutation={isPendingDonorPostMutation}
                  updateDonorsSubmit={updateDonorsSubmit}
                  isPendingDonorUpdateMutation={isPendingDonorUpdateMutation}
                />

                <hr className="mt-3 mb-3" />
                <FundRaiseSection
                  donorData={donorData}
                  isDonorDataFetching={isDonorDataFetching}
                  postDonorsSubmit={postDonorsSubmit}
                  isPendingDonorPostMutation={isPendingDonorPostMutation}
                  updateDonorsSubmit={updateDonorsSubmit}
                  isPendingDonorUpdateMutation={isPendingDonorUpdateMutation}
                />

                <hr className="mt-3 mb-3" />
                <FundRaisingGoalSection
                  donorData={donorData}
                  isDonorDataFetching={isDonorDataFetching}
                  postDonorsSubmit={postDonorsSubmit}
                  postAsyncDonorsSubmit={postAsyncDonorsSubmit}
                  postDonorSubmitStatus={postDonorSubmitStatus}
                  updateDonorsSubmit={updateDonorsSubmit}
                  updateAsyncDonorsSubmit={updateAsyncDonorsSubmit}
                  updateDonorsSubmitStatus={updateDonorsSubmitStatus}
                />

                <hr className="mt-3 mb-3" />
                <DonorListSection />
              </div>
            )}
          </div>
        </div>

        <ActBlueSection />

        <VoiceMailUpload
          candidateElectionId={userInfo?.candidate?.elections?.[0]?.id || 0}
          sectionType="fundraise" // 'voter' or 'fundraise'
        />
      </div>
    </div>
  )
}

export default DonorDetails

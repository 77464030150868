import React, { useEffect, useRef, useState } from "react";
import SideNavbar from "components/common/navbar/SideNavbar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!;
import CommunicationModal from "./Modal";
import CustomEventTitle from "./CustomTitle";
import { ICandidatePost, IEvents } from "./interface";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { getCurrentTimeStamp } from "utils/getDateTime";

const Communications: React.FC = () => {
  const { getIdTokenClaims } = useAuth0();
  const [events, setEvents] = useState<IEvents[]>([]);
  const [eventsGroup, setEventsGroup] = useState<IEvents[]>([]);
  const [currentEvent, setCurrentEvent] = useState<IEvents>();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatedEvent, setIsUpdatedEvent] = useState(false);
  const [generatedEvents, setGeneratedEvents] = useState([]);
  const closeRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    async function fetchEvents() {
      setIsLoading(true);
      const token = await getIdTokenClaims();
      try {
        const { data } = await axios.get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/api/v1/posts/calendar?timestamp=${getCurrentTimeStamp()}`,
          {
            headers: {
              Authorization: `Bearer ${token?.__raw}`,
            },
          }
        );
        const formattedData = data.map((d: any) => {
          let isApproved = false;
          // const formatedDate = getYearMonthDay(d.startDate);
          d.candidatePosts.map((candidatePost: ICandidatePost) => {
            if (candidatePost.status === "Approved") {
              isApproved = true;
            }
          });
          return {
            ...d,
            postId: d.id,
            date: d.startDate,
            isApproved: isApproved,
            // startDate: formatedDate,
            hasCandidatePosts: d.candidatePosts.length > 0,
          };
        });

        const sortedData = formattedData.sort((a: any, b: any) => a.id - b.id);
        setEvents(sortedData);
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
      }
    }
    fetchEvents();
  }, [isUpdatedEvent]);

  const getGeneratedEventData = async (
    eventId: number | undefined,
    socialMedia: string | undefined
  ) => {
    const token = await getIdTokenClaims();
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/posts/candidate?eventId=${eventId}&socialMedia=${socialMedia}`,
        {
          headers: {
            Authorization: `Bearer ${token?.__raw}`,
          },
        }
      );
      setGeneratedEvents(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const close = () => {
    setTimeout(() => {
      closeRef?.current?.click();
    }, 1550); //wait for modal success message to dismiss
  };

  const getCurrentEvent = (id: number) => {
    const event = events.find((event) => event.postId === id);
    const dayEvents = events.filter((ev) => ev.startDate === event?.startDate); // get particular day events list
    const formattedData = dayEvents
      .filter((ev) => ev.isApproved === true) // get approved  posts only
      .map((e, index) => {
        // add countIndex
        return {
          ...e,
          countIndex: ++index,
        };
      });
    setEventsGroup(formattedData);
    const currentEventWithCountIndex = formattedData.find(
      (fd) => fd.postId === id
    );
    setCurrentEvent(currentEventWithCountIndex);
    getGeneratedEventData(event?.eventId, event?.socialMedia);
  };

  const getNextPost = (currentIndex: number | undefined) => {
    if (!currentIndex) return;
    // setIsUpdatedEvent(!isUpdatedEvent);
    const nextPost = eventsGroup.find(
      (eg) => eg.countIndex === currentIndex + 1
    );
    setCurrentEvent(nextPost);
  };

  const removeEventById = async (
    id: number | undefined,
    index: number | undefined
  ) => {
    if (!id || !index) return;
    const isLastPost = index === eventsGroup.length;
    if (eventsGroup.length < 1) return;
    const updatedEventGroup = eventsGroup
      .map((ev) => {
        return {
          ...ev,
          countIndex: Number(ev?.countIndex) - 1,
        };
      })
      .filter((eg) => eg?.postId !== id);
    if (isLastPost) {
      close();
    } else {
      const event = updatedEventGroup.find(
        (event) => event.countIndex === index
      );
      setCurrentEvent(event);
      setEventsGroup(updatedEventGroup);
    }
  };

  const eventOrder = (a: any, b: any) => {
    return a.postId - b.postId;
  };

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="row">
            <div className="col-md-12">
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                  start: "prev next title",
                  center: "",
                  end: "",
                }}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                eventOrder={eventOrder}
                // showNonCurrentDates={false}
                events={events}
                eventContent={(renderInfo) => {
                  return (
                    <CustomEventTitle
                      title={renderInfo.event.title}
                      customProps={renderInfo.event.extendedProps}
                      handleCurrentEvent={getCurrentEvent}
                    />
                  );
                }}
                timeZone="UTC"
              />
            </div>
          </div>
          <CommunicationModal
            events={events}
            currentEvent={currentEvent}
            setIsUpdatedEvent={setIsUpdatedEvent}
            isUpdatedEvent={isUpdatedEvent}
            eventsGroup={eventsGroup}
            generatedEvents={generatedEvents}
            getNextPost={getNextPost}
            removeEventById={removeEventById}
            close={close}
            closeRef={closeRef}
          />
        </main>
      </div>
    </div>
  );
};

export default Communications;

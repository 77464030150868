import "./styles/sass/main.scss";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/css/campaignBrainFonts.css";

import { CandidateProvider } from "./global-state/contexts/CandidateContext";

import { ToastContainer } from "react-toastify";

import DataRouter from "./DataRouter";
import { NotificationProvider } from "global-state/contexts/notificationContext";

import("bootstrap");

function App() {
  return (
    <CandidateProvider>
      <NotificationProvider>
        <ToastContainer />
        <DataRouter />
      </NotificationProvider>
    </CandidateProvider>
  );
}

export default App;

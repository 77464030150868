import React from 'react'

const EditIconPrimary = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M13.5607 2.43934C12.9749 1.85355 12.0251 1.85355 11.4393 2.43934L5.75 8.12868V10.25H7.87132L13.5607 4.56066C14.1464 3.97487 14.1464 3.02513 13.5607 2.43934Z" fill="#0B45A5"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2 5C2 4.17157 2.67157 3.5 3.5 3.5H6.5C6.91421 3.5 7.25 3.83579 7.25 4.25C7.25 4.66421 6.91421 5 6.5 5H3.5V12.5H11V9.5C11 9.08579 11.3358 8.75 11.75 8.75C12.1642 8.75 12.5 9.08579 12.5 9.5V12.5C12.5 13.3284 11.8284 14 11 14H3.5C2.67157 14 2 13.3284 2 12.5V5Z" fill="#0B45A5"/>
</svg>
  )
}

export default EditIconPrimary

// import InfoIconWarning from 'img/svg/InfoIconWarning'
import TrashIconSmall from "img/svg/TrashIconSmall";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DeleteTurfModal } from "./DeleteTurfModal";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toastNotify from "utils/toastNotify";
import Spinner from "components/common/Spinner";
import { IUniverseDetail, SELECTED_UNIVERSE_ID, } from "../turf-detail/useTurfDetail";

interface IManageTurfModalProps {
  universeList: IUniverseDetail[];
  isFetchingUniverseList: boolean;
}

export const ManageTurfModal = React.memo(({
  universeList,
  isFetchingUniverseList
}: IManageTurfModalProps) => {
  const authAxios = CreateAuthAxiosInstance();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const storedSelectedUniverseId = localStorage.getItem(SELECTED_UNIVERSE_ID);

  const [selectedUniverseId, setSelectedUniverseId] = useState<number>(storedSelectedUniverseId ? Number(storedSelectedUniverseId) : 0);
  const [selectedUniverseIdForDeletion, setSelectedUniverseIdForDeletion] = useState<number | null>(null);

  //Handle initial universe selection
  useEffect(() => {
    function handleInitialUniverseSelection() {
      if (!universeList?.length) return;

      const isSelectedUniversePresent = universeList.some((item) => item.id === selectedUniverseId);
      if (isSelectedUniversePresent) return;

      //if no universe selected, select the first universe of the list
      setSelectedUniverseId(universeList[0]?.id || 0);
    }

    handleInitialUniverseSelection();
  }, [universeList]);

  const {
    mutate: activateUniverseMutation,
    data: activateUniverseMutationData,
    isPending: isPendingActivateUniverse,
  } = useMutation({
    mutationFn: (universeId: number) => authAxios.patch(`api/v1/universes/${universeId}`, { isActive: true }),
    onSuccess: handleSuccessEvent,
    onError: (error: any) => {
      console.log("on error", error?.response?.data?.message);
      toastNotify("error", "Error! Could not activate universe!");
    },
  });

  function handleSuccessEvent() {
    toastNotify("success", "Universe activated successfully!");
    queryClient.invalidateQueries({ queryKey: ["universeList"] });
  }

  const handleActivationEvent = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    universeId: number
  ) => {
    e.stopPropagation();
    if (universeId) {
      activateUniverseMutation(universeId);
    }
  };

  const handleDeleteEvent = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    e.stopPropagation();
    setSelectedUniverseIdForDeletion(id);
  };

  const handleContinueEvent = () => {
    if (!selectedUniverseId) return;

    navigate("/voters/turf", { state: { universeId: selectedUniverseId } });
  };

  const getUniverseName = (universeId: number | null) => {
    if (universeList?.length && universeId) {
      const tempUniverse = universeList.find((item, index) => item.id === universeId);
      return tempUniverse?.name || "";
    }
    return "";
  };

  useEffect(() => {
    localStorage.setItem(SELECTED_UNIVERSE_ID, String(selectedUniverseId));
  }, [selectedUniverseId]);

  const closeManageTurfModal = () => {
    navigate("/voters/dashboard");
    localStorage.removeItem(SELECTED_UNIVERSE_ID);
  };

  return (
    <>
      <div
        className="modal fade"
        id="manageTurfModal"
        data-bs-backdrop="static"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header border-1">
              <h5 className="modal-title body-2 mb-0">Choose universe</h5>
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeManageTurfModal}
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className="d-xs-block d-sm-block d-md-none">
                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                  <InfoIconWarning />
                  <span className="ms-2">Use web to access all features.</span>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  >
                    <span className="icon-close"></span>
                  </button>
                </div>
              </div> */}
              <p className="">
                Select a universe. Only active universe changes affect
                door-to-door canvassing.
              </p>
              <span className="body-4 text-muted d-block">Universe list</span>
              {!isFetchingUniverseList ? (
                <>
                  {!!universeList?.length &&
                    universeList.map(
                      (item: IUniverseDetail) => {
                        const {
                          id,
                          name,
                          filterType,
                          candidateId,
                          isActive,
                          type,
                          voterCount,
                          doorCount,
                          turfsCount,
                        } = item;

                        return (
                          <div
                            key={id}
                            className={`universeList ${id === selectedUniverseId ? "active" : ""}`}
                            onClick={() => setSelectedUniverseId(id)}
                          >
                            <div className="universeList-indicator"></div>
                            <div className="universeList-body">
                              <div className="universeList-title">
                                <span className="body-4">
                                  {`${type === "Default" ? "Default Universe: " : ""} ${name}`}
                                </span>
                                {isActive && (
                                  <span className="badge bg-success rounded-pill px-2 font-regular">
                                    Active
                                  </span>
                                )}
                              </div>
                              <div className="universeList-info">
                                <span className="fs-8 text-muted">
                                  {filterType}
                                </span>
                                <span className="dot dot-sm bg-neutral-n3 mx-1" />
                                <span className="fs-8 text-muted">
                                  Total Doors: {doorCount}
                                </span>
                                <span className="dot dot-sm bg-neutral-n3 mx-1" />
                                <span className="fs-8 text-muted">
                                  Total Voters: {voterCount}
                                </span>
                                <span className="dot dot-sm bg-neutral-n3 mx-1" />
                                <span className="fs-8 text-muted">
                                  Turfs: {turfsCount}
                                </span>
                              </div>
                            </div>
                            <div className="universeList-action">
                              <button
                                className="btn btn-link no-hover btn-xs px-0 py-0 d-flex justify-content-cente align-items-center"
                                disabled={isActive}
                                onClick={(e) => handleActivationEvent(e, id)}
                              >
                                {isPendingActivateUniverse ? (
                                  <Spinner varient="text-primary" />
                                ) : (
                                  " Activate"
                                )}
                              </button>
                              <button
                                className="btn btn-link no-hover btn-xs px-0 py-0"
                                disabled={isActive || type === "Default"}
                                data-bs-toggle="modal"
                                data-bs-target="#deleteTurfModal"
                                onClick={(e) => handleDeleteEvent(e, id)}
                              >
                                <TrashIconSmall />
                              </button>
                            </div>
                          </div>
                        );
                      }
                    )}
                </>
              ) : (
                <>
                  {/* universe list skeleton */}
                  <div className="placeholder-glow">
                    <div className="placeholder col-12 pt-5 pb-4 mb-3"></div>
                    <div className="placeholder col-12 pt-5 pb-4 mb-3"></div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer justify-content-between border-1">
              <Link
                to=""
                className="btn btn-link text-primary rounded-pill btn-xs d-flex align-items-center d-none d-md-block"
                data-bs-target="#addTurfModal"
                data-bs-toggle="modal"
              >
                <span className="icon-plus me-1"></span>
                Add universe
              </Link>
              <div className="universe-btns">
                <Link
                  to=""
                  className="btn btn-link text-primary rounded-pill btn-xs me-1"
                  data-bs-dismiss="modal"
                  onClick={closeManageTurfModal}
                >
                  Cancel
                </Link>
                <button
                  className="btn btn-primary rounded-pill btn-xs px-6"
                  data-bs-dismiss="modal"
                  onClick={handleContinueEvent}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DeleteTurfModal
        universeId={selectedUniverseIdForDeletion}
        universeName={getUniverseName(selectedUniverseIdForDeletion)}
      />
    </>
  );
}
);

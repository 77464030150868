import { useEffect, useState } from "react";
import AddVoter from "./account-setup/AddVoter";
import AddDonor from "./account-setup/AddDonor";
import YourStory from "./account-setup/YourStory";
import Ideology from "./account-setup/Ideology";
import axios from "axios";
import {
  ICanidateAdditionalDetails,
  IElectionData,
  IFileType,
  IResponseFileInfo,
} from "type/dashboard";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "utils/localStorageHelper";
import { CANDIDATE_ID } from "../Homepage";
import CreateAuthAxiosInstance from "utils/authAxios";
import CampaignInformation from "./account-setup/CampaignInformation";
import { findDaysUntilElection } from "utils/getDayUntilElection";
import Loader from "components/common/Loader";
import AccountSetupCompletedModal from "./account-setup/AccountSetupCompletedModal";
import AccountSetupStepper from "./account-setup/AccountSetupStepper";
import StartAccountSetup from "./account-setup/StartAccountSetup";
import { ConversationalAIProvider } from "./conversational-ai/useConversationalAi";
import ConversationalAI from "./conversational-ai";
import TopPriorities from "./top-priorities/TopPriorities";
import { TopPrioritiesProvider } from "./top-priorities/useTopPrioritiesData";
import DailyNewsClips from "./daily-news/DailyNewsClips";
import Budget from "./account-setup/Budget";

export const IS_ACCOUNT_SETUP_STARTED = "IS_ACCOUNT_SETUP_STARTED";
export const ACTIVE_STEP = "ACTIVE_STEP";
export const COMPLETED_STEPS = "COMPLETED_STEPS";
export const TOTAL_NUMBER_OF_STEPS = 5;
export const ELECTION_ID = "electionId";
export const DAYS_UNTIL_ELECTION = "daysUnntilElection";

const Dashboard = () => {
  const storedIsAccountSetup = getLocalStorageItem(
    IS_ACCOUNT_SETUP_STARTED,
    false
  );
  const [isProfileCreated, setIsProfileCreated] = useState(false);
  const [daysUntilElection, setDaysUntilElection] = useState<number>(0);

  const [isAccountSetupStarted, setIsAccountSetupStarted] =
    useState<boolean>(storedIsAccountSetup);

  const [fullName, setFullName] = useState("");
  const [electionInfo, setElectionInfo] = useState<IElectionData>();
  const [candidateAdditionDetails, setCandidateAdditionalDetails] =
    useState<ICanidateAdditionalDetails>({});

  const [hasDonorsFileUploaded, setHasDonorsFileUploaded] =
    useState<boolean>(false);

  const localUserId = localStorage.getItem(CANDIDATE_ID);
  const [electionID, setElectionID] = useState<number>();

  const [fileInfo, setFileInfo] = useState<IResponseFileInfo | null>();

  const [isAccountSetupCompleted, setIsAccounntSetupCompleted] = useState<
    boolean | undefined
  >();

  const [status, setStatus] = useState<string>("completed");
  const [activeStep, setActiveStep] = useState<number>(
    0 | Number(localStorage.getItem(ACTIVE_STEP))
  );

  const storedCompletedSteps = getLocalStorageItem(COMPLETED_STEPS, [
    { index: activeStep, status: "active" },
  ]);

  const [completedSteps, setCompletedSteps] =
    useState<{ index: number; status: string }[]>(storedCompletedSteps);

  const handleStepCompletion = (stepNumber: number, status: string) => {
    setCompletedSteps((prevCompletedSteps) => {
      const updatedSteps = [
        ...prevCompletedSteps.filter((step) => step.index !== stepNumber),
        { index: stepNumber, status },
      ];
      setLocalStorageItem(
        COMPLETED_STEPS,
        JSON.stringify(updatedSteps.slice(0, TOTAL_NUMBER_OF_STEPS))
      );
      return updatedSteps.slice(0, TOTAL_NUMBER_OF_STEPS);
    });

    setActiveStep(activeStep + 1);
  };

  const authAxtios = CreateAuthAxiosInstance();

  useEffect(() => {
    if (completedSteps.length < TOTAL_NUMBER_OF_STEPS) {
      setLocalStorageItem(ACTIVE_STEP, String(activeStep));
      setLocalStorageItem(
        IS_ACCOUNT_SETUP_STARTED,
        String(isAccountSetupStarted)
      );
    }

    const fetchFile = async () => {
      try {
        var fileTypeName = "";

        if (activeStep === 0) fileTypeName = "Voters";
        else if (activeStep === 1) fileTypeName = "Donors";
        else fileTypeName = "Candidate";

        const fileTypes = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/file-type`
        );

        const fileType: IFileType = fileTypes.data.find(
          (type: IFileType) => type.title === fileTypeName
        );

        const response = await authAxtios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/file/${Number(
            localUserId
          )}`
        );
        if (response.data?.length > 0) {
          const fileTypeId: number = fileType.id;

          const requiredFile = response.data.filter(
            (file: IResponseFileInfo) =>
              file.fileTypeId.toString() === fileTypeId.toString()
          );
          if (requiredFile) setFileInfo(requiredFile[0]);
        } else {
          setFileInfo(null);
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    fetchFile();

    const fetchEelctionData = async () => {
      const responseElection = await authAxtios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/candidate/election`
      );
      if (responseElection?.data) {
        setFullName(responseElection?.data?.fullName);
        setElectionInfo(responseElection?.data);
        setHasDonorsFileUploaded(
          responseElection.data?.hasDonorsListFileUploaded
        );
        setElectionID(responseElection.data?.id);
        localStorage.setItem(ELECTION_ID, responseElection.data?.id);
      }
    };
    fetchEelctionData();
  }, [activeStep, isAccountSetupStarted, completedSteps, fullName, electionID]);

  useEffect(() => {
    (async () => {
      try {
        if (electionID) {
          await authAxtios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/election/${electionID}`,
            { hasDonorsListFileUploaded: hasDonorsFileUploaded }
          );
        }
      } catch (error: any) {
        console.error("The user data was not updated because", error.message);
      }
    })();
  }, [hasDonorsFileUploaded]);

  useEffect(() => {
    (async () => {
      try {
        const userInfoResponse = await authAxtios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/info/${localUserId}`
        );
        setIsAccounntSetupCompleted(
          userInfoResponse.data?.isDashboardSetupCompleted
        );
      } catch (error: any) {
        console.error("The user data was not fetched because", error.message);
      }
    })();
  }, [isAccountSetupCompleted, electionID]);

  useEffect(() => {
    (async () => {
      try {
        const candidateDetailsResponse = await authAxtios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/profile-setting/candidate-details`
        );
        if (candidateDetailsResponse?.data)
          setCandidateAdditionalDetails(candidateDetailsResponse?.data);
        if (candidateDetailsResponse?.data?.id) setIsProfileCreated(true);
      } catch (error: any) {
        console.error(error.message);
      }
    })();
  }, [isAccountSetupCompleted, electionID]);

  useEffect(() => {
    const electionDays = electionInfo
      ? findDaysUntilElection(electionInfo?.electionDate)
      : 0;
    localStorage.setItem(DAYS_UNTIL_ELECTION, String(electionDays));
    setDaysUntilElection(electionDays);
  }, [electionInfo]);

  return (
    <div className="d-flex align-items-stretch">
      <div className="wrapper active-cont">
        {isAccountSetupCompleted === undefined || electionInfo === undefined ? (
          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ minHeight: "100%" }}
          >
            <Loader />
          </div>
        ) : (
          <main>
            {isAccountSetupCompleted ? (
              // if account setup completed
              <>
                <div className="row justify-content-center mt-3">
                  <div className="col-lg-8">
                    <ConversationalAIProvider>
                      <ConversationalAI />
                    </ConversationalAIProvider>

                    <TopPrioritiesProvider>
                      <TopPriorities daysUntilElection={daysUntilElection} />
                    </TopPrioritiesProvider>
                  </div>
                  <div className="col-lg-4">
                    <DailyNewsClips />
                  </div>
                </div>
              </>
            ) : !isAccountSetupStarted ? (
              // if account setup not started
              <StartAccountSetup
                fullName={fullName}
                isAccountSetupStarted={isAccountSetupStarted}
                setIsAccountSetupStarted={setIsAccountSetupStarted}
              />
            ) : (
              // if account setup started
              <div className="row">
                <div className="col-md-4">
                  <div className="accountSetup accountSetup-fixed">
                    <div className="accountSetup-header">
                      <h5>Account setup list</h5>
                      <p>Setup your account to use Campaign Brain</p>
                    </div>
                    <div className="accountSetup-body">
                      <AccountSetupStepper
                        activeStep={activeStep}
                        completedSteps={completedSteps}
                        setActiveStep={setActiveStep}
                        setStatus={setStatus}
                      />
                    </div>
                  </div>
                </div>

                {(() => {
                  switch (activeStep) {
                    case 0:
                      return (
                        <AddVoter
                          handleStepCompletion={handleStepCompletion}
                          fileInfo={fileInfo}
                          setFileInfo={
                            setFileInfo as React.Dispatch<
                              React.SetStateAction<IResponseFileInfo | null>
                            >
                          }
                          estimatedVoters={
                            candidateAdditionDetails?.estimatedVoters
                              ? Number(
                                  candidateAdditionDetails?.estimatedVoters
                                )
                              : undefined
                          }
                          voteGoal={candidateAdditionDetails?.voteGoal}
                          isProfileCreated={isProfileCreated}
                        />
                      );
                    case 1:
                      return (
                        electionID && (
                          <AddDonor
                            handleStepCompletion={handleStepCompletion}
                            fileInfo={fileInfo}
                            fundRaised={candidateAdditionDetails?.fundRaised}
                            fundraisingGoal={
                              candidateAdditionDetails?.fundraisingGoal
                            }
                            setFileInfo={
                              setFileInfo as React.Dispatch<
                                React.SetStateAction<IResponseFileInfo | null>
                              >
                            }
                            isProfileCreated={isProfileCreated}
                            electionId={electionID}
                            hasDonorsFileUploaded={hasDonorsFileUploaded}
                            setHasDonorsFileUploaded={
                              setHasDonorsFileUploaded as React.Dispatch<
                                React.SetStateAction<boolean>
                              >
                            }
                          />
                        )
                      );

                    case 2:
                      return (
                        <YourStory
                          handleStepCompletion={handleStepCompletion}
                          fileInfo={fileInfo}
                          setFileInfo={
                            setFileInfo as React.Dispatch<
                              React.SetStateAction<IResponseFileInfo | null>
                            >
                          }
                          isProfileCreated={isProfileCreated}
                          // campaignStoryData={candidateAdditionDetails?.}
                        />
                      );

                    case 3:
                      return (
                        <CampaignInformation
                          handleStepCompletion={handleStepCompletion}
                          websiteUrl={candidateAdditionDetails?.websiteUrl}
                          isProfileCreated={isProfileCreated}
                        />
                      );
                    case 4:
                      return(
                        <Budget handleStepCompletion={handleStepCompletion}/>
                      )
                    case 5:
                      return (
                        <Ideology
                          handleStepCompletion={handleStepCompletion}
                          fileInfo={fileInfo}
                          setFileInfo={
                            setFileInfo as React.Dispatch<
                              React.SetStateAction<IResponseFileInfo | null>
                            >
                          }
                          setIsCompleted={setIsAccounntSetupCompleted}
                          isProfileCreated={isProfileCreated}
                        />
                      );

                    default:
                      return <></>;
                  }
                })()}
              </div>
            )}
          </main>
        )}
      </div>

      {/* Modal */}
      <AccountSetupCompletedModal />
    </div>
  );
};

export default Dashboard;

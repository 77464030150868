import axios from "axios";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "./localStorageHelper";

export const formatSize = (size: number): string => {
  if (size < 1024) {
    return `${size} Bytes`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    const calculatedSize = size / (1024 * 1024);
    if (calculatedSize <= 2.0) {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    } else return "File size exceeds 2 MB";
  }
};

export const handleFileDelete = async (step: number) => {
  try {
    const storedFile = getLocalStorageItem(`file-${step}`);
    if (storedFile && storedFile.id) {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/file/${storedFile.id}`
      );
      removeLocalStorageItem(`file-${step}`);
    }
  } catch (error) {
    console.error("File not deleted", error);
  }
};

export const formatFileSize = (bytes: number | null) => {
  if (bytes === null) return;
  if (bytes > 1048576) return (bytes / (1024 * 1024)).toFixed(3) + " MB";
  if (bytes > 1023) return (bytes / 1024).toFixed(3) + " KB";

  return bytes + "Byte";
};

export const dateFormatter = (date: string | null) => {
  if (!date) return

  const newDate = new Date(date)
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()} at ${formattedHours}:${formattedMinutes} ${ampm}`
}

import { FieldValues, UseFormRegister } from "react-hook-form";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useEffect, useState } from "react";
import axios from "axios";
import GoBack from "templates/components/GoBack";
import FbIcon from "img/svg/fb";
import TwitterIcon from "img/svg/twitter";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { useAuth0 } from "@auth0/auth0-react";

export const FB_SAVED_RESPONSE_KEY = "fbResponse";

export const TWITTER_SAVED_RESPONSE_KEY = "twitterResponse";

type ConnectedSocialMediaFormProps = {
  register: UseFormRegister<FieldValues>;
  back: () => void;
};

export function ConnectSocialMediaForm({
  back,
}: ConnectedSocialMediaFormProps) {
  const retrivedCandidateId = localStorage.getItem(CANDIDATE_ID);

  const retrivedTwitterResponse = localStorage.getItem(
    TWITTER_SAVED_RESPONSE_KEY
  );
  const retrivedFbResponse = localStorage.getItem(FB_SAVED_RESPONSE_KEY);

  const [fbResponse, setFbResponse] = useState<any>(
    retrivedFbResponse ? JSON.parse(retrivedFbResponse) : null
  );
  const [twitterResponse, setTwitterResponse] = useState<any>(
    retrivedTwitterResponse ? JSON.parse(retrivedTwitterResponse) : null
  );
  const [isFbConnected, setIsFbConnected] = useState(
    fbResponse?.name ? true : false
  );
  const [isTwitterConnected, setIsTwitterConnected] = useState(
    twitterResponse ? true : false
  );

  const { getIdTokenClaims } = useAuth0();

  const twitterLogin = async () => {
    if (retrivedCandidateId) {
      try {
        const authToken = await getIdTokenClaims();
        // get redirect url
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/twitter/oauth-url`,
          {
            pageAddress: +retrivedCandidateId,
            page: "onboarding",
          },
          {
            headers: {
              Authorization: `Bearer ${authToken?.__raw}`,
            },
          }
        );

        const { oauthURL } = response.data;
        // setTwitterResponse(token);

        // redirect to the twitter url
        if (oauthURL) window.location.href = oauthURL;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const twitterLogout = () => {
    if (retrivedCandidateId) {
      localStorage.removeItem(TWITTER_SAVED_RESPONSE_KEY);
      (async () => {
        try {
          const authToken = await getIdTokenClaims();
          // twitter logout
          await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/twitter/logout`,
            {},
            {
              headers: {
                Authorization: `Bearer ${authToken?.__raw}`,
              },
            }
          );

          setIsTwitterConnected(false);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  };

  const responseFacebook = async (response: any) => {
    if (response.id) {
      try {
        const authToken = await getIdTokenClaims();

        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/facebook/user-access-token`,
          {
            exchangeToken: response.accessToken,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken?.__raw}`,
            },
          }
        );
      } catch (e) {
        console.log(e);
      }
      setIsFbConnected(true);
      setFbResponse(response);
    }
  };

  useEffect(() => {
    if (fbResponse)
      localStorage.setItem(FB_SAVED_RESPONSE_KEY, JSON.stringify(fbResponse));
  }, [fbResponse]);

  useEffect(() => {
    if (twitterResponse)
      localStorage.setItem(
        TWITTER_SAVED_RESPONSE_KEY,
        JSON.stringify(twitterResponse)
      );
  }, [twitterResponse]);

  useEffect(() => {
    if (retrivedCandidateId)
      (async () => {
        // Get twitter profile info
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/info/${retrivedCandidateId}`
        );
        if (response.data.profiles.length) {
          setTwitterResponse(response.data.profiles[0]);
          setIsTwitterConnected(true);
        }
      })();
  }, [isTwitterConnected]);

  return (
    <div className="formArea bg-grey">
      <GoBack back={back} />
      <div className="formContents">
        <div className="formContents-header">
          <h1>What are the official social media pages of the campaign? </h1>
          <p>Campaign Brain will help you build momentum online</p>
        </div>
        <div className="formContents-body">
          {/* connect card facebook*/}
          {isFbConnected ? (
            <div className="connectCard">
              <div className="connectCard-icon connected">
                <div className="profileBox fb">
                  <img
                    src={
                      fbResponse?.picture?.data?.url ??
                      require("../../../../img/avatar.png")
                    }
                    className="img-fluid"
                    alt=""
                  />

                  <div className="profileBox-logo">
                    <FbIcon />
                  </div>
                </div>
                <div className="iconInfo">
                  <span className="iconInfo-platform"> Facebook </span>
                  <p className="mb-0">
                    <span className="text-success">Connected as</span>
                    {fbResponse?.name}
                  </p>
                </div>
              </div>
              <div className="connectCard-info">
                <button
                  type="button"
                  className="btn btn-link text-danger"
                  onClick={() => {
                    localStorage.removeItem(FB_SAVED_RESPONSE_KEY);
                    setIsFbConnected(false);
                  }}
                >
                  {" "}
                  Remove{" "}
                </button>
              </div>
            </div>
          ) : (
            <FacebookLogin
              appId={3592166627694494}
              state={{ config_id: "922691438804656" }}
              // autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              render={(renderProps: any) => (
                <div className="connectCard">
                  <div className="connectCard-icon">
                    <div className="iconBox fb">
                      <FbIcon />
                    </div>
                    <div className="iconInfo">
                      <span className="iconInfo-platform"> Facebook </span>
                    </div>
                  </div>
                  <div className="connectCard-info">
                    <button
                      type="button"
                      className="btn btn-info rounded-pill"
                      onClick={renderProps.onClick}
                    >
                      {" "}
                      Connect{" "}
                    </button>
                  </div>
                </div>
              )}
            />
          )}
          {/* connect card twitter*/}
          {isTwitterConnected ? (
            <div className="connectCard">
              <div className="connectCard-icon connected">
                <div className="profileBox fb">
                  <img
                    src={
                      twitterResponse?.profilePicture ??
                      require("../../../../img/avatar.png")
                    }
                    className="img-fluid"
                    alt=""
                  />

                  <div className="profileBox-logo">
                    <TwitterIcon />
                  </div>
                </div>
                <div className="iconInfo">
                  <span className="iconInfo-platform"> Twitter </span>
                  <p className="mb-0">
                    <span className="text-success">Connected as</span>{" "}
                    {twitterResponse?.name}
                  </p>
                </div>
              </div>
              <div className="connectCard-info">
                <button
                  type="button"
                  className="btn btn-link text-danger"
                  onClick={() => {
                    sessionStorage.removeItem(TWITTER_SAVED_RESPONSE_KEY);
                    twitterLogout();
                  }}
                >
                  {" "}
                  Remove{" "}
                </button>
              </div>
            </div>
          ) : (
            <div className="connectCard">
              <div className="connectCard-icon">
                <div className="iconBox twitter">
                  <TwitterIcon />
                </div>
                <div className="iconInfo">
                  <span className="iconInfo-platform"> Twitter </span>
                </div>
              </div>
              <div className="connectCard-info">
                <button
                  type="button"
                  className="btn btn-info rounded-pill"
                  onClick={async () => await twitterLogin()}
                >
                  {" "}
                  Connect{" "}
                </button>
              </div>
            </div>
          )}
          <button
            type="submit"
            className="btn btn-primary w-100 rounded-pill mb-2"
            disabled={!isFbConnected && !isTwitterConnected ? true : false}
          >
            Continue
          </button>
          <button
            type="submit"
            className="btn btn-link text-primary w-100 rounded-pill"
            disabled={isFbConnected || isTwitterConnected ? true : false}
          >
            Skip for Now
          </button>
        </div>
      </div>
    </div>
  );
}

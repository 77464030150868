import SideNavbar from "../../components/common/navbar/SideNavbar";
import { Link } from "react-router-dom";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import Email from "./socialMedia/Email";
import SocialMedia from "./socialMedia/SocialMedia";
import Confetti from "img/svg/confetti";

const Dashboard = () => {
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          {/* <div className="row">
                  <div className="col-md-12">
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                      <InfoIconWarning/> <span className="ms-2">Please verify your email to continue with all features</span> 
                      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                        <span className="icon-close"></span>
                      </button>
                    </div>
                  </div>
                    <div className="col-md-8">
                        <h2 className='mb-6'> Welcome <span className='welcome-text'> to Campaign Brain,</span>  Elenor !</h2>

                        <p> Stay on track to victory with tailored daily goals to achieve success in your race</p>
                        <p> Call voters instantly with advanced tools and algorithms to reach key voters efficiently.</p>
                        <p>Connect with potential donors using our AI-powered call time tool and donor optimization for effective fundraising.</p>
                        <p> Boost your campaign's social media and email efforts effortlessly with our generative AI tool, saving time and building support.</p>

                        <Link to="/candidate/add-voter" className="btn btn-primary rounded-pill mt-3 me-2"> Setup Account </Link>
                        <Link to="/candidate/add-voter" className="btn btn-primary rounded-pill mt-3" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> Congratulations </Link>
                       
                    </div>
                </div> */}

          {/* after setup */}
          <div className="row justify-content-center mt-3">
            <div className="col-lg-7">
              <h2 className="mb-6"> Welcome back, Elenor!</h2>

              <div className="card card-dailyUpdate shadow-2">
                <div className="card-dailyUpdate-icon">
                  <img src={require("../../img/alert.png")} width="94" alt="" />
                </div>
                <div className="card-dailyUpdate-content">
                  <h4 className="mb-4"> Daily Update </h4>
                  <p>
                    We’re <span className="text-primary">154 Days </span>from
                    Election Day. Here’s what I’m tracking today:
                  </p>

                  <ul className="list-unstyled">
                    <li>
                      {" "}
                      <span>
                        {" "}
                        Fundraising still a focus - 2 hours of call time today{" "}
                      </span>{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        Need to recruit additional volunteers for this weekend’s
                        canvass{" "}
                      </span>{" "}
                    </li>
                    <li>
                      {" "}
                      <span>
                        {" "}
                        Prepare first direct mail piece to go out next week{" "}
                      </span>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <section className="mt-5">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="card card-taskList shadow-2 active">
                  <h4> Today’s Task </h4>

                  <div className="card-taskList-action">
                    <p className="m-0">
                      {" "}
                      Start your day by completing the tasks
                    </p>
                    <button
                      className="btn btn-primary rounded-pill"
                      data-bs-toggle="modal"
                      data-bs-target="#getStarted"
                    >
                      {" "}
                      Get started
                    </button>
                  </div>

                  <div className="card-taskList-list">
                    {/* progress bar */}
                    <div
                      className="progress mt-3"
                      role="progressbar"
                      aria-label="task progress"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ height: 4 + "px" }}
                    >
                      <div
                        className="progress-bar"
                        style={{ width: " 25%" }}
                      ></div>
                    </div>

                    {/* task list */}
                    <ul className="list-unstyled">
                      <li className="taskList-item success">
                        <div className="taskList-item-content">
                          <div className="taskList-item-content-indicator"></div>
                          <div className="taskList-item-content-title">
                            Approvals on different activities
                          </div>
                        </div>
                        <div className="taskList-item-count">
                          <span>{3}</span>/<span>{3}</span>
                        </div>
                      </li>
                      <li className="taskList-item">
                        <div className="taskList-item-content">
                          <div className="taskList-item-content-indicator"></div>
                          <div className="taskList-item-content-title">
                            Call voters now
                          </div>
                        </div>
                        <div className="taskList-item-count">
                          <span>{1}</span>/<span>{5}</span>
                        </div>
                      </li>
                      <li className="taskList-item">
                        <div className="taskList-item-content">
                          <div className="taskList-item-content-indicator"></div>
                          <div className="taskList-item-content-title">
                            Visit voters door to door
                          </div>
                        </div>
                        <div className="taskList-item-count">
                          <span>{0}</span>/<span>{5}</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  {/* if no task list */}
                  <div className="noData d-flex gap-2 flex-row align-items-center">
                    <InfoIconSecondary />{" "}
                    <p className="m-0"> No tasks available for today </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>

      {/* Congratulations Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <Confetti />
              <h2 className="mt-3"> Congratulations!!! </h2>
              <p>
                {" "}
                Campaign Brain is now equipped to provide a personalized
                campaign strategy to guide you through Election Day.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Get Started: Social media post Modal */}
      <div
        className="modal fade"
        id="getStarted"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="taskModal">
              {/* left part */}
              <div className="taskModal-sidenav hide">
                <div className="modal-header">
                  <h5 className="modal-title"> Today’s Task </h5>
                  {/* <button className="btn btn-xs"> <span className="icon-document"> </span></button> */}
                </div>
                <div className="modal-body">
                  {/* tab links */}
                  <ul className="nav flex-column" id="myTab" role="tablist">
                    <li
                      className="nav-item taskList-item success p-0"
                      role="presentation"
                    >
                      <button
                        className="nav-link w-100 active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        <span className="taskList-item-content-indicator"></span>
                        <span className="taskList-item-content-title">
                          {" "}
                          Approvals on different posts{" "}
                        </span>
                      </button>
                    </li>
                    <li
                      className="nav-item taskList-item p-0"
                      role="presentation"
                    >
                      <button
                        className="nav-link w-100"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                      >
                        <span className="taskList-item-content-indicator"></span>
                        <span className="taskList-item-content-title">
                          {" "}
                          Contact voters by phone{" "}
                        </span>
                      </button>
                    </li>
                    <li
                      className="nav-item taskList-item p-0"
                      role="presentation"
                    >
                      <button
                        className="nav-link w-100"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="contact-tab-pane"
                        aria-selected="false"
                      >
                        <span className="taskList-item-content-indicator"></span>
                        <span className="taskList-item-content-title">
                          {" "}
                          Talk to voter on Door to Door{" "}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              {/* right part */}
              <div className="taskModal-content">
                <div className="modal-header">
                  <div className="task-controls text-center">
                    <h5 className="modal-title">
                      Approvals on different posts
                    </h5>
                    <div className="task-staus">1 of 5 approvals</div>
                  </div>

                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="icon-close text-n1 h2"></span>
                  </button>
                </div>
                <div className="modal-body px-4">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabIndex={0}
                    >
                      <div className="postSlider-wrapper">
                        <SocialMedia />
                        {/* <Email /> */}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabIndex={0}
                    >
                      <div className="centeredXY">
                        <div className="card contactCard text-center mt-4">
                          <div className="contactCard-header">
                            <div className="iconBox bg-neutral-n4">
                              <img
                                src={require("../../img/patriotism.png")}
                                className="img-fluid"
                                width={32}
                                height={32}
                                alt=""
                              />
                            </div>
                            <h4 className="mb-1"> Contact Voters </h4>
                            <p className="m-0">
                              {" "}
                              Continue with calling key voters{" "}
                            </p>
                          </div>
                          <div className="contactCard-body">
                            <ul className="list-unstyled">
                              <li className="contactOption-item">
                                <Link to="">
                                  <div className="contactOption-item-content">
                                    <div className="contactOption-item-content-title">
                                      By Phone
                                      <p className="m-0">
                                        Contact voter by Phone
                                      </p>
                                    </div>
                                  </div>
                                  <div className="contactOption-item-link">
                                    <span className="icon-arrow-right"></span>
                                  </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer justify-content-end px-0 pt-4">
                        <div className="next-btn">
                          <button className="btn btn-link">Skip post</button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="contact-tab-pane"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                      tabIndex={0}
                    >
                      <div className="centeredXY">
                        <div className="card contactCard text-center mt-4">
                          <div className="contactCard-header">
                            <div className="iconBox bg-neutral-n4">
                              <img
                                src={require("../../img/patriotism.png")}
                                className="img-fluid"
                                width={32}
                                height={32}
                                alt=""
                              />
                            </div>
                            <h4 className="mb-1"> Contact Voters </h4>
                            <p className="m-0">
                              Continue with calling key voters
                            </p>
                          </div>
                          <div className="contactCard-body">
                            <ul className="list-unstyled">
                              <li className="contactOption-item">
                                <Link to="">
                                  <div className="contactOption-item-content">
                                    <div className="contactOption-item-content-title">
                                      By Phone
                                      <p className="m-0">
                                        Contact voter by Phone
                                      </p>
                                    </div>
                                  </div>
                                  <div className="contactOption-item-link">
                                    <span className="icon-arrow-right"></span>
                                  </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer justify-content-end px-0 pt-4">
                        <div className="next-btn">
                          <button className="btn btn-link">Skip post</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="status-btn">
                    {/* approved */}
                    {/* <button className="btn bg-success-s2 text-s1 rounded-pill me-3" disabled> <span className="icon-tick"></span> Approved </button>  */}

                    {/* rejected */}
                    <button
                      className="btn btn-reject rounded-pill me-3"
                      disabled
                    >
                      {" "}
                      <span className="icon-close"></span> Post Rejected{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React from "react";
import SingleSelectDd from "templates/components/SingleSelectDd";
import { bool } from "yup";

interface ITurfDetailProp {
  isFullscreen: boolean;
}

const EditTurf = ({ isFullscreen }: ITurfDetailProp) => {
  return (
    <div
      className={isFullscreen ? `editTurfWrapper h-auto` : `editTurfWrapper`}
    >
      <form>
        <div className="card shadow-6">
          <div className="card-header">
            <span className="body-2">Edit Turf</span>
          </div>
          <div className="card-body scroll-y">
            <div className="mb-3">
              <span className="body-4">Turf Details</span>
              <div>
                <span className="fs-7 text-muted"> Total Voters: 95 </span>
                <span className="dot dot-md bg-neutral-n3 mx-1"> </span>
                <span className="fs-7 text-muted"> Total Doors: 67 </span>
              </div>
            </div>
            <div className="mb-3 required">
              <label htmlFor="turd=fInputName1" className="form-label">
                Turf name
              </label>
              <input
                type="text"
                className="form-control"
                id="turd=fInputName1"
                aria-describedby="nameHelp"
                placeholder="Enter turf name"
              />
            </div>

            <div className="mb-3 required">
              <label htmlFor="turdAssign" className="form-label">
              Assign to
              </label>
              <SingleSelectDd placeholder="Choose assignee" />
            </div>
          </div>
          <div className="card-footer">
            <button className="btn btn-link btn-xs rounded-pill me-2">
              {" "}
              Cancel{" "}
            </button>
            <button className="btn btn-primary btn-xs rounded-pill">
              {" "}
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditTurf;

import TrashIcon from "img/svg/TrashIcon";
import {
  dateFormatter,
  formatFileSize,
  useProfessionalDetails,
} from "./useProfessionalDetils";
import FileIcon from "img/svg/fileIcon";
import CheckSuccess from "img/svg/checkSuccess";

interface IUploadFile {
  editable: boolean;
  handleFileChange: React.ChangeEventHandler<HTMLInputElement>;
  uploadedFile: File | null | undefined | any;
  isValidFileType: any;
  setUploadedFile: any;
  setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  setStoreToDeletefile: React.Dispatch<any>;
}

const UploadFile = ({
  editable,
  handleFileChange,
  uploadedFile,
  isValidFileType,
  setUploadedFile,
  setIsDeleted,
  setStoreToDeletefile,
}: IUploadFile) => {
  const { resumeFileData, DeleteFile, fileSizeLimit } =
    useProfessionalDetails();

  const handleDeleteButton = () => {
    setStoreToDeletefile(uploadedFile);
    setIsDeleted(true);
    setUploadedFile(null || undefined);
  };

  return (
    <>
      {uploadedFile ? (
        <div className="uploadedCard mb-0 p-0 border-0">
          <div className="uploadedCard-icon">
            <div className="iconBox">
              <FileIcon />
            </div>
            <>
              {uploadedFile.size > fileSizeLimit ? (
                <div className="iconInfo">
                  <span className="iconInfo-title"> {uploadedFile.name}</span>
                  <p className="iconInfo-desc text-danger">
                    {" "}
                    "File Limit Exceeded 2 MB"
                  </p>
                </div>
              ) : uploadedFile?.name && !isValidFileType(uploadedFile) ? (
                <div className="iconInfo">
                  <span className="iconInfo-title"> {uploadedFile.name}</span>
                  <p className="iconInfo-desc text-danger">
                    Error: Invalid file format
                  </p>
                </div>
              ) : (
                <div className="iconInfo">
                  <span className="iconInfo-title">
                    {" "}
                    {uploadedFile.name ?? resumeFileData[0]?.fileName}
                  </span>
                  <p className="iconInfo-desc">
                    {" "}
                    {formatFileSize(
                      uploadedFile?.size ?? resumeFileData[0]?.size
                    )}{" "}
                    {dateFormatter(
                      uploadedFile?.lastModified ?? resumeFileData[0]?.updatedAt
                    )}
                  </p>
                  {/* {resumeUploadStatus === "pending" ? (
                    <>Loading...</>
                  ) : (
                    resumeUploadStatus === "success" && (
                      <span className="iconInfo-status">
                        <CheckSuccess /> File uploaded
                      </span>
                    )
                  )} */}
                </div>
              )}
            </>
          </div>

          {editable && (
            <div className="uploadedCard-info">
              <div className="file-btn">
                <button className="btn btn-link text-primary">
                  {" "}
                  Change File
                  <input type="file" name="file" onChange={handleFileChange} />
                </button>
              </div>

              <button className="btn btn-xs" onClick={handleDeleteButton}>
                <TrashIcon />
              </button>
            </div>
          )}
        </div>
      ) : (
        <>
          {editable ? (
            <>
              {/* if file has been removed */}
              <div className="uploadedCard mb-0 p-0 border-0">
                <div className="uploadedCard-icon">
                  <div className="iconInfo">
                    <span className="iconInfo-title text-muted">
                      pdf, or docs{" "}
                      <span className="dot bg-neutral-n3 ms-1"> </span> 2MB Max
                    </span>
                  </div>
                </div>
                <div className="uploadedCard-info file-btn">
                  <button className="btn btn-info rounded-pill">
                    {" "}
                    Upload File
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                    />
                  </button>
                </div>
              </div>
            </>
          ) : (
            <span className="body-4 text-muted"> Not Provided</span>
          )}
        </>
      )}
    </>
  );
};

export default UploadFile;

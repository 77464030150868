import { useAuth0 } from "@auth0/auth0-react";
import CustomTooltip from "components/common/CustomTooltip";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "components/common/Loader";
import CalendarOutlineSmallIcon from "img/svg/CalendarOutlineSmallIcon";
import SocialMediaModal from "./SocialMediaModal";
import { useTopPrioritiesData } from "./useTopPrioritiesData";
import { formattedDate } from "utils/formatDate";
import CandidateAddedTaskForm from "./CandidateAddedTaskForm";
import CalendarOutlineDangerIcon from "img/svg/CalendarOutlineDangerIcon";
import EditIconSmall from "img/svg/EditIconSmall";
import TrashIconSmall from "img/svg/TrashIconSmall";
import CheckRectSmallIcon from "img/svg/CheckRectSmallIcon";
import TopPrioritiesSkeleton from "./TopPrioritiesSkeleton";

const TopPriorities = ({
  daysUntilElection,
}: {
  daysUntilElection: number;
}) => {
  const {
    prerequisitesTasks,
    persistingTasks,
    isTopprioritiesFlagsLoading,
    setDaysUntilElection,
    systemDefinedAndManuallyAddedTask,
    updateSystemAndManualTask,
    postSystemAndManualTask,
    setIsEditable,
    isEditable,
    setCandidateTaskName,
    candidateTaskName,
    setCandidateTaskDueDate,
    deleteManualTask,
    editedTaskId,
    setEditedTaskId,
  } = useTopPrioritiesData();

  const handlePriorityClick = () => {
    setCandidateTaskName("");
    setIsEditable(true);
  };
  const { user } = useAuth0();
  const avatarImg = require("../../../../img/avatar.png");
  const deleteWarninfImg = require("../../../../img/warning.png");

  useEffect(() => {
    setDaysUntilElection(daysUntilElection);
  }, [daysUntilElection]);

  const [isCandidateTasksChecked, setIsCandidateTasksChecked] = useState<
    boolean[] | undefined
  >();
  const [isSystemTasksChecked, setIsSystemTasksChecked] = useState<
    boolean[] | undefined
  >();

  useEffect(() => {
    const manualTaskStatus =
      systemDefinedAndManuallyAddedTask?.candidateAdded.map((task) =>
        task.status === "Completed" ? true : false
      );
    setIsCandidateTasksChecked(manualTaskStatus);
  }, [systemDefinedAndManuallyAddedTask]);

  useEffect(() => {
    const systemTaskStatus =
      systemDefinedAndManuallyAddedTask?.systemDefined.map((task) =>
        task.status === "Completed" ? true : false
      );
    setIsSystemTasksChecked(systemTaskStatus);
  }, [systemDefinedAndManuallyAddedTask]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <section className="mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-12 mb-5">
          <div className="card card-taskList shadow-2 active p-0">
            <div className="card-taskList-header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <img
                  src={user?.picture ?? avatarImg}
                  style={{ width: "32px", height: "32px" }}
                  className="avatar border-0 img-fluid rounded-circle me-2 mb-0"
                  alt="User Avatar"
                />
                <h4 className="mb-0"> Top Priorities </h4>
              </div>
            </div>

            {isTopprioritiesFlagsLoading ? (
              <TopPrioritiesSkeleton />
            ) : (
              <div className="card-taskList-body card-taskList-list">
                {/* tasks list */}
                <ul className="list-unstyled">
                  {/* prerequisites tasks */}
                  {prerequisitesTasks?.map(
                    (prerequisiteTask, prerequsiteIndex) =>
                      !prerequisiteTask.isCompleted && (
                        <li className="taskList-item" key={prerequsiteIndex}>
                          <div className="taskList-item-content">
                            <div className="taskList-item-content-indicator"></div>
                            <Link
                              to={prerequisiteTask.redirectUrl}
                              className="d-flex justify-content-between w-100"
                            >
                              <div>
                                <span className="taskList-item-content-title">
                                  {prerequisiteTask.title}
                                </span>
                                <span className="d-block text-muted fs-8 font-regular">
                                  {prerequisiteTask.description}
                                </span>
                              </div>
                              <div className="taskList-item-redirect">
                                <span className="icon-arrow-right"></span>
                              </div>
                            </Link>
                          </div>
                        </li>
                      )
                  )}
                  {/* Persisiting tasks */}
                  {persistingTasks?.map(
                    (persistingTask, persisitingTaskIndex) => (
                      <li className="taskList-item" key={persisitingTaskIndex}>
                        <div className="taskList-item-content">
                          <div className="taskList-item-content-indicator"></div>
                          <Link
                            to={persistingTask.redirectUrl ?? ""}
                            className="d-flex justify-content-between w-100"
                            data-bs-toggle={
                              persistingTask.isModal &&
                              //   todaysPosts?.length &&
                              "modal"
                            }
                            data-bs-target={
                              persistingTask.isModal &&
                              //   todaysPosts?.length &&
                              "#getStarted"
                            }
                          >
                            <div>
                              <span className="taskList-item-content-title">
                                {persistingTask.title}
                              </span>
                              <span className="d-block text-muted fs-8 font-regular d-flex align-items-center gap-1">
                                <CheckRectSmallIcon />
                                {persistingTask.description}
                              </span>
                            </div>
                            <div className="taskList-item-redirect">
                              <span className="icon-arrow-right"></span>
                            </div>
                          </Link>
                        </div>
                      </li>
                    )
                  )}
                  {/*  System  defined tasks */}
                  {systemDefinedAndManuallyAddedTask?.systemDefined?.map(
                    (systemDefinedTask, systemDefinedTaskIndex) => (
                      <li
                        className={`taskList-item ${
                          isSystemTasksChecked &&
                          isSystemTasksChecked[systemDefinedTaskIndex]
                            ? "success"
                            : ""
                        }`}
                        key={systemDefinedTaskIndex}
                      >
                        <div className="taskList-item-content">
                          <CustomTooltip
                            content={
                              isSystemTasksChecked &&
                              isSystemTasksChecked[systemDefinedTaskIndex]
                                ? "Completed"
                                : "Mark as complete"
                            }
                          >
                            <div
                              className="taskList-item-content-indicator"
                              onClick={() => {
                                const temp = isSystemTasksChecked;
                                if (temp) {
                                  temp[systemDefinedTaskIndex] = true;
                                  setIsSystemTasksChecked(temp);
                                }
                                postSystemAndManualTask({
                                  name: systemDefinedTask?.name,
                                  dueDate: systemDefinedTask?.dueDate,
                                  status: "Completed",
                                  priorityId: String(
                                    systemDefinedTask?.priorityId
                                  ),
                                });
                              }}
                            ></div>
                          </CustomTooltip>
                          <Link
                            to={systemDefinedTask.redirectsTo ?? ""}
                            state={
                              systemDefinedTask.redirectsTo === "/dashboard"
                                ? {
                                    isTopPriorities: true,
                                    question:
                                      systemDefinedTask.question ??
                                      systemDefinedTask.name,
                                  }
                                : undefined
                            }
                            className="d-flex justify-content-between w-100"
                          >
                            <div>
                              <span className="taskList-item-content-title">
                                {systemDefinedTask.name}
                              </span>
                              {today >= new Date(systemDefinedTask.dueDate) ? (
                                <span className="d-block text-danger-d2 fs-8 font-regular d-flex align-items-center gap-1">
                                  <CalendarOutlineDangerIcon /> Expired on{" "}
                                  {formattedDate(systemDefinedTask.dueDate)}
                                </span>
                              ) : (
                                <span className="d-block text-muted fs-8 font-regular d-flex align-items-center gap-1">
                                  <CalendarOutlineSmallIcon />
                                  Complete by{" "}
                                  {formattedDate(systemDefinedTask.dueDate)}
                                </span>
                              )}
                            </div>
                            {systemDefinedTask.redirectsTo && (
                              <div className="taskList-item-redirect">
                                <span className="icon-arrow-right"></span>
                              </div>
                            )}
                          </Link>
                        </div>
                      </li>
                    )
                  )}

                  {/*  Candidate  added tasks */}
                  {systemDefinedAndManuallyAddedTask?.candidateAdded?.map(
                    (candidateAddedTask, candidateAddedTaskIndex) =>
                      editedTaskId === candidateAddedTask?.id && isEditable ? (
                        <CandidateAddedTaskForm />
                      ) : (
                        <li
                          className={`taskList-item ${
                            isCandidateTasksChecked &&
                            isCandidateTasksChecked[candidateAddedTaskIndex]
                              ? "success"
                              : ""
                          }`}
                          key={candidateAddedTaskIndex}
                        >
                          <div className="taskList-item-content">
                            <CustomTooltip
                              content={
                                isCandidateTasksChecked &&
                                isCandidateTasksChecked[candidateAddedTaskIndex]
                                  ? "Completed"
                                  : "Mark as complete"
                              }
                            >
                              <div
                                className="taskList-item-content-indicator"
                                onClick={() => {
                                  const temp = isCandidateTasksChecked;
                                  if (temp) {
                                    temp[candidateAddedTaskIndex] = true;
                                    setIsCandidateTasksChecked(temp);
                                  }
                                  updateSystemAndManualTask({
                                    id: String(candidateAddedTask?.id),
                                    status: "Completed",
                                  });
                                }}
                              ></div>
                            </CustomTooltip>
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <span className="taskList-item-content-title">
                                  {candidateAddedTask.name}
                                </span>
                                {today >=
                                new Date(candidateAddedTask.dueDate) ? (
                                  <span className="d-block text-danger-d2 fs-8 font-regular d-flex align-items-center gap-1">
                                    <CalendarOutlineDangerIcon /> Expired on{" "}
                                    {formattedDate(candidateAddedTask.dueDate)}
                                  </span>
                                ) : (
                                  <span className="d-block text-muted fs-8 font-regular d-flex align-items-center gap-1">
                                    <CalendarOutlineSmallIcon />
                                    Complete by{" "}
                                    {formattedDate(candidateAddedTask.dueDate)}
                                  </span>
                                )}
                              </div>

                              <div className="taskList-item-action">
                                <button
                                  className="btn btn-link no-hover px-0 py-0"
                                  onClick={() => {
                                    setIsEditable(true);
                                    setCandidateTaskName(
                                      candidateAddedTask?.name
                                    );
                                    setCandidateTaskDueDate(
                                      candidateAddedTask?.dueDate
                                    );
                                    setEditedTaskId(candidateAddedTask?.id);
                                  }}
                                >
                                  <EditIconSmall />{" "}
                                </button>
                                <button
                                  className="btn btn-link no-hover px-0 py-0"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                  onClick={() => {
                                    setEditedTaskId(candidateAddedTask?.id);
                                  }}
                                >
                                  <TrashIconSmall />{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                  )}
                </ul>
                {isEditable && editedTaskId === undefined && (
                  <CandidateAddedTaskForm />
                )}
                {/* Add priority */}
                {!isEditable && (
                  <button
                    className="btn btn-link no-hover btn-xs d-flex align-items-center gap-2"
                    onClick={() => {
                      handlePriorityClick();
                      setEditedTaskId(undefined);
                    }}
                  >
                    <i className="icon-plus"> </i>Add priority
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <SocialMediaModal />

      {/* candidate added task delete warning Modal  */}
      <div
        className="modal fade"
        id="deleteModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={deleteWarninfImg}
                width={66}
                height={56}
                alt="delete warning"
              />
              <h2 className="mt-3">Do you want to delete the priority? </h2>
              <p>
                By deleting, it will be removed from the list of my priorities.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link rounded-pill btn-xs"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button
                className="btn btn-primary rounded-pill btn-xs"
                data-bs-dismiss="modal"
                onClick={() => editedTaskId && deleteManualTask(editedTaskId)}
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopPriorities;

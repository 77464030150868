import React from "react";
import { Tooltip } from "bootstrap";
import { useEffect } from "react";
import ProgressAnalytics from "./ProgressAnalytics";
import SuccessRoadMap from "./success-roadmap";

const Analytics: React.FC = () => {
  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <div className="d-flex align-items-stretch">
      <div className="wrapper active-cont">
        <main>
          <ProgressAnalytics />

          <SuccessRoadMap />
        </main>
      </div>
    </div>
  );
};

export default Analytics;

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "index";
import { useEffect, useRef, useState } from "react";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";

export default function useSecurityDetails() {
  const authAxios = CreateAuthAxiosInstance();
  const editPasswordModalRef = useRef<HTMLButtonElement | null>(null);

  async function updatePassword(data: any) {
    const response = await authAxios.post(
      `api/v1/profile-setting/change-password`,
      data
    );
    return response.data;
  }

  async function logOutFromAllDevices() {
    const response = await authAxios.post(
      `api/v1/profile-setting/logout-devices`
    );
    return response.data;
  }

  const mutationPromise = useMutation({
    mutationFn: updatePassword,
  });

  return {
    mutationPromise,
    editPasswordModalRef,
  };
}

import InfoIconSecondaryOutline from "img/svg/InfoIconSecondaryOutline"

interface Props {
  onClickModal: ()=> void
}

const VerifyNumber = ( {onClickModal} : Props ) => {
  
  return (
    <div className="d-felx align-items-center bg-white shadow-2 px-2 py-1 rounded-1">
       <InfoIconSecondaryOutline /> <span className="body-4 mb-0"> Phone no. unverified. </span> 
        <button className="btn btn-link no-hover btn-xs px-1 py-0" onClick={onClickModal}> Verify now</button>
    </div>
  )
}

export default VerifyNumber

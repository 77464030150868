import { useQuery } from "@tanstack/react-query";
import { ELECTION_ID } from "components/pages/dashboard/Dashboard";
import CreateAuthAxiosInstance from "utils/authAxios";
import { globalQueryConfig } from "utils/reactQuery";
import toastNotify from "utils/toastNotify";
import { CANDIDATE_ID } from "../Homepage";

const useProfileDetails = () => {
  const authAxios = CreateAuthAxiosInstance();

  async function getUserInfo() {
    const userId = localStorage.getItem(CANDIDATE_ID);

    try {
      const response = await authAxios.get(`/api/v1/user/info/${userId}`);
      return response.data;
    } catch (error) {
      console.log(error);
      toastNotify("error", "Error fetching data");
    }
  }

  const { data } = useQuery({
    queryKey: ["userInfo"],
    queryFn: getUserInfo,
    ...globalQueryConfig,
  });

  const isVerifiedPhoneNumber =
    data?.phoneNumber?.verificationStatus === "verified" ? true : false;

  return {
    isVerifiedPhoneNumber,
  };
};

export default useProfileDetails;

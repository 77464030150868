import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useConversationalAI } from "./useConversationalAi";

const PROMPTLIST = [
  {
    id: 0,
    prompt: "Write me a 5 minute speech to give at a fundraising event?",
  },
  {
    id: 1,
    prompt:
      "Create content to fill my website About Me section and expand on my top policy positions for the Policy section of the website?",
  },
  {
    id: 2,
    prompt:
      "Write me a fundraising email related to the voter registration deadline next week?",
  },
  {
    id: 3,
    prompt:
      "Draft an OpEd that highlights how important it is for women to have bodily autonomy that I can publish in the local newspaper?",
  },
  {
    id: 4,
    prompt: "What is a vote goal and how do I calculate it?",
  },
  {
    id: 5,
    prompt: "What is a good place to hold a campaign launch event?",
  },
  {
    id: 6,
    prompt: "How can I get better at Call Time?",
  },
  {
    id: 7,
    prompt: "Where should I find donor prospects from?",
  },
  {
    id: 8,
    prompt: "I can only hire one full time staffer. What should their role be?",
  },
  {
    id: 9,
    prompt: "How do I get more earned media?",
  },
];

interface IPromptsState {
  id: number;
  prompt: string;
}

const PromptSuggestions = () => {
  const [todayPrompts, setTodayPrompts] = useState<IPromptsState[]>([]);
  const { setQuery, chatDivRef, isModalOpen } = useConversationalAI();
  useEffect(() => {
    const getDailyPrompts = () => {
      const date = new Date();
      let day = date.getDate();
      const promptsPerDay = 3;
      const totalPrompts = PROMPTLIST.length;

      let startIndex = (day - 1) * promptsPerDay;
      startIndex = startIndex % totalPrompts;

      const endIndex = startIndex + promptsPerDay;
      let selectedPrompts = [];

      if (endIndex <= totalPrompts) {
        // If there are enough prompts left for the day
        selectedPrompts = PROMPTLIST.slice(startIndex, endIndex);
      } else {
        // If the end index exceeds the total prompts
        selectedPrompts = [
          ...PROMPTLIST.slice(startIndex),
          ...PROMPTLIST.slice(0, endIndex - totalPrompts), // endIndex - totalPrompts = back to first index
        ];
      }

      setTodayPrompts(selectedPrompts);
    };
    getDailyPrompts();
  }, []);

  const handlePromptClick = (data: string) => {
    setQuery(data);
    //open the modal with suggested prompt
    if (!isModalOpen && chatDivRef?.current) {
      chatDivRef?.current?.click();
    }
  };

  return (
    <div className="d-flex h-100 ">
      <ul className="list-unstyled suggestion-box align-self-end mb-0">
        {todayPrompts.map((todayPrompt) => {
          const { id, prompt } = todayPrompt;
          return (
            <li
              className="suggestion-box-item"
              key={id}
              onClick={() => handlePromptClick(prompt)}
            >
              <Link to="">{prompt}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PromptSuggestions;

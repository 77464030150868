import React from 'react'

const CircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="7.5" stroke="#3B3B4F"/>
    </svg>
  )
}

export default CircleIcon

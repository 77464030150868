import { useInfiniteQuery } from '@tanstack/react-query';
import NetworkError from 'components/common/NetworkError';
import InfoIconSecondary from 'img/svg/InfoIconSecondary';
import InfoIconWarning from 'img/svg/InfoIconWarning';
import React, { useEffect, useRef, useState } from 'react'
import CreateAuthAxiosInstance from 'utils/authAxios';

export interface IVolunteerBasic {
  id: number
  fullName: string | null
  email: string | null
  primaryPhoneNumber: string | null
}

export interface IVolunteer extends IVolunteerBasic {
  candidateId: number | null
  isArchived: boolean
  createdAt: string | null
  updatedAt: string | null
  auth0Id: string | null
  status: string | null
  lastActiveAt: string | null
  invitedAt: string | null
}

export const useVolunteerListHook = () => {
  const authAxios = CreateAuthAxiosInstance();

  const [volunteerList, setVolunteerList] = useState<IVolunteer[] | null>(null)
  const [selectedVolunteerIdForDelete, setSelectedVolunteerIdForDelete] = useState<number | null>(null)
  const [selectedVolunteerForUpdation, setSelectedVolunteerForUpdation] = useState<IVolunteerBasic | null>(null)
  const [selectedVolunteerForDetailView, setSelectedVolunteerForDetailView] = useState<IVolunteer | null>(null)
  const [searchText, setSearchText] = useState<string>('')

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const targetItemRef = useRef<HTMLTableRowElement>(null)
  const prevTextCountRef = useRef<number>(0)

  const {
    data: volunteerDataInfinite,
    error: volunteerErrorInfinite,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status: volunteerDataStatusInfinite,
    refetch
  } = useInfiniteQuery({
    queryKey: ['volunteerList'],
    queryFn: ({ pageParam }) => authAxios.get(`api/v1/volunteers?page=${pageParam}${searchText?.length > 2 ? `&fullName=${searchText}` : ''}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      const currentPage = lastPage?.data?.currentPage
      const totalPages = lastPage?.data?.totalPages
      const returnPage = currentPage < totalPages ? currentPage + 1 : undefined

      return returnPage
    },
    retry: false
  })

  //Initial data population
  useEffect(() => {
    handleVolunteerListInfinite()
  }, [volunteerDataInfinite])

  //Debounce for search
  useEffect(() => {
    const currentTextLength = searchText.length
    const prevTextLength = prevTextCountRef.current
    const isTextDeleted = prevTextLength > currentTextLength

    if (isTextDeleted && currentTextLength === 0) {
      refetch()
      return
    }

    const refetchTimer = setTimeout(() => {
      if (currentTextLength > 2 || (isTextDeleted && prevTextLength > 2)) {
        refetch()
      }
      prevTextCountRef.current = currentTextLength
    }, 500)

    return () => clearTimeout(refetchTimer)
  }, [searchText])

  //Intersection observer for inifinite scroll
  useEffect(() => {
    const options = {
      root: tableContainerRef.current,
      rootMargin: "0px",
      threshold: .75,
    };

    const observer = new IntersectionObserver(
      (entries, observer) => {
        if (entries?.[0]?.isIntersecting && !isFetching && hasNextPage) fetchNextPage()
      },
      options
    );

    if (targetItemRef.current) observer.observe(targetItemRef.current)

    return () => observer.disconnect()
  })

  function handleVolunteerListInfinite() {
    if (!volunteerDataInfinite?.pages?.length) return

    const tempVolunteerList = []
    for (let obj of volunteerDataInfinite?.pages) {
      const tempItemArray = obj?.data?.items
      if (tempItemArray?.length) {
        tempVolunteerList.push(...tempItemArray)
      }
    }
    setVolunteerList(tempVolunteerList)
  }

  function handleSearchTextOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      typeof e?.target?.value !== 'string' ||
      (searchText.length === 0 && e.target.value.trim().length === 0)
    ) return

    if (selectedVolunteerForDetailView) setSelectedVolunteerForDetailView(null)

    setSearchText(e.target.value)
  }

  function NetworkErrorStatus() {
    return (
      <tr>
        <td colSpan={7} className="border-0">
          <div className="d-flex justify-content-center gap-2 flex-row align-items-center mt-5">
            <NetworkError TryAgainHandler={refetch} />
          </div>
        </td>
      </tr>
    )
  }


  return {
    formatDate,
    volunteerDataInfinite,
    volunteerErrorInfinite,
    volunteerList,
    selectedVolunteerIdForDelete,
    setSelectedVolunteerIdForDelete,
    selectedVolunteerForUpdation,
    setSelectedVolunteerForUpdation,
    selectedVolunteerForDetailView,
    setSelectedVolunteerForDetailView,
    searchText,
    tableContainerRef,
    targetItemRef,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    volunteerDataStatusInfinite,
    handleSearchTextOnChange,
    AlertToInviteVolunteer,
    NoDataStatus,
    NetworkErrorStatus,
    LoadingAnimation
  }
}

function AlertToInviteVolunteer() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <InfoIconWarning />
          <span className="ms-2">Invite to add volunteers </span>
        </div>
      </div>
    </div>
  )
}

function NoDataStatus() {
  return (
    <tr>
      <td colSpan={7} className="border-0">
        <div className="noData d-flex gap-2 flex-row align-items-center">
          <InfoIconSecondary />
          <p className="m-0 text-muted fs-7">
            No data available
          </p>
        </div>
      </td>
    </tr>
  )
}



function LoadingAnimation() {
  return (
    <tr>
      <td colSpan={7} className="border-0">
        <div className="loader-demo-box">
          <div className="jumping-dots-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </td>
    </tr>
  )
}

export function formatDate(inputDate: string | null | undefined) {
  if (!inputDate) return

  const date = new Date(inputDate);

  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear()

  return `${month}/${day}/${year}`;
}
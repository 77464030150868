import { QueryObserverResult, RefetchOptions, useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { ChangeEvent, useState } from "react";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";

interface IAddBudgetRow {
  categoryId: number
  colSpanNumber: number
  setIsAddingNewSubCategory: React.Dispatch<React.SetStateAction<boolean>>
  refetchCategoryData: (options?: RefetchOptions) => Promise<QueryObserverResult<any, Error>>
}

const AddBudgetRow = ({
  categoryId,
  colSpanNumber,
  setIsAddingNewSubCategory,
  refetchCategoryData
}: IAddBudgetRow) => {
  const authAxios = CreateAuthAxiosInstance()

  const [inputValue, setInputValue] = useState<string>('');

  const {
    mutate: addNewSubCategory,
    isPending: isPendingAddingNewSubCategory,
  } = useMutation({
    mutationFn: (data: any) => authAxios.post(
      `api/v1/budgets/categories/subcategories`,
      data
    )
      .then((data) => data?.data),
    onSuccess: handleOnSuccessEvent,
    onError: handleOnErrorEvent
  })

  function handleOnSuccessEvent() {
    setIsAddingNewSubCategory(false)
    toastNotify('success', 'Subcategory added successfully!')
    refetchCategoryData()
  }

  function handleOnErrorEvent(error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error("on error", error?.response?.data?.message)

      toastNotify("error", "Could add sub category!")
    }
  }

  const handleInputChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    const tempValue = e.target.value
    setInputValue(tempValue)
  };

  const handleKeyDownEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!(categoryId && inputValue?.trim()?.length)) return

      const finalPayLoad = {
        name: inputValue.trim(),
        categoryId: categoryId
      }
      addNewSubCategory(finalPayLoad)
    }

    if (e.key === 'Escape') handleBlurEvent()
  }

  const handleBlurEvent = () => setIsAddingNewSubCategory(false)

  return (
    <tr className="newRowBudget">
      <td colSpan={colSpanNumber}>
        <input
          type="text"
          className="form-control"
          value={inputValue}
          onChange={handleInputChangeEvent}
          onBlur={handleBlurEvent}
          onKeyDown={handleKeyDownEvent}
          autoFocus
        />
      </td>
    </tr>
  );
};

export default AddBudgetRow;

import { getTodayDate } from "utils/getDateTime";
import NewsSlider from "./NewsSlider";
import FilterIcon from "img/svg/fiIterIcon";
import { useEffect, useState } from "react";
import NewsFilterSidebar from "./news-filter-sidebar/NewsFilterSidebar";
import { TMultiValueSelectedOption } from "./news-filter-sidebar/TopIssuesMultiSelectDd";
import CreateAuthAxiosInstance from "utils/authAxios";

export interface INewsFilterData {
  localUrl?: string;
  localTags?: TMultiValueSelectedOption;
  stateTags?: TMultiValueSelectedOption;
  nationalTags?: TMultiValueSelectedOption;
}

const DailyNewsClips = () => {
  const authAxios = CreateAuthAxiosInstance();

  const [isFilterButtonClicked, setIsFilterButtonClicked] = useState(false);
  const [newsFilterData, setNewsFilterData] = useState<INewsFilterData>({});
  const [isApplyFiltersClicked, setIsApplyFiltersClicked] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isInitiallyApplyingFilterActive, setIsInitiallyApplyingFilterActive] =
    useState(true);

  const [isNewsFiltersApiCalled, setIsNewsFiltersApiCalled] = useState(false);

  useEffect(() => {
    const { localTags, localUrl, nationalTags, stateTags } = newsFilterData;

    if (
      isNewsFiltersApiCalled &&
      (isApplyFiltersClicked || isInitiallyApplyingFilterActive)
    ) {
      if (
        localUrl === undefined &&
        !localTags?.length &&
        !nationalTags?.length &&
        !stateTags?.length
      ) {
        setIsFilterActive(false);
      } else {
        setIsFilterActive(true);
      }
      setIsInitiallyApplyingFilterActive(false);
    }
  }, [newsFilterData, isApplyFiltersClicked, isNewsFiltersApiCalled]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await authAxios.get("/api/v1/news-filters");
        setNewsFilterData({
          localUrl: data?.localNewsSource || undefined,
          localTags: data?.localTags?.map((item: string) => ({
            value: item,
            label: item,
          })),
          nationalTags: data?.nationalTags?.map((item: string) => ({
            value: item,
            label: item,
          })),
          stateTags: data?.stateTags?.map((item: string) => ({
            value: item,
            label: item,
          })),
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsNewsFiltersApiCalled(true);
      }
    })();
  }, []);

  return (
    <>
      <div className="card shadow-2">
        {/* news filter sidebar*/}
        <NewsFilterSidebar
          isOpen={isFilterButtonClicked}
          setIsOpen={setIsFilterButtonClicked}
          newsFilterData={newsFilterData}
          setNewsFilterData={setNewsFilterData}
          isApplyFiltersClicked={isApplyFiltersClicked}
          setIsApplyFiltersClicked={setIsApplyFiltersClicked}
        />
        <div className="card-news-header d-flex justify-content-between">
          <div className="">
            <h5>Related News</h5>
            <span className="body-4 text-muted">{getTodayDate()}</span>
          </div>
          {/* news filter button*/}
          <div className="card-toolbox">
            <button
              className={`btn p-0 btn-filter ${isFilterActive && "active"}`}
              type="button"
              onClick={() => setIsFilterButtonClicked(true)}
            >
              <span>
                <FilterIcon />
              </span>
              <span className="dot dot-lg bg-success"></span>
            </button>
          </div>
        </div>
        {/* news slider*/}
        <div className="card-news-body scroll-y">
          {newsTypeList.map((newsTypeData, index) => (
            <NewsSlider
              newsTypeData={newsTypeData}
              newsFilterData={newsFilterData}
              isApplyFiltersClicked={isApplyFiltersClicked}
              setIsApplyFiltersClicked={setIsApplyFiltersClicked}
              setIsewsFilterSidebarOpen={setIsFilterButtonClicked}
              isNewsFiltersApiCalled={isNewsFiltersApiCalled}
              key={index}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default DailyNewsClips;

const newsTypeList = [
  {
    newsType: "Local level",
    variant: "success",
    newsKey: "local",
  },
  {
    newsType: "State level",
    variant: "warning",
    newsKey: "state",
  },
  {
    newsType: "National level",
    variant: "danger",
    newsKey: "national",
  },
] as const;

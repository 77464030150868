import React from 'react'

const InfoIconDangerX16 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M8.0026 14.6666C9.77071 14.6666 11.4664 13.9642 12.7166 12.714C13.9669 11.4637 14.6693 9.76803 14.6693 7.99992C14.6693 6.23181 13.9669 4.53612 12.7166 3.28587C11.4664 2.03563 9.77071 1.33325 8.0026 1.33325C6.23449 1.33325 4.5388 2.03563 3.28856 3.28587C2.03832 4.53612 1.33594 6.23181 1.33594 7.99992C1.33594 9.76803 2.03832 11.4637 3.28856 12.714C4.5388 13.9642 6.23449 14.6666 8.0026 14.6666ZM7.0026 10.9999C7.0026 10.7347 7.10796 10.4803 7.2955 10.2928C7.48303 10.1053 7.73739 9.99992 8.0026 9.99992C8.26782 9.99992 8.52217 10.1053 8.70971 10.2928C8.89725 10.4803 9.0026 10.7347 9.0026 10.9999C9.0026 11.2651 8.89725 11.5195 8.70971 11.707C8.52217 11.8946 8.26782 11.9999 8.0026 11.9999C7.73739 11.9999 7.48303 11.8946 7.2955 11.707C7.10796 11.5195 7.0026 11.2651 7.0026 10.9999ZM7.3466 4.54659C7.37444 4.39288 7.45536 4.25382 7.57525 4.15369C7.69515 4.05355 7.84639 3.9987 8.0026 3.9987C8.15881 3.9987 8.31006 4.05355 8.42996 4.15369C8.54985 4.25382 8.63077 4.39288 8.6586 4.54659L8.66927 4.66658V7.99992L8.6586 8.11992C8.63077 8.27363 8.54985 8.41268 8.42996 8.51282C8.31006 8.61295 8.15881 8.66781 8.0026 8.66781C7.84639 8.66781 7.69515 8.61295 7.57525 8.51282C7.45536 8.41268 7.37444 8.27363 7.3466 8.11992L7.33594 7.99992V4.66658L7.3466 4.54659Z" fill="#DE3715"/>
    </svg>
  )
}

export default InfoIconDangerX16

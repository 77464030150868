import React from 'react'

const DislikeFilledIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M18 9.50012C18 10.3286 17.3285 11.0001 16.5 11.0001C15.6716 11.0001 15 10.3286 15 9.50012V3.50012C15 2.6717 15.6716 2.00012 16.5 2.00012C17.3285 2.00012 18 2.6717 18 3.50012V9.50012Z" fill="#3B3B4F"/>
<path d="M14 9.66679V4.23619C14 3.47865 13.572 2.78612 12.8945 2.44734L12.8446 2.42241C12.2892 2.1447 11.6767 2.00012 11.0558 2.00012L5.63964 2.00012C4.68628 2.00012 3.86545 2.67304 3.67848 3.60789L2.47848 9.60789C2.23097 10.8455 3.17755 12.0001 4.43964 12.0001H8.00004V16.0001C8.00004 17.1047 8.89547 18.0001 10 18.0001C10.5523 18.0001 11 17.5524 11 17.0001V16.3335C11 15.468 11.2807 14.6258 11.8 13.9335L13.2 12.0668C13.7193 11.3744 14 10.5323 14 9.66679Z" fill="#3B3B4F"/>
</svg>
  )
}

export default DislikeFilledIcon

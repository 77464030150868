import React from "react";
import { IS_ACCOUNT_SETUP_STARTED } from "../Dashboard";

interface IStartAccountSetupProps {
  fullName: string;
  isAccountSetupStarted: boolean;
  setIsAccountSetupStarted: React.Dispatch<React.SetStateAction<boolean>>;
}

const StartAccountSetup: React.FC<IStartAccountSetupProps> = ({
  fullName,
  isAccountSetupStarted,
  setIsAccountSetupStarted,
}) => {
  return (
    <div className="col-md-8">
      <h2 className="mb-3">
        Welcome
        <span className="welcome-text"> to Campaign Brain, {fullName} ! </span>
      </h2>

      <p>
        Stay on track to victory with tailored daily goals to achieve success in
        your race.
      </p>
      <p>
        Call voters instantly with advanced tools and algorithms to reach key
        voters efficiently.
      </p>
      <p>
        Connect with potential donors using our AI-powered call time tool and
        donor optimization for effective fundraising.
      </p>
      <p>
        Boost your campaign's social media and email efforts effortlessly with
        our generative AI tool, saving time and building support.
      </p>

      <button
        className="btn btn-primary rounded-pill mt-3 me-2"
        onClick={() => {
          setIsAccountSetupStarted(true);
          localStorage.setItem(
            IS_ACCOUNT_SETUP_STARTED,
            String(isAccountSetupStarted)
          );
        }}
      >
        Setup Account
      </button>
    </div>
  );
};

export default StartAccountSetup;

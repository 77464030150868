import InfoIconSecondary from "img/svg/InfoIconSecondary";
import PhoneOutlineIcon from "img/svg/phoneOutlineIcon";
import { useMemo } from "react";
import { historyTooltip } from "utils/contactDonor";

const DonorDetails = ({ activeDonor }: any) => {
  const isDonationAmountAvailable = useMemo(() => {
    if (!activeDonor?.donorContactHistories?.length) return false

    return activeDonor.donorContactHistories.some((history: any) => typeof history?.donationAmount === 'number')
  }, [activeDonor])

  return (
    <div className="col-md-3">
      <div className="card card-fullHeight mt-3 shadow-2">
        <div className="card-body scroll-y">
          <div className="basic-info">
            <div className="avatar m-auto border-0">
              <span className="name fw-bold"></span>
            </div>
            <p className="mb-0 mt-2 fw-bold text-center">{activeDonor?.name}</p>
            <p className="body-4 fw-bold text-center text-muted">
              {activeDonor?.sex}
            </p>
            <p className="mb-4 fw-bold text-center">
              <PhoneOutlineIcon />
              <span className="ms-2"> {activeDonor?.phoneNumber}</span>
            </p>
          </div>
          <hr className="mb-4"></hr>
          {/* donor Details */}
          <div className="voter-details">
            <h4 className="body-5"> Donor Details </h4>
            <div className="row">
              <div className="col-4 text-muted d-flex body-4">Email</div>
              <div className="col d-flex align-items-center">
                <span className="body-4">{activeDonor?.email}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-muted d-flex body-4">Age</div>
              <div className="col d-flex align-items-center">
                <span className="body-4"> {activeDonor?.age}</span>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4 text-muted d-flex body-4">
                Party Affiliation
              </div>
              <div className="col d-flex align-items-center">
                <span className="body-4"> {activeDonor?.partyAffiliation}</span>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4 text-muted d-flex body-4">Source</div>
              <div className="col d-flex align-items-center">
                <span className="body-4"> {activeDonor?.source}</span>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4 text-muted d-flex body-4">Donor Type</div>
              <div className="col d-flex align-items-center">
                <span className="body-4"> {activeDonor?.donorType}</span>
              </div>
            </div>
          </div>
          <h4 className="body-5">Contact History</h4>
          {/* 
            By default there always exits a donorContactHistories with status
            Prospects and there is no update of history i.e. on each time new
            history will be created  
          */}
          {isDonationAmountAvailable ? (
            <div className="contact-history">
              <div className="timeline timeline-sm">
                {activeDonor?.donorContactHistories?.map((history: any, index: number) => (
                  <div className="timeline-item pb-4" key={index}>
                    <div className="timeline-step">
                      <span></span>
                    </div>
                    <div className="timeline-content">
                      {history?.donationAmount >= 0 ? (
                        <>
                          <h4 className="mb-1 body-4">
                            Donated&nbsp;
                            <span className="text-success body-5">
                              ${history?.donationAmount}
                            </span>
                          </h4>
                          <p className="text-muted fs-8 mb-0">
                            Called on&nbsp;
                            {getLocaleDateString(history?.createdAt)}
                            <span className="dot bg-neutral-n3 ms-1"> </span>
                            {getLocaleTimeString(history?.createdAt)}
                          </p>

                          {!!history?.notes && (
                            <p
                              className="text-muted fs-8 mb-0 text-truncate-1 text-left"
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title={historyTooltip}
                            >
                              {history?.notes}
                            </p>
                          )}
                        </>
                      ) : null}

                      {!!history?.status ? (
                        <>
                          <h4 className="mb-1 body-4">
                            Marked as&nbsp;
                            <span className="text-success body-5">
                              {history?.status}
                            </span>
                          </h4>
                          <p className="text-muted fs-8 mb-0">
                            Called on&nbsp;
                            {getLocaleDateString(history?.createdAt)}
                            <span className="dot bg-neutral-n3 ms-1"> </span>
                            {getLocaleTimeString(history?.createdAt)}
                          </p>
                          {!!history?.notes && (
                            <p
                              className="text-muted fs-8 mb-0 text-truncate-1 text-left"
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title={historyTooltip}
                            >
                              {history?.notes}
                            </p>
                          )}
                        </>
                      ) : null}

                      {history?.isContributionEmailSent ? (
                        <>
                          <h4 className="mb-1 body-4">
                            Email sent to&nbsp;
                            <span className="text-success body-5">
                              {history?.email || ''}
                            </span>
                          </h4>
                          <p className="text-muted fs-8 mb-0">
                            Called on&nbsp;
                            {getLocaleDateString(history?.createdAt)}
                            <span className="dot bg-neutral-n3 ms-1"> </span>
                            {getLocaleTimeString(history?.createdAt)}
                          </p>
                          {!!history?.notes && (
                            <p
                              className="text-muted fs-8 mb-0 text-truncate-1 text-left"
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title={historyTooltip}
                            >
                              {history?.notes}
                            </p>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                )
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex gap-2 flex-row align-items-center">
              <InfoIconSecondary />
              <p className="m-0 text-muted fs-7">No history available</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DonorDetails;

const getLocaleDateString = (newDate: string) => {
  if (typeof newDate === 'string')
    return new Date(newDate).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric", })

  return null
}

const getLocaleTimeString = (newDate: string) => {
  if (typeof newDate === 'string')
    return new Date(newDate).toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", })

  return null
}

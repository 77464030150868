import ChevronDownIcon from "img/svg/chevronDownIcon";
import CircleIcon from "img/svg/circleIcon";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const SideNavbar: React.FC = () => {
  const [activeDropdownTab, setActiveDropDownTab] = useState<string>('');

  const handleMiniSidenav = () => {
    document.body.classList.toggle("mini-sidenav");
  };

  const handleDropdownActiveState = (isActive: boolean, parentTitle: string, title: string) => {
    if (isActive) setActiveDropDownTab(parentTitle ?? '');

    return (
      <span className={isActive ? "nav-item active" : "nav-item"}>
        <span className="nav-link">
          <span className="nav-icon">
            <CircleIcon />
          </span>
          <span className="link-text">{title}</span>
        </span>
      </span>
    );
  };

  return (
    <div className="side-navbar active-nav sidenav-verticle" id="sidebarNav">
      <a href="/" className="sidebar-brand mb-lg-5"></a>
      <button
        className="btn p-0 sidebar-toggle d-none d-lg-block"
        onClick={handleMiniSidenav}
      ></button>
      <ul className="nav flex-column text-white w-100 mt-4">
        <li>
          <NavLink
            to="/dashboard"
            onClick={() => setActiveDropDownTab('')}
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="sidebar-link">
              <div className="link-title">
                <span className="nav-icon icon-home-1x"> </span>
                <span className="link-text"> Dashboard </span>
              </div>
            </span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/analytics"
            onClick={() => setActiveDropDownTab('')}
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="sidebar-link">
              <div className="link-title">
                <span className="nav-icon icon-analytics"> </span>
                <span className="link-text"> Analytics </span>
              </div>
            </span>
          </NavLink>
        </li>

        <li
          className={
            activeDropdownTab === 'voters' ? "nav-link active has-sub no-bg" : "nav-link"
          }
        >
          <span
            className="sidebar-link"
            data-bs-toggle="collapse"
            data-bs-target="#navDashboard"
            aria-expanded="false"
            aria-controls="navDashboard"
          >
            <div className="link-title">
              <span className="nav-icon icon-users"> </span>
              <span className="link-text"> Voters </span>
            </div>
            <ChevronDownIcon />
          </span>

          <div
            id="navDashboard"
            className={activeDropdownTab === 'voters' ? "collapse show" : "collapse"}
            data-parent="#sidebarNav"
          >
            <ul className="nav flex-column">
              <li>
                <NavLink to="/voters/dashboard">
                  {({ isActive }) =>
                    handleDropdownActiveState(isActive, 'voters', "Voter Dashboard")
                  }
                </NavLink>
              </li>

              <li>
                <NavLink to="/voters/list">
                  {({ isActive }) =>
                    handleDropdownActiveState(isActive, 'voters', "Voter List")
                  }
                </NavLink>
              </li>

              <li>
                <NavLink to="/voters/contact-plan">
                  {({ isActive }) =>
                    handleDropdownActiveState(isActive, 'voters', "Contact Plan")
                  }
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <NavLink
            to="/fundraise"
            onClick={() => setActiveDropDownTab('')}
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="sidebar-link">
              <div className="link-title">
                <span className="nav-icon icon-dollar"> </span>
                <span className="link-text"> Fundraise </span>
              </div>
            </span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/communication"
            onClick={() => setActiveDropDownTab('')}
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="sidebar-link">
              <div className="link-title">
                <span className="nav-icon icon-speaker"> </span>
                <span className="link-text"> Communications </span>
              </div>
            </span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/volunteers"
            onClick={() => setActiveDropDownTab('')}
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="sidebar-link">
              <div className="link-title">
                <span className="nav-icon icon-volunteers"> </span>
                <span className="link-text"> Volunteers </span>
              </div>
            </span>
          </NavLink>
        </li>

        <li
          className={
            activeDropdownTab === 'budget' ? "nav-link active has-sub no-bg" : "nav-link"
          }
        >
          <span
            className="sidebar-link"
            data-bs-toggle="collapse"
            data-bs-target="#budget"
            aria-expanded="false"
            aria-controls="budget"
          >
            <div className="link-title">
              <span className="nav-icon icon-chart"> </span>
              <span className="link-text"> Budget </span>
            </div>
            <ChevronDownIcon />
          </span>
          <div
            id="budget"
            className={activeDropdownTab === 'budget' ? "collapse show" : "collapse"}
            data-parent="#sidebarNav"
          >
            <ul className="nav flex-column">
              <li>
                <NavLink to="/budget/overview">
                  {({ isActive }) => handleDropdownActiveState(isActive, 'budget', "Budget Overview")}
                </NavLink>
              </li>

              <li>
                <NavLink to="/budget/detail">
                  {({ isActive }) => handleDropdownActiveState(isActive, 'budget', "Budget Detail")}
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <NavLink
            to="/profile"
            onClick={() => setActiveDropDownTab('')}
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="sidebar-link">
              <div className="link-title">
                <span className="nav-icon icon-setting"> </span>
                <span className="link-text"> Settings </span>
              </div>
            </span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SideNavbar;

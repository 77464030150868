import { useMutation, useQuery } from "@tanstack/react-query";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";
import { globalQueryConfig } from "utils/reactQuery";

interface IAutoDialerData {
  candidate: {
    id: number;
    fullName: string;
    phoneNumber: { phoneNumber: string } | null;
    autoDialerStatus: string | null;
    profilePicture: string | null;
  };

  volunteers: {
    id: number;
    fullName: string;
    phoneNumber: { phoneNumber: string };
    autoDialerStatus: string | null;
    profilePicture: string | null;
  }[];
  integratedVolunteersCount: number;
}

export const FB_SAVED_RESPONSE_KEY = "fbResponse";

export const TWITTER_SAVED_RESPONSE_KEY = "twitterResponse";
export const ITEMS_PER_PAGE = 10;

const useIntegrationDetails = () => {
  const authAxios = CreateAuthAxiosInstance();

  const retrivedCandidateId = localStorage.getItem(CANDIDATE_ID);

  const retrivedTwitterResponse = localStorage.getItem(
    TWITTER_SAVED_RESPONSE_KEY
  );

  const retrivedFbResponse = localStorage.getItem(FB_SAVED_RESPONSE_KEY);

  const [fbResponse, setFbResponse] = useState<any>(
    retrivedFbResponse ? JSON.parse(retrivedFbResponse) : null
  );
  const [twitterResponse, setTwitterResponse] = useState<any>(
    retrivedTwitterResponse ? JSON.parse(retrivedTwitterResponse) : null
  );
  const [isFbConnected, setIsFbConnected] = useState(
    fbResponse?.name ? true : false
  );
  const [isTwitterConnected, setIsTwitterConnected] = useState(
    twitterResponse ? true : false
  );

  async function fetchUserData() {
    const response = await authAxios.get(
      `api/v1/user/info/${retrivedCandidateId}`
    );
    return response.data;
  }

  const { data: userData } = useQuery({
    queryKey: ["userData"],
    queryFn: fetchUserData,
    ...globalQueryConfig,
  });

  async function handleTwitterLogin(retrivedCandidateId: string) {
    const response = await authAxios.post("api/v1/twitter/oauth-url", {
      pageAddress: +retrivedCandidateId,
      page: "profile?tabIndex=5",
    });
    return response.data;
  }

  const { mutate: updateTwitterLogin } = useMutation({
    mutationFn: handleTwitterLogin,
    onSuccess: (data: any) => {
      if (data?.oauthURL) {
        window.location.href = data?.oauthURL;
      }
    },
  });

  const twitterLogin = () => {
    if (retrivedCandidateId) {
      updateTwitterLogin(retrivedCandidateId);
    }
  };

  async function handleTwitterLogout() {
    const response = await authAxios.post("api/v1/twitter/logout", {});
    return response.data;
  }

  const { mutate: updateTwitterLogout, status: twitterLogoutStatus } =
    useMutation({
      mutationFn: handleTwitterLogout,
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["userData"],
        });
        localStorage.removeItem(TWITTER_SAVED_RESPONSE_KEY);
        await setIsTwitterConnected(false);
        fetchUserData();
      },

      onError: (error) => {
        console.error("Error during Twitter logout:", error);
      },
    });

  const twitterLogout = () => {
    if (retrivedCandidateId) {
      updateTwitterLogout();
      toastNotify("success", "Twitter removed successfully");
    }
  };

  async function facebookResponseData(response: any) {
    const responseFacebookData = await authAxios.post(
      "/api/v1/facebook/user-access-token",
      {
        exchangeToken: response.accessToken,
      }
    );
    return responseFacebookData.data;
  }

  const { mutate: facebookMutate } = useMutation({
    mutationFn: facebookResponseData,
    onSuccess: async () => {
      setIsFbConnected(true);
      setFbResponse(facebookResponseData);
    },
  });

  const responseFacebook = (response: any) => {
    if (response.id) {
      facebookMutate(response);
    }
  };

  const facebookLogout = () => {
    localStorage.removeItem(FB_SAVED_RESPONSE_KEY);
    setIsFbConnected(false);
  };

  useEffect(() => {
    if (retrivedCandidateId)
      if (userData?.profiles?.length > 0) {
        setTwitterResponse(userData?.profiles[0]);
        setIsTwitterConnected(true);
      }
  }, [userData, isTwitterConnected]);

  useEffect(() => {
    if (twitterResponse) {
      localStorage.setItem(
        TWITTER_SAVED_RESPONSE_KEY,
        JSON.stringify(twitterResponse)
      );
    }
  }, [twitterResponse]);

  const getAutoDialerData = async () => {
    try {
      const response = await authAxios.get(`/api/v1/integrations/auto-dialer`);
      return response.data as IAutoDialerData;
    } catch (error) {
      console.log(error);
      toastNotify("error", "Error fetching data");
    }
  };

  const { data: autoDialerData, isLoading: isAutoDialerDataLoading } = useQuery(
    {
      queryKey: ["autoDialerData"],
      queryFn: () => getAutoDialerData(),
      ...globalQueryConfig,
    }
  );

  async function handleDisableVolunteer(volunteerId: number) {
    const response = await authAxios.post(
      `api/v1/integrations/auto-dialer/disable/${volunteerId}`,
      {}
    );
    return response.data;
  }

  const { mutate: disableVolunteer } = useMutation({
    mutationFn: handleDisableVolunteer,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["autoDialerData"],
      });
    },
    onError: (error) => {
      console.error("Error during Twitter logout:", error);
    },
  });

  async function handleDisableAutodialerIntegration() {
    const response = await authAxios.post(
      `api/v1/integrations/auto-dialer/deactivate`,
      {}
    );
    return response.data;
  }

  const { mutate: disableAutodialerIntegration } = useMutation({
    mutationFn: handleDisableAutodialerIntegration,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["autoDialerData"],
      });
    },
    onError: (error) => {
      console.error("Error during Twitter logout:", error);
    },
  });

  return {
    isFbConnected,
    responseFacebook,
    fbResponse,
    facebookLogout,
    isTwitterConnected,
    twitterResponse,
    twitterLogin,
    twitterLogout,
    autoDialerData,
    isAutoDialerDataLoading,
    disableVolunteer,
    disableAutodialerIntegration,
  };
};

export default useIntegrationDetails;

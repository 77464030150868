import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const Signup = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    localStorage.clear();
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  }, [loginWithRedirect]);

  return <div></div>;
};

export default Signup;

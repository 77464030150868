import TrashIcon from "img/svg/TrashIcon";
import FileIcon from "img/svg/fileIcon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomScript from "templates/components/scriptEditor/CustomScript";

import DefaultScript from "templates/components/scriptEditor/DefaultScript";
import CustomEmail from "./CustomEmail";

const DonorEmail = () => {
    const [editable, setEditable] = useState<null | string>(null);

  return (
    <div className="settings">
        <div className="settings-header d-flex justify-content-between">
          <div className="">
            <h4> Donor email </h4>
            <p className="body-4 text-muted">
            Edit an email to send the donor who have donated for your campaign.
            </p>
          </div>

          <div>
            {editable === "candidateScript"  ? (
              <>
                {" "}
                <button
                  className="btn btn-link btn-xs rounded-pill"
                  onClick={() => setEditable(null)}
                >
                  Cancel
                </button>
                <button className="btn btn-link btn-xs rounded-pill disabled">
                  Update
                </button>
              </>
            ) : (
              <button
                className="btn btn-link btn-xs px-1 py-0"
                onClick={()=>setEditable("candidateScript")}
              >
                {" "}
                <i className="icon-edit"> </i> Edit details
              </button>
            )}
          </div>
        </div>

        <div className="settings-body">
          <div className="professional-info">
            <hr className="mt-3 mb-3" />
            <div className="row">
              <div className="col-md-4">
                <span className="body-2 fs-7"> Donor email </span>
              </div>
              <div className="col-md-8">
                {editable !== "candidateScript" ? (
                  <div className="d-flex flex-column">
                    <div>
                      <span className="body-4"> Donor contact mail </span>
                     
                    </div>
                    <span className="body-4 text-muted">
                      {" "}
                      Updated on 29 Jan 2024{" "}
                    </span>
                  </div>
                ) : (
                  <>
                    <form>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputTitle"
                          className="form-label"
                        >
                          Title
                        </label>
                        <input
                          
                          type="text"
                          className="form-control"
                          value="Donor contact mail"
                          id="exampleInputTitle"
                          aria-describedby="titleHelp"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputScript"
                          className="form-label"
                        >
                          Email
                        </label>
                        <CustomEmail />
                      </div>
                    </form>
                  </>
                )}
              </div>
              {/* <div className="col-md-2">
              {!editable && <button className="btn btn-link btn-xs no-hover"> Activate </button> }
                
              </div> */}
            </div>
            
          </div>
        </div>
      </div>
  )
}

export default DonorEmail

import DocumentIconSmall from "img/svg/DocumentIconSmall";
import { useConversationalAI } from "./useConversationalAi";
import CustomTooltip from "components/common/CustomTooltip";
import CheckIconDark from "img/svg/CheckIconDark";
import DislikeFilledIcon from "img/svg/DislikeFilledIcon";
import DislikeIcon from "img/svg/DislikeIcon";
import LikeFilledIcon from "img/svg/LikeFilledIcon";
import LikeIcon from "img/svg/LikeIcon";
import { useEffect, useState } from "react";
interface CopyTextProps {
  copyValue: string;
  chatId: number;
  feedbackRating: number | null;
}
const CopyText: React.FC<CopyTextProps> = ({
  copyValue,
  chatId,
  feedbackRating,
}) => {
  const { setRating, setActiveChatId } = useConversationalAI();
  const [isLikeClicked, setIsLikeClicked] = useState(false);
  const [isDislikeClicked, setIsDislikeClicked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleLike = () => {
    isLikeClicked ? setRating(null) : setRating(1);
    setIsLikeClicked(!isLikeClicked)
    setIsDislikeClicked(false);
    chatId && setActiveChatId(chatId);
  };
  const handleDisLike = () => {
    isDislikeClicked ? setRating(null) : setRating(0);
    setIsDislikeClicked(!isDislikeClicked);
    setIsLikeClicked(false);
    chatId && setActiveChatId(chatId);
  };

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = (copyText: string) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (feedbackRating === null) {
      setIsLikeClicked(false);
      setIsDislikeClicked(false);
    } else {
      if (feedbackRating === 1) {
        setIsLikeClicked(true);
        setIsDislikeClicked(false);
      }
      if (feedbackRating === 0) {
        setIsLikeClicked(false);
        setIsDislikeClicked(true);
      }
    }
  }, [feedbackRating]);

  return (
    <>
      <div className="copyText">
        <CustomTooltip content={isLikeClicked ? "Liked" : "Like"}>
          <button
            className="btn btn-link no-hover btn-copyText px-1 py-1"
            onClick={handleLike}
          >
            {isLikeClicked ? <LikeFilledIcon /> : <LikeIcon />}
          </button>
        </CustomTooltip>

        <CustomTooltip content={isDislikeClicked ? "Disliked" : "Dislike"}>
          <button
            className="btn btn-link no-hover btn-copyText px-1 py-1"
            onClick={handleDisLike}
          >
            {isDislikeClicked ? <DislikeFilledIcon /> : <DislikeIcon />}
          </button>
        </CustomTooltip>
        
        <CustomTooltip content={isCopied ? "Copied" : "Copy"}>
          <button
            className="btn btn-link no-hover btn-copyText px-1 py-1"
            onClick={() => handleCopyClick(copyValue)}
          >
            {isCopied ? <CheckIconDark /> : <DocumentIconSmall />}
          </button>
        </CustomTooltip>
      </div>
    </>
  );
};

export default CopyText;

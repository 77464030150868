import Confetti from "img/svg/confetti";
import { IS_ONBOARDING_COMPLETED } from "components/forms/multi-step-forms/onboarding/OnboardingForm";
import Timeline from "../components/Timeline";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import InfoIconLight from "img/svg/InfoIconLight";
import { useNotificationContext } from "global-state/contexts/notificationContext";
import LogoMark from "img/svg/logoMark";

const Welcome = () => {
  const { notificationState } = useNotificationContext();

  useEffect(() => {
    if (notificationState.successMessage) {
      toast.success(notificationState.successMessage, {
        toastId: 1,
        className: "snackBar",
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        hideProgressBar: true,
        autoClose: 5000,
        icon: <InfoIconLight />,
      });
    }
  }, [notificationState.successMessage]);

  return (
    <main className="content">
      <div className="timeline-progress bg-white">
        <Timeline
          titles={[
            "About Yourself",
            "Election Date",
            "Working Hours",
            "Social Media",
          ]}
          currentIndex={4}
          hideActiveTitle={true}
        />{" "}
      </div>
      <div className="formArea bg-grey">
        <div className="centeredXY">
          <LogoMark />
          <h1 className="mt-6">Welcome to Campaign Brain </h1>
          <p className="text-muted">
            Continue to access the features of Campaign Brain
          </p>

          <Link
            to="/dashboard"
            className="btn btn-primary rounded-pill mt-6 px-5"
            onClick={() => localStorage.removeItem(IS_ONBOARDING_COMPLETED)}
          >
            {" "}
            Continue{" "}
          </Link>
        </div>
      </div>
    </main>
  );
};

export default Welcome;

import { AxiosResponse } from "axios";
import DOMPurify from "dompurify";

interface IPhoneSCript {
  candidateInfo: AxiosResponse<any, any> | undefined;
  voterName:string;
  finalScript: string;
  selectedOption: string | undefined;
  setSelectedOption: React.Dispatch<React.SetStateAction<string | undefined>>;
  isScriptActive:boolean;
}

const ContactByPhoneScript = ({
  candidateInfo,
  finalScript,
  selectedOption,
  setSelectedOption,
  voterName,
  isScriptActive,
}: IPhoneSCript) => {
  const SCRIPT_SELECT_OPTIONS = {
    defaultScript: "Default Script",
    customScript: "Custom Script",
  };

  const handleScriptChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div
      className={
        isScriptActive
          ? "contactAction-cardLeft flex-1 scroll-y"
          : "contactAction-cardLeft flex-0 scroll-y"
      }
    >
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title text-primary"> Script</h4>
        {selectedOption && (
          <select
            className="form-select"
            aria-label="Default select example"
            style={{ width: "fit-content" }}
            onChange={handleScriptChange}
            defaultValue={selectedOption}
          >
            {Object.entries(SCRIPT_SELECT_OPTIONS).map(
              ([key, value], index) => (
                <option
                  key={index}
                  value={key}
                  selected={selectedOption === key}
                >
                  {value}
                </option>
              )
            )}
          </select>
        )}
      </div>

      <div className="card-body">
        <div className="script-wrapper textEditor">
          {selectedOption === "defaultScript" && (
            <>
              <p>
                {" "}
                Hi, is{" "}
                <span className="text-bg">
                  {" "}
                  {voterName}{" "}
                </span>
                available?{" "}
              </p>
              <p>
                Hi,{" "}
                <span className="text-bg">
                  {" "}
                  {voterName}
                  ,{" "}
                </span>{" "}
                my name is{" "}
                <span className="text-bg">
                  {" "}
                  {candidateInfo?.data.fullName}{" "}
                </span>{" "}
                and I'm running for{" "}
                <span className="text-bg">
                  {" "}
                  {
                    candidateInfo?.data.candidate.elections[0].electionPosition
                  }{" "}
                </span>{" "}
                , right here in{" "}
                <span className="text-bg">
                  {" "}
                  {candidateInfo?.data.candidate.elections[0].city}{" "}
                </span>{" "}
                . How are you doing today?
              </p>
              <p>
                The reason I'm calling today is because we have a really
                important election upcoming for{" "}
                <span className="text-bg">
                  {" "}
                  {
                    candidateInfo?.data.candidate.elections[0].electionPosition
                  }{" "}
                </span>
                .{" "}
              </p>

              <p>
                Thanks for your time,{" "}
                <span className="text-bg">
                  {" "}
                  {voterName}{" "}
                </span>
                ! <br />
               
              </p>
              <p>
                I'm running for{" "}
                <span className="text-bg">
                  {" "}
                  {candidateInfo?.data.candidate.elections[0].electionPosition}
                </span>{" "}
                .
              </p>
              <p>
                If you have any questions, feel free to reach out. Have a great
                day
              </p>
            </>
          )}

          {selectedOption === "customScript" && (
            <>
              {
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(finalScript),
                  }}
                />
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactByPhoneScript;

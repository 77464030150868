import { ICandidateState } from "../reducers/iCandidateReducer";

export enum CandidateActionTypes {
  ADD_CANDIDATE = "ADD_CANDIDATE",
  REMOVE_CANDIDATE = "REMOVE_CANDIDATE",
  UPDATE_CANDIDATE = "UPDATE_CANDIDATE",
}

type AddCandidateAction = {
  type: CandidateActionTypes.ADD_CANDIDATE;
  payload: ICandidateState;
};

type RemoveCandidateAction = {
  type: CandidateActionTypes.REMOVE_CANDIDATE;
  payload: ICandidateState["id"];
};

type UpdateCandidateAction = {
  type: CandidateActionTypes.UPDATE_CANDIDATE;
  payload: ICandidateState;
};

export type CandidateAction =
  | AddCandidateAction
  | RemoveCandidateAction
  | UpdateCandidateAction;

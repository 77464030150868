import ReactDatePicker from "react-datepicker";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import GoBack from "templates/components/GoBack";
import { IOnboardingFormData } from "components/forms/multi-step-forms/onboarding/OnboardingForm";

interface IElectionDateFormProps {
  errors: FieldErrors<FieldValues> | undefined;
  control: Control<Partial<IOnboardingFormData>, any>;
  back: () => void;
}

// const days = Array.from({ length: 32 }, (_, i) => i + 1);

// const years = Array.from({ length: 10 }, (_, i) => i + 2023);

export function ElectionDateForm({
  back,
  errors,
  control,
}: IElectionDateFormProps) {
  return (
    <div className="formArea bg-grey">
      <GoBack back={back} />
      <div className="formContents">
        <div className="formContents-header">
          <h1>What is the date of Election Day? </h1>
          <p>This allows us to best tailor Campaign Brain to your race</p>
        </div>
        <div className="formContents-body">
          <div className="row gy-2 gx-3 align-items-center mb-3">
            <div className="mb-3">
              <label htmlFor="date" className="form-label">
                Date of Election
              </label>
              <div className="custom-datepicker">
                <Controller
                  name="electionDate"
                  control={control}
                  render={({ field }) => {
                    return (
                      <ReactDatePicker
                        className="form-control"
                        placeholderText="Input the date of Election Day"
                        onChange={(date) =>
                          field.onChange(
                            date?.toLocaleDateString("en-us", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              // timeZone: "UTC",
                            })
                          )
                        }
                        showIcon
                        dateFormat="MMMM d, yyyy"
                        filterDate={(date) => {
                          return new Date() < date;
                        }}
                        selected={field.value ? new Date(field.value) : null}
                      />
                    );
                  }}
                />
              </div>
              {errors?.electionDate?.message && (
                <span className="invalidInput form-text text-danger">
                  {errors.electionDate.message as string}
                </span>
              )}
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary w-100 rounded-pill mt-2"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

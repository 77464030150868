import React from 'react'

const ChevronDownFilledIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
<path d="M1.20711 0C0.761654 0 0.538571 0.538571 0.853553 0.853553L5.64645 5.64645C5.84171 5.84171 6.15829 5.84171 6.35355 5.64645L11.1464 0.853554C11.4614 0.538571 11.2383 0 10.7929 0H1.20711Z" fill="#3B3B4F"/>
</svg>
  )
}

export default ChevronDownFilledIcon

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

import { Tooltip } from "bootstrap";
import { useEffect, useState } from "react";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import VotersListAlert from "../VotersListAlert";
import CampaignSummary from "./CampaignSummary";
import WeeklyContactPlanTable from "./WeeklyContactPlanTable";

// tooltip
export const ATTEMP_TOOLTIP = `Total Voter Contact Attempts shows how many total voter contact calls and door knocks you have made over the entire campaign using Campaign Brain. <br /> <br /><span class='custom-tooltip-text'>Note: Attempts includes phone calls and door knocks where the voter was 'Not Home'</span>`;
export const CONTACT_TOOLTIP = `Total Voter Contacts shows how many successful conversations you have had with target voters. Each voter that you successfully reach is shown in the numerator, while the denominator is the goal for your entire campaign.`;
export const RATE_TOOLTIP = `Contact Rate shows the percentage of door knocks that result in a conversation with the target voter. Calculated as Contacts / Attempts.`;
export const RATE_TWO_TOOLTIP = `Contact Rate shows the percentage of phone calls that result in a conversation with the target voter. Calculated as Contacts / Attempts.`;
export const WEEKLY_TOOLTIP = `Here you can see your weekly progress for this week. In the numerator, you can see how many attempts you have made so far this week. In the denominator, you can see the target goal of contacts for the week. Below, you can see an estimate for how many hours are required to achieve the goal for the week for current week.`;

export interface ICampaignSummary {
  contactedByphone: number;
  contactedDoorToDoor: number;
  dtdContactRate: number | null;
  notContactedVoters: number;
  overallContactRate: number | null;
  phoneContactRate: number | null;
  totalContactAttempts: number;
  totalVoters: number;
  totalSuccessfulContactAttempts: number;
  successfulContactRate: number;
}

export interface IWeeklyContactPlan {
  d2dContactAttempts: number;
  d2dContactTime: number;
  d2dContacts: number;
  isCurrentWeek: boolean;
  phoneContactAttempts: number;
  phoneContactTime: number;
  phoneContacts: number;
  week: string;
  weekNumber: number;
  weeklyAvailability: number;
}

const ContactPlan = () => {
  const userId = localStorage.getItem(CANDIDATE_ID);
  const { getIdTokenClaims } = useAuth0();
  const [token, setToken] = useState<IdToken>();
  const [hasVotersList, setHasVotersList] = useState<boolean>();

  const [campaignSummary, setCampaignSummary] = useState(
    {} as ICampaignSummary
  );

  const [weeklyContactPlan, setWeeklyContactPlan] = useState(
    [] as Array<IWeeklyContactPlan>
  );

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  useEffect(() => {
    (async () => {
      const tokenResponse = await getIdTokenClaims();
      setToken(tokenResponse);
    })();
  }, [getIdTokenClaims]);

  useEffect(() => {
    (async () => {
      try {
        // Get campaign summary
        const campaignSummaryResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/voter/contacts/summary/${userId}`
        );
        setCampaignSummary(campaignSummaryResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [userId]);

  useEffect(() => {
    (async () => {
      if (token)
        try {
          // Get weekly details
          const weeklyContactPlanResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/voter/contacts-attempts/weekly`,
            { headers: { Authorization: `Bearer ${token?.__raw}` } }
          );
          setWeeklyContactPlan(weeklyContactPlanResponse.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    })();
  }, [token]);

  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const electionResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/candidate/election`,
            {
              headers: {
                Authorization: `Bearer ${token?.__raw}`,
              },
            }
          );
          setHasVotersList(electionResponse.data.hasVotersList);
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, [token]);

  return (
    <div className="d-flex align-items-stretch">
      <div className="wrapper active-cont">
        <main>
          <div className="container">
            {!hasVotersList && <VotersListAlert />}
            <CampaignSummary campaignSummary={campaignSummary} />

            <WeeklyContactPlanTable weeklyContactPlan={weeklyContactPlan} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default ContactPlan;

import React from 'react'

const TableCellPlaceholder = () => {
  return (
    <div className='placeholder-glow d-flex justify-content-end align-self-start px-3'>
        <div className="placeholder col-12 align-self-center" style={{width: '120px' ,height: '16px'}}></div>
    </div>
  )
}

export default TableCellPlaceholder

import React from 'react'

const DeviceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path d="M15 0.5C15.5046 0.49984 15.9906 0.690406 16.3605 1.0335C16.7305 1.37659 16.9572 1.84684 16.995 2.35L17 2.5V4.5H18C18.5046 4.49984 18.9906 4.69041 19.3605 5.0335C19.7305 5.37659 19.9572 5.84685 19.995 6.35L20 6.5V15.5C20.0002 16.0046 19.8096 16.4906 19.4665 16.8605C19.1234 17.2305 18.6532 17.4572 18.15 17.495L18 17.5H14C13.6758 17.5001 13.3565 17.4213 13.0695 17.2704C12.7826 17.1196 12.5367 16.9011 12.353 16.634L12.268 16.5H2C1.49542 16.5002 1.00943 16.3096 0.639452 15.9665C0.269471 15.6234 0.0428434 15.1532 0.00500021 14.65L1.00268e-07 14.5V2.5C-0.000159579 1.99542 0.190406 1.50943 0.533497 1.13945C0.876588 0.769471 1.34684 0.542843 1.85 0.505L2 0.5H15ZM18 6.5H14V15.5H18V6.5ZM15 2.5H2V14.5H12V6.5C12 5.96957 12.2107 5.46086 12.5858 5.08579C12.9609 4.71071 13.4696 4.5 14 4.5H15V2.5Z" fill="#3B3B4F"/>
    </svg>
  )
}

export default DeviceIcon

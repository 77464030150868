import React from "react";

const ScriptTabs = () => {
  return (
    <div className="card card-flush border-1 card-verticalNav shadow-4 mb-3">
        <div className="card-header">
            <h5 className="mb-0"> Script </h5>
        </div>
        <div className="card-body px-0">
            <div
                className="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
            >
                <button
                className="nav-link text-start active"
                id="v-pills-calls-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-calls"
                type="button"
                role="tab"
                aria-controls="v-pills-calls"
                aria-selected="true"
                >
                Voter Contact: Calls
                </button>
                <button
                className="nav-link text-start"
                id="v-pills-canvassing-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-canvassing"
                type="button"
                role="tab"
                aria-controls="v-pills-canvassing"
                aria-selected="false"
                >
                Voter Contact: Canvassing
                </button>

                <button
                className="nav-link text-start"
                id="v-pills-callTime-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-callTime"
                type="button"
                role="tab"
                aria-controls="v-pills-callTime"
                aria-selected="false"
                >
                Fundraising: Call Time
                </button>
            </div>
        </div>
    </div>
  );
};

export default ScriptTabs;

import TrashIcon from "img/svg/TrashIcon";
import React, { useEffect, useState } from "react";
import { ACTIVE_STEP } from "../Dashboard";
import { getLocalStorageItem } from "utils/localStorageHelper";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useFormPersist from "react-hook-form-persist";
import CreateAuthAxiosInstance from "utils/authAxios";
import { toast } from "react-toastify";
import InfoIconLight from "img/svg/InfoIconLight";
import { CANDIDATE_ID } from "../../Homepage";

interface ICampaignInfoProps {
  handleStepCompletion: (step: number, status: string) => void;
  isProfileCreated: boolean;
  websiteUrl?: string;
}

interface IOpposition {
  name: string;
  description: string;
}

interface ICampaignInfoSchema {
  oppositions: IOpposition[];
  endorsements?: { body?: string | null }[];
  websiteUrl?: string | null;
}
const ENDORSEMENT_OPPOSITION_ADDING_LIMIT = 5;
const MAX_IMAGE_SIZE_BYTES = 2 * 1024 * 1024;
export const URL_REGEXP =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

// localstroage varaibles
const CAMPAIGN_LOGO = "campaignLogo";
const IMAGE_TYPE_ERROR_MSG = "imageTypeErrorMsg";
const IMAGE_SIZE_ERROR_MSG = "imageSizeErrorMsg";

const campaignInfoSchema = yup.object().shape({
  websiteUrl: yup
    .string()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable()
    .matches(URL_REGEXP, "Invalid URL format"),
  endorsements: yup.array().of(
    yup.object().shape({
      body: yup.string().notRequired(),
      // .max(100, "Endorsement should not exceed 100 characters"),
    })
  ),

  oppositions: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required("Name is required")
          .max(100, "Name should not exceed 100 characters")
          .matches(
            /^[a-zA-Z\s]*$/,
            "Name should contain only letters and spaces"
          ),
        description: yup.string().required("Description is required"),
        // .max(500, "Description should not exceed 500 characters"),
      })
    )
    .required("Opposition is required"),
});

const CampaignInformation = ({
  handleStepCompletion,
  websiteUrl,
}: ICampaignInfoProps) => {
  const activeStep: number = getLocalStorageItem(ACTIVE_STEP);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isCampaignInfoLoaded, setIsCampaignInfoLoaded] = useState(false);

  const [campaignLogoUrl, setCampaignLogoUrl] = useState<string | null>(
    localStorage.getItem(CAMPAIGN_LOGO)
  );

  const authAxtios = CreateAuthAxiosInstance();
  const userId = localStorage.getItem(CANDIDATE_ID);
  const [endorsements, setEndorsements] = useState<{ body: string }[]>([]);
  const [oppositions, setOppositions] = useState<IOpposition[]>([]);
  const [imageSizeErrorMsg, setImageSizeErrorMsg] = useState(
    localStorage.getItem(IMAGE_SIZE_ERROR_MSG) ?? ""
  );
  const [imageTypeErrorMsg, setImageTypeErrorMsg] = useState(
    localStorage.getItem(IMAGE_TYPE_ERROR_MSG) ?? ""
  );

  const [campaignId, setCampaignId] = useState<number>(0);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageSizeErrorMsg("");
      setImageTypeErrorMsg("");
      localStorage.removeItem(IMAGE_SIZE_ERROR_MSG);
      localStorage.removeItem(IMAGE_TYPE_ERROR_MSG);

      const imageFile = e.target.files[0];
      setSelectedImage(imageFile);
      if (imageFile.size > MAX_IMAGE_SIZE_BYTES) {
        const ERROR_MSG = "Image size should be less than 2MB";
        setImageSizeErrorMsg(ERROR_MSG);
        localStorage.setItem(IMAGE_SIZE_ERROR_MSG, ERROR_MSG);
      }
      if (
        imageFile.type !== "image/jpeg" &&
        imageFile.type !== "image/jpg" &&
        imageFile.type !== "image/png"
      ) {
        const ERROR_MSG = "Image should of type jpeg, jpg or png";
        setImageTypeErrorMsg(ERROR_MSG);
        localStorage.setItem(IMAGE_TYPE_ERROR_MSG, ERROR_MSG);
      }

      // Create a URL for the selected image
      const url = URL.createObjectURL(imageFile);
      setCampaignLogoUrl(url);
      localStorage.setItem(CAMPAIGN_LOGO, url);
    }
  };
  const getCampaignInfo = async () => {
    try {
      const res = await authAxtios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/campaigns/candidate-campaigns`
      );

      if (res.data) {
        setCampaignId(res?.data?.id);
        const mappedEndorsements = (res?.data?.endorsements).map(
          (item: string) => ({ body: item })
        );
        setEndorsements(mappedEndorsements);
        setOppositions(res?.data?.oppositions);
        if (!localStorage.getItem(CAMPAIGN_LOGO))
          setCampaignLogoUrl(res?.data?.logo);
      }
      setIsCampaignInfoLoaded(true);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage) {
        toast.error(
          Array.isArray(errorMessage) ? errorMessage[0] : errorMessage,
          {
            toastId: 1,
            className: "snackBar",
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "dark",
            hideProgressBar: true,
            autoClose: 5000,
            icon: <InfoIconLight />,
          }
        );
      }
    }
  };

  useEffect(() => {
    getCampaignInfo();
  }, []);

  const handleUpdateCampaignInfo = async (data: ICampaignInfoSchema) => {
    try {
      const formData = new FormData();
      if (selectedImage) formData.append("logo", selectedImage);
      if (userId) formData.append("candidateId", userId);
      if (data.websiteUrl) formData.append("website", data.websiteUrl);
      if (data.endorsements?.length) {
        const mappedEndorsements = data.endorsements?.filter(
          (item) => item.body && item.body.trim() !== ""
        );

        if (!mappedEndorsements.length) {
          formData.append("endorsements", JSON.stringify(null));
        }
        if (mappedEndorsements.length)
          formData.append(
            "endorsements",
            JSON.stringify(mappedEndorsements.map((item) => item.body))
          );
      }
      if (data.oppositions?.length)
        formData.append("oppositions", JSON.stringify(data.oppositions));

      if (campaignId) {
        await authAxtios.patch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/campaigns/${campaignId}`,
          formData,

          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        await authAxtios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/campaigns`,
          formData,

          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      handleStepCompletion(activeStep, "completed");
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage) {
        toast.error(
          Array.isArray(errorMessage) ? errorMessage[0] : errorMessage,
          {
            toastId: 1,
            className: "snackBar",
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "dark",
            hideProgressBar: true,
            autoClose: 5000,
            icon: <InfoIconLight />,
          }
        );
      }
    }
  };

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ICampaignInfoSchema>({
    resolver: yupResolver(campaignInfoSchema),
  });

  useFormPersist("campaignInfoForm", {
    watch,
    setValue,
    storage: localStorage,
  });

  const {
    fields: endorsementFields,
    append: appendEndorsement,
    remove: removeEndorsement,
  } = useFieldArray({
    control,
    name: "endorsements",
  });

  const {
    fields: oppositionFields,
    append: appendOpposition,
    remove: removeOpposition,
  } = useFieldArray({
    control,
    name: "oppositions",
  });

  useEffect(() => {
    const storedWebsiteUrl = getValues("websiteUrl");
    const storedEndorsements = getValues("endorsements");
    const storedOppositions = getValues("oppositions");

    if (isCampaignInfoLoaded) {
      if (websiteUrl && storedWebsiteUrl === "")
        setValue("websiteUrl", websiteUrl);

      if (storedEndorsements?.length) {
        setValue("endorsements", storedEndorsements);
      } else {
        endorsements.length
          ? setValue("endorsements", endorsements)
          : setValue("endorsements", [{ body: "" }]);
      }

      if (storedOppositions?.length) {
        setValue("oppositions", storedOppositions);
      } else {
        oppositions?.length
          ? setValue("oppositions", oppositions)
          : setValue("oppositions", [{ name: "", description: "" }]);
      }
    }
  }, [isCampaignInfoLoaded]);

  return (
    <div className="col-md-8">
      <div className="card card-accountSetup shadow-2">
        <div className="card-title mb-0">
          <h4 className="mb-0"> Campaign Information </h4>
        </div>
        <form
          onSubmit={handleSubmit(async (data) => {
            if (
              imageSizeErrorMsg.length === 0 &&
              imageTypeErrorMsg.length === 0
            )
              await handleUpdateCampaignInfo(data);
          })}
        >
          <div className="card-body pt-0">
            <div className="campaign-information">
              <div className="mb-3">
                <label htmlFor="website" className="custom-label">
                  Campaign website
                </label>

                <label className="form-label mb-2 text-muted d-block fs-7">
                  Input the link to your campaign website.
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="website"
                  aria-describedby="emailHelp"
                  placeholder={websiteUrl ?? ""}
                  defaultValue={websiteUrl ?? ""}
                  {...register("websiteUrl")}
                />
                {errors?.websiteUrl?.message && (
                  <span className="invalidInput form-text text-danger">
                    {errors.websiteUrl.message as string}
                  </span>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="website" className="custom-label">
                  Campaign’s logo
                </label>

                <div className="media media-lg">
                  <div className="media-header avatar border-0 position-relative">
                    <span className="media-header--text">
                      {campaignLogoUrl ? (
                        <img
                          src={campaignLogoUrl ?? ""}
                          className="img-fluid rounded-circle"
                          alt="User Avatar"
                        />
                      ) : (
                        <i className="icon-camera text-muted"></i>
                      )}
                    </span>

                    <div className="edit-avatar">
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        <i className="icon-pencil"> </i>
                      </label>
                    </div>
                  </div>
                </div>
                {imageSizeErrorMsg?.length > 0 && (
                  <span className="invalidInput form-text text-danger">
                    {imageSizeErrorMsg}
                  </span>
                )}
                <br />
                {imageTypeErrorMsg?.length > 0 && (
                  <span className="invalidInput form-text text-danger">
                    {imageTypeErrorMsg}
                  </span>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="website " className="form-label custom-label">
                  Endorsement
                </label>

                <label className=" mb-2 text-muted d-block fs-7">
                  Input any organizations that have endorsed you, and a brief
                  description of why they endorsed you.
                </label>

                <div className="groupField ">
                  {endorsementFields.map((endorsementItem, index) => (
                    <div key={index} className="groupField-item mb-3">
                      <div className="d-flex gap-3">
                        <div className="position-relative w-100">
                          <textarea
                            className="form-control"
                            id="TextareaStrength"
                            rows={2}
                            // maxLength={1000}
                            {...register(`endorsements.${index}.body`)}
                          ></textarea>

                          {/* <span className="word-count">
                            {" "}
                            {getValues("endorsements")?.[index]?.body?.length} /
                            100{" "}
                          </span> */}
                          {errors?.endorsements?.[index]?.body && (
                            <span className="invalidInput form-text text-danger">
                              {
                                errors.endorsements?.[index]?.body
                                  ?.message as string
                              }
                            </span>
                          )}
                        </div>

                        {
                          <button
                            className="btn btn-link pt-0 px-0 mt-2"
                            type="button"
                            disabled={endorsementFields.length === 1}
                            onClick={() => {
                              removeEndorsement(index);
                            }}
                          >
                            {" "}
                            <span className="svg-neutral-2">
                              {" "}
                              <TrashIcon />{" "}
                            </span>{" "}
                          </button>
                        }
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  className={`btn btn-link ${
                    endorsementFields.length ===
                    ENDORSEMENT_OPPOSITION_ADDING_LIMIT
                      ? " text-disabled"
                      : " text-primary"
                  }`}
                  type="button"
                  id="addEndorsementCount"
                  disabled={
                    endorsementFields.length ===
                    ENDORSEMENT_OPPOSITION_ADDING_LIMIT
                  }
                  onClick={() => {
                    if (
                      endorsementFields.length <
                      ENDORSEMENT_OPPOSITION_ADDING_LIMIT
                    )
                      appendEndorsement({ body: "" });
                  }}
                >
                  {" "}
                  <span className="icon-plus me-2"></span> Add another
                  endorsement{" "}
                </button>
              </div>

              <div className="mb-3 required">
                <label htmlFor="website" className="form-label custom-label">
                  Opposition candidate
                </label>

                <label className=" mb-2 text-muted d-block fs-7">
                  Describe the background of any opposition candidates. This
                  information is used to train the AI Copilot.
                </label>
              </div>

              <div className="mb-3 ">
                <div className="groupfield">
                  {oppositionFields.map((oppositionItem, index) => (
                    <div key={index} className="groupfield-item mb-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="form-label mb-2 text-muted d-block fs-7">
                          Opposition {index + 1}
                        </label>

                        {
                          <button
                            className="btn btn-link pt-0 px-0 mt-2"
                            type="button"
                            disabled={oppositionFields.length === 1}
                            onClick={() => {
                              removeOpposition(index);
                            }}
                          >
                            {" "}
                            <span className="svg-neutral-2">
                              {" "}
                              <TrashIcon />{" "}
                            </span>{" "}
                          </button>
                        }
                      </div>

                      <div className="mb-3">
                        <label htmlFor="oppName" className="body-5">
                          Name
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="oppName"
                          aria-describedby="emailHelp"
                          placeholder=""
                          {...register(`oppositions.${index}.name`)}
                        />
                        {errors?.oppositions?.[index]?.name && (
                          <span className="invalidInput form-text text-danger">
                            {
                              errors.oppositions?.[index]?.name
                                ?.message as string
                            }
                          </span>
                        )}
                      </div>

                      <label htmlFor="TextareaStrength" className="body-5">
                        Description
                      </label>

                      <div className="position-relative">
                        <textarea
                          className="form-control"
                          id="TextareaStrength"
                          rows={3}
                          // maxLength={1000}
                          {...register(`oppositions.${index}.description`)}
                        ></textarea>

                        {/* <span className="word-count">
                          {" "}
                          {
                            getValues("oppositions")?.[index]?.description
                              ?.length
                          }{" "}
                          / 500{" "}
                        </span> */}
                        {errors?.oppositions?.[index]?.description && (
                          <span className="invalidInput form-text text-danger">
                            {
                              errors.oppositions?.[index]?.description
                                ?.message as string
                            }
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  className={`btn btn-link ${
                    oppositionFields.length ===
                    ENDORSEMENT_OPPOSITION_ADDING_LIMIT
                      ? " text-disabled"
                      : " text-primary"
                  }`}
                  type="button"
                  disabled={
                    oppositionFields.length ===
                    ENDORSEMENT_OPPOSITION_ADDING_LIMIT
                  }
                  onClick={() => {
                    if (
                      oppositionFields.length <
                      ENDORSEMENT_OPPOSITION_ADDING_LIMIT
                    )
                      appendOpposition({ name: "", description: "" });
                  }}
                >
                  {" "}
                  <span className="icon-plus me-2"></span> Add another
                  opposition{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="card-footer shadow-6 d-flex gap-4 justify-content-end">
            <button
              onClick={() => {
                handleStepCompletion(activeStep, "skipped");
              }}
              className="btn btn-link text-primary btn-xs rounded-pill"
            >
              Not Now
            </button>
            <button
              type="submit"
              disabled={
                getValues("oppositions")?.[0]?.description?.length === 0 ||
                getValues("oppositions")?.[0]?.name?.length === 0
              }
              className={`btn btn-primary rounded-pill btn-xs px-6`}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CampaignInformation;

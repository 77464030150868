export const getReportValue = (
  isAmount = false,
  value: number | string | null
) => {
  if (!value) return 0;
  if (typeof value === "string") value = parseInt(value);
  if (!isAmount) return value;
  if (value < 0) return `-$${Math.abs(value)}`;
  return `$${value}`;
};

import React from "react";

const AddBudgetRow = () => {
  return (
    <tr className="newRowBudget">
      {/* conditionally rendered: after add sub cat. click  */}
      <td colSpan={9}>
        <input type="text" className="form-control" />
      </td>

      {/* after user clicks enter: new row added  */}
      {/* conditionally render  */}
      {/* <td>
        {" "}
        <span className="text-muted"> New Row </span>{" "}
      </td>
      <td> $0</td>
      <td>
        <BudgetCell />
      </td>
      <td>
        <BudgetCell />
      </td>
      <td>
        <BudgetCell />
      </td>
      <td>
        <BudgetCell />
      </td>
      <td>
        <BudgetCell />
      </td>
      <td>
        <BudgetCell />
      </td>
      <td>
        <BudgetCell />
      </td> */}
    </tr>
  );
};

export default AddBudgetRow;

import { CANDIDATE_ID } from "../../Homepage";
import { useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import React from "react";
import DonorTableTabV2 from "./DonorTableTabV2";

export interface IDonorsList {
  name: string;
  source: string;
  donorType: number;
  status: string;
  donationAmount: string;
}

const TAB_LIST = [
  {
    title: 'All',
    key: 'totalDonors'
  },
  {
    title: 'Prospects',
    key: 'prospects'
  },
  {
    title: 'Donors',
    key: 'donors'
  },
  {
    title: 'Ready for Resolicitation',
    key: 'readyForResolicitation'
  }
]

const DonorsTableV2 = () => {
  const authAxios = CreateAuthAxiosInstance();
  const localCandidateId = localStorage.getItem(CANDIDATE_ID);

  const {
    data: donorTypeStats,
    isFetching: isFetchingDonorTypeStats,
    refetch: refetchDonorTypeStats,
  } = useQuery({
    queryKey: ["donorTypeStats"],
    queryFn: () =>
      authAxios(`api/v1/donor-contact-history/candidate/${localCandidateId}/donor-type-stats`).then(
        (data) => data?.data
      ),
    refetchOnWindowFocus: false,
    enabled: Boolean(localCandidateId)
  })

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="section-box mb-0">
            <h3 className="section-box-title">Donor List</h3>
          </div>
        </div>
      </div>

      {/* contact donor card  */}
      <div className="row">
        <div className="col-md-12">
          <ul
            className="nav nav-tabs nav-flush position-relative bg-transparent"
            id="myTab"
            role="tablist"
          >
            {TAB_LIST.map((item, index) => {
              const { title, key } = item

              return (
                <li key={key} className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${index === 0 ? 'active' : ''}`}
                    id={`${key}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${key}-tab-pane`}
                    type="button"
                    role="tab"
                    aria-controls={`${key}-tab-pane`}
                    aria-selected="true"
                  >
                    {title}
                    <span className="badge badge-secondary ms-2 badge-xs">
                      {donorTypeStats?.[key]}
                    </span>
                  </button>
                </li>
              )
            })}
          </ul>

          <div className="tab-content" id="myTabContent">
            {TAB_LIST.map((item, index) => {
              const { title, key } = item

              return (
                <div
                  key={key}
                  className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                  id={`${key}-tab-pane`}
                  role="tabpanel"
                  aria-labelledby={`${key}-tab`}
                  tabIndex={index}
                >
                  <DonorTableTabV2
                    title={title}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(DonorsTableV2);

import SideNavbar from "components/common/navbar/SideNavbar";
import InfoIconDanger from "img/svg/InfoIconDanger";
import InfoIconPrimary from "img/svg/InfoIconPrimary";
import InfoIconWarning from "img/svg/InfoIconWarning";
import ThreeDots from "img/svg/threeDots";
import React from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import SidePanelDetails from "templates/components/SidePanelDetails";
import Spinner from "templates/components/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import CheckSuccess from "img/svg/checkSuccess";

const VolunteerList = () => {
  const notify = () => {
    // toast("Wow so easy!");

    toast.error("Deleted Successfully !", {
      className: 'alert alert-success',
      position: toast.POSITION.TOP_RIGHT,
      theme:"light",
      hideProgressBar:true,
      autoClose: 5000,
      icon: <CheckSuccess />
    });
  }

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />
      <SidePanelDetails />

      <div className="wrapper active-cont">
        <main>
          {/* Alert */}
          <div className="row">
            <div className="col-md-12">
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <InfoIconWarning />{" "}
                <span className="ms-2">Invite to add volunteers </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="tab-content" id="myTabContent">
                {/* search */}
                <div className="row justify-content-between align-items-start">
                  <div className="col-md-4">
                    <div className="d-flex flex-column gap-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search voter"
                        value=""
                      />

                      <div className="filter-message">
                        Showing results for <span>"aaasdasd", </span>
                        <span>A - Z </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
                      {/* export */}

                      <CSVLink
                        data=""
                        filename={"voters-list.csv"}
                        className="btn btn-info rounded-pill btn-xs px-4"
                        target="_blank"
                      >
                        {" "}
                        Export CSV{" "}
                      </CSVLink>

                      <button
                        className="btn btn-primary rounded-pill btn-xs px-4"
                        data-bs-toggle="modal"
                        data-bs-target="#uploadModal"
                      >
                        {" "}
                        Invite Volunteer{" "}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card card-fullHeight scroll-y mt-3 shadow-4 border-1 card-">
                  {/* table */}
                  <table className="table table-full table-eq-height table-hover">
                    <thead>
                      <tr>
                        <th>
                          <input type="checkbox" />
                        </th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone no.</th>
                        <th> Status</th>
                        <th>Last active date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <input type="checkbox" />
                        </td>
                        <td>Jacob Jones</td>
                        <td> jacob.parker@gmail.com </td>
                        <td>
                          {" "}
                          <span> (555) 123-4567 </span>{" "}
                          {/* <span className="d-block"> - </span>{" "} */}
                        </td>
                        <td>
                          <div>
                            <span className="badge badge-success rounded-pill">
                              Active
                            </span>

                            <span className="d-block fs-10 text-muted">
                              {" "}
                              Sign up: 6/23/23{" "}
                            </span>
                          </div>

                          {/* <span className="d-block"> - </span>  */}
                        </td>
                        <td>
                          {/* <span className="body-5"> $ 300 </span>
                          <span className="d-block fs-10 text-muted">
                              {" "}
                              Last attempt: 6/23/23{" "}
                            </span> */}
                          <span className="d-block"> - </span>
                        </td>
                        <td className="text-center">
                          <Link
                            className="px-3 py-2"
                            to=""
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <ThreeDots />
                          </Link>

                          <div className="dropdown-menu">
                            <div className="dropdown-group">
                              <ul className="dropdown-body list-unstyled mb-0">
                                <li>
                                  <Link
                                    to=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#editModal"
                                  >
                                    {" "}
                                    Edit volunteer
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    {" "}
                                    Delete volunteer{" "}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          {" "}
                          <input type="checkbox" />
                        </td>
                        <td>Jacob Jones</td>
                        <td> jacob.parker@gmail.com </td>
                        <td>
                          {" "}
                          {/* <span> (555) 123-4567 </span>{" "} */}
                          <span className="d-block"> - </span>{" "}
                        </td>
                        <td>
                          <div>
                            <span className="badge badge-success rounded-pill">
                              Active
                            </span>

                            <span className="d-block fs-10 text-muted">
                              {" "}
                              Sign up: 6/23/23{" "}
                            </span>
                          </div>

                          {/* <span className="d-block"> - </span>  */}
                        </td>
                        <td>
                          <span className="body-4"> 09/02/2023 </span>

                          {/* <span className="d-block"> - </span> */}
                        </td>
                        <td className="text-center">
                          <Link
                            className="px-3 py-2"
                            to=""
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <ThreeDots />
                          </Link>

                          <div className="dropdown-menu">
                            <div className="dropdown-group">
                              <ul className="dropdown-body list-unstyled mb-0">
                                <li>
                                  <Link
                                    to=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#editModal"
                                  >
                                    {" "}
                                    Edit volunteer
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    {" "}
                                    Delete volunteer{" "}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          {" "}
                          <input type="checkbox" />
                        </td>
                        <td> Daniel Foster</td>
                        <td> jacob.parker@gmail.com </td>
                        <td>
                          {" "}
                          <span> (555) 123-4567 </span>{" "}
                          {/* <span className="d-block"> - </span>{" "} */}
                        </td>
                        <td>
                          <div>
                            <span className="badge badge-warning rounded-pill">
                              Pending
                            </span>

                            <span className="d-block fs-10 text-muted">
                              {" "}
                              Invitation sent: 6/23/23{" "}
                            </span>
                          </div>

                          {/* <span className="d-block"> - </span>  */}
                        </td>
                        <td>
                          {/* <span className="body-5"> $ 300 </span>
                          <span className="d-block fs-10 text-muted">
                              {" "}
                              Last attempt: 6/23/23{" "}
                            </span> */}
                          <span className="d-block"> - </span>
                        </td>
                        <td className="text-center">
                          <Link
                            className="px-3 py-2"
                            to=""
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <ThreeDots />
                          </Link>

                          <div className="dropdown-menu">
                            <div className="dropdown-group">
                              <ul className="dropdown-body list-unstyled mb-0">
                                <li>
                                  <Link
                                    to=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#editModal"
                                  >
                                    {" "}
                                    Edit volunteer
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to=""
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    {" "}
                                    Delete volunteer{" "}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                            <td colSpan={7} className="border-0">
                              <div className="loader-demo-box">
                                <div className="jumping-dots-loader">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                
                                </div>
                              </div>
                            </td>
                          </tr> 

                      {/* <tr>
                          <td colSpan={7} className="border-0">
                            <div className="noData d-flex gap-2 flex-row align-items-center">
                              <InfoIconSecondary />
                              <p className="m-0 text-muted fs-7">
                                {" "}
                                No tasks available for today{" "}
                              </p>
                            </div>
                          </td>
                        </tr>  */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>

         {/* Add donor Modal */}
         <div
          className="modal fade"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">
                  Invite volunteer
                </h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                  {/* Alert */}
                    <div className="row">
                        <div className="col-md-12">
                        <div
                            className="alert alert-primary fade show"
                            role="alert"
                        >
                            <InfoIconPrimary />{" "}
                            <span className="ms-2">Volunteer will receive an invitation link to sign in </span>
                        </div>
                        </div>
                    </div>

                <form action="">
                <form action="">
                  <div className="mb-3 required">
                    <label htmlFor="exampleInputName1" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      aria-describedby="nameHelp"
                      placeholder="Enter name"
                    />
                    <div className="invalidInput form-text text-danger">
                      {" "}
                      <InfoIconDanger /> Name cannot be numeric{" "}
                    </div>
                  </div>
                  <div className="mb-3 required">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPhone" className="form-label">
                      Phone no.
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="phoneHelp"
                      placeholder="Enter phone no."
                    />
                  </div>
                </form>

                 
                </form>
              </div>

              <div className="modal-footer border-1">
                <Link
                  to=""
                  className="btn btn-link text-primary btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
                <Link
                  to=""
                  className="btn btn-primary rounded-pill btn-xs px-6"
                >
                  Invite volunteer
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* edit Modal */}
        <div
          className="modal fade"
          id="editModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">Edit volunteer </h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                <form action="">
                  <div className="mb-3 required">
                    <label htmlFor="exampleInputName1" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      aria-describedby="nameHelp"
                      placeholder=""
                    />
                    <div className="invalidInput form-text text-danger">
                      {" "}
                      <InfoIconDanger /> Name cannot be numeric{" "}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder=""
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPhone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="phoneHelp"
                      placeholder=""
                    />
                  </div>
                </form>
              </div>

              <div className="modal-footer border-1">
                <Link
                  to=""
                  className="btn btn-link text-primary btn-xs rounded-pill"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
                {/* remove button disabled class after spinner goes off */}
                <Link
                  to=""
                  className="btn btn-primary rounded-pill btn-xs px-6 disabled"
                >
                  
                  <Spinner />
                  Update
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* delete warning Modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body text-center px-4">
                <img
                  src={require("../../img/leave.png")}
                  width={66}
                  height={56}
                  alt="leave alert"
                />
                <h2 className="mt-3">Do you want to delete the volunteer? </h2>
                <p>
                By deleting, this volunteer will no longer be able to support your campaign through Campaign Brain.
                </p>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-link rounded-pill btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                
                <button className="btn btn-primary rounded-pill btn-xs" onClick={notify}>
                 
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerList;

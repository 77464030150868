import SideNavbar from "components/common/navbar/SideNavbar";
import HelpIcon from "img/svg/helpIcon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "bootstrap";
import { useEffect } from "react";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import InfoIconDanger from "img/svg/InfoIconDanger";
import CheckIcon from "img/svg/checkIcon";
import CheckSuccess from "img/svg/checkSuccess";
import InfoIconWarning from "img/svg/InfoIconWarning";
import InfoIconSecondarySmall from "img/svg/InfoIconSecondarySmall";
import InfoIconDangerX16 from "img/svg/InfoIconDangerX16";

const AnalyticsDashboard = () => {
  // tooltip
  const fundraiseTooltip = `Fundraising Progress shows how much money your campaign has raised through call time to date as a percentage of your overall fundraising goal for the entire campaign.`;
  const contactTooltip = `Total Voter Contacts shows how many successful conversations you have had with target voters. Each voter that you successfully reach is shown in the numerator, while the denominator is the goal for your entire campaign.`;
  const progressGoalTooltip = `Progress to Goal is a weighted average of your Fundraising Progress and Total Voter Contacts.`;
  const voterSurplusTooltip = `Based on the constraints of time until the election and how many hours the candidate is able to work each week, will the campaign reach enough voters? <br /> <br /> If the number is negative (and red), there is a deficit and more work beyond the Daily Tasks needs to happen to reach the goal.  <br /> <br />If the number is positive (and green), there is a surplus and the candidate can continue with the Daily Tasks to reach the vote goal.`;
  const additionalvoterSurplusTooltip = `Based on the Voter Contact Deficit, this is an estimate of how many additional hours of work are required during the entire campaign to remove the deficit and reach the Vote Goal.`;
  const fundaiseSurplusTooltip = `Based on the constraints of time until the election and how many hours the candidate is able to work each week, will the campaign raise enough money to reach the fundraising goal?  <br /> <br />  If the number is negative (and red), there is a deficit and more work beyond the Daily Tasks needs to happen to reach the goal.  <br /> <br /> If the number is positive (and green), there is a surplus and the candidate can continue with the Daily Tasks to reach the fundraising goal.`;
  const additionalfundraiseSurplusTooltip = `Based on the Fundraising Deficit, this is an estimate of how many additional hours of work are required during the entire campaign to remove the deficit and reach the Fundraising Goal.`;
  const rateTooltip = `Contact Rate shows the percentage of door knocks that result in a conversation with the target voter. Calculated as Contacts / Attempts.  <br /> <br /> <span class='custom-tooltip-text'> A contact rate at doors typically hovers between 15 - 30%. </span>`;
  const rateTwoTooltip = `Contact Rate shows the percentage of phone calls that result in a conversation with the target voter. Calculated as Contacts / Attempts.`;
  const weeklyPlanTooltip = `Here you can see your weekly progress for this week. In the numerator, you can see how many attempts you have made so far this week. In the denominator, you can see the target goal of contacts for the week. Below, you can see an estimate for how many hours are required to achieve the goal for the week for current week.`;

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          {/* Alert */}
          <div className="row">
            <div className="col-md-12">
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <InfoIconWarning />{" "}
                <span className="ms-2">
                  {" "}
                  0 days left until Election Day. Analytics available up to
                  election day.{" "}
                </span>
              </div>
            </div>
          </div>

          {/* Cards */}
          <div className="row mt-3">
            <div className="col-md-4 col-12 mb-3">
              <div className="card card-flush h-100 shadow-2">
                <div className="card-header d-flex justify-content-between">
                  <h4 className="card-title"> Fundraising Progress</h4>
                  <div className="card-info">
                    <Link
                      to=""
                      data-bs-html="true"
                      data-bs-custom-class="custom-tooltip"
                      className="text-left"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-bs-title={fundraiseTooltip}
                    >
                      {" "}
                      <HelpIcon />{" "}
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-1 d-flex flex-column justify-content-end">
                  <div className="d-flex align-items-end">
                    <h1 className="mb-0 me-2">0 /</h1>
                    <h1 className="mb-0 me-2 text-muted">$18,371</h1>{" "}
                  </div>
                  <div
                    className="progress mt-3"
                    role="progressbar"
                    aria-label="task progress"
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ height: 4 + "px" }}
                  >
                    <div
                      className="progress-bar"
                      style={{ width: " 0%" }}
                    ></div>
                  </div>
                  <p className="m-0 text-muted fs-8 mt-1 fw-bold">
                    <span> 0%</span> to your fundraising goal{" "}
                  </p>

                  {/* placeholder design */}
                  <div className="placeholder-glow w-100">
                    <div className="placeholder col-4 py-3"></div>
                    <div className="placeholder col-12 mt-3"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12 mb-3">
              <div className="card card-flush h-100 shadow-2">
                <div className="card-header d-flex justify-content-between">
                  <h4 className="card-title"> Total Voter Contacts </h4>
                  <div className="card-info">
                    <Link
                      to=""
                      data-bs-html="true"
                      data-bs-custom-class="custom-tooltip"
                      className="text-left"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-bs-title={contactTooltip}
                    >
                      {" "}
                      <HelpIcon />{" "}
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-1 d-flex flex-column justify-content-end">
                  <div className="d-flex align-items-end">
                    <h1 className="mb-0 me-2">0 /</h1>
                    <h1 className="mb-0 me-2 text-muted">871</h1>{" "}
                    <span className="text-muted">voters</span>
                  </div>
                  <div
                    className="progress mt-3"
                    role="progressbar"
                    aria-label="task progress"
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ height: 4 + "px" }}
                  >
                    <div
                      className="progress-bar"
                      style={{ width: " 25%" }}
                    ></div>
                  </div>
                  <p className="m-0 text-muted fs-8 mt-1 fw-bold">
                    <span> 0%</span> to your voter contact goal{" "}
                  </p>

                  {/* placeholder design */}
                  {/* <div className="placeholder-glow w-100">
                      <div className="placeholder col-4 py-3"></div>
                      <div className="placeholder col-12 mt-3"></div>
                    </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12 mb-3">
              <div className="card card-flush h-100 shadow-2">
                <div className="card-header d-flex justify-content-between">
                  <h4 className="card-title"> Progress to Goal</h4>
                  <div className="card-info">
                    <Link
                      to=""
                      data-bs-html="true"
                      data-bs-custom-class="custom-tooltip"
                      className="text-left"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-bs-title={progressGoalTooltip}
                    >
                      {" "}
                      <HelpIcon />{" "}
                    </Link>
                  </div>
                </div>

                <div className="card-body pt-1 d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <h1 className="mb-0 me-2">0%</h1>
                    {/* placeholder design */}
                    <div className="placeholder-glow w-100">
                      <div className="placeholder col-4"></div>
                    </div>
                  </div>

                  <div className="progressGoal">
                    <div className="progressGoal-item">
                      <div className="progressGoal-item-title">
                        {" "}
                        Voters contacted{" "}
                      </div>
                      <div className="progressGoal-item-progress">
                        <div
                          className="progress"
                          role="progressbar"
                          aria-label="task progress"
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ height: 4 + "px" }}
                        >
                          <div
                            className="progress-bar"
                            style={{ width: " 25%" }}
                          ></div>
                        </div>

                        {/* progressbar placeholder design */}
                        {/* <div className="placeholder-glow w-100">
                      <div className="placeholder col-12"></div>
                    </div> */}

                        <div className="progressGoal-item-percent">
                          0%
                          {/* % placeholder design */}
                          {/* <div className="placeholder-glow w-100">
                      <div className="placeholder col-12"></div>
                    </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="progressGoal-item">
                      <div className="progressGoal-item-title">
                        {" "}
                        Funds raised{" "}
                      </div>
                      <div className="progressGoal-item-progress">
                        <div
                          className="progress"
                          role="progressbar"
                          aria-label="task progress"
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ height: 4 + "px" }}
                        >
                          <div
                            className="progress-bar"
                            style={{ width: " 25%" }}
                          ></div>
                        </div>

                        {/* progressbar placeholder design */}
                        {/* <div className="placeholder-glow w-100">
                      <div className="placeholder col-12"></div>
                    </div> */}

                        <div className="progressGoal-item-percent">
                          0%
                          {/* % placeholder design */}
                          {/* <div className="placeholder-glow w-100">
                      <div className="placeholder col-12"></div>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12">
              <div className="card card-flush h-100 shadow-2">
                <div className="card-header d-flex justify-content-between">
                  <h4 className="card-title">
                    {" "}
                    Voter Contact Success Roadmap{" "}
                  </h4>
                </div>

                <div className="card-body pt-2 d-flex flex-column justify-content-end">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="analyticsGroup">
                        <div className="analyticsGroup-item">
                          <div className="d-flex gap-2 align-items-center mb-2">
                            <h6 className="mb-0">
                              {" "}
                              Voter Contact Deficit / Surplus{" "}
                            </h6>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={voterSurplusTooltip}
                              >
                                {" "}
                                <HelpIcon />{" "}
                              </Link>
                            </div>
                          </div>

                          <div className="surplus-label">
                            {/* if 0 = no value */}

                            {/* <h1> 0 </h1> 
                                        
                                        <div className="d-flex gap-2 flex-row align-items-center">
                                        <InfoIconSecondarySmall />
                                        <p className="m-0 text-muted fs-7"> No data available </p>
                                        </div> 
                                        */}
                            <h1 className="text-danger">
                              {" "}
                              -738
                              {/* heading placeholder */}
                              {/* <div className="placeholder-glow w-100 text-muted">
                              <div className="placeholder col-2"></div>
                            </div> */}
                            </h1>

                            <div className="d-flex gap-2 flex-row align-items-center">
                              <InfoIconDangerX16 />
                              <p className="m-0 text-danger fs-7">
                                Not on Track{" "}
                              </p>

                              {/* Not on Track placeholder design */}
                              {/* <div className="placeholder-glow w-100">
                              <div className="placeholder col-3"></div>
                            </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="analyticsGroup-item">
                          <div className="d-flex gap-2 align-items-center mb-2">
                            <h6 className="mb-0">
                              {" "}
                              Additional Hours Needed to Remove Deficit{" "}
                            </h6>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={additionalvoterSurplusTooltip}
                              >
                                {" "}
                                <HelpIcon />{" "}
                              </Link>
                            </div>
                          </div>

                          <div className="surplus-label">
                            {/* <h1> 0 </h1> 
                                    
                                    <div className="d-flex gap-2 flex-row align-items-center">
                                    <InfoIconSecondarySmall />
                                    <p className="m-0 text-muted fs-7"> No data available </p>
                                    </div>  */}

                            <h1 className="">
                              {" "}
                              58 hours
                              {/* hours placeholder */}
                              {/* <div className="placeholder-glow w-100 text-muted">
                              <div className="placeholder col-3"></div>
                            </div> */}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card flex-column gap-3 card-dailyUpdate p-3 border-1">
                        {/* if on track */}

                        {/* <div className="d-flex gap-2 flex-row align-items-center">
                                    <CheckSuccess />
                                    <p className="m-0 text-success fs-8 fw-bold"> On Track </p>
                                </div>  */}

                        {/* if not on track */}
                        <div className="d-flex gap-2 flex-row align-items-center">
                          <InfoIconDangerX16 />
                          <p className="m-0 text-danger fs-8 fw-bold">
                            {" "}
                            Not on Track{" "}
                          </p>

                          {/* not on track placeholder design */}
                          {/* <div className="placeholder-glow w-100 text-muted">
                              <div className="placeholder col-3"></div>
                            </div> */}
                        </div>

                        <div className="card-dailyUpdate-content fundraise-card-dailyUpdate-content">
                          <h5 className="body-5"> Recommendation </h5>
                          <p className="fw-regular">
                            Spend an additional{" "}
                            <span className="fw-bold"> 118 hours </span>during
                            the remainder of the campaign to remove the voter
                            contact deficit by taking the following actions:
                          </p>
                          <ul className="list-unstyled">
                            <li>
                              <span>
                                If possible, the candidate can work additional
                                hours focused on voter contact
                              </span>
                            </li>
                            <li>
                              {" "}
                              <span>
                                Activate additional volunteers to assist the
                                campaign with voter contact
                              </span>
                            </li>
                            <li>
                              {" "}
                              <span>
                                Leverage available campaign funds to pay folks
                                to assist with voter contact
                              </span>
                            </li>
                          </ul>

                          {/* <span> - </span> */}

                          {/* recommendation placeholder design */}

                          {/* paragraph placeholder design */}
                          <div className="placeholder-glow w-100 mb-3">
                            <div className="placeholder col-12 py-4"></div>
                          </div>

                          <div className="placeholder-glow w-100">
                            <div className="placeholder col-4"></div>
                            <div className="placeholder col-12"></div>
                            <div className="placeholder col-12"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-6">
            <div className="col-md-12">
              <div className="card card-flush h-100 shadow-2">
                <div className="card-header d-flex justify-content-between">
                  <h4 className="card-title"> Fundraising Success Roadmap </h4>
                </div>

                <div className="card-body pt-2 d-flex flex-column justify-content-end">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="analyticsGroup">
                        <div className="analyticsGroup-item">
                          <div className="d-flex gap-2 align-items-center mb-2">
                            <h6 className="mb-0">
                              {" "}
                              Fundraising Deficit / Surplus{" "}
                            </h6>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={fundaiseSurplusTooltip}
                              >
                                {" "}
                                <HelpIcon />{" "}
                              </Link>
                            </div>
                          </div>

                          <div className="surplus-label">
                            {/* if 0 = no value */}

                            <h1>
                              {" "}
                              0{/* heading placeholder */}
                              {/* <div className="placeholder-glow w-100 text-muted">
                              <div className="placeholder col-2"></div>
                            </div> */}
                            </h1>

                            <div className="d-flex gap-2 flex-row align-items-center">
                              <InfoIconSecondarySmall />
                              <p className="m-0 text-muted fs-7">
                                {" "}
                                No data available{" "}
                              </p>
                              {/*  placeholder */}
                              {/* <div className="placeholder-glow w-100 text-muted">
                              <div className="placeholder col-3"></div>
                            </div> */}
                            </div>

                            {/* <h1 className="text-danger"> -738 </h1>

                            <div className="d-flex gap-2 flex-row align-items-center">
                              <InfoIconDangerX16 />
                              <p className="m-0 text-danger fs-7">
                                Not on Track{" "}
                              </p>
                            </div> */}
                          </div>
                        </div>

                        <div className="analyticsGroup-item">
                          <div className="d-flex gap-2 align-items-center mb-2">
                            <h6 className="mb-0">
                              {" "}
                              Additional Hours Needed to Remove Deficit{" "}
                            </h6>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={
                                  additionalfundraiseSurplusTooltip
                                }
                              >
                                {" "}
                                <HelpIcon />{" "}
                              </Link>
                            </div>
                          </div>

                          <div className="surplus-label">
                            {/* <h1> 0 </h1> 
                                    
                                    <div className="d-flex gap-2 flex-row align-items-center">
                                    <InfoIconSecondary />
                                    <p className="m-0 text-muted fs-7"> No data available </p>
                                    </div>  */}

                            <h1 className="">
                              {" "}
                              0{/* hours placeholder */}
                              {/* <div className="placeholder-glow w-100 text-muted">
                              <div className="placeholder col-3"></div>
                            </div> */}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card flex-column gap-3 card-dailyUpdate p-3 border-1">
                        {/* if on track */}

                        {/* <div className="d-flex gap-2 flex-row align-items-center">
                                    <CheckSuccess />
                                    <p className="m-0 text-success fs-8 fw-bold"> On Track </p>
                                </div>  */}

                        {/* if not on track */}
                        <div className="d-flex gap-2 flex-row align-items-center">
                          <InfoIconSecondarySmall />
                          <p className="m-0 text-muted fs-8 fw-bold">
                            {" "}
                            No data available{" "}
                          </p>

                          {/*  placeholder */}
                          {/* <div className="placeholder-glow w-100 text-muted">
                              <div className="placeholder col-3"></div>
                            </div> */}
                        </div>

                        <div className="card-dailyUpdate-content fundraise-card-dailyUpdate-content">
                          <h5 className="body-5"> Recommendation </h5>
                          {/* <p>
                            Spend an additional{" "}
                            <span className="fw-bold"> 118 hours </span>during the
                            remainder of the campaign to remove the voter
                            contact deficit by taking the following actions:
                          </p>
                          <ul className="list-unstyled">
                            <li>
                              {" "}
                              <span>
                               Spend additional candidate time executing call time to contact potential donors
                              </span>
                            </li>
                            <li>
                              {" "}
                              <span>
                               Carefully consider who the folks in your personal network are that might donate to the campaign and upload them as Donor prospects in Campaign Brain
                              </span>
                            </li>
                            <li>
                              {" "}
                              <span>
                                Host a fundraising event by gathering supporters together
                              </span>
                            </li>
                          </ul> */}

                          <span> - </span>

                          {/* recommendation placeholder design */}

                          {/* paragraph placeholder design */}
                          <div className="placeholder-glow w-100 mb-3">
                            <div className="placeholder col-12 py-4"></div>
                          </div>

                          <div className="placeholder-glow w-100">
                            <div className="placeholder col-4"></div>
                            <div className="placeholder col-12"></div>
                            <div className="placeholder col-12"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AnalyticsDashboard;

interface ISpinnerProps {
  varient?: string,
  size?: string,
}

const Spinner = ({varient= 'text-light', size= 'spinner-border-sm'}: ISpinnerProps) => {

  return (
    <div className={`spinner-border ${varient} ${size}`} role="status">
      <span className="visually-hidden"></span>
    </div>
  );
};

export default Spinner;

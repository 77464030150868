import DeviceIcon from "img/svg/DeviceIcon";
import ChangePasswordModal from "./ChangePasswordModal";
import PasswordChangedModal from "./PasswordChangedModal";
import { useRef } from "react";
import useSecurityDetails from "./useSecurityDetails";

const SecurityDetails = () => {
  const editPasswordModalRef = useRef<HTMLButtonElement | null>(null);

  return (
    <div className="row mt-5">
      <div className="col-md-8">
        <div className="settings">
          <div className="settings-header d-flex justify-content-between">
            <div className="">
              <h4> Login </h4>
              <p className="body-4 text-muted">
                Update and secure your login credentials
              </p>
            </div>
          </div>

          <div className="settings-body">
            <div className="passwordChange-info">
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-4">
                  <span className="body-2 fs-7"> Change password </span>
                </div>
                <div className="col-md-8">
                  <div className="row justify-content-end">
                    <div className="col-md-8">
                      <span className="body-4 text-muted">
                        Set a unique password to protect your account
                      </span>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-link btn-xs px-1 py-0"
                          data-bs-toggle="modal"
                          data-bs-target="#editPasswordModal"
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-3 mb-3" />
            </div>
            {/* <ActiveDevices /> */}
          </div>
        </div>
        <button
          className="btn btn-link btn-xs px-1 py-0"
          data-bs-toggle="modal"
          data-bs-target="#passwordChangedModal"
          hidden
          ref={editPasswordModalRef}
        >
          Change
        </button>
      </div>

      {/* password Modal */}
      <PasswordChangedModal />
      <ChangePasswordModal editPasswordModalRef={editPasswordModalRef} />
    </div>
  );
};

export default SecurityDetails;

const ActiveDevices = () => {
  return (
    <div className="loginActivity-info mt-5">
      <h4> Active devices </h4>
      <p className="body-4 text-muted">Check and manage logged in devices</p>
      <hr className="mt-3 mb-3" />
      <div className="row">
        <div className="col-md-4">
          <span className="body-2 fs-7"> Devices</span>
        </div>
        <div className="col-md-8">
          <div className="media mb-3">
            <div className="media-icon">
              <DeviceIcon />
            </div>
            <div className="media-body w-100">
              <div className="row">
                <div className="col-md-8">
                  <span className="body-2">Windows PC</span>
                  <div className="body-4 text-muted mb-0">
                    Kathmandu, Nepal{" "}
                    <span className="dot bg-neutral-n3 ms-2"></span>
                    <span className="text-s2 fw-semibold"> Active </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-link text-muted btn-xs px-1 py-0"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteDeviceModal"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="media mb-3">
            <div className="media-icon">
              <DeviceIcon />
            </div>
            <div className="media-body w-100">
              <div className="row">
                <div className="col-md-8">
                  <span className="body-2">Android</span>
                  <div className="body-4 text-muted mb-0">
                    Kathmandu, Nepal{" "}
                    <span className="dot bg-neutral-n3 ms-2"></span>
                    Yesterday at 06:41
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-link text-danger btn-xs px-1 py-0"
                      data-bs-toggle="modal"
                      data-bs-target="#editPasswordModal"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-3 mb-3" />
    </div>
  );
};

import ThreeDots from "img/svg/threeDots";
import { Link } from "react-router-dom";
import { DeleteVolunteerModal } from "./components/DeleteVolunteerModal";
import { UpdateVolunteerModal } from "./components/UpdateVolunteerModal";
import { AddVolunteerModal } from "./components/AddVolunteerModal";
import { ExportCsv } from "./components/ExportCsv";
import { useVolunteerListHook } from "./useVolunteerList";
import SidePanelDetails from "./components/SidePanelDetails";

const VolunteerList = () => {
  const {
    formatDate,
    volunteerDataInfinite,
    volunteerErrorInfinite,
    volunteerList,
    selectedVolunteerIdForDelete,
    setSelectedVolunteerIdForDelete,
    selectedVolunteerForUpdation,
    setSelectedVolunteerForUpdation,
    selectedVolunteerForDetailView,
    setSelectedVolunteerForDetailView,
    searchText,
    tableContainerRef,
    targetItemRef,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    volunteerDataStatusInfinite,
    handleSearchTextOnChange,
    AlertToInviteVolunteer,
    NoDataStatus,
    NetworkErrorStatus,
    LoadingAnimation
  } = useVolunteerListHook()

  return (
    <>
      <div className="d-flex align-items-stretch">
        <div className="wrapper active-cont">
          <main>
            {/* Alert */}
            {volunteerDataStatusInfinite === 'success' &&
              !volunteerList?.length &&
              !searchText?.length &&
              !isFetching &&
              <AlertToInviteVolunteer />
            }
            <div className="row">
              <div className="col-md-12">
                <div className="tab-content" id="myTabContent">
                  {/* search */}
                  <div className="row justify-content-between align-items-start">
                    <div className="col-md-4">
                      <div className="d-flex flex-column gap-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search voter"
                          value={searchText}
                          onChange={handleSearchTextOnChange}
                        />
                        {searchText?.length > 2 &&
                          <div className="filter-message">
                            Showing results for&nbsp;&nbsp;
                            <span>'{searchText}'</span>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
                        <ExportCsv />

                        <button
                          className="btn btn-primary rounded-pill btn-xs px-4"
                          data-bs-toggle="modal"
                          data-bs-target="#uploadModal"
                        >
                          Invite Volunteer
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card card-fullHeight scroll-y mt-3 shadow-4 border-1"
                    ref={tableContainerRef}
                  >
                    {/* table */}
                    <table className="table table-full table-eq-height table-hover table-cursor">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone no.</th>
                          <th> Status</th>
                          <th>Last active date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {(isFetching && !isFetchingNextPage) ? (
                        <LoadingAnimation />
                      ) : (
                        <>
                          <tbody>
                            {!!volunteerList?.length && (
                              volunteerList.map((item, index) => {
                                const {
                                  id,
                                  fullName,
                                  email,
                                  primaryPhoneNumber,
                                  status,
                                  invitedAt,
                                  lastActiveAt,
                                  createdAt
                                } = item
                                return (
                                  <tr
                                    key={email}
                                    ref={index === volunteerList.length - 1 ? targetItemRef : null}
                                  >
                                    <td
                                      onClick={() => setSelectedVolunteerForDetailView(item)}
                                    >
                                      {fullName}
                                    </td>
                                    <td
                                      onClick={() => setSelectedVolunteerForDetailView(item)}
                                    >
                                      {email}
                                    </td>
                                    <td
                                      onClick={() => setSelectedVolunteerForDetailView(item)}
                                    >
                                      <span className="d-block">{primaryPhoneNumber || '-'}</span>
                                    </td>
                                    <td
                                      onClick={() => setSelectedVolunteerForDetailView(item)}
                                    >
                                      {status === 'Active' &&
                                        <div>
                                          <span className="badge badge-success rounded-pill">
                                            Active
                                          </span>
                                          <span className="d-block fs-10 text-muted">
                                            Joined on: {formatDate(createdAt)}
                                          </span>
                                        </div>
                                      }
                                      {status === 'Pending' &&
                                        <div>
                                          <span className="badge badge-warning rounded-pill">
                                            Pending
                                          </span>
                                          <span className="d-block fs-10 text-muted">
                                            Invitation sent: {formatDate(invitedAt)}
                                          </span>
                                        </div>
                                      }
                                    </td>
                                    <td
                                      onClick={() => setSelectedVolunteerForDetailView(item)}
                                    >
                                      <span className="d-block">
                                        {lastActiveAt ? formatDate(lastActiveAt) : '-'}
                                      </span>
                                    </td>

                                    <td className="text-center">
                                      <Link
                                        className="px-3 py-2"
                                        to=""
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <ThreeDots />
                                      </Link>

                                      <div className="dropdown-menu">
                                        <div className="dropdown-group">
                                          <ul className="dropdown-body list-unstyled mb-0">
                                            <li>
                                              <Link
                                                to=""
                                                data-bs-toggle="modal"
                                                data-bs-target="#editModal"
                                                onClick={() => {
                                                  setSelectedVolunteerForUpdation({
                                                    id: id,
                                                    fullName: fullName,
                                                    email: email,
                                                    primaryPhoneNumber: primaryPhoneNumber
                                                  })
                                                }}
                                              >
                                                Edit volunteer
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                to=""
                                                data-bs-toggle="modal"
                                                data-bs-target="#deleteModal"
                                                onClick={() => setSelectedVolunteerIdForDelete(id)}
                                              >
                                                Delete volunteer
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            )}
                          </tbody>

                          {!!volunteerDataInfinite && !volunteerList?.length && <NoDataStatus />}
                          {/* {!hasNextPage && volunteerList?.length > 0 && <p>END</p>} */}
                          {(isFetching && isFetchingNextPage) && <LoadingAnimation />}
                          {!!volunteerErrorInfinite && <NetworkErrorStatus />}
                        </>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div >
      </div >

      <SidePanelDetails
        selectedVolunteerForDetailView={selectedVolunteerForDetailView}
        setSelectedVolunteerForDetailView={setSelectedVolunteerForDetailView}
        setSelectedVolunteerForUpdation={setSelectedVolunteerForUpdation}
        setSelectedVolunteerIdForDelete={setSelectedVolunteerIdForDelete}
      />
      <AddVolunteerModal />
      <UpdateVolunteerModal
        volunteerDetail={selectedVolunteerForUpdation}
        setSelectedVolunteerForDetailView={setSelectedVolunteerForDetailView}
      />
      <DeleteVolunteerModal
        volunteerId={selectedVolunteerIdForDelete}
        setSelectedVolunteerForDetailView={setSelectedVolunteerForDetailView}
      />
    </>
  );
};

export default VolunteerList;


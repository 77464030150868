import { WORKING_HOURS_FORM_COMMON_ERROR_MSG } from "components/forms/multi-step-forms/onboarding/OnboardingForm";
import { useEffect, useState } from "react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import GoBack from "templates/components/GoBack";

type WorkingHoursFormProps = {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues> | undefined;
  back: () => void;
  selectedWorkingHours?: number;
};

export function WorkingHoursForm({
  back,
  register,
  errors,
  selectedWorkingHours,
}: WorkingHoursFormProps) {
  const [isWorkingHoursChecked, setIsWorkingHoursChecked] = useState(
    selectedWorkingHours ? true : false
  );
  const [workingHoursValue, setWorkingHoursValue] = useState<
    number | undefined
  >(selectedWorkingHours);

  useEffect(() => {
    if (selectedWorkingHours) {
      setWorkingHoursValue(selectedWorkingHours);
      setIsWorkingHoursChecked(true);
    }
  }, [selectedWorkingHours]);

  return (
    <div className="formArea bg-grey">
      <GoBack back={back} />
      <div className="formContents">
        <div className="formContents-header">
          <h1>How many hours a week do you plan to work on your campaign? </h1>
          <p>
            Do your best to accurately estimate the time you can invest in
            campaigning per week
          </p>
        </div>
        <div className="formContents-body">
          <div className="mb-3 mt-4">
            <label htmlFor="hoursPerWeek" className="form-label">
              Hours per week
            </label>

            <div className="btn-checkGroup d-flex gap-3">
              <div className="btn-checkGroup-item">
                <input
                  type="radio"
                  className="btn-check"
                  id="option1"
                  autoComplete="off"
                  value={10}
                  {...register("workingHours")}
                  onClick={() => {
                    if (workingHoursValue === 10) {
                      setIsWorkingHoursChecked(!isWorkingHoursChecked);
                    } else {
                      setIsWorkingHoursChecked(true);
                    }
                    setWorkingHoursValue(10);
                  }}
                  checked={isWorkingHoursChecked && workingHoursValue === 10}
                />
                <label
                  className="btn btn-outline-primary btn-xs rounded-pill"
                  htmlFor="option1"
                >
                  10 hours
                </label>
              </div>

              <div className="btn-checkGroup-item">
                <input
                  type="radio"
                  className="btn-check"
                  id="option2"
                  autoComplete="off"
                  value={20}
                  {...register("workingHours")}
                  onClick={() => {
                    if (workingHoursValue === 20) {
                      setIsWorkingHoursChecked(!isWorkingHoursChecked);
                    } else {
                      setIsWorkingHoursChecked(true);
                    }
                    setWorkingHoursValue(20);
                  }}
                  checked={isWorkingHoursChecked && workingHoursValue === 20}
                />
                <label
                  className="btn btn-outline-primary btn-xs rounded-pill"
                  htmlFor="option2"
                >
                  20 hours
                </label>
              </div>

              <div className="btn-checkGroup-item">
                <input
                  type="radio"
                  className="btn-check"
                  id="option3"
                  autoComplete="off"
                  value={40}
                  {...register("workingHours")}
                  onClick={() => {
                    if (workingHoursValue === 40) {
                      setIsWorkingHoursChecked(!isWorkingHoursChecked);
                    } else {
                      setIsWorkingHoursChecked(true);
                    }
                    setWorkingHoursValue(40);
                  }}
                  checked={isWorkingHoursChecked && workingHoursValue === 40}
                />
                <label
                  className="btn btn-outline-primary btn-xs rounded-pill"
                  htmlFor="option3"
                >
                  40 hours
                </label>
              </div>

              <div className="btn-checkGroup-item">
                <input
                  type="radio"
                  className="btn-check"
                  id="option4"
                  autoComplete="off"
                  value={60}
                  {...register("workingHours")}
                  onClick={() => {
                    if (workingHoursValue === 60) {
                      setIsWorkingHoursChecked(!isWorkingHoursChecked);
                    } else {
                      setIsWorkingHoursChecked(true);
                    }
                    setWorkingHoursValue(60);
                  }}
                  checked={isWorkingHoursChecked && workingHoursValue === 60}
                />
                <label
                  className="btn btn-outline-primary btn-xs rounded-pill"
                  htmlFor="option4"
                >
                  60 hours
                </label>
              </div>
            </div>
            {errors?.customWorkingHours?.message ===
              errors?.workingHours?.message && (
              <span className="invalidInput form-text text-danger">
                {errors?.workingHours?.message as string}
              </span>
            )}
          </div>

          <div className="d-flex align-items-center py-3">
            <hr className="w-100" />
            <div className="px-5">Or</div>
            <hr className="w-100" />
          </div>

          <div className="mb-3 position-relative required">
            <label htmlFor="hours" className="form-label">
              Hours per week
            </label>
            <input
              type="text"
              className="form-control"
              id="hours"
              aria-describedby="nameHelp"
              placeholder="Enter hours"
              {...register("customWorkingHours")}
              disabled={isWorkingHoursChecked}
            />
            <div className="abs-text"> Hours per week </div>
            {(errors?.customWorkingHours?.message ===
              errors?.workingHours?.message ||
              errors?.customWorkingHours?.message !==
                WORKING_HOURS_FORM_COMMON_ERROR_MSG) && (
              <span className="invalidInput form-text text-danger">
                {errors?.customWorkingHours?.message as string}
              </span>
            )}
          </div>

          <button
            type="submit"
            className="btn btn-primary w-100 rounded-pill mb-2 mt-2"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

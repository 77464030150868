export const COMMUNICATION_BASE_URL =
  process.env.REACT_APP_COMMUNICATION_BASE_URL;
export const TOTAL_CALL_TIME_TIP = `The combined amount of time spent on calls by candidate and volunteers while reaching out to voters and donors.`;
export const TOTAL_COST_TIP =
  "Total costs reflects cost incurred based on combined call time by candidates and volunteers.";
export const CURRENT_BILLING_TIP = `Current billing period shows the total cost raised by the admin from the selected date range.`;
export const ITEMS_PER_PAGE = 10;

export interface ICallLog {
  basePrice: string;
  callInitiator: string;
  callReceiver: string;
  createdAt: string;
  deletedAt: string | null;
  donorContactHistoryId: number;
  duration: number;
  endTime: string;
  from: string;
  id: number;
  isArchived: boolean;
  isVoiceMail: boolean;
  phoneFromId: number;
  price: number;
  priceUnit: string;
  startTime: string;
  status: string;
  to: string;
  twilioCallLogId: number;
  updatedAt: string;
  voterContactHistoryId: number | null;
  callType: string | null;
}

interface ICurrentBillingData {
  id: number;
  billingPeriodEnd: string;
  billingPeriodStart: string;
  totalAmount: string | null;
}

import React from "react";
import Select from "react-select";
import CustomDdList from "../components/CustomDdList";

interface SingleSelecProps {
  placeholder: string
}

const SingleSelectDd = ({placeholder}: SingleSelecProps) => {
  // for select
  const options = [
    { value: "All voters", label: "302 voters" },
    { value: "Democrats", label: "187 voters" },
    { value: "Republicans", label: "133 voters" },
    { value: "Supporters", label: "0 voters" },
  ];

  return (
    
      

      <Select
        options={options}
        formatOptionLabel={CustomDdList}
        menuPosition="fixed"
        placeholder={<div> {placeholder} </div>}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "#0B45A5" : "#CBCBD8",
          }),
        }}
      />
    
  );
};

export default SingleSelectDd;

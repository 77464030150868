import React from 'react'

const InfoIconWarning = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
<path d="M6.99967 13.6667C8.76778 13.6667 10.4635 12.9643 11.7137 11.7141C12.964 10.4638 13.6663 8.76815 13.6663 7.00004C13.6663 5.23193 12.964 3.53624 11.7137 2.286C10.4635 1.03575 8.76778 0.333374 6.99967 0.333374C5.23156 0.333374 3.53587 1.03575 2.28563 2.286C1.03539 3.53624 0.333008 5.23193 0.333008 7.00004C0.333008 8.76815 1.03539 10.4638 2.28563 11.7141C3.53587 12.9643 5.23156 13.6667 6.99967 13.6667ZM5.99967 10C5.99967 9.73482 6.10503 9.48047 6.29257 9.29293C6.4801 9.1054 6.73446 9.00004 6.99967 9.00004C7.26489 9.00004 7.51924 9.1054 7.70678 9.29293C7.89432 9.48047 7.99967 9.73482 7.99967 10C7.99967 10.2653 7.89432 10.5196 7.70678 10.7071C7.51924 10.8947 7.26489 11 6.99967 11C6.73446 11 6.4801 10.8947 6.29257 10.7071C6.10503 10.5196 5.99967 10.2653 5.99967 10ZM6.34367 3.54671C6.37151 3.393 6.45243 3.25394 6.57232 3.15381C6.69222 3.05367 6.84346 2.99882 6.99967 2.99882C7.15588 2.99882 7.30713 3.05367 7.42703 3.15381C7.54692 3.25394 7.62784 3.393 7.65567 3.54671L7.66634 3.66671V7.00004L7.65567 7.12004C7.62784 7.27375 7.54692 7.41281 7.42703 7.51294C7.30713 7.61307 7.15588 7.66793 6.99967 7.66793C6.84346 7.66793 6.69222 7.61307 6.57232 7.51294C6.45243 7.41281 6.37151 7.27375 6.34367 7.12004L6.33301 7.00004V3.66671L6.34367 3.54671Z" fill="#F9A153"/>
</svg>
  )
}

export default InfoIconWarning

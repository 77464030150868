import SideNavbar from "../../components/common/navbar/SideNavbar";
import { Link } from "react-router-dom";
import InfoIconWarning from "img/svg/InfoIconWarning";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { ToastContainer, toast } from "react-toastify";
import InfoIconLight from "img/svg/InfoIconLight";
import ChevronDownIcon from "../../img/svg/chevronDownIcon";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import InfoIconPrimary from "img/svg/InfoIconPrimary";
import InfoIconDanger from "img/svg/InfoIconDanger";
import MultiSelectDd from "templates/components/MultiSelectDd";
import SingleSelectDd from "templates/components/SingleSelectDd";
import { useState } from "react";
import TrashIcon from "img/svg/TrashIcon";
import TrashIconSmall from "img/svg/TrashIconSmall";
import CustomTooltip from "components/common/CustomTooltip";
import HelpIcon from "img/svg/helpIcon";
import MapIframeDummy from "templates/components/MapIframeDummy";
import LimitedFeaturesModal from "templates/components/LimitedFeaturesModal";
import  Spinner from 'components/common/Spinner';

// chartjs data
const doorData = {
  labels: ["June", "July", "August", "September"],
  datasets: [
    {
      label: "Voter contact goal",
      backgroundColor: "rgba(134, 82, 163, 1)",
      borderColor: "rgba(134, 82, 163, 1)",
      borderWidth: 1,
      borderRadius: 2,
      hoverBackgroundColor: "rgba(134, 82, 163, 0.8)",
      hoverBorderColor: "rgba(134, 82, 163, 1)",
      data: [400, 200, 900, 750],
      barThickness: 14,
    },
    {
      label: "Voter contacted",
      backgroundColor: "rgba(224, 192, 249, 1)",
      borderColor: "rgba(224, 192, 249, 1)",
      borderWidth: 1,
      borderRadius: 2,
      hoverBackgroundColor: "rgba(224, 192, 249, .8)",
      hoverBorderColor: "rgba(224, 192, 249, 1)",
      data: [800, 600, 650, 450],
      barThickness: 14,
    },
  ],
  
  options: {
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          display: false,
          
        }
      }
    }
  },
};


const VoterDashboard = () => {
  const notify = () => {
    // toast("Wow so easy!");

    toast.error("Connection lost !", {
      className: "snackBar",
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "dark",
      hideProgressBar: true,
      autoClose: 5000,
      icon: <InfoIconLight />,
    });
  };

  const [isListVisible, setListVisible] = useState(false);

  let turfTooltip = `Generate lists of voters to reach door-to-door.`
  let activateUniverseTooltip = `Activating this universe will allow your campaign to target this group of voters door-to-door.`

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="row">
            <div className="col-md-12">
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <InfoIconWarning />{" "}
                <span className="ms-2">Voter list will be uploaded soon. </span>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                >
                  <span className="icon-close"></span>
                </button>
              </div>
            </div>
          </div>

          {/* Cards */}
          <div className="row">
            <div className="col-md-9">
              <div className="row h-100">
                <div className="col-md-6">
                  <div className="card card-flush h-100 shadow-2">
                    <div className="card-header">
                      <h4 className="card-title"> Contact Voter</h4>
                      <p className="text-muted">
                      Contact reach compare to contact goal of last 4 weeks.
                      </p>
                    </div>
                    <div className="card-body">
                      <div className="canvas-container">
                          <Bar
                            data={doorData}
                            width={100}
                            height={66}
                          />
                        </div>

                      {/* <div className="noData d-flex gap-2 flex-row align-items-center">
                        <InfoIconSecondary />
                        <p className="m-0 text-muted fs-7">
                          {" "}
                          No tasks available for today{" "}
                        </p>
                      </div> */}
                      
                      {/* chart placeholder */}
                      {/* <div className="placeholder-glow h-100">
                        <div className="placeholder col-12 h-100"></div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card card-flush h-100 shadow-2">
                    <div className="card-header">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex gap-2 align-items-center">
                            <h4 className="card-title"> Turf</h4>
                            <CustomTooltip content={turfTooltip}>
                              <HelpIcon />
                            </CustomTooltip>
                          </div>
                          <p className="body-4 text-muted mb-0 lh-1">
                            Manage voter turf and walk lists.
                          </p>
                        </div>

                        <div className="col-auto">
                          <button
                            className="btn btn-info rounded-pill btn-xs"
                            data-bs-target="#manageTurfModal"
                            data-bs-toggle="modal"
                          >
                            {" "}
                            Manage turf
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body position-relative map-overla">
                      {/* <MapIframeDummy width="100%" height="285px" /> */}

                      {/* <div className="universeList-absolute">
                        <div className="universeList border-0 shadow-6 pe-none">
                          if voter list is added
                          <div className="universeList-body">
                          <div className="universeList-title">
                            <span className="body-4"> Contact all Voters Door-to-Door </span> 
                          

                          </div>

                          <div className="universeList-info">
                            <span className="fs-8 text-muted"> All voters </span>
                            <span className="dot dot-sm bg-neutral-n3 mx-1"> </span>
                            <span className="fs-8 text-muted"> 0 Turfs </span>
                            <span className="dot dot-sm bg-neutral-n3 mx-1"> </span>
                            <span className="fs-8 text-muted"> 0 Doors </span>
                            
                            
                          </div>

                        </div>

                         
                        </div>
                      </div> */}

                       {/* if voter list is not added */}
                       <div className="noData d-flex gap-2 flex-row align-items-center">
                            <InfoIconSecondary />{" "}
                            <p className="m-0 text-muted fs-7">
                              No voter list uploaded by admin.{" "}
                            </p>
                          </div>
                      
                      {/* map placeholder */}
                      {/* <div className="placeholder-glow h-100">
                        <div className="placeholder col-12 h-100"></div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card card-flush h-100 shadow-2">
                <div className="card-header contactCard-header">
                  <div className="iconBox bg-neutral-n4">
                    <img
                      src={require("../../img/patriotism.png")}
                      className="img-fluid"
                      width={32}
                      height={32}
                      alt=""
                    />
                  </div>
                  <h4 className="card-title text-center"> Contact Voters</h4>
                  <p className="text-muted text-center">
                    Reach out to your voters here
                  </p>
                </div>

                <div className="card-body">
                  <ul className="list-unstyled">
                    <li className="contactOption-item contactOption-item-disabled">
                      <Link to="" onClick={notify}>
                        <div className="contactOption-item-content">
                          <div className="contactOption-item-content-title">
                            By Phone
                            <p className="m-0">Contact voter by Phone</p>
                          </div>
                        </div>
                        <div className="contactOption-item-link">
                          <span className="icon-arrow-right"></span>
                        </div>
                      </Link>
                    </li>

                    <li className="contactOption-item contactOption-item-disabled">
                      <Link to="">
                        <div className="contactOption-item-content">
                          <div className="contactOption-item-content-title">
                            Door to Door
                            <p className="m-0">Talk to voter on Door</p>
                          </div>
                        </div>
                        <div className="contactOption-item-link">
                          <span className="icon-arrow-right"></span>
                        </div>
                      </Link>
                    </li>
                    
                    {/* button placeholder */}
                    {/* <li className="placeholder-glow mb-3">
                      <div className="placeholder col-12 py-4"></div>

                    </li>
                    <li className="placeholder-glow">
                      <div className="placeholder col-12 py-4"></div>

                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* table */}
          <div className="row mt-5">
            <div className="col-12">
              <div className="card card-flush shadow-2">
                <div className="card-header d-flex justify-content-between">
                  <div className="d-flex gap-3">
                    <h4 className="card-title"> Recent voters contact</h4>

                    <div className="dropdown">
                      <button
                        className="btn btn-outline-secondary btn-xxs bg-white"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Door to Door
                        <span className="ps-2">
                          <ChevronDownIcon />
                        </span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            Door to Door{" "}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            By Phone{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="card-toolBox">
                    <Link to="/voters/list" className="btn-xxs fw-bold">
                      {" "}
                      View All{" "}
                    </Link>
                  </div>
                </div>

                <div className="card-body table-responsive pb-1 px-0">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Address</th>
                        <th scope="col">Voter Status</th>
                        <th scope="col">Level of Support </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Marvin McKinney</td>
                        <td>1908 E 10 St. Austin TX</td>
                        <td>-</td>
                        <td>
                          {" "}
                          <span className="badge badge-success rounded-pill">
                            {" "}
                            Moderate Support{" "}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Jacob Jones</td>
                        <td>1908 E 10 St. Austin TX</td>
                        <td>-</td>
                        <td>
                          <span className="badge badge-success rounded-pill">
                            {" "}
                            Moderate Support{" "}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Jacob Jones</td>
                        <td>1908 E 10 St. Austin TX</td>
                        <td>
                          <span className="badge badge-secondary rounded-pill">
                            {" "}
                            Deceased{" "}
                          </span>
                        </td>
                        <td>- </td>
                      </tr>
                      <tr>
                        <td>Jacob Jones</td>
                        <td>1908 E 10 St. Austin TX</td>
                        <td>-</td>
                        <td>
                          <span className="badge badge-success rounded-pill">
                            {" "}
                            Moderate Support{" "}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Jacob Jones</td>
                        <td>1908 E 10 St. Austin TX</td>
                        <td>-</td>
                        <td>
                          <span className="badge badge-success rounded-pill">
                            {" "}
                            Moderate Support{" "}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* <div className="noData d-flex gap-2 flex-row align-items-center py-7">
                        <InfoIconSecondary />{" "}
                        <p className="m-0 text-muted fs-7"> No tasks available for today </p>
                      </div> */}
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* manage turf Modal */}
        <div
          className="modal fade"
          id="manageTurfModal"
          data-bs-backdrop="static"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">
                  {" "}
                  {isListVisible ? "Add universe" : "Choose universe"}
                </h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                {isListVisible ? (
                  <>
                    <p className="">
                      Add universe to create the target groups for door-to-door
                      voter contact.
                    </p>
                    <form action="">
                      <div className="mb-3 required">
                        <label
                          htmlFor="exampleInputName1"
                          className="form-label"
                        >
                          Universe name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputName1"
                          aria-describedby="nameHelp"
                          placeholder="Enter universe name"
                        />
                      </div>

                      <div className="mb-3 required">
                        <label
                          htmlFor="exampleInputPhone"
                          className="form-label"
                        >
                          Filter
                        </label>
                        <SingleSelectDd placeholder="Choose filter" />
                      </div>

                      <div className="mb-3 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label className="body-4 me-1" htmlFor="exampleCheck1">
                          Activate and continue this universe.
                        </label>
                        <CustomTooltip content={activateUniverseTooltip}>
                            <HelpIcon />
                          </CustomTooltip>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    {/* default universe list */}


                    <p className="mb-4">
                      Select a universe to continue. Only active universe changes affect
                      door-to-door canvassing.
                    </p>
                    <span className="body-4 text-muted d-block"> Universe list</span>

                    {/* universe list skeleton */}
                    <div className="placeholder-glow">
                      <div className="placeholder col-12 pt-5 pb-4 mb-3"></div>
                      <div className="placeholder col-12 pt-5 pb-4 mb-3"></div>
                    </div>

                    <div className="universeList active">
                      <div className="universeList-indicator"></div>
                      <div className="universeList-body">
                        <div className="universeList-title">
                          <span className="body-4">
                            {" "}
                            Default universe: All Voters{" "}
                          </span>
                          <span className="badge bg-success rounded-pill px-2 font-regular">
                            {" "}
                            Active{" "}
                          </span>
                        </div>

                        <div className="universeList-info">
                          <span className="fs-8 text-muted"> All voters </span>
                          <span className="dot dot-sm bg-neutral-n3 mx-1">
                            {" "}
                          </span>
                          <span className="fs-8 text-muted">
                            {" "}
                             Total Doors: 289{" "}
                          </span>
                          <span className="dot dot-sm bg-neutral-n3 mx-1">
                            {" "}
                          </span>
                          <span className="fs-8 text-muted">
                            {" "}
                             Total Voters: 400{" "}
                          </span>
                          <span className="dot dot-sm bg-neutral-n3 mx-1">
                            {" "}
                          </span>
                          <span className="fs-8 text-muted"> Turfs: 0 </span>
                        </div>
                      </div>
                      <div className="universeList-action">
                        <button
                          className="btn btn-link no-hover btn-xs px-0 py-0"
                          disabled
                        >
                          Activate <Spinner varient="text-primary"/>
                        </button>
                        <button
                          className="btn btn-link no-hover btn-xs px-0 py-0"
                          disabled
                        >
                          {" "}
                          <TrashIconSmall />
                        </button>
                      </div>
                    </div>

                    {/* custom universe list */}
                    <div className="universeList">
                      <div className="universeList-indicator"></div>
                      <div className="universeList-body">
                        <div className="universeList-title">
                          <span className="body-4">
                            Republican Voter Contact{" "}
                          </span>
                        </div>

                        <div className="universeList-info">
                          <span className="fs-8 text-muted"> All voters </span>
                          <span className="dot dot-sm bg-neutral-n3 mx-1">
                            {" "}
                          </span>
                          <span className="fs-8 text-muted">
                            {" "}
                             Total Doors: 289{" "}
                          </span>
                          <span className="dot dot-sm bg-neutral-n3 mx-1">
                            {" "}
                          </span>
                          <span className="fs-8 text-muted">
                            {" "}
                             Total Voters: 400{" "}
                          </span>
                          <span className="dot dot-sm bg-neutral-n3 mx-1">
                            {" "}
                          </span>
                          <span className="fs-8 text-muted"> Turfs: 0 </span>
                        </div>
                      </div>
                      <div className="universeList-action">
                        <button className="btn btn-link no-hover btn-xs px-0 py-0">
                          Activate
                        </button>
                        <button
                          className="btn btn-link no-hover btn-xs px-0 py-0"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteTurfModal"
                        >
                          {" "}
                          <TrashIconSmall />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="modal-footer justify-content-between border-1">
                {isListVisible ? (
                  <>
                    <button
                      className="btn btn-link text-primary rounded-pill btn-xs"
                      onClick={() => setListVisible(false)}
                    >
                      Back
                    </button>

                    <div>
                      <Link
                        to=""
                        className="btn btn-link text-primary rounded-pill btn-xs me-1"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </Link>
                      <Link
                        to=""
                        className="btn btn-primary rounded-pill btn-xs px-6"
                      >
                        Add universe
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <Link
                      to=""
                      onClick={() => setListVisible(true)}
                      className="btn btn-link text-primary rounded-pill btn-xs d-flex align-items-center d-none d-md-block"
                    >
                      <span className="icon-plus me-1"></span> Add universe
                    </Link>

                    <div className="universe-btns">
                      <Link
                        to=""
                        className="btn btn-link text-primary rounded-pill btn-xs me-1"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </Link>
                      <Link
                        to=""
                        className="btn btn-primary rounded-pill btn-xs px-6"
                      >
                        Continue
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* delete turf Modal  */}
        <div
          className="modal fade"
          id="deleteTurfModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body text-center px-4">
                <img
                  src={require("../../img/leave.png")}
                  width={66}
                  height={56}
                  alt="leave alert"
                />
                <h2 className="mt-3"> Do you want to delete this universe? </h2>
                <p>
                  By deleting the universe 'Republican Voters Contact, all turf
                  for this universe will be deleted.
                </p>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-link rounded-pill btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>

                <button
                  className="btn btn-primary rounded-pill btn-xs"
                  onClick={notify}
                  data-bs-toggle="modal"
                  data-bs-target="#manageTurfModal"

                >
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>

       <LimitedFeaturesModal />
      </div>

      <div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default VoterDashboard;

import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useRef,
  useEffect,
} from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import toastNotify from "utils/toastNotify";
import axios from "axios";
import { v4 as uuidv } from "uuid";
import { CANDIDATE_ID } from "components/pages/Homepage";
import CreateAuthAxiosInstance from "utils/authAxios";
import { globalQueryConfig } from "utils/reactQuery";
import { findDaysUntilElection } from "utils/getDayUntilElection";
import { useLocation } from "react-router-dom";
import { getTodayDateInNormalFormat } from "utils/formatDate";
// const CHAT_SERVER_URL = "http://127.0.0.1:8000/chat";
// const CHAT_SERVER_URL = process.env.REACT_APP_CONVERSATION_BASE_URL;
const CHAT_SERVER_URL = process.env.REACT_APP_CONVERSATION_BASE_URL + "/chat";
const TOP_PRIORITIES_CHAT_URL =
  process.env.REACT_APP_CONVERSATION_BASE_URL + "/priority_response";

interface IChatHistoryData {
  chats: Array<{
    id: number;
    query: string;
    response: string;
    rating: number | null;
    sessionId: string | null
  }>;
  date: string;
}
interface ConversationalAIProps {
  query: string;
  setQuery: (query: string) => void;
  onGeneratePrompt: () => void;
  handlePromptInput: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  conversations: ConversationsState[];
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isGenerating: boolean;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  chatHistoryData: Array<IChatHistoryData>;
  activeChatDateIndex: number;
  setActiveChateDateIndex: React.Dispatch<React.SetStateAction<number>>;
  activeChatId: number;
  setActiveChatId: React.Dispatch<React.SetStateAction<number>>;
  rating: number | null;
  setRating: React.Dispatch<React.SetStateAction<number | null>>;
  chatHistoriesLimit: number;
  setChatHistoriesLimit: React.Dispatch<React.SetStateAction<number>>;
  totalChatHistories: number;
  setTotalChatHistories: React.Dispatch<React.SetStateAction<number>>;
  chatDivRef?: React.MutableRefObject<HTMLDivElement | null>;
  setSessionId: React.Dispatch<React.SetStateAction<string | undefined>>;
  electionInfo: IElectionInfo | undefined;
  sendButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  setConversations: React.Dispatch<React.SetStateAction<ConversationsState[]>>;
  isLoading: Boolean;
}
interface ConversationalAIProviderProps {
  children: ReactNode;
}
interface ConversationsState {
  id: string;
  prompt: string;
  response: string;
  isGenerating: boolean;
  rating: number | null;
  isError?: boolean;
  chatId?: number;
}
interface IConversationChatHistory {
  query: string;
  response: string;
}

interface IElectionInfo {
  fullName: string;
  positionTitle: string;
  electionPosition: string;
  daysUntilElection: number;
}

const ConversationalAIContext = createContext<
  ConversationalAIProps | undefined
>(undefined);

export const CHAT_HISTORIES_LIMIT = 20;

export const ConversationalAIProvider: React.FC<
  ConversationalAIProviderProps
> = ({ children }) => {
  const [query, setQuery] = useState("");
  const [conversations, setConversations] = useState<ConversationsState[]>([]);
  const [chatHistoryData, setChatHistoryData] = useState<IChatHistoryData[]>(
    []
  );
  const [currentId, setCurrentId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuerySubmitted, setIsQuerySubmitted] = useState(false);
  const chatDivRef = useRef<HTMLDivElement | null>(null);
  const sendButtonRef = useRef<HTMLButtonElement | null>(null);

  const [isGenerating, setIsGenerating] = useState(false);
  const [activeChatDateIndex, setActiveChateDateIndex] = useState(-1);
  const [activeChatId, setActiveChatId] = useState<number>(-1);
  const [totalChatHistories, setTotalChatHistories] = useState<number>(0);
  const [sessionId, setSessionId] = useState<string>();
  const [
    isFirstTimeRequestingForTopPriorities,
    setIsFirstTimeRequestingForTopPriorities,
  ] = useState(true);

  const [rating, setRating] = useState<number | null>(null);
  const [chatHistoriesLimit, setChatHistoriesLimit] =
    useState<number>(CHAT_HISTORIES_LIMIT);

  const authAxios = CreateAuthAxiosInstance();
  const location = useLocation();

  const userId = localStorage.getItem(CANDIDATE_ID);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [filteredChatHistory, setFilteredChatHistory] = useState<
    IConversationChatHistory[]
  >([]);

  useEffect(() => {
    if (chatHistoryData?.length) {
      const sameSessionChatHistory = chatHistoryData
        ?.find(item => item?.date === getTodayDateInNormalFormat())
        ?.chats
        ?.sort((a, b) => a.id - b.id)
        ?.slice(-2)
        ?.filter(item => item?.sessionId === sessionId)
        || []

      const filteredChats = sameSessionChatHistory.map((item) => ({
        query: item.query || '',
        response: item.response || '',
      }));
      setFilteredChatHistory(filteredChats);
    }
  }, [chatHistoryData]);

  async function generatePrompt() {
    const prompt = {
      question: query,
      user_id: userId,
      chat_history: filteredChatHistory ?? [],
    };
    const auth = {
      username: "campaign_brain",
      password: "12345",
    };
    if (CHAT_SERVER_URL) {
      const api =
        isFirstTimeRequestingForTopPriorities &&
          location?.state?.isTopPriorities
          ? TOP_PRIORITIES_CHAT_URL
          : CHAT_SERVER_URL;

      const { data } = await axios.post(api, prompt, { auth });
      return data;
    }
  }

  const postChatHistory = async (content: string) => {
    try {
      const res = await authAxios.post("/api/v1/chat-histories", {
        candidateId: userId,
        query,
        response: content,
        rating: null,
        sessionId,
      });
      updateConversation(content, res?.data?.id);
      setQuery("");
      setSessionId(res?.data?.sessionId);
    } catch (e) {
      console.log(e);
    } finally {
      setIsQuerySubmitted(true);
    }
  };

  const updateChatFeedback = async () => {
    if (activeChatId !== -1) {
      try {
        const res = await authAxios.patch(
          `/api/v1/chat-histories/${activeChatId}`,
          {
            rating: rating,
          }
        );
        // Find and replace the rating of active chat
        const chatHistoryDataCopy = chatHistoryData.slice();
        const activeChatsIndex =
          activeChatDateIndex === -1 ? 0 : activeChatDateIndex;
        const indexOfActiveChat = chatHistoryDataCopy[
          activeChatsIndex
        ]?.chats.findIndex((item) => item.id === res.data.id);
        let activeChat =
          chatHistoryDataCopy[activeChatsIndex]?.chats[indexOfActiveChat];
        if (activeChat) {
          activeChat = { ...activeChat, rating: res.data.rating };
          chatHistoryDataCopy[activeChatsIndex].chats[indexOfActiveChat] =
            activeChat;
          setChatHistoryData(chatHistoryDataCopy);
        }

        // Find and replace the rating of active conversation
        const conversationsCopy = conversations.slice();
        const activeConversationIndex = conversationsCopy.findIndex(
          (item) => item.chatId === res.data.id
        );
        let activeConversation = conversationsCopy[activeConversationIndex];
        if (activeConversation) {
          activeConversation = {
            ...activeConversation,
            rating: res.data.rating,
          };
          conversationsCopy[activeConversationIndex] = activeConversation;
          setConversations(conversationsCopy);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    updateChatFeedback();
  }, [rating, activeChatId]);

  const getChatHistory = async () => {
    const res = await authAxios.get(
      `/api/v1/chat-histories/datewise?limit=${chatHistoriesLimit}`
    );
    setChatHistoryData(res.data.items);
    setTotalChatHistories(res.data.totalItems);
    return res.data;
  };

  const {
    // data: chatHistory,
    // isLoading: isChatHistoryLoading,
    refetch: refetchChatHistory,
  } = useQuery({
    queryKey: ["ChatHistory"],
    queryFn: getChatHistory,
    ...globalQueryConfig,
  });

  useEffect(() => {
    (async () => {
      if (isQuerySubmitted || chatHistoriesLimit !== CHAT_HISTORIES_LIMIT) {
        await refetchChatHistory();
        setIsQuerySubmitted(false);
      }
    })();
  }, [isQuerySubmitted, chatHistoriesLimit]);

  const updateConversation = (content: string, chatId?: number) => {
    const updatedConversation = conversations.map((conv) => {
      let tempConversation;
      if (conv.id === currentId) {
        tempConversation = {
          ...conv,
          isGenerating: false,
          response: content,
          isError: content ? false : true,
          chatId,
        };
      } else {
        tempConversation = conv;
      }
      return tempConversation;
    });
    setConversations(updatedConversation);
  };

  const addConversation = () => {
    const id = uuidv();
    setCurrentId(id);
    const newConversation: ConversationsState = {
      id,
      prompt: query,
      isGenerating: true,
      response: "",
      rating: null,
    };

    setConversations((prevConversation) => [
      ...prevConversation,
      newConversation,
    ]);
  };

  const { mutate: onGeneratePrompt } = useMutation({
    mutationFn: generatePrompt,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["UpdateCandidateData"],
      });
    },
    onError: (error: any) => {
      toastNotify("error", "prompt failed.");
      console.log("error occured", error);
      updateConversation("");
      setIsGenerating(false);
    },
    onMutate: () => {
      setIsGenerating(true);
      addConversation();
    },
    onSettled: (res) => {
      setIsGenerating(false);
      setIsFirstTimeRequestingForTopPriorities(false);
      postChatHistory(res.content);
      console.log("conversation", conversations);
    },
  });

  const handlePromptInput = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setQuery(val);
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset height to auto
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px"; // Set the height to the scrollHeight
    }
  };

  const getElectionInfo = async () => {
    const res = await authAxios.get(`/api/v1/candidate/election`);
    const electionDate = res?.data?.electionDate;
    const daysUntilElection: number = findDaysUntilElection(electionDate);
    const electionData: IElectionInfo | undefined = {
      fullName: res?.data?.fullName,
      positionTitle: res?.data?.positionTitle,
      electionPosition: res?.data?.electionPosition,
      daysUntilElection,
    };
    return electionData;
  };

  const {
    data: electionInfo,
    isLoading,
  } = useQuery({
    queryKey: ["electionInfo"],
    queryFn: getElectionInfo,
    ...globalQueryConfig,
  });


  return (
    <ConversationalAIContext.Provider
      value={{
        query,
        setQuery,
        onGeneratePrompt,
        handlePromptInput,
        conversations,
        chatDivRef,
        isModalOpen,
        setIsModalOpen,
        isGenerating,
        textAreaRef,
        chatHistoryData,
        activeChatDateIndex,
        setActiveChateDateIndex,
        activeChatId,
        setActiveChatId,
        rating,
        setRating,
        chatHistoriesLimit,
        setChatHistoriesLimit,
        totalChatHistories,
        setTotalChatHistories,
        setSessionId,
        electionInfo,
        sendButtonRef,
        setConversations,
        isLoading
    
      }}
    >
      {children}
    </ConversationalAIContext.Provider>
  );
};

// create custom hooks for using the context
export const useConversationalAI = () => {
  const context = useContext(ConversationalAIContext);
  if (!context)
    throw new Error(
      "useConvsersationalAI hook must be used within the ConversaltionalContextProvider"
    );
  return context;
};

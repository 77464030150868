import { Link } from "react-router-dom";

const EditModal = () => {
  return (
    <div
      className="modal fade"
      id="editBudgetModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close p-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span className="icon-close text-n1 h2"></span>
            </button>
          </div>

          <div className="modal-body text-center px-4">
            <img
              src={require("../../../../img/leave.png")}
              width={66}
              height={56}
              alt="leave alert"
            />
            <h2 className="mt-3">Do you want to change the budget?</h2>
            <p>
              By changing the budget, the details in the current budget will get
              replaced by the new one.
            </p>
          </div>

          <div className="modal-footer">
            <Link
              to=""
              className="btn btn-link text-primary btn-xs rounded-pill"
              data-bs-dismiss="modal"
            >
              Cancel
            </Link>
            {/* remove button disabled class after spinner goes off */}
            <Link
              to=""
              className="btn btn-primary rounded-pill btn-xs px-6"
              data-bs-toggle="modal"
              data-bs-target="#uploadModal"
            >
              Yes, change
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;

import Select from "react-select";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import { IAddUniverseFormInput } from "./AddTurfModal";
import { useMemo } from "react";

interface ISingleSelectProps {
  control: Control<IAddUniverseFormInput, any>
  errors: FieldErrors<IAddUniverseFormInput>
}

interface IUniverseFilter {
  filterType: string,
  votersCount: number
}

const SingleSelectDd = ({ control, errors }: ISingleSelectProps) => {
  const authAxios = CreateAuthAxiosInstance();

  const {
    data: universeFilterList,
    refetch: refetchUniverseFilterList,
    isFetching: isFetchingUniverseFilterList
  } = useQuery({
    queryKey: ["universeFilterList"],
    queryFn: () => authAxios('api/v1/universes/filters').then(data => data.data),
    refetchOnWindowFocus: false
  });
  // console.log("universeFilterList",universeFilterList);
  

  //For react select, selected option highlight
  const transformedOptions = useMemo(() => {
    if (universeFilterList?.length) {
      return universeFilterList.map((item: IUniverseFilter) => ({ ...item, value: item.filterType }))
    }
  }, [universeFilterList])
// console.log("transformedOptions",transformedOptions);


  return (
    <>
      <Controller
        name='filterTypeObj'
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            options={transformedOptions}
            formatOptionLabel={CustomDdList}
            menuPosition="fixed"
            placeholder={<div>Choose voters</div>}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      {!!(errors?.filterTypeObj?.filterType?.message) &&
        <span className="invalidInput form-text text-danger mt-9">
          Voter Filter is required !
        </span>
      }
    </>
  );
};

export default SingleSelectDd;

const CustomDdList = (
  { filterType, votersCount }: { filterType: string, votersCount: number },
  { context }: { context: string }
) => {
  
  return (
    <div className="customDDList">
      {context === 'menu' &&
        <img
          src={require("../../../../../../img/avatar.png")}
          className="img-fluid"
          alt=""
        />
      }
      <div className="customDDList-body">
        <span className="customDDList-title">{filterType}</span>
        {context === 'menu' &&
          <span className="customDDList-subtitle">{votersCount}</span>
        }
      </div>
    </div>
  );
};
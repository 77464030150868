import NoData from "img/svg/noData";
import { Link } from "react-router-dom";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import HelpIcon from "img/svg/helpIcon";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Tooltip } from "bootstrap";
import { useEffect } from "react";
import SideNavbar from "components/common/navbar/SideNavbar";

// next arrow

function NextArrow(props: any) {
  const { nextArrowType, className, style, onClick } = props;
  return (
    <div
      className={`${nextArrowType} ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
function PrevArrow(props: any) {
  const { prevArrowType, className, style, onClick } = props;
  return (
    <div
      className={`${prevArrowType} ${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const VoterContactPlan = () => {
  const sliderSettings = {
    slidesToShow: 3.8,
    slidesToScroll: 2,
    cssEase: "linear",
    infinite: false,
    mobileFirst: true,
    prevArrow: <PrevArrow prevArrowType={"prev-slide"} />,
    nextArrow: <NextArrow nextArrowType={"next-slide"} />,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  // tooltip
  const attemptTooltip = `hello <br /> <span className="tooltip-text">Note: adsaå </span> `;

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-box">
                  <h3 className="section-box-title">Campaign Summary </h3>
                </div>
              </div>
            </div>

            {/* Cards */}
            <div className="row">
              <div className="col-md-3 col-6 mb-3">
                <div className="card card-flush h-100 shadow-2">
                  <div className="card-header d-flex justify-content-between">
                    <h4 className="card-title">
                      {" "}
                      Total Voter Contact Attempts
                    </h4>
                    <div className="card-info">
                      <Link
                        to=""
                        data-bs-html="true"
                        data-bs-custom-class="custom-tooltip"
                        className="text-left"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title={attemptTooltip}
                      >
                        {" "}
                        <HelpIcon />{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-flex align-items-end">
                      <h1 className="mb-0 me-2">0</h1>{" "}
                      <span className="text-muted">voters</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-6 mb-3">
                <div className="card card-flush h-100 shadow-2">
                  <div className="card-header d-flex justify-content-between">
                    <h4 className="card-title"> Total Voter Contacts </h4>
                    <div className="card-info">
                      <Link
                        to=""
                        data-bs-html="true"
                        data-bs-custom-class="custom-tooltip"
                        className="text-left"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Total Voter Contacts shows how many successful conversations you have had with target voters. Each voter that you successfully reach is shown in the numerator, while the denominator is the goal for your entire campaign."
                      >
                        {" "}
                        <HelpIcon />{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-flex align-items-end">
                      <h1 className="mb-0 me-2">0 /</h1>
                      <h1 className="mb-0 me-2 text-muted">871</h1>{" "}
                      <span className="text-muted">voters</span>
                    </div>
                    <div
                      className="progress mt-3"
                      role="progressbar"
                      aria-label="task progress"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ height: 4 + "px" }}
                    >
                      <div
                        className="progress-bar"
                        style={{ width: " 25%" }}
                      ></div>
                    </div>
                    <p className="m-0 text-muted fs-7 mt-1 fw-bold">
                      <span> 0%</span> to your voter contact goal{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-6 mb-3">
                <div className="card card-flush h-100 shadow-2">
                  <div className="card-header d-flex justify-content-between">
                    <h4 className="card-title"> Contact Rate</h4>
                    <div className="card-info">
                      <Link
                        to=""
                        data-bs-html="true"
                        data-bs-custom-class="custom-tooltip"
                        className="text-left"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Contact Rate shows the percentage of door knocks that result in a conversation with the target voter. Calculated as Contacts / Attempts. A contact rate at doors typically hovers between 15 - 30%."
                      >
                        {" "}
                        <HelpIcon />{" "}
                      </Link>
                    </div>
                  </div>

                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-flex align-items-end">
                      <h1 className="mb-0 me-2">0</h1>{" "}
                      <span className="text-muted">voters</span>
                    </div>

                    <p className="m-0 text-muted fs-7 mt-1 fw-bold">
                      {" "}
                      Door to Door contact{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-6 mb-3">
                <div className="card card-flush h-100 shadow-2">
                  <div className="card-header d-flex justify-content-between">
                    <h4 className="card-title"> Contact Rate</h4>
                    <div className="card-info">
                      <Link
                        to=""
                        data-bs-html="true"
                        data-bs-custom-class="custom-tooltip"
                        className="text-left"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Contact Rate shows the percentage of phone calls that result in a conversation with the target voter. Calculated as Contacts / Attempts."
                      >
                        {" "}
                        <HelpIcon />{" "}
                      </Link>
                    </div>
                  </div>

                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-flex align-items-end">
                      <h1 className="mb-0 me-2">0%</h1>{" "}
                      <span className="text-muted">voters</span>
                    </div>

                    <p className="m-0 text-muted fs-7 mt-1 fw-bold">
                      {" "}
                      By Phone contact{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <div className="section-box">
                  <div className="d-flex gap-2 align-items-center">
                    <h3 className="section-box-title"> Weekly Contact Plan </h3>
                    <div className="card-info">
                      <Link
                        to=""
                        data-bs-html="true"
                        data-bs-custom-class="custom-tooltip"
                        className="text-left"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Here you can see your weekly progress for this week. In the numerator, you can see how many attempts you have made so far this week. In the denominator, you can see the target goal of contacts for the week. Below, you can see an estimate for how many hours are required to achieve the goal for the week for current week."
                      >
                        {" "}
                        <HelpIcon />{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card card-flush shadow-2">
                  <div className="card-body p-0">
                    <div className="contactPlan">
                      <div className="contactPlan__legend">
                        <div className="contactPlan__legend-item">
                          <div></div>
                          <div> Door to Door </div>
                          <div>By Phone</div>
                        </div>
                      </div>
                      <div className="contactPlan__carousel">
                        <Slider {...sliderSettings}>
                          <div className="contactPlan__carousel-item">
                            <div className="performance-card">
                              <span className="performance-card__datetime">
                                <span className="text-muted"> Aug </span>
                                <br />8 - 14
                              </span>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status active">
                                <div className="contactPlan__cell-status-header">
                                  <h4> 76 </h4> <span> attempts </span>
                                </div>
                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {" "}
                                    34 Total Contacts{" "}
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      {" "}
                                      <i className="icon-clock"></i> 5.7hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status active">
                                <div className="contactPlan__cell-status-header">
                                  <h4> 104 </h4> <span> attempts </span>
                                </div>
                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {" "}
                                    23 Total Contacts{" "}
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      {" "}
                                      <i className="icon-clock"></i> 5.7hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="contactPlan__carousel-item">
                            <div className="performance-card">
                              <span className="performance-card__datetime">
                                <span className="text-muted"> Aug </span>
                                <br />
                                15 - 21
                              </span>

                              {/* this week */}
                              <div className="current-week">
                                <span className="badge badge-success">
                                  This week
                                </span>
                              </div>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status active has-started">
                                <div className="contactPlan__cell-status-header">
                                  <h4>
                                    {" "}
                                    0 / <span> 229 </span>{" "}
                                  </h4>{" "}
                                  <span> attempts </span>
                                </div>
                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {" "}
                                    20 Total Contacts{" "}
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      {" "}
                                      <i className="icon-clock"></i> 5.7hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status active has-started">
                                <div className="contactPlan__cell-status-header">
                                  <h4>
                                    {" "}
                                    0 / <span> 186 </span>{" "}
                                  </h4>{" "}
                                  <span> attempts </span>
                                </div>
                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {" "}
                                    20 Total Contacts{" "}
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      {" "}
                                      <i className="icon-clock"></i> 5.7hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="contactPlan__carousel-item">
                            <div className="performance-card">
                              <span className="performance-card__datetime">
                                <span className="text-muted"> Aug </span>
                                <br />
                                22 - 28
                              </span>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status active has-started">
                                <div className="contactPlan__cell-status-header">
                                  <h4> 171</h4> <span> attempts </span>
                                </div>
                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {" "}
                                    20 Total Contacts{" "}
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      {" "}
                                      <i className="icon-clock"></i> 5.7hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status active has-started">
                                <div className="contactPlan__cell-status-header">
                                  <h4> 129 </h4> <span> attempts </span>
                                </div>
                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {" "}
                                    20 Total Contacts{" "}
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      {" "}
                                      <i className="icon-clock"></i> 5.7hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="contactPlan__carousel-item">
                            <div className="performance-card">
                              <span className="performance-card__datetime">
                                <span className="text-muted"> Aug / Sep </span>
                                <br />
                                29 - 7
                              </span>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status active has-started">
                                <div className="contactPlan__cell-status-header">
                                  <h4> 171</h4> <span> attempts </span>
                                </div>

                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {" "}
                                    20 Total Contacts{" "}
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      {" "}
                                      <i className="icon-clock"></i> 5.7hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status active has-started">
                                <div className="contactPlan__cell-status-header">
                                  <h4> 171</h4> <span> attempts </span>
                                </div>

                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {" "}
                                    20 Total Contacts{" "}
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      {" "}
                                      <i className="icon-clock"></i> 5.7hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="contactPlan__carousel-item">
                            <div className="performance-card">
                              <span className="performance-card__datetime">
                                <span className="text-muted"> Aug / Sep </span>
                                <br />8 - 15
                              </span>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status active has-started">
                                <div className="contactPlan__cell-status-header">
                                  <h4> 171</h4> <span> attempts </span>
                                </div>

                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {" "}
                                    20 Total Contacts{" "}
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      {" "}
                                      <i className="icon-clock"></i> 5.7hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status active has-started">
                                <div className="contactPlan__cell-status-header">
                                  <h4> 171</h4> <span> attempts </span>
                                </div>

                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {" "}
                                    20 Total Contacts{" "}
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      {" "}
                                      <i className="icon-clock"></i> 5.7hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="contactPlan__carousel-item">
                            <div className="performance-card">
                              <span className="performance-card__datetime">
                                <span className="text-muted"> Aug / Sep </span>
                                <br />
                                16 - 23
                              </span>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status">
                                <div className="contactPlan__cell-status-body">
                                  <span> - </span>
                                </div>
                              </div>
                            </div>
                            <div className="contactPlan__cell">
                              <div className="contactPlan__cell-status">
                                <div className="contactPlan__cell-status-body">
                                  <span> - </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default VoterContactPlan;

import useIntegrationDetails from "./useIntegrationDetails";

interface IDisableIntegrationModalProps {
  content: string;
  volunteerId?: number;
  isVolunteer: boolean;
}

const DisableIntegrationModal: React.FC<IDisableIntegrationModalProps> = ({
  content = "",
  volunteerId,
  isVolunteer,
}) => {
  const defaultImg = require("../../../../img/call-disabled.png");

  const { disableVolunteer, disableAutodialerIntegration } =
    useIntegrationDetails();

  return (
    <div
      className="modal fade"
      id="disableModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close p-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span className="icon-close text-n1 h2"></span>
            </button>
          </div>
          <div className="modal-body text-center px-4">
            <img src={defaultImg} width={56} height={56} alt="delete warning" />
            <h2 className="mt-3">
              {isVolunteer ? "Disable integration?" : "Deactivate integration?"}
            </h2>
            <p>{content}</p>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-link rounded-pill btn-xs"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>

            <button
              className="btn btn-primary rounded-pill btn-xs"
              data-bs-dismiss="modal"
              onClick={() => {
                isVolunteer && volunteerId
                  ? disableVolunteer(volunteerId)
                  : disableAutodialerIntegration();
              }}
            >
              {isVolunteer ? "Yes, disable" : "Yes, deactivate"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisableIntegrationModal;

// This volunteer will no longer be able to access the auto dialer.

import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const HorizontalBarChart = () => {
  const data = {
    labels: ['Staff', 'Data & Technology', 'Materials', 'Miscellaneous'],
    datasets: [
      {
        data: [1212132672.00, 220, 100, 2100],
        backgroundColor: [
          'rgba(11, 69, 165, 1)',
          'rgba(46, 120, 241, 1)',
          'rgba(151, 187, 248, 1)',
          'rgba(225,235,252,1)',
        ],
        borderColor: [
          'rgba(11, 69, 165, 1)',
          'rgba(46, 120, 241, 1)',
          'rgba(151, 187, 248, 1)',
          'rgba(225, 235, 252, 1)',
        ],
        borderWidth: 1,
        maxBarThickness: 20,
        borderRadius: 4
      },
    ],
  };

  const options = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        right: 50 // Adjust the padding to ensure the labels are visible
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
      },
      datalabels: {
        anchor: 'end' as const,
        align: 'end' as const,
        formatter: (value: number) => value.toFixed(2),
        color: '#858599',
        font: {
          size: 12,
          weight: 400
        },
      },
    },
    scales: {
      
      x: {
        
        beginAtZero: true, // Start the axis at zero
        ticks: {
          display: false, 
        },
        grid: {
          display: false, // Remove grid lines from x-axis
         

        },
        border: {
          display: false, // Remove the x-axis line itself
        },
      },
      y: {
       
        grid: {
          display: false, // Remove grid lines from y-axis
          

        },
        ticks: {
          font: {
            // family: 'Figtree Regular',
            size: 12, // Adjust font size of y-axis labels if needed
          },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;





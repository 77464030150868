import React, { useState, useLayoutEffect, useRef } from 'react'
import { UseMutateAsyncFunction, UseMutateFunction } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import toastNotify from 'utils/toastNotify'
import Spinner from 'components/common/Spinner'
import { useGetElectionDetail } from 'components/hooks/useGetElectionDetail'
import FundRaisingGoalConfirmationModal from './FundRaisingGoalConfirmationModal'

interface IFundRaisingGoalSection {
  donorData: any
  isDonorDataFetching: boolean
  postDonorsSubmit: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>
  postAsyncDonorsSubmit: UseMutateAsyncFunction<AxiosResponse<any, any>, unknown, any, unknown>
  postDonorSubmitStatus: "error" | "idle" | "pending" | "success"
  updateDonorsSubmit: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>
  updateAsyncDonorsSubmit: UseMutateAsyncFunction<AxiosResponse<any, any>, unknown, any, unknown>
  updateDonorsSubmitStatus: "error" | "idle" | "pending" | "success"
}

const FundRaisingGoalSection = ({
  donorData,
  isDonorDataFetching,
  postDonorsSubmit,
  postAsyncDonorsSubmit,
  postDonorSubmitStatus,
  updateDonorsSubmit,
  updateAsyncDonorsSubmit,
  updateDonorsSubmitStatus
}: IFundRaisingGoalSection) => {
  const value: string = donorData?.fundraisingGoal ? donorData.fundraisingGoal.toString() : ''

  const [inputValue, setInputValue] = useState<string>('')
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const divRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const fundRaisingGoalConfirmationModalButtonRef = useRef<HTMLButtonElement>(null)
  const budgetConfirmationModalRef = useRef<HTMLDivElement>(null)


  const {
    electionDetail,
    refetch: refetchElectionDetail,
    isFetching
  } = useGetElectionDetail({ enabled: false, gcTime: 1000 })

  const budgetMode: string | null = electionDetail?.budgetMode || null

  //Initial value population & closing due to new update
  useLayoutEffect(() => {
    if (value) setInputValue(value)
    if (isEditable) setIsEditable(false)
  }, [value])

  //Add event listener on outside click 
  useLayoutEffect(() => {
    function handleClickOutSideEvent(event: MouseEvent) {
      if (
        (divRef.current && !divRef.current?.contains(event.target as Node)) &&
        (budgetConfirmationModalRef.current && !budgetConfirmationModalRef.current.contains(event.target as Node))
      ) {
        setIsEditable(false)
      }
    }

    if (isEditable) {
      if (value !== inputValue) setInputValue(value)
      if (inputRef.current) inputRef.current.focus()

      document.addEventListener('mousedown', handleClickOutSideEvent)
    } else {
      document.removeEventListener('mousedown', handleClickOutSideEvent)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutSideEvent)
    }
  }, [isEditable])

  function handleSaveEvent() {
    //Error handling
    if (isNaN(Number(inputValue))) {
      toastNotify("error", "Error! Fund raising goal must be a valid number.")
      return
    }
    if (Number(inputValue) <= 0) {
      toastNotify("error", "Error! Fund raising goal must be a positive non-zero value.")
      return
    }

    //Skip for same value
    if (value === Number(inputValue)?.toString()) {
      setIsEditable(false)
      return
    }

    //Check if budget mode is in fund raising goal and jump to confirmation modal
    if (budgetMode === 'fundraising_goal_based') {
      fundRaisingGoalConfirmationModalButtonRef.current?.click()
      return
    }

    handleFinalSave()
  }

  function handleFinalSave() {
    if (donorData?.id) {
      updateDonorsSubmit({ fundraisingGoal: Number(inputValue) })
      return
    }
    postDonorsSubmit({ fundraisingGoal: Number(inputValue) })
  }

  function handleAsyncFinalSave() {
    if (donorData?.id) {
      return updateAsyncDonorsSubmit({ fundraisingGoal: Number(inputValue) })
    }
    return postAsyncDonorsSubmit({ fundraisingGoal: Number(inputValue) })
  }

  function handleCancelEvent() {
    setIsEditable(false)
  }

  function handleAddEditEvent() {
    setIsEditable(true)
    refetchElectionDetail()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSaveEvent()
      return
    }
    if (e.key === "Escape") {
      handleCancelEvent()
    }
  }

  return (
    <>
      <div
        className="row"
        ref={divRef}
      >
        <div className="col-md-4">
          <span className="body-2 fs-7">Fundraising goal</span>
          {/* <div className="d-flex gap-2">
          <InfoIconSecondary />
          <span className="body-4 text-muted mb-0">
            It cannot be changed later confirm amount before adding.
          </span>
        </div> */}
        </div>
        <div className="col-md-8">
          <div className="row align-items-center justify-content-end">
            <div className="col-md-8">
              {isEditable ? (
                <>
                  <input
                    ref={inputRef}
                    type="text"
                    className="form-control"
                    placeholder="Enter Fund Raising Goal"
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </>
              ) : (
                <>
                  {value ? (
                    <span className="h4">${value}</span>
                  ) : (
                    <span className="body-4 text-muted">
                      Not Provided
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-end">
                {isEditable ? (
                  <>
                    {(postDonorSubmitStatus === 'pending' || updateDonorsSubmitStatus === 'pending' || isDonorDataFetching) ? (
                      <Spinner
                        varient='text-primary'
                        size='spinner-border-sm'
                      />
                    ) : (
                      <>
                        <button
                          className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                          onClick={handleCancelEvent}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                          onClick={handleSaveEvent}
                        >
                          Save
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <button
                    className="btn btn-link btn-xs px-1 py-0"
                    onClick={handleAddEditEvent}
                  >
                    {value ? 'Edit' : "Add"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div >

      <FundRaisingGoalConfirmationModal
        fundRaisingGoalConfirmationModalButtonRef={fundRaisingGoalConfirmationModalButtonRef}
        budgetConfirmationModalRef={budgetConfirmationModalRef}
        handleFinalSave={handleFinalSave}
        handleAsyncFinalSave={handleAsyncFinalSave}
        postDonorSubmitStatus={postDonorSubmitStatus}
        updateDonorsSubmitStatus={updateDonorsSubmitStatus}
      />
    </>
  )
}

export default React.memo(FundRaisingGoalSection)
import SideNavbar from 'components/common/navbar/SideNavbar'
import TrashIcon from 'img/svg/TrashIcon'
import CheckSuccess from 'img/svg/checkSuccess'
import FileIcon from 'img/svg/fileIcon'
import NoData from 'img/svg/noData'
import React from 'react'
import { Link } from 'react-router-dom'

const BudgetNoDetails = () => {
  return (
    <div className="d-flex align-items-stretch">
    <SideNavbar />

    <div className="wrapper active-cont">
        <main>
          <div className="row h-100 justify-content-center">
            <div className="col-md-6">
              <div className="centeredXY noData text-center justify-content-around">
                <div className="noData-header">
                  <NoData />
                  <h1 className="mt-6">No budget available</h1>
                  <p className="text-muted">
                  Provide fundraising goal in settings to add the projected budget for your Campaign.
                  </p>

                  <div className="mt-4">
                    <Link
                      to="/profile?tabIndex=3"
                      className="btn btn-primary rounded-pill px-5"
                      
                    >
                      Go to settings
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> 

         
      </main>

       
     
    </div>
  </div>
  )
}

export default BudgetNoDetails

import { Link } from "react-router-dom";

interface IVerificationFailed {
  cleanAndCloseTwilioCard: () => void
  handleRetryValidation: () => void
}

const VerificationFailed = ({ cleanAndCloseTwilioCard, handleRetryValidation }: IVerificationFailed) => {
  return (
    <div className="card-body text-center">
      <img
        src={require("../../../../img/leave.png")}
        width={56}
        height={56}
        alt="leave alert"
      />
      <h2 className="mt-4"> Verification Failed </h2>
      <p className="mb-6 body-3 text-muted">
        Your number has not been verified by Campaign Brain. Retry to verify again.
      </p>

      <div className="w-100">
        <Link
          to=""
          className="btn btn-primary btn-xs rounded-pill w-100 mb-2"
          onClick={handleRetryValidation}
        >
          Retry
        </Link>
        <Link
          to=""
          type="button"
          className="btn btn-link btn-xs rounded-pill w-100"
          onClick={cleanAndCloseTwilioCard}
        >
          Continue without verifying
        </Link>
      </div>
    </div>
  );
};

export default VerificationFailed;
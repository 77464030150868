import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import CloseIcon from "img/svg/closeIcon";
import TopIssuesMultiSelectDd, {
  Option,
  TMultiValueSelectedOption,
} from "./TopIssuesMultiSelectDd";
import { INewsFilterData } from "../DailyNewsClips";
import Spinner from "components/common/Spinner";
import { MultiValue, SingleValue } from "react-select";
import { URL_REGEXP } from "../../account-setup/CampaignInformation";
import { uppercaseFirstLetter } from "utils/stringManipulate";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";

interface INewsFilterSidebarProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  newsFilterData: INewsFilterData;
  setNewsFilterData: React.Dispatch<React.SetStateAction<INewsFilterData>>;
  setIsApplyFiltersClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isApplyFiltersClicked: boolean;
}

const newsFilterSchema = yup.object().shape({
  sourceUrl: yup
    .string()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable()
    .matches(URL_REGEXP, "Invalid URL format"),
});

type TLevelTags = "localTags" | "nationalTags" | "stateTags";

interface IFormInput {
  sourceUrl: string | null;
}

const NewsFilterSidebar: React.FC<INewsFilterSidebarProps> = ({
  isOpen,
  setIsOpen,
  newsFilterData,
  setNewsFilterData,
  isApplyFiltersClicked,
  setIsApplyFiltersClicked,
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(newsFilterSchema) as any,
  });

  const authAxios = CreateAuthAxiosInstance();

  const updateNewsFilter = async (localUrl: string | null) => {
    const localTags = newsFilterData?.localTags?.map((item) => item.value);
    const stateTags = newsFilterData?.stateTags?.map((item) => item.value);
    const nationalTags = newsFilterData?.nationalTags?.map(
      (item) => item.value
    );
    try {
      await authAxios.post("/api/v1/news-filters", {
        localNewsSource: localUrl,
        localTags: localTags?.length ? localTags : null,
        stateTags: stateTags?.length ? stateTags : null,
        nationalTags: nationalTags?.length ? nationalTags : null,
      });
    } catch (error) {
      console.log(error);
      toastNotify("error", "Error updating the filters");
    }
  };

  useEffect(() => {
    setValue("sourceUrl", newsFilterData.localUrl || "");
  }, [newsFilterData.localUrl, setValue]);

  const handleTagsChange = (
    newValue: SingleValue<Option> | MultiValue<Option>,
    level: TLevelTags
  ) => {
    setNewsFilterData((prevState) => ({
      ...prevState,
      [level]: newValue as TMultiValueSelectedOption,
    }));
  };

  const onSubmit = async (data: IFormInput) => {
    setNewsFilterData((prevState) => ({
      ...prevState,
      localUrl: data.sourceUrl ?? undefined,
    }));
    setIsApplyFiltersClicked(true);
    await updateNewsFilter(data.sourceUrl);
  };

  const resetFilters = () => {
    setValue("sourceUrl", null);
    setNewsFilterData({
      localUrl: undefined,
      localTags: [],
      stateTags: [],
      nationalTags: [],
    });
  };

  return (
    <aside
      className={`sidePanel sidePanel-news control-sidebar shadow-4 ${
        isOpen ? "open" : "close"
      }`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="sidePanel-header shadow-4">
          <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="location-info">
              <div className="location-content">
                <div className="voter-location">Filter news by</div>
              </div>
            </div>
            <div
              className="panel-close"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <Link to="" className="close">
                <CloseIcon />
              </Link>
            </div>
          </div>
        </div>
        <div className="sidePanel-body scroll-y">
          <div className="filter-header">
            <span className="body-3 text-muted">
              Filters applied at each level will only affect news clips from
              that same level.
            </span>
          </div>
          <div className="filter-body">
            {(["local", "state", "national"] as const).map((level) => (
              <div className="filter-item" key={level}>
                <div className="filter-item-header">
                  <div className="filter-item-title body-5">
                    {uppercaseFirstLetter(level)} Level
                  </div>
                </div>
                <div className="filter-item-body">
                  {level === "local" && (
                    <div className="mb-3">
                      <label htmlFor="newsSource" className="form-label">
                        News source URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="newsSource"
                        placeholder="Input news source URL"
                        {...register("sourceUrl")}
                      />
                      {errors?.sourceUrl?.message && (
                        <span className="invalidInput form-text text-danger">
                          {errors.sourceUrl.message as string}
                        </span>
                      )}
                    </div>
                  )}
                  <div className="">
                    <label htmlFor="position" className="form-label">
                      Top Issues
                    </label>
                    <TopIssuesMultiSelectDd
                      selectedOptions={
                        newsFilterData[`${level}Tags` as TLevelTags] || []
                      }
                      onChange={(newValue) =>
                        handleTagsChange(newValue, `${level}Tags` as TLevelTags)
                      }
                    />
                    {(newsFilterData[`${level}Tags` as TLevelTags]?.length ||
                      0) > 0 && (
                      <p className="text-muted fs-8 mb-0 mt-1">
                        {newsFilterData[`${level}Tags` as TLevelTags]?.length}{" "}
                        issues selected
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="sidePanel-footer card-footer d-flex justify-content-end border-top">
          <button
            className="btn btn-link btn-xs rounded-pill me-2"
            type="button"
            onClick={resetFilters}
          >
            Reset
          </button>
          <button
            className="btn btn-primary btn-xs rounded-pill"
            type="submit"
            disabled={isApplyFiltersClicked}
          >
            Apply filters {isApplyFiltersClicked && <Spinner />}
          </button>
        </div>
      </form>
    </aside>
  );
};

export default NewsFilterSidebar;

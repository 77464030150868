import React from 'react'

const LinkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" fill="none">
    <path d="M6.66797 3.99984H4.0013C3.26492 3.99984 2.66797 4.59679 2.66797 5.33317V11.9998C2.66797 12.7362 3.26492 13.3332 4.0013 13.3332H10.668C11.4043 13.3332 12.0013 12.7362 12.0013 11.9998V9.33317M9.33464 2.6665H13.3346M13.3346 2.6665V6.6665M13.3346 2.6665L6.66797 9.33317" stroke="#0B45A5" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default LinkIcon

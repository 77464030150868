
import CustomTooltip from 'components/common/CustomTooltip'
import CheckIconDark from 'img/svg/CheckIconDark'
import DislikeFilledIcon from 'img/svg/DislikeFilledIcon'
import DislikeIcon from 'img/svg/DislikeIcon'
import DocumentIconSmall from 'img/svg/DocumentIconSmall'
import LikeFilledIcon from 'img/svg/LikeFilledIcon'
import LikeIcon from 'img/svg/LikeIcon'
import CheckIcon from 'img/svg/checkIcon'
import CheckSuccess from 'img/svg/checkSuccess'
import React, { useState } from 'react'

const CopyText = () => {
  const [ isLikeClicked, setLikeClicked ] = useState(false);
  const [ isDislikeClicked, setDislikeClicked ] = useState(false);
  const [ iscopyClicked, setCopyClicked ] = useState(false);

  const handleLike = () => {
    setLikeClicked(true)
    setDislikeClicked(false)
  }
  const handleDisLike = () => {
    setDislikeClicked(true)
    setLikeClicked(false)
  }
  const handleCopy = () => {
    setCopyClicked(true)
  }

  return (
    <div className="copyText">
      <CustomTooltip content={isLikeClicked ? "Liked" : 'Like' }>
        <button className="btn btn-link no-hover btn-copyText px-1 py-1" onClick={ handleLike}>
          {isLikeClicked ? <LikeFilledIcon/> :  <LikeIcon />}
            
        </button>

      </CustomTooltip>
      <CustomTooltip content={isDislikeClicked ? "Disliked" : 'Dislike' }>
        <button className="btn btn-link no-hover btn-copyText px-1 py-1" onClick={ handleDisLike}>
          {isDislikeClicked ? <DislikeFilledIcon/>  : <DislikeIcon /> }
        </button>

      </CustomTooltip>

      <CustomTooltip content={iscopyClicked ? "Copied" : 'Copy' }>
        <button className="btn btn-link no-hover btn-copyText px-1 py-1" onClick={ handleCopy}>
        {iscopyClicked ?   <CheckIconDark />   :  <DocumentIconSmall />}
          
        </button>

        </CustomTooltip>
      

        </div>
  )
}

export default CopyText

import React from 'react'

const InfoIconSecondarySmall = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
<path d="M7.0026 13.6667C8.77071 13.6667 10.4664 12.9643 11.7166 11.714C12.9669 10.4638 13.6693 8.7681 13.6693 6.99999C13.6693 5.23188 12.9669 3.53619 11.7166 2.28595C10.4664 1.03571 8.77071 0.333328 7.0026 0.333328C5.23449 0.333328 3.5388 1.03571 2.28856 2.28595C1.03832 3.53619 0.335938 5.23188 0.335938 6.99999C0.335938 8.7681 1.03832 10.4638 2.28856 11.714C3.5388 12.9643 5.23449 13.6667 7.0026 13.6667ZM6.0026 9.99999C6.0026 9.73478 6.10796 9.48042 6.2955 9.29289C6.48303 9.10535 6.73739 8.99999 7.0026 8.99999C7.26782 8.99999 7.52217 9.10535 7.70971 9.29289C7.89725 9.48042 8.0026 9.73478 8.0026 9.99999C8.0026 10.2652 7.89725 10.5196 7.70971 10.7071C7.52217 10.8946 7.26782 11 7.0026 11C6.73739 11 6.48303 10.8946 6.2955 10.7071C6.10796 10.5196 6.0026 10.2652 6.0026 9.99999ZM6.3466 3.54666C6.37444 3.39295 6.45536 3.2539 6.57525 3.15376C6.69515 3.05363 6.84639 2.99877 7.0026 2.99877C7.15881 2.99877 7.31006 3.05363 7.42996 3.15376C7.54985 3.2539 7.63077 3.39295 7.6586 3.54666L7.66927 3.66666V6.99999L7.6586 7.11999C7.63077 7.2737 7.54985 7.41276 7.42996 7.51289C7.31006 7.61303 7.15881 7.66788 7.0026 7.66788C6.84639 7.66788 6.69515 7.61303 6.57525 7.51289C6.45536 7.41276 6.37444 7.2737 6.3466 7.11999L6.33594 6.99999V3.66666L6.3466 3.54666Z" fill="#858599"/>
</svg>
  )
}

export default InfoIconSecondarySmall

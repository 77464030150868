import React from 'react'

const NoNetwork = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="97" viewBox="0 0 96 97" fill="none">
<g clipPath="url(#clip0_1228_12592)">
<path d="M48 96.5C74.5097 96.5 96 75.0097 96 48.5C96 21.9903 74.5097 0.5 48 0.5C21.4903 0.5 0 21.9903 0 48.5C0 75.0097 21.4903 96.5 48 96.5Z" fill="#E3EBFE"/>
<path d="M73.8622 29.3043C76.0674 30.8851 78.2726 32.469 80.2678 34.3247C80.8639 34.8808 81.4013 35.4932 82.0066 36.0461C83.1432 37.0865 83.103 39.214 82.2043 40.3449C81.0183 41.8382 79.227 42.2287 77.8155 41.2978C76.3207 40.3137 75.1779 38.9078 73.7819 37.8019C72.2933 36.621 70.7923 35.4588 69.1368 34.5184C66.666 32.9033 64.0439 31.5911 61.258 30.632C59.1177 29.8947 56.928 29.3168 54.6919 28.9731C52.1933 28.5889 49.6793 28.2858 47.1436 28.3827C36.5253 28.7919 27.5378 33.0314 19.8505 40.3074C18.8529 41.2509 17.7812 42.0382 16.3204 41.7226C14.9707 41.4321 14.0163 40.5167 13.6395 39.2452C13.2689 37.9862 13.6488 36.7584 14.634 35.8024C17.0462 33.4625 19.5725 31.2694 22.3769 29.4105C27.4698 26.0333 32.9983 23.6965 38.956 22.4875C41.0592 22.0626 43.1965 21.6721 45.3832 21.6283C46.7884 21.6002 48.203 21.4471 49.6082 21.519C52.8141 21.6846 55.9798 22.0938 59.1115 22.8686C64.3929 24.1776 69.2665 26.3989 73.8591 29.3012L73.8622 29.3043Z" fill="#26DAC2"/>
<path d="M67.0912 36.9833C67.6286 36.9708 68.166 36.9833 68.7034 36.9427C69.9079 36.8458 70.7727 37.4581 71.3657 38.3954C74.1021 42.716 76.7953 47.0648 79.5039 51.4042C80.2359 52.5788 80.9648 53.7535 81.7184 54.9126C82.4565 56.0466 82.4442 57.2681 81.9284 58.4116C81.3786 59.6237 80.3224 60.3266 78.9665 60.3298C71.9494 60.3485 64.9293 60.3516 57.9122 60.336C55.2654 60.3298 53.8694 57.1276 55.148 55.1156C56.5471 52.9194 57.9029 50.695 59.2804 48.4831C60.6888 46.2588 62.1002 44.0406 63.5024 41.8132C64.2591 40.6135 65.0281 39.4201 65.7323 38.1892C66.0628 37.6112 66.665 37.4113 67.0912 36.9802V36.9833ZM66.7669 48.8362C66.7669 49.0923 66.7577 49.3485 66.7669 49.6047C66.8194 50.6981 67.6379 51.726 68.4502 51.7291C69.42 51.7322 70.1828 50.8168 70.1211 49.5797C70.0593 48.405 70.2539 47.2491 70.3558 46.1026C70.4453 45.1153 70.3218 44.1094 70.5504 43.1378C70.7696 42.1974 70.5658 41.3727 69.8493 40.7572C69.1296 40.1386 68.2525 40.0543 67.4093 40.476C66.5878 40.8884 66.3809 41.6507 66.4056 42.5473C66.4612 44.6498 66.6002 46.743 66.7639 48.8362H66.7669ZM68.41 53.6535C67.3043 53.6535 66.2264 54.7345 66.2542 55.8154C66.282 56.9464 67.3167 57.9461 68.4502 57.9398C69.7226 57.9336 70.6708 56.9963 70.6585 55.7592C70.6461 54.5252 69.7165 53.6535 68.41 53.6535Z" fill="#5688FF"/>
<path d="M48.3826 34.5244C51.712 34.5963 55.0723 35.1274 58.3553 36.2302C59.9397 36.7644 61.4809 37.3705 62.9881 38.0921C63.4668 38.3233 63.6243 38.4983 63.2784 39.02C62.2716 40.5415 61.308 42.0941 60.366 43.6593C60.0849 44.1279 59.8378 44.1842 59.356 43.953C56.6134 42.6502 53.7226 41.9192 50.7051 41.5849C43.8116 40.8164 37.7705 43.0158 32.1309 46.7928C30.5619 47.8456 29.1875 49.1546 27.6093 50.1762C25.1323 51.7789 22.6646 50.0544 22.4392 47.527C22.3372 46.3742 22.9271 45.3838 23.8074 44.6153C28.0417 40.9039 32.727 37.9766 38.067 36.1771C40.4235 35.3804 42.8294 34.8931 45.3126 34.7025C46.2978 34.6275 47.28 34.5775 48.3857 34.5244H48.3826Z" fill="#26DAC2"/>
<path d="M55.6824 51.2197C54.8732 52.4163 54.0393 53.6003 53.4247 54.9156C53.1807 55.4373 52.8966 55.5216 52.4055 55.3467C51.167 54.9062 49.9069 54.5938 48.5789 54.6969C44.953 54.3313 41.9417 55.8059 39.1466 57.9178C38.5382 58.3771 37.9822 58.9082 37.3676 59.3643C35.7153 60.5889 34.5262 60.5858 33.0406 59.3393C31.799 58.299 31.2771 56.7119 32.287 54.9749C32.9819 53.7815 34.1741 53.1598 35.1501 52.2913C36.5862 51.0167 38.3374 50.2263 40.033 49.4202C41.5618 48.6923 43.2141 48.1893 44.9159 47.9707C47.4948 47.6395 50.0737 47.6707 52.6186 48.1893C53.7923 48.4268 54.9474 48.8235 56.0654 49.2984C56.5379 49.4983 56.5935 49.7014 56.3063 50.1138C56.0654 50.4606 55.8832 50.8511 55.6762 51.2229L55.6824 51.2197Z" fill="#26DAC2"/>
<path d="M41.4296 67.8587C41.2474 64.0941 44.4996 60.9263 48.1842 61.0262C52.1405 61.1324 54.9758 63.9848 55.0036 67.9336C55.0314 71.6576 51.946 74.7036 48.2243 74.8004C44.8795 74.8879 41.1239 71.6107 41.4296 67.8618V67.8587Z" fill="#26DAC2"/>
</g>
<defs>
<clipPath id="clip0_1228_12592">
<rect width="96" height="96" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
  )
}

export default NoNetwork

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Spinner from 'components/common/Spinner'
import InfoIconDanger from 'img/svg/InfoIconDanger'
import InfoIconPrimary from 'img/svg/InfoIconPrimary'
import { useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import CreateAuthAxiosInstance from 'utils/authAxios'
import toastNotify from 'utils/toastNotify'
import * as yup from 'yup'

interface IFormInputs {
  fullName: string
  email: string
  phoneNumber?: string
}

const schema = yup
  .object({
    fullName: yup.string().required('Name is required !'),
    email: yup.string().email('Invalid Email !').required('Email is required !'),
    phoneNumber: yup
      .string()
      .matches(/^$|^[0-9\s\(\)\-]{10,}$/, 'Invalid phone number')
  })

export const AddVolunteerModal = () => {
  const authAxios = CreateAuthAxiosInstance()
  const queryClient = useQueryClient()

  const [conflictErrorMsg, setConflictErrorMsg] = useState<string>('')
  const buttonRef = useRef<HTMLButtonElement>(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  })

  const {
    mutate: inviteVolunteerMutation,
    status: inviteVolunteerStatus
  } = useMutation({
    mutationFn: (data: IFormInputs) => authAxios.post('api/v1/volunteers', data),
    onSuccess: (data: any) => {
      handleOnSuccessMutation(data?.data?.status, data?.data?.options?.description || 'none')
    },
    onError: (error: any) => {
      console.log("on error data: ", error)
    }
  })

  const onFinalSubmit: SubmitHandler<IFormInputs> = (submittedData) => {
    const finalDataForMutation: IFormInputs = {
      fullName: '',
      email: ''
    }

    for (const key in submittedData) {
      const submittedValue = submittedData[key as keyof IFormInputs]?.trim()

      if (!submittedValue) continue

      finalDataForMutation[key as keyof IFormInputs] = submittedValue
    }

    inviteVolunteerMutation(finalDataForMutation)
  }

  function handleReset() {
    setTimeout(() => {
      reset()
      clearErrors()
      setConflictErrorMsg('')
    }, 100)
  }

  function handleOnSuccessMutation(status: string | number | null | undefined, description: string) {
    if (!status) {
      toastNotify('error', 'Unexpected Error Occurred !')
      return
    }

    if (status === 409) {
      toastNotify('error', 'Volunteer Already Exists !')
      setConflictErrorMsg(description)
      return
    }

    if (status === 'Pending' || status === 'Active') {
      toastNotify('success', 'Volunteer Invited Successfully !')
      queryClient.invalidateQueries({ queryKey: ['volunteerList'] })
      handleReset()

      if (buttonRef?.current) buttonRef?.current?.click()
    }
  }

  function handleConflictErrorMsg(focusPoint: string) {
    if (!conflictErrorMsg?.length) return

    if (
      (conflictErrorMsg === 'email' && focusPoint === 'email') ||
      (conflictErrorMsg === 'phone' && focusPoint === 'phone')
    ) {
      setConflictErrorMsg('')
      return
    }

    if (conflictErrorMsg === 'both' && focusPoint === 'email') {
      setConflictErrorMsg('phone')
      return
    }

    if (conflictErrorMsg === 'both' && focusPoint === 'phone') {
      setConflictErrorMsg('email')
      return
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onFinalSubmit)}>
        {/* Add donor Modal */}
        <div
          className="modal fade"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">
                  Invite volunteer
                </h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleReset()}
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                {/* Alert */}
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="alert alert-primary fade show"
                      role="alert"
                    >
                      <InfoIconPrimary />
                      <span
                        className="ms-2">Volunteer will receive an invitation link to sign in </span>
                    </div>
                  </div>
                </div>

                <form action="">
                  <form action="">
                    <div className="mb-3 required">
                      <label htmlFor="exampleInputName1" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName1"
                        aria-describedby="nameHelp"
                        placeholder="Enter name"
                        {...register('fullName', { required: true })}
                      />
                      {!!errors?.fullName?.message?.length &&
                        <div className="invalidInput form-text text-danger">
                          <InfoIconDanger />&nbsp;
                          {errors?.fullName?.message}
                        </div>
                      }
                    </div>

                    <div className="mb-3 required">
                      <label htmlFor="exampleInputEmail1" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        {...register('email', { required: true })}
                        onFocus={() => handleConflictErrorMsg('email')}
                      />
                      {!!errors?.email?.message?.length &&
                        <div className="invalidInput form-text text-danger">
                          <InfoIconDanger />&nbsp;
                          {errors?.email?.message}
                        </div>
                      }
                      {(conflictErrorMsg === 'email' || conflictErrorMsg === 'both') &&
                        <div className="invalidInput form-text text-danger">
                          <InfoIconDanger />&nbsp;
                          Email already exists !
                        </div>
                      }
                    </div>

                    <div className="mb-3">
                      <label htmlFor="exampleInputPhone" className="form-label">
                        Phone no.
                      </label>
                      <input
                        type="string"
                        className="form-control"
                        id="exampleInputPhone"
                        aria-describedby="phoneHelp"
                        placeholder="Enter phone no."
                        {...register('phoneNumber')}
                        onFocus={() => handleConflictErrorMsg('phone')}

                      />
                      {!!errors?.phoneNumber?.message?.length &&
                        <div className="invalidInput form-text text-danger">
                          <InfoIconDanger />&nbsp;
                          Invalid Phone Number !
                        </div>
                      }

                      {(conflictErrorMsg === 'phone' || conflictErrorMsg === 'both') &&
                        <div className="invalidInput form-text text-danger">
                          <InfoIconDanger />&nbsp;
                          Phone number already exists !
                        </div>
                      }
                    </div>
                  </form>
                </form>
              </div>

              <div className="modal-footer border-1">
                <button
                  type='button'
                  className="btn btn-link text-primary btn-xs"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary rounded-pill btn-xs px-6"
                  type='submit'
                  disabled={!!Object.keys(errors)?.length || inviteVolunteerStatus === 'pending' || conflictErrorMsg !== ''}
                >
                  {inviteVolunteerStatus === 'pending' && <Spinner />}
                  Invite volunteer
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <button
        className="btn btn-primary d-none"
        ref={buttonRef}
        type="button"
        data-bs-dismiss="modal"
        aria-label="Close"
        data-bs-target="#uploadModal"
      />
    </>
  )
}

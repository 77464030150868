import React from 'react'

const RedoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M12.0381 2.26035C11.691 2.60748 11.691 3.1703 12.0381 3.51743L15.854 7.33333L12.0381 11.1492C11.691 11.4964 11.691 12.0592 12.0381 12.4063C12.3853 12.7535 12.9481 12.7535 13.2952 12.4063L17.7397 7.96187C18.0868 7.61474 18.0868 7.05193 17.7397 6.70479L13.2952 2.26035C12.9481 1.91322 12.3853 1.91322 12.0381 2.26035Z" fill="#3B3B4F"/>
<path fillRule="evenodd" clipRule="evenodd" d="M18 7.33333C18 6.84241 17.602 6.44444 17.1111 6.44444H7.77778C7.01903 6.44444 6.26771 6.59389 5.56672 6.88425C4.86573 7.17461 4.22879 7.6002 3.69227 8.13672C3.15575 8.67323 2.73017 9.31017 2.43981 10.0112C2.14945 10.7122 2 11.4635 2 12.2222C2 12.981 2.14945 13.7323 2.43981 14.4333C2.73017 15.1343 3.15575 15.7712 3.69227 16.3077C4.22879 16.8442 4.86573 17.2698 5.56672 17.5602C6.26771 17.8506 7.01903 18 7.77778 18H10.8889C11.3798 18 11.7778 17.602 11.7778 17.1111C11.7778 16.6202 11.3798 16.2222 10.8889 16.2222H7.77778C7.25249 16.2222 6.73235 16.1188 6.24704 15.9177C5.76174 15.7167 5.32078 15.4221 4.94935 15.0506C4.57792 14.6792 4.28328 14.2383 4.08226 13.753C3.88124 13.2677 3.77778 12.7475 3.77778 12.2222C3.77778 11.6969 3.88124 11.1768 4.08226 10.6915C4.28328 10.2062 4.57792 9.76523 4.94935 9.39379C5.32078 9.02236 5.76174 8.72772 6.24704 8.5267C6.73235 8.32569 7.25249 8.22222 7.77778 8.22222H17.1111C17.602 8.22222 18 7.82425 18 7.33333Z" fill="#3B3B4F"/>
<path fillRule="evenodd" clipRule="evenodd" d="M18 7.33333C18 6.84241 17.602 6.44444 17.1111 6.44444H7.77778C7.01903 6.44444 6.26771 6.59389 5.56672 6.88425C4.86573 7.17461 4.22879 7.6002 3.69227 8.13672C3.15575 8.67323 2.73017 9.31017 2.43981 10.0112C2.14945 10.7122 2 11.4635 2 12.2222C2 12.981 2.14945 13.7323 2.43981 14.4333C2.73017 15.1343 3.15575 15.7712 3.69227 16.3077C4.22879 16.8442 4.86573 17.2698 5.56672 17.5602C6.26771 17.8506 7.01903 18 7.77778 18H10.8889C11.3798 18 11.7778 17.602 11.7778 17.1111C11.7778 16.6202 11.3798 16.2222 10.8889 16.2222H7.77778C7.25249 16.2222 6.73235 16.1188 6.24704 15.9177C5.76174 15.7167 5.32078 15.4221 4.94935 15.0506C4.57792 14.6792 4.28328 14.2383 4.08226 13.753C3.88124 13.2677 3.77778 12.7475 3.77778 12.2222C3.77778 11.6969 3.88124 11.1768 4.08226 10.6915C4.28328 10.2062 4.57792 9.76523 4.94935 9.39379C5.32078 9.02236 5.76174 8.72772 6.24704 8.5267C6.73235 8.32569 7.25249 8.22222 7.77778 8.22222H17.1111C17.602 8.22222 18 7.82425 18 7.33333Z" fill="#3B3B4F"/>
</svg>
  )
}

export default RedoIcon

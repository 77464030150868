import CreateAuthAxiosInstance from "utils/authAxios";
import { IVoiceMailDataType } from "./VoiceMailUpload";
import FileIcon from "img/svg/fileIcon";
import { dateFormatter, formatFileSize } from "../../professional-details/useProfessionalDetils";
import CheckSuccess from "img/svg/checkSuccess";
import { Link } from "react-router-dom";
import ThreeDots from "img/svg/threeDots";
import DeleteVoiceMailModal from "./DeleteVoiceMailModal";

const FetchedVoiceMailCard = ({
  sectionType,
  fetchedAudioData,
  setFetchedAudioData,
  handleFileChange,
  uploadVoiceMailMutationStatus
}: {
  sectionType: string,
  fetchedAudioData: IVoiceMailDataType | null,
  setFetchedAudioData: React.Dispatch<React.SetStateAction<IVoiceMailDataType | null>>,
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  uploadVoiceMailMutationStatus: string
}) => {

  return (
    <>
      <div className="uploadedCard">
        <div className="uploadedCard-icon">
          <div className="iconBox">
            <FileIcon />
          </div>
          <div className="iconInfo">
            <span className="iconInfo-title">
              {fetchedAudioData?.fileName ?? 'N/A'}
            </span>
            <p className="iconInfo-desc">
              {typeof fetchedAudioData?.size === 'number' && formatFileSize(fetchedAudioData.size)}

              {typeof fetchedAudioData?.updatedAt === 'string' &&
                <span>
                  ,&nbsp;
                  {dateFormatter(fetchedAudioData.updatedAt)}
                </span>
              }
            </p>
            {uploadVoiceMailMutationStatus === 'success' &&
              <span className="iconInfo-status">
                <>
                  <CheckSuccess /> File uploaded
                </>
              </span>
            }
          </div>
        </div>
        <div className="d-flex">
          <div
            className="uploadedCard-info file-btn "
            style={{ overflow: 'hidden' }}
          >
            <input
              type="file"
              name="changedFile"
              onChange={handleFileChange}
            />
            <button
              className="btn btn-link rounded-pill text-primary"
            >
              Change
            </button>
          </div>

          <div className="action">
            <Link
              className="px-3 py-2"
              to=""
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <ThreeDots />
            </Link>
            <div className="dropdown-menu">
              <div className="dropdown-group">
                <ul className="dropdown-body list-unstyled mb-0">
                  {fetchedAudioData?.url &&
                    <li>
                      <Link
                        // onClick={handleDownload}
                        to={fetchedAudioData.url}
                        target="_blank"
                        type="button"
                      >
                        Download voicemail
                      </Link>
                    </li>
                  }
                  <li>
                    <Link
                      to=""
                      data-bs-toggle="modal"
                      data-bs-target="#deleteVoterVoicemailModal"
                      style={{ color: 'red' }}
                    >
                      Delete voicemail
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteVoiceMailModal
        sectionType={sectionType}
        fileId={fetchedAudioData?.id}
        setFetchedAudioData={setFetchedAudioData}
      />
    </>
  )
}

export default FetchedVoiceMailCard
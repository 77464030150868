export function getCurrentTimeStamp() {
  const currentDate = new Date();

  // Format the date and time as MM/DD/YYYY, HH:MM:SS AM/PM
  const formattedDateTime =
    (currentDate.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    currentDate.getDate().toString().padStart(2, "0") +
    "/" +
    currentDate.getFullYear() +
    ", " +
    (currentDate.getHours() % 12 || 12).toString().padStart(2, "0") +
    ":" +
    currentDate.getMinutes().toString().padStart(2, "0") +
    ":" +
    currentDate.getSeconds().toString().padStart(2, "0") +
    " " +
    (currentDate.getHours() >= 12 ? "PM" : "AM");

  return formattedDateTime
    .replaceAll("/", "%2F")
    .replaceAll(",", "%2C")
    .replaceAll(" ", "%20")
    .replaceAll(":", "%3A");
}

export function formatSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return (
    String(hours).padStart(2, "0") +
    ":" +
    String(minutes).padStart(2, "0") +
    ":" +
    String(remainingSeconds).padStart(2, "0")
  );
}

export const getYearMonthDate = (date: string) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const monthIndex = dateObj.getMonth(); // Months are 0-indexed
  const day = dateObj.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${day} ${monthNames[monthIndex]} ${year}`;
};

export const getTodayDate = () => {
  const dateObj = new Date();

  const year = dateObj.getFullYear();
  const monthIndex = dateObj.getMonth(); // Months are 0-indexed
  const day = dateObj.getDate();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${day} ${monthNames[monthIndex]}, ${year}`;
};
export const getCurrentTimeFormatted = (): string => {
  const currentDate = new Date();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const year = currentDate.getFullYear();
  const hours = currentDate.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const meridiem = currentDate.getHours() >= 12 ? "PM" : "AM";

  return `${month}/${day}/${year}, ${hours}:${minutes}:${seconds} ${meridiem}`;
};

export function getDateWithDashSeperation(currentDate: Date) {
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

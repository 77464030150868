import { Tooltip } from "bootstrap";
import FileIcon from "img/svg/fileIcon";
import HelpIcon from "img/svg/helpIcon";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";
import { queryClient } from "index";
import EmptyVoiceMailCard from "./EmptyVoiceMailCard";
import SelectedVoiceMailCard from "./SelectedVoiceMailCard";
import FetchedVoiceMailCard from "./FetchedVoiceMailCard";

const VOICEMAIL_TOOLTIP = `Tip: Leaving a voicemail is most effective towards the end of the campaign.`;
const ALLOWED_AUDIO_FILE_TYPE = [
  'audio/mp4',
  'audio/mpeg',
  'audio/mpeg3',
  'audio/x-mpeg-3',
  'audio/wav',
  'audio/vnd.wave',
  'audio/x-m4a'
]
export const ALLOWED_AUDIO_FILE_SIZE = 2 //in MB

interface IVoiceMailUploadPropsType {
  sectionType: string
  candidateElectionId: number
}

interface IVoiceMailSupportDataType {
  fileTypeId: number
  electionId: number
  file?: File
}
export interface IVoiceMailDataType {
  id: number
  isActive: boolean
  isArchived: boolean
  deletedAt: string | null
  createdAt: string | null
  createdBy: string | null
  updatedAt: string | null
  updatedBy: string | null
  internalComment: string | null
  url: string | null
  key: string | null
  mimeType: string | null
  fileTypeId: number | null
  fileLinkId: number | null
  size: number | null
  fileName: string | null
}

const VoiceMailUpload = ({ sectionType, candidateElectionId }: IVoiceMailUploadPropsType) => {
  const authAxios = CreateAuthAxiosInstance();

  const [voiceMailSupportData, setVoiceMailSupportData] = useState<IVoiceMailSupportDataType | null>(null)
  const [selectedAudioFile, setSelectedAudioFile] = useState<File | null>(null)
  const [fetchedAudioData, setFetchedAudioData] = useState<IVoiceMailDataType | null>(null)

  const {
    data: fileTypeList
  } = useQuery({
    queryKey: ['fileTypeList'],
    queryFn: () => authAxios.get('api/v1/file-type'),
    refetchOnWindowFocus: false
  })

  const {
    data: uploadedVoiceMail,
    status: uploadedVoiceMailStatus
  } = useQuery({
    queryKey: ['uploadedVoiceMail', voiceMailSupportData?.fileTypeId],
    queryFn: () => authAxios.get(`api/v1/file/file-type/${voiceMailSupportData?.fileTypeId}`),
    refetchOnWindowFocus: false,
    enabled: !!(voiceMailSupportData?.fileTypeId)
  })

  const {
    mutate: mutateUploadVoiceMail,
    status: uploadVoiceMailMutationStatus
  } = useMutation({
    mutationFn: (data: FormData) => authAxios.post(
      `api/v1/${sectionType === 'voter' ? 'voter' : 'donor'}/voicemail`,
      data,
      {
        timeout: 45000,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          // console.log("progress event: ", progressEvent?.progress)
        },
      }
    ),
    onSuccess: () => {
      toastNotify("success", "Voicemail successfully uploaded!");
      queryClient.invalidateQueries({ queryKey: ['uploadedVoiceMail'] })
      setSelectedAudioFile(null)
    },
    onError: (error: any) => {
      // console.log("on error", error);
      toastNotify("error", error?.response?.data?.message);
    },
  });


  useEffect(() => {
    if (uploadedVoiceMail?.data?.[0]) setFetchedAudioData(uploadedVoiceMail?.data?.[0])

    if (!(fileTypeList && candidateElectionId)) return

    const tempFileTitle = sectionType === 'voter' ? 'voters-voicemail' : 'donors-voicemail'
    const tempFileTypeId = fileTypeList?.data?.find((obj: any) => obj?.title === tempFileTitle)?.id || null

    if (tempFileTypeId && candidateElectionId) {
      const tempObj: IVoiceMailSupportDataType = {
        fileTypeId: tempFileTypeId,
        electionId: candidateElectionId
      }
      setVoiceMailSupportData(tempObj)
    }
  }, [fileTypeList, candidateElectionId, uploadedVoiceMail])


  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );

    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const audioFile = event?.target?.files?.[0]
    if (!audioFile) return

    setSelectedAudioFile(audioFile)

    if (!isValidAudio(audioFile)) return

    const finalFormData = new FormData()
    if (
      voiceMailSupportData?.fileTypeId &&
      voiceMailSupportData?.electionId &&
      audioFile
    ) {

      finalFormData.append('fileTypeId', String(voiceMailSupportData?.fileTypeId))
      finalFormData.append('electionId', String(voiceMailSupportData?.electionId))
      finalFormData.append('file', audioFile)
    }

    // finalFormData.forEach((value, key) => {
    //   console.log(key, value);
    // });

    mutateUploadVoiceMail(finalFormData)
  }

  const isValidAudio = (file?: File | null) => {
    //if argument file is given
    if (file) return isValidAudioType(file?.type) && isValidAudioSize(file?.size)

    //if no argument file is given
    if (!selectedAudioFile) return false
    return isValidAudioType(selectedAudioFile?.type) && isValidAudioSize(selectedAudioFile?.size)
  }

  return (
    <>
      <div className="mt-5">
        <div className="settings">
          <div className="settings-header d-flex justify-content-between">
            <div className="">
              <div className="d-flex gap-2">
                <h4> Voicemail </h4>
                <Link
                  to=""
                  data-bs-html="true"
                  data-bs-custom-class="custom-tooltip"
                  className="text-left"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title={VOICEMAIL_TOOLTIP}
                >
                  <HelpIcon />
                </Link>
              </div>
              <p className="body-4 text-muted">
                Upload a voicemail to easily send to&nbsp;
                {`${sectionType === 'voter' ? 'voters' : 'donors'}`}
                &nbsp;when they do not pick up.
              </p>
            </div>
          </div>
          <div className="settings-body">
            <div className="voterDetail-info">
              <hr className="mt-3 mb-3" />
              <div className="row">
                <div className="col-md-4">
                  <span className="body-2 fs-7"> Voicemail </span>
                </div>
                <div className="col-md-8">
                  {uploadedVoiceMailStatus !== 'success' ? (
                    <>
                      <div className="uploadedCard">
                        <div className="uploadedCard-icon">
                          <div className="iconBox">
                            <FileIcon />
                          </div>
                          Loading....
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {!(selectedAudioFile || fetchedAudioData) ? (
                        <>
                          <EmptyVoiceMailCard handleFileChange={handleFileChange} />
                        </>
                      ) : (
                        <>
                          {selectedAudioFile ? (
                            <SelectedVoiceMailCard
                              selectedAudioFile={selectedAudioFile}
                              setSelectedAudioFile={setSelectedAudioFile}
                              isValidAudio={isValidAudio}
                              handleFileChange={handleFileChange}
                              uploadVoiceMailMutationStatus={uploadVoiceMailMutationStatus}
                            />
                          ) : (
                            <FetchedVoiceMailCard
                              sectionType={sectionType}
                              fetchedAudioData={fetchedAudioData}
                              setFetchedAudioData={setFetchedAudioData}
                              handleFileChange={handleFileChange}
                              uploadVoiceMailMutationStatus={uploadVoiceMailMutationStatus}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <hr className="mt-3 mb-3" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VoiceMailUpload

export const isValidAudioType = (type: string | null | undefined) => {
  if (!type) return false
  return ALLOWED_AUDIO_FILE_TYPE.includes(type)
}

export const isValidAudioSize = (size: number | null | undefined) => {
  if (typeof size !== 'number') return false

  return size <= ALLOWED_AUDIO_FILE_SIZE * 1024 * 1024
}








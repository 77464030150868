import TrashIcon from "img/svg/TrashIcon";
import CheckSuccess from "img/svg/checkSuccess";
import FileIcon from "img/svg/fileIcon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AlertBanner from "templates/components/AlertBanner";
import FileUpload from "templates/components/FileUpload";

const ProfessionalDetails = () => {
  const [editable, setEditable] = useState(false);

  const handleEdit = () => {
    setEditable(true);
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="mt-5">
          <div className="settings">
            <div className="settings-header d-flex justify-content-between">
              <div className="">
                <h4> Candidate story </h4>
                <p className="body-4 text-muted">
                  View or edit your professional details
                </p>
              </div>

              <div>
                {editable ? (
                  <>
                    {" "}
                    <button
                      className="btn btn-link btn-xs rounded-pill"
                      onClick={() => setEditable(false)}
                    >
                      Cancel
                    </button>
                    <button className="btn btn-link btn-xs rounded-pill disabled">
                      Save
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-link btn-xs px-1 py-0"
                    onClick={handleEdit}
                  >
                    {" "}
                    <i className="icon-edit"> </i> Edit details
                  </button>
                )}
              </div>
            </div>

            <div className="settings-body">
              <div className="mt-4">
                <AlertBanner />
              </div>
              <div className="professional-info">
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> Resume </span>
                  </div>
                  <div className="col-md-8">
                    <div className="uploadedCard mb-0 p-0 border-0">
                      <div className="uploadedCard-icon">
                        <div className="iconBox">
                          <FileIcon />
                        </div>

                        <div className="iconInfo">
                          <span className="iconInfo-title"> resume.pdf </span>
                          <p className="iconInfo-desc">
                            {" "}
                            1.5 MB, 6/23/23 at 11:45 am{" "}
                          </p>
                        </div>
                      </div>

                      {editable && (
                        <div className="uploadedCard-info">
                          <div className="file-btn">
                            <button className="btn btn-link text-primary">
                              {" "}
                              Change File
                              <input type="file" name="file" />
                            </button>
                          </div>

                          <button className="btn btn-xs">
                            <TrashIcon />
                          </button>
                        </div>
                      )}
                    </div>

                    {/* if file has been removed */}
                    {/* <div className="uploadedCard mb-0 p-0 border-0">
                      <div className="uploadedCard-icon">
                        <div className="iconInfo">
                          <span className="iconInfo-title text-muted">
                            (pdf, jpg, jpeg, xls, or any){" "}
                            <span className="dot bg-neutral-n3 ms-1"> </span> 2MB
                            Max
                          </span>
                        </div>
                      </div>
                      <div className="uploadedCard-info file-btn">
                        <button className="btn btn-info rounded-pill">
                          {" "}
                          Upload File
                          <input type="file" name="file" />
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7"> Add your Linkedin account </span>
                  </div>
                  <div className="col-md-8">
                    {editable ? (
                      <input
                        type="text"
                        className="form-control"
                        id="InputLinked"
                        aria-describedby="emailHelp"
                        placeholder=""
                      />
                    ) : (
                      <Link to="" className="body-4 text-primary">
                        {" "}
                        https://linkedin.com/in/elenor-park-235363523{" "}
                      </Link>
                    )}
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7">
                      {" "}
                      Why are you running for office?{" "}
                    </span>
                  </div>
                  <div className="col-md-8">
                    {editable ? (
                      <div className="position-relative">
                        <textarea
                          className="form-control"
                          id="Textarea1"
                          rows={3}
                          maxLength={1000}
                        ></textarea>
                        <span className="word-count"> 0 / 200 </span>
                      </div>
                    ) : (
                      <span className="body-4">
                        {" "}
                        Throughout my life, I have been privileged to witness the
                        strength and resilience of our town. As a long-time
                        resident, I have come to deeply understand the challenges
                        and opportunities that lie before us. I firmly believe that
                        we are at a crucial juncture in our town's history, and now
                        is the time for bold leadership and decisive action.
                      </span>
                    )}
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7">
                      {" "}
                      What are your main policy proposals, ideas, and platforms?{" "}
                    </span>
                  </div>
                  <div className="col-md-8">
                    {editable ? (
                      <div className="position-relative">
                        <textarea
                          className="form-control"
                          id="Textarea1"
                          rows={3}
                          maxLength={1000}
                        ></textarea>
                        <span className="word-count"> 0 / 200 </span>
                      </div>
                    ) : (
                      <span className="body-4">
                        {" "}
                        Key pillars of my campaign include:Economic Prosperity: I am
                        committed to nurturing local businesses, attracting new
                        investments, and creating sustainable job opportunities.
                        Education and Youth Empowerment: Our children are the
                        bedrock of our town's future. I will prioritize educational
                        reform, ensuring that our schools receive the support they
                        need to provide a top-notch education.Sustainable
                        Development: As an advocate for the environment, I believe
                        it is our responsibility to safeguard the natural beauty
                        that surrounds us.
                      </span>
                    )}
                  </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="row">
                  <div className="col-md-4">
                    <span className="body-2 fs-7">Your biggest strength </span>
                  </div>
                  <div className="col-md-8">
                    {editable ? (
                      <>
                        <div className="position-relative mb-2">
                          <textarea
                            className="form-control"
                            id="Textarea1"
                            rows={2}
                            maxLength={1000}
                          ></textarea>
                          <span className="word-count"> 0 / 200 </span>
                        </div>
                        <div className="position-relative mb-2">
                          <textarea
                            className="form-control"
                            id="Textarea1"
                            rows={2}
                            maxLength={1000}
                          ></textarea>
                          <span className="word-count"> 0 / 200 </span>
                        </div>

                        {/* Add new strength */}
                        <button className="btn btn-link text-primary mt-3">
                          {" "}
                          <span className="icon-plus me-2"></span> Add another
                          strength{" "}
                        </button>
                      </>
                    ) : (
                      <>
                        <ul className="list-unstyled check-list">
                          <li>
                            <span className="check-icon"></span>
                            <span className="body-4">Leadership</span>
                          </li>
                          <li>
                            <span className="check-icon"></span>
                            <span className="body-4">
                              {" "}
                              My biggest strength is my unwavering dedication to
                              fostering unity and inclusivity within the community.
                            </span>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ProfessionalDetails;

import EmailVerified from "./EmailVerified";
import EmailVerify from "./ResendEmail";
import usePersonalDetails from "../usePersonalDetails";
import { useEffect } from "react";
import Loader from "components/common/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
const EmailVerification = () => {
  const { userData, userDataQuery } = usePersonalDetails();
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  //in case of logout redirect to login
  if (!isAuthenticated) {
    navigate("/login");
  }
  useEffect(() => {
    const fetchLatestEmailStatus = () => {
      if (!userData?.isEmailVerified) {
        userDataQuery.refetch();
      }
    };
    //check isEmailVerified status on interval of 5sec
    const intervalId = setInterval(fetchLatestEmailStatus, 5000);

    return () => clearInterval(intervalId);
  }, [userData?.isEmailVerified]);
  //verification email flow
  if (userDataQuery.isLoading) return <Loader />;
  if (!userData.isOnboardingCompleted) return <EmailVerified />;
  //profile flow
  return <>{userData?.isEmailVerified ? <EmailVerified /> : <EmailVerify />}</>;
};
export default EmailVerification;

import { useEffect, useState } from "react";
import InfoIconWarning from "img/svg/InfoIconWarning";
import { Link } from "react-router-dom";
import NoData from "img/svg/noData";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useQuery } from "@tanstack/react-query";
import { useGetElectionDetail } from "components/hooks/useGetElectionDetail";
import Spinner from "components/common/Spinner";
import DonorsTableV2 from "./donor-table/DonorsTableV2";
import UploadDonorsV2 from "./donor-table/UploadDonorsV2";

const FundraiseList = () => {
  const authAxios = CreateAuthAxiosInstance();

  const { electionDetail, isFetching: isFetchingElectionDetail } = useGetElectionDetail()

  const [hasDonorsList, setHasDonorsList] = useState<boolean>();
  const [hasDonorsListUploaded, setHasDonorsListUploaded] = useState<boolean>(false);
  const [hasDonorListUploadedManually, setHasDonorListUploadedManually] = useState<boolean>(false);

  const {
    data: donorData,
    isFetching: isFetchingDonorData,
    refetch: refetchDonorData
  } = useQuery({
    queryKey: ['donorList', electionDetail?.candidateId],
    queryFn: () => authAxios.get(`api/v1/donor/candidate/${electionDetail?.candidateId}`).then(data => data?.data),
    refetchOnWindowFocus: false,
    enabled: !!(electionDetail?.candidateId)
  })

  useEffect(() => {
    if (electionDetail) {
      setHasDonorsList(electionDetail?.hasDonorsList || false);
      setHasDonorsListUploaded(electionDetail?.hasDonorsListFileUploaded || false);
      setHasDonorListUploadedManually(electionDetail?.hasDonorListUploadedManually || false)
    }
  }, [electionDetail, isFetchingElectionDetail]);

  useEffect(() => {
    (async () => {
      try {
        if (electionDetail?.id) {
          const updatedUserResponse = await authAxios.patch(
            `api/v1/election/${electionDetail?.id}`,
            { hasDonorsListFileUploaded: hasDonorsListUploaded }
          );
        }
      } catch (error: any) {
        console.error("The user data was not updated because", error.message);
      }
    })();
  }, [hasDonorsListUploaded, electionDetail?.id]);

  const isDonorListUploadedAnyway = hasDonorsList || hasDonorsListUploaded || hasDonorListUploadedManually

  return (
    <>
      {(hasDonorsList === undefined || donorData?.total === undefined || hasDonorsListUploaded === undefined) ? (
        <div className="bg-white spinner-wrapper shadow-2">
          <Spinner />
        </div>
      ) : (
        <div className="d-flex align-items-stretch">
          <div className="wrapper active-cont">
            <>
              {(isDonorListUploadedAnyway || donorData?.total) ? (
                <main>
                  {/* Alert */}
                  {(donorData?.total === 0 && hasDonorsListUploaded) && <NoDonorListAlertMessage />}
                  {electionDetail?.id && (
                    <>
                      <ContactDonorSection
                        totalDonorCount={donorData?.total}
                      />
                      <DonorsTableV2 />
                    </>
                  )}
                </main>
              ) : (
                <NoDonorDataPage />
              )}
              {electionDetail?.id &&
                <UploadDonorsV2
                  electionId={electionDetail?.id}
                  hasDonorsFileUploaded={hasDonorsListUploaded}
                  setHasDonorsFileUploaded={setHasDonorsListUploaded}
                />
              }
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default FundraiseList;


const ContactDonorSection = ({
  totalDonorCount
}: {
  totalDonorCount: number
}) => {
  return (
    <div className="row mb-5">
      <div className="col-md-12">
        <div className="card card-flush h-100 shadow-2 flex-row justify-content-between align-items-center p-3">
          <div className="card-header contactCard-header d-flex gap-3 p-0 mb-0">
            <div className="iconBox bg-neutral-n4 mb-0">
              <img
                src={require("../../../img/patriotism.png")}
                className="img-fluid"
                width={32}
                height={32}
                alt=""
              />
            </div>
            <div className="card-header-content p-0">
              <h4 className="card-title">Contact Donors</h4>
              <p className="text-muted">
                Continue with contacting key donors
              </p>
            </div>
          </div>
          <div
            className={`contactOption-item contactOption-item-${totalDonorCount > 0 ? "" : "disabled"} mb-0`}
          >
            <Link
              to={`${totalDonorCount > 0 ? '/donors/contact-donor' : ''}`}
            >
              <div className="contactOption-item-content">
                <div className="contactOption-item-content-title pe-5">
                  Contact Donors
                </div>
              </div>
              <div className="contactOption-item-link">
                <span className="icon-arrow-right"></span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const NoDonorDataPage = () => {
  return (
    <main>
      <div className="row h-100 justify-content-center">
        <div className="col-md-6">
          <div className="centeredXY noData text-center justify-content-around">
            <div className="noData-header">
              <NoData />
              <h1 className="mt-6">No donor data available</h1>
              <p className="text-muted">
                Upload a file of any format containing information
                about potential donors or add manually from your
                personal network
              </p>

              <div className="mt-4">
                <Link
                  to=""
                  className="btn btn-primary rounded-pill px-5"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadModal"
                >
                  Add donor
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

const NoDonorListAlertMessage = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <InfoIconWarning />
          <span className="ms-2">
            Donor list will be uploaded soon.
          </span>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          >
            <span className="icon-close"></span>
          </button>
        </div>
      </div>
    </div>
  )
}

export const hasDuplicateCharacters = (str: string | null | undefined) => {
  if (!str) return

  return str.split('').some((char, index, arr) => arr.indexOf(char) !== index);
}


export const countCharacterOccurrences = (
  str: string,
  char: string
) => {
  let count = 0
  for (let temp of str) {
    if (char === temp)
      count++
  }

  return count
}
import SideNavbar from "../../components/common/navbar/SideNavbar";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import HelpIcon from "img/svg/helpIcon";
import PhoneOutlineIcon from "img/svg/phoneOutlineIcon";
import React, { useState } from "react";
import Breadcrumbs from "templates/components/Breadcrumbs";
import { Link } from "react-router-dom";
import { Tooltip } from "bootstrap";
import { useEffect } from "react";
import EyeIcon from "img/svg/eyeIcon";
import WarningIcon from "img/svg/warningIcon";
import CallerId from "templates/components/CallerId";
import PhoneFilledIcon from "img/svg/PhoneFilledIcon";
import CallEnded from "templates/components/call/CallEnded";
import VerifyNumber from "templates/components/VerifyNumber";
import TwilioCard from "templates/components/TwilioCard";

const ContactByPhoneCall = () => {
  const [isActivePopup, setIsActivePopup] = useState(true);
  const [isScriptActive, setIsScriptActive] = useState(true);
  let buttonText = isScriptActive ? "Hide Script" : "Show Script";

  const handleScript = () => {
    setIsScriptActive(!isScriptActive);
  };

  const voterStatusTooltip = `Tooltip for voter status`;
  const losTooltip = `Tooltip for level of support`;

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  const handleModalClick = ()=> {
    setIsActivePopup(true);
  }

  const handleCancelModal = ()=> {
    setIsActivePopup(false);
  }

  // "wrapper active-cont active-popup"

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />
      <div className={isActivePopup ? "wrapper active-cont active-popup" : "wrapper active-cont"}>
        <main>
          <div className="container">
            <div className="row">
              <div className="col-9">
                <Breadcrumbs />
              </div>
              {/* <div className="col-lg-3 col-12">
                <VerifyNumber onClickModal={handleModalClick}/>
              </div> */}
            </div>

            {/* Daily goal updates */}
            <div className="row justify-content-between mt-3">
              <div className="col-md-5">
                <div className="alert">
                  <div className="d-flex gap-4 voterGoal">
                    <div className="voter-goal-item">
                      <span className="ms-2">
                        {" "}
                        <span className="dot bg-success-s2"> </span>
                        <span className="fw-bold"> 1 of 10 </span>
                        Daily voter goal{" "}
                      </span>
                    </div>
                    <div className="voter-goal-item">
                      <span className="ms-2">
                        {" "}
                        <span className="dot bg-extended-e2"> </span>
                        <span className="fw-bold">1 of 186 </span>
                        Weekly voter goal{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="d-flex justify-content-end gap-2">

                <div className="callBox">
                     {/* <OnCall/> */}
                     <CallEnded />     
                          
                </div>

                <button
                  className="btn btn-success btn-xs px-4 rounded-pill text-white"
                
                >
                 <PhoneFilledIcon /> Call
                </button>
                <button
                  className="btn btn-info btn-xs rounded-pill"
                  data-bs-toggle="modal"
                  data-bs-target="#skipModal"
                >
                  Skip voter
                </button>
                <button
                  className="btn btn-primary rounded-pill btn-xs"
                  data-bs-toggle="modal"
                  data-bs-target="#successModal"
                >
                  Save and call next
                </button>
              </div>
                </div>
            </div>

            {/* Scripts and Response */}
            <div className="row">
              <div className="col-md-3">
                <div className="card mt-3 shadow-2">
                  <div className="card-body">
                    <div className="basic-info">
                      <div className="avatar m-auto border-0">
                        <span className="name fw-bold">MM</span>
                      </div>

                      <p className="mb-0 mt-2 fw-bold text-center">
                        {" "}
                        Marvin Mckinney
                      </p>
                      <p className="body-4 fw-bold text-center text-muted">
                        {" "}
                        Female{" "}
                      </p>
                      <p className="mb-4 fw-bold text-center">
                        {" "}
                        <PhoneOutlineIcon />{" "}
                        <span className="ms-2"> (603) 555-0123 </span>
                      </p>
                    </div>

                    <hr className="mb-4"></hr>

                    {/* voter Details */}

                    <div className="voter-details">
                      <h4 className="body-5"> Voter Details </h4>

                      <div className="row">
                        <div className="col-4 text-muted d-flex">Address</div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4">
                            1586 1st Avenue #2A, New York
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 text-muted d-flex">Age</div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4">40 </span>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-4 text-muted d-flex">
                          Party Affiliation
                        </div>
                        <div className="col d-flex align-items-center">
                          <span className="body-4">Democrats </span>
                        </div>
                      </div>
                    </div>

                    <h4 className="body-5"> Contact History </h4>

                    {/* <div className="contact-history">
                      <div className="timeline timeline-sm">
                        <div className="timeline-item pb-4">
                          <div className="timeline-step">
                            <span> </span>
                          </div>
                          <div className="timeline-content">
                            <h4 className="mb-1 body-4">
                              {" "}
                              Marked as{" "}
                              <span className="text-success body-5">
                                {" "}
                                Not Home{" "}
                              </span>{" "}
                            </h4>
                            <p className="text-muted fs-8">
                              Called on Aug{" "}
                              <span className="dot bg-neutral-n3 ms-1"> </span>{" "}
                              3:30pm
                            </p>
                          </div>
                        </div>

                        <div className="timeline-item pb-4">
                          <div className="timeline-step">
                            <span></span>
                          </div>
                          <div className="timeline-content">
                            <h4 className="mb-1 body-4">
                              {" "}
                              Marked as{" "}
                              <span className="text-success body-5">
                                {" "}
                                Not Home{" "}
                              </span>{" "}
                            </h4>
                            <p className="text-muted fs-8">
                              Called on Aug{" "}
                              <span className="dot bg-neutral-n3 ms-1"> </span>{" "}
                              3:30pm
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="d-flex gap-2 flex-row align-items-center">
                      <InfoIconSecondary />
                      <p className="m-0 text-muted fs-7">
                        {" "}
                        No tasks available for today{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="contactAction flex-row card card-flush card-fullHeight mt-3 shadow-2">
                  {/* script */}
                  <div
                    className={
                      isScriptActive
                        ? "contactAction-cardLeft scroll-y flex-1"
                        : "contactAction-cardLeft scroll-y flex-0"
                    }
                  >
                    <div className="card-header">
                      <h4 className="card-title text-primary"> Script</h4>
                    </div>

                    <div className="card-body">
                      <div className="card p-3 scroll-y script-wrapper shadow-6">
                        <p> Hi, is [Voter Name] available? </p>
                        <p>
                          Hi, [Voter Name], my name is [Candidate Name] and I'm
                          running for [Position], right here in [city voter
                          lives in]. How are you doing today?
                        </p>
                        <p>
                          The reason I'm calling today is because we have a
                          really important election upcoming for [Position].{" "}
                        </p>
                        <p>
                          I'm running for [Position] because [Strength 1] and
                          [Strength 2].
                        </p>
                        <p>Can I count on your support?</p>
                        <p>Thanks so much for your time today!</p>
                      </div>
                    </div>
                  </div>

                  <div className={isScriptActive ? "card-divider" : ""}></div>

                  {/* voter response */}
                  <div className="contactAction-cardRight flex-1">
                    <div className="card-header">
                      <h4 className="card-title"> Voter Response</h4>
                      <button
                        className="btn btn-link btn-sm p-1 ps-0 d-flex align-items-center gap-1"
                        onClick={handleScript}
                      >
                        {" "}
                        <EyeIcon /> {buttonText}
                      </button>
                    </div>
                    <div className="card-body">
                      <p className="body-4 text-muted">
                        Select either voter status or level of support
                      </p>

                      <form action="">
                        <div className="voter-status">
                          <div className="d-flex justify-content-between">
                            <label className="body-5"> Voter Status </label>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={voterStatusTooltip}
                              >
                                {" "}
                                <HelpIcon />
                              </Link>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="notHome"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="notHome"
                                >
                                  Not Home
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="moved"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="moved"
                                >
                                  Moved
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="deceased"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="deceased"
                                >
                                  Deceased
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="wrongNumber"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="wrongNumber"
                                >
                                  Wrong Number
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center py-3 mb-3">
                            <hr className="w-100" />
                            <div className="px-4 text-muted">or</div>
                            <hr className="w-100" />
                          </div>
                        </div>

                        <div className="level-of-support">
                          <div className="d-flex justify-content-between">
                            <label className="body-5"> Level of Support </label>
                            <div className="card-info">
                              <Link
                                to=""
                                data-bs-html="true"
                                data-bs-custom-class="custom-tooltip"
                                className="text-left"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title={losTooltip}
                              >
                                {" "}
                                <HelpIcon />
                              </Link>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="strongSupport"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="strongSupport"
                                >
                                  Strong Support
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="leanSupport"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="leanSupport"
                                >
                                  Lean Support
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="undecided"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="undecided"
                                >
                                  Undecided
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="leanAgainst"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="leanAgainst"
                                >
                                  Lean Against
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="btn-checkGroup-item mb-3 btn-checkGroup-item--icon">
                                <input
                                  type="radio"
                                  className="btn-check form-check-input"
                                  name="statusOption"
                                  id="strongAgainst"
                                  autoComplete="off"
                                />
                                <label
                                  className="btn btn-outline-primary w-100 ps-5 text-start"
                                  htmlFor="strongAgainst"
                                >
                                  Strong Against
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>

            {/* All attempted */}

            {/* <div className="row">
              <div className="card card-flush card-fullHeight mt-3 shadow-2">
                <div className="noData d-flex gap-2 align-items-center">
                    <SuccessIcon />
                    <h1 className="mt-6">All voters contacted </h1>
                    <p> <span className="fw-bold"> 10/10 </span> voters attempted today. Continue to complete other tasks</p>
                    <Link to="/dashboard" className="btn btn-primary rounded-pill px-5"> Continue to Homepage</Link>
                </div>
              </div>
            </div> */}
          </div>
        </main>

        <TwilioCard modalStatus={isActivePopup} onClickModal={handleCancelModal}/>
      </div>

      {/* Skip Modal */}
      <div
        className="modal fade"
        id="skipModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <WarningIcon />
              <h2 className="mt-3"> Do you want to skip ‘Marvin Mckinney’? </h2>
              <p>
                By skipping, all details entered for this voter from this
                contact will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, I Skip
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when user go to next page without confirming Modal */}
      <div
        className="modal fade"
        id="leaveModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Do you want to leave this page </h2>
              <p>
                By leaving, all details entered for this voter from this contact
                will be erased.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link btn-sm p-1 ps-0"
                data-bs-toggle="modal"
                data-bs-target="#skipModal"
              >
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, Leave
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* when save and call next voter after last step */}
      <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../img/success-modal.png")}
                width={56}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3"> Great Work! </h2>
              <p>
                <span className="fw-bold">9/10 </span> voters attempted today.
                Continue to complete other tasks
              </p>
            </div>

            <div className="modal-footer justify-content-center">
              <Link to="/" className="btn btn-primary rounded-pill">
                Continue to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactByPhoneCall;

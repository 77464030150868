import SideNavbar from "../../components/common/navbar/SideNavbar";
import { Link } from "react-router-dom";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import Email from "./socialMedia/Email";
import SocialMedia from "./socialMedia/SocialMedia";
import Confetti from "img/svg/confetti";
import AiIcon from "img/svg/AiIcon";
import MessageSendIcon from "img/svg/MessageSendIcon";
import AiChatBox from "templates/components/AiChatBox";
import HamburgerIcon from "img/svg/HamburgerIcon";
import PromptSuggestions from "templates/components/PromptSuggestions";
import LogoMark from "img/svg/logoMark";
import LogoOnly from "img/svg/logoOnly";
import InfoIconWarning from "img/svg/InfoIconWarning";
import TypingLoader from "templates/components/TypingLoader";
import DocumentIconSmall from "img/svg/DocumentIconSmall";
import CopyText from "templates/components/CopyText";
import FilterIcon from "img/svg/fiIterIcon";
import LinkIcon from "img/svg/LinkIcon";
import { useState } from "react";
import CustomTooltip from "components/common/CustomTooltip";

import InfoIconDanger from "img/svg/InfoIconDanger";
import DatePicker from "react-datepicker";
import TrashIcon from "img/svg/TrashIcon";
import TrashIconSmall from "img/svg/TrashIconSmall";
import PriorityListItem from "./priority/PriorityListItem";
import PriorityAddForm from "./priority/PriorityAddForm";
import CheckRectSmallIcon from "img/svg/CheckRectSmallIcon";
import AlertBanner from "templates/components/AlertBanner";
import NewsSlider from "templates/components/news/NewsSlider";
import PostCompleted from "./socialMedia/PostCompleted";
import SidePanelFilter from "templates/components/SidePanelFilter";

const ConversationalAI = () => {
  const [isShowHistory, setShowHistory] = useState(false);
  const handleClick = () => {
    setShowHistory(!isShowHistory);
  };

  const [isEditable, setEditable] = useState(false);

  const handlePriorityClick = () => {
    setEditable(true);
  };

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />
      <SidePanelFilter />

      <div className="wrapper active-cont">
        <main>
          {/* after setup */}
          {/* conversational ai card */}
          <div className="row justify-content-center mt-3">
            <div className="col-lg-8">
              <div className="card card-conversationlAi shadow-2">
                <div className="card-conversationlAi-content">
                  <h1 className="mb-2 text-gredient ">
                    {" "}
                    Welcome,
                    <span className="">
                      {" "}
                      future City Counselor Elenor!
                    </span>{" "}
                  </h1>

                  <div className="placeholder-glow">
                    <div className="placeholder col-12 mb-2 py-3"></div>
                  </div>

                  <p className="sub-text">
                    {" "}
                    <span className="text-primary-p3 font-semibold">
                      {" "}
                      134{" "}
                    </span>{" "}
                    days until elections. Here are the things to get started.{" "}
                  </p>

                   {/* paragraph placeholder */}
                  <div className="placeholder-glow text-center">
                    <p className="sub-text placeholder col-8"></p>
                  </div>

                  <PromptSuggestions />

                  {/* suggstion placeholder */}
                  <div className="d-flex h-100">
                    <div className="suggestion-box placeholder-glow">
                      <div className="placeholder col-4 flex-1 pt-5 px-2 pb-4 mb-3"></div>
                      <div className="placeholder col-4 flex-1 pt-5 px-2 pb-4 mb-3"></div>
                      <div className="placeholder col-4 flex-1 pt-5 px-2 pb-4 mb-3"></div>
                    </div>

                  </div>

                  <div
                    className=""
                    data-bs-toggle="modal"
                    data-bs-target="#chatAiModal"
                  >
                    <AiChatBox />
                  </div>
                </div>
              </div>

              <section className="mt-5">
                <div className="row justify-content-center">
                  <div className="col-lg-12 mb-5">
                    <div className="card card-taskList shadow-2 active">
                      <div className="card-taskList-header d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={require("../../img/avatar.png")}
                            style={{ width: "32px", height: "32px" }}
                            className="avatar border-0 img-fluid rounded-circle me-2 mb-0"
                            alt="User Avatar"
                          />
                          <h4 className="mb-0"> Top Priorities </h4>
                        </div>
                      </div>

                      <div className="card-taskList-body card-taskList-list">
                        {/* task list */}
                        <ul className="list-unstyled">
                          <li className="taskList-item success">
                            <div className="taskList-item-content">
                              <CustomTooltip content="Completed">
                                <div className="taskList-item-content-indicator"></div>
                              </CustomTooltip>
                              <Link
                                to=""
                                className="d-flex justify-content-between w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#getStarted"
                              >
                                <div>
                                  <span className="taskList-item-content-title">
                                    {" "}
                                    Approvals on different activities{" "}
                                  </span>
                                  <span className="d-block text-muted fs-8 font-regular d-flex align-items-center gap-1">
                                    <CheckRectSmallIcon /> 2 done in last 7 days
                                  </span>
                                </div>
                                <div className="taskList-item-redirect">
                                  <span className="icon-arrow-right"></span>
                                </div>
                              </Link>
                            </div>
                          </li>
                          <li className="taskList-item">
                            <div className="taskList-item-content">
                              <CustomTooltip content="Mark as complete">
                                <div className="taskList-item-content-indicator"></div>
                              </CustomTooltip>
                              <Link
                                to=""
                                className="d-flex justify-content-between w-100"
                              >
                                <div>
                                  <span className="taskList-item-content-title">
                                    {" "}
                                    Create your Campaign Website{" "}
                                  </span>
                                  <span className="d-block text-muted fs-8 font-regular">
                                    Consult the web design expert
                                  </span>
                                </div>
                                <div className="taskList-item-redirect">
                                  <span className="icon-arrow-right"></span>
                                </div>
                              </Link>
                            </div>
                          </li>

                          <PriorityListItem
                            onHandlePriority={handlePriorityClick}
                          />
                        </ul>

                        {/* top priorites list placeholder  */}
                        <div className="">
                          <div className="media media-sm py-2 placeholder-glow mb-3">
                            <div className="media-header bg-neutral-n4 border-0 rounded-circle">
                              <span className="placeholder"></span>
                            </div>
                            <div className="media-body w-100">
                              <h2 className=" placeholder col-9 placeholder-sm mb-0"></h2>
                              <span className="placeholder col-4 placeholder-sm"></span>
                            </div>
                          </div>
                          <div className="media media-sm py-2 placeholder-glow mb-3">
                            <div className="media-header bg-neutral-n4 border-0 rounded-circle">
                              <span className="placeholder"></span>
                            </div>
                            <div className="media-body w-100">
                              <h2 className=" placeholder col-9 placeholder-sm mb-0"></h2>
                              <span className="placeholder col-4 placeholder-sm"></span>
                            </div>
                          </div>
                          <div className="media media-sm py-2 placeholder-glow mb-3">
                            <div className="media-header bg-neutral-n4 border-0 rounded-circle">
                              <span className="placeholder"></span>
                            </div>
                            <div className="media-body w-100">
                              <h2 className=" placeholder col-9 placeholder-sm mb-0"></h2>
                              <span className="placeholder col-4 placeholder-sm"></span>
                            </div>
                          </div>
                        </div>

                        <form>
                          <PriorityAddForm isEditable={isEditable} />
                        </form>
                        {/* Add priority */}
                        {!isEditable && (
                          <button
                            className="btn btn-link no-hover btn-xs d-flex align-items-center gap-2"
                            onClick={handlePriorityClick}
                          >
                            <i className="icon-plus"> </i>Add priority
                          </button>
                        )}
                      </div>

                      {/* if no task list */}
                      {/* <div className="noData d-flex gap-2 flex-row align-items-center">
                        <InfoIconSecondary />{" "}
                        <p className="m-0"> No tasks available for today </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="col-lg-4">
              <div className="card shadow-2">
                <div className="card-news-header d-flex justify-content-between">
                  <div className="">
                    <h5> Related News </h5>
                    <span className="body-4 text-muted">4 March, 2024</span>
                  </div>

                  {/* news filter */}
                  <div className="card-toolbox">
                    <button
                      className="btn p-0 btn-filter active"
                      type="button"
                      
                    >
                      {" "}
                      <span className="">
                        {" "}
                        <FilterIcon />{" "}
                      </span>

                      <span className="dot dot-lg bg-success"></span>
                    </button>

                    {/* <div className="dropdown-menu dropdown-menu-checkbox">
                      <div className="dropdown-group">
                        <h6 className="dropdown-header">News Level </h6>
                        <ul className="dropdown-body list-unstyled mb-0">
                          <li>
                            <input
                              type="checkbox"
                              className="checkbox-round"
                              id="localFilter"
                            />
                            <label htmlFor="localFilter"> Local Level </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              className="checkbox-round"
                              id="stateFilter"
                            />
                            <label htmlFor="stateFilter"> State Level </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              className="checkbox-round"
                              id="nationalFilter"
                            />
                            <label htmlFor="nationalFilter">
                              {" "}
                              National Level{" "}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="card-news-body scroll-y">
                  <NewsSlider newsType="Local level" varient="success" />
                  <NewsSlider newsType="State level" varient="warning" />
                  <NewsSlider newsType="National level" varient="danger" />
                  {/* <Link to="">
                    <div className="card-news-body-item">
                      <div className="card-news-body-item-header">
                        <span className="d-flex gap-1 align-items-center">
                          {" "}
                          <LinkIcon />{" "}
                          <span className="news-title text-primary">
                            The Belmont Voice
                          </span>
                        </span>

                        <span className="dot dot-sm bg-secondary ms-1"></span>
                        <span className="badge badge-success text-n1 font-regular rounded-pill">
                          {" "}
                          Local level
                        </span>
                      </div>
                      <div className="card-news-body-item-body">
                        <ul className="card-news-content-list">
                          <li>
                            Luzerne County will continue mail ballot deficiency
                            curing for the April 23 primary election.
                            Approximately 20,000 mail ballots are expected to be
                            distributed in early April.
                          </li>
                          <li>
                            Approximately 20,000 mail ballots are expected to be
                            distributed in early April.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                  <Link to="">
                    <div className="card-news-body-item">
                      <div className="card-news-body-item-header">
                        <span className="d-flex gap-1 align-items-center">
                          {" "}
                          <LinkIcon />{" "}
                          <span className="news-title text-primary">
                            Masslive
                          </span>
                        </span>

                        <span className="dot dot-sm bg-secondary ms-1"></span>
                        <span className="badge badge-warning text-n1 font-regular rounded-pill">
                          {" "}
                          State level
                        </span>
                      </div>
                      <div className="card-news-body-item-body">
                        <ul className="card-news-content-list">
                          <li>
                            Luzerne County will continue mail ballot deficiency
                            curing for the April 23 primary election.
                            Approximately 20,000 mail ballots are expected to be
                            distributed in early April.
                          </li>
                          <li>
                            Approximately 20,000 mail ballots are expected to be
                            distributed in early April.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                  <Link to="">
                    <div className="card-news-body-item">
                      <div className="card-news-body-item-header">
                        <span className="d-flex gap-1 align-items-center">
                          {" "}
                          <LinkIcon />{" "}
                          <span className="news-title text-primary">
                            Masslive
                          </span>
                        </span>

                        <span className="dot dot-sm bg-secondary ms-1"></span>
                        <span className="badge badge-warning text-n1 font-regular rounded-pill">
                          {" "}
                          State level
                        </span>
                      </div>
                      <div className="card-news-body-item-body">
                        <ul className="card-news-content-list">
                          <li>
                            Luzerne County will continue mail ballot deficiency
                            curing for the April 23 primary election.
                            Approximately 20,000 mail ballots are expected to be
                            distributed in early April.
                          </li>
                          <li>
                            Approximately 20,000 mail ballots are expected to be
                            distributed in early April.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                  <Link to="">
                    <div className="card-news-body-item">
                      <div className="card-news-body-item-header">
                        <span className="d-flex gap-1 align-items-center">
                          {" "}
                          <LinkIcon />{" "}
                          <span className="news-title text-primary">
                            Politico
                          </span>
                        </span>

                        <span className="dot dot-sm bg-secondary ms-1"></span>
                        <span className="badge badge-danger text-n1 font-regular rounded-pill">
                          {" "}
                          National level
                        </span>
                      </div>
                      <div className="card-news-body-item-body">
                        <ul className="card-news-content-list">
                          <li>
                            Luzerne County will continue mail ballot deficiency
                            curing for the April 23 primary election.
                            Approximately 20,000 mail ballots are expected to be
                            distributed in early April.
                          </li>
                          <li>
                            Approximately 20,000 mail ballots are expected to be
                            distributed in early April.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>

                  <Link to="">
                    <div className="card-news-body-item">
                      <div className="card-news-body-item-header">
                        <span className="d-flex gap-1 align-items-center">
                          {" "}
                          <LinkIcon />{" "}
                          <span className="news-title text-primary">
                            Politico
                          </span>
                        </span>

                        <span className="dot dot-sm bg-secondary ms-1"></span>
                        <span className="badge badge-danger text-n1 font-regular rounded-pill">
                          {" "}
                          National level
                        </span>
                      </div>
                      <div className="card-news-body-item-body">
                        <ul className="card-news-content-list">
                          <li>
                            Luzerne County will continue mail ballot deficiency
                            curing for the April 23 primary election.
                            Approximately 20,000 mail ballots are expected to be
                            distributed in early April.
                          </li>
                          <li>
                            Approximately 20,000 mail ballots are expected to be
                            distributed in early April.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link> */}
                </div>

                {/* if no news list */}
                {/* <div className="noData d-flex gap-2 flex-row align-items-center">
                        <InfoIconSecondary />{" "}
                        <p className="m-0 text-muted"> No News for today </p>
                      </div> */}
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* conversational ai modal */}
      <div
        className="modal fade"
        id="chatAiModal"
        tabIndex={-1}
        aria-labelledby="chatAiModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="aiModalLayout">
              {/* left part */}

              <div
                className={
                  isShowHistory
                    ? `aiModalLayout-sidenav`
                    : `aiModalLayout-sidenav hide`
                }
              >
                <div className="modal-header">
                  <h5 className="modal-title"> Chat History </h5>
                  <CustomTooltip content="Add Prompt">
                    <button className="btn btn-xs px-0 py-0">
                      {" "}
                      <span className="icon-edit fs-5"> </span>
                    </button>
                  </CustomTooltip>
                </div>
                <div className="modal-body px-0">
                  {/* tab links */}
                  <ul className="nav flex-column" id="myTab" role="tablist">
                    <li className="nav-item">
                      <button
                        className="nav-link w-100 text-start"
                        aria-current="page"
                      >
                        {" "}
                        Today{" "}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link w-100 text-start active">
                        {" "}
                        24 Jan 2024{" "}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link w-100 text-start">
                        {" "}
                        25 Jan 2024{" "}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link w-100 text-start">
                        {" "}
                        23 Jan 2024{" "}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link w-100 text-start">
                        {" "}
                        22 Jan 2024{" "}
                      </button>
                    </li>
                  </ul>
                  <div className="loader-demo-box">
                    <div className="jumping-dots-loader">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>

              {/* right part */}
              <div className="aiModalLayout-content">
                <div className="modal-header text-center">
                  {/* for history : no need for now */}
                  <Link
                    to=""
                    onClick={handleClick}
                    className={
                      isShowHistory ? `toggle-history active` : `toggle-history`
                    }
                  >
                    {" "}
                    <HamburgerIcon />{" "}
                  </Link>
                  <div className="d-flex flex-column w-100">
                    <h1
                      className="modal-title text-gredient "
                      id="exampleModalLabel"
                    >
                      Welcome,
                      <span className="">
                        {" "}
                        future City Counselor Elenor!
                      </span>{" "}
                    </h1>

                    <p className="text-muted">
                      {" "}
                      <span className="text-primary-p3 font-semibold">
                        {" "}
                        134{" "}
                      </span>{" "}
                      days until election. Here are the things to get started.{" "}
                    </p>
                  </div>

                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="icon-close text-n1 h2"></span>
                  </button>
                </div>
                <div className="modal-body pb-0">
                  {/* questions */}
                  <div className="media media-md promptAi">
                    <div className="media-header">
                      <img
                        src={require("../.././img/avatar.png")}
                        className="img-fluid"
                        alt="User Avatar"
                      />
                    </div>
                    <div className="media-body">
                      <p className="mb-0">
                        {" "}
                        What is person events do you have a political
                        fundraiser?{" "}
                      </p>
                    </div>
                  </div>

                  {/* ai answers */}
                  <div className="media media-md promptAi pt-0">
                    <div className="media-header">
                      <LogoOnly />
                    </div>
                    <div className="media-body promptAi-answer">
                      {/* while generating answer */}
                      <TypingLoader />

                      {/* generated  answer will be here! */}
                      <div className="answer-wrapper">
                        <p>
                          Candidates often organize various political
                          fundraising events to generate financial support for
                          their campaigns. Here are some common types of
                          political fundraising events:
                          <ol>
                            <li>
                              {" "}
                              Campaign Kickoff Rally:
                              <ul>
                                <li> Donor Appreciation Dinners:</li>
                                <li>
                                  {" "}
                                  A large-scale event to officially launch the
                                  candidate's campaign.
                                </li>
                                <li>
                                  {" "}
                                  Attendees can make donations, and the
                                  candidate can outline their platform and
                                  goals.
                                </li>
                              </ul>
                            </li>

                            <li>
                              Donor Appreciation Dinners:
                              <ul>
                                <li>
                                  {" "}
                                  Exclusive dinners or receptions for major
                                  donors.{" "}
                                </li>
                              </ul>
                            </li>
                          </ol>
                        </p>

                        <CopyText />
                      </div>
                    </div>
                  </div>

                  {/* if Error Occured while generating answer */}
                  {/* <div className="media media-md promptAi">
    <div className="media-header border-0">
      <LogoOnly />
    </div>
    <div className="media-body">
      <div
        className="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        <span className="ms-2">
          Apologies, a “Network Error” occurred while responding
          to the prompt.
        </span>
      </div>
    </div>
  </div> */}

                  {/* suggestions : remove suggestions when answer or error displays */}
                  <PromptSuggestions />
                </div>
                <div className="modal-footer">
                  <AiChatBox />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Congratulations Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <Confetti />
              <h2 className="mt-3"> Congratulations!!! </h2>
              <p>
                {" "}
                Campaign Brain is now equipped to provide a personalized
                campaign strategy to guide you through Election Day.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Get Started: Social media post Modal */}
      <div
        className="modal fade"
        id="getStarted"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="taskModal">
              {/* right part */}
              <div className="taskModal-content">
                <div className="modal-header">
                  <div className="task-controls text-center">
                    <h5 className="modal-title">
                      Approvals on different posts
                    </h5>
                    <div className="task-staus">1 of 5 approvals</div>
                  </div>

                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="icon-close text-n1 h2"></span>
                  </button>
                </div>
                <div className="modal-body px-4">
                  <div className="postSlider-wrapper">
                    {/* Alert */}
                    {/* <div className="col-md-12">
                      <div
                        className="alert alert-warning alert-dismissible fade show"
                        role="alert"
                      >
                        <InfoIconWarning />{" "}
                        <span className="ms-2">
                          Connect social media account to directly post.
                        </span>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        >
                          <span className="icon-close"></span>
                        </button>
                      </div>
                    </div> */}
                    <SocialMedia />
                    <PostCompleted />
                    {/* <Email /> */}
                  </div>

                  <div className="status-btn">
                    {/* approved */}
                    {/* <button className="btn bg-success-s2 text-s1 rounded-pill me-3" disabled> <span className="icon-tick"></span> Approved </button>  */}

                    {/* rejected */}
                    <button
                      className="btn btn-reject rounded-pill me-3"
                      disabled
                    >
                      {" "}
                      <span className="icon-close"></span> Post Rejected{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationalAI;

import React from "react";
import CreatableSelect from "react-select/creatable";
import { CSSObjectWithLabel, MultiValue, OptionProps } from "react-select";
import TopIssueCustomOption from "./TopIssueCustomOption";

export interface Option {
  value: string;
  label: string;
}

export type TMultiValueSelectedOption = MultiValue<Option>;

interface ITopIssuesMultiSelectDdProps {
  selectedOptions: TMultiValueSelectedOption;
  onChange: (newValue: MultiValue<Option>) => void;
}

const TopIssuesMultiSelectDd: React.FC<ITopIssuesMultiSelectDdProps> = ({
  selectedOptions,
  onChange,
}) => {
  const options: Option[] = [
    { value: "Crime", label: "Crime" },
    { value: "Economy", label: "Economy" },
    { value: "Education", label: "Education" },
    { value: "Environment", label: "Environment" },
    { value: "Foreign Policy", label: "Foreign Policy" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Immigration", label: "Immigration" },
    { value: "Social Security", label: "Social Security" },
    { value: "Transportation", label: "Transportation" },
  ];

  return (
    <CreatableSelect
      options={options}
      components={{ Option: TopIssueCustomOption }}
      hideSelectedOptions={false}
      isMulti
      menuPosition="fixed"
      value={selectedOptions}
      onChange={(newValue) => {
        if (Array.isArray(newValue)) {
          onChange(newValue);
        } else {
          onChange(newValue as TMultiValueSelectedOption);
        }
      }}
      placeholder={<div> Choose or create issue preference </div>}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? "#0B45A5" : "#CBCBD8",
        }),
        option: (
          baseStyles: CSSObjectWithLabel,
          state: OptionProps<Option, true>
        ): CSSObjectWithLabel => {
          return {
            ...baseStyles,
            backgroundColor: state.isSelected
              ? "#F4F4F6"
              : state.isFocused
              ? "#F4F4F6"
              : undefined,
            color: "black",
          };
        },
      }}
    />
  );
};

export default TopIssuesMultiSelectDd;

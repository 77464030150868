import React from "react";
import { components, OptionProps } from "react-select";
import { Option } from "./TopIssuesMultiSelectDd";

const TopIssueCustomOption: React.FC<OptionProps<Option, true>> = (props) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        readOnly
        style={{ marginRight: 8 }}
      />
      {props.data.label}
    </components.Option>
  );
};

export default TopIssueCustomOption;

import React, { useEffect, useMemo, useState } from "react";
import { bool } from "yup";
import SingleSelectDd from "./SingleSelectDd";
import { IEditableTurfDetail } from "../TurfDetail";
import { useForm } from "react-hook-form";
import CreateAuthAxiosInstance from "utils/authAxios";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { queryClient } from "index";
import toastNotify from "utils/toastNotify";

interface ITurfDetailProp {
  isFullscreen: boolean;
  EditTurfDetails: IEditableTurfDetail | null;
  UpdateTurfName: UseMutateFunction<
    any,
    Error,
    {
      name: string;
    },
    unknown
  >;
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  turfAttemptedData: any;
  PostTurfAssignmentDetails:UseMutateFunction<any, Error, {
    turfId: number;
    candidateId: number | null;
    volunteerId: number | null;
}, unknown>,
  PatchTurfAssignmentDetails: UseMutateFunction<any, Error, {
    turfId: number;
    candidateId: number | null;
    volunteerId: number | null;
}, unknown>,
}

const EditTurfs = ({
  isFullscreen,
  EditTurfDetails,
  UpdateTurfName,
  setOpenEditModal,
  turfAttemptedData,
  PostTurfAssignmentDetails,
  PatchTurfAssignmentDetails,
}: ITurfDetailProp) => {

  const candidateId = localStorage.getItem("userId");
  const [selectedVolunteerId, setSelectedVolunteerId] = useState<number | null>(null);

  const form = useForm({
    defaultValues: {
      name: EditTurfDetails?.name,
      turfAttempted: EditTurfDetails?.turfAssignment
        ? {
            value:
              EditTurfDetails?.turfAssignment?.candidate?.id ??
              EditTurfDetails?.turfAssignment?.volunteer?.id,
            label:
              EditTurfDetails?.turfAssignment?.candidate?.fullName ??
              EditTurfDetails?.turfAssignment?.volunteer?.fullName,
          }
        : null,
    },
  });
  const { register, control, watch, handleSubmit, reset } = form;

  useEffect(() => {
    reset({
      name: EditTurfDetails?.name,
      turfAttempted: EditTurfDetails?.turfAssignment
        ? {
            value:
              EditTurfDetails.turfAssignment.candidate?.id ??
              EditTurfDetails.turfAssignment.volunteer?.id,
            label:
              EditTurfDetails.turfAssignment.candidate?.fullName ??
              EditTurfDetails.turfAssignment.volunteer?.fullName,
          }
        : null,
    });
  }, [EditTurfDetails, reset]);

  const handleVolunteerChange = (selectedOption: { value: number } | null) => {
    if (selectedOption !== null) {
      setSelectedVolunteerId(selectedOption.value);
    }
  };

  const onSubmit = (data: any) => {
    UpdateTurfName({ name: data?.name });

    const payload = {
      turfId: EditTurfDetails?.id!,
      candidateId:
        selectedVolunteerId === Number(candidateId)
          ? Number(candidateId)
          : null,
      volunteerId: selectedVolunteerId !== Number(candidateId) ? selectedVolunteerId : null,
    };

    if (EditTurfDetails?.turfAssignment) {
      PatchTurfAssignmentDetails(payload);
    } else {
      PostTurfAssignmentDetails(payload);
    }
  };

  const handleCancel = () => {
    setOpenEditModal(false);
    reset();
  };

  const isTurfMatched = useMemo(() => {
    const turfNameFromApi = EditTurfDetails?.name
    const turfAssignmentTitleFromApi = EditTurfDetails?.turfAssignment?.candidate?.fullName ?? EditTurfDetails?.turfAssignment?.volunteer?.fullName
    const currentEditModalData = watch()

    return turfNameFromApi === currentEditModalData?.name && turfAssignmentTitleFromApi === currentEditModalData?.turfAttempted?.label
  }, [EditTurfDetails, watch()])

  return (
    <div
      className={isFullscreen ? `editTurfWrapper h-auto` : `editTurfWrapper`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card shadow-6">
          <div className="card-header">
            <span className="body-2">Edit Turf</span>
          </div>
          <div className="card-body scroll-y">
            <div className="mb-3">
              <span className="body-4">Turf Details</span>
              <div>
                <span className="fs-7 text-muted">
                  {" "}
                  Total Voters: {EditTurfDetails?.voterCount}{" "}
                </span>
                <span className="dot dot-md bg-neutral-n3 mx-1"> </span>
                <span className="fs-7 text-muted">
                  {" "}
                  Total Doors: {EditTurfDetails?.doorCount}{" "}
                </span>
              </div>
            </div>
            <div className="mb-3 required">
              <label htmlFor="turd=fInputName1" className="form-label">
                Turf name
              </label>
              <input
                type="text"
                className="form-control"
                id="turd=fInputName1"
                aria-describedby="nameHelp"
                placeholder="Enter turf name"
                {...register("name")}
              />
            </div>

            <div className="mb-3 required">
              <label htmlFor="turdAssign" className="form-label">
                Assign to
              </label>
              <SingleSelectDd
                placeholder="Choose assignee"
                control={control}
                EditTurfDetails={EditTurfDetails}
                turfAttemptedData={turfAttemptedData}
                onVolunteerChange={handleVolunteerChange}
              />
            </div>
          </div>
          <div className="card-footer">
            <button
              type="button"
              className="btn btn-link btn-xs rounded-pill me-2"
              onClick={() => handleCancel()}
            >
              {" "}
              Cancel{" "}
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-xs rounded-pill"
              disabled={isTurfMatched}
            >
              {" "}
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditTurfs;

import NoNetwork from "img/svg/noNetwork";
import { Link } from "react-router-dom";

const NetworkError = ({ TryAgainHandler }: { TryAgainHandler?: () => void }) => {
  return (
    <div className="centeredXY noNetwork text-center justify-content-around">
      <div className="noNetwork-header">
        <NoNetwork />
        <h1 className="mt-6">No internet connection</h1>
        <p className="text-muted">
          Could not connect to the internet. Please check your network
        </p>

        <div className="mt-4">
          <Link
            to="" className="fw-bold"
            onClick={TryAgainHandler}
          >
            Try Again
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NetworkError;

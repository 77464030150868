import React, { useEffect, useRef, useState } from "react";
import TableFilter from "./TableFilter";
import { ASC, BY_PHONE, DOOR_TO_DOOR, NOT_CONTACTED } from "./VotersList";
import axios from "axios";
import { CANDIDATE_ID } from "components/pages/Homepage";
import TableData from "./TableData";
import Loader from "components/common/Loader";
import VotersListAlert from "../VotersListAlert";

export interface IVotersList {
  address: string;
  name: string;
  voterIdNumber: number;
  contactType: string;
  voterContactHistories: [
    { status: string; levelOfSupport: string; updatedAt: string }
  ];
}

interface IVoterStats {
  contactedByPhone: number;
  contactedDoorToDoor: number;
  notContactedVoters: number;
  totalVoters: number;
}

interface IVotersTableProps {
  hasVotersListFileUploaded: boolean;
  electionId: number | null;
}

const VotersTable: React.FC<IVotersTableProps> = ({
  hasVotersListFileUploaded,
  electionId,
}) => {
  const retrivedCandidateId = localStorage.getItem(CANDIDATE_ID);
  const [voterStats, setVoterStats] = useState<IVoterStats>();

  const [totalVotersList, setTotalVotersList] = useState<Array<IVotersList>>(
    []
  );
  const [notContactedVotersList, setNotContactedVotersList] = useState<
    Array<IVotersList>
  >([]);
  const [contactedByPhoneList, setContactedByPhoneList] = useState<
    Array<IVotersList>
  >([]);
  const [contactedDoorToDoorList, setContactedDoorToDoorList] = useState<
    Array<IVotersList>
  >([]);
  const [sortingOrder, setSortingOrder] = useState(ASC);
  const scrollRefTotalVoters = useRef<HTMLDivElement>(null);
  const scrollRefNotContacted = useRef<HTMLDivElement>(null);
  const scrollRefContactedByPhone = useRef<HTMLDivElement>(null);
  const scrollRefContactedDoorToDoor = useRef<HTMLDivElement>(null);

  const [votersLimit, setVotersLimit] = useState(10);

  const [selectedVoterStatus, setSelectedVoterStatus] = useState("");
  const [selectedLevelOfSupport, setSelectedLevelOfSupport] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>();

  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isVotersLoading, setIsVotersLoading] = useState(false);

  const [contactType, setContactType] = useState<string>();

  const loadMore = (scrollDiv: HTMLDivElement) => {
    if (scrollDiv) {
      const scrollHeight = scrollDiv.scrollHeight;
      const scrollTop = scrollDiv.scrollTop;
      const clientHeight = scrollDiv.clientHeight;
      const toBottom = scrollHeight - clientHeight - scrollTop;
      // When scroll position is at bottom then it will load more voters
      if (toBottom <= 10 && !isVotersLoading) {
        setIsVotersLoading(true);
        setVotersLimit(votersLimit + 10);
      }
    }
  };

  useEffect(() => {
    // For triggering loadMore function
    const totalVotersScrollDiv = scrollRefTotalVoters.current;
    if (
      totalVotersScrollDiv &&
      voterStats?.totalVoters !== totalVotersList.length
    )
      totalVotersScrollDiv.addEventListener("scroll", () =>
        loadMore(totalVotersScrollDiv)
      );

    const notContactedVotersScrollDiv = scrollRefNotContacted.current;
    if (
      notContactedVotersScrollDiv &&
      voterStats?.notContactedVoters !== notContactedVotersList.length
    )
      notContactedVotersScrollDiv.addEventListener("scroll", () =>
        loadMore(notContactedVotersScrollDiv)
      );

    const contactedByPhoneScrollDiv = scrollRefContactedByPhone.current;
    if (
      contactedByPhoneScrollDiv &&
      voterStats?.contactedByPhone !== contactedByPhoneList.length
    )
      contactedByPhoneScrollDiv.addEventListener("scroll", () =>
        loadMore(contactedByPhoneScrollDiv)
      );

    const contactedDoorToDoorScrollDiv = scrollRefContactedByPhone.current;
    if (
      contactedDoorToDoorScrollDiv &&
      voterStats?.contactedDoorToDoor !== contactedDoorToDoorList.length
    )
      contactedDoorToDoorScrollDiv.addEventListener("scroll", () =>
        loadMore(contactedDoorToDoorScrollDiv)
      );

    return () => {
      if (totalVotersScrollDiv)
        totalVotersScrollDiv.removeEventListener("scroll", () =>
          loadMore(totalVotersScrollDiv)
        );

      if (notContactedVotersScrollDiv)
        notContactedVotersScrollDiv.removeEventListener("scroll", () =>
          loadMore(notContactedVotersScrollDiv)
        );

      if (contactedByPhoneScrollDiv)
        contactedByPhoneScrollDiv.removeEventListener("scroll", () =>
          loadMore(contactedByPhoneScrollDiv)
        );

      if (contactedDoorToDoorScrollDiv)
        contactedDoorToDoorScrollDiv.removeEventListener("scroll", () =>
          loadMore(contactedDoorToDoorScrollDiv)
        );
    };
  });

  useEffect(() => {
    (async () => {
      try {
        if (retrivedCandidateId) {
          // Get voters stats
          const voterStatsResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/voter/candidate/${retrivedCandidateId}/contact-stats`
          );

          setVoterStats(voterStatsResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [retrivedCandidateId]);

  useEffect(() => {
    (async () => {
      try {
        if (retrivedCandidateId) {
          let searchQuery: string | undefined;
          if (!searchTerm || searchTerm.length > 2) {
            if (searchTerm) {
              setIsSearchLoading(true);
              searchQuery = `&name=${searchTerm}`;
            }

            if (contactType) {
              // Get voters list based on contact types
              const votersByContactTypesResponse = await axios.get(
                `${
                  process.env.REACT_APP_API_BASE_URL
                }/api/v1/voter/candidate/${retrivedCandidateId}/contacts?limit=${votersLimit}&order=${sortingOrder}${
                  selectedVoterStatus ? "&status=" + selectedVoterStatus : ""
                }${
                  selectedLevelOfSupport
                    ? "&levelOfSupport=" + selectedLevelOfSupport
                    : ""
                }${searchQuery ? searchQuery : ""}${
                  contactType ? "&contactType=" + contactType : ""
                }`
              );

              if (contactType === NOT_CONTACTED) {
                setNotContactedVotersList(
                  votersByContactTypesResponse.data.items
                );
              }
              if (contactType === BY_PHONE) {
                setContactedByPhoneList(
                  votersByContactTypesResponse.data.items
                );
              }
              if (contactType === DOOR_TO_DOOR) {
                setContactedDoorToDoorList(
                  votersByContactTypesResponse.data.items
                );
              }
            } else {
              // Get total voters list
              const totalVotersListResponse = await axios.get(
                `${
                  process.env.REACT_APP_API_BASE_URL
                }/api/v1/voter/candidate/${retrivedCandidateId}?limit=${votersLimit}&order=${sortingOrder}${
                  selectedVoterStatus ? "&status=" + selectedVoterStatus : ""
                }${
                  selectedLevelOfSupport
                    ? "&levelOfSupport=" + selectedLevelOfSupport
                    : ""
                }${searchQuery ? searchQuery : ""}`
              );
              setTotalVotersList(totalVotersListResponse.data.items);
            }

            setIsSearchLoading(false);
            setIsVotersLoading(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [
    retrivedCandidateId,
    sortingOrder,
    votersLimit,
    selectedVoterStatus,
    selectedLevelOfSupport,
    searchTerm,
    contactType,
  ]);

  return (
    <div className="d-flex align-items-stretch">
      <div className="wrapper active-cont">
        <ul className="nav nav-tabs nav-flush" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
              onClick={() => setContactType(undefined)}
            >
              All voters{" "}
              <span className="badge badge-secondary ms-2 badge-xs">
                {voterStats?.totalVoters}
              </span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
              onClick={() => setContactType(NOT_CONTACTED)}
            >
              Not Contacted{" "}
              <span className="badge badge-secondary ms-2 badge-xs">
                {voterStats?.notContactedVoters}
              </span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact-tab-pane"
              type="button"
              role="tab"
              aria-controls="contact-tab-pane"
              aria-selected="false"
              onClick={() => setContactType(BY_PHONE)}
            >
              Contacted By Phone{" "}
              <span className="badge badge-secondary ms-2 badge-xs">
                {" "}
                {voterStats?.contactedByPhone}
              </span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="door-tab"
              data-bs-toggle="tab"
              data-bs-target="#door-tab-pane"
              type="button"
              role="tab"
              aria-controls="door-tab-pane"
              aria-selected="false"
              onClick={() => setContactType(DOOR_TO_DOOR)}
            >
              Contacted Door to Door{" "}
              <span className="badge badge-secondary ms-2 badge-xs">
                {" "}
                {voterStats?.contactedDoorToDoor}
              </span>
            </button>
          </li>
        </ul>

        <main>
          <div className="row mt-7">
            <div className="col-md-12">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex={0}
                >
                  {hasVotersListFileUploaded && <VotersListAlert />}
                  <TableFilter
                    votersList={totalVotersList}
                    sortingOrder={sortingOrder}
                    setSortingOrder={setSortingOrder}
                    selectedVoterStatus={selectedVoterStatus}
                    setSelectedVoterStatus={setSelectedVoterStatus}
                    selectedLevelOfSupport={selectedLevelOfSupport}
                    setSelectedLevelOfSupport={setSelectedLevelOfSupport}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    electionId={electionId}
                  />

                  {isSearchLoading ? (
                    <Loader />
                  ) : (
                    <TableData
                      voters={totalVotersList}
                      scrollRef={scrollRefTotalVoters}
                      isLoadingVotersCompleted={
                        voterStats?.totalVoters === totalVotersList.length
                      }
                    />
                  )}
                </div>

                <div
                  className="tab-pane fade"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabIndex={1}
                >
                  <TableFilter
                    votersList={notContactedVotersList}
                    sortingOrder={sortingOrder}
                    setSortingOrder={setSortingOrder}
                    selectedVoterStatus={selectedVoterStatus}
                    setSelectedVoterStatus={setSelectedVoterStatus}
                    selectedLevelOfSupport={selectedLevelOfSupport}
                    setSelectedLevelOfSupport={setSelectedLevelOfSupport}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    electionId={electionId}
                  />

                  {isSearchLoading ? (
                    <Loader />
                  ) : (
                    <TableData
                      voters={notContactedVotersList}
                      scrollRef={scrollRefNotContacted}
                      isLoadingVotersCompleted={
                        voterStats?.notContactedVoters ===
                        notContactedVotersList.length
                      }
                    />
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="contact-tab-pane"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                  tabIndex={2}
                >
                  <TableFilter
                    votersList={contactedByPhoneList}
                    sortingOrder={sortingOrder}
                    setSortingOrder={setSortingOrder}
                    selectedVoterStatus={selectedVoterStatus}
                    setSelectedVoterStatus={setSelectedVoterStatus}
                    selectedLevelOfSupport={selectedLevelOfSupport}
                    setSelectedLevelOfSupport={setSelectedLevelOfSupport}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    electionId={electionId}
                  />

                  {isSearchLoading ? (
                    <Loader />
                  ) : (
                    <TableData
                      voters={contactedByPhoneList}
                      scrollRef={scrollRefContactedByPhone}
                      isLoadingVotersCompleted={
                        voterStats?.contactedByPhone ===
                        contactedByPhoneList.length
                      }
                    />
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="door-tab-pane"
                  role="tabpanel"
                  aria-labelledby="door-tab"
                  tabIndex={3}
                >
                  <TableFilter
                    votersList={contactedDoorToDoorList}
                    sortingOrder={sortingOrder}
                    setSortingOrder={setSortingOrder}
                    selectedVoterStatus={selectedVoterStatus}
                    setSelectedVoterStatus={setSelectedVoterStatus}
                    selectedLevelOfSupport={selectedLevelOfSupport}
                    setSelectedLevelOfSupport={setSelectedLevelOfSupport}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    electionId={electionId}
                  />

                  {isSearchLoading ? (
                    <Loader />
                  ) : (
                    <TableData
                      voters={contactedDoorToDoorList}
                      scrollRef={scrollRefContactedDoorToDoor}
                      isLoadingVotersCompleted={
                        voterStats?.contactedDoorToDoor ===
                        contactedDoorToDoorList.length
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default VotersTable;

import InfoIconDanger from "img/svg/InfoIconDanger"
import TrashIcon from "img/svg/TrashIcon"
import FileIcon from "img/svg/fileIcon"
import { ALLOWED_AUDIO_FILE_SIZE, isValidAudioSize, isValidAudioType } from "./VoiceMailUpload"

const SelectedVoiceMailCard = ({
  selectedAudioFile,
  setSelectedAudioFile,
  isValidAudio,
  handleFileChange,
  uploadVoiceMailMutationStatus
}: {
  selectedAudioFile: File | null,
  setSelectedAudioFile: React.Dispatch<React.SetStateAction<File | null>>,
  isValidAudio: () => boolean,
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  uploadVoiceMailMutationStatus: string
}) => {
  return (
    <>
      <div className="uploadedCard">
        <div className="uploadedCard-icon">
          <div className="iconBox">
            <FileIcon />
          </div>

          <div className="iconInfo">
            <span className="iconInfo-title">
              {selectedAudioFile?.name ?? 'N/A'}
            </span>

            {isValidAudio() && uploadVoiceMailMutationStatus === 'pending' &&
              <p className="iconInfo-desc">
                {/* {typeof selectedAudioFile?.size === 'number' ? formatFileSize(selectedAudioFile?.size) : 'N/A'} */}
                Uploading....
              </p>
            }

            <span className="iconInfo-status">
              {/* Error before uploading */}
              {!isValidAudioType(selectedAudioFile?.type) ? (
                <>
                  <InfoIconDanger />
                  <span className="text-danger">
                    <strong> Error: </strong> Invalid format
                  </span>
                </>
              ) : (
                <>
                  {!isValidAudioSize(selectedAudioFile?.size) &&
                    <>
                      <InfoIconDanger />
                      <span className="text-danger">
                        <strong> Error: </strong> Size exceeds {ALLOWED_AUDIO_FILE_SIZE} MB limit
                      </span>
                    </>
                  }
                </>
              )}

              {/* Error while uploading */}
              {uploadVoiceMailMutationStatus === 'error' &&
                <>
                  <InfoIconDanger />
                  <span className="text-danger">
                    <strong> Error: </strong> Couldn't upload. Please try again.
                  </span>
                </>
              }

              {/* <>
                <InfoIconDanger />
                <span className="text-danger">
                  <strong> Error: </strong> Upload time out
                </span>
              </> */}
            </span>
          </div>
        </div>
        <div className="d-flex">
          {uploadVoiceMailMutationStatus !== 'pending' &&
            <>
              <div
                className="uploadedCard-info file-btn "
                style={{ overflow: 'hidden' }}
              >
                <input
                  type="file"
                  name="changedFile"
                  onChange={handleFileChange}
                />
                <button
                  className="btn btn-link rounded-pill text-primary"
                >
                  Change
                </button>
              </div>
            </>
          }

          {!(isValidAudio() && uploadVoiceMailMutationStatus !== 'error') &&
            <>
              <button
                className="btn btn-link no-hover px-1 text-primary"
                onClick={() => setSelectedAudioFile(null)}
              >
                <TrashIcon />
              </button>
            </>
          }
        </div>
      </div >
    </>
  )
}

export default SelectedVoiceMailCard
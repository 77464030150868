import { createContext, useReducer, Dispatch, useContext } from "react";
import candidateReducer, {
  initialCandidateReducerState,
} from "../reducers/candidateReducer";
import { ICandidateReducerState } from "../reducers/iCandidateReducer";
import { CandidateAction } from "../actions/iCandidateActions";

interface ICandidateContextValue {
  candidateState: ICandidateReducerState;
  candidateDispatch: Dispatch<CandidateAction>;
}

const CandidateContext = createContext<ICandidateContextValue>({
  candidateState: initialCandidateReducerState,
  candidateDispatch: () => {},
});

export const CandidateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    candidateReducer,
    initialCandidateReducerState
  );

  return (
    <CandidateContext.Provider
      value={{
        candidateState: state,
        candidateDispatch: dispatch,
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
};

export const useCandidateContext = () => {
  const context = useContext(CandidateContext);
  if (context === undefined) {
    throw new Error("Context not found");
  }
  return context;
};

import InfoIconDanger from "img/svg/InfoIconDanger";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import HelpIcon from "img/svg/helpIcon";
import ThreeDots from "img/svg/threeDots";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "templates/components/Spinner";
import DatePicker from "react-datepicker";
import {  toast } from "react-toastify";
import CheckSuccess from "img/svg/checkSuccess";

const CallLogsDetails = () => {
  const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  // const [selectedDate, setSelctedDate] = useState([startDate, endDate])

  const totalCallTimeTooltip = `The combined amount of time spent on calls by candidate and volunteers while reaching out to voters and donors.`;
  const totalCostTooltip = `Total Voter Contacts shows how many successful conversations you have had with target voters. Each voter that you successfully reach is shown in the numerator, while the denominator is the goal for your entire campaign`;
  const billingTooltip = `Current billing period shows the total cost incurred from the start of the month to today’s date.`;

  const notify = () => {
    // toast("Wow so easy!");

    toast.success("Call logs exported !", {
      className: "alert alert-success",
      position: toast.POSITION.TOP_RIGHT,
      theme: "light",
      hideProgressBar: true,
      autoClose: 5000,
      icon: <CheckSuccess />,
    });
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="mt-5">
            <div className="settings">
              <div className="settings-header d-flex justify-content-between align-items-end">
                <div className="">
                  <h4> Logs </h4>
                  <p className="body-4 text-muted">
                    {" "}
                    View the history for contacting voters and donors.{" "}
                  </p>
                </div>

                {/* export */}

                {/* <CSVLink
                  data=""
                  filename={"voters-list.csv"}
                  className="btn btn-info rounded-pill btn-xs px-4"
                  target="_blank"
                >
                  {" "}
                  Export CSV{" "}
                </CSVLink> */}

                <button
                  className="btn btn-info rounded-pill btn-xs px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exportCsvModal"
                >
                  {" "}
                  Export CSV{" "}
                </button>
              </div>

              <div className="settings-body">
                <div className="voterDetail-info">
                  <hr className="mt-3 mb-3" />

                  <div className="row mt-4">
                    <div className="col-md-3 col-12 mb-3">
                      <div className="card card-flush h-100 border-1">
                        <div className="card-header d-flex justify-content-between">
                          <h4 className="card-title"> Total Call Time </h4>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title={totalCallTimeTooltip}
                            >
                              {" "}
                              <HelpIcon />{" "}
                            </Link>
                          </div>
                        </div>
                        <div className="card-body pt-1 d-flex flex-column justify-content-end">
                          <div className="d-flex align-items-end">
                            <h1 className="mb-0 me-2">00:09:93</h1>{" "}
                          </div>

                          {/* placeholder design */}
                          <div className="placeholder-glow w-100">
                            <div className="placeholder col-6 py-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-12 mb-3">
                      <div className="card card-flush h-100 border-1">
                        <div className="card-header d-flex justify-content-between">
                          <h4 className="card-title"> Total Cost </h4>
                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title={totalCostTooltip}
                            >
                              {" "}
                              <HelpIcon />{" "}
                            </Link>
                          </div>
                        </div>
                        <div className="card-body pt-1 d-flex flex-column justify-content-end">
                          <div className="d-flex align-items-end">
                            <h1 className="mb-0 me-2">
                              $<span className=""> 4.20 </span>
                            </h1>{" "}
                          </div>

                          {/* placeholder design */}
                          <div className="placeholder-glow w-100">
                            <div className="placeholder col-6 py-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-12 mb-3">
                      <div className="card card-flush h-100 border-1">
                        <div className="card-header d-flex justify-content-between">
                          <div>
                            <h4 className="card-title">
                              {" "}
                              Current Billing Period{" "}
                            </h4>
                            <span className="fs-8 text-muted">
                              02/01/24 - 02/01/24
                            </span>
                          </div>

                          <div className="card-info">
                            <Link
                              to=""
                              data-bs-html="true"
                              data-bs-custom-class="custom-tooltip"
                              className="text-left"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              data-bs-title={billingTooltip}
                            >
                              {" "}
                              <HelpIcon />{" "}
                            </Link>
                          </div>
                        </div>
                        <div className="card-body pt-1 d-flex flex-column justify-content-end">
                          <div className="d-flex align-items-end">
                            <h1 className="mb-0 me-2">
                              $<span className=""> 0.00 </span>
                            </h1>{" "}
                            {/* <Link
                              className="px-3 py-2"
                              to=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDots />
                            </Link>
                            <div className="dropdown-menu">
                              <div className="dropdown-group">
                                <ul className="dropdown-body list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#billingFilterModal"
                                    >
                                      {" "}
                                      Filter billing period
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </div>

                          {/* placeholder design */}
                          <div className="placeholder-glow w-100">
                            <div className="placeholder col-6 py-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-fullHeight scroll-y mt-3 border-1 card-turfDetails">
                        {/* table */}
                        <table className="table table-full table-eq-height table-hover">
                          <thead>
                            <tr>
                              <th>Date and Time </th>
                              <th>Caller Id</th>
                              <th> Receiver ID</th>
                              <th> Call Type </th>
                              <th> Status </th>
                              <th> Voicemail </th>
                              <th> Duration </th>
                              <th> Cost ($) </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {" "}
                                <span> 03/03/24, </span> <span> 07:23:54</span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span> +12335093431 </span>
                                <span className="d-block fs-8 font-regular text-muted">
                                  Elenor Parker
                                </span>
                              </td>
                              <td>
                                {" "}
                                <span> +12335093431 </span>{" "}
                                <span className="d-block fs-8 font-regular text-muted">
                                  {" "}
                                  Marvin Mckinney{" "}
                                </span>{" "}
                              </td>
                              <td> Voter </td>
                              <td>
                                <span className="badge badge-success font-semibold rounded-pill">
                                  <span className="icon-phone-filled fs-8 text-success">
                                    {" "}
                                  </span>{" "}
                                  <span className="badge-label">Completed</span>
                                </span>

                                {/* <span className="d-block"> - </span>  */}
                              </td>
                              <td>
                                <span className="d-flex align-items-center gap-2">
                                  <span className="icon-close-circle fs-6 text-danger">
                                    {" "}
                                  </span>
                                  No
                                </span>
                              </td>
                              <td>
                                00:00:49
                                {/* <span className="d-block"> - </span> */}
                              </td>
                              <td className="text-center">$ 0.30</td>
                            </tr>

                            <tr>
                              <td>
                                {" "}
                                <span> 03/03/24, </span> <span> 07:23:54</span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span> +12335093431 </span>
                                <span className="d-block fs-8 font-regular text-muted">
                                  Elenor Parker
                                </span>
                              </td>
                              <td>
                                {" "}
                                <span> +12335093431 </span>{" "}
                                <span className="d-block fs-8 font-regular text-muted">
                                  {" "}
                                  Jerome Bell{" "}
                                </span>{" "}
                              </td>
                              <td> Donor </td>
                              <td>
                                <span className="badge badge-warning font-semibold rounded-pill">
                                  <span className="icon-phone-missed-call fs-8 text-warning">
                                    {" "}
                                  </span>{" "}
                                  <span className="badge-label"> Busy</span>
                                </span>

                                {/* <span className="d-block"> - </span>  */}
                              </td>
                              <td>
                                <span className="d-flex align-items-center gap-2">
                                  <span className="icon-check-circle fs-6 text-success">
                                    {" "}
                                  </span>
                                  Yes
                                </span>
                              </td>
                              <td>
                                00:01:49
                                {/* <span className="d-block"> - </span> */}
                              </td>
                              <td className="text-center">$ 0.90</td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <span> 03/03/24, </span> <span> 07:23:54</span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span> +12335093431 </span>
                                <span className="d-block fs-8 font-regular text-muted">
                                  Elenor Parker
                                </span>
                              </td>
                              <td>
                                {" "}
                                <span> +12335093431 </span>{" "}
                                <span className="d-block fs-8 font-regular text-muted">
                                  {" "}
                                  Jerome Bell{" "}
                                </span>{" "}
                              </td>
                              <td> Donor </td>
                              <td>
                                <span className="badge badge-danger font-semibold rounded-pill">
                                  <span className="icon-phone-missed-call fs-8 text-danger">
                                    {" "}
                                  </span>{" "}
                                  <span className="badge-label"> Failed </span>
                                </span>

                                {/* <span className="d-block"> - </span>  */}
                              </td>
                              <td>
                                <span className="d-flex align-items-center gap-2">
                                  <span className="icon-check-circle fs-6 text-success">
                                    {" "}
                                  </span>
                                  Yes
                                </span>
                              </td>
                              <td>
                                00:00:49
                                {/* <span className="d-block"> - </span> */}
                              </td>
                              <td className="text-center">$ 0.60</td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <span> 03/03/24, </span> <span> 07:23:54</span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span> +12335093431 </span>
                                <span className="d-block fs-8 font-regular text-muted">
                                  Elenor Parker
                                </span>
                              </td>
                              <td>
                                {" "}
                                <span> +12335093431 </span>{" "}
                                <span className="d-block fs-8 font-regular text-muted">
                                  {" "}
                                  Jerome Bell{" "}
                                </span>{" "}
                              </td>
                              <td> Donor </td>
                              <td>
                                <span className="badge badge-primary5 font-semibold rounded-pill">
                                  <span className="icon-phone-missed-call fs-8 text-primary">
                                    {" "}
                                  </span>{" "}
                                  <span className="badge-label">
                                    {" "}
                                    No answer{" "}
                                  </span>
                                </span>

                                {/* <span className="d-block"> - </span>  */}
                              </td>
                              <td>
                                <span className="d-flex align-items-center gap-2">
                                  <span className="icon-check-circle fs-6 text-success">
                                    {" "}
                                  </span>
                                  Yes
                                </span>
                              </td>
                              <td>
                                00:01:40
                                {/* <span className="d-block"> - </span> */}
                              </td>
                              <td className="text-center">$ 0.60</td>
                            </tr>
                            <tr>
                              <td>
                                {" "}
                                <span> 03/03/24, </span> <span> 07:23:54</span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span> +12335093431 </span>
                                <span className="d-block fs-8 font-regular text-muted">
                                  Elenor Parker
                                </span>
                              </td>
                              <td>
                                {" "}
                                <span> +12335093431 </span>{" "}
                                <span className="d-block fs-8 font-regular text-muted">
                                  {" "}
                                  Jerome Bell{" "}
                                </span>{" "}
                              </td>
                              <td> Voter </td>
                              <td>
                                <span className="badge badge-success font-semibold rounded-pill">
                                  <span className="icon-phone-filled fs-8 text-success">
                                    {" "}
                                  </span>{" "}
                                  <span className="badge-label">
                                    {" "}
                                    Completed{" "}
                                  </span>
                                </span>

                                {/* <span className="d-block"> - </span>  */}
                              </td>
                              <td>
                                <span className="d-flex align-items-center gap-2">
                                  <span className="icon-close-circle fs-6 text-danger">
                                    {" "}
                                  </span>
                                  No
                                </span>
                              </td>
                              <td>
                                00:01:40
                                {/* <span className="d-block"> - </span> */}
                              </td>
                              <td className="text-center">$ 0.60</td>
                            </tr>

                            <tr>
                              <td colSpan={8} className="border-0">
                                <div className="loader-demo-box">
                                  <div className="jumping-dots-loader">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        {/* if no data */}
                        {/* <div className="noData d-flex gap-2 flex-row align-items-center">
                          <InfoIconSecondary />
                          <p className="m-0 text-muted fs-7">
                            {" "}
                            No tasks available for today{" "}
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* password Modal */}
      <div
        className="modal fade"
        id="exportCsvModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static" data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header border-1">
              <h5 className="modal-title body-2 mb-0">
                {" "}
                Export call logs as CSV{" "}
              </h5>
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body">
              <span className="body-3">
                Export call logs as a CSV to quickly share call details .
              </span>
              <form action="">
                <div className="mb-3 required">
                  <label htmlFor="inputCuPass" className="form-label">
                    Date range
                  </label>
                  <div className="custom-datepicker multiplePicker">
                    <DatePicker
                      className="form-control"
                      renderCustomHeader={({
                        monthDate,
                        customHeaderCount,
                        decreaseMonth,
                        increaseMonth,
                      }) => (
                        <div>
                          <button
                            aria-label="Previous Month"
                            className={
                              "react-datepicker__navigation react-datepicker__navigation--previous"
                            }
                            style={
                              customHeaderCount === 1
                                ? { visibility: "hidden" }
                                : undefined
                            }
                            onClick={decreaseMonth}
                          >
                            <span
                              className={
                                "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                              }
                            >
                              {"<"}
                            </span>
                          </button>
                          <span className="react-datepicker__current-month">
                            {monthDate.toLocaleString("en-US", {
                              month: "long",
                              year: "numeric",
                            })}
                          </span>
                          <button
                            aria-label="Next Month"
                            className={
                              "react-datepicker__navigation react-datepicker__navigation--next"
                            }
                            style={
                              customHeaderCount === 0
                                ? { visibility: "hidden" }
                                : undefined
                            }
                            onClick={increaseMonth}
                          >
                            <span
                              className={
                                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                              }
                            >
                              {">"}
                            </span>
                          </button>
                        </div>
                      )}
                      selected={startDate}
                      onChange={(date) => date && setStartDate(date)}
                      monthsShown={2}
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer border-1">
              <Link
                to=""
                className="btn btn-link text-primary btn-xs rounded-pill"
                data-bs-dismiss="modal"
              >
                Cancel
              </Link>
              {/* remove button disabled class after spinner goes off */}
              <Link
                to=""
                className="btn btn-primary rounded-pill btn-xs px-6 disabled"
              >
                <Spinner />
                Export
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* billing filter Modal */}
      <div
        className="modal fade"
        id="billingFilterModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header border-1">
              <h5 className="modal-title body-2 mb-0">
                Filter billing period{" "}
              </h5>
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="mb-3 required">
                  <label htmlFor="exampleInputName1" className="form-label">
                    Date Range
                  </label>
                  <div className="custom-datepicker multiplePicker">
                    <DatePicker
                      className="form-control"
                      renderCustomHeader={({
                        monthDate,
                        customHeaderCount,
                        decreaseMonth,
                        increaseMonth,
                      }) => (
                        <div>
                          <button
                            aria-label="Previous Month"
                            className={
                              "react-datepicker__navigation react-datepicker__navigation--previous"
                            }
                            style={
                              customHeaderCount === 1
                                ? { visibility: "hidden" }
                                : undefined
                            }
                            onClick={decreaseMonth}
                          >
                            <span
                              className={
                                "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                              }
                            >
                              {"<"}
                            </span>
                          </button>
                          <span className="react-datepicker__current-month">
                            {monthDate.toLocaleString("en-US", {
                              month: "long",
                              year: "numeric",
                            })}
                          </span>
                          <button
                            aria-label="Next Month"
                            className={
                              "react-datepicker__navigation react-datepicker__navigation--next"
                            }
                            style={
                              customHeaderCount === 0
                                ? { visibility: "hidden" }
                                : undefined
                            }
                            onClick={increaseMonth}
                          >
                            <span
                              className={
                                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                              }
                            >
                              {">"}
                            </span>
                          </button>
                        </div>
                      )}
                      selected={startDate}
                      onChange={(date) => date && setStartDate(date)}
                      monthsShown={2}
                    />
                  </div>

                  <div className="invalidInput form-text text-danger">
                    {" "}
                    <InfoIconDanger /> Field is required{" "}
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer border-1">
              <Link
                to=""
                className="btn btn-link text-primary btn-xs rounded-pill"
                data-bs-dismiss="modal"
              >
                Cancel
              </Link>
              {/* remove button disabled class after spinner goes off */}
              <Link
                to=""
                className="btn btn-primary rounded-pill btn-xs px-6 disabled"
              >
                <Spinner />
                Apply
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallLogsDetails;

const Breadcrumbs = () => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb flex-nowrap">
        <li className="breadcrumb-item">
          <a href="#">Contact</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Contact voter by Phone (Aug 8 -14){" "}
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumbs;

import React from "react";

const SidePanelPlaceholder = () => {
  return (
    <>
      {/* heading, edit, delete placeholder */}
      <div className="placeholder-glow mb-3">
        <div className="d-flex gap-3">
          <div className="w-100 d-flex gap-2">
            <div className="placeholder col-12"></div>
          </div>
          <div className="placeholder col-2"></div>
          <div className="placeholder col-2"></div>
        </div>
      </div>

      {/* media list placeholder */}
      <div className="mb-4">
        <div className="media media-sm py-2 placeholder-glow">
          <div className="media-header bg-neutral-n4 border-0">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className="mb-0 body-4 text-muted lh-1 placeholder">Email</h2>
            <span className="d-block body-4 col-md-8 placeholder">
              {" "}
              -{" "}
            </span>
          </div>
        </div>
        <div className="media media-sm py-2 placeholder-glow">
          <div className="media-header bg-neutral-n4 border-0">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className="mb-0 body-4 text-muted lh-1 placeholder">
              Phone no.
            </h2>
            <span className="d-block body-4 col-md-8 placeholder">
              {" "}
              -{" "}
            </span>
          </div>
        </div>
        <div className="media media-sm py-2 placeholder-glow">
          <div className="media-header bg-neutral-n4 border-0">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className="mb-0 body-4 text-muted lh-1 placeholder">Status</h2>
            <span className="d-block body-4 col-md-8 placeholder">
              {" "}
              -{" "}
            </span>
          </div>
        </div>
        <div className="media media-sm py-2 placeholder-glow">
          <div className="media-header bg-neutral-n4 border-0">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className="mb-0 body-4 text-muted lh-1 placeholder">
              Last Active Date
            </h2>
            <span className="d-block body-4 col-md-8 placeholder">
              {" "}
              -{" "}
            </span>
          </div>
        </div>
      </div>

      {/* heading placeholder */}
      <div className="placeholder-glow mb-3">
        <div className="placeholder col-5"></div>
      </div>
      <div className="placeholder-glow mb-3">
        <div className="placeholder col-3"></div>
      </div>

      {/* media list placeholder */}
      <div className="mb-4">
        <div className="media media-sm py-2 placeholder-glow">
          <div className="media-header bg-neutral-n4 border-0">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className="mb-0 body-4 text-muted lh-1 placeholder">Voter Contacted by</h2>
            <span className="d-block body-4 col-md-8 placeholder">
              {" "}
              -{" "}
            </span>
          </div>
        </div>
        <div className="media media-sm py-2 placeholder-glow">
          <div className="media-header bg-neutral-n4 border-0">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className="mb-0 body-4 text-muted lh-1 placeholder">
            Voter Contacted by
            </h2>
            <span className="d-block body-4 col-md-8 placeholder">
              {" "}
              -{" "}
            </span>
          </div>
        </div>
        <div className="media media-sm py-2 placeholder-glow">
          <div className="media-header bg-neutral-n4 border-0">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className="mb-0 body-4 text-muted lh-1 placeholder">Voter Contacted by</h2>
            <span className="d-block body-4 col-md-8 placeholder">
              {" "}
              -{" "}
            </span>
          </div>
        </div>
        <div className="media media-sm py-2 placeholder-glow">
          <div className="media-header bg-neutral-n4 border-0">
            <span className="placeholder"></span>
          </div>
          <div className="media-body w-100">
            <h2 className="mb-0 body-4 text-muted lh-1 placeholder">
            Voter Contacted by
            </h2>
            <span className="d-block body-4 col-md-8 placeholder">
              {" "}
              -{" "}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePanelPlaceholder;

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import InfoIconDanger from 'img/svg/InfoIconDanger'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Spinner from 'templates/components/Spinner'
import CreateAuthAxiosInstance from 'utils/authAxios'
import toastNotify from 'utils/toastNotify'
import * as yup from 'yup'
import { IVolunteer } from '../useVolunteerList'

interface IVolunteerBasic {
  id: number
  fullName: string | null
  email: string | null
  primaryPhoneNumber: string | null
}

interface IFormMutation {
  fullName?: string
  primaryPhoneNumber?: string
}

interface IFormInputs {
  fullName: string
  email: string
  primaryPhoneNumber?: string
}

const schema = yup
  .object({
    fullName: yup.string().required('Name is required !'),
    email: yup.string().email('Invalid Email !').required('Email is required !'),
    primaryPhoneNumber: yup
      .string()
      .matches(/^$|^[0-9\s\(\)\-]{10,}$/, 'Invalid phone number')
  })

export const UpdateVolunteerModal = ({
  volunteerDetail,
  setSelectedVolunteerForDetailView
}: {
  volunteerDetail: IVolunteerBasic | null,
  setSelectedVolunteerForDetailView: React.Dispatch<React.SetStateAction<IVolunteer | null>>
}) => {
  const authAxios = CreateAuthAxiosInstance()
  const queryClient = useQueryClient()

  const [isUpdateDataSame, setIsUpdateDataSame] = useState<boolean>(false)
  const [isPhoneExist, setIsPhoneExist] = useState<boolean>(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  })

  const {
    mutate: updateVolunteerMutation,
    status: updateVolunteerStatus
  } = useMutation({
    mutationFn: (data: IFormMutation) => authAxios.patch(`api/v1/volunteers/${volunteerDetail?.id}`, data),
    onSuccess: handleOnSuccessMutation,
    onError: (data) => {
      setIsPhoneExist(true)
      toastNotify('error', 'Error! Please check your email !')
    }
  })

  //Population of data in the form
  useEffect(() => {
    if (volunteerDetail) {
      reset({
        fullName: volunteerDetail?.fullName ?? '',
        email: volunteerDetail?.email ?? '',
        primaryPhoneNumber: volunteerDetail?.primaryPhoneNumber ?? ''
      })
    }
    setIsUpdateDataSame(true)
    setIsPhoneExist(false)
  }, [volunteerDetail])

  //Check if the new value is same as old value
  useEffect(() => {
    if (
      watch('fullName') === volunteerDetail?.fullName &&
      watch('primaryPhoneNumber') === volunteerDetail?.primaryPhoneNumber
    ) {
      setIsUpdateDataSame(true)
      return
    }
    if (isUpdateDataSame) setIsUpdateDataSame(false)

  }, [watch('fullName'), watch('primaryPhoneNumber')])

  function handleOnSuccessMutation() {
    toastNotify('success', 'Volunteer Updated Successfully !')
    queryClient.invalidateQueries({ queryKey: ['volunteerList'] })
    setSelectedVolunteerForDetailView(null)

    if (buttonRef?.current) buttonRef?.current?.click()
  }

  const onFinalSubmit: SubmitHandler<IFormInputs> = (submittedData) => {
    if (!volunteerDetail?.id) return

    const finalDataForMutation: IFormMutation = {}

    for (const key in submittedData) {
      if (key === 'email') continue

      const submittedValue = submittedData[key as keyof IFormInputs]?.trim()
      const volunteerValue = volunteerDetail[key as keyof IFormInputs]?.trim()

      if (submittedValue === volunteerValue) continue

      finalDataForMutation[key as keyof IFormMutation] = submittedValue
    }

    updateVolunteerMutation(finalDataForMutation)
  }


  return (
    <>
      <form onSubmit={handleSubmit(onFinalSubmit)}>
        <div
          className="modal fade"
          id="editModal"
          data-bs-backdrop="static"
          // data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">Edit volunteer </h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                <form action="">
                  <div className="mb-3 required">
                    <label htmlFor="exampleInputName1" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      aria-describedby="nameHelp"
                      placeholder="Enter name"
                      {...register('fullName', { required: true })}
                    />
                    {!!errors?.fullName?.message?.length &&
                      <div className="invalidInput form-text text-danger">
                        <InfoIconDanger />&nbsp;
                        {errors?.fullName?.message}
                      </div>
                    }
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      {...register('email', { required: true })}
                      disabled
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputPhone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="string"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="phoneHelp"
                      placeholder="Enter phone no."
                      {...register('primaryPhoneNumber')}
                      onFocus={() => setIsPhoneExist(false)}
                    />
                    {!!errors?.primaryPhoneNumber?.message?.length &&
                      <div className="invalidInput form-text text-danger">
                        <InfoIconDanger />&nbsp;
                        Invalid Phone Number !
                      </div>
                    }
                    {isPhoneExist &&
                      <div className="invalidInput form-text text-danger">
                        <InfoIconDanger />&nbsp;
                        Phone number already exists !
                      </div>
                    }
                  </div>
                </form>
              </div>

              <div className="modal-footer border-1">
                <button
                  type='button'
                  className="btn btn-link text-primary btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary rounded-pill btn-xs px-6"
                  type='submit'
                  disabled={!!Object.keys(errors)?.length || updateVolunteerStatus === 'pending' || isUpdateDataSame || isPhoneExist}
                >
                  {updateVolunteerStatus === 'pending' && <Spinner />}
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <button
        className="btn btn-primary d-none"
        ref={buttonRef}
        type="button"
        data-bs-dismiss="modal"
        aria-label="Close"
        data-bs-target="#editModal"
      />
    </>
  )
}

import {
  NotificationActionType,
  SuccessNotificationAction,
  ErrorNotificationAction,
} from "./notificationActions.d";

export const SuccessNotification = (
  payload: string
): SuccessNotificationAction => ({
  type: NotificationActionType.SUCCESS,
  payload,
});

export const ErrorNotification = (
  payload: string
): ErrorNotificationAction => ({
  type: NotificationActionType.ERROR,
  payload,
});

import FilterIcon from "img/svg/fiIterIcon";
import SortIcon from "img/svg/sortIcon";
import React from "react";
import { CSVLink } from "react-csv";
import { isAxiosError } from "axios";
import { ASC, DESC } from "../../voters/voters-list/VotersList";
import { CANDIDATE_ID } from "../../Homepage";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useQuery } from "@tanstack/react-query";
import { IReducerAction, IReducerState } from "./DonorTableTabV2";

export const DONOR_STATUS = ["Not Home", "Not Interested ", "Donating Later"];
export const DONOR_SOURCE = ["Personal Network", "Campaign Brain"];
export const DONOR_TYPE = ["Prospects", "Donors", "Ready for Resolicitations"];

const DonorTableFilterV2 = ({
  donorState,
  dispatch
}: {
  donorState: IReducerState
  dispatch: React.Dispatch<IReducerAction>
}) => {
  const authAxios = CreateAuthAxiosInstance();
  const localCandidateId = localStorage.getItem(CANDIDATE_ID);

  const {
    data: donorCsv,
    isFetching: isFetchingDonorCsv,
    refetch: refetchDonorCsv,
    isError,
    error
  } = useQuery({
    queryKey: ["donorCsv"],
    queryFn: getDonorCsv,
    refetchOnWindowFocus: false,
    enabled: Boolean(localCandidateId)
  });

  async function getDonorCsv() {
    try {
      const apiResponse = await authAxios(`api/v1/donor/csv/${localCandidateId}`)
      const csvDataInFormat = apiResponse?.data
        ?.split("\n")
        .reduce((result: string[][], item: string) => {
          return (result = [...result, item.split(",")]);
        }, []);

      return csvDataInFormat
    } catch (error: unknown) {
      if (isAxiosError(error))
        throw error
    }
  }

  return (
    <div className="row justify-content-between align-items-start mt-4">
      <div className="col-md-4">
        <div className="d-flex flex-column gap-2">
          <input
            type="text"
            className="form-control"
            placeholder="Search donor"
            value={donorState?.searchInput || ''}
            onChange={(e) => dispatch({ type: 'searchInput', value: e.target.value || '' })}
          />

          {(
            (donorState?.searchInput?.length > 2) ||
            donorState?.donorStatus ||
            donorState?.donorSource
          ) && (
              <div className="filter-message">
                Showing results for {donorState?.searchInput && <span>"{donorState.searchInput}", </span>}
                {donorState?.donorStatus && <span>"{donorState.donorStatus}", </span>}
                {donorState?.donorSource && <span>"{donorState.donorSource}", </span>}
                <span>{donorState?.order === ASC ? "A - Z" : "Z - A"} </span>
              </div>
            )
          }
        </div>
      </div>
      <div className="col-md-8">
        <div className="d-flex justify-content-end align-items-center  flex-wrap gap-3">
          <div className="dropdown">
            <button
              className="btn btn-outline-secondary btn-xxs bg-white"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Filter
              <span className="">
                <FilterIcon />
              </span>
            </button>

            <div className="dropdown-menu dropdown-menu-checkbox">
              <div className="dropdown-group">
                <h6 className="dropdown-header">Status</h6>
                <ul className="dropdown-body list-unstyled mb-0">
                  {DONOR_STATUS.map((status, index) => (
                    <li key={index}>
                      <input
                        className="checkbox-round"
                        type="checkbox"
                        id={status + index}
                        value={status}
                        checked={status === donorState?.donorStatus}
                        onChange={() => dispatch({ type: 'donorStatus', value: status !== donorState?.donorStatus ? status : '' })}
                      />
                      <label htmlFor={status + index}>{status}</label>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="dropdown-group">
                <h6 className="dropdown-header">Source</h6>
                <ul className="dropdown-body list-unstyled mb-0 mb-0">
                  {DONOR_SOURCE.map((source, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        className="checkbox-round"
                        id={source + index}
                        value={source}
                        checked={source === donorState?.donorSource}
                        onChange={() => dispatch({ type: 'donorSource', value: source !== donorState?.donorSource ? source : '' })}
                      />
                      <label htmlFor={source + index}> {source}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <button
              className="btn btn-outline-secondary btn-xxs bg-white"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-none d-lg-block">Sort</span>
              <span className="">
                <SortIcon />
              </span>
            </button>
            <div className="dropdown-menu dropdown-menu-checkbox">
              <div className="dropdown-group">
                <ul className="dropdown-body list-unstyled mb-0">
                  <li>
                    <input
                      type="radio"
                      value="asc"
                      id="atoz"
                      className="checkbox-round"
                      checked={donorState?.order === ASC}
                      onChange={() => dispatch({ type: 'order', value: ASC })}
                    />
                    <label htmlFor="atoz">A - Z</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      value="desc"
                      id="ztoa"
                      className="checkbox-round"
                      checked={donorState?.order === DESC}
                      onChange={() => dispatch({ type: 'order', value: DESC })}
                    />
                    <label htmlFor="ztoa">Z - A</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* export */}
          <CSVLink
            data={donorCsv ? donorCsv : ''}
            filename={"donor-list.csv"}
            className={`btn btn-info rounded-pill btn-xs px-4 ${(isFetchingDonorCsv || !donorCsv) ? "disabled" : ""}`}
            target="_blank"
          >
            Export CSV
          </CSVLink>

          <button
            className="btn btn-primary rounded-pill btn-xs px-4"
            data-bs-toggle="modal"
            data-bs-target="#uploadModal"
          >
            Add donor
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DonorTableFilterV2);

import NoData from "img/svg/noData";
import SideNavbar from "../../components/common/navbar/SideNavbar";
import { Link } from "react-router-dom";
import InfoIconWarning from "img/svg/InfoIconWarning";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import FilterIcon from "img/svg/fiIterIcon";
import SortIcon from "img/svg/sortIcon";
import { CSVLink } from "react-csv";
import ThreeDots from "../../img/svg/threeDots";
import InfoIconDanger from "img/svg/InfoIconDanger";
import TrashIcon from "img/svg/TrashIcon";
import NoNetwork from "img/svg/noNetwork";
import SidePanelFundraise from "templates/components/SidePanelFundraise";
import CustomTooltip from "components/common/CustomTooltip";

const FundraiseList = () => {
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />
      <SidePanelFundraise />

      <div className="wrapper active-cont">
        <main>
          {/* Alert */}
          <div className="row">
            <div className="col-md-12">
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <InfoIconWarning />{" "}
                <span className="ms-2">Donor list will be uploaded soon. </span>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                >
                  <span className="icon-close"></span>
                </button>
              </div>
            </div>
          </div>

          {/* contact donor card  */}
          <div className="row mb-5">
            <div className="col-md-12">
              <div className="card card-flush h-100 shadow-2 flex-row justify-content-between align-items-center p-3">
                <div className="card-header contactCard-header d-flex gap-3 p-0 mb-0">
                  <div className="iconBox bg-neutral-n4 mb-0">
                    <img
                      src={require("../../img/patriotism.png")}
                      className="img-fluid"
                      width={32}
                      height={32}
                      alt=""
                    />
                  </div>

                  <div className="card-header-content p-0">
                    <h4 className="card-title"> Contact Voters</h4>
                    <p className="text-muted">
                      Continue with contacting key donors
                    </p>
                  </div>
                </div>

                <div className="contactOption-item contactOption-item-disabled mb-0">
                  <Link to="">
                    <div className="contactOption-item-content">
                      <div className="contactOption-item-content-title pe-5">
                        Contact Donors
                        <p className="m-0">Talk to voter on Door</p>
                      </div>
                    </div>
                    <div className="contactOption-item-link">
                      <span className="icon-arrow-right"></span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* when no data view  */}

          {/* <div className="row h-100 justify-content-center">
              <div className="col-md-6">
              <div className="centeredXY noData text-center justify-content-around">
                <div className="noData-header">
                  <NoData />
                  <h1 className="mt-6">No donor data available</h1>
                  <p className="text-muted">
                    Upload a file of any format containing information about
                    potential donors or add manually from your personal network
                  </p>

                  <div className="mt-4">
                    <Link
                      to=""
                      className="btn btn-primary rounded-pill px-5"
                      data-bs-toggle="modal"
                      data-bs-target="#uploadModal"
                    >
                      Add donor
                    </Link>
                  </div>
                </div>
              </div>
              </div>
            </div> */}

          <div className="row">
            <div className="col-12">
              <div className="section-box mb-0">
                <h3 className="section-box-title"> Donor List </h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <ul
                className="nav nav-tabs nav-flush position-relative bg-transparent"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected="true"
                  >
                    All{" "}
                    <span className="badge badge-secondary ms-2 badge-xs">
                      {" "}
                      12{" "}
                    </span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane"
                    aria-selected="false"
                  >
                    Prospects{" "}
                    <span className="badge badge-secondary ms-2 badge-xs">
                      {" "}
                      8{" "}
                    </span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="contact-tab-pane"
                    aria-selected="false"
                  >
                    Donors{" "}
                    <span className="badge badge-secondary ms-2 badge-xs">
                      {" "}
                      0{" "}
                    </span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="door-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#door-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="door-tab-pane"
                    aria-selected="false"
                  >
                    Ready for Resoliciation{" "}
                    <span className="badge badge-secondary ms-2 badge-xs">
                      {" "}
                      0{" "}
                    </span>
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex={0}
                >
                  {/* filter */}
                  <div className="row justify-content-between align-items-start mt-4">
                    <div className="col-md-4">
                      <div className="d-flex flex-column gap-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search voter"
                          value=""
                        />

                        <div className="filter-message">
                          Showing results for <span>"aaasdasd", </span>
                          <span>A - Z </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
                        <div className="dropdown">
                          <button
                            className="btn btn-outline-secondary btn-xxs bg-white"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-none d-lg-block"> Filter</span>{" "}
                            <span className="">
                              {" "}
                              <FilterIcon />
                            </span>
                          </button>

                          <div className="dropdown-menu">
                            <div className="dropdown-group">
                              <h6 className="dropdown-header"> Source </h6>
                              <ul className="dropdown-body list-unstyled mb-0">
                                <li>
                                  <input
                                    className="checkbox-round"
                                    type="checkbox"
                                  />
                                  <span> Personal Network </span>
                                </li>
                                <li>
                                  <input
                                    className="checkbox-round"
                                    type="checkbox"
                                  />
                                  <span> Campaign Brain </span>
                                </li>
                              </ul>
                            </div>

                            <div className="dropdown-group">
                              <h6 className="dropdown-header">Status</h6>
                              <ul className="dropdown-body list-unstyled mb-0 mb-0">
                                <li>
                                  <input
                                    type="checkbox"
                                    className="checkbox-round"
                                  />
                                  <span> Not Home </span>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    className="checkbox-round"
                                  />
                                  <span> Not Interested </span>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    className="checkbox-round"
                                  />
                                  <span> Donating Later </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="dropdown">
                          <button
                            className="btn btn-outline-secondary btn-xxs bg-white"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-none d-lg-block"> Sort </span>
                            <span className="">
                              {" "}
                              <SortIcon />
                            </span>
                          </button>
                          <div className="dropdown-menu">
                            <div className="dropdown-group">
                              <ul className="dropdown-body list-unstyled mb-0">
                                <li>
                                  <input
                                    type="radio"
                                    value="asc"
                                    className="checkbox-round"
                                  />
                                  <span>A - Z</span>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    value="desc"
                                    className="checkbox-round"
                                  />
                                  <span>Z - A</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        {/* export */}

                        <CSVLink
                          data=""
                          filename={"voters-list.csv"}
                          className="btn btn-info rounded-pill btn-xs px-4"
                          target="_blank"
                        >
                          {" "}
                          Export CSV{" "}
                        </CSVLink>

                        <button
                          className="btn btn-primary rounded-pill btn-xs px-4"
                          data-bs-toggle="modal"
                          data-bs-target="#uploadModal"
                        >
                          {" "}
                          Add donor{" "}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card card-fullHeight scroll-y mt-3 shadow-4 border-1 card-fundraise">
                    {/* table */}
                    <table className="table table-full table-eq-height">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" />
                          </th>
                          <th>Name</th>
                          <th>Source</th>
                          <th>Donor Type</th>
                          <th> Status</th>
                          <th>Donation Amount</th>
                          <th style={{width: '15%'}}>Notes</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Jacob Jones</td>
                          <td>Personal Network</td>
                          <td> Ready for Resoliciations</td>
                          <td>
                            <div>
                              <span className="badge badge-secondary rounded-pill">
                                Not Home
                              </span>

                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div>

                            {/* <span className="d-block"> - </span>  */}
                          </td>
                          <td>
                            {/* <span className="body-5"> $ 300 </span>
                            <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span> */}
                            <span className="d-block"> - </span>
                          </td>
                          <td>
                            <CustomTooltip content="This donor is not home who could be the potential donor. We can contact him later on.">
                              <span className="text-truncate-1"> This donor is not home who could be the potential donor. We can contact him later on.</span>
                            </CustomTooltip>
                          </td>
                          <td className="text-center">
                            <Link
                              className="px-3 py-2"
                              to=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDots />
                            </Link>

                            <div className="dropdown-menu">
                              <div className="dropdown-group">
                                <ul className="dropdown-body list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#editModal"
                                    >
                                      {" "}
                                      Edit Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      
                                    >
                                      {" "}
                                      Contact Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                    >
                                      {" "}
                                      Delete Donor{" "}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Jacob Jones</td>
                          <td>Personal Network</td>
                          <td> Ready for Resoliciations</td>
                          <td>
                            <div>
                              <span className="badge badge-secondary rounded-pill">
                                Not Interested
                              </span>

                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div>

                            {/* <span className="d-block"> - </span>  */}
                          </td>
                          <td>
                            {/* <span className="body-5"> $ 300 </span>
                            <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span> */}

                            <span className="d-block"> - </span>
                          </td>
                          <td></td>
                          <td className="text-center">
                            <Link
                              className="px-3 py-2"
                              to=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDots />
                            </Link>

                            <div className="dropdown-menu">
                              <div className="dropdown-group">
                                <ul className="dropdown-body list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#editModal"
                                    >
                                      {" "}
                                      Edit Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      
                                    >
                                      {" "}
                                      Contact Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                    >
                                      {" "}
                                      Delete Donor{" "}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Jacob Jones</td>
                          <td>Personal Network</td>
                          <td> Ready for Resoliciations</td>
                          <td>
                            {/* <div>
                              <span className="badge badge-secondary rounded-pill">
                                Not Interested
                              </span>

                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div> */}

                            <span className="d-block"> - </span>
                          </td>
                          <td>
                            <span className="body-5"> $ 300 </span>
                            <span className="d-block fs-10 text-muted">
                              {" "}
                              Last attempt: 6/23/23{" "}
                            </span>
                          </td>
                          <td></td>
                          <td className="text-center">
                            <Link
                              className="px-3 py-2"
                              to=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDots />
                            </Link>

                            <div className="dropdown-menu">
                              <div className="dropdown-group">
                                <ul className="dropdown-body list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#editModal"
                                    >
                                      {" "}
                                      Edit Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      
                                    >
                                      {" "}
                                      Contact Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                    >
                                      {" "}
                                      Delete Donor{" "}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Jacob Jones</td>
                          <td>Personal Network</td>
                          <td> Ready for Resoliciations</td>
                          <td>
                            <div>
                              <span className="badge badge-secondary rounded-pill">
                                Not Home
                              </span>

                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div>

                            {/* <span className="d-block"> - </span>  */}
                          </td>
                          <td>
                            {/* <span className="body-5"> $ 300 </span>
                            <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span> */}
                            <span className="d-block"> - </span>
                          </td>
                          <td></td>
                          <td className="text-center">
                            <Link
                              className="px-3 py-2"
                              to=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDots />
                            </Link>

                            <div className="dropdown-menu">
                              <div className="dropdown-group">
                                <ul className="dropdown-body list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#editModal"
                                    >
                                      {" "}
                                      Edit Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      
                                    >
                                      {" "}
                                      Contact Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                    >
                                      {" "}
                                      Delete Donor{" "}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Jacob Jones</td>
                          <td>Personal Network</td>
                          <td> Ready for Resoliciations</td>
                          <td>
                            <div>
                              <span className="badge badge-secondary rounded-pill">
                                Not Interested
                              </span>

                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div>

                            {/* <span className="d-block"> - </span>  */}
                          </td>
                          <td>
                            {/* <span className="body-5"> $ 300 </span>
                            <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span> */}

                            <span className="d-block"> - </span>
                          </td>
                          <td></td>
                          <td className="text-center">
                            <Link
                              className="px-3 py-2"
                              to=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDots />
                            </Link>

                            <div className="dropdown-menu">
                              <div className="dropdown-group">
                                <ul className="dropdown-body list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#editModal"
                                    >
                                      {" "}
                                      Edit Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      
                                    >
                                      {" "}
                                      Contact Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                    >
                                      {" "}
                                      Delete Donor{" "}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Jacob Jones</td>
                          <td>Personal Network</td>
                          <td> Ready for Resoliciations</td>
                          <td>
                            {/* <div>
                              <span className="badge badge-secondary rounded-pill">
                                Not Interested
                              </span>

                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div> */}

                            <span className="d-block"> - </span>
                          </td>
                          <td>
                            <span className="body-5"> $ 300 </span>
                            <span className="d-block fs-10 text-muted">
                              {" "}
                              Last attempt: 6/23/23{" "}
                            </span>
                          </td>
                          <td></td>
                          <td className="text-center">
                            <Link
                              className="px-3 py-2"
                              to=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDots />
                            </Link>

                            <div className="dropdown-menu">
                              <div className="dropdown-group">
                                <ul className="dropdown-body list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#editModal"
                                    >
                                      {" "}
                                      Edit Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      
                                    >
                                      {" "}
                                      Contact Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                    >
                                      {" "}
                                      Delete Donor{" "}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            {" "}
                            <input type="checkbox" />
                          </td>
                          <td>Jacob Jones</td>
                          <td>Personal Network</td>
                          <td> Ready for Resoliciations</td>
                          <td>
                            {/* <div>
                              <span className="badge badge-secondary rounded-pill">
                                Not Interested
                              </span>

                              <span className="d-block fs-10 text-muted">
                                {" "}
                                Last attempt: 6/23/23{" "}
                              </span>
                            </div> */}

                            <span className="d-block"> - </span>
                          </td>
                          <td>
                            <span className="body-5"> $ 300 </span>
                            <span className="d-block fs-10 text-muted">
                              {" "}
                              Last attempt: 6/23/23{" "}
                            </span>
                          </td>
                          <td></td>
                          <td className="text-center">
                            <Link
                              className="px-3 py-2"
                              to=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDots />
                            </Link>

                            <div className="dropdown-menu">
                              <div className="dropdown-group">
                                <ul className="dropdown-body list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#editModal"
                                    >
                                      {" "}
                                      Edit Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      
                                    >
                                      {" "}
                                      Contact Donor
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                    >
                                      {" "}
                                      Delete Donor{" "}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={8} className="border-0">
                            <div className="loader-demo-box">
                              <div className="jumping-dots-loader">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {/* if no data */}
                    {/* <div className="noData d-flex gap-2 flex-row align-items-center">
                      <InfoIconSecondary />
                      <p className="m-0 text-muted fs-7">
                        {" "}
                        No tasks available for today{" "}
                      </p>
                    </div> */}

                    <div className="centeredXY noNetwork text-center justify-content-around">
                      <div className="noNetwork-header">
                        <NoNetwork />
                        <h1 className="mt-6">No internet connection</h1>
                        <p className="text-muted">
                          Could not connect to the internet. Please check your
                          network
                        </p>

                        <div className="mt-4">
                          <Link to="" className="fw-bold">
                            Try Again
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabIndex={0}
                >
                  {/* filter */}
                  {/* <TableFilter /> */}

                  <div className="card card-fullHeight scroll-y mt-3 card-fundraise">
                    {/* no table list */}
                    <table className="table table-full table-eq-height">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" />
                          </th>
                          <th>Name</th>
                          <th>Address</th>
                          <th>Voter Id</th>
                          <th>Voter Status</th>
                          <th>Level of Support</th>
                          <th>Contact Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={7} className="border-0">
                            <div className="noData d-flex gap-2 flex-row align-items-center">
                              <InfoIconSecondary />
                              <p className="m-0 text-muted fs-7">
                                {" "}
                                No tasks available for today{" "}
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="contact-tab-pane"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                  tabIndex={0}
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="door-tab-pane"
                  role="tabpanel"
                  aria-labelledby="door-tab"
                  tabIndex={0}
                >
                  ...
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* Add donor Modal */}
        <div
          className="modal fade"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">
                  Add donor information
                </h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                <form action="">
                  <div className="btn-checkGroup d-flex gap-3 mb-4">
                    <div className="btn-checkGroup-item btn-checkGroup-item--icon">
                      <input
                        type="radio"
                        className="btn-check form-check-input"
                        name="options"
                        id="option11"
                        autoComplete="off"
                      />
                      <label
                        className="btn btn-outline-primary rounded-pill ps-5"
                        htmlFor="option11"
                      >
                        File upload
                      </label>
                    </div>

                    <div className="btn-checkGroup-item btn-checkGroup-item--icon">
                      <input
                        type="radio"
                        className="btn-check form-check-input"
                        name="options"
                        id="option12"
                        autoComplete="off"
                      />
                      <label
                        className="btn btn-outline-primary rounded-pill ps-5"
                        htmlFor="option12"
                      >
                        Add a donor manually
                      </label>
                    </div>
                  </div>

                  <div className="">
                    <label className="custom-label">File upload</label>
                    <p>
                      Upload a file of any format containing information about
                      potential donors from your personal network
                    </p>

                    {/* before upload */}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="uploadedCard">
                          <div className="uploadedCard-icon">
                            <div className="iconInfo">
                              <span className="iconInfo-title text-muted">
                                (pdf, jpg, jpeg, xls, or any){" "}
                                <span className="dot bg-neutral-n3 ms-1">
                                  {" "}
                                </span>{" "}
                                2MB Max
                              </span>
                            </div>
                          </div>
                          <div className="uploadedCard-info file-btn">
                            <button className="btn btn-info rounded-pill">
                              {" "}
                              Upload File
                              <input type="file" name="file" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* after upload */}

                    {/* <div className="row">
                      <div className="col-md-12">
                        <div className="uploadedCard">
                          <div className="uploadedCard-icon">
                            <div className="iconBox">
                              <FileIcon />
                            </div>

                            <div className="iconInfo">
                              <span className="iconInfo-title">
                                {" "}
                                Donor List.png{" "}
                              </span>
                              <p className="iconInfo-desc">
                                {" "}
                                1.5 MB, 6/23/23 at 11:45 am{" "}
                              </p>
                              <span className="iconInfo-status">
                                {" "}
                                <CheckSuccess /> File uploaded{" "}
                              </span>
                            </div>
                          </div>
                          <div className="uploadedCard-info">
                            <div className="file-btn">
                              <button className="btn btn-link text-primary">
                                {" "}
                                Change File
                                <input type="file" name="file" />
                              </button>
                            </div>

                            <button className="btn btn-xs">
                              <span className="">
                                {" "}
                                <TrashIcon />{" "}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="mb-3">
                      <label className="custom-label">
                        Add a donor manually
                      </label>

                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              <label htmlFor="inputName" className="form-label">
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="inputName"
                              />
                            </td>
                            <td>
                              <label
                                htmlFor="inputPhone"
                                className="form-label"
                              >
                                Phone
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="inputPhone"
                              />
                            </td>
                            <td>
                              <label
                                htmlFor="inputEmail"
                                className="form-label"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="inputEmail"
                              />
                            </td>
                            <td>
                              <button className="btn btn-link pt-0 px-0 mt-4">
                                {" "}
                                <span className="svg-neutral-2">
                                  {" "}
                                  <TrashIcon />
                                </span>{" "}
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <button className="btn btn-link text-primary px-1 py-1">
                        {" "}
                        <span className="icon-plus me-2"></span> Add another
                        donor{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer border-1">
                <Link
                  to=""
                  className="btn btn-link text-primary btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
                <Link
                  to=""
                  className="btn btn-primary rounded-pill btn-xs px-6"
                >
                  Done
                </Link>
              </div>
            </div>
          </div>
        </div>
        
        {/* DEPRICATED MODAL IN NEW FUNDRAISE MODULE  */}
        {/* edit Modal */}
        <div
          className="modal fade"
          id="editModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header border-1">
                <h5 className="modal-title body-2 mb-0">Edit donor </h5>
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body">
                <form action="">
                  <div className="mb-3">
                    <label htmlFor="exampleInputName1" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      aria-describedby="nameHelp"
                      placeholder=""
                    />
                    <div className="invalidInput form-text text-danger">
                      {" "}
                      <InfoIconDanger /> Name cannot be numeric{" "}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder=""
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPhone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputPhone"
                      aria-describedby="phoneHelp"
                      placeholder=""
                    />
                  </div>
                </form>
              </div>

              <div className="modal-footer border-1">
                <Link
                  to=""
                  className="btn btn-link text-primary btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
                <Link
                  to=""
                  className="btn btn-primary rounded-pill btn-xs px-6"
                >
                  Update
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* delete warning Modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body text-center px-4">
                <img
                  src={require("../../img/leave.png")}
                  width={66}
                  height={56}
                  alt="leave alert"
                />
                <h2 className="mt-3">Do you want to delete the donor? </h2>
                <p>
                  By deleting, details and history of the donor will be erased.
                </p>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-link btn-sm p-1 ps-0"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button className="btn btn-primary rounded-pill btn-xs">
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundraiseList;

import { INotification } from "./notificationReducer.d";

import {
  NotificationsAction,
  NotificationActionType,
} from "../actions/notificationActions.d";

export const initialNotificationReducerState: INotification = {
  errorMessage: "",
  successMessage: "Successfully submitted data",
};

export const NotificationReducer = (
  state: INotification,
  action: NotificationsAction
) => {
  switch (action.type) {
    case NotificationActionType.SUCCESS:
      return { ...state, successMessage: action.payload };
    case NotificationActionType.ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

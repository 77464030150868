import React, { useState } from "react";
import SideNavbar from "../../components/common/navbar/SideNavbar";
import Breadcrumbs from "templates/components/Breadcrumbs";
import { Link } from "react-router-dom";
import SidePanel from "templates/components/SidePanel";
import WarningIcon from "img/svg/warningIcon";
import MapIframeDummy from "templates/components/MapIframeDummy";
import ChevronDownIcon from "img/svg/chevronDownIcon";
import ThreeDots from "img/svg/threeDots";
import UndoIcon from "img/svg/undoIcon";
import RedoIcon from "img/svg/redoIcon";
import EditTurf from "./turf/EditTurf";
import Spinner from "templates/components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import CheckSuccess from "img/svg/checkSuccess";
import SortIcon from "img/svg/sortIcon";
import PolygonIcon from "img/svg/PolygonIcon";
import InfoIconWarning from "img/svg/InfoIconWarning";
import Loader from "components/common/Loader";

const TurfDetailView = () => {
  const [isFullscreen, setFullscreen] = useState(false);
  const handleView = () => {
    setFullscreen(!isFullscreen);
  };

  const notify = () => {
    // toast("Wow so easy!");

    toast.error("Deleted Successfully !", {
      className: "alert alert-success",
      position: toast.POSITION.TOP_RIGHT,
      theme: "light",
      hideProgressBar: true,
      autoClose: 5000,
      icon: <CheckSuccess />,
    });
  };
  return (
    <div className="d-flex align-items-stretch">
      <div className="wrapper active-cont">
        <main className="p-0">
          <div className={isFullscreen ? `turfDetail fullView` : "turfDetail"}>
            <div className="turfDetail-header">
              <div className="turfDetail-header-selection">
                <div className="d-flex gap-3 align-items-center">
                  {/* turf selection dropdown */}
                  <div className="dropdown">
                    <button
                      className="btn btn-outline-secondary btn-xxs bg-white border-0 px-0 py-0 font-semibold gap-1"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Default universe: All Voters{" "}
                      <span className="">
                        {" "}
                        <ChevronDownIcon />{" "}
                      </span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-checkbox turfSelectionDd">
                      <ul className="dropdown-body list-unstyled">
                        <li>
                          <div className="turfSelectionDd-list">
                            <div className="d-flex gap-2 align-items-center">
                              <span className="body-4 mb-1">
                                {" "}
                                Default universe: All Voters{" "}
                              </span>

                              <span className="badge bg-success rounded-pill px-2 font-regular">
                                Active
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="fs-8 text-muted">
                                {" "}
                                All voters{" "}
                              </span>
                              <span className="dot dot-sm bg-neutral-n3 mx-1">
                                {" "}
                              </span>
                              <span className="fs-8 text-muted">
                                {" "}
                                Total Doors: 289{" "}
                              </span>
                              <span className="dot dot-sm bg-neutral-n3 mx-1">
                                {" "}
                              </span>
                              <span className="fs-8 text-muted">
                                {" "}
                                Total Voters: 400{" "}
                              </span>
                              <span className="dot dot-sm bg-neutral-n3 mx-1">
                                {" "}
                              </span>
                              <span className="fs-8 text-muted">
                                {" "}
                                Turfs: 0{" "}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="turfSelectionDd-list">
                            <div className="d-flex gap-2 align-items-center">
                              <span className="body-4 mb-1">
                                {" "}
                                Republican Voter Contact{" "}
                              </span>

                              {/* <span className="badge bg-success rounded-pill px-2 font-regular">
                                        Active
                                    </span> */}
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="fs-8 text-muted">
                                {" "}
                                All voters{" "}
                              </span>
                              <span className="dot dot-sm bg-neutral-n3 mx-1">
                                {" "}
                              </span>
                              <span className="fs-8 text-muted">
                                {" "}
                                Total Doors: 289{" "}
                              </span>
                              <span className="dot dot-sm bg-neutral-n3 mx-1">
                                {" "}
                              </span>
                              <span className="fs-8 text-muted">
                                {" "}
                                Total Voters: 400{" "}
                              </span>
                              <span className="dot dot-sm bg-neutral-n3 mx-1">
                                {" "}
                              </span>
                              <span className="fs-8 text-muted">
                                {" "}
                                Turfs: 0{" "}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="turfSelectionDd-list">
                            <div className="d-flex gap-2 align-items-center">
                              <span className="body-4 mb-1">
                                {" "}
                                Democrat Voter Contact{" "}
                              </span>

                              {/* <span className="badge bg-success rounded-pill px-2 font-regular">
                                        Active
                                    </span> */}
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="fs-8 text-muted">
                                {" "}
                                All voters{" "}
                              </span>
                              <span className="dot dot-sm bg-neutral-n3 mx-1">
                                {" "}
                              </span>
                              <span className="fs-8 text-muted">
                                {" "}
                                Total Doors: 289{" "}
                              </span>
                              <span className="dot dot-sm bg-neutral-n3 mx-1">
                                {" "}
                              </span>
                              <span className="fs-8 text-muted">
                                {" "}
                                Total Voters: 400{" "}
                              </span>
                              <span className="dot dot-sm bg-neutral-n3 mx-1">
                                {" "}
                              </span>
                              <span className="fs-8 text-muted">
                                {" "}
                                Turfs: 0{" "}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <span className="badge bg-success rounded-pill px-2 font-regular">
                    Active
                  </span>
                </div>
                <span className="body-4 text-muted">
                  Create and assign turf for this universe for contacting the
                  voters.
                </span>
              </div>
              <div className="turfDetail-header-action">
                <button className="btn btn-link rounded-pill btn-xs me-2">
                  {" "}
                  Exit{" "}
                </button>
                <button className="btn btn-info rounded-pill btn-xs">
                  {" "}
                  Save changes{" "}
                </button>
              </div>
            </div>
            <div
              className={
                isFullscreen ? `turfDetail-body show` : `turfDetail-body`
              }
            >
              <div className="position-relative w-100 h-100">
                <MapIframeDummy width="100%" height="100%" />

                {/* edit turf form */}
                <EditTurf isFullscreen={isFullscreen} />

                <div className="undoRedo shadow-6 d-none d-md-block">
                  <button className="btn border-0 p-1 bg-white disabled">
                    <UndoIcon />
                  </button>
                  <button className="btn border-0 p-1 bg-white">
                    <RedoIcon />
                  </button>
                </div>
                <div className="drawPolygon shadow-6 d-none d-md-block">
                  <button className="btn border-0 p-1 bg-white">
                    <PolygonIcon />
                  </button>
                </div>
              </div>
              <div
                className="viewControlBtn border border-bottom-0"
                onClick={handleView}
              >
                <ChevronDownIcon />
              </div>
            </div>
            <div className="turfDetail-footer">
              <div className="turfDetail-footer-content">
                <h5>Turf details</h5>
                <div className="d-flex align-items-center gap-2">
                  <span className="fs-7 text-muted"> All voters </span>
                  <span className="dot dot-sm bg-neutral-n3 mx-1"> </span>
                  <span className="fs-7 text-muted"> Total Doors: 439 </span>
                  <span className="dot dot-sm bg-neutral-n3 mx-1"> </span>
                  <span className="fs-7 text-muted"> Selected Doors: 0 </span>
                </div>
              </div>
              <div className="card card-fullHeight scroll-y border-top rounded-0 card-turfDetails">
                {/* table */}
                <table className="table table-full table-eq-height table-hover">
                  <thead>
                    <tr>
                      <th> Turf name </th>
                      <th> Voters </th>
                      <th> Doors </th>
                      <th> % Completed </th>
                      <th> Estimated time to complete </th>
                      <th> Assigned to </th>
                      <th> Date Created </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <span> Turf 1 </span>
                      </td>
                      <td>
                        {" "}
                        <span> 164 </span>
                      </td>
                      <td>
                        {" "}
                        <span> 40 </span>{" "}
                      </td>
                      <td>
                        {" "}
                        <span> 0% </span>{" "}
                      </td>
                      <td>
                        <span> 3:15:00 </span>
                      </td>
                      <td>
                        <span>Elenor Parker (You)</span>
                      </td>
                      <td>4/10/24</td>
                      <td className="text-center">
                        <Link
                          className="px-3 py-0"
                          to=""
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <ThreeDots />
                        </Link>

                        <div className="dropdown-menu">
                          <div className="dropdown-group">
                            <ul className="dropdown-body list-unstyled mb-0">
                              <li>
                                <Link to=""> Edit turf </Link>
                              </li>
                              <li>
                                <Link
                                  to=""
                                  className="text-danger"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                >
                                  {" "}
                                  Delete turf{" "}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <span> Turf 2 </span>
                      </td>
                      <td>
                        {" "}
                        <span> 164 </span>
                      </td>
                      <td>
                        {" "}
                        <span> 40 </span>{" "}
                      </td>
                      <td>
                        {" "}
                        <span> 0% </span>{" "}
                      </td>
                      <td>
                        <span> 3:15:00 </span>
                      </td>
                      <td>
                        <span className="text-muted"> Unassigned</span>
                        {/* <span> Christopher Nolan</span> */}
                      </td>
                      <td>4/10/24</td>
                      <td className="text-center">
                        <Link
                          className="px-3 py-0"
                          to=""
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <ThreeDots />
                        </Link>

                        <div className="dropdown-menu">
                          <div className="dropdown-group">
                            <ul className="dropdown-body list-unstyled mb-0">
                              <li>
                                <Link to=""> Edit turf </Link>
                              </li>
                              <li>
                                <Link
                                  to=""
                                  className="text-danger"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                >
                                  {" "}
                                  Delete turf{" "}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <span> Turf 3 </span>
                      </td>
                      <td>
                        {" "}
                        <span> 164 </span>
                      </td>
                      <td>
                        {" "}
                        <span> 40 </span>{" "}
                      </td>
                      <td>
                        {" "}
                        <span> 0% </span>{" "}
                      </td>
                      <td>
                        <span> 3:15:00 </span>
                      </td>
                      <td>
                        <span className="text-muted">Unassigned</span>
                        {/* <span>Chloe Harrison</span> */}
                      </td>
                      <td>4/10/24</td>
                      <td className="text-center">
                        <Link
                          className="px-3 py-0"
                          to=""
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <ThreeDots />
                        </Link>

                        <div className="dropdown-menu">
                          <div className="dropdown-group">
                            <ul className="dropdown-body list-unstyled mb-0">
                              <li>
                                <Link to=""> Edit turf </Link>
                              </li>
                              <li>
                                <Link
                                  to=""
                                  className="text-danger"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                >
                                  {" "}
                                  Delete turf{" "}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <span> Turf 4 </span>
                      </td>
                      <td>
                        {" "}
                        <span> 164 </span>
                      </td>
                      <td>
                        {" "}
                        <span> 40 </span>{" "}
                      </td>
                      <td>
                        {" "}
                        <span> 0% </span>{" "}
                      </td>
                      <td>
                        <span> 1:30:23 </span>
                      </td>
                      <td>
                        <span>Chloe Harrison</span>
                        {/* <span className="text-muted">Unassigned</span> */}
                      </td>
                      <td>4/10/24</td>
                      <td className="text-center">
                        <Link
                          className="px-3 py-0"
                          to=""
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <ThreeDots />
                        </Link>

                        <div className="dropdown-menu">
                          <div className="dropdown-group">
                            <ul className="dropdown-body list-unstyled mb-0">
                              <li>
                                <Link to=""> Edit turf </Link>
                              </li>
                              <li>
                                <Link
                                  to=""
                                  className="text-danger"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                >
                                  {" "}
                                  Delete turf{" "}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    {/* <tr>
                            <td colSpan={8} className="border-0">
                              <div className="loader-demo-box">
                                <div className="jumping-dots-loader">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              </div>
                            </td>
                          </tr> */}
                  </tbody>
                </table>

                <div className="noData d-flex gap-2 flex-row align-items-center">
                  <Loader />
                </div>

                {/* if no data */}
                {/* <div className="noData d-flex gap-2 flex-row align-items-center">
                          <InfoIconSecondary />
                          <p className="m-0 text-muted fs-7">
                            {" "}
                           No turf created. Cut turf to create new.{" "}
                          </p>
                        </div> */}
              </div>
            </div>
          </div>
        </main>

        {/* delete warning Modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body text-center px-4">
                <img
                  src={require("../../img/leave.png")}
                  width={66}
                  height={56}
                  alt="leave alert"
                />
                <h2 className="mt-3">Do you want to delete “Turf 1”? </h2>
                <p>
                  By deleting this turf, the voters will be removed in candidate
                  and volunteer door-to-door voter contact.
                </p>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-link rounded-pill btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>

                <button
                  className="btn btn-primary rounded-pill btn-xs"
                  onClick={notify}
                >
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* when user leave page  */}
        <div
          className="modal fade"
          id="leaveModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body text-center px-4">
                <img
                  src={require("../../img/leave.png")}
                  width={66}
                  height={56}
                  alt="leave alert"
                />
                <h2 className="mt-3"> Do you want to leave this page? </h2>
                <p>
                  By leaving the page, details filled in this page will be
                  erased.
                </p>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-link rounded-pill btn-xs"
                  data-bs-toggle="modal"
                  data-bs-target="#skipModal"
                >
                  Cancel
                </button>
                <button className="btn btn-primary rounded-pill btn-xs">
                  Yes, Leave
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TurfDetailView;

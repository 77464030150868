import React from "react";
import { IVotersList } from "./VotersTable";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import Loader from "components/common/Loader";

const TableData = ({
  voters,
  scrollRef,
  isLoadingVotersCompleted = true,
}: {
  voters: IVotersList[];
  scrollRef: React.RefObject<HTMLDivElement>;
  isLoadingVotersCompleted: boolean;
}) => {
  return (
    <div
      className="card card-fullHeight scroll-y mt-3 shadow-4"
      ref={scrollRef}
    >
      {/* table */}
      <table className="table table-full table-hover table-eq-height">
        <thead>
          <tr>
            <th>Name</th>
            <th>Address</th>
            <th>Voter Id</th>
            <th>Voter Status</th>
            <th>Level of Support</th>
            <th>Contact Type</th>
          </tr>
        </thead>
        <tbody>
          {voters.map((voter, index) => (
            <tr key={index}>
              <td>{voter.name}</td>
              <td>{voter.address}</td>
              <td>{voter.voterIdNumber}</td>
              <td>
                {voter.voterContactHistories?.length > 0 &&
                voter.voterContactHistories[
                  voter.voterContactHistories?.length - 1
                ].status ? (
                  <div>
                    <span className="badge badge-secondary rounded-pill">
                      {
                        voter.voterContactHistories[
                          voter.voterContactHistories?.length - 1
                        ].status
                      }
                    </span>

                    {
                      <span className="d-block fs-10 text-muted ">
                        Last attempt:{" "}
                        {new Date(
                          voter.voterContactHistories[
                            voter.voterContactHistories?.length - 1
                          ].updatedAt
                        ).toLocaleDateString()}
                      </span>
                    }
                  </div>
                ) : (
                  <span> - </span>
                )}
              </td>
              <td>
                {voter.voterContactHistories?.length > 0 &&
                voter.voterContactHistories[
                  voter.voterContactHistories?.length - 1
                ].levelOfSupport ? (
                  <div>
                    <span className="badge badge-secondary rounded-pill">
                      {
                        voter.voterContactHistories[
                          voter.voterContactHistories?.length - 1
                        ].levelOfSupport
                      }
                    </span>
                    <span className="d-block fs-10 text-muted ">
                      Last attempt:{" "}
                      {new Date(
                        voter.voterContactHistories[
                          voter.voterContactHistories?.length - 1
                        ].updatedAt
                      ).toLocaleDateString()}
                    </span>
                  </div>
                ) : (
                  <span> - </span>
                )}
              </td>
              <td>{voter.contactType}</td>

              {/* <span> - </span> */}
            </tr>
          ))}
        </tbody>
      </table>

      {isLoadingVotersCompleted ? (
        voters.length < 1 && (
          <div className="noData d-flex gap-2 flex-row align-items-center">
            <InfoIconSecondary />
            <p className="m-0 text-muted fs-7"> No data available </p>
          </div>
        )
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default TableData;

import InfoIconDanger from "img/svg/InfoIconDanger";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

interface IPriorityData {
    isEditable: boolean;
   
}

const PriorityAddForm = ({isEditable}: IPriorityData) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
        {isEditable &&  <div className="groupForm">
      <div className="groupForm-header row">
        <div className="col-md-8 required">
          <label htmlFor="inputPriority" className="form-label">
            Priority name
          </label>
          <input
            type="email"
            className="form-control"
            id="inputPriority"
            aria-describedby="emailHelp"
            placeholder="Enter priority name"
          />
          <div className="invalidInput form-text text-danger">
            <InfoIconDanger /> Priority cannot be numeric{" "}
          </div>
        </div>
        <div className="col-md-4 required">
          <label htmlFor="date" className="form-label">
            Due date
          </label>
          <div className="custom-datepicker">
            <DatePicker
              className="form-control"
              showIcon={true}
              selected={startDate}
              onChange={(date) => setStartDate(date ?? new Date())}
            />
          </div>
        </div>
      </div>
      <div className="groupForm-body d-flex justify-content-end gap-3">
        <button className="btn btn-link rounded-pill btn-xs"> Cancel</button>
        <button className="btn btn-primary rounded-pill btn-xs">
          {" "}
          Add Priority{" "}
        </button>
      </div>
    </div>}
    </>
   
  );
};

export default PriorityAddForm;

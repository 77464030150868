import BudgetModeActivationModal from "./budget-mode-component/BudgetModeActivationModal";
import useBudgetMode, { BUDGET_MODE_QUERY_KEY } from "./useBudgetMode";

const BudgetMode = () => {
  const {
    currentBudgetMode,
    setCurrentBudgetMode,
    isCandidateBudgetFileUploaded,
    refetchElectionDetail,
    isFundRaisingGoalAdded,
    budgetMode
  } = useBudgetMode()

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="mt-5">
            <div className="settings">
              <div className="settings-header d-flex justify-content-between align-items-end">
                <div className="">
                  <h4>Budget</h4>
                  <p className="body-4 text-muted">
                    Activate a budget mode that you want to display in budget details.
                  </p>
                </div>
                {/* clear all budget */}
                <button
                  className="btn btn-info rounded-pill btn-xs px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#budgetModeModal"
                  onClick={() => setCurrentBudgetMode(BUDGET_MODE_QUERY_KEY.RESET)}
                >
                  Reset budget
                </button>
              </div>
              <div className="settings-body">
                <div className="passwordChange-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7">Budget mode</span>
                    </div>
                    <div className="col-md-8">
                      {/* Scratch mode */}
                      <div className="row justify-content-end groupField mb-4 ">
                        <div className="col-md-8">
                          <div className="d-flex flex-column">
                            <div>
                              <span className="body-4">
                                Start from scratch
                              </span>
                              &nbsp;&nbsp;
                              {(budgetMode === null || budgetMode === BUDGET_MODE_QUERY_KEY.NULL) &&
                                <span className="badge bg-success rounded-pill px-2 font-regular">
                                  Active
                                </span>
                              }
                            </div>
                            <span className="body-4 text-muted">
                              Budget details will be empty initially.
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          {!(budgetMode === null || budgetMode === BUDGET_MODE_QUERY_KEY.NULL) &&
                            <div className="d-flex justify-content-end">
                              <button
                                className="btn btn-link btn-xs px-1 py-0"
                                data-bs-toggle="modal"
                                data-bs-target="#budgetModeModal"
                                onClick={() => setCurrentBudgetMode(BUDGET_MODE_QUERY_KEY.NULL)}
                              >
                                Activate
                              </button>
                            </div>
                          }
                        </div>
                      </div>
                      {/* Uploaded file mode */}
                      <div className={`row justify-content-end groupField mb-4 ${isCandidateBudgetFileUploaded ? '' : 'disabled'}`}>
                        <div className="col-md-8">
                          <div className="d-flex flex-column">
                            <div>
                              <span className="body-4">
                                Reflect according to uploaded file
                              </span>
                              &nbsp;&nbsp;
                              {(budgetMode === BUDGET_MODE_QUERY_KEY.FILE) &&
                                <span className="badge bg-success rounded-pill px-2 font-regular">
                                  Active
                                </span>
                              }
                            </div>
                            <span className="body-4 text-muted">
                              {isCandidateBudgetFileUploaded ?
                                'Budget file uploaded from budget details.'
                                :
                                'Upload budget file from budget details to select this budget mode.'
                              }
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          {!(budgetMode === BUDGET_MODE_QUERY_KEY.FILE) &&
                            <div className="d-flex justify-content-end">
                              <button
                                className="btn btn-link btn-xs px-1 py-0"
                                data-bs-toggle="modal"
                                data-bs-target="#budgetModeModal"
                                onClick={() => setCurrentBudgetMode(BUDGET_MODE_QUERY_KEY.FILE)}
                              >
                                Activate
                              </button>
                            </div>
                          }
                        </div>
                      </div>
                      <div className={`row justify-content-end groupField mb-4 ${isFundRaisingGoalAdded ? '' : 'disabled'}`}>
                        <div className="col-md-8">
                          <div className="d-flex flex-column">
                            <div>
                              <span className="body-4">
                                Reflect according to suggested budget based on fundraising goal
                              </span>
                              &nbsp;&nbsp;
                              {(budgetMode === BUDGET_MODE_QUERY_KEY.FUND) &&
                                <span className="badge bg-success rounded-pill px-2 font-regular">
                                  Active
                                </span>
                              }
                            </div>
                            <span className="body-4 text-muted">
                              {isFundRaisingGoalAdded ?
                                'Fund raising goal added.'
                                :
                                'Add fund raising goal to select this mode'
                              }
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          {!(budgetMode === BUDGET_MODE_QUERY_KEY.FUND) &&
                            <div className="d-flex justify-content-end">
                              <button
                                className="btn btn-link btn-xs px-1 py-0"
                                data-bs-toggle="modal"
                                data-bs-target="#budgetModeModal"
                                onClick={() => setCurrentBudgetMode(BUDGET_MODE_QUERY_KEY.FUND)}
                              >
                                Activate
                              </button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BudgetModeActivationModal
        budgetMode={currentBudgetMode}
        refetchElectionDetail={refetchElectionDetail}
      />
    </>
  );
};

export default BudgetMode;

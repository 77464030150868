import { Link } from "react-router-dom";
import { IWeeklyContactPlan, WEEKLY_TOOLTIP } from "./ContactPlan";
import Slider from "react-slick";
import HelpIcon from "img/svg/helpIcon";
import { useEffect, useState } from "react";

const LEAST_NUMBER_OF_DATA_TO_SHOW_IN_TABLE = 4;

export const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step
  );

const WeeklyContactPlanTable = ({
  weeklyContactPlan,
}: {
  weeklyContactPlan: IWeeklyContactPlan[];
}) => {
  const currentWeekObj = weeklyContactPlan?.find(
    (item: IWeeklyContactPlan) => item.isCurrentWeek
  );

  const dummyDataLength =
    weeklyContactPlan.length >= LEAST_NUMBER_OF_DATA_TO_SHOW_IN_TABLE
      ? 0
      : LEAST_NUMBER_OF_DATA_TO_SHOW_IN_TABLE - weeklyContactPlan.length - 1;

  const thisWeekDate = weeklyContactPlan.find(
    (item) => item.isCurrentWeek
  )?.week;

  // next arrow
  const NextArrow = (props: any) => {
    const { nextArrowType, className, style, onClick } = props;
    return (
      <button
        className={`${nextArrowType} ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  };
  // prev arrow
  const PrevArrow = (props: any) => {
    const { prevArrowType, className, style, onClick } = props;
    return (
      <div
        className={`${prevArrowType} ${className}`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  };

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 2,
    cssEase: "linear",
    infinite: true,
    mobileFirst: true,
    prevArrow: <PrevArrow prevArrowType={"prev-slide"} />,
    nextArrow: <NextArrow nextArrowType={"next-slide"} />,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const [initialSlide, setInitialSlide] = useState<number>();

  useEffect(() => {
    if (currentWeekObj?.weekNumber) {
      setInitialSlide(currentWeekObj?.weekNumber - 1);
    }
  }, [currentWeekObj?.weekNumber]);

  return (
    <>
      <div className="row mt-5">
        <div className="col-12">
          <div className="section-box">
            <div className="d-flex gap-2 align-items-center">
              <h3 className="section-box-title"> Weekly Contact Plan </h3>
              <div className="card-info">
                <Link
                  to=""
                  data-bs-html="true"
                  data-bs-custom-class="custom-tooltip"
                  className="text-left"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title={WEEKLY_TOOLTIP}
                >
                  <HelpIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-flush shadow-2">
            <div className="card-body p-0">
              <div className="contactPlan">
                <div className="contactPlan__legend">
                  <div className="contactPlan__legend-item">
                    <div></div>
                    <div> Door to Door </div>
                    <div>By Phone</div>
                  </div>
                </div>
                <div className="contactPlan__carousel">
                  {initialSlide !== undefined && (
                    <Slider
                      {...{
                        initialSlide,
                        ...sliderSettings,
                      }}
                    >
                      {weeklyContactPlan.map((contactPlan, index) => (
                        <div className="contactPlan__carousel-item" key={index}>
                          <div className="performance-card">
                            <span className="performance-card__datetime">
                              <span className="text-muted">
                                {/* For showing the month range eg: Oct/Nov */}
                                {new Date(contactPlan.week).toLocaleString(
                                  "en-us",
                                  { month: "short", timeZone: "UTC" }
                                ) ===
                                new Date(
                                  new Date(contactPlan.week).setDate(
                                    new Date(contactPlan.week).getDate() + 6
                                  )
                                ).toLocaleString("en-us", {
                                  month: "short",
                                  timeZone: "UTC",
                                })
                                  ? new Date(contactPlan.week).toLocaleString(
                                      "en-us",
                                      { month: "short", timeZone: "UTC" }
                                    )
                                  : new Date(contactPlan.week).toLocaleString(
                                      "en-us",
                                      { month: "short", timeZone: "UTC" }
                                    ) +
                                    "/" +
                                    new Date(
                                      new Date(contactPlan.week).setDate(
                                        new Date(contactPlan.week).getDate() + 6
                                      )
                                    ).toLocaleString("en-us", {
                                      month: "short",
                                      timeZone: "UTC",
                                    })}
                              </span>
                              <br />
                              {/* For showing the day/date range eg: 1-7 */}
                              {new Date(contactPlan.week).toLocaleString(
                                "en-us",
                                { day: "numeric", timeZone: "UTC" }
                              ) +
                                "-" +
                                new Date(
                                  new Date(contactPlan.week).setDate(
                                    new Date(contactPlan.week).getDate() + 6
                                  )
                                ).toLocaleString("en-us", {
                                  day: "numeric",
                                  timeZone: "UTC",
                                })}
                            </span>
                            {contactPlan.isCurrentWeek && (
                              <div className="current-week">
                                <span className="badge badge-success">
                                  This week
                                </span>
                              </div>
                            )}
                          </div>
                          {/* Weekly contact plan door to door  */}
                          <div className="contactPlan__cell">
                            <Link
                              to={
                                contactPlan.isCurrentWeek ? "door-to-door" : ""
                              }
                            >
                              <div
                                className={`contactPlan__cell-status active ${
                                  currentWeekObj &&
                                  currentWeekObj.week <= contactPlan.week
                                    ? "has-started"
                                    : ""
                                } ${
                                  contactPlan.isCurrentWeek && "cursor-pointer"
                                } `}
                              >
                                <div className="contactPlan__cell-status-header">
                                  {/* {contactPlan.isCurrentWeek && <h6> this </h6>} */}
                                  <h4>
                                    {thisWeekDate &&
                                    contactPlan.week <= thisWeekDate
                                      ? contactPlan.d2dContactAttempts
                                      : contactPlan.d2dContacts}
                                    {contactPlan.isCurrentWeek &&
                                      `/${contactPlan.d2dContacts}`}
                                  </h4>
                                  <span> attempts </span>
                                </div>
                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {contactPlan.d2dContacts} Total Contacts
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      <i className="icon-clock"></i>
                                      {contactPlan.d2dContactTime}hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>

                          {/* Weekly contact plan by phone  */}
                          <div className="contactPlan__cell">
                            <Link
                              to={
                                contactPlan.isCurrentWeek
                                  ? "contact-by-phone"
                                  : ""
                              }
                            >
                              <div
                                className={`contactPlan__cell-status active ${
                                  currentWeekObj &&
                                  currentWeekObj.week <= contactPlan.week
                                    ? "has-started"
                                    : ""
                                } ${
                                  contactPlan.isCurrentWeek && "cursor-pointer"
                                } `}
                              >
                                <div className="contactPlan__cell-status-header">
                                  <h4>
                                    {thisWeekDate &&
                                    contactPlan.week <= thisWeekDate
                                      ? contactPlan.phoneContactAttempts
                                      : contactPlan.phoneContacts}
                                    {contactPlan.isCurrentWeek &&
                                      `/${contactPlan.phoneContacts}`}
                                  </h4>
                                  <span> attempts </span>
                                </div>
                                <div className="contactPlan__cell-status-body">
                                  <span className="contact-total">
                                    {contactPlan.phoneContacts} Total Contacts
                                  </span>
                                  <div className="timer">
                                    <div className="timer-icon"></div>
                                    <div className="timer-duration">
                                      <i className="icon-clock"></i>{" "}
                                      {contactPlan.phoneContactTime}hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}

                      {/* if no data available */}
                      {arrayRange(0, dummyDataLength * 7, 7).map((index) => (
                        <div className="contactPlan__carousel-item" key={index}>
                          {/* <div className="performance-card"></div> */}
                          {/* <div className="contactPlan__cell">
                            <div className="contactPlan__cell-status">
                              <div className="contactPlan__cell-status-body">
                                <span>
                                  {" "}
                                  {String("Recheck from the start")}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="contactPlan__cell">
                            <div className="contactPlan__cell-status">
                              <div className="contactPlan__cell-status-body">
                                <span>
                                  {" "}
                                  {String("Recheck from the start")}{" "}
                                </span>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeeklyContactPlanTable;

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ChevronDownIcon from "img/svg/chevronDownIcon";
import ThreeDots from "img/svg/threeDots";

import TurfMap from "./TurfMap";
import { getDate } from "utils/formatDate";
import Loader from "components/common/Loader";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import { ITurfDetail, useTurfDetail } from "./useTurfDetail";
import EditTurfs from "./components/EditTurfs";
import { secondsToHMS } from "utils/secondToHMSFormat";

export interface IEditableTurfDetail {
  id: number;
  name: string;
  voterCount: number;
  doorCount: number;
  turfAssignment: {
    id: number;
    candidate: {
      id: number;
      fullName: string;
    };
    volunteerId: number;
    volunteer: {
      id: number;
      fullName: string;
    };
  };
}

const TurfDetail = () => {
  const [isFullscreen, setFullscreen] = useState(false);
  const [deleteTurfId, setDeleteTurfId] = useState<number>();
  const [deleteTurfName, setDeleteTurfName] = useState<string>();

  const handleView = () => {
    setFullscreen(!isFullscreen);
  };
  const {
    turfDetails,
    isTurfDetailsLoading,
    universeDetails,
    selectedUniverse,
    setSelectedUniverse,
    createTurf,
    deleteTurf,
    turfs,
    totalDoors,
    resetMap,
    UpdateTurfName,
    setEditTurfDetails,
    EditTurfDetails,
    turfAttemptedData,
    setHighlightedPolygonIndex,
    setOpenEditModal,
    openEditModal,
    PostTurfAssignmentDetails,
    PatchTurfAssignmentDetails,
  } = useTurfDetail();

  const leaveImg = require("../../../../../../img/leave.png");
  const [selectedDoors, setSelectedDoors] = useState(0);

  const savedDoors = turfDetails?.items?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.doorCount,
    0
  );

  useEffect(() => {
    const currentlySelectedDoors = turfs?.reduce(
      (acc, item) => acc + item.doorCount,
      0
    );
    const totalDoors = savedDoors
      ? savedDoors + currentlySelectedDoors
      : currentlySelectedDoors;
    setSelectedDoors(totalDoors);
  }, [turfs, savedDoors]);

  const navigate = useNavigate();

  const handleEditClick = (turf: ITurfDetail, index: number) => {
    setHighlightedPolygonIndex(index);
    setOpenEditModal(false);
    setOpenEditModal(true);
    setEditTurfDetails({
      id: turf.id,
      name: turf.name,
      voterCount: turf.voterCount,
      doorCount: turf.doorCount,
      turfAssignment: turf.turfAssignment,
    });
  };

  useEffect(() => {
    setOpenEditModal(false);
  }, [selectedUniverse]);

  return (
    <div className="d-flex align-items-stretch">
      <div className="wrapper active-cont">
        <main className="p-0">
          <div className={isFullscreen ? `turfDetail fullView` : "turfDetail"}>
            <div className="turfDetail-header">
              <div className="turfDetail-header-selection">
                <div className="d-flex gap-3 align-items-center">
                  {/* Universe selection dropdown */}
                  <div className="dropdown">
                    <button
                      className="btn btn-outline-secondary btn-xxs bg-white border-0 px-0 py-0 font-semibold fs-6 gap-1"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedUniverse?.type === "Default"
                        ? "Default Universe"
                        : selectedUniverse?.name
                      }
                      : {selectedUniverse?.filterType}
                      <span className="">
                        <ChevronDownIcon />
                      </span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-checkbox turfSelectionDd">
                      <ul className="dropdown-body list-unstyled">
                        {universeDetails?.map((universe, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setSelectedUniverse(universe);
                            }}
                          >
                            <div className="turfSelectionDd-list">
                              <div className="d-flex gap-2 align-items-center">
                                <span className="body-4 mb-1">
                                  {universe?.type === "Default"
                                    ? "Default Universe"
                                    : universe?.name}
                                  : {universe?.filterType}
                                </span>

                                {universe?.isActive && (
                                  <span className="badge bg-success rounded-pill px-2 font-regular">
                                    Active
                                  </span>
                                )}
                              </div>
                              <div className="">
                                <span className="fs-8 text-muted">
                                  {universe?.filterType}
                                </span>
                                <span className="dot dot-sm bg-neutral-n3 mx-1"></span>
                                <span className="fs-8 text-muted">
                                  {universe?.doorCount} Total Doors
                                </span>
                                <span className="dot dot-sm bg-neutral-n3 mx-1"></span>
                                <span className="fs-8 text-muted">
                                  {universe?.voterCount} Total Voters
                                </span>
                                <span className="dot dot-sm bg-neutral-n3 mx-1"></span>
                                <span className="fs-8 text-muted">
                                  {universe?.turfsCount} Turfs
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {selectedUniverse?.isActive && (
                    <span className="badge bg-success rounded-pill px-2 font-regular">
                      Active
                    </span>
                  )}
                </div>
                <span className="body-4 text-muted">
                  Create and assign turf for this universe for contacting the
                  voters.
                </span>
              </div>
              <div className="turfDetail-header-action">
                <button
                  className="btn btn-link rounded-pill btn-xs me-2"
                  onClick={() => {
                    resetMap();
                    navigate("/voters/dashboard", {
                      state: { showUniverseList: true },
                    });
                  }}
                >
                  Exit
                </button>
                <button
                  className="btn btn-info rounded-pill btn-xs"
                  onClick={() => {
                    createTurf(turfs);
                  }}
                  disabled={turfs?.length ? false : true}
                >
                  Save changes
                </button>
              </div>
            </div>
            <div
              className={
                isFullscreen ? `turfDetail-body show` : `turfDetail-body`
              }
            >
              <div className="position-relative w-100 h-100">
                <TurfMap />
                {/* edit turf form */}
                {openEditModal && (
                  <EditTurfs
                    isFullscreen={isFullscreen}
                    UpdateTurfName={UpdateTurfName}
                    EditTurfDetails={EditTurfDetails}
                    setOpenEditModal={setOpenEditModal}
                    turfAttemptedData={turfAttemptedData}
                    PostTurfAssignmentDetails={PostTurfAssignmentDetails}
                    PatchTurfAssignmentDetails={PatchTurfAssignmentDetails}
                  />
                )}
              </div>

              {/* Collapse turf table */}
              <div
                className="viewControlBtn border border-bottom-0"
                onClick={handleView}
              >
                <ChevronDownIcon />
              </div>
            </div>
            {/* Turf details */}
            <div className="turfDetail-footer">
              <div className="turfDetail-footer-content">
                <h5>Turf details</h5>
                <div>
                  <span className="fs-7 text-muted">
                    {" "}
                    {selectedUniverse?.filterType}{" "}
                  </span>
                  <span className="dot dot-sm bg-neutral-n3 mx-1"> </span>
                  <span className="fs-7 text-muted">
                    {" "}
                    Total Doors: {totalDoors}{" "}
                  </span>
                  <span className="dot dot-sm bg-neutral-n3 mx-1"> </span>
                  <span className="fs-7 text-muted">
                    {" "}
                    Selected Doors: {selectedDoors}{" "}
                  </span>
                </div>
              </div>
              <div className="card card-fullHeight scroll-y border-top rounded-0 card-turfDetails">
                {/* table */}
                <table className="table table-full table-eq-height table-hover">
                  <thead>
                    <tr>
                      <th> Turf name </th>
                      <th> Voters </th>
                      <th> Doors </th>
                      <th> % Completed </th>
                      <th> Estimated time to complete </th>
                      <th> Assigned to </th>
                      <th> Date Created </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {turfDetails?.items?.map((turf, index) => (
                      <tr key={index}>
                        <td>
                          <span> {turf.name} </span>
                        </td>
                        <td>
                          <span> {turf.voterCount} </span>
                        </td>
                        <td>
                          {" "}
                          <span> {turf.doorCount} </span>{" "}
                        </td>
                        <td>
                          <span> {turf?.completePercentage} </span>{" "}
                        </td>
                        <td>
                          <span>
                            {" "}
                            {secondsToHMS(turf?.expectedTimeToComplete)}{" "}
                          </span>
                        </td>
                        <td>
                          {turf?.turfAssignment?.candidate?.fullName ||
                            turf?.turfAssignment?.volunteer?.fullName ? (
                            turf.turfAssignment.candidate?.fullName ||
                            turf.turfAssignment.volunteer?.fullName
                          ) : (
                            <span className="text-muted">Unassigned</span>
                          )}

                          {/* <span>
                            {turf?.turfAssignment?.candidate?.fullName ||
                              turf?.turfAssignment?.volunteer?.fullName ||
                              "Unassigned"}{" "}
                          </span> */}
                          <span>
                            {" "}
                            {turf?.turfAssignment?.candidate?.id &&
                              "(You)"}{" "}
                          </span>
                        </td>
                        <td>{getDate(new Date(turf.createdAt))}</td>
                        <td className="text-center">
                          <button
                            className="btn btn-link no-hover px-3 py-0"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <ThreeDots />
                          </button>

                          <div className="dropdown-menu">
                            <div className="dropdown-group">
                              <ul className="dropdown-body list-unstyled mb-0">
                                <li>
                                  <button
                                    className={`btn font-medium text-start fs-7 py-2 px-4 w-100 ${openEditModal && 'btn-link'}`}
                                    onClick={() => handleEditClick(turf, index)}
                                    disabled={openEditModal}
                                  >
                                    Edit turf{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className={`btn font-medium text-start fs-7 py-2 px-4 text-danger w-100 ${openEditModal && 'btn-link'}`}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                    onClick={() => {
                                      setDeleteTurfId(turf.id);
                                      setDeleteTurfName(turf?.name);
                                    }}
                                    disabled={openEditModal}

                                  >
                                    {" "}
                                    Delete turf{" "}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {/* 
                    {isTurfDetailsLoading && (
                      <tr>
                        <td colSpan={8} className="border-0">
                          <div className="loader-demo-box">
                            <Loader />
                          </div>
                        </td>
                      </tr>
                    )} */}
                  </tbody>
                </table>

                {/* Loader */}
                {isTurfDetailsLoading && (
                  <div className="noData d-flex gap-2 flex-row align-items-center">
                    <Loader />
                  </div>
                )}

                {/* if no data */}
                {turfDetails?.items.length === 0 && (
                  <div className="noData d-flex gap-2 flex-row align-items-center">
                    <InfoIconSecondary />
                    <p className="m-0 text-muted fs-7">
                      {" "}
                      No turf created. Cut turf to create new.{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
        {/* delete warning Modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body text-center px-4">
                <img src={leaveImg} width={66} height={56} alt="leave alert" />
                <h2 className="mt-3">
                  Do you want to delete {deleteTurfName} ?{" "}
                </h2>
                <p>
                  By deleting this turf, the voters will be removed in candidate
                  and volunteer door-to-door voter contact.
                </p>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-link rounded-pill btn-xs"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>

                <button
                  className="btn btn-primary rounded-pill btn-xs"
                  data-bs-dismiss="modal"
                  onClick={() => deleteTurfId && deleteTurf(deleteTurfId)}
                >
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* when user leave page  */}
        <div
          className="modal fade"
          id="leaveModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="icon-close text-n1 h2"></span>
                </button>
              </div>
              <div className="modal-body text-center px-4">
                <img src={leaveImg} width={66} height={56} alt="leave alert" />
                <h2 className="mt-3"> Do you want to leave this page? </h2>
                <p>
                  By leaving the page, details filled in this page will be
                  erased.
                </p>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-link rounded-pill btn-xs"
                  data-bs-toggle="modal"
                  data-bs-target="#skipModal"
                >
                  Cancel
                </button>
                <button className="btn btn-primary rounded-pill btn-xs">
                  Yes, Leave
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TurfDetail;

import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query'
import Spinner from 'components/common/Spinner'
import { useEffect, useRef } from 'react'
import CreateAuthAxiosInstance from 'utils/authAxios'
import toastNotify from 'utils/toastNotify'
import axios from 'axios'
import { BUDGET_MODE_QUERY_KEY, MODAL_TEXT, ModalTextKeyType } from '../useBudgetMode'

const BudgetModeActivationModal = ({
  budgetMode,
  refetchElectionDetail
}: {
  budgetMode: ModalTextKeyType
  refetchElectionDetail: (options?: RefetchOptions) => Promise<QueryObserverResult<any, Error>>
}
) => {
  const authAxios = CreateAuthAxiosInstance()

  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const {
    mutate: activateBudget,
    isPending: isPendingBudgetActivation,
    status: budgetActivationStatus
  } = useMutation({
    mutationFn: (budgetMode: string) => authAxios.post(
      `api/v1/budgets/build-budgets?budgetMode=${budgetMode}`
    )
      .then((data) => data?.data),
    onSuccess: handleOnSuccessEvent,
    onError: handleOnErrorEvent
  })

  function handleOnSuccessEvent() {
    toastNotify('success', budgetMode === BUDGET_MODE_QUERY_KEY.RESET ? 'Budget reset successfully.' : 'Budget mode changed successfully.')
    refetchElectionDetail()
  }

  function handleOnErrorEvent(error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error("on error", error?.response?.data?.message)
      if (budgetMode === BUDGET_MODE_QUERY_KEY.FUND && error?.response?.status === 404) {
        toastNotify("error", "Cannot generate budget! System limits election dates to 12 months.")
        return
      }
      toastNotify("error", "Could activate budget!")
    }
  }

  //Close modal after successful activation
  useEffect(() => {
    if (closeButtonRef?.current && budgetActivationStatus === 'success') {
      closeButtonRef.current.click()
    }
  }, [budgetActivationStatus])

  const handleBudgetReset = () => {
    activateBudget(budgetMode === BUDGET_MODE_QUERY_KEY.RESET ? BUDGET_MODE_QUERY_KEY.NULL : budgetMode)
  }

  return (
    <>
      {/* reset value Modal */}
      <div
        className="modal fade"
        id="budgetModeModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                ref={closeButtonRef}
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                disabled={budgetActivationStatus === 'pending'}
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../../img/leave.png")}
                width={56}
                height={56}
                alt="delete warning"
              />
              <h2 className="mt-3">{MODAL_TEXT[budgetMode].text1}</h2>
              <p>{MODAL_TEXT[budgetMode].text2}</p>
            </div>

            <div className="modal-footer">
              <button className="btn btn-link rounded-pill btn-xs"
                data-bs-dismiss="modal"
                aria-label="Close"
                disabled={budgetActivationStatus === 'pending'}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary rounded-pill btn-xs d-flex align-items-center"
                onClick={handleBudgetReset}
                disabled={budgetActivationStatus === 'pending'}
              >
                {budgetActivationStatus === 'pending' && <Spinner />}
                Yes,&nbsp;
                {budgetMode === BUDGET_MODE_QUERY_KEY.RESET ? 'reset' : 'change'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BudgetModeActivationModal
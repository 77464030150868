import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { DashboardChildProp } from "type/dashboard";
import {
  removeLocalStorageItem,
  setLocalStorageItem,
} from "utils/localStorageHelper";

const clearLocalStorage = () => {
  removeLocalStorageItem("COMPLETED_STEPS");
  removeLocalStorageItem("IS_SETUP");
  removeLocalStorageItem("ACTIVE_STEP");
  removeLocalStorageItem("file-0");
  removeLocalStorageItem("file-1");
  removeLocalStorageItem("file-2");
};

const Ideology = ({
  handleStepCompletion,
  setIsCompleted,
}: DashboardChildProp) => {
  const { getIdTokenClaims } = useAuth0();

  const [selectedValue, setSelectedValue] = useState<number>(3);
  const localUserId = localStorage.getItem("userId");

  const updateSliderStyles = (input: HTMLInputElement) => {
    const percentage =
      ((Number(input.value) - Number(input.min)) /
        (Number(input.max) - Number(input.min))) *
      100;
    const bg = `linear-gradient(90deg, var(--fill-color) ${percentage}%, var(--background-color) ${
      percentage + 0.1
    }%)`;
    input.style.background = bg;
  };
  const updateIdeologyData = async (input: number) => {
    const token = await getIdTokenClaims();
    if (input > 2) input = input + 2; // To map ideology id with BE
    if (localUserId) {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/${localUserId}`,
        { ideologyId: input, isDashboardSetupCompleted: true },
        {
          headers: {
            Authorization: `Bearer ${token?.__raw}`,
          },
        }
      );
    }
    setIsCompleted && setIsCompleted(true);
  };

  useEffect(() => {
    const sliders = document.querySelectorAll(".range-slider");

    sliders?.forEach((slider) => {
      const input = slider.querySelector<HTMLInputElement>("input");
      if (input) {
        const updateSliderStyles = () => {
          const percentage =
            ((Number(input.value) - Number(input.min)) /
              (Number(input.max) - Number(input.min))) *
            100;
          const bg = `linear-gradient(90deg, var(--fill-color) ${percentage}%, var(--background-color) ${
            percentage + 0.1
          }%)`;
          input.style.background = bg;
        };

        updateSliderStyles();

        const inputEventListener = (event: Event) => {
          setSelectedValue(Number(input.value));
          updateSliderStyles();
        };

        input.addEventListener("input", inputEventListener);

        return () => {
          input.removeEventListener("input", inputEventListener);
        };
      }
    });
  }, []);

  return (
    <div className="col-md-8">
      <div className="card card-accountSetup shadow-2">
        <div className="card-title">
          <h4>Candidate Ideology</h4>
        </div>

        <div className="card-body">
          <div className="your-story">
            <form action="">
              <div className="mb-3">
                <label className="custom-label">
                  On the political spectrum, where do you put yourself?
                </label>

                <div className="row">
                  <div className="col-md-12">
                    <div className="range-wrapper">
                      <div className="range-slider">
                        <input
                          className="range-slider__range"
                          type="range"
                          min="1"
                          max="5"
                          value={selectedValue}
                          onChange={(e) => {
                            let value: number = Number(e.target.value);
                            setSelectedValue(value);
                            updateSliderStyles(e.target);
                          }}
                        />
                      </div>

                      <div className="ideology">
                        <div className="ideology-item">Very Progressive</div>
                        <div className="ideology-item"> Liberal </div>
                        <div className="ideology-item"> Moderate </div>
                        <div className="ideology-item"> Conservative </div>
                        <div className="ideology-item">Very Conservative</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card-footer shadow-6 gap-4 d-flex justify-content-end">
          <Link
            to=""
            onClick={() => {
              handleStepCompletion(3, "completed");
              if (setIsCompleted) {
                setIsCompleted(true);
                setLocalStorageItem("IS_COMPLETED", JSON.stringify(true));
                updateIdeologyData(selectedValue);
                clearLocalStorage();
              }
            }}
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            className={`btn btn-primary rounded-pill btn-xs px-6`}
          >
            Finish setup
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Ideology;

import CreateAuthAxiosInstance from "utils/authAxios"
import { IDeleteTarget } from "./BudgetTable"
import { QueryObserverResult, RefetchOptions, useMutation } from "@tanstack/react-query"
import { useRef } from "react"
import toastNotify from "utils/toastNotify"
import Spinner from "components/common/Spinner"
import axios from "axios"

interface IDeleteModal {
  categoryId: number
  deleteTarget: IDeleteTarget | null
  setDeleteTarget: React.Dispatch<React.SetStateAction<IDeleteTarget | null>>
  refetchCategoryData: (options?: RefetchOptions) => Promise<QueryObserverResult<any, Error>>
}

export const DeleteModal = ({
  categoryId,
  deleteTarget,
  setDeleteTarget,
  refetchCategoryData
}: IDeleteModal) => {
  const authAxios = CreateAuthAxiosInstance()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const { mutate: deleteSubCategory, isPending: isPendingDeleteSubCategory } =
    useMutation({
      mutationFn: (data: IDeleteTarget) => authAxios.delete(
        `api/v1/budgets/categories/${data?.categoryId}/subcategories/${data?.subcategoryId}`
      )
        .then((data) => data?.data),
      onSuccess: handleOnSuccessMutation,
      onError: handleOnErrorEvent
    })

  function handleOnSuccessMutation() {
    toastNotify("success", "Subcategory Deleted Successfully !")
    refetchCategoryData()
    setDeleteTarget(null)

    if (buttonRef?.current) buttonRef?.current?.click()
  }

  function handleOnErrorEvent(error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error("on error", error?.response?.data?.message)

      toastNotify("error", "Could not delete subcategory!")
    }
  }

  const handleDeleteMutation = (deleteTarget: IDeleteTarget | null) => {
    if (!(deleteTarget && deleteTarget?.categoryId && deleteTarget?.subcategoryId)) return

    deleteSubCategory(deleteTarget)
  }

  return (
    <>
      <div
        className="modal fade"
        id={`budgetDeleteModal${categoryId}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered bg-transparent">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={buttonRef}
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3">
                Do you want to delete “
                {deleteTarget?.title || "this subcategory"}”?
              </h2>
              <p>
                By deleting this sub category, details filled for all months
                will be erased.
              </p>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-link rounded-pill btn-xs"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary rounded-pill btn-xs"
                type="button"
                onClick={() => handleDeleteMutation(deleteTarget)}
                disabled={isPendingDeleteSubCategory}
              >
                {isPendingDeleteSubCategory && <Spinner />}
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

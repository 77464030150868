import React from 'react'

const MessageSendIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M2.34505 2.24494C2.49432 2.11528 2.67867 2.03277 2.87482 2.00785C3.07097 1.98293 3.2701 2.01672 3.44705 2.10494L21.447 11.1049C21.6135 11.1879 21.7534 11.3156 21.8513 11.4737C21.9491 11.6318 22.001 11.814 22.001 11.9999C22.001 12.1859 21.9491 12.3681 21.8513 12.5262C21.7534 12.6843 21.6135 12.812 21.447 12.8949L3.44705 21.8949C3.27011 21.9835 3.07089 22.0175 2.87459 21.9928C2.6783 21.9681 2.49374 21.8857 2.34429 21.756C2.19484 21.6264 2.0872 21.4553 2.035 21.2645C1.98281 21.0736 1.98839 20.8716 2.05105 20.6839L4.61305 12.9999H10C10.2653 12.9999 10.5196 12.8946 10.7072 12.7071C10.8947 12.5195 11 12.2652 11 11.9999C11 11.7347 10.8947 11.4804 10.7072 11.2928C10.5196 11.1053 10.2653 10.9999 10 10.9999H4.61305L2.05005 3.31594C1.98771 3.12836 1.98237 2.9265 2.0347 2.73588C2.08703 2.54526 2.19568 2.37442 2.34505 2.24494Z" fill="#2E78F1"/>
</svg>
  )
}

export default MessageSendIcon

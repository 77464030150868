import React from 'react'

const HamburgerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.59998 6.00005C3.59998 5.33731 4.13723 4.80005 4.79998 4.80005H19.2C19.8627 4.80005 20.4 5.33731 20.4 6.00005C20.4 6.66279 19.8627 7.20005 19.2 7.20005H4.79998C4.13723 7.20005 3.59998 6.66279 3.59998 6.00005Z" fill="#111827"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.59998 12C3.59998 11.3373 4.13723 10.8 4.79998 10.8H19.2C19.8627 10.8 20.4 11.3373 20.4 12C20.4 12.6628 19.8627 13.2 19.2 13.2H4.79998C4.13723 13.2 3.59998 12.6628 3.59998 12Z" fill="#111827"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.59998 18C3.59998 17.3373 4.13723 16.8 4.79998 16.8H19.2C19.8627 16.8 20.4 17.3373 20.4 18C20.4 18.6628 19.8627 19.2 19.2 19.2H4.79998C4.13723 19.2 3.59998 18.6628 3.59998 18Z" fill="#111827"/>
    </svg>
  )
}

export default HamburgerIcon

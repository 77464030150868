import React from "react";

const AiIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 2C6.05228 2 6.5 2.44772 6.5 3V4H7.5C8.05228 4 8.5 4.44772 8.5 5C8.5 5.55228 8.05228 6 7.5 6H6.5V7C6.5 7.55228 6.05228 8 5.5 8C4.94772 8 4.5 7.55228 4.5 7V6H3.5C2.94772 6 2.5 5.55228 2.5 5C2.5 4.44772 2.94772 4 3.5 4H4.5V3C4.5 2.44772 4.94772 2 5.5 2ZM5.5 12C6.05228 12 6.5 12.4477 6.5 13V14H7.5C8.05228 14 8.5 14.4477 8.5 15C8.5 15.5523 8.05228 16 7.5 16H6.5V17C6.5 17.5523 6.05228 18 5.5 18C4.94772 18 4.5 17.5523 4.5 17V16H3.5C2.94772 16 2.5 15.5523 2.5 15C2.5 14.4477 2.94772 14 3.5 14H4.5V13C4.5 12.4477 4.94772 12 5.5 12Z"
        fill="url(#paint0_linear_10413_145338)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4999 2C12.9537 2 13.3505 2.30548 13.4667 2.74411L14.6459 7.19893L17.9997 9.13381C18.3092 9.31241 18.4999 9.64262 18.4999 10C18.4999 10.3574 18.3092 10.6876 17.9997 10.8662L14.6459 12.8011L13.4667 17.2559C13.3505 17.6945 12.9537 18 12.4999 18C12.0462 18 11.6493 17.6945 11.5332 17.2559L10.354 12.8011L7.00027 10.8662C6.69072 10.6876 6.5 10.3574 6.5 10C6.5 9.64262 6.69072 9.31241 7.00027 9.13382L10.354 7.19893L11.5332 2.74411C11.6493 2.30548 12.0462 2 12.4999 2Z"
        fill="url(#paint1_linear_10413_145338)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10413_145338"
          x1="2.5"
          y1="10"
          x2="18.4999"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4047F2" />
          <stop offset="0.428665" stopColor="#54B0F3" />
          <stop offset="1" stopColor="#29F607" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10413_145338"
          x1="2.5"
          y1="10"
          x2="18.4999"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4047F2" />
          <stop offset="0.428665" stopColor="#54B0F3" />
          <stop offset="1" stopColor="#29F607" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AiIcon;

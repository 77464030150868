import InfoIconDanger from 'img/svg/InfoIconDanger'
import React, { useState } from 'react'
import FlagUsa from 'img/svg/FlagUsa'
import Spinner from 'components/common/Spinner';
import { useMutation } from '@tanstack/react-query';
import CreateAuthAxiosInstance from 'utils/authAxios';
import { CANDIDATE_ID } from 'components/pages/Homepage';

interface IVerificationNumberForm {
  cleanAndCloseTwilioCard: () => void
  setStep: React.Dispatch<React.SetStateAction<string>>
  setPhoneNumberFinal: React.Dispatch<React.SetStateAction<string | null>>
  setValidationCode: React.Dispatch<React.SetStateAction<string | null>>
}

const countryCode = {
  usa: <><FlagUsa /><span className="body-4"> +1 </span></>,
  nepal: <><span className="body-4"> +977 </span></>
}

const VerificationNumberForm = ({
  cleanAndCloseTwilioCard,
  setStep,
  setPhoneNumberFinal,
  setValidationCode
}: IVerificationNumberForm) => {
  const authAxios = CreateAuthAxiosInstance()
  const retrievedCandidateId = localStorage.getItem(CANDIDATE_ID);

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean | null>(null)
  const [country, setCountry] = useState<string>('usa')
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const {
    mutate: postVerifyPhoneNumberMutation,
    data: postVerifyPhoneNumberData,
    isPending
  } = useMutation({
    mutationFn:
      (finalPhoneNumber: string) => authAxios.post(
        `${process.env.REACT_APP_COMMUNICATION_BASE_URL}/api/v1/calls/candidate/phonenumber-verification`,
        {
          'phoneNumber': finalPhoneNumber
        }
      ),
    onSuccess: (data) => {
      handleSuccessfulSubmission(data?.data?.validationCode)
    },
    onError: (error: any) => {
      // console.log("on error", error?.response?.data?.message);
      setErrorMsg(error?.response?.data?.message || 'Unexpected error occurred!')
    },
  })

  const {
    mutate: executeCancelPhoneNumberVerificationMutation,
    data: phoneNumberCancellationData,
    status: phoneNumberCancellationStatus
  } = useMutation({
    mutationFn: () => authAxios.patch(
      `/api/v1/user/${retrievedCandidateId}`,
      {
        'autoDialerStatus': 'Rejected'
      }
    ),
    onSuccess: (data) => {
      // console.log("successfully cancelled", phoneNumberCancellationData, phoneNumberCancellationStatus)
    },
    onError: (error) => {
      // console.log("on error", error);
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMsg(null)
    const formattedValue = formatPhoneNumber(event?.target?.value);
    setPhoneNumber(formattedValue ?? '')

    if (isValid !== null) checkValidity(formattedValue, 'onChange')
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (checkValidity(phoneNumber, 'onSubmit')) {
      setIsValid(null)
      postVerifyPhoneNumberMutation(
        `${country === 'usa' ? '+1' : '+977'}${phoneNumber.replace(/\D/g, '')}`
      )
    }
  }

  const handleSuccessfulSubmission = (validationCode: string) => {
    if (validationCode) {
      setValidationCode(validationCode)
      setPhoneNumberFinal(`${country === 'usa' ? '+1' : '+977'}${phoneNumber.replace(/\D/g, '')}`)
      setStep('otp')
    }
  }

  const checkValidity = (value: string, action: string) => {
    const cleanedValue = value.replace(/\D/g, '')
    if (cleanedValue?.length < 10) {
      setIsValid(false)
      return false
    }
    if (action === 'onChange') setIsValid(true)

    return true
  }

  function resetAllState() {
    setPhoneNumber('')
    setIsValid(null)
    setCountry('usa')
  }

  function handleCancelPhoneNumberVerification() {
    cleanAndCloseTwilioCard()
    resetAllState()
    executeCancelPhoneNumberVerificationMutation()
  }

  return (
    <>
      <div className="card-title mb-0">
        <span className="body-2">Verify Phone Number</span>
        <h4></h4>
        <p className="body-3">
          Enter and verify your phone number to access the automated call
          services.
        </p>
      </div>
      <div className="card-body">
        <form onSubmit={handleOnSubmit}>
          <div className="mb-3 required">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Phone no
            </label>
            <div className="input-group">
              <button
                className={`btn py-1 px-3 btn-outline-secondary dropdown-toggle ${!(process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'staging') ? 'disabled' : ''}`}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {countryCode[country as keyof typeof countryCode]}
              </button>
              <ul className="dropdown-menu">
                <li
                  onClick={() => setCountry('usa')}
                >
                  <a className="dropdown-item" href="#">
                    USA
                  </a>
                </li>
                <li
                  onClick={() => setCountry('nepal')}
                >
                  <a className="dropdown-item" href="#">
                    Nepal
                  </a>
                </li>
              </ul>
              <input
                type="text"
                id='phonenumber'
                className="form-control"
                aria-label="Text input with country code dropdown"
                placeholder="Enter your phone no."
                value={phoneNumber}
                onChange={handleChange}
              />
            </div>

            {/* if phone no. is valid  */}
            {!!isValid &&
              <div className="d-flex align-items-center gap-1 invalidInput form-text text-success">
                <span className="icon-check-circle"></span> Valid Phone no.
              </div>
            }

            {/* if phone no. is not valid  */}
            {isValid === false &&
              <div className="d-flex align-items-center gap-1 invalidInput form-text text-danger">
                <InfoIconDanger /> Invalid Phone no.
              </div>
            }
            {!!errorMsg &&
              <div className="d-flex align-items-center gap-1 invalidInput form-text text-danger">
                <InfoIconDanger />{errorMsg}
              </div>
            }
          </div>

          <div>
            <button
              className="btn btn-primary w-100 rounded-pill mb-2"
              type='submit'
              disabled={isPending}
            >
              {!!isPending &&
                <>
                  <Spinner /> &nbsp;
                </>
              }
              Verify number
            </button>
            <button
              type="button"
              className="btn btn-link w-100 rounded-pill"
              onClick={handleCancelPhoneNumberVerification}
            >
              Continue without verifying
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default VerificationNumberForm


const formatPhoneNumber = (input: string) => {
  const cleanedValue = input.replace(/\D/g, '')
  let formattedValue = ''

  if (cleanedValue.length <= 3) {
    formattedValue = cleanedValue
  }
  else if (cleanedValue.length > 3 && cleanedValue.length <= 6) {
    formattedValue = `(${cleanedValue.substring(0, 3)}) ${cleanedValue.substring(3)}`
  }
  else if (cleanedValue.length >= 6) {
    formattedValue = `(${cleanedValue.substring(0, 3)}) ${cleanedValue.substring(3, 6)} - ${cleanedValue.substring(6)}`
  }

  // console.log(
  //   "input:", input,
  //   '\nclean value: ', cleanedValue,
  //   '\nformated value:', formattedValue
  // )

  return formattedValue
};
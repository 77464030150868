import React from 'react'

const InfoIconWarningX18 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM10.125 13.5C10.125 14.1213 9.62132 14.625 9 14.625C8.37868 14.625 7.875 14.1213 7.875 13.5C7.875 12.8787 8.37868 12.375 9 12.375C9.62132 12.375 10.125 12.8787 10.125 13.5ZM9 3.375C8.37868 3.375 7.875 3.87868 7.875 4.5V9C7.875 9.62132 8.37868 10.125 9 10.125C9.62132 10.125 10.125 9.62132 10.125 9V4.5C10.125 3.87868 9.62132 3.375 9 3.375Z" fill="#F9A153"/>
    </svg>
  )
}

export default InfoIconWarningX18

import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import { globalQueryConfig } from "utils/reactQuery";

const DefaultScript = (props: any) => {
  let contentRef = useRef<HTMLDivElement>(null);
  // const authAxios = CreateAuthAxiosInstance();
  // const {
  //   isLoading,
  //   error,
  //   data: candidateData,
  // } = useQuery({ queryKey: ["candidateDetail"], queryFn: fetchCandidateData, ...globalQueryConfig, });

  // async function fetchCandidateData() {
  //   try {
  //     const response = await authAxios.get(
  //       "api/v1/user/detail"
  //     );

  //     return response.data;
  //   } catch (error: any) {
  //     console.log("error", error);
  //     return null;
  //   }
  // }

  // console.log("props",props)
  return (
    <>
      <div
        className="textEditor defaultScript form-control"
        contentEditable={false}
        ref={contentRef}
        // suppressContentEditableWarning={true}
        // TO DO: Use dom purify while using default script
        // dangerouslySetInnerHTML={{ __html: DEFAULT_SCRIPT_HTML ?? "" }}
      >
        { props.contactType === 'By Phone' && props.userType === 'candidate' && props.postedTo === 'Voter' &&(<>
          <div className="">
            {" "}
            <p>
              {" "}
              Hi, is <span className="text-bg">
                {" "}
                {props.postedTo} Name{" "}
              </span>{" "}
              available? .{" "}
            </p>{" "}
            <p>
              {" "}
              Hi, <span className="text-bg"> {props.postedTo} Name </span>, my
              name is <span className="text-bg"> {props.userType} Name </span> and I'm
              running for <span className="text-bg"> Position </span> , right here
              in <span className="text-bg"> City </span>. How are you doing
              today?{" "}
            </p>{" "}
            <p>
              {" "}
              The reason I’m calling today is because we have really important
              election upcoming for <span className="text-bg">
                {" "}
                Position .{" "}
              </span>{" "}
            </p>{" "}
            <p>
              {" "}
              Thanks for your time,{" "}
              <span className="text-bg">{props.postedTo} Name </span> !{" "}
            {" "} <br />
            </p>
            <p>
              I’m running for <span className="text-bg"> Position. </span> 
            </p>
            <p>
              {" "}
              If you have any questions, feel free to reach out. Have a great day{" "}
            </p>
          </div>
        
        </>)}

        { props.contactType === 'By Phone' && props.userType === 'volunteer' && (<>
          <div className="">
            {" "}
            <p>
              {" "}
              Hi, is <span className="text-bg">
                {" "}
                {props.postedTo} Name{" "}
              </span>{" "}
              available? .{" "}
            </p>{" "}
            <p>
              {" "}
              Hi, <span className="text-bg"> {props.postedTo} Name </span>, my
              name is <span className="text-bg"> {props.userType} Name </span> and I'm
              volunteering for <span className="text-bg"> Candidate Name </span> who is running for <span className="text-bg"> Position </span>, right here in <span className="text-bg"> City </span>. How are you doing today?
            </p>{" "}
            <p>
              {" "}
              The reason I’m calling today is because we have really important
              election upcoming for <span className="text-bg">
                {" "}
                Position .{" "}
              </span>{" "}
            </p>{" "}
            <p>
              {" "}
             Can we count on your support for  <span className="text-bg"> Candiate Name </span> ?{" "}
            </p>
            <p> 

              Thank you so much for your time today!
            </p>
          </div>
        </>) }

        { props.contactType === 'By Door' && props.userType === 'candidate' && (<>
          <div className="">
            {" "}
            <p>
              {" "}
              Hi, is <span className="text-bg">
                {" "}
                {props.postedTo} Name{" "}
              </span>{" "}
              available? .{" "}
            </p>{" "}
            <p>
              {" "}
              Hi, <span className="text-bg"> {props.postedTo} Name </span>, my
              name is <span className="text-bg"> {props.userType} Name </span> and I'm
              running for <span className="text-bg"> Position </span> , right here
              in <span className="text-bg"> City </span>. How are you doing
              today?{" "}
            </p>{" "}
            <p>
              {" "}
              The reason I’m calling today is because we have really important
              election upcoming for <span className="text-bg">
                {" "}
                Position .{" "}
              </span>{" "}
            </p>{" "}
            <p>
              {" "}
              Thanks for your time,{" "}
              <span className="text-bg">{props.postedTo} Name </span> !{" "}
            {" "} <br />
            </p>
            <p>
            I’m running for <span className="text-bg"> Position. 
              </span>
            </p>
            <p>
              {" "}
              If you have any questions, feel free to reach out. Have a great day{" "}
            </p>
          </div>
        </>) }

        { props.contactType === 'By Door' && props.userType === 'volunteer' && (<>
          <div className="">
            {" "}
            <p>
              {" "}
              Hi, is <span className="text-bg">
                {" "}
                {props.postedTo} Name{" "}
              </span>{" "}
              available? .{" "}
            </p>{" "}
            <p>
              {" "}
              Hi, <span className="text-bg"> {props.postedTo} Name </span>, my
              name is <span className="text-bg"> {props.userType} Name </span> and I'm
              volunteering for <span className="text-bg"> Candidate Name </span> who is running for <span className="text-bg"> Position </span>, right here in <span className="text-bg"> City </span>. How are you doing today?
            </p>{" "}
            <p>
              {" "}
              The reason I’m calling today is because we have really important
              election upcoming for <span className="text-bg">
                {" "}
                Position .{" "}
              </span>{" "}
            </p>{" "}
            <p>
              {" "}
             Can we count on your support for  <span className="text-bg"> Candiate Name </span> ?{" "}
            </p>
            <p> 

              Thank you so much for your time today!
            </p>
          </div>
        </>) }

        { props.contactType === 'By Fundraising' && props.userType === 'candidate' && props.postedTo === 'Donor' && (<>
          <div className="">
            {" "}
            <p>
              {" "}
              Hi, is <span className="text-bg">
                {" "}
                {props.postedTo} Name{" "}
              </span>{" "}
              available? .{" "}
            </p>{" "}
            <p>
              {" "}
              Hi, <span className="text-bg"> {props.postedTo} Name </span>, my
              name is <span className="text-bg"> {props.userType} Name </span> and I'm
              running for <span className="text-bg"> Position </span> , right here
              in <span className="text-bg"> City </span>. How are you doing
              today?{" "}
            </p>{" "}
            <p>
              {" "}
              The reason I’m calling today is because we have really important
              election upcoming for <span className="text-bg">
                {" "}
                Position .{" "}
              </span>{" "}
            </p>{" "}
            
            <p>
              I’m running for <span className="text-bg"> Position. 
              </span> 
            </p>
            <p>
              {" "}
             Can we count on you to support our campaign with a donation to help us reach voters and meet our goals?{" "}
            </p>
          </div>
        </>) }

      </div>
    </>
  );
};

export default DefaultScript;

import TrashIcon from "img/svg/TrashIcon";
import FileIcon from "img/svg/fileIcon";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import DefaultScript from "../scriptEditor/DefaultScript";
import CustomScript from "../scriptEditor/CustomScript";
import { useMutation } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { queryClient } from "index";
import { DevTool } from "@hookform/devtools";
import { title } from "process";
import useCandidateScriptDetails from "./useCandidateScriptDetails";
import { CANDIDATE_ID } from "components/pages/Homepage";
import { getYearMonthDate } from "utils/getDateTime";
import InfoIconDanger from "img/svg/InfoIconDanger";
import { CustomScriptV2 } from "../scriptEditor/CustomScriptV2";

type ScriptTitleSchema = yup.InferType<typeof schema>;

const schema = yup.object({
  title: yup.string().required("Title is a required field"),
  // script: yup.string().required(),
});

const CandidateScript = ({
  userType,
  contactType,
  postedTo,
  editable,
  setEditable,
}: ICandidateScriptProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const userId = localStorage.getItem(CANDIDATE_ID);

  // form validation
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ScriptTitleSchema>({
    resolver: yupResolver(schema) as any,
  });

  const {
    postCandidateScriptData,
    patchCandidateScriptData,
    fetchScriptsData,
    active,
    setActive,
    setActiveScript,
    activeScript,
    defaultValueMap
  } = useCandidateScriptDetails();

  // console.log("caneditable", editable);
  // console.log("active", active);
  const [textLength, setTextLength] = useState<number>(0);

  useEffect(() => {
    const activeScript = fetchScriptsData?.find(
      (data: any) =>
        data.contactType === contactType && data.usageScope === "candidate"
    );
    setActiveScript(activeScript);

    setActive(activeScript?.isActive ?? true);
  }, [fetchScriptsData, active]);


  const handleScript = (type: any) => {
    if (type == "default") {
      patchCandidateScriptData({ isActive: true });
    }
    if (type === "custom") {
      patchCandidateScriptData({ isActive: false });
    }
  };
  const handleEditClick = (contactType: string) => {
    setEditable && setEditable(`candidateScript-${contactType}`);
  };

  useEffect(() => {
    if (activeScript) {
      Object.entries(activeScript).forEach(([key, value]) => {
        // console.log(key, value);

        if (typeof key === 'string') {
          setValue(key as "title", value);
        }
      })
    }
  }, [fetchScriptsData, activeScript, setValue])



  //check contactType to open form 
  const matchContactTypeForForm = editable && editable.startsWith("candidateScript-") && editable.split("-")[1] === contactType;

  // check if volunteer form is open 
  const VolunteerFormOpen = editable?.startsWith("volunteerScript-") && editable.split("-")[1] === contactType;

  //for default script title
  const defaultValue = defaultValueMap[contactType] || "";

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const customScriptPostData = {
          candidateId: Number(userId),
          title: data.title,
          script: contentRef?.current?.innerHTML,
          contactType: contactType,
          usageScope: "candidate",
        };

        if (!activeScript) {
          // To create
          postCandidateScriptData(customScriptPostData);
        } else {
          //To patch
          patchCandidateScriptData(customScriptPostData);
        }
        setEditable && setEditable(null);
      })}
    >
      <div className="settings">
        <div className="settings-header d-flex justify-content-between">
          <div className="">
            <h4> Candidate </h4>
            <p className="body-4 text-muted">
              Edit or add a script for Voter Contact calls done by the
              Candidate.
            </p>
          </div>

          <div>
            {matchContactTypeForForm ? (
              <>
                {" "}
                <button
                  className="btn btn-link btn-xs rounded-pill"
                  onClick={() => setEditable && setEditable(null)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  // onClick={onSubmit}
                  className="btn btn-link btn-xs rounded-pill"
                  disabled={textLength === 0}
                >
                  Update
                </button>
              </>
            ) : (
              <button
                className="btn btn-link btn-xs px-1 py-0"
                onClick={() => handleEditClick(contactType)}
                disabled={VolunteerFormOpen}
              >
                {" "}
                <i className="icon-edit"> </i> Edit details
              </button>
            )}
          </div>
        </div>

        <div className="settings-body">
          <div className="professional-info">
            <hr className="mt-3 mb-3" />
            <div className="row">
              <div className="col-md-4">
                <span className="body-2 fs-7"> Default Script </span>
              </div>

              <div className={matchContactTypeForForm ? "col-md-8" : "col-md-6"}>
                {matchContactTypeForForm ? (
                  <>
                    <div className="mb-3">
                      <label htmlFor="exampleInputTitle" className="form-label">
                        Title
                      </label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        value={defaultValue}
                        id="exampleInputTitle"
                        aria-describedby="titleHelp"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputScript"
                        className="form-label"
                      >
                        Script
                      </label>
                      <DefaultScript
                        userType={userType}
                        contactType={contactType}
                        postedTo={postedTo}
                      />
                    </div>
                  </>
                ) : (
                  <div className="d-flex flex-column">
                    <div>
                      <span className="body-4"> Voter Contact Script </span>
                      {active === true && (
                        <span className="badge bg-success rounded-pill px-2 font-regular">
                          {" "}
                          Active{" "}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!matchContactTypeForForm && active === false && (
                <div className="col-md-2 text-end">
                  <button
                    className="btn btn-link btn-xs no-hover"
                    onClick={() => handleScript("default")}
                    type="button"
                    disabled={VolunteerFormOpen}
                  >
                    {" "}
                    Activate{" "}
                  </button>
                </div>
              )}
            </div>
            <hr className="mt-3 mb-3" />
            <div className="row">
              <div className="col-md-4">
                <span className="body-2 fs-7"> Custom Script </span>
              </div>
              <div className={matchContactTypeForForm ? "col-md-8" : "col-md-6"}>
                {!(matchContactTypeForForm) ? (
                  !activeScript?.script ? (
                    <span className="body-4 text-muted"> Not Provided </span>
                  ) : (
                    <div className="d-flex flex-column">
                      <div>
                        <span className="body-4"> Custom Contact Script </span>
                        {active === false && (
                          <span className="badge bg-success rounded-pill px-2 font-regular">
                            {" "}
                            Active{" "}
                          </span>
                        )}
                      </div>
                      <span className="body-4 text-muted">
                        {" "}
                        Updated on{" "}
                        {activeScript?.updatedAt &&
                          getYearMonthDate(activeScript?.updatedAt)}{" "}
                      </span>
                    </div>
                  )
                ) : (
                  <>
                    <div className="mb-3">
                      <label htmlFor="exampleInputTitle" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputTitle"
                        aria-describedby="emailHelp"
                        placeholder="Enter a Title for Your Script"
                        maxLength={100}
                        {...register("title")}
                      />
                      {errors?.title?.message && (
                        <div
                          id="emailHelp"
                          className="form-text text-danger fs-8"
                        >
                          <span className="me-2">
                            <InfoIconDanger />
                          </span>
                          {errors?.title?.message as string}
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputScript"
                        className="form-label"
                      >
                        Script
                      </label>
                      {/* <div className="position-relative">
                        <CustomScript
                          contentRef={contentRef}
                          fetchScriptsData={fetchScriptsData}
                          contactType={contactType}
                          userType={userType}
                          textLength={textLength}
                          setTextLength={setTextLength}
                        />
                        <span className="word-count"> {textLength} / 500 </span>
                      </div> */}

                      <div className="position-relative">
                        <CustomScriptV2
                          contentRef={contentRef}
                          fetchScriptsData={fetchScriptsData}
                          contactType={contactType}
                          userType={userType}
                          textLength={textLength}
                          setTextLength={setTextLength}
                        />
                        <span className="word-count"> {textLength} / 500 </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-2 text-end">
                {!(matchContactTypeForForm) && active === true && activeScript?.script && (
                  <>
                    <button
                      className="btn btn-link btn-xs no-hover"
                      onClick={() => handleScript("custom")}
                      disabled={VolunteerFormOpen}
                      type="button"
                    >
                      {" "}
                      Activate{" "}
                    </button>
                  </>
                )}
              </div>
            </div>
            <hr className="mt-3 mb-3" />
          </div>
        </div>

        <DevTool control={control} />
      </div>
    </form>
  );
};

export default CandidateScript;

const TwitterSmall = ()=> {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_245_4855)">
            <path d="M8.00017 15.9999C12.4184 15.9999 16.0001 12.4182 16.0001 7.99993C16.0001 3.58169 12.4184 0 8.00017 0C3.58193 0 0.000244141 3.58169 0.000244141 7.99993C0.000244141 12.4182 3.58193 15.9999 8.00017 15.9999Z" fill="#55ACEE"/>
            <path d="M12.9003 5.74925C12.5575 5.90127 12.1887 6.00408 11.8019 6.05C12.1968 5.81342 12.4999 5.43908 12.6429 4.99229C12.2734 5.21148 11.864 5.37049 11.4286 5.45619C11.0798 5.0847 10.5828 4.85254 10.0325 4.85254C8.97663 4.85254 8.12028 5.70889 8.12028 6.76475C8.12028 6.91463 8.13725 7.06051 8.17005 7.20055C6.58084 7.12084 5.17175 6.3596 4.22855 5.20235C4.06398 5.48471 3.96958 5.81342 3.96958 6.1638C3.96958 6.82707 4.30741 7.41261 4.82022 7.75543C4.50692 7.74559 4.21187 7.6596 3.95418 7.516C3.95403 7.52413 3.95403 7.53225 3.95403 7.54024C3.95403 8.46675 4.61344 9.23954 5.48805 9.41509C5.32776 9.45901 5.15849 9.48225 4.98436 9.48225C4.86087 9.48225 4.74122 9.47042 4.62457 9.44817C4.86785 10.2077 5.5739 10.7606 6.41085 10.7761C5.75629 11.2891 4.93188 11.5947 4.03575 11.5947C3.88173 11.5947 3.72914 11.5857 3.57983 11.5679C4.42563 12.1106 5.43101 12.4271 6.51082 12.4271C10.0281 12.4271 11.9515 9.51334 11.9515 6.98622C11.9515 6.90336 11.9497 6.82079 11.946 6.73894C12.3199 6.46941 12.644 6.13272 12.9003 5.74925Z" fill="#F1F2F2"/>
            </g>
            <defs>
            <clipPath id="clip0_245_4855">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default TwitterSmall;
import React from "react";

const TrashIconSmall = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M7.28572 2C7.01516 2 6.76783 2.1605 6.64684 2.41459L6.12998 3.5H3.71429C3.3198 3.5 3 3.83579 3 4.25C3 4.66421 3.3198 5 3.71429 5L3.71429 12.5C3.71429 13.3284 4.35388 14 5.14286 14H10.8571C11.6461 14 12.2857 13.3284 12.2857 12.5V5C12.6802 5 13 4.66421 13 4.25C13 3.83579 12.6802 3.5 12.2857 3.5H9.87003L9.35316 2.41459C9.23217 2.1605 8.98484 2 8.71429 2H7.28572ZM5.85714 6.5C5.85714 6.08579 6.17694 5.75 6.57143 5.75C6.96592 5.75 7.28571 6.08579 7.28571 6.5V11C7.28571 11.4142 6.96592 11.75 6.57143 11.75C6.17694 11.75 5.85714 11.4142 5.85714 11V6.5ZM9.42857 5.75C9.03408 5.75 8.71429 6.08579 8.71429 6.5V11C8.71429 11.4142 9.03408 11.75 9.42857 11.75C9.82306 11.75 10.1429 11.4142 10.1429 11V6.5C10.1429 6.08579 9.82306 5.75 9.42857 5.75Z" fill="#3B3B4F"/>
</svg>
  );
};
export default TrashIconSmall;

import { useQuery } from "@tanstack/react-query";
import { ELECTION_ID } from "components/pages/dashboard/Dashboard";
import CreateAuthAxiosInstance from "utils/authAxios";
import { globalQueryConfig } from "utils/reactQuery";
import { COMMUNICATION_BASE_URL, ICallLog, ICurrentBillingData, ITEMS_PER_PAGE } from "./callLogs.d";
import { useEffect, useState } from "react";
import toastNotify from "utils/toastNotify";

const useCallLogsDetails = () => {
  const [callLogsLimit, setCallLogsLimit] = useState(ITEMS_PER_PAGE);
  const [isRefetching, setIsRefetching] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const electionId = localStorage.getItem(ELECTION_ID);

  const authAxios = CreateAuthAxiosInstance(COMMUNICATION_BASE_URL);

  async function getCallLogsDetails() {
    try {
      const response = await authAxios.get(
        `/api/v1/calls/logs?electionId=${electionId}&limit=${callLogsLimit}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      toastNotify("error", "Error fetching data");
    }
  }

  const {
    data: callLogsResponse,
    refetch,
    isLoading: icCallLogsLoading,
  } = useQuery({
    queryKey: ["callLogsDetails"],
    queryFn: getCallLogsDetails,
    ...globalQueryConfig,
  });

  useEffect(() => {
    (async () => {
      if (callLogsLimit > ITEMS_PER_PAGE) {
        setIsRefetching(true);
        await refetch();
        setIsRefetching(false);
      }
    })();
  }, [callLogsLimit]);

  const callLogsData: ICallLog[] = callLogsResponse?.items;
  const totalCallDuration: number = callLogsResponse?.totalCallDuration;
  const totalCallCost: number = callLogsResponse?.totalCallCost;
  const totalCallLogs: number = callLogsResponse?.totalItems;

  async function getCsvData(startDate: Date | null, endDate: Date | null) {
    try {
      if (startDate && endDate) {
        const endDateCopy = new Date(endDate);
        const endDateExtendedByOneDay = new Date(
          endDateCopy.setDate(endDate.getDate() + 1)
        );

        const response = await authAxios.get(
          `/api/v1/calls/logs/csv?dateCreatedAfter=${startDate.toISOString()}&dateCreatedBefore=${endDateExtendedByOneDay.toISOString()}&electionId=${electionId}`
        );
        return response.data;
      }
    } catch (error: any) {
      console.log(error);

      toastNotify("error", error?.response?.data?.message);
    }
  }

  const {
    data: csvData,
    isLoading: isCsvLoading,
    refetch: refetchCsvData,
  } = useQuery({
    queryKey: ["csvDetails", startDate, endDate],
    queryFn: () => getCsvData(startDate, endDate),
    enabled: startDate && endDate ? true : false,
    ...globalQueryConfig,
  });

  useEffect(() => {
    if (startDate && endDate) {
      refetchCsvData();
    }
  }, [startDate, endDate]);


  
  async function getCurrentBillingAmount() {
    try {
      const response = await authAxios.get(
        `/api/v1/invoices/elections/${electionId}`
      );
      return response.data as ICurrentBillingData;
    } catch (error: any) {
      console.log(error);
      toastNotify("error", error?.response?.data?.message);
    }
  }

  const {
    data: currentBillingData,
    isLoading: isCurrentBillingLoading,
    // refetch: refetchBillingAmount,
  } = useQuery({
    queryKey: ["currentBillingAmount"],
    queryFn: getCurrentBillingAmount,
    ...globalQueryConfig,
  });

  return {
    callLogsData,
    totalCallCost,
    totalCallDuration,
    icCallLogsLoading,
    totalCallLogs,
    callLogsLimit,
    setCallLogsLimit,
    isRefetching,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isCsvLoading,
    csvData,
    currentBillingData,
    isCurrentBillingLoading
  };
};

export default useCallLogsDetails;

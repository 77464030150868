const FbSmall = ()=> {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <g clipPath="url(#clip0_6_911)">
            <path d="M8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16Z" fill="#3B5998"/>
            <path d="M10.5112 8.31316H9.08369V13.5429H6.9209V8.31316H5.89227V6.47523H6.9209V5.28589C6.9209 4.43537 7.32491 3.10356 9.10294 3.10356L10.705 3.11026V4.89428H9.5426C9.35193 4.89428 9.08383 4.98955 9.08383 5.39527V6.47694H10.7001L10.5112 8.31316Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_6_911">
            <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default FbSmall;
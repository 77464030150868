import React from 'react'

const ExclamationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.9987 2.66659C5.05318 2.66659 2.66536 5.0544 2.66536 7.99992C2.66536 10.9454 5.05318 13.3333 7.9987 13.3333C10.9442 13.3333 13.332 10.9454 13.332 7.99992C13.332 5.0544 10.9442 2.66659 7.9987 2.66659ZM1.33203 7.99992C1.33203 4.31802 4.3168 1.33325 7.9987 1.33325C11.6806 1.33325 14.6654 4.31802 14.6654 7.99992C14.6654 11.6818 11.6806 14.6666 7.9987 14.6666C4.3168 14.6666 1.33203 11.6818 1.33203 7.99992ZM7.9987 4.66659C8.36689 4.66659 8.66536 4.96506 8.66536 5.33325V7.99992C8.66536 8.36811 8.36689 8.66659 7.9987 8.66659C7.63051 8.66659 7.33203 8.36811 7.33203 7.99992V5.33325C7.33203 4.96506 7.63051 4.66659 7.9987 4.66659ZM7.33203 10.6666C7.33203 10.2984 7.63051 9.99992 7.9987 9.99992H8.00536C8.37355 9.99992 8.67203 10.2984 8.67203 10.6666C8.67203 11.0348 8.37355 11.3333 8.00536 11.3333H7.9987C7.63051 11.3333 7.33203 11.0348 7.33203 10.6666Z" fill="#858599"/>
</svg>
  )
}

export default ExclamationIcon

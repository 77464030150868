import React, { useEffect, useRef, useState } from "react";

const CustomScript = () => {
  let contentRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [customScriptHtml, setCustomScriptHtml] = useState<string>();
  const [selectedText, setSelectedText] = useState<string>();
  const [customScript, setCustomScript] = useState<string>("");

  const listData = ["Voter Name", "Candidate Name", "City", "Strength 1", "Strength 2"];

  //   const defaultScript = { name: "Anish", city: "Ktm", strength: "code" };
  //   const DEFAULT_SCRIPT_HTML = `<p>My name is <h1> ${defaultScript.name} </h1> My city is ${defaultScript.city}</p>`;

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "/") {
      event.preventDefault();
      contentRef.current?.focus();
      setShowDropdown(true);
       // Set cursor position to the end of the contentEditable element
      
    
   
      //   try {

      //   } catch (e) {
      //     console.log("error", e);
      //   }
    } else {
      contentRef.current?.focus();
      setShowDropdown(false);
    }
  };

  useEffect(() => {
   
    if (showDropdown) {
      const customScriptText = contentRef?.current?.innerText;
      if (customScriptText) setCustomScript(customScriptText);
      const mappedDataListHtml = listData.map(
        (item, index) =>
          ` <li
                key=${index}
                value=${item}
              
              >
                <span class="dropdown-item">${item} </span>
              </li>`
      ).join('');
      const scriptHtml = `${customScriptText} <ul class="dropdown-menu d-block"> ${mappedDataListHtml}</ul>`;
      setCustomScriptHtml(scriptHtml);
    } else {
      if (selectedText) {
        setCustomScriptHtml(`  ${customScript} ${selectedText}`);

      } else {
        setCustomScriptHtml(undefined);
      }
    }
  }, [showDropdown]);

  // max charater length to 500 
  const handleInput = (event: React.FormEvent<HTMLDivElement>) => {
    const maxLength = 500;
    const text = event.currentTarget.textContent || "";
    if (text.length > maxLength) {
      event.preventDefault();
      const truncatedText = text.slice(0, maxLength);
      event.currentTarget.textContent = truncatedText;
    }
  };


  return (
    <>
      <div
        className="textEditor customScript form-control"
        contentEditable
        ref={contentRef}
        
       
        onClick={(event) => {
          setShowDropdown(false);
          if ((event.target as any).innerText)
            setSelectedText((event.target as any).innerText);
        }}
        onKeyDown={handleKeyDown}
        // suppressContentEditableWarning={true}
        // TO DO: Use dom purify while using default script
        dangerouslySetInnerHTML={{ __html: customScriptHtml ?? "" }}
        onInput={handleInput}
      ></div>
    </>
  );
};

export default CustomScript;

import React from "react";
import { Link } from "react-router-dom";
import { StepperProps } from "type/dashboard";

const AccountSetupStepper: React.FC<StepperProps> = ({
  activeStep,
  completedSteps,
  setActiveStep,
  setStatus,
}) => {
  const steps = [
    { label: "Voter" },
    { label: "Fundraise" },
    { label: "Your story" },
    { label: "Campaign Information" },
    { label: "Budget" },
    { label: "Communication" },
  ];

  return (
    <div className="stepper">
      <div className="stepper-header">
        <div className="indicator">
          <div className="indicator-item success"></div>
          {steps.map((step, index) => {
            const isCompleted = completedSteps.some(
              (completedStep) =>
                completedStep.index === index &&
                completedStep.status === "completed"
            );
            const isSkipped = completedSteps.some(
              (completedStep) =>
                completedStep.index === index &&
                completedStep.status === "skipped"
            );

            return (
              <div
                key={index}
                className={`indicator-item ${isCompleted ? "success" : ""} 
                ${activeStep === index ? "active" : ""} 
                ${isSkipped ? "skipped" : ""}`}
              ></div>
            );
          })}
        </div>
      </div>

      <div className="stepper-body d-none d-md-block mt-4">
        <ul className="nav flex-column">
          <li className="nav-item success">
            <Link className="nav-link" aria-current="page" to="#">
              <span className="nav-link-circle"> </span>
              <span> Create your account </span>{" "}
            </Link>{" "}
          </li>
          {steps.map((step, index) => {
            const isCompleted = completedSteps.some(
              (completedStep) =>
                completedStep.index === index &&
                completedStep.status === "completed"
            );
            const isSkipped = completedSteps.some(
              (completedStep) =>
                completedStep.index === index &&
                completedStep.status === "skipped"
            );

            return (
              <li
                key={index}
                className={`nav-item ${isCompleted ? "success" : ""} ${
                  activeStep === index ? "active" : ""
                } ${isSkipped ? "skipped" : ""}`}
              >
                {isCompleted || isSkipped ? (
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="#"
                    onClick={() => {
                      setActiveStep(index);
                      setStatus(isCompleted ? "completed" : "skipped");
                    }}
                  >
                    <span className="nav-link-circle"> </span>
                    <span>{step.label}</span>
                  </Link>
                ) : (
                  <span className="nav-link">
                    <span className="nav-link-circle"> </span>
                    <span>{step.label}</span>
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AccountSetupStepper;